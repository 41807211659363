import { Component, OnInit } from '@angular/core';
import { FormProService } from 'src/app/core/formPro/form-pro.service';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Suggestion } from 'src/app/shared/models/suggestion';

@Component({
  selector: 'app-suggestion-table',
  templateUrl: './suggestion-table.component.html',
  styleUrls: ['./suggestion-table.component.scss']
})
export class SuggestionTableComponent implements OnInit {

  window: number = 1;

  arraySuggestion: Suggestion[] = [];

  valueFilterName: string = '';
  valueFilterSubject: string = '';
  valueFilterStatus: number = null;

  arrayStatus: any[] = [];

  secondArrayStatus: any[] = [];
  valueStatusSelected: number = null;
  valueCommentary: string = '';

  //pagination
  page: number = 1;
  pageSize: number = 10;
  counter: number = 0;

  suggestionSelected: Suggestion = null;

  imageUrl: string = GlobalConstants.backendURL;


  constructor(private suggestionService: FormProService) { }

  ngOnInit(): void {
    this.getAllSuggestion();
    this.getStatus();
  }

  getAllSuggestion(){
    this.suggestionService.getAllSuggestion(
      this.valueFilterName,
      this.valueFilterSubject,
      this.valueFilterStatus,
      this.pageSize,
      (this.page - 1) * this.pageSize
    ).toPromise().then((data: NodeResponse) => {
      console.log(data);

      if(data.status == true){
        this.arraySuggestion = data.data;
        this.counter = data.arrayLengthCounter;
      }else{
        console.log(data.message);
      }
    })
  }

  getStatus(){
    this.suggestionService.getAllSuggestionStatus().toPromise().then((data: NodeResponse) => {
      console.log(data);

      if(data.status == true){
        this.arrayStatus = data.data;
        for(let status of data.data){
          if(status.id != 1) this.secondArrayStatus.push(status)
        }
      }else{
        console.log(data.message);
      }
    })
  }

  initTable(){
    if(this.window != 1) this.window = 1;

    this.valueFilterName = '';
    this.valueFilterSubject = '';
    this.valueFilterStatus = null;
    this.valueStatusSelected = null;
    this.suggestionSelected = null;
    this.valueCommentary = '';

    this.getAllSuggestion();
  }

  showSuggestion(suggestion_id: number){
    this.suggestionService.getSuggestionById(suggestion_id).toPromise().then((data: NodeResponse) => {
      console.log(data);

      if(data.status == true){
        this.window = 2;
        this.suggestionSelected = data.data;

        if(data.data.status_id != 1)
          this.valueStatusSelected = data.data.status_id;

        this.valueCommentary = data.data.commentary;
      }else{
        console.log(data.message);
      }
    })
  }

  changeStatus(){

    if(confirm('Are you sure do you want to change the status?')){
      this.suggestionService.updateStatus(this.suggestionSelected.id, this.valueStatusSelected, this.valueCommentary).toPromise().then((data: NodeResponse) => {
        console.log(data);

        if(data.status == true){
          this.initTable();
        }else{
          console.log(data.message);
        }
      })
    }

  }

  deleteSuggestion(id: number){
    this.suggestionService.deleteSuggestion(id).toPromise().then((data: NodeResponse) => {
      console.log(data);

      if(data.status == true){
        this.initTable();
      }else{
        console.log(data.message);
      }
    })

  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import proj4 from 'proj4';

@Injectable({
  providedIn: 'root'
})
export class CadastreSiService {

  private cadastreCoordinatebyURL = '/ows-ins-wfs/bu-core2d/ows';
  private buildingCadastreInfo = 'https://ipi.eprostor.gov.si/wfs-si-gurs-kn/wfs';

  constructor(private http: HttpClient) { }

  getBuildingInfoSIParcel(coorX: string, coorY: string): Promise<any>{
    const header = new HttpHeaders({ Accept: 'application/xml'});

    const crs25830 = '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

    const crs3794 = '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

    const coordinates = proj4(crs25830,crs3794,[parseFloat(coorX), parseFloat(coorY)]);

    const params = new HttpParams()
      .set('service', 'wfs')
      //.set('version', '2.0.0')
      .set('typeNames', 'cp:CadastralParcel') //bu-core2d:Building
      .set('request', 'getfeature')
      .set('outputFormat', 'application/json')
      .set('cql_filter', 'INTERSECTS(geometry, POINT('+ coordinates[0] + ' ' + coordinates[1] + '))')
    const options = {
      header,
      params,
      responseType: 'text' as 'text'
    };
    return this.http.request('GET', this.cadastreCoordinatebyURL, options).toPromise();
  }

  getBuildingInfoSIUnits(coorX: string, coorY: string): Promise<any>{
    const header = new HttpHeaders({ Accept: 'application/xml'});

    const crs25830 = '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

    const crs3794 = '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

    const coordinates = proj4(crs25830,crs3794,[parseFloat(coorX), parseFloat(coorY)]);

    const params = new HttpParams()
      .set('service', 'wfs')
      //.set('version', '2.0.0')
      .set('typeNames', 'bu-core2d:Building') //bu-core2d:Building
      .set('request', 'getfeature')
      .set('outputFormat', 'application/json')
      .set('cql_filter', 'INTERSECTS(geometry, POINT('+ coordinates[0] + ' ' + coordinates[1] + '))')
    const options = {
      header,
      params,
      responseType: 'text' as 'text'
    };
    return this.http.request('GET', this.cadastreCoordinatebyURL, options).toPromise();
  }

  getBuildingInfoSI(coorX: string, coorY: string): Promise<any>{
    const header = new HttpHeaders({ Accept: 'application/xml'});

    const crs25830 = '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

    const crs3794 = '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

    const coordinates = proj4(crs25830,crs3794,[parseFloat(coorX), parseFloat(coorY)]);

    const coordinateX = coordinates[0] - 19404;

    const coordinateY = coordinates[1] + 7000;

    const crs = '3794'

    const params = new HttpParams()
      //.set('service', 'wfs')
      .set('version', '2.0.0')
      .set('typeNames', 'STAVBE')
      .set('srsName', 'EPSG:3794')
      .set('request', 'GetFeature')
      //.set('count', '5')
      .set('outputFormat', 'application/json')
      .set('cql_filter', 'INTERSECTS(NADZEMNI_GEOM, POINT('+ coordinates[0] + ' ' + coordinates[1] + '))')
      //.set('cql_filter', 'KO_ID=2035 and ST_STAVBE=171')
    const options = {
      header,
      params,
      responseType: 'text' as 'text'
    };
    return this.http.request('GET', this.buildingCadastreInfo, options).toPromise();
  }

  getBuildingInfoSurfaceSI(coorX: string, coorY: string, eid_edificio: number): Promise<any>{
    const header = new HttpHeaders({ Accept: 'application/xml'});

    const crs25830 = '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

    const crs3794 = '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs';

    const coordinates = proj4(crs25830,crs3794,[parseFloat(coorX), parseFloat(coorY)]);

    const coordinateX = coordinates[0] - 19404;

    const coordinateY = coordinates[1] + 7000;

    const crs = '3794'

    const params = new HttpParams()
      //.set('service', 'wfs')
      .set('version', '2.0.0')
      .set('typeNames', 'DELI_STAVB')
      .set('srsName', 'EPSG:3794')
      .set('request', 'GetFeature')
      //.set('count', '5')
      .set('outputFormat', 'application/json')
      .set('cql_filter', 'EID_STAVBA = ' + eid_edificio)
      //.set('cql_filter', 'KO_ID=2035 and ST_STAVBE=171')
    const options = {
      header,
      params,
      responseType: 'text' as 'text'
    };
    return this.http.request('GET', this.buildingCadastreInfo, options).toPromise();
  }
}

<h1>My suggestions</h1>

<div class="d-flex justify-content-center navButtons">
  <button type="button" class="btn btn-link buttonsAdminTools" (click)="initForm()"><div class="h3">Form suggestion</div></button>
  <button type="button" class="btn btn-link buttonsAdminTools" (click)="initTable()"><div class="h3">Table suggestion</div></button>
  <br>
</div>

<div *ngIf="window == 1">

  <form>

    <p>This form is not anonymous. Your user data will be saved in the submit.</p>

    <div class="form-group">
      <label for="name">Subject<span class="obligatory">*</span></label>
      <input type="text" id="subject" class="form-control" placeholder="Subject of the propose" name="subject" [(ngModel)]="valueSubject"><br>
    </div>

    <div class="form-group">
      <label for="suggestion">Suggestion (5000)<span class="obligatory">*</span></label><br>
      <textarea id="suggestion" name="suggestion" class="form-control" placeholder="Write the suggestion" rows="4" cols="50" maxlength="5000" [(ngModel)]="valueSugestion"></textarea>
      <div>{{valueSugestion.length}}/5000</div><br>
    </div>

    <div class="form-group">
      <label for="images" class="form-label">Main Image<span class="obligatory">*</span></label><br>
      <div class="nameImages">{{nameImages}}</div>
      <input class="form-control" type="file" id="images" name="images" (change)="fileImageChangeEvent($event)" multiple>
    </div>

    <div class="form-group">
      <label for="name">Video</label>
      <input type="text" id="video_url" class="form-control" placeholder="Link to a video" name="video_url" [(ngModel)]="valueVideo"><br>
    </div>

    <button class="btn btn-primary" (click)="createSuggestion()">Submit</button>
  </form>
</div>

<div *ngIf="window == 2">

  <table class="table table-hover" id="table" name="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Subject</th>
        <th scope="col">Status</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let suggestion of arraySuggestion; index as i">
        <td>{{suggestion.subject}}</td>
        <td>{{suggestion.suggestion_status.name}}</td>
        <td>
          <button class="btn btn-light" (click)="showSuggestion(suggestion.id)">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="arraySuggestion.length"></ngb-pagination>


</div>

<div *ngIf="window == 3">
  <form>
    <div class="form-group">
      <label for="name">Subject</label>
      <input type="text" id="subject" class="form-control" placeholder="Subject of the propose" name="subject" [(ngModel)]="suggestionSelected.subject" readonly><br>
    </div>

    <div class="form-group">
      <label for="suggestion">Suggestion (5000)</label><br>
      <textarea id="suggestion" name="suggestion" class="form-control" placeholder="Write the suggestion" rows="4" cols="50" maxlength="5000" [(ngModel)]="suggestionSelected.suggestion" readonly></textarea>
      <div>{{suggestionSelected.suggestion.length}}/5000</div><br>
    </div>

    <div class="form-group">
      <label for="images" class="form-label">Images</label><br>
      <div *ngFor="let image of suggestionSelected.suggestion_images">
        <img src="{{imageUrl}}/{{image.name.substring(9)}}" height="350" width="500" class="card-img-top">
      </div>
    </div>

    <div class="form-group">
      <label for="video_url">Video</label>
      <input type="text" id="video_url" class="form-control" placeholder="Link to a video" name="video_url" [(ngModel)]="suggestionSelected.video_url" readonly><br>
    </div>

    <div class="form-group">
      <label for="video_url">Select status to change it</label>
      <input type="text" id="status" class="form-control" placeholder="Link to a video" name="status" [(ngModel)]="suggestionSelected.suggestion_status.name" readonly><br>
    </div>

    <div class="form-group">
      <label for="video_url">Select status to change it</label>
      <textarea id="comentary" name="comentary" class="form-control" placeholder="Write the comentary" rows="4" cols="50" maxlength="5000" [(ngModel)]="suggestionSelected.commentary" readonly></textarea>
      <div>{{suggestionSelected.commentary.length}}/5000</div><br>
    </div>
  </form>
</div>




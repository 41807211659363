import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-zoomed-modal',
  templateUrl: './zoomed-modal.component.html',
  styleUrls: ['./zoomed-modal.component.scss']
})
export class ZoomedModalComponent {

  @Input() imageUrl: string;
  @ViewChild('modalContent') modalContent: ElementRef;

  private imageWidth: number;
  private imageHeight: number;

  constructor(public activeModal: NgbActiveModal) {}

  closeModal() {
    this.activeModal.close();
  }

  setImageDimensions() {
    const imageElement = this.modalContent.nativeElement.querySelector('img');
    this.imageWidth = imageElement.offsetWidth;
    this.imageHeight = imageElement.offsetHeight;

    const modalDialog = this.modalContent.nativeElement.closest('.modal-dialog');
    modalDialog.style.width = `${this.imageWidth}px`;
    modalDialog.style.height = `${this.imageHeight}px`;
  }

}

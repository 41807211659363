import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private baseUrl = GlobalConstants.backendURL + '/api/notification';

  private alertSource = new Subject();
  alert$ = this.alertSource.asObservable();

  constructor(private http: HttpClient) { }

  getNotificationByUid(uid: string){
    return this.http.get(this.baseUrl + '/' + uid);
  }

  showAlert(){
    this.alertSource.next()
  }
}

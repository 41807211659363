import { Component, Input, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Tools} from '../../shared/models/tools';
import { ToolsNew } from 'src/app/shared/models/toolsnew';
import {User} from '../../shared/models/user';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from '../../core/authentication/user.service';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { DomSanitizer } from '@angular/platform-browser';
import { ToolsService } from 'src/app/core/tools/tools.service';
import { ToolsImages } from 'src/app/shared/models/toolsImages';
import { ToolsDocuments } from 'src/app/shared/models/toolsDocuments';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZoomedModalComponent } from '../zoomed-modal/zoomed-modal.component';
import { ToolComments } from 'src/app/shared/models/tool_comments';
import { NodeResponse } from 'src/app/shared/models/node_response';

@Component({
  selector: 'app-tools-modal',
  templateUrl: './tools-modal.component.html',
  styleUrls: ['./tools-modal.component.scss']
})
export class ToolsModalComponent implements OnInit {

  tool?: ToolsNew;

  imageUrl2: any;
  imageUrl = GlobalConstants.backendURL;
  closeBtnName?: string;
  isUserLogged: boolean;
  currentUser: User = new User();
  countryOneClick: string;
  countriesOneClick: [{id, name}];
  comments = "";
  postComments = [];
  replyComments = "";
  postReplyComments = [];
  stops = [];
  countryList = [];
  safeURL: any;

  imagesTool: ToolsImages[] = [];
  tmpImagesTool: ToolsImages[] = [];

  newImages: ToolsImages[] = [];

  documentsTool: ToolsDocuments[] = [];
  tmpDocumentsTool: ToolsDocuments[] = [];

  newDocuments: ToolsDocuments[] = [];

  images: boolean;
  imagesFin: boolean;
  documents: boolean;

  @Input() currentUserID: string;
  rateObjects: any;
  ratingTool: any;
  isRate: boolean;

  constructor(public bsModalRef: BsModalRef, public afAuth: AngularFireAuth, private userService: UserService, private toolsService: ToolsService, private modalService: NgbModal) {
    //this.checkLogin();
   }

  ngOnInit(): void {
    this.toolsService.getToolByUid(this.tool.uid).toPromise().then((data: NodeResponse) => {
      console.log('data unica de tool -> ', data);
      if(data.status){
        this.tool = data.data;

        this.dataValues();
        this.getImages();
        this.getDocuments();
        this.getRatingTools();
      }
    })
    this.checkLogin();
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
  }

  postComment(): void{
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          if (userFromDB) {
            this.currentUser.name = userFromDB['user'].name;
            this.currentUser.role = userFromDB['role'].name;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
    this.postComments.push(this.comments)
  }

  postReplyComment(): void{
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          if (userFromDB) {
            this.currentUser.name = userFromDB['user'].name;
            this.currentUser.role = userFromDB['role'].name;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
    this.postReplyComments.push(this.replyComments)
  }

  getImages(){
    this.toolsService.getToolsImages().subscribe(data => {
      this.buildToolsImages(data);
      this.newImages.forEach(images => {
        this.tmpImagesTool.push(images);
      });

      /*for(let i = 0; i < this.tmpImagesTool.length; i++){
        if(this.tmpImagesTool[i].tool_id == this.tool.uid){
          this.images = true;

        }else{
          this.images = false;

        }
      }

      console.log(this.images)*/
      console.log(this.tmpImagesTool);
    })
  }

  buildToolsImages(data) {
    data.forEach(image => {
      const info = new ToolsImages(
        image.id,
        image.name,
        image.tool_id,
      );

      this.newImages.push(info);
    });
  }

  getDocuments(){
    this.toolsService.getToolsDocuments().subscribe(data => {
      this.buildToolsDocuments(data);
      this.newDocuments.forEach(documents => {
        this.tmpDocumentsTool.push(documents);
      });
      console.log(this.tmpDocumentsTool);
    })
  }

  hasPhotos(toolId: string): boolean {
    return this.tmpImagesTool.some(tool => tool.tool_id.toString() === toolId);
  }

  hasDocuments(toolId: string): boolean {
    return this.tmpDocumentsTool.some(tool => tool.tool_id.toString() === toolId);
  }

  isURL(): boolean {
    // Expresión regular para verificar si es una URL válida
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    const videoURL = this.tool.video_url;

    return urlPattern.test(videoURL);
  }

  buildToolsDocuments(data) {
    data.forEach(documents => {
      const info = new ToolsDocuments(
        documents.id,
        documents.name,
        documents.tool_id,
      );

      this.newDocuments.push(info);
    });
  }

  dataValues(){

    console.log(this.tool)

    this.imageUrl2 = GlobalConstants.backendURL + '/mainImage/' + this.tool.uid + '/' + this.tool.main_image

    console.log(this.imageUrl2);

    /*if(this.tool.name === 'DRIVE-0 autoevaluation'){
      let elem = (document.getElementById("imagen-modal"));
      elem.innerHTML += "<img class='logo-tool' src='./assets/img/tools/cropped-circular.png' width='100' height='90' alt='Logo tool'>";
    }else{
      let elem = (document.getElementById("imagen-modal"));
      elem.innerHTML += "<img class='logo-tool' src='https://www.hommaxsistemas.com:13443/web/image/website/1/favicon?unique=ff3737a' style='background-color: #ACBDD5 !important; border-radius: 50%;' width='150' height='150' alt='Logo tool'>";
    }*/
  }

  openZoomedModal(imageUrl: string, nameImage: string) {
    const modalRef = this.modalService.open(ZoomedModalComponent, {size: 'xl'});
    modalRef.componentInstance.imageUrl = imageUrl+nameImage;
  }

  getRatingTools(){
    //this.administrationPanel.getGuid$().subscribe((data: string) => this.guid = data);
    this.toolsService.getToolRateInfo().subscribe((data: any) => {
      this.rateObjects = data;
      console.log(this.rateObjects);

        for(let i = 0; i < this.rateObjects.length; i++){
            if(this.rateObjects[i].tool_id == this.tool.uid){
              //console.log(this.rateObjects[i].rate)
              this.isRate = true;
              this.ratingTool = this.rateObjects[i].rate;
            }
          }

        });
  }
}

<div class="relab-main">
  <!--<div class="container">
    <img alt="Work in progess" src="../../../assets/img/Work-In-Progress.png">
  </div>-->
    <div class="info-section">
      <section class="">
        <div style="width: 40%; float: left; padding-left: 5%;" class="col-md-6 content-text-generator text-justify">
          <h1 class="relab-title">
            {{"RELAB_PAGE.TITLE" | translate}}
          </h1>
          <p style="color: #265F85;">
            {{"RELAB_PAGE.DESCRIPTION" | translate}}
          </p>
        </div>
        </section>
        <section>
            <div style="width: 50%; float: right; background-color: #DADEE2 !important;" class="col-md-6">
              <img *ngIf="currentUser && currentUser.country != 26" src="../../../assets/img/re-hub/AH-image.jpg" class="image-camera-responsive" height="485" width="auto">
              <img *ngIf="currentUser && currentUser.country == 26" src="../../../assets/img/personalization/ES/reM_reHUB.png" class="image-responsive" height="485" width="auto">
              <img *ngIf="!currentUser" src="../../../assets/img/re-hub/AH-image.jpg" class="image-camera-responsive" height="485" width="auto">
            </div>
        </section>
    </div>

    <div>
      <hr>
    </div>

    <nav ngbNav #nav="ngbNav" class="nav-tabs">
      <ng-container ngbNavItem class="me-2">
        <h5>
          <a href="#news" ngbNavLink>{{"RELAB_PAGE.REPORTS" | translate}}</a>
        </h5>
        <ng-template ngbNavContent>
          <div class="articles-section">
            <div>
              <div class="container button-filter-articles">
                <div class="input-group col-md-3 search-box">
                  <!--<button class="btn btn-primary boton-filtros filter-button-alph" type="button">A - Z</button>-->
                  <input class="form-control" type="search" [(ngModel)]="searchTextArticles" placeholder="{{'FILTERS.SEARCH_NEWS' | translate}}" id="example-search-input">&nbsp; &nbsp;
                  <button class="btn btn-primary boton-filtros" type="button" data-toggle="collapse" data-target="#collapseArticles" aria-expanded="false" aria-controls="collapseExample">
                    <i class="fa fa-filter" aria-hidden="true"></i>&nbsp;{{'RELAB_PAGE.SUPPLIERS_PART.BUTTON_FILTERS' | translate}}
                  </button>
                </div>
              </div>

              <div class="collapse container" id="collapseArticles">
                <div class="row justify-content-end filters-block" style="border-radius: 15px; padding-right: 8px; padding-left: 8px; padding-top: 8px; padding-bottom: 8px;">
                  <div class="col-2 filters">
                    <ng-select id="filterStops" class="filter" [(ngModel)]="countrySelectd" placeholder="{{'FILTERS.COUNTRY_NEWS' | translate}}" (change)="filter('countries');">
                      <ng-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</ng-option>
                    </ng-select>
                  </div>

                  <div class="col-2 filters">
                    <ng-select id="filterStops" class="filter" [(ngModel)]="categorySelectd" placeholder="{{'FILTERS.CATEGORY_NEWS' | translate}}" (change)="filter('category');">
                      <ng-option *ngFor="let category of categories" [value]="category.name">{{category.name}}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>

              <div class="row" style="margin-top: 20px; padding-bottom: 20px; margin-left: 5%;">
                <div class="col-md-2 card-article justify-content-center" *ngFor="let article of tmpArticles | filter:searchTextArticles">
                  <div class="header">
                    <button style="margin-left: 0%;" type="button" class="btn btn-default row" (click)="saveArticles(article.id)">
                      {{'TOOLS_PAGE.TOOLS.BUTTON_SAVE' | translate}}
                      <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
                    </button>&nbsp; &nbsp; &nbsp; &nbsp;

                    <span ngbDropdown>
                      <button class="btn btn-default dropdown-toggle row" type="button" id="dropdownBasic2" ngbDropdownToggle>
                        Share
                        <span><i class="fa fa-share-alt" aria-hidden="true"></i></span>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                        <a ngbDropdownItem href="#/" (click)="shareFacebook(article.id, article.article_name)">Facebook</a>
                        <a ngbDropdownItem href="#/" (click)="shareTwitter(article.id, article.article_name)">Twitter</a>
                        <a ngbDropdownItem href="#" (click)="shareLinkedIn(article.id, article.article_name)">LinkedIn</a>
                      </div>
                    </span>
                  </div>
                  <a [routerLink]="['/relab']" [queryParams]="{ id: article.id, modal: 'myModal' }" (click)="openModalArticles(article)" style="text-decoration: none; cursor: pointer;">
                    <h3 class="report-title">{{article.article_name}}</h3>
                  </a>
                  <span class="badge" style="background-color: #1C8972; color: white;">{{article.category_article.name}}</span>
                  &nbsp;
                  <span class="badge" style="background-color: #5A1981; color: white;">{{article.country.country}}</span>
                  <br>
                  <img src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" class="rounded-circle" style="width: 50px;" alt="Avatar" />
                  <footer class="author-article">{{article.author}}</footer>
                  <footer>{{article.publication_date | date: 'dd/MM/yyyy'}}</footer>
                  <p class="report-parrafo" style="text-align: justify;">
                    {{article.short_description}}
                  </p>
                  <div class="image-container">
                    <img src="{{imageUrl}}/mainImageArticles/{{article.id}}/{{article.main_image}}" width="300" height="auto">
                  </div>
                  <br>
                  <ngb-rating class="estrellas" (rateChange)="rateArticle($event, article)" [max]="5" [readonly]="false"></ngb-rating>
                  &nbsp; {{ article.visitCount }} visits<br>
                  <br>
                  <div style="padding-bottom: 10px;" *ngFor="let rate of rateObjects">
                    <span *ngIf="article.id == rate.article_id">Average rating: {{rate.rate}}</span>
                    <!--<ngb-rating class="estrellas" *ngIf="tool.uid == rate.tool_id" (rateChange)="rateTool($event, tool)" [max]="5" [readonly]="false" [(rate)]="rate.rate"></ngb-rating>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem class="me-2">
        <h5>
          <a href="#members" ngbNavLink>{{"RELAB_PAGE.SUPPLIERS" | translate}}</a>
        </h5>
        <ng-template ngbNavContent>
          <div *ngIf="isUserLogged == false">
            <div class="text-login" style="text-align: center;">
              <h2>{{"ONE_CLICK_PAGE.NOT_LOGIN.TITLE" | translate}}</h2>
            </div><br>
            <div class="text-login" style="text-align: center;">
              In order to see the members, you must be a professional user. You can request an upgrade of your role in your account.
            </div>
          </div>
              <div *ngIf="isUserLogged == true">
                <div class="container button-filter-suppliers">
                  <div class="input-group col-md-3 search-box">
                    <button class="btn btn-primary boton-filtros filter-button-alph" type="button" (click)="sortUsers()">A - Z</button>
                    <input class="form-control" type="search" [(ngModel)]="searchTextSupplier" placeholder="{{'FILTERS.SEARCH_MEMBERS' | translate}}" id="example-search-input">&nbsp; &nbsp;
                    <button class="btn btn-primary boton-filtros" type="button" data-toggle="collapse" data-target="#collapseUsers" aria-expanded="false" aria-controls="collapseExample">
                      <i class="fa fa-filter" aria-hidden="true"></i>&nbsp;{{'RELAB_PAGE.SUPPLIERS_PART.BUTTON_FILTERS' | translate}}
                    </button>
                  </div>
                </div>

                <div class="collapse container" id="collapseUsers">
                  <div class="row justify-content-end filters-block" style="border-radius: 15px; padding-right: 8px; padding-left: 8px; padding-top: 8px; padding-bottom: 8px;">
                    <div class="col-2 filters">
                      <ng-select id="filterStops" class="filter" [(ngModel)]="countryUserSelectd" placeholder="{{'FILTERS.COUNTRY_MEMBERS' | translate}}" (change)="filterUsers('countriesUsers');">
                        <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
                      </ng-select>
                    </div>

                    <div class="col-2 filters">
                      <ng-select id="filterStops" class="filter" [(ngModel)]="profile2Selectd" placeholder="{{'FILTERS.PROFILE_MEMBERS' | translate}}" (change)="filterUsers('profileUsers');">
                        <ng-option *ngFor="let profile of profiles2" [value]="profile.id">{{profile.name}}</ng-option>
                      </ng-select>
                    </div>

                    <div class="col-2 filters">
                      <ng-select id="filterStops" class="filter" [(ngModel)]="activitySelectd" placeholder="Activity" (change)="filterUsers('activityUsers');">
                        <ng-option *ngFor="let activity of activityFormProFilterAutomatically" [value]="activity.id">{{activity.name}}</ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="tools row">
                  <div class="card card-tool col-md-2 justify-content-center" *ngFor="let user of tmpUser | filter:searchTextSupplier">
                    <img *ngIf="user.user_extension.profileImage == null || user.user_extension.profileImage == undefined || user.user_extension.profileImage == ''" src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" height="auto" width="100" class="card-img-top rounded-circle">
                    <img *ngIf="user.user_extension.profileImage != null" src="{{imageUrl}}/{{user.user_extension.profileImage.substring(10)}}" height="auto" width="100" class="card-img-top rounded-circle">
                    <div class="card-body">
                      <a href="#/relab" (click)="openModal(user)"><h4>{{user.name}}</h4></a>
                      <p>Mauris imperdiet urna et nibh fringilla ornare. Nam non pharetra nulla. Mauris accusan sodales finibus.</p>
                    </div>
                  </div>
                </div>
              </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem class="me-2">
        <h5>
          <a href="#members" ngbNavLink>{{"RELAB_PAGE.PROPOSE" | translate}}</a>
        </h5>
        <ng-template ngbNavContent>
          <div *ngIf="isUserLogged == false || roleUser == 4">
            <div class="text-login" style="text-align: center;">
              <h2>{{"ONE_CLICK_PAGE.NOT_LOGIN.TITLE" | translate}}</h2>
            </div><br>
            <div class="text-login" style="text-align: center;">
              You don't have permission to do this, if you want to propose an article, please request an upgrade of your user role. Only the professional users can propose an article.
            </div>
          </div>

          <div class="form-section" *ngIf="isUserLogged == true && roleUser < 4">
            <div>
              <div class="bloque-blanco-rounded">
                <div class="form-izquierda col-md-6">
                  <h2>{{"RELAB_PAGE.PROPOSE_ARTICLE" | translate}}</h2><br>
                  <p>
                    {{"RELAB_PAGE.TEXT_PROPOSE_ARTICLE" | translate}}
                  </p>
                </div>
                <div class="form-derecha col-md-6">
                  <form #tooldata="ngForm" (ngSubmit)="createArticle()" enctype="multipart/form-data">
                    <label for="name">{{"RELAB_PAGE.ARTICLE_NAME" | translate}} <span class="obligatory">*</span>:</label>
                    <input type="text" id="name" class="form-control" placeholder="Write a name for the article" [(ngModel)]="articleName" name="name" required><br>

                    <label for="country">{{"RELAB_PAGE.COUNTRY_ORIGIN" | translate}} <span class="obligatory">*</span>:</label>
                    <ng-select
                      [multiple]="false"
                      [closeOnSelect]="true"
                      [searchable]="true"
                      bindLabel="name"
                      placeholder="Select country"
                      [(ngModel)]="articlecountry"
                      id="articlecountry"
                      name="articlecountry"
                      [required]="true">
                      <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
                    </ng-select><br>

                    <label for="category">{{"RELAB_PAGE.ARTICLE_CATEGORY" | translate}} <span class="obligatory">*</span>:</label>
                    <ng-select
                      [multiple]="false"
                      [closeOnSelect]="true"
                      [searchable]="true"
                      bindLabel="name"
                      placeholder="Select category"
                      [(ngModel)]="articlecategory"
                      id="articlecategory"
                      name="articlecategory"
                      [required]="true">
                      <ng-option *ngFor="let category of categories" [value]="category.id">{{category.name}}</ng-option>
                    </ng-select><br>

                    <label for="date">{{"RELAB_PAGE.PUBLICATION_DATE" | translate}} <span class="obligatory">*</span></label>
                    <input type="date" id="date" class="form-control" [(ngModel)]="articleDate" name="date"><br>

                    <label for="author">{{"RELAB_PAGE.AUTHOR_ARTICLE" | translate}} <span class="obligatory">*</span></label>
                    <input type="text" id="author" class="form-control" placeholder="Name of the author" [(ngModel)]="articleAuthor" name="author"><br>

                    <label for="short_desc">{{"RELAB_PAGE.ARTICLE_SUMMARY" | translate}} (255) <span class="obligatory">*</span></label><br>
                    <textarea id="long_desc" name="long_desc" class="form-control" placeholder="Write the sort description" rows="4" cols="50" maxlength="255" [(ngModel)]="valueShortDescription"></textarea>
                    <div>{{valueShortDescription.length}}/255</div><br>

                    <label for="long_desc">{{"RELAB_PAGE.ARTICLE_LONG_DESCRIPTION" | translate}} (5000) <span class="obligatory">*</span></label><br>
                    <textarea id="long_desc" name="long_desc" class="form-control" placeholder="Write the long description" rows="4" cols="50" maxlength="5000" [(ngModel)]="valueLongDescription"></textarea>
                    <div>{{valueLongDescription.length}}/5000</div><br>

                    <label for="link">Link</label>
                    <input type="text" id="link" class="form-control" placeholder="Webpage link" [(ngModel)]="link" name="link"><br>

                    <label>{{"RELAB_PAGE.ARTICLE_MULTIMEDIA_INFORMATION" | translate}}</label>
                    <div class="form-group">
                      <label for="images" class="form-label">{{"RELAB_PAGE.ARTICLE_MAIN_IMAGE" | translate}} <span class="obligatory">*</span></label>
                      <input class="form-control" type="file" id="images" name="images" (change)="fileImageChangeEvent($event)">
                    </div>

                    <div>
                      <label>{{"RELAB_PAGE.ARTICLE_MORE_MULTIMEDIA" | translate}}</label>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="moreMultimedia">
                        <label class="form-check-label" for="flexRadioMonitoring1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="moreMultimedia">
                        <label class="form-check-label" for="flexRadioMonitoring2">
                          No
                        </label>
                      </div>
                    </div>

                    <br>

                    <div *ngIf="moreMultimedia == true">
                      <label for="images" class="form-label">Multimedia resources</label>

                      <div class="form-group">
                        <label for="images" class="form-label">{{"RELAB_PAGE.ARTICLE_OTHER_IMAGES" | translate}}</label>
                        <input class="form-control" type="file" id="images" name="images" (change)="fileOtherImageChangeEvent($event)" multiple>
                      </div>

                      <div class="form-group">
                        <label for="name">{{"RELAB_PAGE.ARTICLE_VIDEO" | translate}}</label>
                        <input type="text" class="form-control" id="video" name="video" placeholder="Video URL" [(ngModel)]="valueVideoURL">
                      </div>

                      <div class="form-group">
                        <label for="formFile" class="form-label">{{"RELAB_PAGE.ARTICLE_DOCUMENTS" | translate}}</label>
                        <input class="form-control" type="file" name="documents" (change)="fileChangeEvent($event)" multiple>
                      </div>
                    </div>

                    <button role="button" class="btn calculateTyp">
                      <h4>
                        <span> {{"RELAB_PAGE.ARTICLE_SEND" | translate}} &nbsp; <i class="fa fa-bookmark" aria-hidden="true"></i></span>
                      </h4>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

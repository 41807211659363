import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ArticlesModalComponent } from 'src/app/components/articles-modal/articles-modal.component';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { CountryService } from 'src/app/core/country/country.service';
import { ArticlesService } from 'src/app/core/re-hub/articles.service';
import { Article } from 'src/app/shared/models/article';
import { CategoryArticle } from 'src/app/shared/models/categoryArticle';
import { Country } from 'src/app/shared/models/country';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { UserAllData } from 'src/app/shared/models/userAllData';

@Component({
  selector: 'app-my-articles',
  templateUrl: './my-articles.component.html',
  styleUrls: ['./my-articles.component.scss']
})
export class MyArticlesComponent implements OnInit, OnDestroy {

  @Input() user: UserAllData = null;

  window: number = 1;

  getArrayArticle: Subscription = null;
  getArrayCategoryArticle: Subscription = null;
  getArrayCountry: Subscription = null;


  arrayArticle: Article[] = [];
  tmpArrayArticle: Article[] = [];

  arrayCategoryArticle: CategoryArticle[] = [];
  arrayCountry: Country[] = [];

  adminComment: string = '';
  valueName: string = '';
  valueCountry: number = null;
  valueCategory: number = null;
  valueDate: string = '';
  valueAuthor: string = '';
  valueShortDescription: string = '';
  valueLongDescription: string = '';
  valueLink: string = '';
  valueMainImage: any = null;
  valueOtherImages: any[] = [];
  valueDocuments: any[] = [];
  valueVideoURL: string = '';

  isMoreMultimedia: boolean = false;

  nameMainImage: string = '';
  nameAdditionalImages: string = '';
  nameAdditionalDocuments: string = '';

  isAdditionalImagesChange: boolean = false;
  isPdfFilesChange: boolean = false;

  articleSelected: Article = null;

  lengthShortDescription: number = 0;
  lengthLongDescription: number = 0

  page: number = 1;
  pageSize: number = 10;

  modalRef: BsModalRef;

  constructor(private articleService: ArticlesService, private administrationPanelService: AdministrationPanelService, private countryService: CountryService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getArticles();
    this.getCategories();
    this.getCountries();
  }

  ngOnDestroy(): void {
    if(this.getArrayArticle != null) this.getArrayArticle.unsubscribe();
    if(this.getArrayCategoryArticle != null) this.getArrayCategoryArticle.unsubscribe();
    if(this.getArrayCountry != null) this.getArrayCountry.unsubscribe();
  }

  getArticles(){
    this.articleService.getArticleByUserId(this.user.uid).toPromise().then((data: NodeResponse) => {
      console.log('articles by user id -> ', data);

      if(data.status){
        this.arrayArticle = data.data;
        this.tmpArrayArticle = data.data;
      }else{
        alert(data.message);
      }
    })
  }

  getCategories(){
    this.getArrayCategoryArticle = this.administrationPanelService.getArrayCategoryArticle$().subscribe((data: CategoryArticle[]) => {
      if(data.length == 0){
        this.articleService.getCategoryArticle().toPromise().then((dataDB: NodeResponse) => {
          console.log('category article data DB -> ', dataDB);
          if(dataDB.status == true){
            this.administrationPanelService.setArrayCategoryArticle(dataDB.data);
            this.arrayCategoryArticle = dataDB.data;
          }
        })
      }else{
        console.log('category article data -> ', data);

        this.arrayCategoryArticle = data;
      }
    })
  }

  getCountries(){
    this.getArrayCountry = this.administrationPanelService.getArrayCountry$().subscribe((data: Country[]) => {

      if(data.length == 0){
        this.countryService.getCountries().toPromise().then((dataDB: NodeResponse) => {
          console.log('countries DB -> ', dataDB);

          if(dataDB.status){
            this.arrayCountry = dataDB.data
            this.administrationPanelService.setArrayCountry(this.arrayCountry);
          }
        })
      }else{
        this.arrayCountry = data
      }
    })
  }

  updateArticle(){
    if(this.isFormFilled()){
      if(confirm('Do you want to update this article?') == true){

        let nameMainImage = this.valueMainImage == null ? this.nameMainImage : this.valueMainImage.name;
        let messageResponse = '';

        let body = {
          article_name: this.valueName,
          country_id: this.valueCountry,
          category_id: this.valueCategory,
          publication_date: this.valueDate,
          author: this.valueAuthor,
          short_description: this.valueShortDescription,
          long_description: this.valueLongDescription,
          main_image: nameMainImage,
          articles_status_id: 3,
          user_id: this.user.uid,
          video: '',
          link: this.valueLink
        }

        if(this.isMoreMultimedia == true) body.video = this.valueVideoURL;

        let formData = new FormData();

        formData.append('article', JSON.stringify(body));
        formData.append('oldArticle', JSON.stringify(this.articleSelected));
        formData.append('image', this.valueMainImage);

        this.articleService.updateArticle(formData).toPromise().then(async (data: NodeResponse) => {
          console.log('data update tool -> ', data)

          if(data.status == true){
            messageResponse = data.message;

            if(this.isMoreMultimedia == true){

              if(this.isAdditionalImagesChange == true){
                for(let i = 0; i < this.valueOtherImages.length; i++){

                  let formDataAdditionalImages = new FormData();

                  formDataAdditionalImages.append('image', this.valueOtherImages[i], this.valueOtherImages[i].name);
                  formDataAdditionalImages.append('articleId', this.articleSelected.id + '');
                  let isFirst = i == 0 ? true : false;
                  formDataAdditionalImages.append('isFirst', isFirst + '');

                  await this.articleService.updateUploadImage(formDataAdditionalImages).toPromise().then((updateImage: NodeResponse) => {
                    console.log("dataArticle node update image -> ", updateImage);

                  })

                }
              }

              if(this.isPdfFilesChange == true){
                for(let i = 0; i < this.valueDocuments.length; i++){
                  let bodyPdf = new FormData();

                  bodyPdf.append('file', this.valueDocuments[i], this.valueDocuments[i].name);
                  bodyPdf.append('articleId', this.articleSelected.id + '');
                  let isFirst = i == 0 ? true : false;
                  bodyPdf.append('isFirst', isFirst + '');

                  await this.articleService.updateUploadDocuments(bodyPdf).toPromise().then((data: NodeResponse) => {
                    console.log("dataArticle node update pdf -> ", data);
                  })

                }
              }

              await this.articleService.getArticleById(this.articleSelected.id).toPromise().then((newData: NodeResponse) => {
                console.log("dataTool DB -> ", newData);

                if(data.status == true){
                  let article = newData.data;
                  let index = this.arrayArticle.findIndex(art => art.id == article.id);

                  this.arrayArticle.splice(index, 1);
                  this.arrayArticle.push(article);
                  this.tmpArrayArticle = [];
                  this.tmpArrayArticle = this.arrayArticle;

                  this.administrationPanelService.getArrayProposedArticle$().subscribe((dataAPS) => {
                    let arrayArticle = dataAPS;
                    let index = arrayArticle.findIndex(art => art.id == article.id);
                    arrayArticle.splice(index, 1);
                    this.administrationPanelService.setArrayProposedArticle(arrayArticle);
                  });

                  alert(messageResponse);
                  this.window = 1;
                }
              })


            }else{

              if(this.articleSelected.articles_images.length > 0){
                this.articleService.deleteAdditionalImagesArticle(this.articleSelected.id).toPromise().then(async (dataImage: NodeResponse) => {
                  console.log("dataImage node delete  -> ", data);
                })

              }

              if(this.articleSelected.articles_documents.length > 0){
                this.articleService.deleteFilesArticle(this.articleSelected.id).toPromise().then(async (dataFile: NodeResponse) => {
                  console.log("dataFile node delete  -> ", data);
                })
              }

              await this.articleService.getArticleById(this.articleSelected.id).toPromise().then((newData: NodeResponse) => {
                console.log("dataTool DB -> ", newData);

                if(data.status == true){
                  let article = newData.data;
                  let index = this.arrayArticle.findIndex(art => art.id == article.id);

                  this.arrayArticle.splice(index, 1);
                  this.arrayArticle.push(article);
                  this.tmpArrayArticle = [];
                  this.tmpArrayArticle = this.arrayArticle;

                  alert(messageResponse);
                  this.window = 1;
                }else{
                  alert(newData.message);
                }
              })

            }

          }else{
            alert(data.message);
          }
        })
      }
    }else{
      alert('You need to fill out whole form')
    }

  }

  deleteArticle(article: Article){
    if(confirm('Do you want to delete this tool?') == true){
      this.articleService.deleteArticle(article).toPromise().then((data: NodeResponse) => {
        console.log('article delete responde -> ', data)

        if(data.status){
          let index = this.arrayArticle.findIndex(e => e.id == article.id);
          this.arrayArticle.splice(index, 1);
          this.tmpArrayArticle = this.arrayArticle;

          this.administrationPanelService.getArrayProposedArticle$().subscribe((data) => {
            let arrayArticle = data;
            let index = arrayArticle.findIndex(art => art.id == article.id);
            arrayArticle.splice(index, 1);
            this.administrationPanelService.setArrayProposedArticle(arrayArticle);
          });

          alert(data.message);
        }else{
          alert(data.message);
        }
      })
    }
  }

  toUpdateArticle(article: Article){
    this.initVariablesForm();

    console.log(article)

    this.articleSelected = article;

    this.adminComment = article.adminComment;
    this.valueName = article.article_name;
    this.valueCountry = article.country_id;
    this.valueCategory = article.category_id;
    this.valueDate = article.publication_date.split('T')[0];
    this.valueAuthor = article.author;
    this.valueShortDescription = article.short_description;
    this.valueLongDescription = article.long_description;

    this.nameMainImage = article.main_image;

    if(article.articles_images.length > 0 && article.articles_documents.length > 0 && article.video.length > 0){

      for(let i = 0; i < article.articles_images.length; i++){
        console.log('estoy dentro del bucle de images additional')
        let name: string =  article.articles_images[i].name;
        this.nameAdditionalImages += name.split('/')[4];

        if(i < article.articles_images.length)  this.nameAdditionalImages += ', '
      }

      for(let i = 0; i < article.articles_documents.length; i++){
        console.log('estoy dentro del bucle de documents')
        let name: string =  article.articles_documents[i].name;
        this.nameAdditionalDocuments += name.split('/')[4];

        if(i < article.articles_documents.length)  this.nameAdditionalDocuments += ', '
      }

      this.valueVideoURL = article.video;
      this.isMoreMultimedia = true;

    }
    this.window = 2;
  }

  initVariablesForm(){

    this.valueName = '';
    this.valueCountry = null;
    this.valueCategory = null;
    this.valueDate = null;
    this.valueAuthor = '';
    this.valueShortDescription = '';
    this.valueLongDescription = '';
    this.valueMainImage = null;
    this.valueOtherImages = [];
    this.valueDocuments = [];
    this.valueVideoURL = '';

    this.isMoreMultimedia = false;

    this.isAdditionalImagesChange = false;
    this.isPdfFilesChange = false;
  }

  fileImageChangeEvent(event: any){

    this.valueMainImage = null;

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.valueMainImage = event.target.files[i];
      }else{
        alert('You must to select a IMAGE');
        this.valueMainImage = null;
      }
    }

  }

  fileOtherImageChangeEvent(event: any){

    let isAllImages = true;
    this.valueOtherImages = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.valueOtherImages.push(event.target.files[i]);
        this.isAdditionalImagesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es una imagen');
        isAllImages = false;
        break;
      }
    }

    if(isAllImages == false){
      this.valueOtherImages = [];
      alert('You must to select IMAGES');
    }
  }

  fileChangeEvent(event: any){

    let isAllDocuments = true;
    this.valueDocuments = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log( 'Archivo seleccionado n' + i + '-> ', event.target.files[i]);

      if(event.target.files[i].type.substring(5) == 'pdf' || event.target.files[i].type == 'application/pdf'){
        this.valueDocuments.push(event.target.files[i]);
        this.isPdfFilesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es un archivo PDF -> ');
        isAllDocuments = false;
        break;
      }
    }

    if(isAllDocuments == false){
      this.valueDocuments = [];
      alert('You must to select PDF FILES');
    }
  }

  shortDescriptionChangeEvent(){
    this.lengthShortDescription = this.valueShortDescription.length;
  }

  longDescriptionChangeEvent(){
    this.lengthLongDescription = this.valueLongDescription.length;
  }

  isFormFilled(): boolean{

    let value = false;
    let value2 = false;

    if(
      this.valueName != '' &&
      this.valueCountry != null &&
      this.valueCategory != null &&
      this.valueDate != null &&
      this.valueAuthor != '' &&
      this.valueShortDescription != '' &&
      this.valueLongDescription != '' &&
      (this.valueMainImage != null || this.nameMainImage != '')

    ){
      value = true;
    }

    if(this.isMoreMultimedia == true){
      if((this.valueOtherImages.length > 0 || this.nameAdditionalImages != '') || (this.valueDocuments.length > 0 || this.nameAdditionalDocuments != '') || this.valueVideoURL != ''){
        value2 = true
      }
    }else{
      value2 = true
    }

    return (value && value2);
  }

  toTable(){
    this.window = 1;
  }

  openModalArticles(article: Article) {
    //this.incrementVisitCount(article.id);
    this.modalRef = this.modalService.show(ArticlesModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { article}});
    this.modalRef.content.closeBtnName = 'Close';
  }


}
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { Building } from 'src/app/shared/models/building';
import { Property } from 'src/app/shared/models/property';
import {DomSanitizer} from '@angular/platform-browser';
import { CadastreESService } from 'src/app/core/cadastre/ES/cadastreES.service';
import {User} from '../../shared/models/user';
import {UserService} from '../../core/authentication/user.service';
import { ceeBuilding } from 'src/app/shared/models/ceeBuilding';
import { CeeService } from '../../core/wms/cee.service';
import { isNumber } from 'highcharts';
import { image } from 'html2canvas/dist/types/css/types/image';
import { async } from '@angular/core/testing';
import { BestPracticesService } from 'src/app/core/binding-data/best-practices/best-practices.service';
import { isNull, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Coordinates } from 'src/app/shared/models/coordinates';
import { element } from 'protractor';
import { CeeBuilding } from '../../shared/models/ceeBuilding';
import { Monitoring } from '../../shared/models/monitoring';
import { Testimony } from '../../shared/models/testimony';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { Subscription } from 'rxjs';
import { NodeResponse } from 'src/app/shared/models/node_response';

@Component({
  selector: 'app-form-cee',
  templateUrl: './form-cee.component.html',
  styleUrls: ['./form-cee.component.scss'],

})
export class FormCeeComponent implements OnInit, OnDestroy {

  @Output() optionEmitter = new EventEmitter<string>();
  @Output() optionSelectecEmitter = new EventEmitter<number>();

  idGenerator: number = 0;

  arrayFamily: any[] = [];
  arrayCase: any[] = [];
  arrayVariable: any[] = [];
  arrayTipology: any[] = [];
  arraySeason: any[] = [];
  arrayPhase: any[] = [];

  getArrayFamily: Subscription = null;
  getArrayCase: Subscription = null;
  getArrayVariable: Subscription = null;
  getArrayTipology: Subscription = null;
  getArraySeason: Subscription = null;
  getArrayPhase: Subscription = null;

  arrayTestimony: any[] = [];

  dataNumberCertificate: any;
  dataCalificationCEE: any;
  dataCalificationEnergyPrimary: any;
  dataEnergyConsumed: number = 0;
  dataCo2Emissions: number = 0;
  dataCadastreReference: any;
  //dataBuildingAddress: string = '';
  dataBuildingYear: number = null;
  dataCo2EmissionsCertificate2: number = 0;
  dataEnergyConsumedCertificate2: number = 0;
  dataNumberCertificate2: number;
  dataCalificationEnergyPrimaryCertificate2: any;
  dataCalificationCO2EmissionsCertificate2: any;
  dataCO2Absolute: number = 0;
  dataEnergyAbsolute: number = 0;
  isFavoriteBuilding: boolean;

  urlImage: any;

  idCeeBuilding: number;
  valueCountry: string = '';

  coordinateLong: any;
  coordinateLat: any;

  coordinateX: any;
  coordinateY: any;

  imageFacade: any;

  lengthBuildingDescription: number = 0;
  lengthInterventionDescription: number = 0;
  lengthCommentMonitoring: number[] = [];

  //pagination
  switchFormCount: number = 1;
  totalFormCount: number = 5;

  //variables first form
  valueTipology: any = {
    code: null,
    name: null
  };
  valueFlexRadioCase: number = null;
  isCaseVisible: boolean = false;
  isIdentificationDwellingVisible: boolean = false;
  valueIdentificacionDwelling: string = null;
  dataAddressMap: string = null;

  valueSavingCo2: number = null;
  valueSavingEnergyPrimaryPercent: number = 0;

  //variables second form
  valueActiveNormative: any = null;
  valueYearReform: number = null;
  valueTypeReform: any = null;
  valueNumberFloors: number = null;
  valueNumberDwellings: number = null;
  valueNumberLocales: any = null;
  valueAreaFloor: number = null;
  valueBuildArea: number = null;
  valueFlexRadioProtected: boolean = null;
  valueConstructionQuality: number = null;
  valueBuildingDescription: string = null;
  valueFlexRadioWindows: boolean = null;
  valueSurround: boolean = null;
  valuePhotovoltaic: boolean = null;
  valueAcs: boolean = null;
  valueAerothermal: boolean = null;
  valueInterventionDescription: string = null;
  valueInvestment: string = null;
  valueSubsidies: string = null;
  valueInvestmentNumber: number = 0;
  valueSubsidiesNumber: number = 0;
  valueRenovationPictures: any[] = [];
  isRenovationPicturesChange: boolean = false;

  //variables third form
  valueInvestmentM2: number = 0;
  valueSubsidiesPercent: number = 0;
  valueFinalInvestment: number = 0;
  valueFinalInvestmentm2: number = 0;
  valueFinalInvestmentMonth: number = 0;
  valuePotSubsidies: number = 0;
  valuePotSubsidiesPercent: number = 0;
  valuePotFinalInvestment: number = 0;
  valuePotFinalInvestmentm2: number = 0;
  valuePotFinalInvestmentMonth: number = 0;
  valueDwellingInvestment: number = 0;
  valueInvestmentDwelling: number = 0;
  valueSubsidiesDwelling: number = 0;
  valueInvestmentDwellingm2: number = 0;
  valueInvestmentDwellingPercent: number = 0;
  valueFinalInvestmentDwelling: number = 0;
  valueFinalInvestmentDwellingm2: number = 0;
  valueFinalInvestmentDwellingMonth: number = 0;
  valuePotSubsidiesDwelling: number = 0 ;
  valuePotSubsidiesDwellingPercent: number = 0;
  valuePotFinalInvestmentDwelling: number = 0;
  valuePotFinalInvestmentDwellingm2: number = 0;
  valuePotFinalInvestmentDwellingMonth: number = 0;

  //variables fourth form
  valueFlexRadioTestimony: boolean = false;
  valueName: string;
  valueRoleName: string;
  valueQuote: string;
  valueVideoURL: any;
  imagesTestimony: any[] = [];
  imagesTestimonyName: string = '';
  isAllImages: boolean = true;

  //variables five form
  valueFlexRadioMonitoring: boolean = false;
  valueCadastralreferenceunit: string;
  valueFlexRadioSeason: number; // 1=winter 2=summer
  valueFlexRadioPhase: number; // 1=pre 2=post
  valueFlexRadioFamily: number; // 1=energy 2=comfort 3=health
  valueLocation: boolean;
  fileMonitoring: any = null;
  isFile: boolean = false;
  valueInputNumber: number = 0;
  absoluteInputNumber: number = 0;
  valueCurrentIntputNumber: number = 0;
  formFilesArray: any[] = [];
  csvFiles: any[] = [];
  guid: string = "";
  isCsvFileChange: boolean[] = [];

  //conditions -> complete building or single home
  isCompleteBuilding: boolean = false;
  completeBuilding: any = null;
  doubleCeeCompleteBuilding: any = null;
  isDoubleCeeCompleteBuilding: boolean = false;

  buildingData: any = null;
  arraySingleHome: any[] = [];
  arrayAddressSingleHome: string[] = [];
  arrayRefReferenciaSingleHome: string[] = [];
  isDoubleCeeSingleHome: boolean = false;

  arrayDoubleCEE: Map<string, object> = new Map();
  doubleCEE: any = null;

  coordinates: Coordinates = {
    coordinateX: null,
    coordinateY: null,
    coordinateLong: null,
    coordinateLat: null
  };

  //booleans readonly
  isYearCertificate2: boolean = true;

  //validate video url
  regexYoutube: RegExp = new RegExp('^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$');
  regexMega: RegExp = new RegExp('https:\/\/mega\.nz\/file\/');
  regexVimeo: RegExp = new RegExp('(http|https)?:\/\/(www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)');
  regexDriveGoogle: RegExp = new RegExp('https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?');

  arrayRegex: RegExp[] = [this.regexYoutube, this.regexMega, this.regexVimeo, this.regexDriveGoogle];

  @Input() isUpdateCeeBuilding: boolean = false;
  @Input() ceeBuildingToUpdate: CeeBuilding = null;

  isThereWasRenovationPictures: boolean = false;
  renovationPicturesName: string = '';

  isThereWasTestimony: boolean;
  valueIdTestimony: number;

  isThereWasMonitoring: boolean;
  backUpArrayMonitoring: Monitoring[] = [];

  backUpArrayMonitoringHealth: any[] = [];

  isFilesFromDB: boolean[] = [];
  isFileAlready: string = 'There is a file uploaded';

  isSpainCountry: boolean = false;

  statusToCeeBuilding: number = 0;

  //temporalVariables

  arrayStressor: any[] = [
    {id: 10, name: 'Dry air'},
    {id: 2, name: 'Moist'},
    {id: 3, name: 'Noise'},
    {id: 4, name: 'Stuffy air'},
    {id: 5, name: 'Drafts'},
    {id: 6, name: 'Odour'},
    {id: 7, name: 'Dust'},
    {id: 8, name: 'Gloom'},
    {id: 9, name: 'Glare'}
  ]

  arraySymptoms: any[] = [
    {id: 1, name: 'Repiratory problems'},
    {id: 2, name: 'Eye irritation'},
    {id: 3, name: 'Sneezing or stuffy nose'},
    {id: 4, name: 'Headache'},
    {id: 5, name: 'Dry throat'},
    {id: 6, name: 'Fatigue or tiredness'}
  ]

  arrayThermalComfort: any[] = [
    {id: 1, name:'Hot'},
    {id: 2, name:'Warm'},
    {id: 3, name:'Slightly warm'},
    {id: 4, name:'Neutral'},
    {id: 5, name:'Slightly cool'},
    {id: 6, name:'Cool'},
    {id: 7, name:'Cold'}
  ]

  arrayActivity: any = [
    {id: 1, name:'Sedentary'},
    {id: 2, name:'Moderate'},
    {id: 3, name:'Active'},
    {id: 4, name:'Intense'},
  ]

  arrayClothing: any = [
    {id: 1, name:'None'},
    {id: 2, name:'Light'},
    {id: 3, name:'Medium'},
    {id: 4, name:'Heavy'},
  ]

  textHealth: string = null;

  @Output() newCeeBuildingEmitter = new EventEmitter<CeeBuilding>();

  constructor
  (
    private sanitizer: DomSanitizer,
    private cadastreServiceES: CadastreESService,
    private userService: UserService,
    private ceeService: CeeService,
    private bestPracticesService: BestPracticesService,
    private cadastreService: CadastreESService,
    private administrationPanelService: AdministrationPanelService
  ) {}

  async ngOnInit() {

    this.initValues();

    this.getFamily();
    this.getCase();
    this.getPhase();
    this.getVariable();
    this.getSeason();
    this.getTipology();

    if(this.isUpdateCeeBuilding == false){
      this.bestPracticesService.getCountry$().subscribe((data: string) => {
        switch(data){
          case 'ES':
            this.getValuesES();
            this.isSpainCountry = true;
            break;
          case 'NL':
            this.getValuesNL();
            break;
          default:
            this.bestPracticesService.getAddressMap$().subscribe((data: any) => this.dataAddressMap = data);
            this.bestPracticesService.getcoordinates$().subscribe((data: Coordinates) => this.coordinates = data);
            this.administrationPanelService.getGuid$().subscribe((data: any) => this.guid = data);
            break;
        }
      })
    }

    if(this.isUpdateCeeBuilding == true){
      console.log('actualisando el sistema -> ', this.ceeBuildingToUpdate);
      await this.setValuesToUpdateCeeBuilding();
    }

    this.administrationPanelService.getRoleCurrentUser$().subscribe(data => {
      console.log('role user => ', data);

      if(data == 1 || data == 2){
        this.statusToCeeBuilding = 1;
      }else if(data == 3 || data == 4){
        this.statusToCeeBuilding = 3;
      }

      console.log('status ceebuilding => ', this.statusToCeeBuilding);

    })

  }

  ngOnDestroy(): void {
    if(this.getArrayCase != null) this.getArrayCase.unsubscribe();
    if(this.getArrayFamily != null) this.getArrayFamily.unsubscribe();
    if(this.getArraySeason != null) this.getArraySeason.unsubscribe();
    if(this.getArrayPhase != null) this.getArrayPhase.unsubscribe();
    if(this.getArrayTipology != null) this.getArrayTipology.unsubscribe();
    if(this.getArrayVariable != null) this.getArrayVariable.unsubscribe();

  }

  getFamily(){
    this.getArrayFamily = this.bestPracticesService.getFamily$().subscribe(data => {
      if(data.length == 0){
        this.ceeService.getFamily().toPromise().then((dataDB: NodeResponse) => {
          console.log('data DB family -> ', dataDB);

          if(dataDB.status == true){
            this.arrayFamily = dataDB.data;
            this.bestPracticesService.setFamily(dataDB.data);
          }
        })
      }else{
        console.log('data family -> ', data);
        this.arrayFamily = data;
      }
    })
  }

  getCase(){
    this.getArrayCase = this.bestPracticesService.getCase$().subscribe(data => {
      if(data.length == 0){
        this.ceeService.getCase().toPromise().then((dataDB: NodeResponse) => {
          console.log('data DB Case -> ', dataDB);

          if(dataDB.status == true){
            this.arrayCase = dataDB.data;
            this.bestPracticesService.setCase(dataDB.data);
          }
        })
      }else{
        this.arrayCase = data;
        console.log('data Case -> ', data);
      }
    })
  }

  getPhase(){
    this.getArrayPhase = this.bestPracticesService.getPhase$().subscribe(data => {
      if(data.length == 0){
        this.ceeService.getPhase().toPromise().then((dataDB: NodeResponse) => {
          console.log('data DB Phase -> ', dataDB);

          if(dataDB.status == true){
            this.arrayPhase = dataDB.data;
            this.bestPracticesService.setPhase(dataDB.data);
          }
        })
      }else{
        this.arrayPhase = data;
        console.log('data Phase -> ', data);
      }
    })
  }

  getVariable(){
    this.getArrayVariable = this.bestPracticesService.getVariable$().subscribe(data => {
      if(data.length == 0){
        this.ceeService.getVariable().toPromise().then((dataDB: NodeResponse) => {
          console.log('data DB Variable -> ', dataDB);

          if(dataDB.status == true){
            this.arrayVariable = dataDB.data;
            this.bestPracticesService.setVariable(dataDB.data);
          }
        })
      }else{
        this.arrayVariable = data;
        console.log('data Variable -> ', data);
      }
    })
  }

  getSeason(){
    this.getArraySeason = this.bestPracticesService.getSeason$().subscribe(data => {
      if(data.length == 0){
        this.ceeService.getSeason().toPromise().then((dataDB: NodeResponse) => {
          console.log('data DB Season -> ', dataDB);

          if(dataDB.status == true){
            this.arraySeason = dataDB.data;
            this.bestPracticesService.setSeason(dataDB.data);
          }
        })
      }else{
        this.arraySeason = data;
        console.log('data Season -> ', data);
      }
    })
  }

  getTipology(){
    this.getArrayTipology = this.bestPracticesService.getTipology$().subscribe(data => {
      if(data.length == 0){
        this.ceeService.getTipology().toPromise().then((dataDB: NodeResponse) => {
          console.log('data DB Tipology -> ', dataDB);

          if(dataDB.status == true){
            this.arrayTipology = dataDB.data;
            this.bestPracticesService.setTipology(dataDB.data);
          }
        })
      }else{
        this.arrayTipology = data;
        console.log('data Tipology -> ', data);
      }
    })
  }

  async setValuesToUpdateCeeBuilding(){

    this.administrationPanelService.getGuid$().subscribe((data: any) => {
      this.guid = data;
    })

    this.idCeeBuilding = this.ceeBuildingToUpdate.id;

    //this.dataBuildingAddress = this.ceeBuildingToUpdate.address; //sustituir variables
    this.dataAddressMap = this.ceeBuildingToUpdate.map_address;
    this.dataCadastreReference = this.ceeBuildingToUpdate.rc;
    this.valueTipology.code = this.ceeBuildingToUpdate.tipology_id;
    this.valueFlexRadioCase = this.ceeBuildingToUpdate.case_id;
    this.dataBuildingYear = this.ceeBuildingToUpdate.year;

    this.imageFacade = this.ceeBuildingToUpdate.facade_picture;
    this.urlImage = this.sanitizer.bypassSecurityTrustUrl(this.imageFacade);

    this.dataNumberCertificate = this.ceeBuildingToUpdate.year_certificate
    this.dataCalificationCEE = this.ceeBuildingToUpdate.letter_co2;
    this.dataCo2Emissions = this.ceeBuildingToUpdate.value_co2;
    this.dataCalificationEnergyPrimary = this.ceeBuildingToUpdate.letter_ep;
    this.dataEnergyConsumed = this.ceeBuildingToUpdate.value_ep;
    this.dataNumberCertificate2 = this.ceeBuildingToUpdate.year_certificate2;
    this.dataCalificationCO2EmissionsCertificate2 = this.ceeBuildingToUpdate.letter_co2_cert2;
    this.dataCo2EmissionsCertificate2 = this.ceeBuildingToUpdate.value_co2_cert2;
    this.dataCalificationEnergyPrimaryCertificate2 = this.ceeBuildingToUpdate.letter_ep_cert2
    this.dataEnergyConsumedCertificate2 = this.ceeBuildingToUpdate.value_ep_cert2;
    this.dataCO2Absolute = this.ceeBuildingToUpdate.saving_co2_abs;
    this.valueSavingCo2 = this.ceeBuildingToUpdate.saving_co2_percent;
    this.dataEnergyAbsolute = this.ceeBuildingToUpdate.saving_ep_abs;
    this.valueSavingEnergyPrimaryPercent = this.ceeBuildingToUpdate.saving_ep_percent;
    this.valueYearReform = this.ceeBuildingToUpdate.reform_year;
    this.valueNumberFloors = this.ceeBuildingToUpdate.number_floors;
    this.valueNumberDwellings = this.ceeBuildingToUpdate.number_dwellings;
    this.valueAreaFloor = this.ceeBuildingToUpdate.land_surface;
    this.valueBuildArea = this.ceeBuildingToUpdate.building_surface;
    this.valueFlexRadioProtected = this.ceeBuildingToUpdate.heritage_building;
    this.valueBuildingDescription = this.ceeBuildingToUpdate.building_description;
    this.valueFlexRadioWindows = this.ceeBuildingToUpdate.windows;
    this.valueSurround = this.ceeBuildingToUpdate.wall;
    this.valuePhotovoltaic = this.ceeBuildingToUpdate.photovoltaic;
    this.valueAcs = this.ceeBuildingToUpdate.shw;
    this.valueAerothermal = this.ceeBuildingToUpdate.aerothermal;
    this.valueInterventionDescription = this.ceeBuildingToUpdate.intervention_description;
    this.valueInvestment = this.ceeBuildingToUpdate.investment + '';
    this.valueSubsidies = this.ceeBuildingToUpdate.renovation_subsidies + '';

    this.valueInvestmentM2 = this.ceeBuildingToUpdate.investment_square_meter;
    this.valueSubsidiesPercent = this.ceeBuildingToUpdate.subsidies_percent;
    this.valueFinalInvestment = this.ceeBuildingToUpdate.final_investment;
    this.valueFinalInvestmentm2 = this.ceeBuildingToUpdate.final_investment_sqmeters;
    this.valueFinalInvestmentMonth = this.ceeBuildingToUpdate.investment_permonth;
    this.valuePotSubsidies = this.ceeBuildingToUpdate.potential_subsidies;
    this.valuePotSubsidiesPercent = this.ceeBuildingToUpdate.potential_subsidies_percent;
    this.valuePotFinalInvestment = this.ceeBuildingToUpdate.potential_final_investment;
    this.valuePotFinalInvestmentm2 = this.ceeBuildingToUpdate.potential_final_investment_m2;
    this.valuePotFinalInvestmentMonth = this.ceeBuildingToUpdate.potential_final_inv_month;
    this.valueInvestmentDwelling = this.ceeBuildingToUpdate.investment_dwelling;
    this.valueSubsidiesDwelling = this.ceeBuildingToUpdate.subsidies_dwelling;
    this.valueInvestmentDwellingPercent = this.ceeBuildingToUpdate.subsidies_percent_dwelling;
    this.valueFinalInvestmentDwelling = this.ceeBuildingToUpdate.final_investment_dwelling;
    this.valueFinalInvestmentDwellingm2 = this.ceeBuildingToUpdate.final_inv_sqmeters_dwellings;
    this.valueFinalInvestmentDwellingMonth = this.ceeBuildingToUpdate.inv_permonth_dwellings;
    this.valuePotSubsidiesDwelling = this.ceeBuildingToUpdate.potential_subsidies_dw;
    this.valuePotSubsidiesDwellingPercent = this.ceeBuildingToUpdate.potential_subsidies_percent_dw;
    this.valuePotFinalInvestmentDwelling = this.ceeBuildingToUpdate.potential_final_investment_dw;
    this.valuePotFinalInvestmentDwellingm2 = this.ceeBuildingToUpdate.potential_final_inv_m2_dw;
    this.valuePotFinalInvestmentDwellingMonth = this.ceeBuildingToUpdate.potential_final_inv_month_dw;
    this.valueIdentificacionDwelling = this.ceeBuildingToUpdate.dwelling_id;
    this.valueTypeReform = this.ceeBuildingToUpdate.type_reform;
    this.valueVideoURL = this.ceeBuildingToUpdate.video_testimony;

    switch(this.ceeBuildingToUpdate.letter_co2){
      case 1:
        this.dataCalificationCEE = "A";
        break;
      case 2:
        this.dataCalificationCEE = "B";
        break;
      case 3:
        this.dataCalificationCEE = "C";
        break;
      case 4:
        this.dataCalificationCEE = "D";
        break;
      case 5:
        this.dataCalificationCEE = "E";
        break;
      case 6:
        this.dataCalificationCEE = "F";
        break;
      case 7:
        this.dataCalificationCEE = "G";
        break;
      case null:
        this.dataCalificationCEE = "";
        break;
    }

    switch(this.ceeBuildingToUpdate.letter_ep){
      case 1:
        this.dataCalificationEnergyPrimary = "A";
        break;
      case 2:
        this.dataCalificationEnergyPrimary = "B";
        break;
      case 3:
        this.dataCalificationEnergyPrimary = "C";
        break;
      case 4:
        this.dataCalificationEnergyPrimary = "D";
        break;
      case 5:
        this.dataCalificationEnergyPrimary = "E";
        break;
      case 6:
        this.dataCalificationEnergyPrimary = "F";
        break;
      case 7:
        this.dataCalificationEnergyPrimary = "G";
        break;
      case null:
        this.dataCalificationEnergyPrimary = "";
        break;
    }

    switch(this.ceeBuildingToUpdate.letter_co2_cert2){
      case 1:
        this.dataCalificationCO2EmissionsCertificate2 = "A";
        break;
      case 2:
        this.dataCalificationCO2EmissionsCertificate2 = "B";
        break;
      case 3:
        this.dataCalificationCO2EmissionsCertificate2 = "C";
        break;
      case 4:
        this.dataCalificationCO2EmissionsCertificate2 = "D";
        break;
      case 5:
        this.dataCalificationCO2EmissionsCertificate2 = "E";
        break;
      case 6:
        this.dataCalificationCO2EmissionsCertificate2 = "F";
        break;
      case 7:
        this.dataCalificationCO2EmissionsCertificate2 = "G";
        break;
      case null:
        this.dataCalificationCO2EmissionsCertificate2 = "";
        break;
    }

    switch(this.ceeBuildingToUpdate.letter_ep_cert2){
      case 1:
        this.dataCalificationEnergyPrimaryCertificate2 = "A";
        break;
      case 2:
        this.dataCalificationEnergyPrimaryCertificate2 = "B";
        break;
      case 3:
        this.dataCalificationEnergyPrimaryCertificate2 = "C";
        break;
      case 4:
        this.dataCalificationEnergyPrimaryCertificate2 = "D";
        break;
      case 5:
        this.dataCalificationEnergyPrimaryCertificate2 = "E";
        break;
      case 6:
        this.dataCalificationEnergyPrimaryCertificate2 = "F";
        break;
      case 7:
        this.dataCalificationEnergyPrimaryCertificate2 = "G";
        break;
      case null:
        this.dataCalificationEnergyPrimaryCertificate2 = "";
        break;
    }

    await this.ceeService.getRenovationPicturesIdCeeBuilding(this.ceeBuildingToUpdate.id).toPromise().then((data: NodeResponse) => {
      console.log('renovation pictures -> ', data)

      if(data.data.length == 0){
        this.isThereWasRenovationPictures = false;
      }else{
        for(let i = 0; i < data.data.length; i++){
          let name: string =  data.data[i].name;
          this.renovationPicturesName += name.split('/')[4];

          if(i != (data.data.length - 1))  this.renovationPicturesName += ', '
        }

        this.isThereWasRenovationPictures = true;

      }
    })

    try{
      await this.ceeService.getTestimonyIdCeeBuilding(this.ceeBuildingToUpdate.id).toPromise().then((data: any[]) => {

        console.log("testimony get update -> ",data)


        if(data.length == 0){
          this.valueFlexRadioTestimony = false;
          this.isThereWasTestimony = false;
        }else{

          for(let i = 0; i < data.length; i++){

            this.arrayTestimony.push({id: null, name: null, role: null, quote: null});

            this.arrayTestimony[i].id = data[i].id;

            this.arrayTestimony[i].name = data[i].name;
            this.arrayTestimony[i].role = data[i].role;
            this.arrayTestimony[i].quote = data[i].quote;
          }

          this.valueFlexRadioTestimony = true;
          this.isThereWasTestimony = true;

          this.ceeService.getImagesIdCeeBuilding(this.ceeBuildingToUpdate.id).toPromise().then((data: any[]) => {

            console.log('images testimony update -> ' , data)

            if(data != null && data.length > 0){
              for(let i = 0; i < data.length; i++){
                let name: string =  data[i].name;
                this.imagesTestimonyName += name.split('/')[5];

                if(i < data.length)  this.imagesTestimonyName    += ', '
              }
            }
          })
        }
      });
    }catch(e){
      console.error('fallo al recoger el testimony -> ', e);
    }

    try{
      await this.ceeService.getMonitoringIdCeeBuilding(this.ceeBuildingToUpdate.id).toPromise().then(async (data: Monitoring[]) => {
        console.log('monitoring data -> ', data)
        if(data.length == 0){
          this.isThereWasMonitoring = false;
        }else{
          this.formFilesArray = data;
          this.backUpArrayMonitoring = data;
          for(let i = 0; i < this.formFilesArray.length; i ++){
            this.isCsvFileChange.push(false);

            this.idGenerator++;
            this.formFilesArray[i].idg = this.idGenerator;

            if(this.formFilesArray[i].comment != null)
              this.lengthCommentMonitoring.push(this.formFilesArray[i].comment.length);

            if(this.formFilesArray[i].family_id == 3){
              await this.ceeService.getMonitoringHealthByIdMonitoring(this.formFilesArray[i].id).toPromise().then((dataHealth: NodeResponse) => {
                console.log('data monitoring health -> ', dataHealth);

                if(dataHealth.status == true){
                  this.isFilesFromDB.push(false)
                  this.csvFiles.push(null)
                  this.initRelationHealthId(i, dataHealth.data);
                }
              })
            }else{

              //for(let i = 0; i < this.formFilesArray.length; i++){
                await this.ceeService.getFileIdMonitoring(this.formFilesArray[i].id).toPromise().then((data: any) => {
                  console.log('files de la base de dates -> ', data);
                  if(data.length > 0){
                    // hacer array para que aparezca un mensaje en el monitoring. Aunque no lo hago, no vaya ser que el file no sea obligatorio
                    this.isFilesFromDB.push(true);
                    this.csvFiles.push(null)

                  }
                })
              //}
            }
          }
          this.valueFlexRadioMonitoring = true;
          console.log("monitoring data de la base de datos", this.formFilesArray);

          this.isThereWasMonitoring = true;
        }
      })
    }catch(e){
      console.error('fallo al recoger el monitoring -> ', e);
    }

    /*try{
      for(let i = 0; i < this.formFilesArray.length; i++){
        await this.ceeService.getFileIdMonitoring(this.formFilesArray[i].id).toPromise().then((data: any) => {
          console.log('files de la base de dates -> ', data);
        })
      }

      console.log('el array de files -> ', this.isFilesFromDB);
    }catch(e){
      console.error('fallo al recoger el file -> ', e);

    }*/

  }

  getValuesES(): any{

    this.buildingData = null;
    this.isCompleteBuilding = false;
    this.doubleCeeCompleteBuilding = null;
    this.arraySingleHome = [];
    this.arrayAddressSingleHome = [];

    this.isDoubleCeeCompleteBuilding = false;
    this.isDoubleCeeSingleHome = false;
    this.arrayDoubleCEE.clear();

    this.bestPracticesService.getCountry$().subscribe(data => {
      this.valueCountry = data;
    })

    this.bestPracticesService.getImageFacade$().subscribe((data: any) => {
      this.imageFacade = data;

      this.urlImage = this.sanitizer.bypassSecurityTrustUrl(this.imageFacade);
    })

    this.bestPracticesService.getcoordinates$().subscribe((data: Coordinates) => this.coordinates = data);
    this.bestPracticesService.getCadastreReference$().subscribe((data: string) => this.dataCadastreReference = data);

    this.administrationPanelService.getGuid$().subscribe((data: string) => this.guid = data);
    this.bestPracticesService.getYearConstruction$().subscribe((data: any) => this.dataBuildingYear = data);
    this.bestPracticesService.getAddressMap$().subscribe((data: any) => this.dataAddressMap = data);

    this.bestPracticesService.getIsCompleteBuilding$().subscribe((data: boolean) => this.isCompleteBuilding = data);

    if(this.isCompleteBuilding){
      this.bestPracticesService.getCompleteBuilding$().subscribe((data: any) => {
        this.completeBuilding = data

        this.dataNumberCertificate = this.completeBuilding.getElementsByTagName('codigo')[0].textContent.substring(1, 5);
        this.dataCalificationCEE = this.completeBuilding.getElementsByTagName('cer_emicalificacion')[0].textContent;
        this.dataEnergyConsumed = this.completeBuilding.getElementsByTagName('cer_contotal')[0].textContent;
        this.dataCo2Emissions = this.completeBuilding.getElementsByTagName('cer_emitotal')[0].textContent;
        this.dataCalificationEnergyPrimary = this.completeBuilding.getElementsByTagName('cer_concalificacion')[0].textContent;
        //this.dataBuildingAddress = this.completeBuilding.getElementsByTagName('exp_direccion')[0].textContent;
        this.valueIdentificacionDwelling = this.completeBuilding.getElementsByTagName('ref_referencia')[0].textContent;

        this.bestPracticesService.getDoubleCEECompleteBuilding$().subscribe((data: any) => {
          this.doubleCeeCompleteBuilding = data;
          console.log("doble certificacion complete building -> ", this.doubleCeeCompleteBuilding)

          if(this.doubleCeeCompleteBuilding != null && this.doubleCeeCompleteBuilding.getElementsByTagName('reference')[0].textContent == this.dataCadastreReference){
            this.dataNumberCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('codigo')[0].textContent.substring(1, 5);
            this.dataCalificationCO2EmissionsCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('cer_emicalificacion')[0].textContent;
            this.dataCo2EmissionsCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('cer_emitotal')[0].textContent;
            this.dataCalificationEnergyPrimaryCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('cer_concalificacion')[0].textContent;
            this.dataEnergyConsumedCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('cer_contotal')[0].textContent;

            this.dataCO2Absolute = Math.round((this.dataCo2Emissions - this.dataCo2EmissionsCertificate2) * 100) / 100;
            this.dataEnergyAbsolute =  Math.round((this.dataEnergyConsumed - this.dataEnergyConsumedCertificate2) * 100) / 100;
            this.valueSavingCo2 = Math.round(((1 - (this.dataCo2EmissionsCertificate2 / this.dataCo2Emissions)) * 100) * 100) / 100;
            this.valueSavingEnergyPrimaryPercent = Math.round(((1 - (this.dataEnergyConsumedCertificate2 / this.dataEnergyConsumed)) * 100) * 100) / 100;

            this.isDoubleCeeCompleteBuilding = true;
          }
        })
      });

      this.bestPracticesService.getArraySingleHome$().subscribe((data: any) => {
        this.arraySingleHome = data

        for(let i = 0; i < this.arraySingleHome.length; i++){
          this.arrayAddressSingleHome.push(this.arraySingleHome[i].getElementsByTagName('exp_direccion')[0].textContent);
          this.arrayRefReferenciaSingleHome.push(this.arraySingleHome[i].getElementsByTagName('ref_referencia')[0].textContent);
        }

        this.bestPracticesService.getArrayDoubleCEE$().subscribe((data: Map<string, object>) => {
          if(data.size > 0){
            this.arrayDoubleCEE = data;
            console.log("doble certificacion array single home -> ", this.arrayDoubleCEE.get(this.valueIdentificacionDwelling));

          }
        })
      });

      this.valueFlexRadioCase = 1;

    }else{
      this.bestPracticesService.getBuilding$().subscribe((data: any) => {

        /*let element = document.getElementsByName('ceeyearconstructionJorge');
        console.log("elemento undefined -> ", element[0].classList)*/

        if(data != undefined){
          this.buildingData = data;

          console.log("primer certificado -> ", this.buildingData);

          this.dataNumberCertificate = this.buildingData.getElementsByTagName('codigo')[0].textContent.substring(1, 5);
          this.dataCalificationCEE = this.buildingData.getElementsByTagName('cer_emicalificacion')[0].textContent;
          this.dataEnergyConsumed = this.buildingData.getElementsByTagName('cer_contotal')[0].textContent;
          this.dataCo2Emissions = this.buildingData.getElementsByTagName('cer_emitotal')[0].textContent;
          this.dataCalificationEnergyPrimary = this.buildingData.getElementsByTagName('cer_concalificacion')[0].textContent;
          //this.dataBuildingAddress = this.buildingData.getElementsByTagName('exp_direccion')[0].textContent;
          this.valueIdentificacionDwelling = this.buildingData.getElementsByTagName('ref_referencia')[0].textContent;

          this.bestPracticesService.getDoubleCEE$().subscribe((data: any) => {
            this.doubleCEE = data;
            console.log("doble certificado -> ", this.doubleCEE);

            if(
                data != null &&
                this.doubleCEE.getElementsByTagName('ref_referencia')[0].textContent == this.valueIdentificacionDwelling &&
                this.doubleCEE.getElementsByTagName('codigo')[0].textContent.substring(1, 5) != this.dataNumberCertificate
              ){

                this.dataNumberCertificate2 = this.doubleCEE.getElementsByTagName('codigo')[0].textContent.substring(1, 5);
                this.dataCalificationCO2EmissionsCertificate2 = this.doubleCEE.getElementsByTagName('cer_emicalificacion')[0].textContent;
                this.dataCo2EmissionsCertificate2 = this.doubleCEE.getElementsByTagName('cer_emitotal')[0].textContent;
                this.dataCalificationEnergyPrimaryCertificate2 = this.doubleCEE.getElementsByTagName('cer_concalificacion')[0].textContent;
                this.dataEnergyConsumedCertificate2 = this.doubleCEE.getElementsByTagName('cer_contotal')[0].textContent;

                this.dataCO2Absolute = Math.round((this.dataCo2Emissions - this.dataCo2EmissionsCertificate2) * 100) / 100;
                this.dataEnergyAbsolute =  Math.round((this.dataEnergyConsumed - this.dataEnergyConsumedCertificate2) * 100) / 100;
                this.valueSavingCo2 = Math.round(((1 - (this.dataCo2EmissionsCertificate2 / this.dataCo2Emissions)) * 100) * 100) / 100;
                this.valueSavingEnergyPrimaryPercent = Math.round(((1 - (this.dataEnergyConsumedCertificate2 / this.dataEnergyConsumed)) * 100) * 100) / 100;

                this.isDoubleCeeSingleHome = true;
            }else{
              this.isYearCertificate2 = false;
              //console.log("element by id -> ", this.input);

            }
          })
        }else{
          this.valueIdentificacionDwelling = this.dataCadastreReference;
        }
      });

      this.valueFlexRadioCase = 1;
    }

    console.log("data de anterior form -> ", this.buildingData);

    this.bestPracticesService.getParcel$().subscribe((parcel) => {
      const parser = new DOMParser();
      const dataXML = parser.parseFromString(parcel, 'text/xml');
      console.log("catastro parcel -> ", dataXML);
      if ( parcel && parcel.length > 0 ) {
        this.bestPracticesService.getPartParcel$().subscribe((partParcel) =>{
          const parser2 = new DOMParser();
          const dataXML2 = parser2.parseFromString(partParcel, 'text/xml');
          console.log("catastro partParcel -> ", dataXML2);
          this.bestPracticesService.getCp$().subscribe((cp) => {
            const parser3 = new DOMParser();
            const dataXML3 = parser3.parseFromString(cp, 'text/xml');
            console.log("catastro cp -> ", dataXML3);

            const infoFromParcel = this.getInfoFromParcel(parcel);
            const infoFromCadastralParcel = this.getInfoFromINSPIRECP(cp);
            const infoFromPartOfParcel = this.getNumberOfFloorFromXML(partParcel);

            this.getTypologyAutomatic(infoFromPartOfParcel, +infoFromParcel.numberOfResidentUnits, +infoFromParcel.numberOfUnits,
              parseInt(infoFromCadastralParcel), +infoFromParcel.area );

          })
        })
      }
    })
  }

  goBack() {
    if(this.isUpdateCeeBuilding == true){
      //this.optionSelectecEmitter.emit(4);
      this.optionEmitter.emit('infoBuilding');
      this.bestPracticesService.setRefreshCeeBuilding();
    }

    if(this.isUpdateCeeBuilding == false){
      this.optionEmitter.emit('infoBuilding');
    }

  }

  addSwitchFormCount(){

    if(this.switchFormCount <= this.totalFormCount){
      switch(this.switchFormCount){
        case 1:
          if(this.saveFirstForm() == true){
            this.switchFormCount++;
          }else{
            console.log('Faltan datos por rellenar en el formulario ' + this.switchFormCount)
          }
          //this.switchFormCount++;
          break;
        case 2:
          if(this.saveSecondForm() == true){
            this.switchFormCount ++;
          }else{
            console.log('Faltan datos por rellenar en el formulario ' + this.switchFormCount)
          }
          //this.switchFormCount++;
          break;
        case 3:
          if(this.saveThirdForm() == true){
            if(this.arrayTestimony.length == 0) {
              this.idGenerator++;
              this.arrayTestimony.push({id: this.idGenerator, name: null, role: null, quote: null});
            }
            this.switchFormCount ++;
          }else{
            console.log('Faltan datos por rellenar en el formulario ' + this.switchFormCount)
          }
          //this.switchFormCount++;
          break;
        case 4:
          if(this.saveFourthForm() == true){
            this.switchFormCount++;
          }else{
            console.log('Faltan datos por rellenar en el formulario ' + this.switchFormCount)
          }
          //this.switchFormCount++;
          break;
        case 5:
          this.totalFormCount = this.switchFormCount;

          if(this.valueFlexRadioMonitoring == false){

            if(this.isUpdateCeeBuilding == false){
              this.saveAllForms();
            }else{
              this.saveAllFormsToUpdate();
            }

          }else if(this.valueFlexRadioMonitoring == true){

            if(this.formFilesArray.length == 0){
              this.idGenerator++;

              this.formFilesArray.push({
                idg: this.idGenerator,
                season_id: null,
                phase_id: null,
                family_id: null,
                cee_building_id: null,
                variables_id: null,
                location: null,
                comment: null,
                monitoringHealth: []
              });
              this.csvFiles.push(null);
              this.lengthCommentMonitoring.push(0);

            }

            this.totalFormCount++;
            this.switchFormCount++;
          }
          break;
        case 6:

          if(this.saveSixthForm()){
            if(this.isUpdateCeeBuilding == false){
              this.saveAllForms();
              //console.log('saved :)')
            }else{
              this.saveAllFormsToUpdate();
            }

          }else{
            console.log('Te faltan datos por rellenar');
            //alert("ERROR you need to complete more information in this form")
          }
          break;
      }
    }

  }

  deductSwitchFormCount(){
    this.switchFormCount--;
  }

  consoleJorge(){
    console.log('isFilesFromDB -> ', this.isFilesFromDB);
    console.log('files -> ', this.csvFiles);
    for(let i = 0; i < this.formFilesArray.length; i++){
      console.log(this.formFilesArray[i].variables_id);
      console.log(this.isFilesFromDB[i]);
      console.log(this.csvFiles[i]);
      console.log(this.formFilesArray[i].variables_id == null || (this.isFilesFromDB[i] == false && this.csvFiles[i] == null));

    }

  }

  async saveAllForms(){

    console.log('Estamos haciendo la peticion')

    let bodyCeebuilding = {
      //address:this.dataBuildingAddress,
      map_address:this.dataAddressMap,
      rc: this.dataCadastreReference,
      tipology_id:this.valueTipology.code,
      case_id:this.valueFlexRadioCase,
      year:this.dataBuildingYear,
      facade_picture: null,
      year_certificate:this.dataNumberCertificate,
      letter_co2:this.dataCalificationCEE,
      value_co2:this.dataCo2Emissions,
      letter_ep:this.dataCalificationEnergyPrimary,
      value_ep:this.dataEnergyConsumed,
      year_certificate2:this.dataNumberCertificate2,
      letter_co2_cert2:this.dataCalificationCO2EmissionsCertificate2,
      value_co2_cert2:this.dataCo2EmissionsCertificate2,
      letter_ep_cert2:this.dataCalificationEnergyPrimaryCertificate2,
      value_ep_cert2:this.dataEnergyConsumedCertificate2,
      saving_co2_abs:this.dataCO2Absolute,
      saving_co2_percent:this.valueSavingCo2,
      saving_ep_abs:this.dataEnergyAbsolute,
      saving_ep_percent:this.valueSavingEnergyPrimaryPercent,
      coordinates: {"lng": this.coordinates.coordinateLong.toString(), "lat": this.coordinates.coordinateLat.toString()},
      point: {"x": this.coordinates.coordinateX.toString(), "y": this.coordinates.coordinateY.toString()},
      map_url: "",
      current_regulations: null, //this.valueActiveNormative
      reform_year: this.valueYearReform,
      number_floors: this.valueNumberFloors,
      number_dwellings: this.valueNumberDwellings,
      number_comercial_units: null,
      land_surface: this.valueAreaFloor,
      building_surface: this.valueBuildArea,
      heritage_building: this.valueFlexRadioProtected,
      construction_quality: null,//this.valueConstructionQuality
      building_description: this.valueBuildingDescription,
      windows: this.valueFlexRadioWindows,
      wall: this.valueSurround,
      photovoltaic: this.valuePhotovoltaic,
      shw: this.valueAcs,
      aerothermal: this.valueAerothermal,
      intervention_description: this.valueInterventionDescription,
      investment: this.valueInvestmentNumber,
      renovation_subsidies: this.valueSubsidiesNumber,

      investment_square_meter: this.valueInvestmentM2,
      subsidies_percent: this.valueSubsidiesPercent,
      final_investment: this.valueFinalInvestment,
      final_investment_sqmeters: this.valueFinalInvestmentm2,
      investment_permonth: this.valueFinalInvestmentMonth,
      potential_subsidies: this.valuePotSubsidies,
      potential_subsidies_percent: this.valuePotSubsidiesPercent,
      potential_final_investment: this.valuePotFinalInvestment,
      potential_final_investment_m2: this.valuePotFinalInvestmentm2,
      potential_final_inv_month: this.valuePotFinalInvestmentMonth,
      investment_dwelling: this.valueInvestmentDwelling,
      subsidies_dwelling: this.valueSubsidiesDwelling,
      subsidies_percent_dwelling: this.valueInvestmentDwellingPercent,
      final_investment_dwelling: this.valueFinalInvestmentDwelling,
      final_inv_sqmeters_dwellings: this.valueFinalInvestmentDwellingm2,
      inv_permonth_dwellings: this.valueFinalInvestmentDwellingMonth,
      potential_subsidies_dw: this.valuePotSubsidiesDwelling,
      potential_subsidies_percent_dw: this.valuePotSubsidiesDwellingPercent,
      potential_final_investment_dw: this.valuePotFinalInvestmentDwelling,
      potential_final_inv_m2_dw: this.valuePotFinalInvestmentDwellingm2,
      potential_final_inv_month_dw: this.valuePotFinalInvestmentDwellingMonth,
      dwelling_id: this.valueIdentificacionDwelling,
      type_reform: this.valueTypeReform,
      country: this.valueCountry,
      video_testimony: null,
      status_id: this.statusToCeeBuilding
    }

    if(this.valueFlexRadioTestimony == true) bodyCeebuilding.video_testimony = this.valueVideoURL;



    switch(bodyCeebuilding.letter_co2){
      case "A":
        bodyCeebuilding.letter_co2 = 1;
        break;
      case "B":
        bodyCeebuilding.letter_co2 = 2;
        break;
      case "C":
        bodyCeebuilding.letter_co2 = 3;
        break;
      case "D":
        bodyCeebuilding.letter_co2 = 4;
        break;
      case "E":
        bodyCeebuilding.letter_co2 = 5;
        break;
      case "F":
        bodyCeebuilding.letter_co2 = 6;
        break;
      case "G":
        bodyCeebuilding.letter_co2 = 7;
        break;
      case "":
        bodyCeebuilding.letter_co2 = null;
        break;
    }

    switch(bodyCeebuilding.letter_co2_cert2){
      case "A":
        bodyCeebuilding.letter_co2_cert2 = 1;
        break;
      case "B":
        bodyCeebuilding.letter_co2_cert2 = 2;
        break;
      case "C":
        bodyCeebuilding.letter_co2_cert2 = 3;
        break;
      case "D":
        bodyCeebuilding.letter_co2_cert2 = 4;
        break;
      case "E":
        bodyCeebuilding.letter_co2_cert2 = 5;
        break;
      case "F":
        bodyCeebuilding.letter_co2_cert2 = 6;
        break;
      case "G":
        bodyCeebuilding.letter_co2_cert2 = 7;
        break;
      case "":
        bodyCeebuilding.letter_co2_cert2 = null;
        break;
    }

    switch(bodyCeebuilding.letter_ep){
      case "A":
        bodyCeebuilding.letter_ep = 1;
        break;
      case "B":
        bodyCeebuilding.letter_ep = 2;
        break;
      case "C":
        bodyCeebuilding.letter_ep = 3;
        break;
      case "D":
        bodyCeebuilding.letter_ep = 4;
        break;
      case "E":
        bodyCeebuilding.letter_ep = 5;
        break;
      case "F":
        bodyCeebuilding.letter_ep = 6;
        break;
      case "G":
        bodyCeebuilding.letter_ep = 7;
        break;
      case "":
        bodyCeebuilding.letter_ep = null;
        break;
    }

    switch(bodyCeebuilding.letter_ep_cert2){
      case "A":
        bodyCeebuilding.letter_ep_cert2 = 1;
        break;
      case "B":
        bodyCeebuilding.letter_ep_cert2 = 2;
        break;
      case "C":
        bodyCeebuilding.letter_ep_cert2 = 3;
        break;
      case "D":
        bodyCeebuilding.letter_ep_cert2 = 4;
        break;
      case "E":
        bodyCeebuilding.letter_ep_cert2 = 5;
        break;
      case "F":
        bodyCeebuilding.letter_ep_cert2 = 6;
        break;
      case "G":
        bodyCeebuilding.letter_ep_cert2 = 7;
        break;
      case "":
        bodyCeebuilding.letter_ep_cert2 = null;
        break;
    }

    console.log(bodyCeebuilding)

    console.log(bodyCeebuilding.tipology_id);

    let formData = new FormData();

    if(this.isSpainCountry){
      bodyCeebuilding.facade_picture = this.imageFacade;
    }else{
      formData.append('image_facade', this.imageFacade, this.imageFacade.name);
      bodyCeebuilding.facade_picture = this.imageFacade.name;
    }

    formData.append('ceeBuilding', JSON.stringify(bodyCeebuilding));

    await this.ceeService.addCeeBuilding(formData).toPromise().then(async (data: NodeResponse) => {
      console.log("node response -> ", data);

      this.idCeeBuilding = data.data.id;

      let userCeeBuilding = {
        user_id: this.guid,
        cee_building_id: this.idCeeBuilding
      }

      this.newCeeBuildingEmitter.emit(data.data);

      this.ceeService.addUserCeeBuilding(userCeeBuilding).toPromise().then((data: any) => {
        console.log("node response user_cee_building ->", data);
      })

      for(let i = 0; i < this.valueRenovationPictures.length; i++){
        let formDataRenovationPictures = new FormData();

        formDataRenovationPictures.append('cee_building_id', this.idCeeBuilding + '');
        formDataRenovationPictures.append('image', this.valueRenovationPictures[i], this.valueRenovationPictures[i].name);

        await this.ceeService.uploadRenovationPictures(formDataRenovationPictures).toPromise().then((data: NodeResponse) => {
          console.log('image renovation picture -> ', data)
        })
      }

    });

    if(this.valueFlexRadioTestimony == true){

      try{

        for(let i = 0; i < this.arrayTestimony.length; i++){

          let bodyTestimony = {
            name: this.arrayTestimony[i].name,
            quote: this.arrayTestimony[i].quote,
            role: this.arrayTestimony[i].role,
            cee_building_id: this.idCeeBuilding
          }

          let idTestimony = null;

          await this.ceeService.addTestimony(bodyTestimony).toPromise().then((data: any) => {
              console.log("node response testimony => ", data);
              idTestimony = data.id;
          });
        }

        if(this.imagesTestimony.length > 0){
          let images = new FormData();

          for(let i = 0; i < this.imagesTestimony.length; i++){
            images.append('file', this.imagesTestimony[i], 'img_' + i);

            if(i == 4) break;
          }

          images.append('guid', this.guid);
          images.append('idCeeBuilding', this.idCeeBuilding + '');

          await this.ceeService.uploadImage(images).toPromise().then((data) => {
            console.log("response node images -> ", data);
          })
        }

      }catch(error){
        console.error(error)
      }

    }

    if(this.valueFlexRadioMonitoring == true){
      let bodyMonitoring = null;
      let idMonitoring = null;

      for(let i = 0; i < this.formFilesArray.length; i++){

        try{

          bodyMonitoring = {
            cee_building_id: this.idCeeBuilding,
            season_id: this.formFilesArray[i].season_id,
            phase_id: this.formFilesArray[i].phase_id,
            family_id: this.formFilesArray[i].family_id,
            variables_id: this.formFilesArray[i].variables_id,
            location: this.formFilesArray[i].location,
            comment: this.formFilesArray[i].comment
          }

          console.log("bodyMonitoring -> ", bodyMonitoring)

          await this.ceeService.addMonitoring(bodyMonitoring).toPromise().then((data: any) => {
            console.log("response node monitoring -> " + i, data);
            idMonitoring = data.id;
          })

          console.log("idMonitoring -> ", idMonitoring);
          console.log('guid -> '+ this.guid)

          if(this.formFilesArray[i].family_id == 1 || this.formFilesArray[i].family_id == 2){
            let bodyFile = new FormData();

            bodyFile.append('file', this.csvFiles[i], this.csvFiles[i].name);
            bodyFile.append('guid', this.guid);
            bodyFile.append('idMonitoring', idMonitoring);

            console.log('monitoring data ' + i + ' -> file: ' + this.csvFiles[i] );

            await this.ceeService.uploadFile(bodyFile).toPromise().then((data) => {
              console.log("response node file -> " + i, data);
            })
          }else if(this.formFilesArray[i].family_id == 3){
            for(let j = 0; j < this.formFilesArray[i].monitoringHealth.length; j++){

              console.log('monitoring data ' + i + ' -> health: ' + this.formFilesArray[i].monitoringHealth[j] );

              await this.ceeService.addMonitoringHealth(
                this.formFilesArray[i].monitoringHealth[j].age,
                this.formFilesArray[i].monitoringHealth[j].sex,
                this.formFilesArray[i].monitoringHealth[j].arrayStressorId,
                this.formFilesArray[i].monitoringHealth[j].arraySymptomId,
                this.formFilesArray[i].monitoringHealth[j].arrayThermalComfortId,
                this.formFilesArray[i].monitoringHealth[j].arrayActivityId,
                this.formFilesArray[i].monitoringHealth[j].arrayClothingId,
                this.formFilesArray[i].monitoringHealth[j].text,
                idMonitoring
              ).toPromise().then((data: NodeResponse) => {
                console.log('data create monitoring health -> ', data);
              })
            }
          }

        }catch(error){
          console.error(error)
        }

      }
    }

    alert('Form complete');
    this.goBack();
  }


  async saveAllFormsToUpdate(){

    let bodyCeebuildingUpdate = {
      id: this.idCeeBuilding,
      //address:this.dataBuildingAddress,
      map_address:this.dataAddressMap,
      rc: this.dataCadastreReference,
      tipology_id:this.valueTipology.code,
      case_id:this.valueFlexRadioCase,
      year:this.dataBuildingYear,
      facade_picture: this.imageFacade,
      year_certificate:this.dataNumberCertificate,
      letter_co2:this.dataCalificationCEE,
      value_co2:this.dataCo2Emissions,
      letter_ep:this.dataCalificationEnergyPrimary,
      value_ep:this.dataEnergyConsumed,
      year_certificate2:this.dataNumberCertificate2,
      letter_co2_cert2:this.dataCalificationCO2EmissionsCertificate2,
      value_co2_cert2:this.dataCo2EmissionsCertificate2,
      letter_ep_cert2:this.dataCalificationEnergyPrimaryCertificate2,
      value_ep_cert2:this.dataEnergyConsumedCertificate2,
      saving_co2_abs:this.dataCO2Absolute,
      saving_co2_percent:this.valueSavingCo2,
      saving_ep_abs:this.dataEnergyAbsolute,
      saving_ep_percent:this.valueSavingEnergyPrimaryPercent,
      reform_year: this.valueYearReform,
      number_floors: this.valueNumberFloors,
      number_dwellings: this.valueNumberDwellings,
      land_surface: this.valueAreaFloor,
      building_surface: this.valueBuildArea,
      heritage_building: this.valueFlexRadioProtected,
      building_description: this.valueBuildingDescription,
      windows: this.valueFlexRadioWindows,
      wall: this.valueSurround,
      photovoltaic: this.valuePhotovoltaic,
      shw: this.valueAcs,
      aerothermal: this.valueAerothermal,
      intervention_description: this.valueInterventionDescription,
      investment: this.valueInvestmentNumber,
      renovation_subsidies: this.valueSubsidiesNumber,

      investment_square_meter: this.valueInvestmentM2,
      subsidies_percent: this.valueSubsidiesPercent,
      final_investment: this.valueFinalInvestment,
      final_investment_sqmeters: this.valueFinalInvestmentm2,
      investment_permonth: this.valueFinalInvestmentMonth,
      potential_subsidies: this.valuePotSubsidies,
      potential_subsidies_percent: this.valuePotSubsidiesPercent,
      potential_final_investment: this.valuePotFinalInvestment,
      potential_final_investment_m2: this.valuePotFinalInvestmentm2,
      potential_final_inv_month: this.valuePotFinalInvestmentMonth,
      investment_dwelling: this.valueInvestmentDwelling,
      subsidies_dwelling: this.valueSubsidiesDwelling,
      subsidies_percent_dwelling: this.valueInvestmentDwellingPercent,
      final_investment_dwelling: this.valueFinalInvestmentDwelling,
      final_inv_sqmeters_dwellings: this.valueFinalInvestmentDwellingm2,
      inv_permonth_dwellings: this.valueFinalInvestmentDwellingMonth,
      potential_subsidies_dw: this.valuePotSubsidiesDwelling,
      potential_subsidies_percent_dw: this.valuePotSubsidiesDwellingPercent,
      potential_final_investment_dw: this.valuePotFinalInvestmentDwelling,
      potential_final_inv_m2_dw: this.valuePotFinalInvestmentDwellingm2,
      potential_final_inv_month_dw: this.valuePotFinalInvestmentDwellingMonth,
      dwelling_id: this.valueIdentificacionDwelling,
      type_reform: this.valueTypeReform,
      country: this.valueCountry,
      video_testimony: null,
      status_id: this.statusToCeeBuilding
    }

    if(this.valueFlexRadioTestimony == true) bodyCeebuildingUpdate.video_testimony = this.valueVideoURL;

    switch(bodyCeebuildingUpdate.letter_co2){
      case "A":
        bodyCeebuildingUpdate.letter_co2 = 1;
        break;
      case "B":
        bodyCeebuildingUpdate.letter_co2 = 2;
        break;
      case "C":
        bodyCeebuildingUpdate.letter_co2 = 3;
        break;
      case "D":
        bodyCeebuildingUpdate.letter_co2 = 4;
        break;
      case "E":
        bodyCeebuildingUpdate.letter_co2 = 5;
        break;
      case "F":
        bodyCeebuildingUpdate.letter_co2 = 6;
        break;
      case "G":
        bodyCeebuildingUpdate.letter_co2 = 7;
        break;
      case "":
        bodyCeebuildingUpdate.letter_co2 = null;
        break;
    }

    switch(bodyCeebuildingUpdate.letter_co2_cert2){
      case "A":
        bodyCeebuildingUpdate.letter_co2_cert2 = 1;
        break;
      case "B":
        bodyCeebuildingUpdate.letter_co2_cert2 = 2;
        break;
      case "C":
        bodyCeebuildingUpdate.letter_co2_cert2 = 3;
        break;
      case "D":
        bodyCeebuildingUpdate.letter_co2_cert2 = 4;
        break;
      case "E":
        bodyCeebuildingUpdate.letter_co2_cert2 = 5;
        break;
      case "F":
        bodyCeebuildingUpdate.letter_co2_cert2 = 6;
        break;
      case "G":
        bodyCeebuildingUpdate.letter_co2_cert2 = 7;
        break;
      case "":
        bodyCeebuildingUpdate.letter_co2_cert2 = null;
        break;
    }

    switch(bodyCeebuildingUpdate.letter_ep){
      case "A":
        bodyCeebuildingUpdate.letter_ep = 1;
        break;
      case "B":
        bodyCeebuildingUpdate.letter_ep = 2;
        break;
      case "C":
        bodyCeebuildingUpdate.letter_ep = 3;
        break;
      case "D":
        bodyCeebuildingUpdate.letter_ep = 4;
        break;
      case "E":
        bodyCeebuildingUpdate.letter_ep = 5;
        break;
      case "F":
        bodyCeebuildingUpdate.letter_ep = 6;
        break;
      case "G":
        bodyCeebuildingUpdate.letter_ep = 7;
        break;
      case "":
        bodyCeebuildingUpdate.letter_ep = null;
        break;
    }

    switch(bodyCeebuildingUpdate.letter_ep_cert2){
      case "A":
        bodyCeebuildingUpdate.letter_ep_cert2 = 1;
        break;
      case "B":
        bodyCeebuildingUpdate.letter_ep_cert2 = 2;
        break;
      case "C":
        bodyCeebuildingUpdate.letter_ep_cert2 = 3;
        break;
      case "D":
        bodyCeebuildingUpdate.letter_ep_cert2 = 4;
        break;
      case "E":
        bodyCeebuildingUpdate.letter_ep_cert2 = 5;
        break;
      case "F":
        bodyCeebuildingUpdate.letter_ep_cert2 = 6;
        break;
      case "G":
        bodyCeebuildingUpdate.letter_ep_cert2 = 7;
        break;
      case "":
        bodyCeebuildingUpdate.letter_ep_cert2 = null;
        break;
    }

    await this.ceeService.updateCeeBuilding(bodyCeebuildingUpdate).toPromise().then((data: CeeBuilding) => {
      console.log("node response update CeeBuilding-> ", data);
    });

    //RENOVATION PICTURES

    if(this.isThereWasRenovationPictures == true && this.isRenovationPicturesChange == true){

      console.log('hola estoy dentro jeje')

      for(let i = 0; i < this.valueRenovationPictures.length; i++){

        let formDataRenovationPictures = new FormData();

        formDataRenovationPictures.append('image', this.valueRenovationPictures[i], this.valueRenovationPictures[i].name);
        formDataRenovationPictures.append('ceeBuildingId', this.idCeeBuilding + '');
        let isFirst = i == 0 ? true : false;
        formDataRenovationPictures.append('isFirst', isFirst + '');

        await this.ceeService.updateRenovationPictures(formDataRenovationPictures).toPromise().then((updateImage: NodeResponse) => {
          console.log("dataArticle node update image -> ", updateImage);
        })

      }
    }else if(this.isThereWasRenovationPictures == false && this.isRenovationPicturesChange == true){
      for(let i = 0; i < this.valueRenovationPictures.length; i++){
        let formDataRenovationPictures = new FormData();

        formDataRenovationPictures.append('cee_building_id', this.idCeeBuilding + '');
        formDataRenovationPictures.append('image', this.valueRenovationPictures[i], this.valueRenovationPictures[i].name);

        await this.ceeService.uploadRenovationPictures(formDataRenovationPictures).toPromise().then((data: NodeResponse) => {
          console.log('image renovation picture -> ', data)
        })
      }
    }

    //TESTIMONY AND IMAGES

    if(this.isThereWasTestimony == true && this.valueFlexRadioTestimony == true){
      console.log('entro en 1 -> ' ,this.arrayTestimony)

      for(let i = 0; i < this.arrayTestimony.length; i++){


        let bodyTestimony = {
          id: this.arrayTestimony[i].id,
          name: this.arrayTestimony[i].name,
          quote: this.arrayTestimony[i].quote,
          role: this.arrayTestimony[i].role,
          idCeeBuilding: this.idCeeBuilding,
          isFirst: i == 0 ? true : false
        }

        await this.ceeService.updateTestimony(bodyTestimony).toPromise().then((data: any) => {
          console.log("node response testimony => ", data);
        });
      }



      if(this.imagesTestimony.length > 0){
        let formDataImages = new FormData();

        for(let i = 0; i < this.imagesTestimony.length; i++){
          formDataImages.append('file', this.imagesTestimony[i], 'img_' + i);

          if(i == 4) break;
        }

        formDataImages.append('guid', this.guid);
        formDataImages.append('idCeeBuilding', this.idCeeBuilding + '');

        await this.ceeService.updateImagesIdCeeBuilding(formDataImages).toPromise().then((data: any) => {
          console.log(data);
        });
      }

    }else if(this.isThereWasTestimony == false && this.valueFlexRadioTestimony == true){
      console.log('entro en 2 -> ' ,this.arrayTestimony)


      for(let i = 0; i < this.arrayTestimony.length; i++){


        let bodyTestimony = {
          id: this.arrayTestimony[i].id,
          name: this.arrayTestimony[i].name,
          quote: this.arrayTestimony[i].quoute,
          role: this.arrayTestimony[i].role,
          idCeeBuilding: this.idCeeBuilding,
          isFirst: i == 0 ? true : false
        }


      await this.ceeService.addTestimony(bodyTestimony).toPromise().then(async (data: any) => {
        console.log("node response testimony => ", data);
      });

      }

      if(this.imagesTestimony.length > 0){
        let images = new FormData();

        for(let i = 0; i < this.imagesTestimony.length; i++){
          images.append('file', this.imagesTestimony[i], 'img_' + i);

          if(i == 4) break;
        }

        images.append('guid', this.guid);
        images.append('idCeeBuilding', this.idCeeBuilding + '');

        await this.ceeService.uploadImage(images).toPromise().then((data) => {
          console.log("response node images -> ", data);
        })
      }

      this.isThereWasTestimony = true;


    }else if(this.isThereWasTestimony == true && this.valueFlexRadioTestimony == false){
      await this.ceeService.deleteImagesidCeeBuilding(this.idCeeBuilding).toPromise().then(async (data: Testimony)=> {
        console.log(data);
      })

      await this.ceeService.deleteTestimonyIdCeeBuilding(this.idCeeBuilding).toPromise().then((data: any) => {
        console.log(data);

        this.valueIdTestimony = null;

        this.valueName = null;
        this.valueRoleName = null;
        this.valueQuote = null;
        this.valueVideoURL = null;
      })
    }

    // MONITORING DATA AND CSV FILES

    if(this.isThereWasMonitoring == true && this.valueFlexRadioMonitoring == true){

      let bodyMonitoring = null;
      let idMonitoring = null;

      for(let i = 0; i < this.formFilesArray.length; i++){

        bodyMonitoring = {
          id: this.formFilesArray[i].id,
          cee_building_id: this.idCeeBuilding,
          season_id: this.formFilesArray[i].season_id,
          phase_id: this.formFilesArray[i].phase_id,
          family_id: this.formFilesArray[i].family_id,
          variables_id: this.formFilesArray[i].variables_id,
          location: this.formFilesArray[i].location,
          comment: this.formFilesArray[i].comment,
        }

        await this.ceeService.updateMonitoring(bodyMonitoring).toPromise().then(async (data: any) => {
          console.log('monitoring '+ this.formFilesArray[i].id, data);

          let idMonitoring = this.formFilesArray[i].id == undefined ? data.id : this.formFilesArray[i].id;


          if(this.formFilesArray[i].family_id == 1 || this.formFilesArray[i].family_id == 2){

            if(this.isFilesFromDB[i] == false){
              this.ceeService.deleteMonitoringHealthIdMonitoring(idMonitoring).toPromise().then((data: any) => {
                console.log('monitoring health del monitoring borrado -> ', data)
              })
            }

            let bodyFile = new FormData();

            if(this.isFilesFromDB[i] == false && this.isCsvFileChange[i] == true){
              bodyFile.append('file', this.csvFiles[i], this.csvFiles[i].name);
              bodyFile.append('guid', this.guid);
              bodyFile.append('idMonitoring', idMonitoring);


              await this.ceeService.uploadFile(bodyFile).toPromise().then((data) => {
                console.log("response node file -> " + i, data);
              })
            }else if(this.isFilesFromDB[i] == true && this.isCsvFileChange[i] == true){

              bodyFile.append('file', this.csvFiles[i], this.csvFiles[i].name);
              bodyFile.append('guid', this.guid);
              bodyFile.append('idMonitoring', idMonitoring);

              await this.ceeService.updateFile(bodyFile).toPromise().then((data) => {
                console.log("response node update file -> " + i, data);
              })

            }
          }else{

            if(this.isFilesFromDB[i] == true){
              await this.ceeService.deleteFileIdMonitoring(this.formFilesArray[i].id).toPromise().then((data: any) => {
                console.log('delete file id monitoring ' + this.formFilesArray[i].id, data);
              })
            }

            for(let j = 0; j < this.formFilesArray[i].monitoringHealth.length; j++){

              let isFirst = j == 0 ? true : false;

              await this.ceeService.updateMonitoringHealth(
                this.formFilesArray[i].monitoringHealth[j].age,
                this.formFilesArray[i].monitoringHealth[j].sex,
                this.formFilesArray[i].monitoringHealth[j].arrayStressorId,
                this.formFilesArray[i].monitoringHealth[j].arraySymptomId,
                this.formFilesArray[i].monitoringHealth[j].arrayThermalComfortId,
                this.formFilesArray[i].monitoringHealth[j].arrayActivityId,
                this.formFilesArray[i].monitoringHealth[j].arrayClothingId,
                this.formFilesArray[i].monitoringHealth[j].text,
                idMonitoring,
                isFirst
              ).toPromise().then((data: NodeResponse) => {
                console.log('update monitoring health -> ', data)
              })
            }

          }

        })

      }


    }else if(this.isThereWasMonitoring == false && this.valueFlexRadioMonitoring == true){

      console.log('no habia, ahora si')


      let bodyMonitoring = null;
      let idMonitoring = null;
      let bodyFile = new FormData();

      for(let i = 0; i < this.formFilesArray.length; i++){

        try{

          bodyMonitoring = {
            cee_building_id: this.idCeeBuilding,
            season_id: this.formFilesArray[i].season_id,
            phase_id: this.formFilesArray[i].phase_id,
            family_id: this.formFilesArray[i].family_id,
            variables_id: this.formFilesArray[i].variables_id,
            location: this.formFilesArray[i].location,
            comment: this.formFilesArray[i].comment,
          }

          console.log("bodyMonitoring -> ", bodyMonitoring)

          await this.ceeService.addMonitoring(bodyMonitoring).toPromise().then(async (data: any) => {
            console.log("response node monitoring -> " + i, data);
            idMonitoring = data.id;

            if(this.formFilesArray[i].family_id == 1 || this.formFilesArray[i].family_id == 2){
              console.log("idMonitoring -> ", idMonitoring);
              console.log('guid -> '+ this.guid)

              if(this.csvFiles.length > 0){
                bodyFile.append('file', this.csvFiles[i], this.csvFiles[i].name);
                bodyFile.append('guid', this.guid);
                bodyFile.append('idMonitoring', idMonitoring);


                await this.ceeService.uploadFile(bodyFile).toPromise().then((data) => {
                  console.log("response node file -> " + i, data);
                })
              }
            }else{
              for(let j = 0; j < this.formFilesArray[i].monitoringHealth.length; j++){

                await this.ceeService.addMonitoringHealth(
                  this.formFilesArray[i].monitoringHealth[j].age,
                  this.formFilesArray[i].monitoringHealth[j].sex,
                  this.formFilesArray[i].monitoringHealth[j].arrayStressorId,
                  this.formFilesArray[i].monitoringHealth[j].arraySymptomId,
                  this.formFilesArray[i].monitoringHealth[j].arrayThermalComfortId,
                  this.formFilesArray[i].monitoringHealth[j].arrayActivityId,
                  this.formFilesArray[i].monitoringHealth[j].arrayClothingId,
                  this.formFilesArray[i].monitoringHealth[j].text,
                  idMonitoring
                ).toPromise().then((data: NodeResponse) => {
                  console.log('data create monitoring health -> ', data);
                })
              }
            }

          })



        }catch(error){
          console.error(error)
        }

        bodyFile = new FormData();
      }

      for(let i = 0; i < this.formFilesArray.length; i++){
        this.backUpArrayMonitoring.push(this.formFilesArray[i]);
      }

      this.isThereWasMonitoring = true;

    }else if(this.isThereWasMonitoring == true && this.valueFlexRadioMonitoring == false){


      for(let i = 0; i < this.formFilesArray.length; i++){
        await this.ceeService.deleteFileIdMonitoring(this.formFilesArray[i].id).toPromise().then((data: any) => {
          console.log(data);
        })

        await this.ceeService.deleteMonitoringHealthIdMonitoring(this.formFilesArray[i].id).toPromise().then((data: any) => {
          console.log(data);
        })
      }

      await this.ceeService.deleteMonitoringIdCeeBuilding(this.idCeeBuilding).toPromise().then((data: any) => {
        console.log(data);
      })


    }

    this.goBack();

  }

  pruebaUpdate(){


    let bodyMonitoring = null;
    let idMonitoring = null;

    for(let i = 0; i < this.formFilesArray.length; i++){

      bodyMonitoring = {
        id: this.formFilesArray[i].id,
        cee_building_id: this.idCeeBuilding,
        season_id: this.formFilesArray[i].season_id,
        phase_id: this.formFilesArray[i].phase_id,
        family_id: this.formFilesArray[i].family_id,
        variables_id: this.formFilesArray[i].variables_id,
        location: this.formFilesArray[i].location,
        comment: this.formFilesArray[i].comment,
      }

      //await this.ceeService.updateMonitoring(bodyMonitoring).toPromise().then(async (data: any) => {

        if(this.formFilesArray[i].family_id == 1 || this.formFilesArray[i].family_id == 2){
          let bodyFile = new FormData();

          if(this.isFilesFromDB[i] == false && this.isCsvFileChange[i] == true){
            bodyFile.append('file', this.csvFiles[i], this.csvFiles[i].name);
            bodyFile.append('guid', this.guid);
            bodyFile.append('idMonitoring', bodyMonitoring.id);

            console.log('crear nuevo file -> ', this.csvFiles[i]);

          }else if(this.isFilesFromDB[i] == true && this.isCsvFileChange[i] == true){

            bodyFile.append('file', this.csvFiles[i], this.csvFiles[i].name);
            bodyFile.append('guid', this.guid);
            bodyFile.append('idMonitoring', this.formFilesArray[i].id + '');

            console.log('actualizar nuevo file -> ', this.csvFiles[i]);


          }
        }else{

          for(let j = 0; j < this.formFilesArray[i].monitoringHealth.length; j++){

            let isFirst = j == 0 ? true : false;

            console.log('update monitoring health -> ', this.formFilesArray[i].monitoringHealth)
          }

        }

      //})

    }



  }

  saveFirstForm(): boolean{

    let value = true;

    if(this.dataAddressMap == '' || this.valueCountry == '' || this.valueTipology.code == '' || this.valueFlexRadioCase == null || this.dataBuildingYear == null){
      value = false;
    }

    console.log(this.dataAddressMap,
     this.valueCountry,
      this.valueTipology.code,
      this.valueFlexRadioCase,
      this.dataBuildingYear,)

    return value;
  }

  saveSecondForm(): boolean{

    let value = true;

    if(this.valueNumberFloors == null || this.valueNumberDwellings == null){
      value = false;
    }

    console.log(this.valueNumberFloors,
      this.valueNumberDwellings)

    return value;
  }

  saveThirdForm(){

    /*let value = true;

    if(this.valueYearReform != null){
      if(
        this.valueFlexRadioWindows == null ||
        this.valueSurround == null ||
        this.valuePhotovoltaic == null ||
        this.valueAcs == null ||
        this.valueAerothermal == null
      ){
        value = false
      }
    }

    if(value == true) this.calculateData();

    return value;*/

    this.calculateData();
    return true;
  }

  calculateData(){
    this.valueInvestmentM2 = Math.round((this.valueInvestmentNumber / this.valueAreaFloor) * 100) / 100;
    this.valueSubsidiesPercent = Math.round(((this.valueSubsidiesNumber / this.valueInvestmentNumber) * 100) * 100) / 100;
    this.valueFinalInvestment = Math.round((this.valueInvestmentNumber - this.valueSubsidiesNumber)* 100) / 100;
    this.valueFinalInvestmentm2 = Math.round((this.valueFinalInvestment / this.valueAreaFloor) * 100) / 100;
    this.valueFinalInvestmentMonth = Math.round((this.valueFinalInvestment / 24)* 100) / 100;
    this.valuePotFinalInvestment = Math.round((this.valueFinalInvestment - this.valuePotSubsidies) * 100) / 100;
    this.valuePotFinalInvestmentm2 = Math.round((this.valuePotFinalInvestment / this.valueAreaFloor) * 100) / 100;
    this.valuePotFinalInvestmentMonth = Math.round((this.valueFinalInvestment / 24) * 100) / 100;

    if(this.valueNumberDwellings > 1){
      this.valueInvestmentDwelling = Math.round((this.valueInvestmentNumber / this.valueNumberDwellings) * 100) / 100;
      this.valueInvestmentDwellingPercent = Math.round(this.valueSubsidiesPercent * 100) / 100;
      this.valueSubsidiesDwelling = Math.round((this.valueSubsidiesNumber / this.valueNumberDwellings) * 100) / 100;
      this.valueFinalInvestmentDwelling = Math.round((this.valueFinalInvestment / this.valueNumberDwellings) * 100) / 100;
      this.valueFinalInvestmentDwellingm2 = Math.round((this.valueFinalInvestmentm2) * 100) / 100;
      this.valueFinalInvestmentDwellingMonth = Math.round((this.valueFinalInvestmentMonth / this.valueNumberDwellings) * 100) / 100;
      this.valuePotSubsidiesDwellingPercent = Math.round(this.valuePotSubsidiesPercent * 100) / 100;
      this.valuePotSubsidiesDwelling = Math.round((this.valuePotSubsidies / this.valueNumberDwellings) * 100) / 100;
      this.valuePotFinalInvestmentDwelling = Math.round((this.valueFinalInvestmentDwelling / this.valuePotSubsidiesDwelling) * 100) / 100;
      this.valuePotFinalInvestmentDwellingm2 = Math.round((this.valuePotFinalInvestmentDwelling * this.valueNumberDwellings / this.valueAreaFloor) * 100) / 100;
      this.valuePotFinalInvestmentDwellingMonth = Math.round((this.valuePotFinalInvestmentDwelling / 24) * 100) / 100;
    }else{
      this.valueInvestmentDwelling = 0;
      this.valueInvestmentDwellingPercent = 0;
      this.valueSubsidiesDwelling = 0;
      this.valueFinalInvestmentDwelling = 0;
      this.valueFinalInvestmentDwellingm2 = 0;
      this.valueFinalInvestmentDwellingMonth = 0;
      this.valuePotSubsidiesDwellingPercent = 0;
      this.valuePotSubsidiesDwelling = 0;
      this.valuePotFinalInvestmentDwelling = 0;
      this.valuePotFinalInvestmentDwellingm2 = 0;
      this.valuePotFinalInvestmentDwellingMonth = 0;
    }

  }

  saveFourthForm(): boolean{
    let value = true;

    if(this.valueFlexRadioTestimony == true){
      for(let i = 0; i < this.arrayTestimony.length; i++){
        if(
          this.arrayTestimony[i].name == null || this.arrayTestimony[i].name == '' ||
          this.arrayTestimony[i].role == null || this.arrayTestimony[i].role == '' ||
          this.arrayTestimony[i].quote == null || this.arrayTestimony[i].quote == ''
        ){
          value = false;
          break;
        }
      }

      return value;

    }else{
      return value;
    }
  }

  saveSixthForm(): boolean{
    let value1 = true;
    let value2 = true;

    for(let i = 0; i < this.formFilesArray.length; i++){
      if(
        this.formFilesArray[i].season_id != null ||
        this.formFilesArray[i].phase_id != null ||
        this.formFilesArray[i].family_id != null
      ){
        if(this.formFilesArray[i].family_id == 1){
          if(this.isUpdateCeeBuilding){
            if(this.formFilesArray[i].variables_id == null || (this.isFilesFromDB[i] == false && this.csvFiles[i] == null)){
              value2 = false;
              break;
            }
          }else{
            if(this.formFilesArray[i].variables_id == null || this.csvFiles[i] == null){
              value2 = false;
              break;
            }
          }

        }

        if(this.formFilesArray[i].family_id == 2){
          if(this.isUpdateCeeBuilding){
            if(this.formFilesArray[i].location == null || this.formFilesArray[i].variables_id == null || (this.isFilesFromDB[i] == false && this.csvFiles[i] == null)){
              value2 = false;
              break;
            }
          }else{
            if(this.formFilesArray[i].location == null || this.formFilesArray[i].variables_id == null || this.csvFiles[i] == null){
              value2 = false;
              break;
            }
          }
        }

        if(this.formFilesArray[i].family_id == 3){
          for(let j = 0; j < this.formFilesArray[i].monitoringHealth.length; j++){
            if(
              this.formFilesArray[i].monitoringHealth[j].age == null || !isNumber(this.formFilesArray[i].monitoringHealth[j].age) ||
              this.formFilesArray[i].monitoringHealth[j].sex == null ||
              this.formFilesArray[i].monitoringHealth[j].arrayStressorId.length == 0 ||
              this.formFilesArray[i].monitoringHealth[j].arraySymptomId.length == 0 ||
              this.formFilesArray[i].monitoringHealth[j].arrayThermalComfortId.length == 0 ||
              this.formFilesArray[i].monitoringHealth[j].arrayActivityId.length == 0 ||
              this.formFilesArray[i].monitoringHealth[j].arrayClothingId.length == 0
            ){
              value2 = false;
              break;
            }
          }
          if(value2 == false) break;
        }

      }else{
        value1 = false;
        break;
      }

    }

    return (value1 && value2);
  }

  fileChangeEvent(event: any, i: number): boolean{

    let file = event.target.files[0];

    console.log( 'Archivo seleccionado -> ', event.target.files[0]);

    if(file.type.substring(5) == 'csv' || file.type == 'application/vnd.ms-excel'){
      this.csvFiles.splice(i, 1, file);
      this.isCsvFileChange.splice(i, 1, true);
      return true;
    }else{
      this.csvFiles.splice(i, 1, null);
      this.isCsvFileChange.splice(i, 1, false);
      console.log('esto no es un archivo CSV');
      return false;
    }
  }

  fileImageChangeEvent(event: any){

    this.isAllImages = true;
    this.imagesTestimony = [];

    //console.log(event.target.files[0].type.substring(0,5));

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.imagesTestimony.push(event.target.files[i]);
      }else{
        console.log('no es una imagen ', this.isAllImages);
        this.isAllImages = false;
      }

      if(i == 4) break;
    }
  }

  familyInputChangeEvent(index: number){

    this.idGenerator++;

    if(this.formFilesArray[index].family_id == 3){

      this.formFilesArray[index].monitoringHealth = [
        {id: this.idGenerator,
        arrayStressorId: [],
        arraySymptomId: [],
        arrayThermalComfortId: [],
        arrayActivityId: [],
        arrayClothingId: [],
        text: null,
        sex: null,
        age: null}
      ]

      this.formFilesArray[index].variables_id = null;
      this.formFilesArray[index].location = null;
      this.formFilesArray[index].comment = null;
      this.csvFiles.splice(index, 1, null);

    }else{
      this.formFilesArray[index].monitoringHealth = [];
      this.formFilesArray[index].variables_id = null;
      this.formFilesArray[index].location = null;
      this.formFilesArray[index].comment = null;
      this.csvFiles.splice(index, 1, null);
    }

  }

  inputArrayVariable(familyId: number){

    //this.formFilesArray[index].variables_id = null;

    return this.arrayVariable.filter(e => e.family_id == familyId);
  }

  public isValidateUrlVideo(url: string): boolean{

    if(url == null || url == undefined || url == ''){
      return true;
    }else{
      for(let i = 0; i < this.arrayRegex.length; i++){
        if(this.arrayRegex[i].test(url)) {
          return true;
        }else{
          return false;
        }
      }
    }

  }

  getInputNumber(){
    if(this.valueInputNumber > 0){
      if(this.valueInputNumber >= 6){
        this.absoluteInputNumber = 6;
      }else{
        this.absoluteInputNumber = this.valueInputNumber;
      }
      return true;
    }else{
      return false;
    }
  }

  //functions copied from one click

  getInfoFromParcel(information: string): any{
    const domParser = new DOMParser();
    const dataXML = domParser.parseFromString(information, 'text/xml');
    const use = dataXML.getElementsByTagName('bu-ext2d:currentUse').length > 0 ?
      dataXML.getElementsByTagName('bu-ext2d:currentUse')[0].textContent : '';
    const numberOfUnits = dataXML.getElementsByTagName('bu-ext2d:numberOfBuildingUnits').length > 0 ?
      dataXML.getElementsByTagName('bu-ext2d:numberOfBuildingUnits')[0].textContent : '';
    const numberOfResidentUnits = dataXML.getElementsByTagName('bu-ext2d:numberOfDwellings').length > 0 ?
      dataXML.getElementsByTagName('bu-ext2d:numberOfDwellings')[0].textContent : '';
    const area = dataXML.getElementsByTagName('bu-ext2d:OfficialArea').length > 0 ?
      dataXML.getElementsByTagName('bu-ext2d:OfficialArea')[0].getElementsByTagName('bu-ext2d:value')[0].textContent : '';
    return {
      use,
      numberOfUnits,
      numberOfResidentUnits,
      area
    };
  }

  getInfoFromINSPIRECP(information: string): any{
    const domParser = new DOMParser();
    const dataXML = domParser.parseFromString(information, 'text/xml');
    //console.log("jorge inspire -> ", dataXML);

    const buildedSurface = dataXML.getElementsByTagName('cp:areaValue').length > 0 ?
      dataXML.getElementsByTagName('cp:areaValue')[0].textContent : '';

    return buildedSurface;
  }

  getNumberOfFloorFromXML(information: string): number{
    const domParser = new DOMParser();
    const dataXML = domParser.parseFromString(information, 'text/xml');
    const floorsInTotal = dataXML.getElementsByTagName('bu-ext2d:numberOfFloorsAboveGround');
    let floors = 0;
    for ( let i = 0; i < floorsInTotal.length; i++){
      const numberInPart = +floorsInTotal[i].textContent;
      if ( floors < numberInPart){
        floors = +numberInPart;
      }
    }
    return floors;
  }

  getTypologyAutomatic(floors: number, dwellings: number, numberOfUnits: number,  surfaceIn: number, buildedSurface: number  ): void {

    console.log("floors -> " + floors, "dwellings -> " + dwellings, "numberOfUnits -> " + numberOfUnits, "surfaceIn -> " + surfaceIn, "buildedSurface -> " + buildedSurface);

    this.valueNumberFloors = floors;
    this.valueNumberDwellings = dwellings;
    //numberOfUnits??
    this.valueAreaFloor = surfaceIn;
    this.valueBuildArea = buildedSurface;

    let typology = { code: '', name: ''};

    if ( numberOfUnits === 1 ) {
      const surface = surfaceIn * 0.6;

      if ( buildedSurface < surface ) {
        typology.code = 'SFH'; typology.name = 'Single Family Home';
      }
      else {
        typology.code = 'TH'; typology.name = 'Terraced House';
      }
    }
    else if ( dwellings > 1 ) {
      if ( floors > 1 && floors <= 5 ) {
        typology.code = 'MFH'; typology.name = 'MultiFamily Home';
      }else if ( floors > 5 ) {
        typology.code = 'AB'; typology.name = 'Apartment block';
      }
    }

    console.log("tipology jorge -> ",typology)
    this.valueTipology.code = typology.code;
    this.valueTipology.name = typology.name;
  }

  eventChangeSelectBuilding(event){
    let i = event.target.value;

    this.dataNumberCertificate = this.arraySingleHome[i].getElementsByTagName('codigo')[0].textContent.substring(1, 5);
    this.dataCalificationCEE = this.arraySingleHome[i].getElementsByTagName('cer_emicalificacion')[0].textContent;
    this.dataEnergyConsumed = this.arraySingleHome[i].getElementsByTagName('cer_contotal')[0].textContent;
    this.dataCo2Emissions = this.arraySingleHome[i].getElementsByTagName('cer_emitotal')[0].textContent;
    this.dataCalificationEnergyPrimary = this.arraySingleHome[i].getElementsByTagName('cer_concalificacion')[0].textContent;
    //this.dataBuildingAddress = this.arraySingleHome[i].getElementsByTagName('exp_direccion')[0].textContent;
    this.valueIdentificacionDwelling = this.arraySingleHome[i].getElementsByTagName('ref_referencia')[0].textContent;


    if(this.arrayDoubleCEE.has(this.valueIdentificacionDwelling)){
      let value: any = this.arrayDoubleCEE.get(this.valueIdentificacionDwelling);

      this.dataNumberCertificate2 = value.getElementsByTagName('codigo')[0].textContent.substring(1, 5);
      if(this.dataNumberCertificate2 != this.dataNumberCertificate){
        this.dataCalificationCO2EmissionsCertificate2 = value.getElementsByTagName('cer_emicalificacion')[0].textContent;
        this.dataCo2EmissionsCertificate2 = value.getElementsByTagName('cer_emitotal')[0].textContent;
        this.dataCalificationEnergyPrimaryCertificate2 = value.getElementsByTagName('cer_concalificacion')[0].textContent;
        this.dataEnergyConsumedCertificate2 = value.getElementsByTagName('cer_contotal')[0].textContent;

        this.dataCO2Absolute = Math.round((this.dataCo2Emissions - this.dataCo2EmissionsCertificate2) * 100) / 100;
        this.dataEnergyAbsolute = Math.round((this.dataEnergyConsumed - this.dataEnergyConsumedCertificate2) * 100) / 100;
        this.valueSavingCo2 = Math.round(((1 - (this.dataCo2EmissionsCertificate2 / this.dataCo2Emissions)) * 100) * 100) / 100;
        this.valueSavingEnergyPrimaryPercent = Math.round(((1 - (this.dataEnergyConsumedCertificate2 / this.dataEnergyConsumed)) * 100) * 100) / 100;
  }

    }else{
      this.dataNumberCertificate2 = 0;
      this.dataCalificationCO2EmissionsCertificate2 = 0;
      this.dataCo2EmissionsCertificate2 = 0;
      this.dataCalificationEnergyPrimaryCertificate2 = 0;
      this.dataEnergyConsumedCertificate2 = 0;

      this.dataCO2Absolute = 0;
      this.dataEnergyAbsolute = 0;

      this.valueSavingCo2 = 0;
      this.valueSavingEnergyPrimaryPercent = 0;
    }
  }

  eventChangeRadioCase(){
    if(this.isCompleteBuilding && this.valueFlexRadioCase == 1){
      this.dataNumberCertificate = this.completeBuilding.getElementsByTagName('codigo')[0].textContent.substring(1, 5);
      this.dataCalificationCEE = this.completeBuilding.getElementsByTagName('cer_emicalificacion')[0].textContent;
      this.dataEnergyConsumed = this.completeBuilding.getElementsByTagName('cer_contotal')[0].textContent;
      this.dataCo2Emissions = this.completeBuilding.getElementsByTagName('cer_emitotal')[0].textContent;
      this.dataCalificationEnergyPrimary = this.completeBuilding.getElementsByTagName('cer_concalificacion')[0].textContent;
      //this.dataBuildingAddress = this.completeBuilding.getElementsByTagName('exp_direccion')[0].textContent;
      this.valueIdentificacionDwelling = this.completeBuilding.getElementsByTagName('ref_referencia')[0].textContent;

      if(this.isDoubleCeeCompleteBuilding == true){
        this.dataNumberCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('codigo')[0].textContent.substring(1, 5);
        this.dataCalificationCO2EmissionsCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('cer_emicalificacion')[0].textContent;
        this.dataCo2EmissionsCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('cer_emitotal')[0].textContent;
        this.dataCalificationEnergyPrimaryCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('cer_concalificacion')[0].textContent;
        this.dataEnergyConsumedCertificate2 = this.doubleCeeCompleteBuilding.getElementsByTagName('cer_contotal')[0].textContent;

        this.dataCO2Absolute = Math.round((this.dataCo2Emissions - this.dataCo2EmissionsCertificate2) * 100) / 100;
        this.dataEnergyAbsolute = Math.round((this.dataEnergyConsumed - this.dataEnergyConsumedCertificate2) * 100) / 100;
        this.valueSavingCo2 = Math.round(((1 - (this.dataCo2EmissionsCertificate2 / this.dataCo2Emissions)) * 100) * 100) / 100;
        this.valueSavingEnergyPrimaryPercent = Math.round(((1 - (this.dataEnergyConsumedCertificate2 / this.dataEnergyConsumed)) * 100) * 100) / 100;

      }else{
        this.dataNumberCertificate2 = 0;
        this.dataCalificationCO2EmissionsCertificate2 = 0;
        this.dataCo2EmissionsCertificate2 = 0;
        this.dataCalificationEnergyPrimaryCertificate2 = 0;
        this.dataEnergyConsumedCertificate2 = 0;

        this.dataCO2Absolute = 0;
        this.dataEnergyAbsolute = 0;

        this.valueSavingCo2 = 0;
        this.valueSavingEnergyPrimaryPercent = 0;
      }

    }

    if(this.isCompleteBuilding && this.valueFlexRadioCase == 2){
      this.dataNumberCertificate = this.arraySingleHome[0].getElementsByTagName('codigo')[0].textContent.substring(1, 5);
      this.dataCalificationCEE = this.arraySingleHome[0].getElementsByTagName('cer_emicalificacion')[0].textContent;
      this.dataEnergyConsumed = this.arraySingleHome[0].getElementsByTagName('cer_contotal')[0].textContent;
      this.dataCo2Emissions = this.arraySingleHome[0].getElementsByTagName('cer_emitotal')[0].textContent;
      this.dataCalificationEnergyPrimary = this.arraySingleHome[0].getElementsByTagName('cer_concalificacion')[0].textContent;
      //this.dataBuildingAddress = this.arraySingleHome[0].getElementsByTagName('exp_direccion')[0].textContent;
      this.valueIdentificacionDwelling = this.arraySingleHome[0].getElementsByTagName('ref_referencia')[0].textContent;


      if(this.arrayDoubleCEE.has(this.valueIdentificacionDwelling)){
        let value: any = this.arrayDoubleCEE.get(this.valueIdentificacionDwelling)
        this.dataNumberCertificate2 = value.getElementsByTagName('codigo')[0].textContent.substring(1, 5);

        if(this.dataNumberCertificate2 != this.dataNumberCertificate){
          this.dataCalificationCO2EmissionsCertificate2 = value.getElementsByTagName('cer_emicalificacion')[0].textContent;
          this.dataCo2EmissionsCertificate2 = value.getElementsByTagName('cer_emitotal')[0].textContent;
          this.dataCalificationEnergyPrimaryCertificate2 = value.getElementsByTagName('cer_concalificacion')[0].textContent;
          this.dataEnergyConsumedCertificate2 = value.getElementsByTagName('cer_contotal')[0].textContent;

          this.dataCO2Absolute = Math.round((this.dataCo2Emissions - this.dataCo2EmissionsCertificate2) * 100) / 100;
          this.dataEnergyAbsolute = Math.round((this.dataEnergyConsumed - this.dataEnergyConsumedCertificate2) * 100) / 100;
          this.valueSavingCo2 = Math.round(((1 - (this.dataCo2EmissionsCertificate2 / this.dataCo2Emissions)) * 100) * 100) / 100;
          this.valueSavingEnergyPrimaryPercent = Math.round(((1 - (this.dataEnergyConsumedCertificate2 / this.dataEnergyConsumed)) * 100) * 100) / 100;
        }


      }else{
        this.dataNumberCertificate2 = 0;
        this.dataCalificationCO2EmissionsCertificate2 = 0;
        this.dataCo2EmissionsCertificate2 = 0;
        this.dataCalificationEnergyPrimaryCertificate2 = 0;
        this.dataEnergyConsumedCertificate2 = 0;

        this.dataCO2Absolute = 0;
        this.dataEnergyAbsolute = 0;

        this.valueSavingCo2 = 0;
        this.valueSavingEnergyPrimaryPercent = 0;
      }
    }
  }

  isNanOrNull(variable: number): boolean{
    if(isNaN(variable) || variable == null || variable == undefined){
      return false;
    }else{
      return true;
    }
  }

  buildingDescriptionChangeEvent(){
    this.lengthBuildingDescription = this.valueBuildingDescription.length;
  }

  interventionDescriptionChangeEvent(){
    this.lengthInterventionDescription = this.valueInterventionDescription.length;
  }

  commentMonitoringChangeEvent(index: number){
    this.lengthCommentMonitoring[index] = this.formFilesArray[index].comment.length;
  }

  valueInvestmentEventChange(){
    let cadena = this.valueInvestment.split(',');
    let indexed = '';

    for(let i = 0; i < cadena.length; i++){
      indexed += cadena[i];
    }

    this.valueInvestmentNumber = parseFloat(indexed);

    if(!((isNaN(this.valueInvestmentNumber) || this.valueInvestment == '' || this.valueInvestment == undefined || this.valueInvestment == null) || (isNaN(this.valuePotSubsidiesPercent) || this.valuePotSubsidiesPercent == undefined || this.valuePotSubsidiesPercent == null))){
      this.valuePotSubsidies = (this.valueInvestmentNumber * this.valuePotSubsidiesPercent) / 100;
    }else{
      this.valuePotSubsidies = 0;
    }


  }

  valueSubsidiesEventChange(){
    let cadena = this.valueSubsidies.split(',');
    let indexed = '';

    for(let i = 0; i < cadena.length; i++){
      indexed += cadena[i];
    }

    this.valueSubsidiesNumber = parseFloat(indexed);

  }

  valuePotSubsidiesPercentEventChange(){
    if(this.valuePotSubsidiesPercent > 100){
      this.valuePotSubsidiesPercent = 100;
    }

    if(this.valuePotSubsidiesPercent < 0){
      this.valuePotSubsidiesPercent = 0;
    }

    if(!((isNaN(this.valueInvestmentNumber) || this.valueInvestment == '' || this.valueInvestment == undefined || this.valueInvestment == null) || (isNaN(this.valuePotSubsidiesPercent) || this.valuePotSubsidiesPercent == undefined || this.valuePotSubsidiesPercent == null))){
      this.valuePotSubsidies = (this.valueInvestmentNumber * this.valuePotSubsidiesPercent) / 100;
    }else{
      this.valuePotSubsidies = 0;
    }
  }

  calculateDataEventChange(){

    if((this.dataEnergyConsumed == null || this.dataEnergyConsumed == undefined || this.dataEnergyConsumed == 0) || (this.dataEnergyConsumedCertificate2 == null || this.dataEnergyConsumedCertificate2 == undefined || this.dataEnergyConsumedCertificate2 == 0)){
      this.valueSavingEnergyPrimaryPercent = 0;
    }else{
      this.valueSavingEnergyPrimaryPercent = Math.round(((1 - (this.dataEnergyConsumedCertificate2 / this.dataEnergyConsumed)) * 100) * 100) / 100;
      //round((1-(${value_epnr_2} div ${value_epnr}))*100,2)
    }

    if((this.dataCo2Emissions == null || this.dataCo2Emissions == undefined || this.dataCo2Emissions == 0)  || (this.dataCo2EmissionsCertificate2 == null || this.dataCo2EmissionsCertificate2 == undefined || this.dataCo2EmissionsCertificate2 == 0)){
      this.valueSavingCo2 = 0;
    }else{
      this.valueSavingCo2 = Math.round(((1 - (this.dataCo2EmissionsCertificate2 / this.dataCo2Emissions)) * 100) * 100) / 100;
      //round((1-(${value_co2_2} div ${value_co2}))*100,2)

    }

    this.dataCO2Absolute = Math.round((this.dataCo2Emissions - this.dataCo2EmissionsCertificate2) * 100) / 100;
    this.dataEnergyAbsolute = Math.round((this.dataEnergyConsumed - this.dataEnergyConsumedCertificate2) * 100) / 100;

  }

  getValuesNL(){
    this.bestPracticesService.getYearConstruction$().subscribe((data: any) => this.dataBuildingYear = data);
    this.bestPracticesService.getCadastreReference$().subscribe((data: string) => this.dataCadastreReference = data);
    this.bestPracticesService.getAddressMap$().subscribe((data: any) => this.dataAddressMap = data);
    this.bestPracticesService.getcoordinates$().subscribe((data: Coordinates) => this.coordinates = data);
    this.administrationPanelService.getGuid$().subscribe((data: any) => this.guid = data);
  }

  initValues(){
    this.dataNumberCertificate = undefined;
    this.dataCalificationCEE = undefined;
    this.dataCalificationEnergyPrimary = undefined;
    this.dataEnergyConsumed = 0;
    this.dataCo2Emissions = 0;
    this.dataCadastreReference = undefined;
    //this.dataBuildingAddress = undefined;
    this.dataBuildingYear = undefined;
    this.dataCo2EmissionsCertificate2 = 0;
    this.dataEnergyConsumedCertificate2 = 0;
    this.dataNumberCertificate2 = undefined;
    this.dataCalificationEnergyPrimaryCertificate2 = undefined;
    this.dataCalificationCO2EmissionsCertificate2 = undefined;
    this.dataCO2Absolute = 0;
    this.dataEnergyAbsolute = 0;
    this.isFavoriteBuilding = undefined;
    this.urlImage = undefined;
    this.idCeeBuilding = undefined;
    this.valueCountry = undefined;
    this.coordinateLong = undefined;
    this.coordinateLat = undefined;
    this.coordinateX = undefined;
    this.coordinateY = undefined;
    this.imageFacade = undefined;

    this.valueTipology = {
      code: null,
      name: null
    }

    this.valueFlexRadioCase = null;
    this.isCaseVisible = false;
    this.isIdentificationDwellingVisible = false;
    this.valueIdentificacionDwelling = null;
    this.dataAddressMap = null;

    this.valueSavingCo2 = 0;
    this.valueSavingEnergyPrimaryPercent = 0;
    this.valueActiveNormative = null;
    this.valueYearReform = null;
    this.valueTypeReform = null;
    this.valueNumberFloors = null;
    this.valueNumberDwellings = null;
    this.valueNumberLocales = null;
    this.valueAreaFloor = null;
    this.valueBuildArea = null;
    this.valueFlexRadioProtected = null;
    this.valueConstructionQuality = null;
    this.valueBuildingDescription = null;
    this.valueFlexRadioWindows = null;
    this.valueSurround = null;
    this.valuePhotovoltaic = null;
    this.valueAcs = null;
    this.valueAerothermal = null;
    this.valueInterventionDescription = null;
    this.valueInvestment = null;
    this.valueSubsidies = null;
    this.valueInvestmentNumber = 0;
    this.valueSubsidiesNumber = 0;

    this.valueInvestmentM2 = 0;
    this.valueSubsidiesPercent = 0;
    this.valueFinalInvestment = 0;
    this.valueFinalInvestmentm2 = 0;
    this.valueFinalInvestmentMonth = 0;
    this.valuePotSubsidies = 0;
    this.valuePotSubsidiesPercent = 0;
    this.valuePotFinalInvestment = 0;
    this.valuePotFinalInvestmentm2 = 0;
    this.valuePotFinalInvestmentMonth = 0;
    this.valueDwellingInvestment = 0;
    this.valueInvestmentDwelling = 0;
    this.valueSubsidiesDwelling = 0;
    this.valueInvestmentDwellingm2 = 0;
    this.valueInvestmentDwellingPercent = 0;
    this.valueFinalInvestmentDwelling = 0;
    this.valueFinalInvestmentDwellingm2 = 0;
    this.valueFinalInvestmentDwellingMonth = 0;
    this.valuePotSubsidiesDwelling = 0;
    this.valuePotSubsidiesDwellingPercent = 0;
    this.valuePotFinalInvestmentDwelling = 0;
    this.valuePotFinalInvestmentDwellingm2 = 0;
    this.valuePotFinalInvestmentDwellingMonth = 0;

    this.valueFlexRadioTestimony = false;
    this.valueName = undefined;
    this.valueRoleName = undefined;
    this.valueQuote = undefined;
    this.valueVideoURL = undefined;
    this.imagesTestimony = [];
    this.isAllImages = true;

    this.valueFlexRadioMonitoring = false;
    this.valueCadastralreferenceunit = undefined;
    this.valueFlexRadioSeason = undefined;
    this.valueFlexRadioPhase = undefined;
    this.valueFlexRadioFamily = undefined;
    this.valueLocation = undefined;
    this.fileMonitoring = null;
    this.isFile = false;
    this.valueInputNumber = 0;
    this.absoluteInputNumber = 0;
    this.valueCurrentIntputNumber = 0;
    this.formFilesArray = [];
    this.csvFiles = [];
    this.guid = "";

    this.isCompleteBuilding = false;
    this.completeBuilding = null;
    this.doubleCeeCompleteBuilding = null;
    this.isDoubleCeeCompleteBuilding = false;

    this.buildingData = null;
    this.arraySingleHome = [];
    this.arrayAddressSingleHome = [];
    this.arrayRefReferenciaSingleHome = [];

    this.isDoubleCeeSingleHome = false;
    this.arrayDoubleCEE = new Map();
    this.doubleCEE = null;
    this.isYearCertificate2 = true;

    this.coordinates = {
      coordinateX: null,
      coordinateY: null,
      coordinateLong: null,
      coordinateLat: null
    };

    this.isThereWasTestimony = undefined;
    this.valueIdTestimony = undefined;

    this.isThereWasMonitoring = undefined;
    this.imagesTestimonyName = '';

    this.renovationPicturesName = '';
    this.isThereWasRenovationPictures = false;
    this.isRenovationPicturesChange = false;
    this.isCsvFileChange = [];
    this.isSpainCountry = false;

    this.arrayTestimony = [];
    this.lengthCommentMonitoring = [];
  }

  addForm(page: number){
    this.idGenerator++;
    switch(page){
      case 4:
        this.arrayTestimony.push({id: this.idGenerator, name: null, role: null, quote: null});
        break;

      case 6:

        this.formFilesArray.push({
          idg: this.idGenerator,
          season_id: null,
          phase_id: null, family_id: null,
          cee_building_id: null,
          variables_id: null,
          location: null,
          comment: null,
          monitoringHealth: []
        });
        this.csvFiles.push(null);
        this.lengthCommentMonitoring.push(0);
        this.isCsvFileChange.push(false);
        this.isFilesFromDB.push(false);
        break;
    }
  }

  deductForm(page: number, index: number){

    switch(page){
      case 4:
        if(this.arrayTestimony.length > 0) {
          this.arrayTestimony.splice(index, 1);
        }
        break;
      case 6:
        if(this.formFilesArray.length > 0) {

          if(this.isUpdateCeeBuilding && this.formFilesArray[index].id != undefined){
            let idMonitoring = this.formFilesArray[index].id;

            this.ceeService.deleteFileIdMonitoring(idMonitoring).toPromise().then((dataFile: any) => {
              console.log('file monitoring ' + idMonitoring + ' deleted -> ' + dataFile);
              this.ceeService.deleteMonitoringHealthIdMonitoring(idMonitoring).toPromise().then((dataHealth: any) => {
                console.log('health monitoring ' + idMonitoring + ' deleted -> ' + dataHealth);

                this.ceeService.deleteMonitoring(idMonitoring).toPromise().then((data: any) => {
                  console.log('monitoring ' + idMonitoring + ' deleted -> ' + data);

                  this.formFilesArray.splice(index, 1);
                  this.csvFiles.splice(index, 1);
                  this.isCsvFileChange.splice(index, 1);
                  this.lengthCommentMonitoring.splice(index, 1);

                })
              })
            })
          }else{
            this.formFilesArray.splice(index, 1);
            this.csvFiles.splice(index, 1);
            this.isCsvFileChange.splice(index, 1);
            this.lengthCommentMonitoring.splice(index, 1);
          }

        }
        break;

    }
  }

  addFormHealth(i: number){
    this.idGenerator++;

    this.formFilesArray[i].monitoringHealth.push(
      {idg: this.idGenerator,
      arrayStressorId: [],
      arraySymptomId: [],
      arrayThermalComfortId: [],
      arrayActivityId: [],
      arrayClothingId: [],
      text: null,
      sex: null,
      age: null})

    console.log(i)

  }

  deductFormHealth(i: number, j: number){
    this.formFilesArray[i].monitoringHealth.splice(j, 1)
  }

  renovationPicturesChangeEvent(event: any){
    this.valueRenovationPictures = [];

    for(let i = 0; i < event.target.files.length; i++){

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.valueRenovationPictures.push(event.target.files[i]);
        console.log('Imagen n' + i + ' -> ', event.target.files[i]);
        this.isRenovationPicturesChange = true;
      }else{
        alert('You must to select a IMAGE');
        this.valueRenovationPictures = [];
      }

      if(i == 4) break;
    }
  }

  facadePictureChangeEvent(event: any){

    this.imageFacade = null;

    if(event.target.files[0].type.substring(0,5) == 'image'){
      this.imageFacade = event.target.files[0];
      console.log('Imagen facade' + ' -> ', event.target.files[0]);
    }else{
      alert('You must to select a IMAGE');
      this.imageFacade = null;
    }

  }

  initRelationHealthId(i: number, dataDB: any){
    this.formFilesArray[i].monitoringHealth = [];

    for(let j = 0; j < dataDB.length; j++){
      this.idGenerator++;
      this.formFilesArray[i].monitoringHealth.push(
        {idg: this.idGenerator,
        id: null,
        arrayStressorId: [],
        arraySymptomId: [],
        arrayThermalComfortId: [],
        arrayActivityId: [],
        arrayClothingId: [],
        text: null,
        sex: null,
        age: null})

        this.formFilesArray[i].monitoringHealth[j].id = dataDB[j].id;
        this.formFilesArray[i].monitoringHealth[j].age = dataDB[j].age;
        this.formFilesArray[i].monitoringHealth[j].sex = dataDB[j].sex;
        this.formFilesArray[i].monitoringHealth[j].text = dataDB[j].text;

        for(let k = 0; k < dataDB[j].arrayActivityId.length; k++){
          this.formFilesArray[i].monitoringHealth[j].arrayActivityId.push(parseInt(dataDB[j].arrayActivityId[k].activity_id))
        }

        for(let k = 0; k < dataDB[j].arrayStressorId.length; k++){
          this.formFilesArray[i].monitoringHealth[j].arrayStressorId.push(parseInt(dataDB[j].arrayStressorId[k].stressor_id))
        }

        for(let k = 0; k < dataDB[j].arraySymptomId.length; k++){
          this.formFilesArray[i].monitoringHealth[j].arraySymptomId.push(parseInt(dataDB[j].arraySymptomId[k].symptom_id))
        }

        for(let k = 0; k < dataDB[j].arrayThermalComfortId.length; k++){
          this.formFilesArray[i].monitoringHealth[j].arrayThermalComfortId.push(parseInt(dataDB[j].arrayThermalComfortId[k].thermalComfort_id))
        }

        for(let k = 0; k < dataDB[j].arrayClothingId.length; k++){
          this.formFilesArray[i].monitoringHealth[j].arrayClothingId.push(parseInt(dataDB[j].arrayClothingId[k].clothing_id))
        }
    }
    console.log('monitoring health formateado -> ', this.formFilesArray[i].monitoringHealth)
  }

}

<main>
  <div class="header row">
    <a class="col-md-2 logo" routerLink="/home">
      <img src="./assets/img/logos/logo-remodulees-transparent.png" class="logo-img">
    </a>
    <a class="col-md-1 menu-option" href="https://re-modulees.eu/">
      <div>
        <h4>
          {{"HEADER.ABOUT_US" | translate}}
        </h4>
      </div>
    </a>
    <a class="col-md-1 menu-option" routerLink="/relab">
      <div>
        <h4>
          {{"HEADER.RE_LAB" | translate}}
        </h4>
      </div>
    </a>
    <a class="col-md-1 menu-option" routerLink="/tools">
      <div >
        <h4>
          {{"HEADER.TOOLS" | translate}}
        </h4>
      </div>
    </a>
    <a class="col-md-1 menu-option" href="https://youtube.com/@Horizonre-MODULEESproject?si=LzWic3Of1OKHy_XV" target="_blank">
      <div >
        <h4>
          {{"HEADER.VIDEOS" | translate}}
        </h4>
      </div>
    </a>
    <div class="col-md-2 logo">

    </div>
    <div class="col-md-4 user-menu row">
      <div class="col-md-6 row form-language">
          <div class="col-md-5">{{"HEADER.SELECT_LANGUAGE" | translate}} &nbsp;</div>
          <div ngbDropdown>
            <button type="button" class="btn btn-outline-primary language-menu" id="dropdownBasic1" ngbDropdownToggle>
              <img src="../../../assets/img/flags/flag_europe.png" *ngIf="selectedLanguage2 === 'EU'" width="30" height="30">
              <img src="../../../assets/img/flags/spain_flag.svg" *ngIf="selectedLanguage2 === 'ES'" width="30" height="30">
              <img src="../../../assets/img/flags/bulgaria_flag.svg" *ngIf="selectedLanguage2 === 'BG'" width="30" height="30">
              <img src="../../../assets/img/flags/francia_flag.svg" *ngIf="selectedLanguage2 === 'FR'" width="30" height="30">
              <img src="../../../assets/img/flags/grecia_flag.svg" *ngIf="selectedLanguage2 === 'GR'" width="30" height="30">
              <img src="../../../assets/img/flags/italia_flag.svg" *ngIf="selectedLanguage2 === 'IT'" width="30" height="30">
              <img src="../../../assets/img/flags/holanda_flag.svg" *ngIf="selectedLanguage2 === 'NL'" width="30" height="30">
              <img src="../../../assets/img/flags/eslovenia_flag.svg" *ngIf="selectedLanguage2 === 'SI'" width="30" height="30">
              <span *ngIf="selectedLanguage2 == undefined"><img src="../../../assets/img/flags/flag_europe.png" width="30" height="30">EU</span>
              <span> {{selectedLanguage2}}</span>
            </button>
            <div class="card-opciones" ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div class="card opciones">
                <ng-container *ngFor="let language of translate.getLangs()" ngbDropdownItem>
                  <li role="menuitem">
                    <a class="dropdown-item" (click)="translateLanguageTo(language)">
                      <img *ngIf="language === 'EU'" src="../../../assets/img/flags/flag_europe.png" width="30" height="30">
                      <img *ngIf="language === 'ES'" src="../../../assets/img/flags/spain_flag.svg" width="30" height="30">
                      <img *ngIf="language === 'BG'" src="../../../assets/img/flags/bulgaria_flag.svg" width="30" height="30">
                      <img *ngIf="language === 'FR'" src="../../../assets/img/flags/francia_flag.svg" width="30" height="30">
                      <img *ngIf="language === 'GR'" src="../../../assets/img/flags/grecia_flag.svg" width="30" height="30">
                      <img *ngIf="language === 'IT'" src="../../../assets/img/flags/italia_flag.svg" width="30" height="30">
                      <img *ngIf="language === 'NL'" src="../../../assets/img/flags/holanda_flag.svg" width="30" height="30">
                      <img *ngIf="language === 'SI'" src="../../../assets/img/flags/eslovenia_flag.svg" width="30" height="30">
                      {{ language }}
                    </a>
                  </li>
                </ng-container>
              </div>
            </div>
          </div>
      </div>
      <div class="col-md-7 row login-buttons" *ngIf="!isUserLogged">
        <div class="col-md-6 join">
          <button type="button" class="rounded-button" (click)="openModalRegister(modalRegister)">
            <h5><strong>{{"HEADER.JOIN_NOW" | translate}}</strong></h5>
          </button>
        </div>
        <button class="col-md-6 login" (click)="openModal(modalLogin, modalCountry)">
          <h4>
            {{"HEADER.LOGIN" | translate}}
          </h4>
        </button>
      </div>

      <div class="col-md-6 login" *ngIf="isUserLogged">
        <div class="row usuario">
          <div class="col">
            <div ngbDropdown>
              <button type="button" class="btn btn-outline-primary boton-user" id="dropdownBasic1" ngbDropdownToggle>
                <img *ngIf="profileImage == null || profileImage == undefined || profileImage == ''" src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" width="25" alt="UserPhoto" class="rounded-circle"> 
                <img *ngIf="profileImage != null" src="{{imageUrl}}/{{profileImage.substring(10)}}" width="25" height="25" alt="UserPhoto" class="rounded-circle">
                {{userLogged.name}}
              </button>
              <div class="card-opciones" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <div class="card opciones">
                  <div class="row">
                    <div class="col-md-4">
                      <img *ngIf="profileImage == null || profileImage == undefined || profileImage == ''" src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" width="50" alt="UserPhoto" class="rounded-circle">
                      <img *ngIf="profileImage != null" src="{{imageUrl}}/{{profileImage.substring(10)}}" width="50" height="50" alt="UserPhoto" class="rounded-circle">
                    </div>
                    <div class="col">
                      <h3>{{userLogged.name}}</h3>
                    </div>
                  </div>
                  <button ngbDropdownItem (click)="goToAccount()">{{'HEADER.USER_OPTIONS.MY_ACCOUNT' | translate}}</button>
                  <!--<button ngbDropdownItem (click)="goProposePanel()">Notifications <b>{{notificationCounter}}</b></button>-->
                  <button ngbDropdownItem (click)="logOut()">{{'HEADER.USER_OPTIONS.LOGOUT' | translate}}</button>
                  <!--<hr>
                  <div>
                    You are a {{currentUser.role}} user
                  </div>
                  <div>
                    <input type="range" class="form-range" min="0" max="12" id="customRange1">
                  </div><br>
                  <div>
                    {{'HEADER.USER_OPTIONS.PROGRESS' | translate}}
                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="role == 1 || role == 2" class="col-md-2">
            <button type="button" class="btn btn-outline-primary" id="administrationButton" (click)="gotToAdministrationPanel()">
              <img src="../../../assets/img/buttons/dashboard_botton.png" width="30" height="30">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <script>
    $(document).ready(function() {
   $('.selectpicker').selectpicker();
});
  </script>
</main>

<!--Modal login-->

<ng-template #modalLogin let-modal>
  <div class="modal-dialog modal-lg" style="text-align: center">
    <div class="modal-content">
      <div class="modal-body">
        <section class="sign-in">
          <div class="container-fluid">
            <div class="signin-content row">
              <div class="signin-image col-md-6">
                <figure><img src="./assets/img/logos/signup-image.jpg" alt="sing up image"></figure>
                <!--<button style="background: transparent; border: 0;" class="btn-link" >Create an account</button>-->
              </div>

              <div class="signin-form col-md-6">
                <h2 class="form-title">{{"MODAL_LOGIN.TEXT_LOGIN" | translate}}</h2>
                <form class="needs-validation">
                  <div class="form-group">
                    <input type="email" placeholder="Email" name="email" [(ngModel)]="bodyLogin.email"/>
                  </div>
                  <div class="form-group">
                    <input type="password" placeholder="Password" name="password" [(ngModel)]="bodyLogin.password"/>
                  </div>
                  <!--<div class="form-group">
                    <label for="remenberMe">Remenber me</label>
                    <input type="checkbox" name="remenberMe" id="remenberMe" [value]="valueRemenberMe">
                  </div>-->
                  <div *ngIf="messageErrorLogin != null" class="form-group">
                    <p class="text-center text-danger mt-4">{{messageErrorLogin}}</p>
                  </div>
                  <button class="btn btn-primary active px-4" (click)="login()">{{"MODAL_LOGIN.BUTTON_LOGIN" | translate}}</button>
                </form>
                <div class="social-login">
                  <span class="social-label">{{"MODAL_LOGIN.TEXT_LOGIN_WITH" | translate}}</span>
                    <ul class="socials">
                    <li><input type="image" name="signin" src="./assets/img/buttons/btn_google_signin_dark_normal_web.png" (click)="loginWithGoogle();"/></li>
                  </ul>
                </div>
                <div>
                  <a (click)="openModalForgotPassword(modalForgotPassword)" style="cursor: pointer; text-decoration: underline;"><p>Forgot your password?</p></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</ng-template>

<!--Modal register-->

<ng-template #modalRegister let-modal>
  <div class="modal-dialog modal-lg" style="text-align: center">
    <div class="modal-content">
      <div class="modal-body">
        <section class="sign-in">
          <div class="container">
            <div class="signup-content row">
              <div class="signup-form col-md-6">
                <h2 class="form-title">{{"MODAL_SIGN_UP.TEXT_SIGN_UP" | translate}}</h2>
                <form  #signUpForm="ngForm">
                  <div class="form-group">
                    <input type="text" placeholder="Your Name" name="name" [(ngModel)]="bodyRegister.name" ngModel [email]="true"/>
                  </div>
                  <div class="form-group">
                    <input type="email" placeholder="Your Email"  name="email" [(ngModel)]="bodyRegister.email"/>
                  </div>
                  <div class="form-group">
                    <input type="password" placeholder="Password" name="pwd" [(ngModel)]="bodyRegister.password">
                  </div>
                  <div class="form-group">
                    <input type="password" placeholder="Repeat Password" name="repPwd" [(ngModel)]="bodyRegister.password2">
                  </div>
                  <div class="form-group">
                    <label for="country">{{"MODAL_SIGN_UP.TEXT_SELECT_COUNTRY" | translate}} &nbsp;</label>
                    <select name="country" [(ngModel)]="bodyRegister.country">
                      <option *ngFor="let country of arrayCountry; index as i" [value]="country.id">{{country.country}}</option>
                    </select>
                  </div>
                  <div class="form-group" *ngIf="messageErrorRegister != null">
                    <p class="text-center text-danger mt-4">{{messageErrorRegister}}</p>
                  </div>
                  <button class="btn btn-primary active px-4" (click)="registerUser()">{{"MODAL_SIGN_UP.BUTTON_REGISTER" | translate}}</button>
                </form>
              </div>
              <div class="signup-image col-md-6">
                <figure><img src="./assets/img/logos/signup-image.jpg" alt="sing up image"></figure>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</ng-template>

<!--Modal select country-->


<ng-template #modalCountry let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Select country</h4>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<p>You must to select a country to navigate on the website</p>
        <label for="role">Country</label>
				<div class="input-group">
          <select class="custom-select" name="selectRole" id="selectRole" [(ngModel)]="valueCountryUserRegister">
            <option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</option>
          </select>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
	</div>
</ng-template>

<!--Modal forgot password-->


<ng-template #modalForgotPassword let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title" style="color: #004470;">Reset password</h4>
    <div class="col-md-2">
      <button type="button" class="btn btn-default row" (click)="modal.close()">
        <h5>
          {{closeBtnName}} <i class="fa fa-times" aria-hidden="true"></i>
        </h5>
      </button>
    </div>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<p style="font-weight: bold;">Please enter your email in order to reset your password. We will send you an email to your account.</p>
				<div class="form-group">
          <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="bodyForgotPassword.email"/>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="passwordReset()">Send</button>
	</div>
</ng-template>

<div class="alert alert-primary alerta" role="alert" *ngIf="showAlert">
  {{messageNotification}}
</div>

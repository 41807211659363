import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import { Subscription } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { MenuService } from 'src/app/core/menu/menu.service';
import { ToolsService } from 'src/app/core/tools/tools.service';
import { Country } from 'src/app/shared/models/country';
import { Menu } from 'src/app/shared/models/menu';
import { MenuTool } from 'src/app/shared/models/menuTool';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Step } from 'src/app/shared/models/step';
import { Stop, StopTools } from 'src/app/shared/models/stop';
import { ToolsNew } from 'src/app/shared/models/toolsnew';
import { User } from 'src/app/shared/models/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'src/app/core/authentication/user.service';

@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  styleUrls: ['./menu-form.component.scss']
})
export class MenuFormComponent implements OnInit, OnDestroy {

  window: number = 2;

  @Input() arrayCountry: Country[] = [];

  arrayMenu: Menu[] = [];
  arrayTool: any[] = [];
  arrayStep: Step[] = [];
  arrayStop: Stop[] = [];

  tmpArrayTool: any[] = [];
  tmpArrayMenu: Menu[] = [];

  getArrayTool: Subscription = null;
  getarrayMenu: Subscription = null;
  getArrayStep: Subscription = null;
  getArrayStop: Subscription = null;

  toolsSelected: any[] = [];

  valueName: string = '';
  valueShortDescription: string = '';
  valueCountry: number = null;
  valueStopId: number[] = [];
  valueStop: Stop[] = [];
  valueToolsId: any[] = [];

  idToUpdate: number = 0;
  idOldMenuStop: number[] = [];

  isMenuCreated: boolean = false;

  //pagination
  pageMenu: number = 1;
  pageSizeMenu: number = 5;

  //filter
  valueFilterName: string = '';
  valueFilterCountry: number = null;
  valueFilterStop: number = null;
  valueFilterStep: number = null;

  valueFilterCountryMenu: number = null;
  valueFilterNameMenu: string = '';
  valueFilterStopUidMenu: number = null;

  isUserLogged: boolean;
  currentUser: User = new User();

  constructor(
      private toolService: ToolsService,
      private administrationPanelService: AdministrationPanelService,
      private menuService: MenuService,
      private modalService: NgbModal,
      public afAuth: AngularFireAuth,
      private userService: UserService
  ) {}

  ngOnInit() {
    this.checkLogin();
    this.getMenus();
    this.getTools();
    this.getStopStep();
  }

  ngOnDestroy(): void {
    if(this.getArrayTool != null) this.getArrayTool.unsubscribe();
    if(this.getarrayMenu != null) this.getarrayMenu.unsubscribe();
    if(this.getArrayStep != null) this.getArrayStep.unsubscribe();
    if(this.getArrayStop != null) this.getArrayStop.unsubscribe();
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          console.log(userFromDB['data'].user_roles[0].role_id)
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;
            this.currentUser.user_roles = userFromDB['data'].user_roles;
            if(userFromDB['data'].user_roles[0].role_id == 2){
              this.applyAutoFilter();
            }
          }
          console.log(this.currentUser)
        });
      } else {
        this.isUserLogged = false;
      }
    });
  }

  applyAutoFilter(): void {
    // Supongamos que tienes un mapeo entre los países y los valores del filtro
    const countryFilterMapping = {
      3: 3,
      10: 10,
      12: 12,
      15: 15,
      20: 20,
      25: 25,
      26: 26,
    };

    // Obtener el valor del filtro según el país del usuario
    const autoFilterValue = countryFilterMapping[this.currentUser.country];

    // Aplicar el filtro automáticamente
    if (autoFilterValue) {
      this.valueFilterCountryMenu = autoFilterValue;

      this.arrayMenu = this.tmpArrayMenu.filter(menu => menu.country_id == this.valueFilterCountryMenu);
      // Aquí puedes llamar a tu lógica de filtrado manual si es necesario
      //this.applyFilter();
    }
  }

  getMenus(){
    this.getarrayMenu = this.administrationPanelService.getArrayMenu$().subscribe((data: Menu[]) => {
      console.log(this.currentUser)
      if(data.length == 0){
        this.menuService.getAll().toPromise().then((dataDb: NodeResponse) => {
          console.log(dataDb);
          if(dataDb.status == true){
            this.arrayMenu = dataDb.data;
            this.tmpArrayMenu = this.arrayMenu;

            this.administrationPanelService.setArrayMenu(dataDb.data);
            //this.formatTable('menu');
          }
        })
      }else{
        console.log('data Menu service -> ', data)
        this.arrayMenu = data;
        this.tmpArrayMenu = this.arrayMenu;
      }
    })
  }

  getTools(){

    this.toolService.getToolStop().toPromise().then((dataDB: NodeResponse) => {

      console.log('data tool DB -> ', dataDB)
      this.arrayTool = dataDB.data;
    })
  }

  getStopStep(){
    this.getArrayStep = this.administrationPanelService.getArrayStep$().subscribe((data: Step[]) => {

      if(data.length == 0){
        this.toolService.getSteps().toPromise().then((dataDb: NodeResponse) => {

          console.log('data step DB -> ', dataDb)
          this.arrayStep = dataDb.data;
          this.administrationPanelService.setArrayStep(dataDb.data);
        })
      }else{
        console.log('data step service -> ', data);
        this.arrayStep = data;
      }
    })

    this.getArrayStop = this.administrationPanelService.getArrayStop$().subscribe((data: Stop[]) => {

      if(data.length == 0){
        this.toolService.getStops().toPromise().then((dataDb: NodeResponse) => {

          console.log('data stop DB -> ', dataDb)
          this.arrayStop = dataDb.data;
          this.administrationPanelService.setArrayStop(dataDb.data);
        })
      }else{
        console.log('data stop service -> ', data);
        this.arrayStop = data;
      }
    })
  }

  toMenuTable(){
    this.window = 1;
    this.initValues();
  }

  toCreateMenu(){
    this.window = 2;
    this.initValues();
  }

  applyFilter(){
    this.tmpArrayMenu = [];
    this.tmpArrayMenu = this.arrayMenu;

    console.log(this.tmpArrayMenu)

    if(this.valueFilterCountryMenu != null){
      this.tmpArrayMenu = this.tmpArrayMenu.filter(menu => menu.country_id == this.valueFilterCountryMenu);
      console.log(this.currentUser.country)
      console.log(this.tmpArrayMenu);
    }

    if(this.valueFilterNameMenu != null && this.valueFilterNameMenu != undefined && this.valueFilterNameMenu != ''){
      this.tmpArrayMenu = this.tmpArrayMenu.filter(menu => menu.name.includes(this.valueFilterNameMenu));
    }
  }

  deleteAllFilters(section: string){
    switch(section){
      case 'menu':
        this.tmpArrayMenu =  this.arrayMenu;

        this.valueFilterStopUidMenu = null;
        if (this.currentUser.user_roles[0].role_id !== 2) {
          this.valueFilterCountryMenu = null;
        }
        this.valueFilterNameMenu = '';
        break;
    }
  }

  refreshTable(section: string){
    switch(section){
      case 'menu':

        this.menuService.getAll().toPromise().then((dataDB: NodeResponse) => {

          console.log(dataDB);

          if(dataDB.status == true){

            this.arrayMenu = dataDB.data;
            this.administrationPanelService.setArrayMenu(dataDB.data);
            this.deleteAllFilters('menu');
          }
        })

        break;
    }
  }

  createMenu(){

    if(this.isFilled()){
      if(confirm('Do you want to create this menu?') == true){
        /*let idTools = [];
        this.toolsSelected.forEach(tool => idTools.push(tool.uid))*/

        /*let stopsId = [];
        this.valueStopId.forEach(stop => stopsId.push(stop.id));*/

        this.menuService.create(this.valueName, this.valueShortDescription, this.valueCountry, this.valueStopId, this.valueToolsId).toPromise().then((dataMenu: NodeResponse) => {
          console.log(dataMenu)

          if(dataMenu.status){
            this.arrayMenu.push(dataMenu.data);
            this.administrationPanelService.setArrayMenu(this.arrayMenu);

            this.toMenuTable();
          }
        })
      }
    }else{
      alert('You need to fill out whole form')
    }

  }

  isFilled(): boolean{
    let value1 = true;
    let value2 = true;

    if(this.valueName == '' || this.valueShortDescription == '' || this.valueCountry == null)
      value1 = false;

    if(this.valueStopId.length == 0){
      value2 = false;
    }else{
      this.valueStopId.forEach(current => {
        /*if(current.tools.length == 0){
          value2 = false;
        }*/
      })
    }

    return (value1 && value2);
  }

  initUpdateMenu(menu: any) {

    this.initValues();

    this.idToUpdate = menu.id;

    this.valueName = menu.name;
    this.valueShortDescription = menu.short_description;
    this.valueCountry = menu.country_id;



    for(let i = 0; i < menu.menu_stops.length; i++){
      this.valueStopId.push(menu.menu_stops[i].stop.id);
      this.valueToolsId.push([]);
      this.idOldMenuStop.push(menu.menu_stops[i].id);

      for(let j = 0; j < menu.menu_stops[i].menuStop_tools.length; j++){
        this.valueToolsId[i].push(menu.menu_stops[i].menuStop_tools[j].tool.uid);
      }

    }

    this.selectStopChangeEvent();

    this.window = 3;
	}

  updateMenu(){
    //if(!(this.valueName == null || this.valueName == '' ||  this.valueShortDescription == null ||  this.valueShortDescription == '' || this.valueCountry == null || this.valueCountry == 0 || this.toolsSelected.length == 0 || this.valueStopId.length == 0)){

      if(confirm('Do you want to update this menu?') == true){
        let idTools = [];
        this.toolsSelected.forEach(tool => idTools.push(tool.uid))

        let stopsId = [];
        //this.valueStopId.forEach(stop => stopsId.push(stop.id));

        this.menuService.update(this.idToUpdate, this.valueName, this.valueShortDescription, this.valueCountry, this.valueStopId, this.valueToolsId, this.idOldMenuStop).toPromise().then((dataMenu: NodeResponse) => {
          console.log(dataMenu)

          if(dataMenu.status){
            let index = this.arrayMenu.findIndex(menu => menu.id == this.idToUpdate);
            this.arrayMenu.splice(index, 1);
            this.arrayMenu.push(dataMenu.data);

            this.toMenuTable();
          }
        })
      }
    /*}else{
      alert('You must to complete whole form')
    }*/
  }

  deleteMenu(menu: Menu){

    console.log('info menu -> index: ' + this.arrayMenu.findIndex(e => e.id == menu.id) + ' id: ' + menu.id);

    let arrayIdMenuStop = [];

    for(let i = 0; i < menu.menu_stops.length; i++){
      arrayIdMenuStop.push(menu.menu_stops[i].id);
    }

    if(confirm('Do you want to delete this menu?') == true){
      this.menuService.delete(menu.id, arrayIdMenuStop).toPromise().then((data: NodeResponse) => {
        console.log(data)

        let index = this.arrayMenu.findIndex(e => e.id == menu.id);
        this.arrayMenu.splice(index, 1);

        this.administrationPanelService.setArrayMenu(this.arrayMenu);
        this.initValues();
      })
    }

  }

  initValues(){
    this.valueName = '';
    this.valueShortDescription = '';
    this.valueCountry = null;
    this.valueStopId = [];
    this.valueStop = [];
    this.valueToolsId = [];

    this.idToUpdate = null;

    this.deleteAllFilters('menu');
  }

  selectStopChangeEvent(){
    this.valueStop = [];

    for(let i = 0; i < this.valueStopId.length; i++){
      for(let j = 0; j < this.arrayStop.length; j++){
        if(this.valueStopId[i] == this.arrayStop[j].id){
          this.valueStop.push(this.arrayStop[j]);
          break;
        }
      }
    }
  }

  getArrayToolByStop(stopId: number): any[]{

    let value: any[] = [];

    for(let i = 0; i < this.arrayTool.length; i++){
      if(this.arrayTool[i].tools_stops.filter(stop => stop.stop_id == stopId).length > 0){
        value.push(this.arrayTool[i])
      }
    }

    return value;
  }

  consoleJorge(){
    console.log(this.valueStopId, this.valueToolsId)
  }

  workInProgres(){
    alert('We disable this action because we are working in some tasks')
  }

}

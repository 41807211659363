import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { GlobalConstants} from '../../shared/GlobalConstants';
import { CeeBuilding } from '../../shared/models/ceeBuilding';
import { id } from '@swimlane/ngx-charts';
import { Monitoring } from '../../shared/models/monitoring';

@Injectable({
  providedIn: 'root'
})
export class CeeService {

  private ceeByURL = 'https://terramapas.icv.gva.es/certificados_energeticos?';
  private urlMonitoring = GlobalConstants.backendURL + "/api/monitoring";
  private urlMonitoringHealth = GlobalConstants.backendURL + "/api/monitoringHealth";
  private urlFile = GlobalConstants.backendURL + "/api/file";
  private urlTestimony = GlobalConstants.backendURL + "/api/testimony";
  private urlImage = GlobalConstants.backendURL + "/api/image";
  private urlCeeBuilding = GlobalConstants.backendURL + "/api/cee_building";
  private urlUserCeeBuilding = GlobalConstants.backendURL + "/api/user_cee_building";
  private urlFamily = GlobalConstants.backendURL + "/api/family";
  private urlPhase = GlobalConstants.backendURL + "/api/phase";
  private urlSeason = GlobalConstants.backendURL + "/api/season";
  private urlVariable = GlobalConstants.backendURL + "/api/variable";
  private urlTipology = GlobalConstants.backendURL + "/api/typology";
  private urlCase = GlobalConstants.backendURL + "/api/case";
  private urlRenovationPictures = GlobalConstants.backendURL + "/api/renovation_pictures";

  constructor(private http: HttpClient) { }

  getGeneralInfoCEE(coorX: string, coorY: string): Promise<any>{
    const header = new HttpHeaders({Accept: 'application/xml'});
    const coorx2 = +coorX + .000001;
    const coory2 = +coorY + .000001;
    const bbox = coorX + ',' + coorY + ',' + String(coorx2) + ',' + String(coory2);
    const params = new HttpParams()
      .set('SERVICE', 'WMS')
      .set('QUERY_LAYERS', 'certificados')
      .set('VERSION', '1.3.0')
      .set('INFO_FORMAT', 'application/vnd.ogc.gml')
      .set('LAYERS', 'certificados')
      .set('REQUEST', 'GetFeatureInfo')
      .set('FEATURE_COUNT', '150')
      .set('LANGUAGE', 'spa')
      .set('WIDTH', '1446')
      .set('HEIGHT', '923')
      .set('BBOX', bbox)
      .set('CRS', 'EPSG:25830')
      .set('I', '868')
      .set('J', '202')
    const options = {
      header,
      params,
      responseType: 'text' as 'text'
    };
    return this.http.request('GET', this.ceeByURL, options).toPromise();
  }

  getAllCeeBuilding(){
    return this.http.get(this.urlCeeBuilding);
  }

  getAllCeeBuildingProposed(){
    return this.http.get(this.urlCeeBuilding + '/proposed');
  }

  getCeeBuildingId(id: number){
    return this.http.get(this.urlCeeBuilding + '/' + id);
  }

  getCeeBuildingByUid(uid: string){
    return this.http.get(this.urlCeeBuilding + '/user/' + uid);
  }

  changeStatusCeeBuilding(id: number, status_id: number, adminComment: string){
    return this.http.put(this.urlCeeBuilding + '/status', {id: id, status_id: status_id, adminComment: adminComment});
  }

  getTestimony(id: number){
    return this.http.get(this.urlTestimony + '/' + id);
  }

  getTestimonyIdCeeBuilding(idCeeBuilding: number){
    return this.http.get(this.urlTestimony + '/idCeeBuilding/' + idCeeBuilding);
  }

  getMonitoringIdCeeBuilding(idCeeBuilding: number){
    return this.http.get(this.urlMonitoring + '/idCeeBuilding/' + idCeeBuilding);
  }

  getGuidUserCeeBuildings(guid: string){
    return this.http.get(this.urlUserCeeBuilding + "/userId/" + guid);
  }

  getFileIdMonitoring(idMonitoring: number){
    return this.http.get(this.urlFile + '/idMonitoring/' + idMonitoring);
  }

  addCeeBuilding(body: any){
    return this.http.post(this.urlCeeBuilding, body);
  }

  addMonitoring(body: any){
    return this.http.post(this.urlMonitoring, body);
  }

  addMonitoringHealth(
    age: number,
    sex: number,
    arrayStressorId: number[],
    arraySymptomId: number[],
    arrayThermalComfortId: number[],
    arrayActivityId: number[],
    arrayClothingId: number[],
    text: string,
    monitoring_id: number
  ){

    let body = {
      age: age,
      sex: sex,
      arrayStressorId: arrayStressorId,
      arraySymptomId: arraySymptomId,
      arrayThermalComfortId: arrayThermalComfortId,
      arrayActivityId: arrayActivityId,
      arrayClothingId: arrayClothingId,
      text: text,
      monitoring_id: monitoring_id
    }

    console.log(body)

    return this.http.post(this.urlMonitoringHealth, body);
  }

  updateMonitoringHealth(age: number,
    sex: number,
    arrayStressorId: number[],
    arraySymptomId: number[],
    arrayThermalComfortId: number[],
    arrayActivityId: number[],
    arrayClothingId: number[],
    text: string,
    monitoring_id: number,
    isFirst: boolean
  ){
    let body = {
      age: age,
      sex: sex,
      arrayStressorId: arrayStressorId,
      arraySymptomId: arraySymptomId,
      arrayThermalComfortId: arrayThermalComfortId,
      arrayActivityId: arrayActivityId,
      arrayClothingId: arrayClothingId,
      text: text,
      monitoring_id: monitoring_id,
      isFirst: isFirst
    }

    console.log(body)

    return this.http.put(this.urlMonitoringHealth, body);
  }

  deleteMonitoringHealth(id: number){
    return this.http.delete(this.urlMonitoringHealth + '/' + id);
  }

  deleteMonitoringHealthIdMonitoring(idMonitoring: number){
    return this.http.delete(this.urlMonitoringHealth + '/idMonitoring/' + idMonitoring);
  }

  getMonitoringHealthByIdMonitoring(idMonitoring: number){
    return this.http.get(this.urlMonitoringHealth + '/idMonitoring/' + idMonitoring);
  }

  addTestimony(body: any){
    return this.http.post(this.urlTestimony, body);
  }

  uploadFile(file: any){
    return this.http.post(this.urlFile, file);
  }

  uploadFile2(file: any){
    return this.http.post(this.urlFile + '/' + 2, file);
  }

  uploadImage(arrayImages: any){
    return this.http.post(this.urlImage, arrayImages);
  }

  addUserCeeBuilding(body: any){
    return this.http.post(this.urlUserCeeBuilding, body);
  }

  deleteCeeBuilding(id: number){
    return this.http.delete(this.urlCeeBuilding + '/' + id);
  }

  deleteUserCeeBuildingBothId(ids: any){
    return this.http.post(this.urlUserCeeBuilding + '/delete/idUserIdCeeBuilding', ids);
  }

  deleteTestimonyIdCeeBuilding(idCeeBuilding: number){
    return this.http.delete(this.urlTestimony + '/idCeeBuilding/' + idCeeBuilding);
  }

  deleteImagesidCeeBuilding(idCeeBuilding: number){
    return this.http.delete(this.urlImage + '/idCeeBuilding/' + idCeeBuilding);
  }

  getImagesIdCeeBuilding(idCeeBuilding: number){
    return this.http.get(this.urlImage + '/ceeBuilding/' + idCeeBuilding);
  }

  deleteFileIdMonitoring(idMonitoring: number){
    return this.http.delete(this.urlFile + '/idMonitoring/' + idMonitoring);
  }

  deleteMonitoring(id: number){
    return this.http.delete(this.urlMonitoring + '/' + id)
  }

  deleteMonitoringIdCeeBuilding(idCeeBuilding: number){
    return this.http.delete(this.urlMonitoring + '/idCeeBuilding/' + idCeeBuilding)
  }

  updateCeeBuilding(body: any){
    return this.http.put(this.urlCeeBuilding, body);
  }

  updateTestimony(body: any){
    return this.http.put(this.urlTestimony, body);
  }

  updateImagesIdCeeBuilding(body: any){
    return this.http.post(this.urlImage + '/updateIdCeebuilding', body);
  }

  updateMonitoring(body: Monitoring){
    return this.http.put(this.urlMonitoring, body);
  }

  updateFile(body: any){
    return this.http.put(this.urlFile, body);
  }

  updateFile2(body: any){
    return this.http.put(this.urlFile + '/2', body);
  }

  getFamily(){
    return this.http.get(this.urlFamily);
  }

  getSeason(){
    return this.http.get(this.urlSeason);
  }

  getPhase(){
    return this.http.get(this.urlPhase);
  }

  getTipology(){
    return this.http.get(this.urlTipology);
  }

  getCase(){
    return this.http.get(this.urlCase);
  }

  getVariable(){
    return this.http.get(this.urlVariable );
  }

  uploadRenovationPictures(image: any){
    return this.http.post(this.urlRenovationPictures, image);
  }

  getRenovationPicturesIdCeeBuilding(idCeeBuilding: number){
    return this.http.get(this.urlRenovationPictures + '/' + idCeeBuilding);
  }

  updateRenovationPictures(image: any){
    return this.http.put(this.urlRenovationPictures, image);
  }

  deleteRenovationPicturesIdCeeBuilding(idCeeBuilding: number){
    return this.http.delete(this.urlRenovationPictures + '/' + idCeeBuilding);
  }

  saveUserCeeBuilding(idCeeBuilding: number, idUser: string){
    return this.http.put(this.urlCeeBuilding + '/' + idCeeBuilding + '/user/' + idUser, {});
  }

  deleteASavedUserCeeBuilding(idCeeBuilding: number, idUser: string){
    return this.http.delete(this.urlCeeBuilding + '/' + idCeeBuilding + '/user/' + idUser);
  }

  isSavedCeeBuilding(idCeeBuilding: number, idUser: string){
    return this.http.get(this.urlCeeBuilding + '/' + idCeeBuilding + '/user/' + idUser);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { BestPracticesService } from 'src/app/core/binding-data/best-practices/best-practices.service';
import { CeeService } from 'src/app/core/wms/cee.service';
import { CeeBuilding } from 'src/app/shared/models/ceeBuilding';
import { Monitoring } from 'src/app/shared/models/monitoring';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Testimony } from 'src/app/shared/models/testimony';

@Component({
  selector: 'app-ceebuilding-list',
  templateUrl: './ceebuilding-list.component.html',
  styleUrls: ['./ceebuilding-list.component.scss']
})
export class CeebuildingListComponent implements OnInit {

  @Output() historyUsersEmitter = new EventEmitter<any>();
  @Output() buildingToBeUpdatedEmitter = new EventEmitter<any>();
  @Output() buildingSelectedEmitter = new EventEmitter<any>();
  @Output() historyEmitter = new EventEmitter<any>();

  @Input() history: CeeBuilding[] = [];
  @Input() role: number = 0;

  filterApplied: string[] = [];

  filterCountries: string[];
  filterTypologies: { code: string, name: string}[];
  filterYears: string[];

  searchText: any;

  typologySelected: string;
  countrySelected: string;

  yearControl: boolean;
  yearSelectedMin: any;
  yearSelectedMax: any;

  ceeBuilding: any[] = [];
  tmpCeeBuilding: any[] = [];

  //pagination
  page: number = 1;
  pageSize: number = 5;

  guid: string = null;

  constructor(private ceeService: CeeService, private administrationPanelService: AdministrationPanelService, private bestPracticesService: BestPracticesService,) { }

  ngOnInit(): void {
    this.getCeeBuildings();
  }

  getCeeBuildings(){

    this.ceeBuilding = [];
    this.tmpCeeBuilding = [];

    this.ceeService.getAllCeeBuilding().toPromise().then((data: any) => {
      console.log('cee building validados => ', data)

      if(data.length > 0){

        for(let i = 0; i < data.length; i++){
          if(data[i].status_id == 1) this.ceeBuilding.push(data[i])
        }

        this.tmpCeeBuilding = this.ceeBuilding;

        this.buildFilters();
        this.historyUsersEmitter.emit(this.ceeBuilding);
      }
    })
  }

  showBuildingResults(building: any): void {
    this.buildingSelectedEmitter.emit(building);
  }

  updateBuilding(building: any): void {
    this.buildingToBeUpdatedEmitter.emit(building);
  }

  async removeCEEBuildingFromUserHistory(id: number, index: number) {

    if(confirm('do you want to delete this building?')){


        await this.ceeService.deleteRenovationPicturesIdCeeBuilding(id).toPromise().then(async (data: NodeResponse) => {
          console.log('renovation pictures delete -> ', data)
        })

        await this.ceeService.getTestimonyIdCeeBuilding(id).toPromise().then(async (data: Testimony) => {
          if(data != null){
            let testimony = data;

            console.log("testimony para el delete -> ",testimony)

            await this.ceeService.deleteImagesidCeeBuilding(id).toPromise().then(async (data: Testimony)=> {
              console.log(data);

              await this.ceeService.deleteTestimonyIdCeeBuilding(id).toPromise().then((data: any) => {
                console.log(data);
              })
            })
          }
        })

        await this.ceeService.getMonitoringIdCeeBuilding(id).toPromise().then(async (data: Monitoring[]) => {
          if(data != null){
            let monitoring = data;

            console.log("monitoring para el delete -> ", monitoring);

            for(let i = 0; i < monitoring.length; i++){
              await this.ceeService.deleteFileIdMonitoring(monitoring[i].id).toPromise().then(async (data: any) => {
                console.log(data);
              })

              await this.ceeService.deleteMonitoringHealthIdMonitoring(monitoring[i].id).toPromise().then(async (data: any) => {
                console.log(data);
              })

            }

            await this.ceeService.deleteMonitoringIdCeeBuilding(id).toPromise().then((data: any) => {
              console.log(data);
            })

          }
        })

        await this.ceeService.deleteCeeBuilding(id).toPromise().then(async (data: any) => {
          console.log(data);

          this.tmpCeeBuilding.splice(index, 1);
          this.bestPracticesService.setRefreshUserCeeBuilding();

          for(let i = 0; i < this.history.length; i++){
            if(this.history[i].id == id){
              this.history.splice(i, 1);
              this.historyEmitter.emit(this.history);
              break;
            }
          }
        })
    }
  }

  buildFilters() {
    this.filterCountries = [];
    this.filterTypologies = [];
    this.filterYears = [];
    const currentTime = new Date();
    for ( let i = currentTime.getFullYear(); i > 0; i --) {
      this.filterYears.push(i.toString());
    }
    this.tmpCeeBuilding.forEach( ( hist: any) => {
      if ( this.filterCountries.length === 0 || !this.filterCountries.includes(hist.country)) {
        this.filterCountries.push(hist.country);
      }
      if (this.filterTypologies.length === 0 || !this.filterTypologies.find(item => item.code === hist.tipology_id)) {
        const typo = { code: hist.tipology_id, name: hist.tipology_id};
        this.filterTypologies.push(typo);
      }
      /*if (this.filterYears.length === 0 || !this.filterYears.includes(hist.year)) {
        this.filterYears.push(hist.year);
      }*/
    });
  }

  filter(type: string): void {
    if ( ( type === 'country' && this.countrySelected !== null ) ||
      ( type === 'yearMin' && this.yearSelectedMin !== null ) ||
      ( type === 'yearMax' && this.yearSelectedMax !== null ) ||
      ( type === 'typology' && this.typologySelected !== null )){

      const indexFilterApplied = this.filterApplied.indexOf(type);
      if (indexFilterApplied < 0) {
        this.filterApplied.push(type);
      }
    } else {
      this.cleanFilter(type);
    }
    let arrayFiltered = [];
    this.ceeBuilding.forEach(el => {
      arrayFiltered.push(el);
    });
    this.filterApplied.forEach( filter => {
      if (filter === 'yearMin') {
        const filterByYear  = [];
        this.ceeBuilding.forEach( hist => {
          if ( hist.year >= this.yearSelectedMin) {
            filterByYear.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByYear);
      }
      if (filter === 'yearMax') {
        const filterByYear  = [];
        this.ceeBuilding.forEach( hist => {
          if ( hist.year <= this.yearSelectedMax) {
            filterByYear.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByYear);
      }
      if (filter === 'country') {
        const filterByUse  = [];
        this.ceeBuilding.forEach( hist => {
          if ( hist.country === this.countrySelected) {
            filterByUse.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByUse);
      }

      if (filter === 'typology') {
        const filterByTypology  = [];
        this.ceeBuilding.forEach( hist => {
          if ( hist.typology.categoryCode === this.typologySelected) {
            filterByTypology.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByTypology);
      }
    });
    this.tmpCeeBuilding = arrayFiltered;
    this.buildFilters();
    this.historyUsersEmitter.emit(this.tmpCeeBuilding);
  }

  cleanFilter(type: string) {
    const indexFilterApplied = this.filterApplied.indexOf(type);
    if (indexFilterApplied > -1) {
      this.filterApplied.splice(indexFilterApplied, 1);
    }
  }

  removeElementsFromArray(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }

}

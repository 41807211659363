import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { CountryService } from 'src/app/core/country/country.service';
import { Country } from 'src/app/shared/models/country';
import { NodeResponse } from 'src/app/shared/models/node_response';

@Component({
  selector: 'app-administration-panel',
  templateUrl: './administration-panel.component.html',
  styleUrls: ['./administration-panel.component.scss']
})
export class AdministrationPanelComponent implements OnInit, OnDestroy {

  arrayCountry: Country[] = [];
  guid: string = '';

  getArrayCountry: Subscription = null;
  getGuid: Subscription = null;

  menu: number = 0;

  constructor(private administrationPanelService: AdministrationPanelService, private countryService: CountryService, private router: Router) {}

  ngOnInit(): void {
    this.getCountries();
    this.getGuid = this.administrationPanelService.getGuid$().subscribe((data: string) => {
      this.guid = data;
    })
  }

  ngOnDestroy(): void {
    if(this.getArrayCountry != null) this.getArrayCountry.unsubscribe();
    if(this.getGuid != null) this.getGuid.unsubscribe();
  }

  getCountries(){
    this.getArrayCountry = this.administrationPanelService.getArrayCountry$().subscribe((data: Country[]) => {

      if(data.length == 0){
        this.countryService.getCountries().toPromise().then((dataDB: NodeResponse) => {
          console.log('countries DB -> ', dataDB);

          if(dataDB.status){
            this.arrayCountry = dataDB.data
            this.administrationPanelService.setArrayCountry(this.arrayCountry);
          }
        })
      }else{
        this.arrayCountry = data
      }
    })
  }

  goToBestPractices(){
    this.router.navigate(['/', 'bestpractices'])
  }

  switchMenu(i: number){
    this.menu = i;
  }

}

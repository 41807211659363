import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from '../../core/authentication/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AdministrationPanelService } from '../../core/binding-data/administration-panel/administration-panel.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NodeResponse } from 'src/app/shared/models/node_response';
import firebase from 'firebase/app';
import { CountryService } from 'src/app/core/country/country.service';
import { Country } from 'src/app/shared/models/country';
import { NotificationService } from 'src/app/core/notification/notification.service';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent implements OnInit, OnDestroy {

  closeBtnName?: string;
  arrayCountry: Country[] = [];

  selectedFile: File | null = null;
  selectedImage: File | null = null;

  showProffesionalForm = false;
  declarationAccepted = false;

  isUserLogged: boolean = false;
  userLogged: any = null;

  messageErrorLogin: string = null;
  messageErrorRegister: string = null;

  modalCountry: any = null;
  valueCountryUserRegister: number = 1;

  modalForgotPassword: any = null;

  showLogin: boolean;
  selectedLanguage: any;
  selectedLanguage2: any;
  noLanguage: boolean;

  valueSelectLanguageRegister: string[] = [];

  role: number = null;

  modalReference: NgbModalRef = null;

  bodyLogin: any = {
    email: '',
    password: ''
  }

  /*bodyLogin: any = {
    email: 'autologin2@gmail.com',
    password: '12345_asdfg'
  }*/

  bodyRegister: any = {
    email: '',
    password: '',
    name: '',
    password2: '',
    country: 0
  };

  bodyForgotPassword: any = {
    email: ''
  }

  valueRemenberMe: boolean = false;

  //notfification
  notificationCounter: number = 0;
  showAlert: boolean = false;
  messageNotification: string = '';
  arrayNotification: any[] = [];

  profileImage: string;
  imageUrl = GlobalConstants.backendURL;

  constructor(
    private modalService: NgbModal,
    public afAuth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
    public translate: TranslateService,
    private administrationPanelService: AdministrationPanelService,
    private countryService: CountryService,
    private notificationService: NotificationService
  ) {}

  ngOnDestroy(): void {}

  async ngOnInit(): Promise<void> {
    //this.checkLogin();

    // Register translation languages
    this.translate.addLangs(['EU', 'ES', 'BG', 'FR', 'GR', 'IT', 'NL', 'SI']);

    this.selectedLanguage = this.translate.getLangs();

    console.log(this.translate.getLangs())

    // Set default language
    this.translate.use('EU');

    await this.getCountries();

    this.login();

  }

  async getCountries(){
    await this.countryService.getCountries().toPromise().then((dataLanguage: NodeResponse) => {
      console.log(dataLanguage)

      this.arrayCountry = dataLanguage.data;
      this.administrationPanelService.setArrayCountry(this.arrayCountry);
    });
  }

  openModal(content, modalCountry) {
    if(this.modalCountry == null) this.modalCountry = modalCountry;

    this.modalReference = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    this.modalReference.result.then(
      save => {

      },
      cancel => {
        this.bodyLogin = {email: '', password: ''};
        this.messageErrorLogin = '';
      }
    )
  }

  openModalRegister(content) {

    this.modalReference = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    this.modalReference.result.then(
      save => {

      },
      cancel => {
        this.bodyRegister = {email: '', password: '', nombre: '', password2: '', country: 0};
        this.messageErrorRegister = '';
      }
    )
  }

  openModalCountry(){

    this.valueCountryUserRegister = 1;

    this.modalReference = this.modalService.open(this.modalCountry, {keyboard: false, backdrop: 'static', ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    this.modalReference.result.then(
      save => {

        let body = {
          country_id: this.valueCountryUserRegister
        }

        this.userService.update(this.userLogged.uid, body).toPromise().then((data: NodeResponse) => {
          console.log('data DB update country -> ', data);
        })

      },
      cancel => {

      }
    )
  }

  openModalForgotPassword(content){

    this.modalReference = this.modalService.open(content, {keyboard: false, backdrop: 'static', ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    this.modalReference.result.then(
      save => {

      },
      cancel => {

      }
    )
  }

  /*login(){

    if((this.bodyLogin.password == null || this.bodyLogin.password == '') || (this.bodyLogin.email == null || this.bodyLogin.email == '')){
      this.messageErrorLogin = 'You must to type a email and a password';
    }else{
      this.afAuth.signInWithEmailAndPassword(this.bodyLogin.email, this.bodyLogin.password).then((resGoogle: any) => {
        console.log(resGoogle)

        if(resGoogle.user != null){

          this.userService.login(resGoogle.user.uid).toPromise().then((data: NodeResponse) => {
            console.log(data)

            if(data.status == true){

              this.messageErrorLogin = '';
              this.isUserLogged = true;

              sessionStorage.clear();
              sessionStorage.setItem('auth-token', data.token);

              this.userLogged = data.data;
              this.role = data.data.user_roles[0].role_id;
              this.administrationPanelService.setRoleCurrentUser(this.role);
              this.administrationPanelService.setGuid(this.userLogged.uid);

              this.userService.updateLastLogin(this.userLogged.uid).toPromise().then((dataUpdate: any) => {
                console.log('update del last login -> ', dataUpdate)
              })

              this.notificationService.getNotificationByUid(this.userLogged.uid).toPromise().then((dataNotification: NodeResponse) => {
                console.log('notification DB -> ', dataNotification);
                if(dataNotification.status == true){
                  this.arrayNotification = dataNotification.data;
                  this.notificationCounter = dataNotification.data.length;
                  this.administrationPanelService.setArrayNotification(this.arrayNotification);
                }
              });

              if(this.modalReference != null) this.modalReference.close();

            }else{
              this.messageErrorLogin = data.message;
            }
          })

        }else{
          this.messageErrorLogin = resGoogle.message;
        }
      }).catch((reason) => {
        console.log('razon de google -> ', reason);
        this.messageErrorLogin = reason.message;
      })
    }
  }*/

  //Login method with email verification
  /*login() {
  if ((this.bodyLogin.password == null || this.bodyLogin.password == '') || (this.bodyLogin.email == null || this.bodyLogin.email == '')) {
    this.messageErrorLogin = 'You must type an email and a password';
  } else {
    this.afAuth.signInWithEmailAndPassword(this.bodyLogin.email, this.bodyLogin.password)
      .then((resGoogle: any) => {
        console.log(resGoogle);

        if (resGoogle.user != null) {
          // Verificar si el correo electrónico está verificado antes de permitir el acceso
          if (resGoogle.user.emailVerified) {
            this.userService.login(resGoogle.user.uid)
              .toPromise()
              .then((data: NodeResponse) => {
                console.log(data);

                if (data.status == true) {
                  this.messageErrorLogin = '';
                  this.isUserLogged = true;

                  sessionStorage.clear();
                  sessionStorage.setItem('auth-token', data.token);

                  this.userLogged = data.data;
                  this.role = data.data.user_roles[0].role_id;
                  this.administrationPanelService.setRoleCurrentUser(this.role);
                  this.administrationPanelService.setGuid(this.userLogged.uid);

                  this.userService.updateLastLogin(this.userLogged.uid)
                    .toPromise()
                    .then((dataUpdate: any) => {
                      console.log('update del last login -> ', dataUpdate);
                    });

                  this.notificationService.getNotificationByUid(this.userLogged.uid)
                    .toPromise()
                    .then((dataNotification: NodeResponse) => {
                      console.log('notification DB -> ', dataNotification);
                      if (dataNotification.status == true) {
                        this.arrayNotification = dataNotification.data;
                        this.notificationCounter = dataNotification.data.length;
                        this.administrationPanelService.setArrayNotification(this.arrayNotification);
                      }
                    });

                  if (this.modalReference != null) this.modalReference.close();
                } else {
                  this.messageErrorLogin = data.message;
                }
              })
              .catch((error) => {
                console.error('Error during login:', error);
              });
          } else {
            // El correo no está verificado, notificar al usuario
            window.alert('Please verify your email before logging in.');
          }
        } else {
          this.messageErrorLogin = resGoogle.message;
        }
      })
      .catch((reason) => {
        console.log('reason of login -> ', reason);
        this.messageErrorLogin = reason.message;
      });
  }
}*/

//Login method with verification and if the user doesn't verify the email yet, we will send an email
login() {
  if ((this.bodyLogin.password == null || this.bodyLogin.password == '') || (this.bodyLogin.email == null || this.bodyLogin.email == '')) {
    this.messageErrorLogin = 'You must type an email and a password';
  } else {
    this.afAuth.signInWithEmailAndPassword(this.bodyLogin.email, this.bodyLogin.password)
      .then((resGoogle: any) => {
        console.log(resGoogle);

        if (resGoogle.user != null) {
          // Verificar si el correo electrónico está verificado
          if (resGoogle.user.emailVerified) {
            this.userService.login(resGoogle.user.uid)
              .toPromise()
              .then((data: NodeResponse) => {
                console.log(data);

                if (data.status == true) {
                  this.messageErrorLogin = '';
                  this.isUserLogged = true;

                  sessionStorage.clear();
                  sessionStorage.setItem('auth-token', data.token);

                  this.userLogged = data.data;
                  this.role = data.data.user_roles[0].role_id;
                  this.administrationPanelService.setRoleCurrentUser(this.role);
                  this.administrationPanelService.setGuid(this.userLogged.uid);

                  if(this.userLogged.country_id == 26){
                    this.translate.use('ES')
                    this.selectedLanguage2 = 'ES';
                  }

                  if(this.userLogged.country_id == 3){
                    this.translate.use('BG')
                    this.selectedLanguage2 = 'BG';
                  }

                  if(this.userLogged.country_id == 10){
                    this.translate.use('FR')
                    this.selectedLanguage2 = 'FR';
                  }

                  if(this.userLogged.country_id == 12){
                    this.translate.use('GR')
                    this.selectedLanguage2 = 'GR';
                  }

                  if(this.userLogged.country_id == 15){
                    this.translate.use('IT')
                    this.selectedLanguage2 = 'IT';
                  }

                  if(this.userLogged.country_id == 20){
                    this.translate.use('NL')
                    this.selectedLanguage2 = 'NL';
                  }

                  if(this.userLogged.country_id == 25){
                    this.translate.use('SI')
                    this.selectedLanguage2 = 'SI';
                  }

                  this.userService.updateLastLogin(this.userLogged.uid)
                    .toPromise()
                    .then((dataUpdate: any) => {
                      console.log('update del last login -> ', dataUpdate);
                    });

                  this.notificationService.getNotificationByUid(this.userLogged.uid)
                    .toPromise()
                    .then((dataNotification: NodeResponse) => {
                      console.log('notification DB -> ', dataNotification);
                      if (dataNotification.status == true) {
                        this.arrayNotification = dataNotification.data;
                        this.notificationCounter = dataNotification.data.length;
                        this.administrationPanelService.setArrayNotification(this.arrayNotification);
                      }
                    });

                  if (this.modalReference != null) this.modalReference.close();
                } else {
                  this.messageErrorLogin = data.message;
                }
              })
              .catch((error) => {
                console.error('Error during login:', error);
              });
          } else {
            // El correo no está verificado, enviar el correo de verificación
            this.sendVerificationEmail();
            window.alert('A verification email has been sent. Please verify your email before logging in.');
          }
        } else {
          this.messageErrorLogin = resGoogle.message;
        }
      })
      .catch((reason) => {
        console.log('reason of login -> ', reason);
        this.messageErrorLogin = reason.message;
      });
  }
}



  //keyboard: false, backdrop: 'static',

  loginWithGoogle(){
    const provider = new firebase.auth.GoogleAuthProvider();
    let resGoogle = null;

    console.log(this.modalCountry)

    this.afAuth.signInWithPopup(provider).then(result => {
      console.log(result)
      resGoogle = result;

      let bodyuser = {
        uid: result.user.uid,
        email:  result.user.email,
        name: result.user.displayName
      }

      if(result.additionalUserInfo.isNewUser == true){
        this.userService.register(result.user.uid, result.user.email, result.user.displayName, 0).toPromise().then((resUser: NodeResponse) => {
          console.log("res del registro en BD -> ", resUser)
          if(resUser.status == true){

            if(resUser.status = true){
              this.messageErrorLogin = null;
              this.isUserLogged = true;

              sessionStorage.clear();
              sessionStorage.setItem('auth-token', resUser.data.token);

              this.userLogged = resUser.data;
              this.role = resUser.data.user_roles[0].role_id;
              this.administrationPanelService.setRoleCurrentUser(this.role);
              this.administrationPanelService.setGuid(this.userLogged.uid);

              this.modalReference.close();
              this.openModalCountry();

            }else{
              this.messageErrorLogin = resUser.message;
            }

          }
        })
      }else{
        console.log('estaba ya en firebase');
        this.userService.login(result.user.uid).toPromise().then((resUser: NodeResponse) => {
          console.log('res User de node -> ', resUser)
          if(resUser.status == true){

            this.profileImage = resUser.data.user_extension.profileImage;

            this.messageErrorLogin = null;
            this.isUserLogged = true;

            sessionStorage.clear();
            sessionStorage.setItem('auth-token', resUser.token);

            this.userLogged = resUser.data;
            this.role = resUser.data.user_roles[0].role_id;

            this.administrationPanelService.setRoleCurrentUser(this.role);
            this.administrationPanelService.setGuid(this.userLogged.uid);

            this.userService.updateLastLogin(bodyuser.uid).toPromise().then((dataUpdate: any) => {
              console.log('update del last login -> ', dataUpdate)
            })

            this.modalReference.close();

            if(resUser.data.country_id == 0){
              this.openModalCountry();
            }

            if(resUser.data.country_id == 26){
              this.translate.use('ES')
              this.selectedLanguage2 = 'ES';
            }

            if(resUser.data.country_id == 3){
              this.translate.use('BG')
              this.selectedLanguage2 = 'BG';
            }

            if(resUser.data.country_id == 10){
              this.translate.use('FR')
              this.selectedLanguage2 = 'FR';
            }

            if(resUser.data.country_id == 12){
              this.translate.use('GR')
              this.selectedLanguage2 = 'GR';
            }

            if(resUser.data.country_id == 15){
              this.translate.use('IT')
              this.selectedLanguage2 = 'IT';
            }

            if(resUser.data.country_id == 20){
              this.translate.use('NL')
              this.selectedLanguage2 = 'NL';
            }

            if(resUser.data.country_id == 25){
              this.translate.use('SI')
              this.selectedLanguage2 = 'SI';
            }

          }else{
            this.modalReference.close();
            alert(resUser.message)
          }
        })
      }
    })
  }

  sendVerificationEmail() {
    return this.afAuth.currentUser.then((user) => {
      if (user) {
        window.alert('We send you an email in order to verify your email.')
        return user.sendEmailVerification();
      }
    });
  }

  registerUser(){
    if(this.isValidDataRegister()){
      this.afAuth.createUserWithEmailAndPassword(this.bodyRegister.email, this.bodyRegister.password).then((result: any) => {
        console.log(result);

        this.userService.register(result.user.uid, result.user.email, this.bodyRegister.name, this.bodyRegister.country).toPromise().then((data: NodeResponse) => {

          console.log("res del registro en BD -> ", data)

          if(data.status == true){

            this.messageErrorLogin = '';
            this.isUserLogged = true;

            sessionStorage.clear();
            sessionStorage.setItem('auth-token', data.data.token);

            this.userLogged = data.data;
            this.administrationPanelService.setRoleCurrentUser(data.data.user_roles[0].role_id);
            this.role = data.data.user_roles[0].role_id;
            this.administrationPanelService.setGuid(data.data.uid);
            this.administrationPanelService.setIsLanguage(true);

            this.bodyRegister = {email: '', password: '', nombre: '', password2: '', country: 0};
            this.messageErrorRegister = '';

            this.sendVerificationEmail();

            this.modalReference.close();

          }else{
            this.messageErrorLogin = data.message;
          }

        })

      }).catch((reason) => {
        console.log(reason)
        alert(reason)
      });
    }
  }

  logOut(){
    this.isUserLogged = false;
    this.userLogged = null;
    this.bodyLogin = {email: null, password: null};

    this.afAuth.signOut();

    this.administrationPanelService.setGuid(null);
    this.administrationPanelService.setIsLanguage(false);
    this.administrationPanelService.setInitRoleCurrentUser();
    this.administrationPanelService.setRole([]);
    this.administrationPanelService.setUser([]);

    sessionStorage.clear();
    sessionStorage.clear();

    this.translate.use('EU')
    this.selectedLanguage2 = 'EU';

    this.router.navigate(['/', 'home'])
    this.administrationPanelService.setInitRoleCurrentUser();

  }

  isValidEmail(email: string): boolean {
    let mailValido = false;
      'use strict';

      let EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (email.match(EMAIL_REGEX)){
        mailValido = true;
      }
    return mailValido;
  }

  isValidDataRegister(): boolean{

    if(this.bodyRegister.name == null || this.bodyRegister.name == undefined || this.bodyRegister.name == '' || this.bodyRegister.name.trim() == ""){
      this.messageErrorRegister = "The name is empty";
      return false;
    }

    if(!this.isValidEmail(this.bodyRegister.email)){
      this.messageErrorRegister = "The email is invalid";
      return false;
    }

    if(this.bodyRegister.password == null || this.bodyRegister.password == undefined || this.bodyRegister.password == '' || this.bodyRegister.password.trim() == ""){
      this.messageErrorRegister = "The password is empty";
      return false;
    }else if(this.bodyRegister.password != this.bodyRegister.password2){
      this.messageErrorRegister = "Both passwords are differents";
      return false;
    }

    if(this.bodyRegister.country == 0){
      this.messageErrorRegister = "You need to select a language";
      return false;
    }

    this.messageErrorRegister = '';

    return true;
  }

  goToAccount(){
    this.router.navigate(['/', 'accountuser'])
  }

  goProposePanel(){
    this.router.navigate(['/', 'propose-panel'])
  }

  gotToAdministrationPanel(){
    this.router.navigate(['/', 'administrationPanel']);
  }

  //Switch language
  translateLanguageTo(lang: string) {
    this.translate.use(lang);
    this.selectedLanguage2 = lang
  }

  consoleJorge(){
    console.log(this.bodyRegister.country)
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      console.log('Archivo seleccionado:', file);
    }
  }

  onImageSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedImage = file;
      console.log('Imagen seleccionada:', file);
    }
  }

  async passwordReset() {
    try {
      const email = this.bodyForgotPassword.email;
      await this.afAuth.sendPasswordResetEmail(email);
      window.alert('Email sent, check your inbox!');
      this.modalReference.close();
    } catch (error) {
      console.log(error);
    }
  }

}

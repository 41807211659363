<div>
  <!--<button (click)="consoleJorge()">Consolame</button>-->
  <h2 class="title-cee-form">
    New Story <img src="../../../assets/img/icons/location_icon.svg">
  </h2>

  <br>

  <!--<h2>id -> {{idCeeBuilding}}</h2>-->
    <form>
        <!--<div class="form-group">
          <label for="ceeid">ID</label>
          <input type="number" class="form-control" id="ceeid" name="ceeid" aria-describedby="emailHelp" disabled="true" ngModel>
        </div>-->

        <div [ngSwitch]="switchFormCount">

          <!--CEE BUILDING-->
          <div *ngSwitchCase="1" id="form1">

            <div class="form-group">
              <label for="ceeaddress" class="label-form">Address <span class="obligatory">*</span></label>
              <input type="text" class="form-control" id="ceeaddress" name="ceeaddress" aria-describedby="emailHelp" [(ngModel)]="dataAddressMap" placeholder="Dwelling Address">
            </div>

            <div class="form-group">
              <label for="country">Country <span class="obligatory">*</span></label>
              <input type="text" class="form-control" id="country" name="country" aria-describedby="emailHelp" [(ngModel)]="valueCountry" placeholder="Country" readonly>
            </div>

            <div class="form-group">
              <label for="ceecr">Cadastral reference</label>
              <input type="text" class="form-control" id="ceecr" name="ceecr" aria-describedby="emailHelp" [(ngModel)]="dataCadastreReference" placeholder="Catastral reference" readonly>
            </div>

            <!--<label>Case</label>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioCase" id="flexRadioCase1" [value]="1" [(ngModel)]="valueFlexRadioCase" (change)="eventChangeRadioCase($event)">
              <label class="form-check-label" for="flexRadioCase1">
                Complete Building
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioCase" id="flexRadioCase2" [value]="2" [(ngModel)]="valueFlexRadioCase" (change)="eventChangeRadioCase($event)">
              <label class="form-check-label" for="flexRadioCase2">
                Single Home
              </label>
            </div>
            <br>-->

            <div class="form-group">
              <label for="tipology">Typology <span class="obligatory">*</span></label>
              <!--<input type="text" class="form-control " id="tipology" name="tipology" aria-describedby="emailHelp" [(ngModel)]="valueTipology.name" readonly>-->
              <ng-select name="tipology" id="tipology" placeholder="Select tipology" [(ngModel)]="valueTipology.code">
                <ng-option [value]="'AB'">Apartment Block</ng-option>
                <ng-option [value]="'MFH'">Multi Family Home</ng-option>
                <ng-option [value]="'SFH'">Single Family Home</ng-option>
                <ng-option [value]="'TH'">Terraced House</ng-option>
              </ng-select>
            </div>

            <div *ngIf="valueTipology.code == 'AB' || valueTipology.code == 'MFH'" class="form-group">
              <label for="selectCase">Success Case <span class="obligatory">*</span></label>
              <ng-select id="selectCase" name="selectCase" placeholder="Select success case" [(ngModel)]="valueFlexRadioCase">
                <ng-option [value]="1">Complete Building</ng-option>
                <ng-option [value]="2">Single Home</ng-option>
              </ng-select>
            </div>

            <div *ngIf="valueTipology.code == 'SFH' || valueTipology.code == 'TH'" class="form-group">
              <label for="selectCase">Success Case <span class="obligatory">*</span></label>
              <ng-select placeholder="Select case" id="selectCase" name="selectCase" [(ngModel)]="valueFlexRadioCase">
                <ng-option [value]="2">Single Home</ng-option>
              </ng-select>
            </div>

            <div *ngIf="isCompleteBuilding && valueFlexRadioCase == 2">
              <label for="selectAddress">Select Dwelling</label>
              <ng-select *ngIf="isCompleteBuilding" placeholder="Select dwelling" id="selectAddress" (change)="eventChangeSelectBuilding($event)">
                <ng-option *ngFor="let address of arrayAddressSingleHome; index as i" [value]="i">{{address}}</ng-option>
              </ng-select>
            </div>

            <!--<div *ngIf="valueFlexRadioTipology == 'AB' || valueFlexRadioTipology == 'MFH'">
              <label>Case</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioCase" id="flexRadioCase1" [value]="1" [(ngModel)]="valueFlexRadioCase">
                <label class="form-check-label" for="flexRadioCase1">
                  Complete Building
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioCase" id="flexRadioCase2" [value]="2" [(ngModel)]="valueFlexRadioCase">
                <label class="form-check-label" for="flexRadioCase2">
                  Single Home
                </label>
              </div>
            <br>
            </div>-->

            <div *ngIf="!(isCompleteBuilding == true && valueFlexRadioCase == 1)">
              <div class="form-group" >
                <label for="ceeyearconstruction">Identification of Dwelling</label>
                <input type="text" class="form-control " id="identificationDwelling" name="identificationDwelling" [(ngModel)]="valueIdentificacionDwelling" placeholder="Identificate your dwelling">
              </div>
            </div>

            <div class="form-group">
              <label for="ceeyearconstruction">Year of Construction <span class="obligatory">*</span></label>
              <input type="number" class="form-control " id="ceeyearconstructionDiego" name="ceeyearconstructionJorge" [(ngModel)]="dataBuildingYear" placeholder="Year construction">
            </div>

            <div class="form-group" *ngIf="isSpainCountry == false">
              <label for="facadePicture">Facade Picture</label>
              <!--<p *ngIf="renovationPicturesName != ''">You have got already some renovation images: {{renovationPicturesName}}</p>-->
              <input class="form-control" type="file" id="facadePicture" name="facadePicture" (change)="facadePictureChangeEvent($event)">
            </div>

            <div class="form-group">
              <label for="ceeyearcertificate">First Certificacion Year</label>
              <input type="text" class="form-control " id="ceeyearcertificate" name="ceeyearcertificate" [(ngModel)]="dataNumberCertificate" placeholder="Year certificate 1">
            </div>

            <div class="form-group">
              <label for="ceeco21">CO<sub>2</sub> Qualification Letter</label>
              <!--<input type="text" class="form-control " id="ceeco21" name="ceeco21" [(ngModel)]="dataCalificationCEE" placeholder="Letter CO2" >-->
              <ng-select name="ceeco21" id="ceeco21" placeholder="Select CO2 qualification CO2" [(ngModel)]="dataCalificationCEE">
                <ng-option [value]="''"></ng-option>
                <ng-option [value]="'A'">A</ng-option>
                <ng-option [value]="'B'">B</ng-option>
                <ng-option [value]="'C'">C</ng-option>
                <ng-option [value]="'D'">D</ng-option>
                <ng-option [value]="'E'">E</ng-option>
                <ng-option [value]="'F'">F</ng-option>
                <ng-option [value]="'G'">G</ng-option>
              </ng-select>
            </div>

            <div class="form-group">
              <label for="ceevalueemissions1">CO<sub>2</sub> Qualification Value</label>
              <input type="number" class="form-control " id="ceevalueemissions1" name="ceevalueemissions1" [(ngModel)]="dataCo2Emissions" (change)="calculateDataEventChange()" placeholder="Emissions CO2 1">
            </div>

            <div class="form-group">
              <label for="ceeepnr1">Non-renewable Energy Letter</label>
              <!--<input type="text" class="form-control " id="ceeepnr1" name="ceeepnr1" [(ngModel)]="dataCalificationEnergyPrimary" placeholder="Letter Energy primary 1">-->
              <ng-select name="ceeepnr1" id="ceeepnr1" placeholder="Select energy letter" [(ngModel)]="dataCalificationEnergyPrimary">
                <ng-option [value]="''"></ng-option>
                <ng-option [value]="'A'">A</ng-option>
                <ng-option [value]="'B'">B</ng-option>
                <ng-option [value]="'C'">C</ng-option>
                <ng-option [value]="'D'">D</ng-option>
                <ng-option [value]="'E'">E</ng-option>
                <ng-option [value]="'F'">F</ng-option>
                <ng-option [value]="'G'">G</ng-option>
              </ng-select>
            </div>

            <div class="form-group">
              <label for="ceevalueepnr1">Non-renewable Energy Value</label>
              <input type="number" class="form-control " id="ceevalueepnr1" name="ceevalueepnr1" [(ngModel)]="dataEnergyConsumed" (change)="calculateDataEventChange()" placeholder="Value Energy primary 1">
            </div>

            <div class="form-group">
              <label for="ceeyearcertificate2">Second Certificacion Year</label>
              <input type="number" class="form-control" id="ceeyearcertificate2" name="ceeyearcertificate2" [(ngModel)]="dataNumberCertificate2" placeholder="Year certificate 2">
            </div>

            <div class="form-group">
              <label for="ceeco22">CO<sub>2</sub> Qualification Letter Number 2</label>
              <!--<input #jorgeInput type="text" class="form-control " id="ceeco22" name="ceeco22" [(ngModel)]="dataCalificationCO2EmissionsCertificate2" placeholder="Letter CO2 2">-->
              <ng-select name="ceeco22" id="ceeco22" placeholder="Select CO2 qualification letter 2" [(ngModel)]="dataCalificationCO2EmissionsCertificate2">
                <ng-option [value]="''"></ng-option>
                <ng-option [value]="'A'">A</ng-option>
                <ng-option [value]="'B'">B</ng-option>
                <ng-option [value]="'C'">C</ng-option>
                <ng-option [value]="'D'">D</ng-option>
                <ng-option [value]="'E'">E</ng-option>
                <ng-option [value]="'F'">F</ng-option>
                <ng-option [value]="'G'">G</ng-option>
              </ng-select>
            </div>

            <div class="form-group">
              <label for="ceevalueco22">CO<sub>2</sub> Qualification Value Number 2</label>
              <input type="number" class="form-control " id="ceevalueco22" name="ceevalueco22" [(ngModel)]="dataCo2EmissionsCertificate2" placeholder="Value CO2 2" (change)="calculateDataEventChange()">
            </div>

            <div class="form-group">
              <label for="ceeepnr2">Non-renewable Energy Letter Number 2</label>
              <!--<input type="text" class="form-control " id="ceeepnr2" name="ceeepnr2" [(ngModel)]="dataCalificationEnergyPrimaryCertificate2" placeholder="Letter Energy primary 2">-->
              <ng-select name="ceeepnr2" id="ceeepnr2" placeholder="Select energy letter 2" [(ngModel)]="dataCalificationEnergyPrimaryCertificate2">
                <ng-option [value]="''"></ng-option>
                <ng-option [value]="'A'">A</ng-option>
                <ng-option [value]="'B'">B</ng-option>
                <ng-option [value]="'C'">C</ng-option>
                <ng-option [value]="'D'">D</ng-option>
                <ng-option [value]="'E'">E</ng-option>
                <ng-option [value]="'F'">F</ng-option>
                <ng-option [value]="'G'">G</ng-option>
              </ng-select>
            </div>

            <div class="form-group">
              <label for="ceevalueepnr2">Non-renewable Energy Value Number 2</label>
              <input type="number" class="form-control " id="ceevalueepnr2" name="ceevalueepnr2" [(ngModel)]="dataEnergyConsumedCertificate2" (change)="calculateDataEventChange()" placeholder="Value Energy primary 2">
            </div>

            <div *ngIf="isNanOrNull(dataCO2Absolute)">
              <div class="form-group">
                <label for="ceeco2saving">Absolute Saving CO<sub>2</sub></label>
                <input type="number" class="form-control inputReadonly" id="ceeco2saving" name="ceeco2saving" [(ngModel)]="dataCO2Absolute" placeholder="Saving CO2 absolute" readonly>
              </div>
            </div>

            <div *ngIf="isNanOrNull(valueSavingCo2)">
              <div class="form-group">
                <label for="ceeco2saving%">Percent CO<sub>2</sub> Saving (%)</label>
                <div class="currency-wrap">
                  <span class="currency-code">%</span>
                  <input type="number" class="form-control inputReadonly text-currency" id="ceeco2savingperc" name="ceeco2savingperc" [(ngModel)]="valueSavingCo2" placeholder="Percent CO2 saving (%)" readonly>
                </div>
              </div>
            </div>

            <div *ngIf="isNanOrNull(dataEnergyAbsolute)">
              <div class="form-group">
                <label for="ceeepnrsaving">Absolute non-renewable primary (NRPE) energy</label>
                <input type="number" class="form-control inputReadonly" id="ceeepnrsaving" name="ceeepnrsaving" [(ngModel)]="dataEnergyAbsolute" placeholder="Saving Energy primary absolute" readonly>
              </div>
            </div>

            <div *ngIf="isNanOrNull(valueSavingEnergyPrimaryPercent)">
              <div class="form-group">
                <label for="ceeepnrsaving%">Percent non-renewable primary (NRPE) Saving (%)</label>
                <div class="currency-wrap">
                  <span class="currency-code">%</span>
                  <input type="number" class="form-control inputReadonly text-currency" id="ceeepnrsavingperc" name="ceeepnrsavingperc" [(ngModel)]="valueSavingEnergyPrimaryPercent" placeholder="Percent Epnr Saving (%)" readonly>
                </div>
              </div>
            </div>

            <div class="form-group" [hidden]="true">
              <label for="ceeepnrsaving%">Lat</label>
              <input type="text" class="form-control" id="ceecoordinatelong" name="ceecoordinatelong" [(ngModel)]="coordinateLong" placeholder="Saving Energy primary %">
            </div>

            <div class="form-group" [hidden]="true">
              <label for="ceeepnrsaving%">Long</label>
              <input type="text" class="form-control" id="ceecoordinatelat" name="ceecoordinatelat" [(ngModel)]="coordinateLat" placeholder="Saving Energy primary %">
            </div>

            <div class="form-group" [hidden]="true">
              <label for="ceeepnrsaving%">X</label>
              <input type="text" class="form-control" id="ceecoordinatex" name="ceecoordinatex" [(ngModel)]="coordinateX" placeholder="Saving Energy primary %">
            </div>

            <div class="form-group" [hidden]="true">
              <label for="ceeepnrsaving%">Y</label>
              <input type="text" class="form-control" id="ceecoordinatey" name="ceecoordinatey" [(ngModel)]="coordinateY" placeholder="Saving Energy primary %">
            </div>

          </div>

          <!--More data cee building-->
          <div *ngSwitchCase="2" id="form2">
<!--
            <div class="form-group">
              <label for="map">Current Regulations</label>
              <input type="text" class="form-control" id="activeNormative" name="activeNormative" aria-describedby="emailHelp" [(ngModel)]="valueActiveNormative">
            </div>
            <div>
              <label>Type reform</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioTypeReform" id="flexRadioTypeReform1" value="0.25" [(ngModel)]="valueTypeReform">
                <label class="form-check-label" for="flexRadioTypeReform1">
                  Low
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioTypeReform" id="flexRadioTypeReform2" value="0.5" [(ngModel)]="valueFlexRadioProtected">
                <label class="form-check-label" for="flexRadioTypeReform2">
                  Medium
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioTypeReform" id="flexRadioTypeReform3" value="0.75" [(ngModel)]="valueFlexRadioProtected">
                <label class="form-check-label" for="flexRadioTypeReform3">
                  Total
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioTypeReform" id="flexRadioTypeReform4" value="1" [(ngModel)]="valueFlexRadioProtected">
                <label class="form-check-label" for="flexRadioTypeReform4">
                  Integral
                </label>
              </div>
            </div>-->

            <div class="form-group">
              <label for="map">Number of floors <span class="obligatory">*</span></label>
              <input type="number" class="form-control" id="numberFloors" name="numberFloors" aria-describedby="emailHelp" [(ngModel)]="valueNumberFloors" placeholder="Number of Floors">
            </div>

            <div class="form-group">
              <label for="map">Number of dwellings <span class="obligatory">*</span></label>
              <input type="number" class="form-control" id="numberDwellings" name="numberDwellings" aria-describedby="emailHelp" [(ngModel)]="valueNumberDwellings" placeholder="Number of Dwelling">
            </div>

            <!--<div class="form-group">
              <label for="map">Number of Locales</label>
              <input type="text" class="form-control" id="numberLocales" name="numberLocales" aria-describedby="emailHelp" [(ngModel)]="valueNumberLocales">
            </div>-->

            <div class="form-group">
              <label for="map">Land surface</label>
              <input type="number" class="form-control" id="areaFloor" name="areaFloor" aria-describedby="emailHelp" [(ngModel)]="valueAreaFloor" placeholder="Land Surface">
            </div>

            <div class="form-group">
              <label for="map">Building surface</label>
              <input type="number" class="form-control" id="buildArea" name="buildArea" aria-describedby="emailHelp" [(ngModel)]="valueBuildArea" placeholder="Building Surface">
            </div>

            <div class="radio-group">
              <label>Heritage building</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioProtected" id="flexRadioProtected1" [value]="true" [(ngModel)]="valueFlexRadioProtected">
                <label class="form-check-label" for="flexRadioProtected1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioProtected" id="flexRadioProtected2" [value]="false" [(ngModel)]="valueFlexRadioProtected">
                <label class="form-check-label" for="flexRadioProtected2">
                  No
                </label>
              </div>
            </div>

            <br>

            <!--<div class="form-group">
              <label for="map">Construction Quality</label>
              <input type="number" class="form-control" id="constructionQuality" name="constructionQuality" aria-describedby="emailHelp" [(ngModel)]="valueConstructionQuality">
            </div>-->

            <div class="form-group">
              <label for="buildingDescription">Builiding description</label>
              <textarea name="buildingDescription" id="buildingDescription" class="form-control z-depth-1" maxlength="500" placeholder="Building Description" cols="113" rows="5" [(ngModel)]="valueBuildingDescription" (ngModelChange)="buildingDescriptionChangeEvent()"></textarea>
              <div>{{lengthBuildingDescription}}/500</div>
            </div>

          </div>

          <div *ngSwitchCase="3" id="form3">

            <div class="form-group">
              <label for="map">Renovation year</label>
              <input type="number" class="form-control" id="yearReform" name="yearReform" aria-describedby="emailHelp" [(ngModel)]="valueYearReform" placeholder="Reformation Year">
            </div>

            <div>
              <label>Renovation measures</label>
            </div>

            <div class="radio-group">
              <label><img src="../../../assets/img/icons/window_icon.svg"> Windows</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioWindows" id="flexRadioWindows1" [value]="true" [(ngModel)]="valueFlexRadioWindows">
                <!--<label class="form-check-label" for="flexRadioWindows1">
                  Yes
                </label>-->
              </div>
              <label><img src="../../../assets/img/icons/wall_icon.svg"> Insulation</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioSurround" id="flexRadioSurround1" [value]="true" [(ngModel)]="valueSurround">
                <!--<label class="form-check-label" for="flexRadioSurround1">
                  Yes
                </label>-->
              </div>
              <label><img src="../../../assets/img/icons/solar-panel-icon.png"> Renewables</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioPhotovoltaic" id="flexRadioPhotovoltaic1" [value]="true" [(ngModel)]="valuePhotovoltaic">
                <!--<label class="form-check-label" for="flexRadioPhotovoltaic1">
                  Yes
                </label>-->
              </div>
              <label><img src="../../../assets/img/icons/water_icon.svg"> Domestic hot water</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioACS" id="flexRadioACS1" [value]="true" [(ngModel)]="valueAcs">
                <!--<label class="form-check-label" for="flexRadioACS1">
                  Yes
                </label>-->
              </div>
              <label><img src="../../../assets/img/icons/heating_icon.svg"> Heating/ cooling/ ventilation</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioAerothermal" id="flexRadioACS1" [value]="true" [(ngModel)]="valueAerothermal">
                <!--<label class="form-check-label" for="flexRadioAerothermal1">
                  Yes
                </label>-->
              </div>
              <!--<div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioWindows" id="flexRadioWindows2" [value]="false" [(ngModel)]="valueFlexRadioWindows">
                <label class="form-check-label" for="flexRadioWindows2">
                  No
                </label>
              </div>-->
            </div>

            <br>

            <!--<div class="radio-group">
              <label><img src="../../../assets/img/icons/wall_icon.svg"> Insulation</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioSurround" id="flexRadioSurround1" [value]="true" [(ngModel)]="valueSurround">
                <label class="form-check-label" for="flexRadioSurround1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioSurround" id="flexRadioSurround2" [value]="false" [(ngModel)]="valueSurround">
                <label class="form-check-label" for="flexRadioSurround2">
                  No
                </label>
              </div>
            </div>

            <br>

            <div class="radio-group">
              <label><img src="../../../assets/img/icons/solar-panel-icon.png"> Renovables</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioPhotovoltaic" id="flexRadioPhotovoltaic1" [value]="true" [(ngModel)]="valuePhotovoltaic">
                <label class="form-check-label" for="flexRadioPhotovoltaic1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioPhotovoltaic" id="flexRadioPhotovoltaic2" [value]="false" [(ngModel)]="valuePhotovoltaic">
                <label class="form-check-label" for="flexRadioProtected2">
                  No
                </label>
              </div>
            </div>

            <br>-->

            <!--<div class="radio-group">
              <label><img src="../../../assets/img/icons/water_icon.svg"> Sanitary Hot Water</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioACS" id="flexRadioACS1" [value]="true" [(ngModel)]="valueAcs">
                <label class="form-check-label" for="flexRadioACS1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioACS" id="flexRadioACS2" [value]="false" [(ngModel)]="valueAcs">
                <label class="form-check-label" for="flexRadioACS2">
                  No
                </label>
              </div>
            </div>

            <br>-->

            <!--<div class="radio-group">
              <label><img src="../../../assets/img/icons/heating_icon.svg"> Heating</label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="flexRadioAerothermal" id="flexRadioACS1" [value]="true" [(ngModel)]="valueAerothermal">
                <label class="form-check-label" for="flexRadioAerothermal1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioAerothermal" id="flexRadioACS2" [value]="false" [(ngModel)]="valueAerothermal">
                <label class="form-check-label" for="flexRadioAerothermal2">
                  No
                </label>
              </div>
            </div>-->

            <!--<br>-->

            <div class="form-group">
              <label for="interventionDescription">Renovation description</label>
              <textarea name="interventionDescription" id="interventionDescription" class="form-control z-depth-1" maxlength="500" placeholder="Intervention Description" cols="113" rows="5" [(ngModel)]="valueInterventionDescription" (ngModelChange)="interventionDescriptionChangeEvent()"></textarea>
              <div>{{lengthInterventionDescription}}/500</div>
            </div>

            <div class="form-group">
              <label for="renovationPictures">Pictures from renovation process (Max.5)</label>
              <p *ngIf="renovationPicturesName != ''">You have got already some renovation images: {{renovationPicturesName}}</p>
              <input class="form-control" type="file" id="images" name="images" (change)="renovationPicturesChangeEvent($event)" multiple>
            </div>

            <div class="form-group">
              <label for="investment">Renovation investment</label>
              <div class="currency-wrap">
                <span class="currency-code">€</span>
                <input type="text" class="form-control text-currency" id="investment" name="investment" aria-describedby="emailHelp" [(ngModel)]="valueInvestment" (ngModelChange)="valueInvestmentEventChange()" separator>
              </div>
            </div>

            <div class="form-group">
              <label for="subsidies">Renovation subsidies</label>
              <div class="currency-wrap">
                <span class="currency-code">€</span>
                <input type="text" class="form-control text-currency" id="subsidies" name="subsidies" aria-describedby="emailHelp" [(ngModel)]="valueSubsidies" (ngModelChange)="valueSubsidiesEventChange()" separator>
              </div>
            </div>

            <div class="form-group">
              <label for="percentPotentialSubsidies">Percent potential subsidies</label>
              <div class="currency-wrap">
                <span class="currency-code">%</span>
                <input type="number" class="form-control text-currency" id="percentPotentialSubsidies" name="percentPotentialSubsidies" aria-describedby="emailHelp" [(ngModel)]="valuePotSubsidiesPercent" (ngModelChange)="valuePotSubsidiesPercentEventChange()">
              </div>
            </div>

              <label for="potentialSubsidies">Potential subsidies</label>
              <div class="currency-wrap">
                <span class="currency-code">€</span>
                <input type="number" class="form-control text-currency" id="potentialSubsidies" name="potentialSubsidies" aria-describedby="emailHelp" [(ngModel)]="valuePotSubsidies" readonly>
              </div>

          </div>

          <!--Media form-->
          <div *ngSwitchCase="4" id="form4">

            <div>
              <label>Would you like to share us your housing's renovation process?</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioTestimony" id="flexRadioTestimony1" [value]="true" [(ngModel)]="valueFlexRadioTestimony">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioTestimony" id="flexRadioTestimony2" [value]="false" [(ngModel)]="valueFlexRadioTestimony">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </div>

            <br>

            <div *ngIf="valueFlexRadioTestimony == true">

              <div class="mb-3">
                <label for="images" class="form-label">Testimony Pictures</label>
                <p *ngIf="imagesTestimonyName != ''">You have got already some PDF FILES: {{imagesTestimonyName}}</p>
                <input class="form-control" type="file" id="images" name="images" (change)="fileImageChangeEvent($event)" multiple>
              </div>

              <div class="form-group">
                <label for="name">Video</label>
                <input type="text" class="form-control" id="video" name="video" placeholder="Video URL" [(ngModel)]="valueVideoURL">
              </div>

              <div *ngFor="let current of arrayTestimony; index as i">
                <h3>Testimony form {{i + 1}}</h3>
                <div class="col-md-5">
                  <button *ngIf="i > 0" class="form-control btn btn-warning" id="deductForm{{current.id}}" name="deductForm" (click)="deductForm(4, i)">Deduct testimony</button>
                </div>

                <div class="form-group">
                  <label for="testimonyName{{current.id}}">Name <span class="obligatory">*</span></label>
                  <input type="text" class="form-control" id="testimonyName{{current.id}}" name="testimonyName{{current.id}}" placeholder="Name" [(ngModel)]="arrayTestimony[i].name">
                </div>

                <div class="form-group">
                  <label for="testimonyRole{{current.id}}">Role <span class="obligatory">*</span></label>
                  <input type="text" class="form-control" id="testimonyRole{{current.id}}" name="testimonyRole{{current.id}}" placeholder="Role name" [(ngModel)]="arrayTestimony[i].role">
                </div>

                <div class="form-group">
                  <label for="testimonyQuote{{current.id}}">Quote <span class="obligatory">*</span></label>
                  <input type="text" class="form-control" id="testimonyQuote{{current.id}}" name="testimonyQuote{{current.id}}" placeholder="Quote" [(ngModel)]="arrayTestimony[i].quote">
                </div>

                <div class="col-md-5">
                  <button class="form-control btn btn-primary" id="addForm{{current.id}}" name="addForm" (click)="addForm(4)">Add new testimony</button>
                </div>

                <br>
              </div>

            </div>

          </div>

          <!--File form-->
          <div *ngSwitchCase="5" id="form5">

            <div>
              <label>Do you have monitoring data?</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioMonitoring">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioMonitoring">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </div>

            <br>

            <!--<div *ngIf="valueFlexRadioMonitoring == true">
              <div class="form-group">
                <label for="inputNumber">How many monitoring files do you have?</label>
                <input type="number" min="1" max="6" class="form-control" id="inputNumber" name="inputNumber" aria-describedby="emailHelp" [(ngModel)]="valueInputNumber" placeholder="Type a number">
              </div>
            </div>-->

          </div>

          <!--Loop files forms-->

          <div *ngSwitchCase="6" id="form6">
            <div *ngFor="let fileForm of formFilesArray; index as i">

              <h3>File Form {{i + 1}}</h3>
              <div class="col-md-5">
                <button *ngIf="i > 0" class="form-control btn btn-warning" id="deductForm{{fileForm.idg}}" name="deductForm" (click)="deductForm(6, i)">Deduct</button>
              </div>

              <div class="form-group">
                <label for="cadastralreferenceunit">Housing unit cadastral reference</label>
                <input type="text" class="form-control inputReadonly" id="cadastralreferenceunit" name="cadastralreferenceunit" placeholder="Cadastral reference" [(ngModel)]="valueIdentificacionDwelling" readonly>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <label>Season <span class="obligatory">*</span></label>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioSeason{{fileForm.idg}}" id="flexRadioSeason{{fileForm.idg}}_1" [value]="1" [(ngModel)]="fileForm.season_id">
                    <label class="form-check-label" for="flexRadioSeason{{fileForm.idg}}_1">
                      Winter
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="flexRadioSeason{{fileForm.idg}}" id="flexRadioSeason{{fileForm.idg}}_2" [value]="2" [(ngModel)]="fileForm.season_id">
                    <label class="form-check-label" for="flexRadioSeason{{fileForm.idg}}_2">
                      Summer
                    </label>
                  </div>
                </div>

                <div class="col-md-4">
                  <label>Phase <span class="obligatory">*</span></label>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioPhase{{fileForm.idg}}" id="flexRadioPhase{{fileForm.idg}}_1" [value]="1" [(ngModel)]="fileForm.phase_id">
                    <label class="form-check-label" for="flexRadioPhase{{fileForm.idg}}_1">
                      Pre
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="flexRadioPhase{{fileForm.idg}}" id="flexRadioPhase{{fileForm.idg}}_2" [value]="2" [(ngModel)]="fileForm.phase_id">
                    <label class="form-check-label" for="flexRadioPhase{{fileForm.idg}}_2">
                      Post
                    </label>
                  </div>
                </div>

                <div class="col-md-4">
                  <label>Family <span class="obligatory">*</span></label>
                  <div class="form-check" *ngFor="let family of arrayFamily">
                    <input class="form-check-input" type="radio" name="flexRadioFamily{{fileForm.idg}}" id="flexRadioFamily{{fileForm.idg}}" [value]="family.id" [(ngModel)]="fileForm.family_id" (change)="familyInputChangeEvent(i)">
                    <label class="form-check-label" for="flexRadioFamily{{fileForm.idg}}">{{family.name}}</label>
                  </div>

                </div>
              </div>

              <br>

              <div *ngIf="fileForm.family_id == 1 || fileForm.family_id == 2">

                <div class="form-select">
                  <label for="selectVariables{{fileForm.idg}}">Variables <span class="obligatory">*</span></label>
                  <ng-select name="selectVariables{{fileForm.idg}}" id="selectVariables{{fileForm.idg}}" [(ngModel)]="fileForm.variables_id">
                    <ng-option *ngFor="let variable of inputArrayVariable(fileForm.family_id)" [value]="variable.id" selected>{{variable.name}}</ng-option>
                  </ng-select>
                </div>

                <br>

              </div>
              <div *ngIf="fileForm.family_id == 3">
                <div *ngFor="let currentHealth of fileForm.monitoringHealth; index as j">
                  <button *ngIf="fileForm.monitoringHealth.length > 1" class="form-control btn btn-warning" id="deductForm{{currentHealth.idg}}" name="deductForm{{currentHealth.idg}}" (click)="deductFormHealth(i, j)">Deduct health form</button>

                  <div class="form-select">
                    <label for="years{{currentHealth.idg}}">How old are you? <span class="obligatory">*</span></label>
                    <input type="number" class="form-control" id="years{{currentHealth.idg}}" name="years{{currentHealth.idg}}" [(ngModel)]="currentHealth.age" placeholder="(Empty at the moment)">
                  </div>

                  <div class="form-select">
                    <label for="sex{{currentHealth.idg}}">What is your sex? <span class="obligatory">*</span></label>
                    <ng-select name="sex{{currentHealth.idg}}" id="sex{{currentHealth.idg}}" [(ngModel)]="currentHealth.sex">
                      <ng-option [value]="false">Male</ng-option>
                      <ng-option [value]="true">Female</ng-option>
                    </ng-select>
                  </div>

                  <div class="form-select">
                    <label for="selectStressors{{currentHealth.idg}}">During the monitoring, have you been bothered by…? <span class="obligatory">*</span></label>
                    <ng-select name="selectStressors{{currentHealth.idg}}" id="selectStressors{{currentHealth.idg}}" [(ngModel)]="currentHealth.arrayStressorId" multiple="true" [closeOnSelect]="false">
                      <ng-option *ngFor="let e of arrayStressor" [value]="e.id" selected>{{e.name}}</ng-option>
                    </ng-select>
                  </div>

                  <div class="form-select">
                    <label for="selectSymptoms{{currentHealth.idg}}">During the monitoring, have you suffered…? <span class="obligatory">*</span></label>
                    <ng-select name="selectSymptoms{{currentHealth.idg}}" id="selectSymptoms{{currentHealth.idg}}" [(ngModel)]="currentHealth.arraySymptomId" multiple="true" [closeOnSelect]="false">
                      <ng-option *ngFor="let e of arraySymptoms" [value]="e.id" selected>{{e.name}}</ng-option>
                    </ng-select>
                  </div>

                  <div class="form-select">
                    <label for="selectComfort{{currentHealth.idg}}">During the monitoring, have you feel…? <span class="obligatory">*</span></label>
                    <ng-select name="selectComfort{{currentHealth.idg}}" id="selectComfort{{currentHealth.idg}}" [(ngModel)]="currentHealth.arrayThermalComfortId" multiple="true" [closeOnSelect]="false">
                      <ng-option *ngFor="let e of arrayThermalComfort" [value]="e.id" selected>{{e.name}}</ng-option>
                    </ng-select>
                  </div>

                  <div class="form-select">
                    <label for="selectActivity{{currentHealth.idg}}">At home, your activity was mostly… <span class="obligatory">*</span></label>
                    <ng-select name="selectActivity{{currentHealth.idg}}" id="selectActivity{{currentHealth.idg}}" [(ngModel)]="currentHealth.arrayActivityId" multiple="true" [closeOnSelect]="false">
                      <ng-option *ngFor="let e of arrayActivity" [value]="e.id" selected>{{e.name}}</ng-option>
                    </ng-select>
                  </div>

                  <div class="form-select">
                    <label for="selectClothing{{currentHealth.idg}}">At home, your clothes were mostly… <span class="obligatory">*</span></label>
                    <ng-select name="selectClothing{{currentHealth.idg}}" id="selectClothing{{currentHealth.idg}}" [(ngModel)]="currentHealth.arrayClothingId" multiple="true" [closeOnSelect]="false">
                      <ng-option *ngFor="let e of arrayClothing" [value]="e.id" selected>{{e.name}}</ng-option>
                    </ng-select>
                  </div>

                  <div class="form-group">
                    <label for="impresionFile{{currentHealth.idg}}">Other impressions… <span class="obligatory">*</span></label>
                    <textarea name="impresionFile{{currentHealth.idg}}" id="impresionFile{{currentHealth.idg}}" class="form-control z-depth-1" maxlength="5000" placeholder="Comment that you feel" cols="113" rows="5" [(ngModel)]="currentHealth.text"></textarea>
                  </div>

                  <div class="col-md-5">
                    <button class="form-control btn btn-primary" id="addForm{{currentHealth.idg}}" name="addForm{{currentHealth.idg}}" (click)="addFormHealth(i)">Add health form</button>
                  </div>

                </div>

              </div>

              <div *ngIf="fileForm.family_id == 2">
                <label>Location <span class="obligatory">*</span></label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioLocation{{fileForm.idg}}" id="flexRadioLocation{{fileForm.idg}}_1" [value]="true" [(ngModel)]="fileForm.location">
                  <label class="form-check-label" for="flexRadioLocation{{fileForm.idg}}_1">
                    Indoor
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="flexRadioLocation{{fileForm.idg}}" id="flexRadioLocation{{fileForm.idg}}_2" [value]="false" [(ngModel)]="fileForm.location">
                  <label class="form-check-label" for="flexRadioLocation{{fileForm.idg}}_2">
                    Outdoor
                  </label>
                </div>
              </div>

              <br>

              <div *ngIf="fileForm.family_id == 1 || fileForm.family_id == 2" class="mb-3">
                <label for="formFile{{fileForm.idg}}" class="form-label">File input <span class="obligatory">*</span></label>
                <p *ngIf="isUpdateCeeBuilding && isFilesFromDB[i] == true">{{isFileAlready}}</p>
                <input class="form-control" type="file" name="formFile{{fileForm.idg}}" id="formFile{{fileForm.idg}}" (change)="fileChangeEvent($event, i)">
              </div>

              <div class="form-group">
                <label for="commentFile{{fileForm.idg}}">Comments</label>
                <textarea name="commentFile{{fileForm.idg}}" id="commentFile{{fileForm.idg}}" class="form-control z-depth-1" maxlength="5000" placeholder="Comment" cols="113" rows="5" [(ngModel)]="fileForm.comment" (ngModelChange)="commentMonitoringChangeEvent(i)"></textarea>
                <div>{{lengthCommentMonitoring[i]}}/5000</div>
              </div>
              <div class="col-md-5">
                <button class="form-control btn btn-primary" id="addForm{{fileForm.idg}}" name="addForm" (click)="addForm(6)">Add new monitoring data</button>
              </div>
            </div>

          </div>

        </div>

        <div>
          <div class="d-flex justify-content-around typologyInfo">

            <div [ngSwitch]="switchFormCount">

              <div *ngSwitchCase="1">

                <button class="btn calculateTyp buttonLeft" (click)="goBack();">
                  <h4>
                    <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
                  </h4>
                </button>

                <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
                  <h4>
                    <span><i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; Next</span>
                  </h4>
                </button>

              </div>

              <div *ngSwitchCase="2">

                <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
                  <h4>
                    <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
                  </h4>
                </button>

                <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
                  <h4>
                    <span><i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; Next</span>
                  </h4>
                </button>

              </div>

              <div *ngSwitchCase="3">

                <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
                  <h4>
                    <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
                  </h4>
                </button>

                <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
                  <h4>
                    <span><i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; Next</span>
                  </h4>
                </button>

              </div>

              <div *ngSwitchCase="4">

                <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
                  <h4>
                    <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
                  </h4>
                </button>

                <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
                  <h4>
                    <span><i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; Next</span>
                  </h4>
                </button>

              </div>

              <div *ngSwitchCase="5">

                <div *ngIf="valueFlexRadioMonitoring == false">
                  <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
                    <h4>
                      <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
                    </h4>
                  </button>

                  <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
                    <h4>
                      <span> Save &nbsp; <i class="fa fa-bookmark" aria-hidden="true"></i></span>
                    </h4>
                  </button>
                </div>

                <div *ngIf="valueFlexRadioMonitoring == true">

                  <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
                    <h4>
                      <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
                    </h4>
                  </button>

                  <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
                    <h4>
                      <span><i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; Next</span>
                    </h4>
                  </button>
                </div>

              </div>

              <div *ngSwitchCase="6">

                <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
                  <h4>
                    <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
                  </h4>
                </button>

                <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
                  <h4>
                    <span> Save &nbsp; <i class="fa fa-bookmark" aria-hidden="true"></i></span>
                  </h4>
                </button>

              </div>

            </div>

          </div>
        </div>
      </form>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import {UserService} from '../../core/authentication/user.service';
import { Router } from '@angular/router';
import {Building} from '../../shared/models/building';
import {Envelope} from '../../shared/models/envelope';
import {System} from '../../shared/models/system';
import {SystemType} from '../../shared/models/systemType';
import {Efficiency} from '../../shared/models/eficiency';
import {Typology} from '../../shared/models/typology';
import { Tools } from 'src/app/shared/models/tools';
import { ToolsNew } from 'src/app/shared/models/toolsnew';

import {User} from '../../shared/models/user';
import { ToolsService } from 'src/app/core/tools/tools.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToolsModalComponent } from 'src/app/components/tools-modal/tools-modal.component';
import { BuildingModalComponent } from 'src/app/components/building-modal/building-modal.component';
import { $, element } from 'protractor';
import { BestPracticesService } from '../../core/binding-data/best-practices/best-practices.service';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { CeeService } from 'src/app/core/wms/cee.service';
import { CeeBuilding } from 'src/app/shared/models/ceeBuilding';
import { Options } from '@angular-slider/ngx-slider';
import { UserAllData } from 'src/app/shared/models/userAllData';
import { UserModalComponent } from 'src/app/components/user-modal/user-modal.component';
import { Testimony } from 'src/app/shared/models/testimony';
import { ArticlesService } from 'src/app/core/re-hub/articles.service';
import { Article } from 'src/app/shared/models/article';
import { ArticlesModalComponent } from 'src/app/components/articles-modal/articles-modal.component';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})

export class UserAccountComponent implements OnInit {

  window: number = 1;
  mode: number = 1;
  user: UserAllData = null;

  isUserLogged: boolean;
  tools: Tools[] = [];

  userLogged: any = null;

  bodyLogin: any = {
    email: '',
    password: ''
  }

  selectedImage: string | null = null;

  tmpTools: Tools[] = [];
  tmpToolsFiltered: ToolsNew[] = [];
  filtered: Boolean = false;
  userHistory: Building[];
  userTools: Tools[];
  stopSelectd: string;
  deletedBuilding: boolean;
  deletedTool: boolean;
  modalRef: BsModalRef;
  countryOneClick: string;
  filterCountries: string[];
  filterTypologies: { code: string, name: string}[];
  filters = {countries: [], typologies: [], profiles: [], solutions: [], steps: [], stops: []};
  filterYears: string[];
  tmpUserHistory: Building[];
  tmpUserTools: Tools[];

  countryControl: boolean;
  countrySelected: string;

  imageUrl = GlobalConstants.backendURL;
  profileImage: string;

  yearControl: boolean;
  yearSelectedMin: any;
  yearSelectedMax: any;

  newTools: ToolsNew[] = [];
  tmpNewTools: ToolsNew[] = [];

  userToolsNew: ToolsNew[] = [];
  tmpUserToolsNew: ToolsNew[] = [];

  userCEEHistory: any[] = [];
  tmpUserCEEHistory: CeeBuilding[] = [];

  guid: string = null;

  roleUser: number;

  stopsList: any[] = [];

  value: number = 0;
  options: Options = {
    floor: 0,
    ceil: 10
  };

  usersSaved: any;
  savedUsers: any[];
  originalSavedUsers: any[] = [];

  articlesSaved: any;
  savedArticles: any[];

  countOwning: number;
  countDesign: number;
  countFinancing: number;
  countPublicSector: number;
  countConstructing: number;

  allInformationUsers: any[];

  selectedFilter: string = '';
  foundMatches: boolean = true;

  selectedFilterTools: string = '';
  filteredTools: boolean = false;

  selectedFilterBuildings: string = '';
  filteredBuildings: boolean = false;
  tmpBuildingsFiltered: Building[] = [];

  originalArrayBuildings: Building[] = [];
  originalArrayCeeBuildings: CeeBuilding[] = [];
  testimonies: any[] = [];
  monitoringBuilding: any[] = [];

  countBuildingsOneClick: number;
  countBuildingsCEEOnboarded: number;
  countBuildingsCEEDesigned: number;
  countBuildingsCEEContracted: number;
  countBuildingsCEEValidated: number;

  filteredElementsLength: number = 0;

  phoneNumber: any;
  webpage: any;
  dossier: string;
  userDescription: string;

  countOnboarding: number;
  countEvaluation: number;
  countDesignFormalization: number;
  countRealization: number;
  countValidation: number;

  typologyControl: boolean;
  typologySelected: string;
  searchText: any;
  filterApplied: string[];
  filterApplied2: string[];
  @Input() optionSelected: number;
  @Output() historyEmitter = new EventEmitter<any>();
  @Output() historyEmitter2 = new EventEmitter<any>();
  @Output() buildingSelectedEmitter = new EventEmitter<any>();
  @Output() buildingToBeUpdatedEmitter = new EventEmitter<any>();

  @Input() currentUser: User = new User();
  @Output() logoutEmitter = new EventEmitter<any>();
  constructor
  (
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
    private toolsService: ToolsService,
    private modalService: BsModalService,
    private administrationPanel: AdministrationPanelService,
    private ceeService: CeeService,
    private articleService: ArticlesService
  ) {}

  ngOnInit(): void {
    this.checkLogin();
    this.filterApplied = [];
    this.filterApplied2 = [];
    this.getTools();
    this.buildHistory();
    this.buildHistoryTools();
    console.log("tools ->", this.tmpUserToolsNew);
    this.administrationPanel.getGuid$().subscribe((data: string) => this.guid = data);
    this.getUserCeeBuildings();
    this.getStops();
    this.getUsersSaved();
    this.getArticlesSaved();

    this.administrationPanel.getRoleCurrentUser$().subscribe(data => {
      console.log('rol del usuario logeado -> ', data)
      this.roleUser = data;
    })
  }

  changeWindow(windowNumber: number){
    this.window = windowNumber;
  }

  getNewUser(user: UserAllData){
    //this.variableDeUsuario = user;

    console.log('usuario con nueva peticion de userformpro ->', user);

    let booleanoParaElBoton = true;

    for(let i = 0; i < user.userToProRequests.length; i++){
      let formPro = user.userToProRequests[i];

      if(formPro.userToProRequest_status_id == 1 || formPro.userToProRequest_status_id == 3){
        booleanoParaElBoton = false;
        break;
      }
    }
  }

  getArticlesSaved(){
    this.savedArticles = [];

    this.userService.getSavedArticles(this.guid).subscribe(data => {
      this.articlesSaved = data;
      console.log(this.articlesSaved);

      for(let i = 0; i < this.articlesSaved.length; i++){
        const articleSavedId = this.articlesSaved[i].article_id;

        this.articleService.getArticleById(articleSavedId).subscribe((articleData: any) => {
          this.savedArticles.push(articleData.data);
          console.log(this.savedArticles);
        });
      }
    })
  }

  getUsersSaved(){
    this.savedUsers = [];

    this.userService.getSavedUsers(this.guid).subscribe(data => {
        this.usersSaved = data;
        console.log(this.usersSaved);

        this.countOwning = 0;
        this.countDesign = 0;
        this.countFinancing = 0;
        this.countPublicSector = 0;
        this.countConstructing = 0;
        for (let i = 0; i < this.usersSaved.length; i++) {
          if (this.usersSaved[i].name === 'Owning') {
            this.countOwning++;
          }
          if (this.usersSaved[i].name === 'Design') {
            this.countDesign++;
          }
          if (this.usersSaved[i].name === 'Financing') {
            this.countFinancing++;
          }
          if (this.usersSaved[i].name === 'Public Sector') {
            this.countPublicSector++;
          }
          if (this.usersSaved[i].name === 'Constructing') {
            this.countConstructing++;
          }
        }

        console.log(this.countOwning);
        console.log(this.countDesign);
        console.log(this.countFinancing);
        console.log(this.countPublicSector);
        console.log(this.countConstructing);

        for(let i = 0; i < this.usersSaved.length; i++){
          const userSavedId = this.usersSaved[i].user_saved;

          // Comprueba si el usuario ya existe en savedUsers por su ID
          const existingUser = this.savedUsers.find(user => user.id === userSavedId);

          if (!existingUser) {
            // Si el usuario no existe en savedUsers, obtén sus datos y agrégalo
            this.userService.getByUid(userSavedId).subscribe((userData: any) => {
              this.savedUsers.push(userData.data);
              console.log(this.savedUsers);
            });
          }
        }
      });
  }

  openFileInput() {
    const fileInput = document.getElementById('imageInput');
    if (fileInput) {
      fileInput.click();
    }
  }

  onImageSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      // Realiza cualquier procesamiento necesario con la imagen seleccionada aquí
      // Por ejemplo, puedes mostrar la imagen en la vista previa
      const reader = new FileReader();
      reader.onload = () => {
        this.selectedImage = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  getStops(){
    this.toolsService.getStops().subscribe((data:any) => {
      this.stopsList = data.data;
    })
  }

  showBuildingResults(building: Building): void {
    this.buildingSelectedEmitter.emit(building);
  }

  updateBuilding(building: Building): void {
    this.buildingToBeUpdatedEmitter.emit(building);
  }

  removeBuildingFromUserHistory(building: Building): void {
    this.userService.deletePropertyFromHistory( building.id, sessionStorage.getItem('auth-token')).subscribe( () => {
      this.deletedBuilding = true;
      this.buildHistory();
      setTimeout( () => {
        this.deletedBuilding = false;
      }, 80000 );
    });
  }

  removeToolFromUserHistory(tool: Tools): void {
    this.userService.deletePropertyToolFromHistory( tool.id, sessionStorage.getItem('auth-token')).subscribe( () => {
      this.deletedTool = true;
      this.buildHistoryTools();
      setTimeout( () => {
        this.deletedTool = false;
      }, 80000 );
    });
  }

  async getUserCeeBuildings(){
    await this.ceeService.getGuidUserCeeBuildings(this.guid).toPromise().then( async (data: any[]) => {
      console.log("las idCeeBuildings del user ->", data );

      if(data.length > 0){
        for(let i = 0; i < data.length; i++){
          await this.ceeService.getCeeBuildingId(data[i].cee_building_id).toPromise().then((data: CeeBuilding) => {
            this.tmpUserCEEHistory.push(data);
          })
        }

        for(let i = 0; i < data.length; i++){
          this.ceeService.getTestimonyIdCeeBuilding(data[i].cee_building_id).subscribe((data) => {
            this.testimonies.push(data);
            console.log(this.testimonies);
          })
        }

        for(let i = 0; i < data.length; i++){
          this.ceeService.getMonitoringIdCeeBuilding(data[i].cee_building_id).subscribe((data) => {
            this.monitoringBuilding.push(data);
            console.log(this.monitoringBuilding);
          });
        }
        this.userCEEHistory = this.tmpUserCEEHistory;
        //this.buildFilters();
        this.countBuildingsCEEDesigned = this.tmpUserCEEHistory.filter(building => building.aerothermal == true || building.wall == true || building.windows == true || building.photovoltaic == true || building.shw == true).length;
        this.countBuildingsCEEOnboarded = this.tmpUserCEEHistory.length;
        console.log(this.testimonies);
        console.log('todos los UserCeeBuilding -> ', this.tmpUserCEEHistory);
      }
    });
  }

  buildHistory() {
    this.userService.getUserHistory(sessionStorage.getItem('auth-token')).subscribe( hist => {
      this.userHistory = [];
      for (const histKey in hist) {
        this.parseHistory(hist[histKey]);
      }
      this.tmpUserHistory = this.userHistory;
      this.buildFilters();
      this.historyEmitter.emit(this.userHistory);
      console.log(this.tmpUserHistory);
      this.countBuildingsOneClick = this.tmpUserHistory.length;
    });
  }

  buildHistoryTools() {
    this.userService.getUserTools(sessionStorage.getItem('auth-token')).subscribe(hist => {
      for (const histKey in hist) {
        this.parseToolHistory(hist[histKey]);
      }

      this.tmpUserToolsNew = this.userToolsNew;
      console.log('Aqui las tools: ', this.tmpUserToolsNew)
      this.historyEmitter2.emit(this.userToolsNew);
      console.log(this.tmpUserToolsNew);

      this.countToolsByStop(this.tmpUserToolsNew);
    });
  }

  // Función para contar las paradas
  countToolsByStop(arrayTools: ToolsNew[]) {
    // Reinicia el conteo de herramientas para cada tipo de parada
    this.countOnboarding = 0;
    this.countEvaluation = 0;
    this.countDesignFormalization = 0;
    this.countRealization = 0;
    this.countValidation = 0;

    // Recorre todas las herramientas y cuenta cuántas veces aparece cada tipo de parada
    for (let i = 0; i < arrayTools.length; i++) {
      for (let j = 0; j < arrayTools[i].stops.length; j++) {
        switch (arrayTools[i].stops[j].stop) {
          case 'Onboarding':
            this.countOnboarding++;
            break;
          case 'Evaluation':
            this.countEvaluation++;
            break;
          case 'Design and Formalization':
            this.countDesignFormalization++;
            break;
          case 'Realization':
            this.countRealization++;
            break;
          case 'Validation':
            this.countValidation++;
            break;
          default:
            break;
        }
      }
    }

    console.log(this.countOnboarding)
    console.log(this.countEvaluation)
    console.log(this.countDesignFormalization)
    console.log(this.countRealization)
    console.log(this.countValidation)
  }

  buildFilters() {
    this.filterCountries = [];
    this.filterTypologies = [];
    this.filterYears = [];
    const currentTime = new Date();
    for ( let i = currentTime.getFullYear(); i > 0; i --) {
      this.filterYears.push(i.toString());
    }
    this.tmpUserHistory.forEach( ( hist) => {
      if ( this.filterCountries.length === 0 || !this.filterCountries.includes(hist.country)) {
        this.filterCountries.push(hist.country);
      }
      if (this.filterTypologies.length === 0 || !this.filterTypologies.find(item => item.code === hist.typology.categoryCode)) {
        const typo = { code: hist.typology.categoryCode, name: hist.typology.categoryName};
        this.filterTypologies.push(typo);
      }
      /*if (this.filterYears.length === 0 || !this.filterYears.includes(hist.year)) {
        this.filterYears.push(hist.year);
      }*/
    });
  }

  filter(type: string): void {
    if ( ( type === 'country' && this.countrySelected !== null ) ||
      ( type === 'yearMin' && this.yearSelectedMin !== null ) ||
      ( type === 'yearMax' && this.yearSelectedMax !== null ) ||
      ( type === 'typology' && this.typologySelected !== null )){

      const indexFilterApplied = this.filterApplied.indexOf(type);
      if (indexFilterApplied < 0) {
        this.filterApplied.push(type);
      }
    } else {
      this.cleanFilter(type);
    }
    let arrayFiltered = [];
    this.userHistory.forEach(el => {
      arrayFiltered.push(el);
    });
    this.filterApplied.forEach( filter => {
      if (filter === 'yearMin') {
        const filterByYear  = [];
        this.userHistory.forEach( hist => {
          if ( hist.year >= this.yearSelectedMin) {
            filterByYear.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByYear);
      }
      if (filter === 'yearMax') {
        const filterByYear  = [];
        this.userHistory.forEach( hist => {
          if ( hist.year <= this.yearSelectedMax) {
            filterByYear.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByYear);
      }
      if (filter === 'country') {
        const filterByUse  = [];
        this.userHistory.forEach( hist => {
          if ( hist.country === this.countrySelected) {
            filterByUse.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByUse);
      }

      if (filter === 'typology') {
        const filterByTypology  = [];
        this.userHistory.forEach( hist => {
          if ( hist.typology.categoryCode === this.typologySelected) {
            filterByTypology.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByTypology);
      }
    });
    this.tmpUserHistory = arrayFiltered;
    this.buildFilters();
    this.historyEmitter.emit(this.tmpUserHistory);
  }

  cleanFilter(type: string) {
    const indexFilterApplied = this.filterApplied.indexOf(type);
    if (indexFilterApplied > -1) {
      this.filterApplied.splice(indexFilterApplied, 1);
    }
  }

  removeElementsFromArray(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }

  parseHistory(history: any) {
    const enveloped: Envelope[] = [];
    const systems: System[] = [];
    let systemType: SystemType = null;
    const efficiency: Efficiency[] = [];
    const dataBuilding = history.data_building;
    history.envelope.forEach( env => {
      enveloped.push(new Envelope(env.enveloped.enveloped_code, env.enveloped.type_construction,
        env.enveloped.description, env.u_value, env.enveloped.picture, env.component_type.name, env.enveloped.type_construction_original, env.enveloped.original_description));
    });
    history.systems.forEach( sys => {
      if ( sys.level_improvement === 'Actual conditions') {
        systems.push(new System( 'Heating', sys.heating.system_code, sys.heating.system_description,
          sys.heating.system_description_original, sys.heating.picture, sys.heating.system_name));
        systems.push(new System( 'Water', sys.water.system_code, sys.water.system_description,
          sys.water.system_description_original, sys.water.picture, sys.water.system_name));
        systems.push(new System( 'Ventilation', sys.ventilation.system_code, sys.ventilation.system_description,
          sys.ventilation.system_description_original, sys.ventilation.picture, sys.ventilation.system_name));
        systemType = new SystemType(history.systems[0].code_system_measure, history.systems[0].system_measure.description_actual_conditions,
          history.systems[0].system_measure.original_description_aconditions, systems);
      }
    });
    history.efficiency.forEach( eff => {
      efficiency.push( new Efficiency(eff.level_improvement, eff.energy_demand, eff.recovered_heat_ventilation,
        eff.fossils_fuels, eff.biomass, eff. electricity, eff.district_heating, eff.other, eff.produced_electricity,
        eff.renewable_p_energy, eff.total_p_energy, eff.non_renewable_pe, eff.renewable_pe_demand, eff.CO2_emissions, eff.energy_costs));
    });
    this.userHistory.push(new Building(dataBuilding.country, dataBuilding.climate_zone, dataBuilding.climate_sub_zone, dataBuilding.year,
      dataBuilding.region, '', dataBuilding.address, dataBuilding.altitude,
      { lng: dataBuilding.coordinates.lng, lat: dataBuilding.coordinates.lat },
      { x: dataBuilding.point.x, y: dataBuilding.point.y }, [], dataBuilding.rc, dataBuilding.use, dataBuilding.surface,
      new Typology(dataBuilding.category_code, dataBuilding.category_name, dataBuilding.category_pic_code, '',
        dataBuilding.year_code, dataBuilding.pic_name,
        dataBuilding.building_code, enveloped, systemType, null), true, null, efficiency, dataBuilding.id));
  }

  parseToolHistory(history: any) {
    const dataTool = history.tool;
    const existingToolIndex = this.userToolsNew.findIndex(tool => tool.uid === dataTool.id);
    
    if (existingToolIndex === -1) {
      // La herramienta no existe en la lista, la agregamos
      this.userToolsNew.push(dataTool);
    } else {
      // La herramienta ya existe en la lista, no la agregamos nuevamente
      // Puedes actualizar la información de la herramienta si es necesario
      this.userToolsNew[existingToolIndex] = dataTool;
    }
  }

  getTools() {
    this.administrationPanel.getArrayValidateTool$().subscribe((data: ToolsNew[]) => {

      if(data.length == 0){
        this.toolsService.getValidateTools().toPromise().then((dataDb: NodeResponse) => {

          console.log('data tool DB -> ', dataDb)
          this.newTools = dataDb.data;
          this.tmpNewTools = this.newTools;
          console.log(this.tmpNewTools)
          this.administrationPanel.setArrayValidateTool(dataDb.data);
        })
      }else{
        console.log('data tool service -> ', data);
        this.newTools = data;
        this.tmpNewTools = this.newTools;

      }
    })
  }

  buildToolsInformation(data) {
    data.forEach( tool => {
      const countries = [];
      const profiles = [];
      const solutions = [];
      const typologies = [];
      const steps = [];
      const stops = [];
      tool.tools_typologies.forEach( typology => {
        typologies.push({id: typology.id, typology: typology.typology});
        if (this.filters.typologies.length === 0 || !this.filters.typologies.find(item => item.id === typology.id)){
          this.filters.typologies.push({id: typology.id, typology: typology.typology});
        }
      });
      tool.tools_profiles.forEach( profile => {
        profiles.push({id: profile.id, profile: profile.profile});
        if (this.filters.profiles.length === 0 || !this.filters.profiles.find(item => item.id === profile.id)){
          this.filters.profiles.push({id: profile.id, profile: profile.profile});
        }
      });
      tool.tools_stops.forEach( stop => {
        stops.push({id: stop.id, stop: stop.stop});
        if (this.filters.stops.length === 0 || !this.filters.stops.find(item => item.id === stop.id)){
          this.filters.stops.push({id: stop.id, stop: stop.stop});
        }
      });
      tool.tools_steps.forEach( step => {
        steps.push({id: step.id, step: step.step});
        if (this.filters.steps.length === 0 || !this.filters.steps.find(item => item.id === step.id)){
          this.filters.steps.push({id: step.id, step: step.step});
        }
      });
      tool.tools_solutions.forEach( solution => {
        solutions.push({id: solution.id, solution: solution.solution});
        if (this.filters.solutions.length === 0 || !this.filters.solutions.find(item => item.id === solution.id)){
          this.filters.solutions.push({id: solution.id, solution: solution.solution});
        }
      });
      const info = new Tools(
        tool.id,
        tool.name,
        tool.login_access,
        tool.url,
        tool.short_description,
        tool.long_description,
        tool.image,
        tool.wurl,
        countries,
        typologies,
        profiles,
        solutions,
        steps,
        stops
      );
      this.tools.push(info);
    });
  }

  applyFilter2(): void {
    let toolsTmp = [];
    this.tools.forEach( tool => {
      toolsTmp.push(tool);
    });
    this.filterApplied.forEach( filter => {
      if (filter === 'stops') {
        const filterByStops  = [];
        this.tools.forEach( tool => {
          const hasStop = tool.stops.find( (item, i) => {
            if (item.id === this.stopSelectd){
              return true;
            }
          });
          if( hasStop ) {
            filterByStops.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray2(toolsTmp, filterByStops);
      }
    });
    this.tmpTools = toolsTmp;
  }

  removeElementsFromArray2(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }

  filter2(type: string): void {
    if ( ( type === 'stops' && this.stopSelectd !== null )){
      const indexFilterApplied = this.filterApplied.indexOf(type);
      if (indexFilterApplied < 0) {
        this.filterApplied.push(type);
      }
    } else {
      this.cleanFilter(type);
    }
    this.applyFilter2();
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);

        this.userService.currentUser = this.currentUser;

        console.log(this.currentUser);
        console.log(this.currentUser.role)

        this.userService.getByUid(user.uid).subscribe((userFromDB: NodeResponse) => {
          console.log('data user db -> ', userFromDB);

          this.user = userFromDB.data;

          if (userFromDB.status) {
            this.currentUser.name = userFromDB.data.name;
            this.currentUser.role = userFromDB.data.user_roles[0].role.name;
            this.currentUser.country = userFromDB.data.country.country;
            this.guid = userFromDB.data.uid;
            this.profileImage = userFromDB.data.user_extension.profileImage;
            this.phoneNumber = userFromDB.data.user_extension.phoneNumber;
            this.webpage = userFromDB.data.user_extension.webPage;
            this.dossier = userFromDB.data.user_extension.dossier;
            this.userDescription = userFromDB.data.user_extension.description;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
  }

  logOut(){
    this.isUserLogged = false;
    this.userLogged = null;
    this.bodyLogin = {email: null, password: null};

    this.afAuth.signOut();

    this.administrationPanel.setGuid(null);
    this.administrationPanel.setIsLanguage(false);
    this.administrationPanel.setInitRoleCurrentUser();
    this.administrationPanel.setRole([]);
    this.administrationPanel.setUser([]);

    sessionStorage.clear();
    sessionStorage.clear();

    this.router.navigate(['/', 'home'])
    this.administrationPanel.setInitRoleCurrentUser();
  }

  openModal(tool: ToolsNew) {
    this.modalRef = this.modalService.show(ToolsModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { tool}});
    this.modalRef.content.closeBtnName = 'Close';
  }

  openBuildingModal(building: Building) {
    this.modalRef = this.modalService.show(BuildingModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { building}});
    this.modalRef.content.closeBtnName = 'Close';
  }

  openUserModal(user: UserAllData) {
    this.modalRef = this.modalService.show(UserModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { user}});
    this.modalRef.content.closeBtnName = 'Close';
  }

  openModalArticles(article: Article) {
    //this.incrementVisitCount(article.id);
    this.modalRef = this.modalService.show(ArticlesModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { article}});
    this.modalRef.content.closeBtnName = 'Close';
  }

  goToOneClick(): void {
    this.router.navigate(['/oneclick'], {state: {country: this.countryOneClick}});
  }

  dataValues(){
    if(this.tmpUserTools[0].name === 'DRIVE-0 autoevaluation'){
      let elem = (document.getElementById("data-image"));
      elem.innerHTML += "<img class='logo-tool' src='./assets/img/tools/cropped-circular.png' width='50' height='50' alt='Logo tool'>";
    }else{
      let elem = (document.getElementById("data-image"));
      elem.innerHTML += "<img src='./assets/img/tools/camera-icon.jpeg' height='50' width='150' class='card-img-top'>";
    }
  }

  //FUNCTIONS FILTER MY ACCOUNT

  //Apply filter funcionality

  filterBuildings(workId: number) {
    console.log(workId);
    this.filteredBuildings = true;
    this.tmpBuildingsFiltered = [];

    if (this.originalArrayBuildings.length === 0) {
      this.originalArrayBuildings = this.tmpUserHistory.slice();
    }

    if (this.originalArrayCeeBuildings.length === 0) {
      this.originalArrayCeeBuildings = this.tmpUserCEEHistory.slice();
    }

    // Hacer copias de las matrices originales
    const tmpUserHistoryCopy = this.originalArrayBuildings.slice();
    const tmpUserCEEHistoryCopy = this.originalArrayCeeBuildings.slice();

    if(workId == 1){
      this.selectedFilterBuildings = 'Onboarded'
      this.tmpUserHistory = [];
    }

    if(workId == 2){
      this.selectedFilterBuildings = 'Informed'

      this.tmpUserHistory = tmpUserHistoryCopy;

      this.tmpUserCEEHistory = tmpUserCEEHistoryCopy.filter(tmpHistory => {
        // Verificar si hay al menos un testimonio asociado a este historial
        return this.monitoringBuilding.some(monitoring => monitoring.some(item => item.cee_building_id === tmpHistory.id && item.phase_id == 1));
      });
    }

    if(workId == 3){
      this.selectedFilterBuildings = 'Designed'
      this.tmpUserHistory = [];
      this.tmpUserCEEHistory = tmpUserCEEHistoryCopy.filter(building => building.aerothermal == true || building.wall == true || building.windows == true || building.photovoltaic == true || building.shw == true);
    }

    if(workId == 4){
      this.selectedFilterBuildings = 'Contracted'
      this.tmpUserHistory = [];

      this.tmpUserCEEHistory = tmpUserCEEHistoryCopy.filter(tmpHistory => {
        return this.testimonies.some(testimony => testimony.some(item => {
            if (item.cee_building_id === tmpHistory.id) {
                const building = tmpHistory;
                return building.aerothermal == true || building.wall == true || building.windows == true || building.photovoltaic == true || building.shw == true;
            }
            return false;
        }));
      });

      this.countBuildingsCEEContracted = this.tmpUserCEEHistory.length;
    }

    if(workId == 5){
      this.selectedFilterBuildings = 'Validated'
      this.tmpUserHistory = [];

      const filteredCeeBuildingIds = new Set();

      this.testimonies.forEach(testimony => {
          testimony.forEach(item => {
              filteredCeeBuildingIds.add(item.cee_building_id);
          });
      });

      this.monitoringBuilding.forEach(monitoring => {
          monitoring.forEach(item => {
              if (item.phase_id == 2) {
                  filteredCeeBuildingIds.add(item.cee_building_id);
              }
          });
      });

      this.tmpUserCEEHistory = tmpUserCEEHistoryCopy.filter(tmpHistory => {
          const hasAdditionalCondition = tmpHistory.aerothermal || tmpHistory.wall || tmpHistory.windows || tmpHistory.photovoltaic || tmpHistory.shw;

          return hasAdditionalCondition && filteredCeeBuildingIds.has(tmpHistory.id);
      });

      this.countBuildingsCEEValidated = this.tmpUserCEEHistory.length;
    }
  }

  filterStopTools(stopId: number) {
    console.log(stopId);
    this.filteredTools = true;
    this.tmpToolsFiltered = [];

    this.filteredElementsLength = 0;

    if(stopId == 1){
      this.selectedFilterTools = 'Onboarding'
    }

    if(stopId == 2){
      this.selectedFilterTools = 'Evaluation'
    }

    if(stopId == 3){
      this.selectedFilterTools = 'Design and formalization'
    }

    if(stopId == 4){
      this.selectedFilterTools = 'Realization'
    }

    if(stopId == 5){
      this.selectedFilterTools = 'Validation'
    }

    for (let i = 0; i < this.tmpUserToolsNew.length; i++) {
      const tool = this.tmpUserToolsNew[i];
      const hasStop = tool.stops.some(stop => stop.id === stopId);
      if (hasStop) {
        this.tmpToolsFiltered.push(tool);
        this.filteredElementsLength++;
      }
    }

    console.log(this.tmpToolsFiltered);

    let card = document.getElementById('cardFilterStops' + (stopId - 1));
    this.defaultCssCardFilter();
    card.classList.remove('bg-light');
    card.classList.add('text-white', 'bg-warning');
  }

  clearFilter() {
    this.selectedFilterTools = null;
    this.filteredTools = false;
  }

  clearFilterUsers() {
    this.selectedFilter = null;
    this.savedUsers = this.originalSavedUsers.slice();
  }

  clearFilterBuildings() {
    this.selectedFilterBuildings = null;
    this.tmpUserHistory = this.originalArrayBuildings.slice();
    this.tmpUserCEEHistory = this.originalArrayCeeBuildings.slice();
  }

  applyFilterUsers(filterValue: string){
    console.log('El filtro elegido es: ', filterValue);
    this.selectedFilter = filterValue;
    // Copiar los usuarios guardados originales si aún no se ha hecho
    if (this.originalSavedUsers.length === 0) {
      this.originalSavedUsers = this.savedUsers.slice();
    }

    // Filtrar los usuarios guardados basados en sus características
    const filteredUserSavedIds = this.usersSaved
      .filter(userSaved => userSaved.name.toLowerCase().includes(filterValue.toLowerCase()))
      .map(userSaved => userSaved.user_saved);

    console.log("IDs de usuarios guardados filtrados:", filteredUserSavedIds); // Imprimir los IDs filtrados

    // Verificar si hay usuarios guardados filtrados
    if (filteredUserSavedIds.length > 0) {
      // Filtrar los datos completos de los usuarios guardados basados en los IDs filtrados
      this.savedUsers = this.originalSavedUsers.filter(savedUser => filteredUserSavedIds.includes(savedUser.uid));
    } else {
      // Si no hay usuarios guardados filtrados, ocultar los usuarios
      this.savedUsers = [];
    }

    console.log('Usuarios guardados filtrados:', this.savedUsers);
  }


  //Delete filter

  deleteFilter(){
    this.filtered = false;
    this.defaultCssCardFilter();
  }

  //Change the card design to default

  defaultCssCardFilter(){
    for(let i=0; i<this.filters.stops.length; i++){
      let card = document.getElementById('cardFilter' + i);
      if(card.classList.contains('text-white') && card.classList.contains('bg-warning')){
        card.classList.add('bg-light');
        card.classList.remove('text-white', 'bg-warning');
      }
    }
  }

  goToEditAccount(user: User){
    this.router.navigate(['/', 'edit-account'])
  }

  toUserProfile(){
    this.window = 1;
  }

  toFormProClassic(){
    this.window = 2;
    this.mode = 1;
  }

  toFormProToUpdate(){
    this.window = 2;
    this.mode = 2;
  }

  toMyArticles(){
    this.window = 3;
  }

  toMyTools(){
    this.window = 4;
  }

  toSuggestions(){
    this.window = 5;
  }

  myWorkFilters = [
    { id: 1, name: 'Onboarded' },
    { id: 2, name: 'Informed' },
    { id: 3, name: 'Designed' },
    { id: 4, name: 'Contracted' },
    { id: 5, name: 'Validated' }
  ];

  myNetworkFilters = [
    { id: 1, name: 'Owning' },
    { id: 2, name: 'Design' },
    { id: 3, name: 'Financing' },
    { id: 4, name: 'Public Sector' },
    { id: 5, name: 'Constructing' }
  ]
}

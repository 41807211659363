import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remodulees',
  templateUrl: './remodulees.component.html',
  styleUrls: ['./remodulees.component.scss']
})
export class RemoduleesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

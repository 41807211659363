import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Envelope} from '../../shared/models/envelope';
import {System} from '../../shared/models/system';
import {SystemType} from '../../shared/models/systemType';
import {Efficiency} from '../../shared/models/eficiency';
import {Building} from '../../shared/models/building';
import {Typology} from '../../shared/models/typology';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from '../../core/authentication/user.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-data-map',
  templateUrl: './data-map.component.html',
  styleUrls: ['./data-map.component.scss']
})
export class DataMapComponent implements OnInit  {

  userHistory: Building[];
  typologyChart: typeof Highcharts = Highcharts;
  chartOptionsTypology: Highcharts.Options;

  energyChart: typeof Highcharts = Highcharts;
  chartOptionsEnergy: Highcharts.Options;

  primaryEnergyChart: typeof Highcharts = Highcharts;
  chartOptionsPrimaryEnergy: Highcharts.Options;

  categories = [];
  typologies = [];
  data = [];
  totalsPercent = [];

  deletedBuilding: boolean;
  filterCountries: string[];
  filterTypologies: { code: string, name: string}[];
  filterYears: string[];
  tmpUserHistory: Building[];

  countryControl: boolean;
  countrySelected: string;
  filterApplied: string[];

  yearControl: boolean;
  yearSelectedMin: any;
  yearSelectedMax: any;

  typologyControl: boolean;
  typologySelected: string;

  @Input() optionSelected: number;
  @Output() historyEmitter = new EventEmitter<any>();
  @Input() filters: any[];
  constructor(
    public afAuth: AngularFireAuth,
    private userService: UserService
  ) {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.buildHistory();
      }
    });
  }

  ngOnInit(): void {
  }


  buildHistory() {
    this.userService.getUserHistory(sessionStorage.getItem('auth-token')).subscribe( hist => {
      this.userHistory = [];
      for (const histKey in hist) {
        this.parseHistory(hist[histKey]);
      }
      this.historyEmitter.emit(this.userHistory);
      this.buildCharts();
      this.buildFilters();
    });
  }

  parseHistory(history: any) {
    const enveloped: Envelope[] = [];
    const systems: System[] = [];
    let systemType: SystemType = null;
    const efficiency: Efficiency[] = [];
    const dataBuilding = history.data_building;
    history.envelope.forEach( env => {
      enveloped.push(new Envelope(env.enveloped.enveloped_code, env.enveloped.type_construction,
        env.enveloped.description, env.u_value, env.enveloped.picture, env.component_type.name, env.enveloped.type_construction_original, env.enveloped.original_description));
    });
    history.systems.forEach( sys => {
      if ( sys.level_improvement === 'Actual conditions') {
        systems.push(new System( 'Heating', sys.heating.system_code, sys.heating.system_description,
          sys.heating.system_description_original, sys.heating.picture, sys.heating.system_name));
        systems.push(new System( 'Water', sys.water.system_code, sys.water.system_description,
          sys.water.system_description_original, sys.water.picture, sys.water.system_name));
        systems.push(new System( 'Ventilation', sys.ventilation.system_code, sys.ventilation.system_description,
          sys.ventilation.system_description_original, sys.ventilation.picture, sys.ventilation.system_name));
        systemType = new SystemType(history.systems[0].code_system_measure, history.systems[0].system_measure.description_actual_conditions,
          history.systems[0].system_measure.original_description_aconditions, systems);
      }
    });
    history.efficiency.forEach( eff => {
      efficiency.push( new Efficiency(eff.level_improvement, eff.energy_demand, eff.recovered_heat_ventilation,
        eff.fossils_fuels, eff.biomass, eff. electricity, eff.district_heating, eff.other, eff.produced_electricity,
        eff.renewable_p_energy, eff.total_p_energy, eff.non_renewable_pe, eff.renewable_pe_demand, eff.CO2_emissions, eff.energy_costs));
    });
    this.userHistory.push(new Building(dataBuilding.country, dataBuilding.climate_zone, dataBuilding.climate_sub_zone, dataBuilding.year,
      dataBuilding.region, '', dataBuilding.address, dataBuilding.altitude,
      { lng: dataBuilding.coordinates.lng, lat: dataBuilding.coordinates.lat },
      { x: dataBuilding.point.x, y: dataBuilding.point.y }, [], dataBuilding.rc, dataBuilding.use, dataBuilding.surface,
      new Typology(dataBuilding.category_code, dataBuilding.category_name, dataBuilding.category_pic_code, '',
        dataBuilding.year_code, dataBuilding.pic_name,
        dataBuilding.building_code, enveloped, systemType, null), true, null, efficiency, dataBuilding.id));
  }
  buildCharts() {
    this.buildTypologyChart();
    this.buildEnergyChart();
    this.buildPrimaryEnergyChart();
  }
  buildEnergyChart(): void {
    const refurbish = ['Today', 'Standard', 'Advanced'];
    let data = [];
    let todayCost = 0;
    let standardCost = 0;
    let advancedCost = 0;
    this.userHistory.forEach( ( building: Building) => {
      building.efficiency.forEach( eff => {
        if ( eff.level_improvement === 'Actual conditions' ) {
          todayCost = todayCost + +eff.energy_costs ;
        } else if ( eff.level_improvement === 'Standard') {
          standardCost = standardCost + +eff.energy_costs;
        } else {
          advancedCost = advancedCost + +eff.energy_costs;
        }
      });
    });

    data = [parseFloat((todayCost / this.userHistory.length).toFixed(2)), parseFloat((standardCost / this.userHistory.length).toFixed(2)),
    parseFloat((advancedCost / this.userHistory.length).toFixed(2)) ];
    this.chartOptionsEnergy = this.energyCostChartData(refurbish, data, '', 'Average cost');
  }
  buildPrimaryEnergyChart(): void {
    const refurbish = ['Today', 'Standard', 'Advanced'];
    let data = [];
    let todayCost = 0;
    let standardCost = 0;
    let advancedCost = 0;
    this.userHistory.forEach( ( building: Building) => {
      building.efficiency.forEach( eff => {
        if ( eff.level_improvement === 'Actual conditions' ) {
          todayCost = todayCost + +eff.renewable_p_energy ;
        } else if ( eff.level_improvement === 'Standard') {
          standardCost = standardCost + +eff.renewable_p_energy;
        } else {
          advancedCost = advancedCost + +eff.renewable_p_energy;
        }
      });
    });

    data = [parseFloat((todayCost / this.userHistory.length).toFixed(2)), parseFloat((standardCost / this.userHistory.length).toFixed(2)),
    parseFloat((advancedCost / this.userHistory.length).toFixed(2)) ];
    this.chartOptionsPrimaryEnergy = this.energyRenewableChartData(refurbish, data, '', 'Average renewable energy');
  }

  buildTypologyChart(): void {
    this.typologies = [];
    this.categories = [];
    this.data = [];
    this.totalsPercent = [];
    this.userHistory.forEach( ( hist) => {
      if (this.categories.length === 0 || !this.categories.find(item => item === hist.typology.categoryName)) {
        this.categories.push(hist.typology.categoryName);
        this.typologies[hist.typology.categoryCode] = 1;
      } else if (this.categories.find(item => item === hist.typology.categoryName)) {
        this.typologies[hist.typology.categoryCode] = this.typologies[hist.typology.categoryCode] + 1;
      }
    });
    Object.entries(this.typologies).forEach(([key, value]) => {
      this.data.push(value);
    });

    const total = this.data.map(item => item).reduce((prev, curr) => prev + curr, 0);

    const totalpercentAB = this.data[0] * 100 / total;

    const totalpercentMFH = this.data[1] * 100 / total;

    const totalpercentTH = this.data[2] * 100 / total;

    const totalpercentSFH = this.data[3] * 100 / total;

    this.totalsPercent.push(parseFloat(totalpercentAB.toFixed(2)));
    this.totalsPercent.push(parseFloat(totalpercentMFH.toFixed(2)));
    this.totalsPercent.push(parseFloat(totalpercentTH.toFixed(2)));
    this.totalsPercent.push(parseFloat(totalpercentSFH.toFixed(2)));
    console.log(this.totalsPercent)

    this.chartOptionsTypology = this.typologyChartData(this.categories, this.totalsPercent, 'Typologies', 'Percentage of type buildings saved');
  }

  filter(type: string): void {
    if ( ( type === 'country' && this.countrySelected !== null ) ||
      ( type === 'yearMin' && this.yearSelectedMin !== null ) ||
      ( type === 'yearMax' && this.yearSelectedMax !== null ) ||
      ( type === 'typology' && this.typologySelected !== null )){

      const indexFilterApplied = this.filterApplied.indexOf(type);
      if (indexFilterApplied < 0) {
        this.filterApplied.push(type);
      }
    } else {
      this.cleanFilter(type);
    }
    let arrayFiltered = [];
    this.userHistory.forEach(el => {
      arrayFiltered.push(el);
    });
    this.filterApplied.forEach( filter => {
      if (filter === 'yearMin') {
        const filterByYear  = [];
        this.userHistory.forEach( hist => {
          if ( hist.year >= this.yearSelectedMin) {
            filterByYear.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByYear);
      }
      if (filter === 'yearMax') {
        const filterByYear  = [];
        this.userHistory.forEach( hist => {
          if ( hist.year <= this.yearSelectedMax) {
            filterByYear.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByYear);
      }
      if (filter === 'country') {
        const filterByUse  = [];
        this.userHistory.forEach( hist => {
          if ( hist.country === this.countrySelected) {
            filterByUse.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByUse);
      }

      if (filter === 'typology') {
        const filterByTypology  = [];
        this.userHistory.forEach( hist => {
          if ( hist.typology.categoryCode === this.typologySelected) {
            filterByTypology.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByTypology);
      }
    });
    this.tmpUserHistory = arrayFiltered;
    this.buildFilters();
    this.historyEmitter.emit(this.tmpUserHistory);
  }

  cleanFilter(type: string) {
    const indexFilterApplied = this.filterApplied.indexOf(type);
    if (indexFilterApplied > -1) {
      this.filterApplied.splice(indexFilterApplied, 1);
    }
  }

  buildFilters() {
    this.filterCountries = [];
    this.filterTypologies = [];
    this.filterYears = [];
    const currentTime = new Date();
    for ( let i = currentTime.getFullYear(); i > 0; i --) {
      this.filterYears.push(i.toString());
    }
    this.userHistory.forEach( ( hist) => {
      if ( this.filterCountries.length === 0 || !this.filterCountries.includes(hist.country)) {
        this.filterCountries.push(hist.country);
      }
      if (this.filterTypologies.length === 0 || !this.filterTypologies.find(item => item.code === hist.typology.categoryCode)) {
        const typo = { code: hist.typology.categoryCode, name: hist.typology.categoryName};
        this.filterTypologies.push(typo);
      }
      /*if (this.filterYears.length === 0 || !this.filterYears.includes(hist.year)) {
        this.filterYears.push(hist.year);
      }*/
    });
  }

  removeElementsFromArray(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }

  typologyChartData(categories, data, titleX, titleY): any {
    return {
      chart: {
        type: 'bar',
        height: 250,
        width: 350
      },
      xAxis: {
        title: titleX,
        className: 'icon-type',
        categories
      },
      yAxis: {
        min: 0,
        title: {
          text: titleY
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      title: {
        text: ''
      },
      colors: ['#FD9C6A','#004470','#4AAD47', '#DADEE2'],
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{point.key} </td>' +
          '<td style="padding:0"><b>{point.y}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [
        {
          colorByPoint: true,
          data
        }
      ]
    };
  }

  energyCostChartData(categories, data, titleX, titleY): any {
    return {
      chart: {
        type: 'bar',
        height: 250,
        width: 300
      },
      xAxis: {
        title: titleX,
        categories

      },
      yAxis: {
        min: 0,
        title: {
          text: titleY
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      title: {
        text: ''
      },
      colors: ['#FD9C6A','#004470','#4AAD47'],
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{point.key} </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [
        {
          colorByPoint: true,
          data
        }
      ]
    };
  }

  energyRenewableChartData(categories, data, titleX, titleY): any {
    return {
      chart: {
        type: 'bar',
        height: 250,
        width: 300
      },
      xAxis: {
        title: titleX,
        categories

      },
      yAxis: {
        min: 0,
        title: {
          text: titleY
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      title: {
        text: ''
      },
      colors: ['#FD9C6A','#004470','#4AAD47'],
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{point.key} </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      series: [
        {
          colorByPoint: true,
          data
        }
      ]
    };
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../../authentication/user.service';
import { Step } from 'src/app/shared/models/step';
import { Stop } from 'src/app/shared/models/stop';
import { MenuTool } from 'src/app/shared/models/menuTool';
import { ToolStatus } from 'src/app/shared/models/toolStatus';
import { UserAllData } from 'src/app/shared/models/userAllData';
import { Role } from 'src/app/shared/models/role';
import { Menu } from 'src/app/shared/models/menu';
import { ToolsNew } from 'src/app/shared/models/toolsnew';
import { Language } from 'src/app/shared/models/language';
import { Country } from 'src/app/shared/models/country';
import { Solution } from 'src/app/shared/models/solution';
import { TypologyTool } from 'src/app/shared/models/typologyTool';
import { Profile } from 'src/app/shared/models/profile';
import { ConstraintType } from 'src/app/shared/models/constraintType';
import { ConstraintCategory } from 'src/app/shared/models/constraintCategory';
import { Article } from 'src/app/shared/models/article';
import { CategoryArticle } from 'src/app/shared/models/categoryArticle';
import { ArticleStatus } from 'src/app/shared/models/articleStatus';
import { ActivityFormPro } from 'src/app/shared/models/activityFormPro';
import { Specialization } from 'src/app/shared/models/specialization';

const initVariable = null;
const initBoolean = false;
const initArray = [];

@Injectable({
  providedIn: 'root'
})
export class AdministrationPanelService {

  private guid$ = new BehaviorSubject<string>(initVariable);
  private isLanguage$ = new BehaviorSubject<boolean>(initBoolean);
  private roleCurrentUser$ = new BehaviorSubject<any>(initVariable);
  private arrayLanguage$ = new BehaviorSubject<Language[]>(initArray);
  private arrayCountry$ = new BehaviorSubject<Country[]>(initArray);

  //user table

  private arrayUser$ = new BehaviorSubject<UserAllData[]>(initArray);
  private arrayRole$ = new BehaviorSubject<Role[]>(initArray);

  //tool menu and more
  private arrayTool$ = new BehaviorSubject<any[]>(initArray);
  private arrayMenu$ = new BehaviorSubject<Menu[]>(initArray)
  private arrayStep$ = new BehaviorSubject<Step[]>(initArray);
  private arrayStop$ = new BehaviorSubject<Stop[]>(initArray);

  private arrayValidateTool$ = new BehaviorSubject<ToolsNew[]>(initArray);
  private arrayProposeTool$ = new BehaviorSubject<any[]>(initArray);

  private arrayToolStatus$ = new BehaviorSubject<ToolStatus[]>(initArray);

  private arraySolution$ = new BehaviorSubject<Solution[]>(initArray);
  private arrayTypology$ = new BehaviorSubject<TypologyTool[]>(initArray);
  private arrayProfile$ = new BehaviorSubject<Profile[]>(initArray);
  private arrayConstraintType$ = new BehaviorSubject<ConstraintType[]>(initArray);
  private arrayConstraintCategory$ = new BehaviorSubject<ConstraintCategory[]>(initArray);

  //articles
  private arrayValidateArticle$ = new BehaviorSubject<Article[]>(initArray);
  private arrayProposedArticle$ = new BehaviorSubject<Article[]>(initArray);
  private arrayCategoryArticle$ = new BehaviorSubject<CategoryArticle[]>(initArray);
  private arrayArticleStatus$ = new BehaviorSubject<ArticleStatus[]>(initArray);

  //notification
  private arrayNotification$ = new BehaviorSubject<Notification[]>(initArray);

  //form pro
  private arrayActivityFormPro$ = new BehaviorSubject<ActivityFormPro[]>(initArray);
  private arraySpecialization$ = new BehaviorSubject<Specialization[]>(initArray);

  constructor(private userService: UserService) { }


  getGuid$(): Observable<string>{
    return this.guid$.asObservable();
  }

  setGuid(guid: string){
    this.guid$ = new BehaviorSubject<string>(initVariable);
    this.guid$.next(guid);
  }

  getIsLanguage$(): Observable<boolean>{
    return this.isLanguage$.asObservable();
  }

  setIsLanguage(isLanguage: boolean){
    this.isLanguage$ = new BehaviorSubject<boolean>(initBoolean);
    this.isLanguage$.next(isLanguage);
  }

  getRoleCurrentUser$(): Observable<any>{
    return this.roleCurrentUser$.asObservable();
  }

  setRoleCurrentUser(role: any){
    this.roleCurrentUser$ = new BehaviorSubject<any>(initVariable);
    this.roleCurrentUser$.next(role);
  }

  async setRoleCurrentUserByUid(uid: string): Promise<number>{
    let role = null;

    this.roleCurrentUser$ = new BehaviorSubject<any>(initVariable);
    await this.userService.getByUid(uid).toPromise().then(async (data: any) => {
      console.log('data del user service by uid -> ', data);

      role = data.role_id;
      this.roleCurrentUser$.next(data.role_id);
    });

    return role;
  }

  setInitRoleCurrentUser(){
    this.roleCurrentUser$ = new BehaviorSubject<any>(initVariable);
  }

  getArrayCountry$(){
    return this.arrayCountry$.asObservable();
  }

  setArrayCountry(array: Country[]){
    this.arrayCountry$ = new BehaviorSubject<Country[]>(initArray);
    this.arrayCountry$.next(array);
  }

  getArrayLanguage$(){
    return this.arrayLanguage$.asObservable();
  }

  setArrayLanguage(array: Language[]){
    this.arrayLanguage$ = new BehaviorSubject<Language[]>(initArray);
    this.arrayLanguage$.next(array);
  }

  //User Table

  getUser$(): Observable<UserAllData[]>{
    return this.arrayUser$.asObservable();
  }

  setUser(user: UserAllData[]){
    this.arrayUser$ = new BehaviorSubject<UserAllData[]>(initArray);
    this.arrayUser$.next(user);
  }

  getRole$(): Observable<Role[]>{
    return this.arrayRole$.asObservable();
  }

  setRole(role: Role[]){
    this.arrayRole$ = new BehaviorSubject<Role[]>(initArray);
    this.arrayRole$.next(role);
  }

  //Tool

  getArrayTool$(){
    return this.arrayTool$.asObservable();
  }

  setArrayTool(array: any[]){
    this.arrayTool$ = new BehaviorSubject<any[]>(initArray);
    this.arrayTool$.next(array);
  }

  getArrayValidateTool$(){
    return this.arrayValidateTool$.asObservable();
  }

  setArrayValidateTool(array: ToolsNew[]){
    this.arrayValidateTool$ = new BehaviorSubject<ToolsNew[]>(initArray);
    this.arrayValidateTool$.next(array);
  }

  getArrayProposeTool$(){
    return this.arrayProposeTool$.asObservable();
  }

  setArrayProposeTool(array: any[]){
    this.arrayProposeTool$ = new BehaviorSubject<any[]>(initArray);
    this.arrayProposeTool$.next(array);
  }

  getArrayStep$(){
    return this.arrayStep$.asObservable();
  }

  setArrayStep(array: Step[]){
    this.arrayStep$ = new BehaviorSubject<any[]>(initArray);
    this.arrayStep$.next(array);
  }

  getArrayStop$(){
    return this.arrayStop$.asObservable();
  }

  setArrayStop(array: Stop[]){
    this.arrayStop$ = new BehaviorSubject<any[]>(initArray);
    this.arrayStop$.next(array);
  }

  //Menu

  getArrayMenu$(){
    return this.arrayMenu$.asObservable();
  }

  setArrayMenu(array: Menu[]){
    this.arrayMenu$ = new BehaviorSubject<Menu[]>(initArray);
    this.arrayMenu$.next(array);
  }

  getArrayToolStatus$(){
    return this.arrayToolStatus$.asObservable();
  }

  setArrayToolStatus(array: ToolStatus[]){
    this.arrayToolStatus$ = new BehaviorSubject<any[]>(initArray);
    this.arrayToolStatus$.next(array);
  }

  getArraySolution$(){
    return this.arraySolution$.asObservable();
  }

  setArraySolution(array: Solution[]){
    this.arraySolution$ = new BehaviorSubject<any[]>(initArray);
    this.arraySolution$.next(array);
  }

  getArrayTypology$(){
    return this.arrayTypology$.asObservable();
  }

  setArrayTypology(array: TypologyTool[]){
    this.arrayTypology$ = new BehaviorSubject<any[]>(initArray);
    this.arrayTypology$.next(array);
  }

  getArrayProfile$(){
    return this.arrayProfile$.asObservable();
  }

  setArrayProfile(array: Profile[]){
    this.arrayProfile$ = new BehaviorSubject<any[]>(initArray);
    this.arrayProfile$.next(array);
  }

  getArrayConstraintType$(){
    return this.arrayConstraintType$.asObservable();
  }

  setArrayConstraintType(array: ConstraintType[]){
    this.arrayConstraintType$ = new BehaviorSubject<any[]>(initArray);
    this.arrayConstraintType$.next(array);
  }

  getArrayConstraintCategory$(){
    return this.arrayConstraintCategory$.asObservable();
  }

  setArrayConstraintCategory(array: ConstraintCategory[]){
    this.arrayConstraintCategory$ = new BehaviorSubject<any[]>(initArray);
    this.arrayConstraintCategory$.next(array);
  }

  //Article

  getArrayValidateArticle$(){
    return this.arrayValidateArticle$.asObservable();
  }

  setArrayValidateArticle(array: Article[]){
    this.arrayValidateArticle$ = new BehaviorSubject<Article[]>(initArray);
    this.arrayValidateArticle$.next(array);
  }

  getArrayProposedArticle$(){
    return this.arrayProposedArticle$.asObservable();
  }

  setArrayProposedArticle(array: Article[]){
    this.arrayProposedArticle$ = new BehaviorSubject<Article[]>(initArray);
    this.arrayProposedArticle$.next(array);
  }

  getArrayCategoryArticle$(){
    return this.arrayCategoryArticle$.asObservable();
  }

  setArrayCategoryArticle(array: CategoryArticle[]){
    this.arrayCategoryArticle$ = new BehaviorSubject<CategoryArticle[]>(initArray);
    this.arrayCategoryArticle$.next(array);
  }

  getArrayArticleStatus$(){
    return this.arrayArticleStatus$.asObservable();
  }

  setArrayArticleStatus(array: ArticleStatus[]){
    this.arrayArticleStatus$ = new BehaviorSubject<ArticleStatus[]>(initArray);
    this.arrayArticleStatus$.next(array);
  }

  getArrayNotification$(){
    return this.arrayNotification$.asObservable();
  }

  setArrayNotification(array: Notification[]){
    this.arrayNotification$ = new BehaviorSubject<Notification[]>(initArray);
    this.arrayNotification$.next(array);
  }

  getArrayActivityFormPro$(){
    return this.arrayActivityFormPro$.asObservable();
  }

  setArrayActivityFormPro(array: ActivityFormPro[]){
    this.arrayActivityFormPro$ = new BehaviorSubject<ActivityFormPro[]>(initArray);
    this.arrayActivityFormPro$.next(array);
  }

  getArraySpecialization$(){
    return this.arraySpecialization$.asObservable();
  }

  setArraySpecialization(array: Specialization[]){
    this.arraySpecialization$ = new BehaviorSubject<Specialization[]>(initArray);
    this.arraySpecialization$.next(array);
  }
}

<div *ngIf="window == 1" class="form-group row">
  <table class="table table-hover" id="tableTool" name="tableTool">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Address</th>
        <th scope="col">Year</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let build of arrayBuilding | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
        <td>{{build.address}}</td>
        <td>{{build.year}}</td>
        <td>
          <button class="btn btn-light">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          </button>
          <button class="btn btn-light">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="arrayBuilding.length"></ngb-pagination>
</div>

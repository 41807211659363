import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { isNumber } from 'highcharts';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/authentication/user.service';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { CountryService } from 'src/app/core/country/country.service';
import { FormProService } from 'src/app/core/formPro/form-pro.service';
import { RoleService } from 'src/app/core/role/role.service';
import { ToolsService } from 'src/app/core/tools/tools.service';
import { ActivityFormPro } from 'src/app/shared/models/activityFormPro';
import { CertificationInfo } from 'src/app/shared/models/certificationInfo';
import { Country } from 'src/app/shared/models/country';
import { Experience } from 'src/app/shared/models/experience';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Role } from 'src/app/shared/models/role';
import { Specialization } from 'src/app/shared/models/specialization';
import { ToolsNew } from 'src/app/shared/models/toolsnew';
import { TypologyTool } from 'src/app/shared/models/typologyTool';
import { UserAllData } from 'src/app/shared/models/userAllData';

@Component({
  selector: 'app-user-form-pro',
  templateUrl: './user-form-pro.component.html',
  styleUrls: ['./user-form-pro.component.scss']
})
export class UserFormProComponent implements OnInit, OnDestroy {

  @Input() user: UserAllData = null;
  @Input() mode: number = null;
  @Output() window = new EventEmitter();
  @Output() newUser = new EventEmitter();

  getRoleDB: Subscription = null;
  getActivityFormProDB: Subscription = null;
  getSpecializationDB: Subscription = null;
  getTypologyDB: Subscription = null;
  getToolDB: Subscription = null;
  getArrayCountry: Subscription = null;

  arrayRole: Role[] = [];
  arrayActivityFormPro: ActivityFormPro[] = [];
  arraySpecialization: Specialization[] = [];
  arrayTypology: TypologyTool[] = [];
  arrayTool: ToolsNew[] = [];
  arrayCountry: Country[] = [];

  valueUsername: string = '';
  valueEmail: string = '';
  valueRole: Role = null;
  valueIsUpgadreRole: boolean = false;

  valueName: string = '';
  valueLastName: string = '';
  valueNameProfileImage: string = '';
  valueProfileImage: any = null;
  valuePrefixNumber: string = null;
  valueNumberPhone: string = '';
  valueWebpage: string = '';
  valueIdProfile: number = 0;
  valueIdActivity: number = null;
  valueIdSpecialization: number[] = [];
  valueNameDossier: any = null;
  valueDossier: any = null;
  valueDescription: string = '';
  valueExperience: Experience[] = [];
  valueCertificationInfo: CertificationInfo[] = [];

  idGenerator: number = 0;

  profileImageIsChanged: boolean = false;
  dossierIsChanged: boolean = false;

  certificationToDelete: number[] = [];

  errorMessages: string[] = [];
  isError: boolean = false;

  constructor(
    private administrationPanel: AdministrationPanelService,
    private userService: UserService,
    private roleService: RoleService,
    private formProService: FormProService,
    private toolService: ToolsService,
    private countryService: CountryService
  ) { }

  ngOnInit(): void {

    this.getRole();
    this.getActivityFormPro();
    this.getSpecialization();
    this.getTypology();
    this.getTools();
    this.getCountries();

    console.log('user -> ', this.user);

    this.valueUsername = this.user.name;
    this.valueEmail = this.user.email;
    this.valueRole = this.user.user_roles[0].role;

    this.initToUpdateUser();
  }

  ngOnDestroy(): void {
    if(this.getRoleDB != null) this.getRoleDB.unsubscribe();
    if(this.getActivityFormProDB != null) this.getActivityFormProDB.unsubscribe();
    if(this.getSpecializationDB != null) this.getSpecializationDB.unsubscribe();
    if(this.getTypologyDB != null) this.getTypologyDB.unsubscribe();
    if(this.getToolDB != null) this.getToolDB.unsubscribe();
  }

  getRole(){
    this.getRoleDB = this.administrationPanel.getRole$().subscribe(data => {
      if(data.length == 0){
        this.roleService.getAllRole().toPromise().then((dataRoleDB: NodeResponse) => {
          if(dataRoleDB.status){
            this.arrayRole = dataRoleDB.data;
          }
        })
      }else{
        this.arrayRole = data;
      }
    })
  }

  getActivityFormPro(){
    this.getActivityFormProDB = this.administrationPanel.getArrayActivityFormPro$().subscribe(data => {
      if(data.length == 0){
        this.formProService.getActivityFormPro().toPromise().then((dataDB: NodeResponse) => {
          if(dataDB.status){
            this.arrayActivityFormPro = dataDB.data;
            this.administrationPanel.setArrayActivityFormPro(dataDB.data);
            console.log('activity form pro DB -> ', this.arrayActivityFormPro);
          }
        })
      }else{
        this.arrayActivityFormPro = data;
        console.log('activity form pro -> ', this.arrayActivityFormPro);
      }
    })
  }

  getSpecialization(){
    this.getSpecializationDB = this.administrationPanel.getArraySpecialization$().subscribe(data => {
      if(data.length == 0){
        this.formProService.getSpecialization().toPromise().then((dataDB: NodeResponse) => {
          if(dataDB.status){
            this.arraySpecialization = dataDB.data;
            this.administrationPanel.setArraySpecialization(dataDB.data);
            console.log('specialization DB -> ', this.arraySpecialization);
          }
        })
      }else{
        this.arraySpecialization = data;
        console.log('specialization -> ', this.arraySpecialization);
      }
    })
  }

  filterTools(data: ToolsNew[]): ToolsNew[]{
    return data.filter((tool: ToolsNew) => tool.training_url != null && tool.training_url != '');
  }

  getTools(){
    this.getToolDB = this.administrationPanel.getArrayValidateTool$().subscribe(data => {
      if(data.length == 0){
        this.toolService.getValidateTools().toPromise().then((dataDB: NodeResponse) => {
          if(dataDB.status){
            console.log('tool DB -> ', this.arrayTool);
            this.arrayTool = this.filterTools(dataDB.data);
            this.administrationPanel.setArrayValidateTool(dataDB.data);
            console.log('filter tools -> ', this.arrayTool);
          }
        })
      }else{
        console.log('tool -> ', this.arrayTool);
        this.arrayTool = this.filterTools(data);
        console.log('filter tools -> ', this.arrayTool);
      }
    })
  }

  getTypology(){
    this.getTypologyDB = this.administrationPanel.getArrayTypology$().subscribe(data => {
      if(data.length == 0){

        this.toolService.getTypologies().toPromise().then((dataDB: NodeResponse) => {
          if(dataDB.status){
            this.arrayTypology = dataDB.data;
            this.administrationPanel.setArrayTypology(dataDB.data);
            console.log('typology DB -> ', this.arrayTypology);
          }
        })

      }else{
        this.arrayTypology = data;
        console.log('typology -> ', this.arrayTypology);
      }
    })
  }

  initToUpdateUser(){
    if(this.user.user_roles[0].role_id <= 3){

      this.valueExperience = [];
      this.valueCertificationInfo = [];

      this.valueName = this.user.user_extension.officialName;
      this.valueLastName = this.user.user_extension.lastName;
      if(this.user.user_extension.profileImage != null) this.valueNameProfileImage = this.user.user_extension.profileImage.split('/')[5];
      this.valueNumberPhone = this.user.user_extension.phoneNumber;
      this.valuePrefixNumber = this.user.user_extension.prefix;
      this.valueWebpage = this.user.user_extension.webPage;
      this.valueIdActivity = this.user.user_extension.activityFormPro_id;
      this.user.user_specializations.forEach(e => this.valueIdSpecialization.push(e.specialization_id));
      this.valueNameDossier = this.user.user_extension.dossier;
      this.valueDescription = this.user.user_extension.description;
      this.user.experiences.forEach(e => {
        this.idGenerator++;
        this.valueExperience.push({
          idg: this.idGenerator,
          cadastreReference: e.cadastreReference,
          description: e.description,
          interventionYear: e.interventionYear,
          typology_id: e.typology_id
        })
      })
      this.user.certifications.forEach(e => {
        this.idGenerator++;

        if(e.tool_id == null || e.tool_id == 0){
          this.valueCertificationInfo.push({
            file: e.file.split('/')[5],
            idg: this.idGenerator,
            name: e.name,
            tool_id: 0,
            id: e.id,
            updateWithFile: false
          })
        }else{
          this.valueCertificationInfo.push({
            file: e.file.split('/')[5],
            idg: this.idGenerator,
            name: null,
            tool_id: e.tool_id,
            id: null,
            updateWithFile: false
          })
        }
      })

      if(this.valueExperience.length == 0){
        this.idGenerator++;

        this.valueExperience.push({
          idg: this.idGenerator,
          cadastreReference: '',
          description: '',
          typology_id: null,
          interventionYear: null
        })
      }

      if(this.valueCertificationInfo.length == 0){
        this.idGenerator++;

        this.valueCertificationInfo.push({idg: this.idGenerator, tool_id: null, name: null, file: null, id: null, updateWithFile: false})
      }
    }
  }

  getCountries(){
    this.getArrayCountry = this.administrationPanel.getArrayCountry$().subscribe((data: Country[]) => {

      if(data.length == 0){
        this.countryService.getCountries().toPromise().then((dataDB: NodeResponse) => {
          console.log('countries DB -> ', dataDB);

          if(dataDB.status){
            this.arrayCountry = dataDB.data
            this.administrationPanel.setArrayCountry(this.arrayCountry);
          }
        })
      }else{
        this.arrayCountry = data
      }
    })
  }

  fileProfileImageChangeEvent(event: any){
    let file = event.target.files[0];

    //console.log(event.target.files)

    if(file.type.substring(0,5) == 'image'){
      console.log('profile image -> ', file);
      this.valueProfileImage = file;
      this.profileImageIsChanged = true;
    }else{
      alert('This is not an image');
      this.valueProfileImage = null;
    }

  }

  fileDossierChangeEvent(event: any){
    let file = event.target.files[0];

    if(file.type.substring(5) == 'pdf' || file.type == 'application/pdf'){
      console.log('dossier -> ', file);
      this.valueDossier = file;
      this.dossierIsChanged = true;
    }else{
      alert('This file is not a PDF');
      this.valueDossier = null;
    }
  }

  fileCertificationSupportChangeEvent(event: any, index: number){
    let file = event.target.files[0];

    if(file.type.substring(5) == 'pdf' || file.type == 'application/pdf'){
      console.log('Certification -> ', file);
      this.valueCertificationInfo[index].file = file;
      this.valueCertificationInfo[index].updateWithFile = true;
    }else{
      alert('This file is not a PDF');
      this.valueCertificationInfo[index].file = null;
    }
  }

  isUpdateChangeEvent(){

    console.log(this.valueIsUpgadreRole);

    if(this.user.user_roles[0].role_id == 4){
      if(!this.valueIsUpgadreRole){
        this.valueExperience = [];
        this.valueCertificationInfo = [];

        this.valueName = '';
        this.valueLastName = '';
        this.valueProfileImage = null;
        this.valueNumberPhone = null;
        this.valueWebpage = '';
        this.valueIdProfile = 0;
        this.valueIdActivity = null;
        this.valueIdSpecialization = null;

      }else if(this.valueIsUpgadreRole){
        this.idGenerator++;

        this.valueExperience.push({idg: this.idGenerator, cadastreReference: '', description: '', typology_id: null, interventionYear: null})
        this.valueCertificationInfo.push({idg: this.idGenerator, tool_id: null, name: null, file: null, id: null, updateWithFile: false})
      }
    }
  }

  deductForm(option: number, index: number){
    switch(option){

      case 1:
        this.valueExperience.splice(index, 1);
        break;

      case 2:
        if(this.valueCertificationInfo[index].id != null) this.certificationToDelete.push(this.valueCertificationInfo[index].id);
        this.valueCertificationInfo.splice(index, 1);
        break;
    }
  }

  addForm(option: number, index: number){

    this.idGenerator++;

    switch(option){

      case 1:
        this.valueExperience.push(
          {idg: this.idGenerator, cadastreReference: '', description: '', typology_id: null, interventionYear: null}
        )
        break;

      case 2:
        this.valueCertificationInfo.push({idg: this.idGenerator, tool_id: null, name: '', file: null, id: null, updateWithFile: false})
        break;
    }
  }

  updateToPro(){

    if(this.isReady()){
      this.formProService.updateToPro(
        this.user.uid,
        this.user.user_extension.id,
        this.user.user_roles[0].role_id,
        this.valueName,
        this.valueLastName,
        this.valueNumberPhone,
        this.valueWebpage,
        this.valueIdActivity,
        this.valueProfileImage,
        this.valueDossier,
        this.valueIdSpecialization,
        this.valueExperience,
        this.valuePrefixNumber,
        this.valueDescription
      ).toPromise().then(async (data: NodeResponse) => {
        console.log('response update to pro DB -> ', data);

        if(data.status == true){

          for(let i = 0; i < this.valueCertificationInfo.length; i++){
            await this.formProService.createCertification(this.user.uid, this.valueCertificationInfo[i].tool_id, this.valueCertificationInfo[i].name, this.valueCertificationInfo[i].file).toPromise().then(async (dataCert: NodeResponse) => {
              console.log('response create certification -> ', dataCert);
              if(dataCert.status == false){
                alert(dataCert.message);
                i = this.valueCertificationInfo.length;
              }
            })
            if(i == (this.valueCertificationInfo.length - 1)){
              this.userService.getByUid(this.user.uid).toPromise().then((dataUser: NodeResponse) => {
                if(dataUser.status){
                  alert(data.message);
                  this.newUser.emit(dataUser.data);
                  this.window.emit(1 + '');
                }else{
                  alert('Conection error with the server');
                }
              })
            }
          }



        }else{
          alert(data.message);
        }
      })
    }else{
      alert('You must to complete whole form');
    }


  }

  isReady(): boolean{

    let isReady = true;

    //check strings
    if(this.valueUsername == '' || this.valueEmail == '' || this.valueName == '' || this.valueLastName == ''){
      isReady = false;
      console.log(1)
    }

    if(this.valueProfileImage == null || this.valueIdActivity == null){
      isReady = false;
      console.log(2)
    }

    if(!this.isRightNumberPhone()){
      isReady = false;
    }

    if(this.valueIdSpecialization.length == 0){
      isReady = false;
      console.log(4)
    }

    for(let i = 0; i < this.valueExperience.length; i++){
      if(this.valueExperience[i].cadastreReference == '' || this.valueExperience[i].description == '' || this.valueExperience[i].typology_id == null || !isNumber(this.valueExperience[i].interventionYear)){
        isReady = false;
        console.log(5)
      }
    }

    for(let i = 0; i < this.valueCertificationInfo.length; i++){
      if(this.valueCertificationInfo[i].tool_id == null || this.valueCertificationInfo[i].file == null){
        isReady = false;
        console.log(6)
      }else if(this.valueCertificationInfo[i].tool_id == 0 && this.valueCertificationInfo[i].name == ''){
        isReady = false;
        console.log(7)
      }
    }

    return isReady;
  }

  isRightNumberPhone(): boolean{

    let value = false;

    if(this.valuePrefixNumber == null && this.valueNumberPhone == ''){
      value = true;
    }

    if(this.valueNumberPhone != ''){
      if(this.valueNumberPhone.length == 9 && isNumber(this.valueNumberPhone)){
        if(this.valuePrefixNumber != null){
          value = true;
        }
      }
    }

    return value;
  }

  async updateForm(){
    await this.formProService.updateFormProBasicData(
      this.user.user_extension.id,
      this.valueName,
      this.valueLastName,
      this.valueNumberPhone,
      this.valueWebpage,
      this.valueIdActivity,
      this.valuePrefixNumber,
      this.valueDescription
    ).toPromise().then(async (data: NodeResponse) => {
      console.log('basic update -> ', data);

      if(data.status == false){
        this.errorMessages.push(data.message);
        this.isError = true;
      }
    })
  }

  async updateProfileImage(){
    await this.formProService.updateProfileImage(
      this.valueProfileImage,
      this.user.uid,
      this.user.user_extension.id,
      this.user.user_extension.profileImage
    ).toPromise().then((data: NodeResponse) => {
      console.log('profile image -> ', data);
      if(data.status == false){
        this.errorMessages.push(data.message);
        this.isError = true;
      }
    });
  }

  async updateDossier(){

    await this.formProService.updateDossier(
      this.valueDossier,
      this.user.uid,
      this.user.user_extension.id,
      this.user.user_extension.dossier
    ).toPromise().then((data: NodeResponse) => {
      console.log('update dossier -> ', data);
      if(data.status == false){
        this.errorMessages.push(data.message);
        this.isError = true;
      }
    });

  }

  async updateExperience(){

    await this.formProService.updateExperience(this.valueExperience, this.user.uid).toPromise().then((data: NodeResponse) => {
      console.log('experience -> ', data);
      if(data.status == false){
        this.errorMessages.push(data.message);
        this.isError = true;
      }
    });
  }

  async updateCertification(){

    this.formProService.deleteCertification(this.user.uid).toPromise().then(async (data: NodeResponse) => {
      console.log('certification -> ', data);
      if(data.status == false){
        this.errorMessages.push(data.message);
        this.isError = true;
      }else{
        for(let i = 0; i < this.valueCertificationInfo.length; i++){
          await this.formProService.createCertification(
            this.user.uid,
            this.valueCertificationInfo[i].tool_id,
            this.valueCertificationInfo[i].name,
            this.valueCertificationInfo[i].file
          ).toPromise().then(async (dataCert: NodeResponse) => {
            console.log('response create certification -> ', dataCert);
            if(data.status == false){
              this.errorMessages.push(data.message);
              this.isError = true;
            }

          })
        }
      }
    })
  }

  async deleteById(idCertification: number){
    this.formProService.deleteCertificationById(this.user.uid, idCertification).toPromise().then((data: NodeResponse) => {
      console.log('delete by id -> ', data)
    })
  }

  async updateCertificateWithFile(index: number){

    await this.formProService.updateWithFile(
      this.valueCertificationInfo[index].id,
      this.user.uid,
      this.valueCertificationInfo[index].tool_id,
      this.valueCertificationInfo[index].name,
      this.valueCertificationInfo[index].file,
      this.user.certifications[index].file
    ).toPromise().then(async (dataCert: NodeResponse) => {
      console.log('update with file -> ', dataCert);

      if(dataCert.status == false){
        this.errorMessages.push(dataCert.message);
        this.isError = true;
      }
    })
  }

  async updateCertificateWithoutFile(index: number){

    await this.formProService.updateWithoutFile(
      this.valueCertificationInfo[index].id,
      this.user.uid,
      this.valueCertificationInfo[index].tool_id,
      this.valueCertificationInfo[index].name
    ).toPromise().then(async (dataCert: NodeResponse) => {
      console.log('update without file -> ', dataCert)

      if(dataCert.status == false){
        this.errorMessages.push(dataCert.message);
        this.isError = true;
      }
    })
  }


  async submitForm(){
    if(this.user.user_roles[0].role_id <= 3){

      if(this.isReady){
        await this.updateForm();
        if(this.profileImageIsChanged) await this.updateProfileImage();
        if(this.dossierIsChanged) await this.updateDossier();
        await this.updateExperience();
        //await this.updateCertification();

        for(let i = 0; i < this.certificationToDelete.length; i++){
          await this.deleteById(this.certificationToDelete[i]);
        }

        for(let i = 0; i < this.valueCertificationInfo.length; i++){
          if(this.valueCertificationInfo[i].id != null){
            if(this.valueCertificationInfo[i].updateWithFile){
              this.updateCertificateWithFile(i);
            }else{
              this.updateCertificateWithoutFile(i);
            }
          }else{
            await this.formProService.createCertification(
              this.user.uid, this.valueCertificationInfo[i].tool_id,
              this.valueCertificationInfo[i].name,
              this.valueCertificationInfo[i].file
            ).toPromise().then(async (dataCert: NodeResponse) => {
              console.log('create new certificate -> ', dataCert)

              if(dataCert.status == false){
                this.errorMessages.push(dataCert.message);
                this.isError = true;
              }
            })

          }
        }

        if(this.isError){

          let string = '';

          for(let i = 0; i < this.errorMessages.length; i++){
            string += this.errorMessages[i]; + ' '
          }
          alert(string);
        }else{
          this.userService.getByUid(this.user.uid).toPromise().then((dataUser: NodeResponse) => {
            if(dataUser.status){
              alert('Form updated');
              this.newUser.emit(dataUser.data);
              this.window.emit(1 + '');
            }
          })
        }

      }else{
        alert('You must to complete whole form');
      }

    }else{
      this.updateToPro();
    }
  }

  consoleJorge(){
    console.log('numero -> ', this.valueNumberPhone)
    console.log('prefijo -> ', this.valuePrefixNumber)
  }

}

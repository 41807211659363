import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CeeService } from 'src/app/core/wms/cee.service';
import { NodeResponse } from 'src/app/shared/models/node_response';

@Component({
  selector: 'app-ceebuilding-propose',
  templateUrl: './ceebuilding-propose.component.html',
  styleUrls: ['./ceebuilding-propose.component.scss']
})
export class CeebuildingProposeComponent implements OnInit {

  @Output() historyUsersEmitter = new EventEmitter<any>();
  @Output() buildingSelectedEmitter = new EventEmitter<any>();

  ceeBuilding: any[] = [];
  tmpCeeBuilding: any[] = [];

  searchText: any;

  constructor(private ceeService: CeeService) { }

  ngOnInit(): void {
    this.getCeeBuildings();
  }

  getCeeBuildings(){

    this.ceeBuilding = [];
    this.tmpCeeBuilding = [];

    this.ceeService.getAllCeeBuildingProposed().toPromise().then((data: NodeResponse) => {
      console.log('todos los buildings => ', data)

      if(data.data.length > 0){

        this.ceeBuilding = data.data;
        this.tmpCeeBuilding = this.ceeBuilding;

        //this.buildFilters();
        this.historyUsersEmitter.emit(this.ceeBuilding);
      }
    })
  }

  showBuildingResults(building: any): void {
    this.buildingSelectedEmitter.emit(building);
  }

}

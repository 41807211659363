import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isNumber } from 'highcharts';
import { Subscription } from 'rxjs';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { CountryService } from 'src/app/core/country/country.service';
import { ToolsService } from 'src/app/core/tools/tools.service';
import { ConstraintCategory } from 'src/app/shared/models/constraintCategory';
import { ConstraintType } from 'src/app/shared/models/constraintType';
import { ConstraintsInfo } from 'src/app/shared/models/constraintsInfo';
import { Country } from 'src/app/shared/models/country';
import { Language } from 'src/app/shared/models/language';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Profile } from 'src/app/shared/models/profile';
import { Solution } from 'src/app/shared/models/solution';
import { Step } from 'src/app/shared/models/step';
import { Stop } from 'src/app/shared/models/stop';
import { ToolStatus } from 'src/app/shared/models/toolStatus';
import { Tools } from 'src/app/shared/models/tools';
import { ToolsNew } from 'src/app/shared/models/toolsnew';
import { TypologyTool } from 'src/app/shared/models/typologyTool';
import { UserAllData } from 'src/app/shared/models/userAllData';

@Component({
  selector: 'app-my-tools',
  templateUrl: './my-tools.component.html',
  styleUrls: ['./my-tools.component.scss']
})
export class MyToolsComponent implements OnInit, OnDestroy {

  @Input() user: UserAllData = null;

  window = 1;

  getArrayCountry: Subscription = null;

  arrayCountry: Country[] = [];
  arrayTool: ToolsNew[] = [];
  arrayStep: Step[] = [];
  arrayStop: Stop[] = [];
  arrayLanguage: Language[] = [];

  toolSelected: ToolsNew = null;

  //subscriptions
  getGuid: Subscription = null;
  getArrayTool: Subscription = null;
  getArrayLanguage: Subscription = null;
  getArrayToolPropose: Subscription = null;
  getArrayStep: Subscription = null;
  getArrayStop: Subscription = null;
  getArraySolution: Subscription = null;
  getArrayTypology: Subscription = null;
  getArrayProfile: Subscription = null;
  getArrayConstraintType: Subscription = null;
  getArrayConstraintCategory: Subscription = null;
  getArrayToolsSolution: Subscription = null;

  //pagination
  page: number = 1;
  pageSize: number = 10;

  //pagination form
  switchFormCount: number = 1;
  totalFormCount: number = 8;

  //first page

  valueUid: number = 0;
  valueCountry: number = null;
  valueName: string = '';
  valueNameOriginal: string = '';
  valueIsURL: boolean = false;
  valueURL: string = '';
  valueShortDescription: string = '';
  valueLongDescription: string = '';
  valueShortDescriptionOriginal: string = '';
  valueLongDescriptionOriginal: string = '';
  adminComment: string = '';

   //second page
   toolImage: string = '';
   mainImage: any = null;
   nameOfImages: string = '';
   valueFlexRadioMonitoring: boolean = false;
   additionalImages: any[] = [];
   valueVideoURL: string = '';
   isAllDocuments: boolean;
   toolsDocuments: any[] = [];
   pdfDocumentsString: string = '';

   isAdditionalImagesChange: boolean = false;
   isPdfFilesChange: boolean = false;

   //third page
   valuePrototype: boolean = false;
   valueFlexRadioAccessForm3: boolean = false;
   valueAccess: string = 'free-access';
   valueOpenSource: boolean = false;
   valueFlexRadioRepositoryForm3: boolean = false;
   valuelUrlRepository: string;
   valueFlexRadioAPIForm3: boolean = false;
   valueApiUrl: string;
   valueFlexRadioCountriesForm3: boolean = false;
   valueCountryWork: string[] = [];
   valueExpert: boolean = false;
   valueFlexRadioMoreConstraints: boolean = false;

   //fourth page
   valueConstraintInfo: any[] = [];
   valueConstraintInfoOld: ConstraintsInfo[] = [];

   valueProfiles: number[] = [];
   valueConstraintType: number[] = [];
   valueConstraintCategory: any[] = [];

   arrayProfile: Profile[] = [];
   arrayConstraintType: ConstraintType[] = [];
   arrayConstraintCategory: ConstraintCategory[] = [];
   tmpConstrainsCategoryList: any[] = [];
   isCategoryReadOnly: boolean[] = [];

   //fifth page
   arraySolution: Solution[] = [];
   arrayTypology: TypologyTool[] = [];
   valueSolutions: number[] = [];
   valueTypologies: number[] = [];
   valueStops: number[] = [];
   tmpArrayStep: Step[] = [];
   valueSteps: number[] = [];
   valueFlexRadioTrainingForm6: boolean = false;
   valueTrainingPage: string = '';
   valueFlexRadioMoreLanguagesForm6: boolean = false;
   valueLanguagesAdditional: string[] = [];
  tmpValueLanguagesAdditional: Language[] = [];

   descriptionLanguage: any[] = [];
   valueFlexRadioMoreDescriptionsForm6: boolean = false;

   isStepReadOnly: boolean = true;

   //sixth page
   descriptionsDB: any[] = [];
   lengthShortDescriptionAdditional: number = 0;
   lengthLongDescriptionAdditional: number = 0

   arrayLengthShortDescriptionAdditional: number[] = [];
   arrayLengthLongDescriptionAdditional: number[] = [];

   //seventh page
   valueApplicationOwner: string = '';
   valueWebPage: string = '';
   valueContactEmailPropose: string = '';
   valueContactEmailOwner: string = '';

  //lengths descriptions
  lengthShortDescription: number = 0;
  lengthLongDescription: number = 0;
  lengthShortDescriptionOriginal: number = 0;
  lengthLongDescriptionOriginal: number = 0;

  idGenerator: number = 0;

  constructor(private toolService: ToolsService, private administrationPanelService: AdministrationPanelService, private countryService: CountryService) { }

  ngOnInit(): void {
    this.getTools();
    this.getCountries();
    this.getLanguage();
    //this.getStatus();
    this.getStopStep();
    this.getSolution();
    this.getTypology();
    this.getProfile();
    this.getConstraintType();
    this.getConstraintCategory();
  }

  ngOnDestroy(): void {
    if(this.getArrayCountry != null) this.getArrayCountry.unsubscribe();
  }

  getTools(){
    this.toolService.getToolByUserId(this.user.uid).toPromise().then((data: NodeResponse) => {
      console.log('data db array by user -> ', data);

      if(data.status){
        this.arrayTool = data.data;
      }else{
        alert(data.message)
      }
    })
  }

  getCountries(){
    this.getArrayCountry = this.administrationPanelService.getArrayCountry$().subscribe((data: Country[]) => {

      if(data.length == 0){
        this.countryService.getCountries().toPromise().then((dataDB: NodeResponse) => {
          console.log('countries DB -> ', dataDB);

          if(dataDB.status){
            this.arrayCountry = dataDB.data
            this.administrationPanelService.setArrayCountry(this.arrayCountry);
          }
        })
      }else{
        this.arrayCountry = data
      }
    })
  }

  getLanguage(){
    this.administrationPanelService.getArrayLanguage$().subscribe((dataLanguage: Language[]) => {

      if(dataLanguage.length == 0){
        this.countryService.getLanguages().toPromise().then((dataLanguageDB: NodeResponse) => {
          console.log('data language DB ->', this.arrayLanguage);

          this.arrayLanguage = dataLanguageDB.data;
          this.administrationPanelService.setArrayLanguage(this.arrayLanguage);
        })
      }else{
        this.arrayLanguage = dataLanguage;
        console.log('data language service ->', this.arrayLanguage);
      }
    })
  }

  /*getStatus(){
    this.getArrayToolsSolution = this.administrationPanelService.getArrayToolStatus$().subscribe( (data: ToolStatus[]) => {

      if(data.length == 0){

        this.toolService.getToolStatus().toPromise().then((dataDB: NodeResponse) => {

          console.log('dataDB status tool -> ', dataDB.data);

          if(dataDB.status == true){
            this.arrayStatus = dataDB.data;
            this.administrationPanelService.setArrayToolStatus(this.arrayStatus);
          }
        })
      }else{
        console.log('data status tool -> ', data);
        this.arrayStatus = data;
      }
    })
  }*/

  getStopStep(){
    this.getArrayStep = this.administrationPanelService.getArrayStep$().subscribe((data: Step[]) => {

      if(data.length == 0){
        this.toolService.getSteps().toPromise().then((dataDb: NodeResponse) => {

          console.log('data step DB -> ', dataDb)
          this.arrayStep = dataDb.data;
          this.administrationPanelService.setArrayStep(dataDb.data);
        })
      }else{
        console.log('data step service -> ', data);
        this.arrayStep = data;
      }
    })

    this.getArrayStop = this.administrationPanelService.getArrayStop$().subscribe((data: Stop[]) => {

      if(data.length == 0){
        this.toolService.getStops().toPromise().then((dataDb: NodeResponse) => {

          console.log('data stop DB -> ', dataDb)
          this.arrayStop = dataDb.data;
          this.administrationPanelService.setArrayStop(dataDb.data);
        })
      }else{
        console.log('data stop service -> ', data);
        this.arrayStop = data;
      }
    })
  }

  getSolution(){
    this.administrationPanelService.getArraySolution$().subscribe((data: Solution[]) => {

      if(data.length == 0){
        this.toolService.getSolutions().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB solution -> ', dataDB)

          if(dataDB.status){
            this.arraySolution = dataDB.data;
            this.administrationPanelService.setArraySolution(this.arraySolution);
          }
        })
      }else{
        console.log('data solution -> ', data)
        this.arraySolution = data;
      }
    })
  }

  getTypology(){
    this.administrationPanelService.getArrayTypology$().subscribe((data: TypologyTool[]) => {
      if(data.length == 0){
        this.toolService.getTypologies().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB typology -> ', dataDB)

          if(dataDB.status){
            this.arrayTypology = dataDB.data;
            this.administrationPanelService.setArrayTypology(this.arrayTypology);
          }
        })
      }else{
        console.log('data typology -> ', data)
        this.arrayTypology = data;
      }
    })
  }

  getProfile(){

    this.administrationPanelService.getArrayProfile$().subscribe((data: Profile[]) => {
      if(data.length == 0){
        this.toolService.getProfiles().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB profile -> ', dataDB)

          if(dataDB.status){
            this.arrayProfile = dataDB.data;
            this.administrationPanelService.setArrayProfile(this.arrayProfile);
          }
        })
      }else{
        console.log('data profile -> ', data)
        this.arrayProfile = data;
      }
    })
  }

  getConstraintType(){
    this.administrationPanelService.getArrayConstraintType$().subscribe((data: ConstraintType[]) => {
      if(data.length == 0){
        this.toolService.getContraintsType().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB constraint type -> ', dataDB)

          if(dataDB.status){
            this.arrayConstraintType = dataDB.data;
            this.administrationPanelService.setArrayConstraintType(this.arrayConstraintType);
          }
        })
      }else{
        console.log('data constraint type -> ', data)
        this.arrayConstraintType = data;
      }
    })
  }

  getConstraintCategory(){
    this.administrationPanelService.getArrayConstraintCategory$().subscribe((data: ConstraintCategory[]) => {
      if(data.length == 0){
        this.toolService.getContraintsCategory().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB constraint category -> ', dataDB)

          if(dataDB.status){
            this.arrayConstraintCategory = dataDB.data;
            this.administrationPanelService.setArrayConstraintCategory(this.arrayConstraintCategory);
          }
        })
      }else{
        console.log('data constraint category -> ', data)
        this.arrayConstraintCategory = data;
      }
    })
  }

  toTinyForm(tool: ToolsNew){
    this.window = 2;

    this.initVariablesProposeForm();

    console.log(tool)

    this.valueUid = tool.uid;
    this.adminComment = tool.adminComment;
    this.valueCountry = tool.country_origin_data.id;
    this.valueName = tool.app_name;
    this.valueNameOriginal = tool.app_name_origin;

    this.valueShortDescription = tool.short_description;
    this.valueLongDescription = tool.long_description;
    this.valueShortDescriptionOriginal = tool.short_description_origin;
    this.valueLongDescriptionOriginal = tool.long_description_origin;

    this.valueURL = tool.url;

    if(this.valueURL == null || this.valueURL == ''){
      this.valueIsURL = false;
    }else{
      this.valueIsURL = true;
    }

    this.shortDescriptionChangeEvent();
    this.longDescriptionChangeEvent();
    this.shortDescriptionOriginalChangeEvent();
    this.longDescriptionOriginalChangeEvent();
  }

  toBigForm(tool_id: number){
    this.initVariablesForm();

    this.toolService.getToolByUid(tool_id).toPromise().then((data: NodeResponse) => {
      if(data.status){
        let tool = data.data;

        console.log(tool)
        this.toolSelected = tool;
        //first page
        this.adminComment = tool.adminComment;
        this.valueUid = tool.uid;
        this.valueCountry = tool.country_origin_data.id;
        this.valueName = tool.app_name;
        this.valueNameOriginal = tool.app_name_origin;
        this.valueShortDescription = tool.short_description;
        this.valueLongDescription = tool.long_description;
        this.valueShortDescriptionOriginal = tool.short_description_origin;
        this.valueLongDescriptionOriginal = tool.long_description_origin;
        this.valueURL = tool.url;

        if(this.valueURL == null || this.valueURL == ''){
          this.valueIsURL = false;
        }else{
          this.valueIsURL = true;
        }

        this.shortDescriptionChangeEvent();
        this.longDescriptionChangeEvent();
        this.shortDescriptionOriginalChangeEvent();
        this.longDescriptionOriginalChangeEvent();

        //second page

        this.toolImage = tool.main_image;
        //console.log(toolSelected.tools_images.length)

        if(tool.tools_images.length > 0){
          for(let i = 0; i < tool.tools_images.length; i++){
            console.log('estoy dentro del bucle')
            let name: string =  tool.tools_images[i].name;
            this.nameOfImages += name.split('/')[4];

            if(i < tool.tools_images.length)  this.nameOfImages += ', '
          }
        }

        this.valueVideoURL = tool.video_url;
        this.additionalImages = tool.tools_images;
        this.isAllDocuments = false;
        this.toolsDocuments = tool.tools_documents;

        if(tool.tools_documents.length > 0){
          for(let i = 0; i < tool.tools_documents.length; i++){
            let name: string =  tool.tools_documents[i].name;
            this.pdfDocumentsString += name.split('/')[4];

            if(i < tool.tools_documents.length)  this.pdfDocumentsString    += ', '
          }
        }

        if(this.pdfDocumentsString != '' || this.nameOfImages != '' || (this.valueVideoURL != null && this.valueVideoURL.length > 0)){
          this.valueFlexRadioMonitoring = true;
        }

        //third page
        this.valuePrototype = tool.prototypeValue;
        this.valueAccess = tool.access_characteristics;
        this.valueFlexRadioAccessForm3 = tool.login_access;
        this.valueOpenSource = tool.open_source;
        this.valuelUrlRepository = tool.repo_url;
        this.valueFlexRadioRepositoryForm3 = this.valuelUrlRepository != null && this.valuelUrlRepository != '' ? true : false;
        this.valueApiUrl = tool.api_url;
        this.valueFlexRadioAPIForm3 = this.valueApiUrl != null && this.valueApiUrl != '' ? true : false;
        tool.countries.forEach(e => this.valueCountryWork.push(e.id));
        this.valueFlexRadioCountriesForm3 = this.valueCountryWork.length > 0 ? true : false;
        this.valueExpert = tool.expert;
        this.valueFlexRadioMoreConstraints = tool.constraints_infos.length > 0 ? true : false;

        //fourth page

        this.tmpConstrainsCategoryList = [];
        for(let i = 0; i < tool.constraints_infos.length; i++){
          this.idGenerator++;
          this.tmpConstrainsCategoryList.push([]);

          this.valueConstraintInfo.push({
            profile_id: tool.constraints_infos[i].profile_id + '',
            constraint_type_id: tool.constraints_infos[i].constraint_type_id + '',
            constraint_category: [],
            tool_id: null,
            idg: this.idGenerator
          });
          console.log('value constraint info -> ', this.valueConstraintInfo);

          this.isCategoryReadOnly.push(true);
          this.categoryChangeEvent(i);

          console.log('lista de constraint de esta iteracion -> ', this.tmpConstrainsCategoryList[i]);
          let arrayCategoryCurrentConstraint = this.tmpConstrainsCategoryList[i];

          for(let j = 0; j < tool.constraints_infos[i].constraint_category.length; j++){
            for(let k = 0; k < arrayCategoryCurrentConstraint.length; k++){
              if(tool.constraints_infos[i].constraint_category[j] == arrayCategoryCurrentConstraint[k].name){
                this.valueConstraintInfo[i].constraint_category.push(arrayCategoryCurrentConstraint[k].name);
              }
              //console.log('constraint value -> ', tool.constraints_infos[i].constraint_category[j], typeof(tool.constraints_infos[i].constraint_category[j]), 'constraint array -> ', this.tmpConstrainsCategoryList[k], typeof(this.tmpConstrainsCategoryList[k].name));
            }
          }
        }


        //fifth sixth page
        tool.solutions.forEach(e => this.valueSolutions.push(e.id));
        tool.typologies.forEach(e => this.valueTypologies.push(e.id));
        tool.stops.forEach(e => this.valueStops.push(e.id));
        this.stopChangeEvent();
        tool.steps.forEach(e => this.valueSteps.push(e.id));

        if(this.valueSteps.length > 0) this.isStepReadOnly == true;

        this.valueTrainingPage = tool.training_url;
        this.valueFlexRadioTrainingForm6 = this.valueTrainingPage == null || this.valueTrainingPage == '' ? false : true;

        if(tool.languages.length > 0) {
          tool.languages.forEach(e => this.valueLanguagesAdditional.push(e.language_code));
          this.valueFlexRadioMoreLanguagesForm6 = true;

          if(tool.tools_descriptions.length > 0) {
            //this.descriptionLanguage.push(tool.tools_descriptions)
            tool.tools_descriptions.forEach(e => {
              this.descriptionLanguage.push({language_id: e.language_id, name_other_language: e.name_other_language, short_description: e.short_description, long_description: e.long_description, tool_id: null});
              this.arrayLengthShortDescriptionAdditional.push(0);
              this.arrayLengthLongDescriptionAdditional.push(0);
            })

            this.valueFlexRadioMoreDescriptionsForm6 = tool.tools_descriptions.length > 0 ? true : false;
          }
        }

        //seventh page
        this.valueApplicationOwner = tool.app_owner;
        this.valueWebPage = tool.webpage;
        this.valueContactEmailPropose = tool.contact_email_proposal;
        this.valueContactEmailOwner = tool.contact_email_owner;

        this.window = 3;

      }
    })


  }

  initVariablesForm(){
    this.switchFormCount = 1;

    this.valueUid = 0;
    this.valueCountry = null;
    this.valueName = '';
    this.valueNameOriginal = '';
    this.valueIsURL = false;
    this.valueURL = '';
    this.valueShortDescription = '';
    this.valueLongDescription = '';
    this.valueShortDescriptionOriginal = '';
    this.valueLongDescriptionOriginal = '';

    //second page
    this.toolImage = "";
    this.mainImage = null;
    this.valueFlexRadioMonitoring = false;
    this.additionalImages = [];
    this.valueVideoURL = '';
    this.toolsDocuments = [];

    this.toolImage = '';
    this.nameOfImages = '';

    this.pdfDocumentsString = '';

    this.isAdditionalImagesChange = false;
    this.isPdfFilesChange = false;

    //third page
    this.valuePrototype = false;
    this.valueFlexRadioAccessForm3 = false;
    this.valueAccess = 'free-access';
    this.valueOpenSource = false;
    this.valueFlexRadioRepositoryForm3 = false;
    this.valuelUrlRepository = '';
    this.valueFlexRadioAPIForm3 = false;
    this.valueApiUrl = '';
    this.valueFlexRadioCountriesForm3 = false;
    this.valueCountryWork = [];
    this.valueExpert = false;


    //fourth page
    this.valueFlexRadioMoreConstraints = false;
    this.valueConstraintInfoOld = [];
    this.tmpConstrainsCategoryList = [];
    this.isCategoryReadOnly = [];

    //fifth page
    this.valueConstraintInfo = [];

    //sixth page
    this.valueSolutions = [];
    this.valueTypologies = [];
    this.valueStops = [];
    this.valueSteps = [];
    this.valueFlexRadioTrainingForm6 = false;
    this.valueTrainingPage = '';
    this.valueFlexRadioMoreLanguagesForm6 = false;
    this.valueLanguagesAdditional = [];
    this.tmpValueLanguagesAdditional = [];


    this.descriptionLanguage = [];
    this.valueFlexRadioMoreDescriptionsForm6 = false;


    //seventh page
    this.descriptionsDB = [];
    this.lengthShortDescriptionAdditional = 0;
    this.lengthLongDescriptionAdditional = 0

    this.arrayLengthShortDescriptionAdditional = [];
    this.arrayLengthLongDescriptionAdditional = [];

    //eigth page
    this.valueApplicationOwner = '';
    this.valueWebPage = '';
    this.valueContactEmailPropose = '';
    this.valueContactEmailOwner = '';
  }

  deleteProposeTool(uid: number){
    if(confirm('Do you want to delete this tool?') == true){

      this.toolService.deleteProposeTool(uid).toPromise().then((data: NodeResponse) => {
        console.log(data);

        if(data.status){
          let index = this.arrayTool.findIndex(e => e.uid == uid);
          this.arrayTool.splice(index, 1);
        }
      })
    }
  }

  deleteTool(tool_uid: number){

    if(confirm('Do you want to delete this tool?') == true){

      this.toolService.getToolByUid(tool_uid).toPromise().then((data: NodeResponse) => {
        if(data.status == true){
          let tool: ToolsNew = data.data;

          this.toolService.deleteTool(tool).toPromise().then((dataDelete: NodeResponse) => {
            console.log(dataDelete);

            if(dataDelete.status){

              let index = this.arrayTool.findIndex(e => e.uid == tool.uid);
              this.arrayTool.splice(index, 1);
              this.window = 1;
            }else{
              alert(data.message);
            }
          })
        }
      })
    }
  }

  updateToPropose(){

    if(confirm('Do you want to update this tool?') == true){

      this.toolService.updateProposeTool(
        this.valueUid,
        this.valueCountry,
        this.valueName,
        this.valueNameOriginal,
        this.valueURL,
        this.valueShortDescription,
        this.valueLongDescription,
        this.valueShortDescriptionOriginal,
        this.valueLongDescriptionOriginal
      ).toPromise().then((data: NodeResponse) => {
        console.log('response update to propose -> ', data);

        if(data.status){
          let index = this.arrayTool.findIndex(e => e.uid == this.valueUid);
          this.arrayTool.splice(index, 1);
          this.arrayTool.push(data.data);

          this.window = 1;
          alert(data.message);
        }else{
          alert(data.message);
        }
      })
    }

  }

  updateTool(){
    if(confirm('Do you want to update this tool?') == true){

      let nameMainImage = this.mainImage == null ? this.toolImage : this.mainImage.name;
      let formData = new FormData();

      const body = {
        uid: this.valueUid,
        country_origin: this.valueCountry,
        app_name: this.valueName,
        app_name_origin: this.valueNameOriginal,
        url: this.valueURL,
        short_description: this.valueShortDescription,
        long_description: this.valueLongDescription,
        short_description_origin: this.valueShortDescriptionOriginal,
        long_description_origin: this.valueLongDescriptionOriginal,
        main_image: nameMainImage, //body.main_image,
        video_url: null, //body.video_url,
        prototypeValue: this.valuePrototype,
        login_access: this.valueFlexRadioAccessForm3,
        access_characteristics: this.valueAccess,
        open_source: this.valueOpenSource,
        repo_url: this.valuelUrlRepository,
        api_url: this.valueApiUrl,
        expert: this.valueExpert,
        training_url: this.valueTrainingPage,
        app_owner: this.valueApplicationOwner,
        webpage: this.valueWebPage,
        contact_email_proposal: this.valueContactEmailPropose,
        contact_email_owner: this.valueContactEmailOwner,
        toolStatus: 3,
        userUid: this.user.uid,
        arrayConstraintsInfo: this.valueConstraintInfo,
        arrayDescription: this.descriptionLanguage,
        arrayLanguage: this.valueLanguagesAdditional,
        arraySolution: this.valueSolutions,
        arrayTypology: this.valueTypologies,
        arrayStop: this.valueStops,
        arrayStep: this.valueSteps,
        arrayCountry: this.valueCountryWork
      }

      console.log('main image -> ', this.mainImage)

      if(this.valueFlexRadioMonitoring == true) body.video_url = this.valueVideoURL;

      formData.append('tool', JSON.stringify(body));
      formData.append('oldTool', JSON.stringify(this.toolSelected));
      formData.append('image', this.mainImage);

      this.toolService.updateTool(formData).toPromise().then(async (data: NodeResponse) => {
        console.log('data update tool -> ', data)

        if(data.status){

          if(this.valueFlexRadioMonitoring == true){

            if(this.isAdditionalImagesChange == true){

              for(let i = 0; i < this.additionalImages.length; i++){
                let formDataAdditionalImages = new FormData();
                console.log(this.valueUid);

                formDataAdditionalImages.append('image', this.additionalImages[i], this.additionalImages[i].name);
                formDataAdditionalImages.append('toolId', this.valueUid + '');
                let isFirst = i == 0 ? true : false;
                formDataAdditionalImages.append('isFirst', isFirst + '');

                await this.toolService.updateUploadImage(formDataAdditionalImages).toPromise().then((updateImage: NodeResponse) => {
                  console.log(updateImage);
                })
              }

            }

            if(this.isPdfFilesChange == true){

              for(let i = 0; i < this.toolsDocuments.length; i++){
                let bodyPdf = new FormData();

                bodyPdf.append('file', this.toolsDocuments[i], this.toolsDocuments[i].name);
                bodyPdf.append('toolId', this.valueUid + '');
                let isFirst = i == 0 ? true : false;
                bodyPdf.append('isFirst', isFirst + '');

                await this.toolService.updateUploadDocuments(bodyPdf).toPromise().then((data: NodeResponse) => {
                  console.log("dataTool node update pdf -> ", data);
                })

              }
            }

            await this.toolService.getToolByUid(this.valueUid).toPromise().then((data: NodeResponse) => {
              console.log("dataTool node file -> ", data);

              if(data.status){

                let index = this.arrayTool.findIndex(tool => tool.uid == this.valueUid);
                this.arrayTool.splice(index, 1);

                this.arrayTool.push(data.data);
                //this.tmpArrayTool.push(data.data);
                this.administrationPanelService.setArrayValidateTool(this.arrayTool);

                this.window = 1;
              }
            })
          }else{

            if(this.toolSelected.tools_images.length > 0){
              await this.toolService.deleteImage(this.valueUid).toPromise().then((data: NodeResponse) => {
                console.log("dataImage node delete  -> ", data);
              })
            }

            if(this.toolSelected.tools_documents.length > 0){
              await this.toolService.deletePDF(this.valueUid).toPromise().then((data: NodeResponse) => {
                console.log("dataPDF node delete  -> ", data);
              })
            }

            await this.toolService.getToolByUid(this.valueUid).toPromise().then((data: NodeResponse) => {
              console.log("dataTool node file -> ", data);

              if(data.status){

                let index = this.arrayTool.findIndex(tool => tool.uid == this.valueUid);
                this.arrayTool.splice(index, 1);

                this.arrayTool.push(data.data);
                //this.tmpArrayTool.push(data.data);
                this.administrationPanelService.setArrayValidateTool(this.arrayTool);

                this.window = 1;
              }
            })
          }
        }


      })
    }
  }

  shortDescriptionChangeEvent(){
    this.lengthShortDescription = this.valueShortDescription.length;
  }

  longDescriptionChangeEvent(){
    this.lengthLongDescription = this.valueLongDescription.length;
  }

  shortDescriptionOriginalChangeEvent(){

    if(this.valueShortDescriptionOriginal == null || this.valueShortDescriptionOriginal == undefined){
      this.lengthShortDescriptionOriginal = 0;
    }else{
      this.lengthShortDescriptionOriginal = this.valueShortDescriptionOriginal.length;
    }
  }

  longDescriptionOriginalChangeEvent(){

    if(this.valueLongDescriptionOriginal == null || this.valueLongDescriptionOriginal == undefined){
      this.lengthLongDescriptionOriginal = 0;
    }else{
      this.lengthLongDescriptionOriginal = this.valueLongDescriptionOriginal.length;
    }
  }

  initVariablesProposeForm(){
    this.valueUid = 0;
    this.valueCountry = null;
    this.valueName = '';
    this.valueNameOriginal = '';
    this.valueIsURL = null;
    this.valueURL = '';
    this.valueShortDescription = '';
    this.valueLongDescription = '';
    this.valueShortDescriptionOriginal = '';
    this.valueLongDescriptionOriginal = '';
    this.adminComment = '';
  }

  fileImageChangeEvent(event: any){

    this.mainImage = null;

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.mainImage = event.target.files[i];
      }else{
        alert('You must to select a IMAGE');
        this.mainImage = null;
      }
    }

  }

  fileOtherImageChangeEvent(event: any){

    let isAllImages = true;
    this.additionalImages = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.additionalImages.push(event.target.files[i]);
        this.isAdditionalImagesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es una imagen');
        isAllImages = false;
        break;
      }
    }

    if(isAllImages == false){
      this.additionalImages = [];
      alert('You must to select IMAGES');
    }
  }

  fileChangeEvent(event: any){

    let isAllDocuments = true;
    this.toolsDocuments = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log( 'Archivo seleccionado n' + i + '-> ', event.target.files[i]);

      if(event.target.files[i].type.substring(5) == 'pdf' || event.target.files[i].type == 'application/pdf'){
        this.toolsDocuments.push(event.target.files[i]);
        this.isPdfFilesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es un archivo PDF -> ');
        isAllDocuments = false;
        break;
      }
    }

    if(isAllDocuments == false){
      this.toolsDocuments = [];
      alert('You must to select PDF FILES');
    }
  }

  addForm(page: number){

    switch(this.switchFormCount){
      case 4:
        this.idGenerator++;
        this.valueConstraintInfo.push({profile_id: null, constraint_type_id: null, constraint_category: null, tool_id: null, idg: this.idGenerator});
        this.isCategoryReadOnly.push(true);
        break;
      case 6:
        this.idGenerator++;
        this.descriptionLanguage.push({language_id: null, name_other_language: null, short_description: null, long_description: null, tool_id: null});
        this.arrayLengthShortDescriptionAdditional.push(0);
        this.arrayLengthLongDescriptionAdditional.push(0);
        break;
    }

  }

  deductForm(page: number, index: number){

    switch(page){
      case 4:
        if(this.valueConstraintInfo.length > 0) {
          this.valueConstraintInfo.splice(index, 1);
          this.isCategoryReadOnly.splice(index, 1);
        }
        break;
      case 6:
        if(this.descriptionLanguage.length > 0){
          this.descriptionLanguage.splice(index, 1);
          this.arrayLengthShortDescriptionAdditional.splice(index, 1);
          this.arrayLengthLongDescriptionAdditional.splice(index, 1);
        }

        break;
    }
  }

  categoryChangeEvent(index: number){
    this.isCategoryReadOnly[index] = true;
    this.valueConstraintInfo[index].constraint_category = [];
    this.tmpConstrainsCategoryList[index] = [];

    let idProfile = this.valueConstraintInfo[index].profile_id;
    let idType = this.valueConstraintInfo[index].constraint_type_id;

    if(idProfile != null && idType != null){
      this.arrayConstraintCategory.forEach(current => {
        if(current.profile_id == idProfile && current.constraint_type_id == idType) this.tmpConstrainsCategoryList[index].push(current);
      });
      this.isCategoryReadOnly[index] = false;
    }

    console.log(idProfile, idType, this.isCategoryReadOnly, this.tmpConstrainsCategoryList)

  }

  stopChangeEvent(){
    console.log(this.tmpArrayStep, this.valueSteps);

    this.valueSteps = [];
    this.tmpArrayStep = [];
    this.isStepReadOnly = this.valueStops.length == 0 ? true : false;

    this.valueStops.forEach(stop => {
      if(stop == 1) this.tmpArrayStep.push(this.arrayStep[0], this.arrayStep[1]);
      if(stop == 2) this.tmpArrayStep.push(this.arrayStep[2], this.arrayStep[3]);
      if(stop == 3) this.tmpArrayStep.push(this.arrayStep[4], this.arrayStep[5], this.arrayStep[6]);
      if(stop == 4) this.tmpArrayStep.push(this.arrayStep[7], this.arrayStep[8], this.arrayStep[9], this.arrayStep[10]);
      if(stop == 5) this.tmpArrayStep.push(this.arrayStep[11], this.arrayStep[12], this.arrayStep[13], this.arrayStep[14]);
    })

  }

  shortDescriptionAdditionalChangeEvent(index: number){
    //this.arrayLengthShortDescriptionAdditional[index] = this.descriptionLanguage[index].short_description.length;
  }

  longDescriptionAdditionalChangeEvent(index: number){
    this.arrayLengthLongDescriptionAdditional[index] = this.descriptionLanguage[index].long_description.length;
  }

  addSwitchFormCount(){

    if(this.switchFormCount <= this.totalFormCount){
      switch(this.switchFormCount){
        case 1:
          if(this.saveFirstForm()){
            this.switchFormCount++;
          }else{
            alert('You must to type in some inputs');
          }
          //this.switchFormCount++;

          break;

        case 2:
          if(this.saveSecondForm()){
            this.switchFormCount++;
          }else{
            alert('You must to type in some inputs');
          }
          //this.switchFormCount++;

          break;

        case 3:

          if(this.window == 2){
            this.valueConstraintInfo = []
            this.isCategoryReadOnly = [];
          }

          if(this.saveThirdForm()){
            if(this.valueFlexRadioMoreConstraints == true){
              this.idGenerator++;

              if(this.window == 2){
                this.valueConstraintInfo.push({profile_id: null, constraint_type_id: null, constraint_category: null, tool_id: null, idg: this.idGenerator});
                this.isCategoryReadOnly.push(true);
              }else if(this.window == 3){
                if(this.valueConstraintInfo.length == 0){
                  this.valueConstraintInfo.push({profile_id: null, constraint_type_id: null, constraint_category: null, tool_id: null, idg: this.idGenerator});
                  this.isCategoryReadOnly.push(true);
                }
              }

              this.switchFormCount++;
            }else{
              this.switchFormCount = this.switchFormCount + 2;
            }
          }else{
            alert('You must to type in some inputs');
          }

          break;

        case 4:
          let isSaved2 = true;
          console.log(this.isChangeConstraintsInfo())

          for(let i = 0; i < this.valueConstraintInfo.length; i++){
            if(this.saveFourthForm(i) == false){
              alert("You must to complete whole form");
              isSaved2 = false;
              break;
            }
          }

          if(isSaved2 == true){
            this.switchFormCount ++;
          }

          break;

        case 5:
          if(this.window == 2){
            this.descriptionLanguage = [];
            this.arrayLengthShortDescriptionAdditional = [];
            this.arrayLengthLongDescriptionAdditional = [];
          }


          if(this.saveFifthForm() == true){
            if(this.valueFlexRadioMoreDescriptionsForm6 == true){

              if(this.window == 2){
                this.descriptionLanguage.push({language_id: null, name_other_language: null, short_description: null, long_description: null, tool_id: null});
                this.arrayLengthShortDescriptionAdditional.push(0);
                this.arrayLengthLongDescriptionAdditional.push(0);

              }else if(this.window == 3){

                if(this.descriptionLanguage.length == 0){
                  this.descriptionLanguage.push({language_id: null, name_other_language: null, short_description: null, long_description: null, tool_id: null});
                  this.arrayLengthShortDescriptionAdditional.push(0);
                  this.arrayLengthLongDescriptionAdditional.push(0);
                }
              }

              this.switchFormCount++;
            }else{
              this.switchFormCount = this.switchFormCount + 2;
            }
          }else{
            alert("You must to complete whole form");
          }

          break;

        case 6:
          let isSaved = true;

          for(let i = 0; i < this.descriptionLanguage.length; i++){
            if(this.saveSixthForm(i) == false){
              isSaved = false;
              break;
            }
          }

          if(isSaved == true){
            this.switchFormCount++;
          }else{
            alert('You must to complete whole form');
          }

          break;

        case 7:
          this.updateTool();

          break;
      }
    }

  }

  deductSwitchFormCount(){

    if(this.switchFormCount == 5 && this.valueFlexRadioMoreConstraints == false){
      this.switchFormCount = this.switchFormCount - 2;
    }else if(this.switchFormCount == 7 && this.valueFlexRadioMoreDescriptionsForm6 == false){
      this.switchFormCount = this.switchFormCount - 2;
    }else{
      this.switchFormCount--;
    }
  }

  isChangeConstraintsInfo(): boolean{

    if(this.valueConstraintInfo == null && (this.valueConstraintInfoOld != null && this.valueConstraintInfoOld.length == 0)){
      return true;

    }else if(this.valueConstraintInfoOld == null && this.valueConstraintInfo != null){
      return true;

    }else{

      let first = JSON.stringify(this.valueConstraintInfo);
      let second = JSON.stringify(this.valueConstraintInfoOld);

      if(first != second){
        return true;
      }else{
        return false;
      }
    }
  }

  isChangeDescriptionLanguages(): boolean{

    if(this.descriptionLanguage == null && (this.descriptionsDB != null && this.descriptionsDB.length == 0)){
      return true;

    }else if(this.descriptionsDB == null && this.descriptionLanguage != null){
      return true;

    }else{

      let first = JSON.stringify(this.descriptionLanguage);
      let second = JSON.stringify(this.descriptionsDB);

      if(first != second){
        return true;
      }else{
        return false;
      }
    }
  }

  saveFirstForm(): boolean{

    let country = isNumber(this.valueCountry);
    let name = this.valueShortDescription.length > 0 ? true : false;

    let url = false;

    if(this.valueIsURL == false){
      url = true;
    }else if(this.valueIsURL == true && this.valueURL.length > 0){
      url = true;
    }

    let commentShort = this.valueShortDescription.length > 0 ? true : false;
    let commentLong = this.valueLongDescription.length > 0 ? true : false;

    return (url && commentShort && commentLong && country && name);
  }

  saveSecondForm(): boolean{
    let isMainImage = this.mainImage != null || this.toolImage != '' ? true : false;
    let additional = false;

    if(this.valueFlexRadioMonitoring == false){
      additional = true;
    }else if(this.valueFlexRadioMonitoring == true){
      if((this.additionalImages.length > 0 || this.nameOfImages != '') || this.valueVideoURL != ''|| (this.toolsDocuments.length > 0 || this.pdfDocumentsString != '')){
        additional = true;
      }
    }

    console.log('second Form -> ', isMainImage && additional)

    return (isMainImage && additional);
  }

  saveThirdForm(): boolean{
    let access = false;
    let repository = false;
    let api = false;
    let countries = false;
    let cost = false;

    if(this.valueFlexRadioAccessForm3 != null){
      access = true;
    }

    if(this.valueAccess != null){
      cost = true;
    }

    if(this.valueFlexRadioRepositoryForm3 == false){
      repository = true;
    }else if(this.valueFlexRadioRepositoryForm3 == true && this.valuelUrlRepository != ''){
      repository = true;
    }

    if(this.valueFlexRadioAPIForm3 == false){
      api = true;
    }else if(this.valueFlexRadioAPIForm3 == true && this.valueApiUrl != ''){
      api = true;
    }

    if(this.valueFlexRadioCountriesForm3 == false){
      countries = true;
    }else if(this.valueFlexRadioCountriesForm3 == true && this.valueCountryWork.length > 0){
      countries = true;
    }

    console.log('third Form -> ', (access && repository && api && countries && cost))

    return (access && repository && api && countries && cost);
  }

  saveFourthForm(index: number){

    if(
        this.valueConstraintInfo[index].profile_id != null
        && this.valueConstraintInfo[index].constraint_type_id != null
        && this.valueConstraintInfo[index].constraint_category != null && this.valueConstraintInfo[index].constraint_category.length > 0
      ){
        return true;
    }else{
      return false;
    }

    return true;
  }

  saveFifthForm(){

    this.tmpValueLanguagesAdditional = [];

    let training = false;
    let countries = false;

    let solutions = this.valueSolutions.length > 0 ? true : false;
    let typologies = this.valueTypologies.length > 0 ? true : false;
    let stops = this.valueStops.length > 0 ? true : false;
    let steps = this.valueSteps.length > 0 ? true : false;

    if(this.valueFlexRadioTrainingForm6 == true && this.valueTrainingPage.length > 0){
      training = true;
    }else if(this.valueFlexRadioTrainingForm6 == false){
      training = true;
    }

    if(this.valueFlexRadioMoreLanguagesForm6 == false){
      countries = true;
    }else if(this.valueFlexRadioMoreLanguagesForm6 == true){
      if(this.valueLanguagesAdditional.length > 0){
        countries = true;

        for(let i = 0; i < this.valueLanguagesAdditional.length; i++){
          for(let j = 0; j < this.arrayLanguage.length; j++){
            if(this.valueLanguagesAdditional[i] == this.arrayLanguage[j].language_code){
              this.tmpValueLanguagesAdditional.push(this.arrayLanguage[j]);
            }
          }
        }

      }
    }

    console.log('fifth Form -> ', training , solutions , typologies , stops , steps, countries)

    return (training && solutions && typologies && stops && steps && countries)
  }

  saveSixthForm(index: number){

    if(
        this.descriptionLanguage[index].language_id != null
        && (this.descriptionLanguage[index].name_other_language != null && this.descriptionLanguage[index].name_other_language != '')
        && (this.descriptionLanguage[index].short_description != null && this.descriptionLanguage[index].short_description != '')
        && (this.descriptionLanguage[index].long_description != null && this.descriptionLanguage[index].long_description != '')
    ){
      return true;
    }else{
      return false;
    }
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ToolsService} from '../../core/tools/tools.service';
import {Tools} from '../../shared/models/tools';
import {Router} from '@angular/router';
import {LoginComponent} from '../login/login.component';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {ToolsModalComponent} from '../../components/tools-modal/tools-modal.component';
import {UserService} from '../../core/authentication/user.service';
import { Building } from 'src/app/shared/models/building';
import { User } from 'src/app/shared/models/user';
import { TranslateService } from '@ngx-translate/core';
import { ToolsNew } from 'src/app/shared/models/toolsnew';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import {AngularFireAuth} from '@angular/fire/auth';
import { ToolRate } from 'src/app/shared/models/tool_rate';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
  image: string;
  contenido: string;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class LandingComponent implements OnInit {
  starRating = 3;
  tools: Tools[] = [];
  filters = {countries: [], typologies: [], profiles: [], solutions: [], steps: [], stops: []};
  countrySelectd: string;
  profileSelectd: string;
  typologySelectd: string;
  solutionSelectd: string;
  stepSelectd: string;
  stopSelectd: string;
  filterApplied: string[];
  tmpTools: Tools[] = [];
  countryOneClick: string;
  countriesOneClick: [{id, name}];
  confirmAddress = [];
  isFavoriteTool: boolean;
  modalRef: BsModalRef;
  currentMsgToParent="";
  msgFromChild1 = [];

  isUserLogged: boolean;
  currentUser: User = new User();
  countrySelected: number = 0;

  newTools: ToolsNew[] = [];
  tmpNewTools: ToolsNew[] = [];

  imageUrl = GlobalConstants.backendURL;

  //Pagination
  page: number = 1;
  pageSize: number = 6;

  arrayToolMostVisited: any[] = [];
  counterMostVisited: number = 0;

  pageMostVisited: number = 1;
  pageSizeMostVisited: number = 6;

  arrayToolMostRated: any[] = [];
  counterMostRated: number = 0;

  pageMostRated: number = 1;
  pageSizeMostRated: number = 6;

  arrayToolMostRecent: any[] = [];
  counterMostRecent: number = 0;

  pageMostRecent: number = 1;
  pageSizeMostRecent: number = 6;

  //end pagination

  pagePopularSpain: number = 1;
  pageSizePopularSpain: number = 6;

  pagePopularEurope: number = 1;
  pageSizePopularEurope: number = 6;

  tmpNewToolsPopularSpain: ToolsNew[] = [];
  tmpNewToolsMostVisited: ToolsNew[] = [];

  currentPage: number = 1;
  objectsPerPage: number = 6;
  totalPages: number;

  currentPageMostVisited: number = 1;
  objectsPerPageMostVisited: number = 6;
  totalPagesMostVisited: number;

  currentPagePopularEurope: number = 1;
  objectsPerPagePopularEurope: number = 6;
  totalPagesPopularEurope: number;

  currentPagePopularSpain: number = 1;
  objectsPerPagePopularSpain: number = 6;
  totalPagesPopularSpain: number;

  rateObjects: any[];
  averageRatings: number[] = [];

  @Input() tool: Tools;
  @Input() addressEmitter = new EventEmitter<any>();
  constructor(private afAuth: AngularFireAuth, private toolsService: ToolsService, private router: Router, private modalService: BsModalService, private userService: UserService, public translate: TranslateService, private administrationPanelService: AdministrationPanelService) {

  }

  ngOnInit(): void {
    this.checkLogin(); // <- initTools inside
    this.filterApplied = [];
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;
            this.countrySelected = userFromDB['data'].country_id;
          }
          console.log(this.currentUser)
        });
      } else {
        this.isUserLogged = false;
        this.currentUser = null;
      }
    });
    this.initTools();
  }

  getCountries() {
   this.toolsService.getFilters().subscribe( data => {
     this.countriesOneClick = data['countries'];
   });
  }

  initTools(){
    this.pagination('rated', 'first');
    this.pagination('visited', 'first');
    this.pagination('recent', 'first');
  }

  pagination(section: string, action: string){

    if(section == 'visited'){
      this.arrayToolMostVisited = [];

      switch(action){
        case 'first':
          this.pageMostVisited = 1;
          break;

        case 'previous':
          this.pageMostVisited--;
          break;

        case 'next':
          this.pageMostVisited++;
          break;

        case 'last':
          this.pageMostVisited = Math.ceil(this.counterMostVisited / this.pageSizeMostVisited);
          break;
      }

      this.toolsService.getMostVisited(this.pageSizeMostVisited, (this.pageMostVisited - 1) * this.pageSizeMostVisited, this.countrySelected).toPromise().then((data: NodeResponse) => {
        console.log('tools most visited -> ', data)

        if(data.status){
          this.arrayToolMostVisited = data.data;
          this.counterMostVisited = data.arrayLengthCounter;
        }
      })
    }

    if(section == 'rated'){
      this.arrayToolMostRated = [];

      switch(action){
        case 'first':
          this.pageMostRated = 1;
          break;

        case 'previous':
          this.pageMostRated--;
          break;

        case 'next':
          this.pageMostRated++;
          break;

        case 'last':
          this.pageMostRated = Math.ceil(this.counterMostRated / this.pageSizeMostRated);
          break;
      }

      this.toolsService.getMostRated(this.pageSizeMostRated, (this.pageMostRated - 1) * this.pageSizeMostRated).toPromise().then((data: NodeResponse) => {
        console.log('tools most rated -> ', data)

        if(data.status){
          this.arrayToolMostRated = data.data;
          this.counterMostRated = data.arrayLengthCounter;
        }
      })
    }

    if(section == 'recent'){
      this.arrayToolMostRecent = [];
      switch(action){
        case 'first':
          this.pageMostRecent = 1;
          break;

        case 'previous':
          this.pageMostRecent--;
          break;

        case 'next':
          this.pageMostRecent++;
          break;

        case 'last':
          this.pageMostRecent = Math.ceil(this.counterMostRecent / this.pageSizeMostRecent);
          break;
      }

      this.toolsService.getRecentTools(this.pageSizeMostRecent, (this.pageMostRecent - 1) * this.pageSizeMostRecent, this.countrySelected).toPromise().then((data: NodeResponse) => {
        console.log('tools most recent -> ', data)

        if(data.status){
          this.arrayToolMostRecent = data.data;
          this.counterMostRecent = data.arrayLengthCounter;

          console.log('tools recent ordenadas -> ', this.arrayToolMostRecent)
        }
      })
    }

  }

  getLastNumberPageMostVisited(): number{
    return Math.ceil(this.counterMostVisited / this.pageSizeMostVisited);
  }

  getLastNumberPageMostRated(): number{
    return Math.ceil(this.counterMostRated / this.pageSizeMostRated);
  }

  getLastNumberPageMostRecent(): number{
    return Math.ceil(this.counterMostRecent / this.pageSizeMostRecent);
  }

  buildToolsInformation(data) {
    data.forEach( tool => {
      const countries = [];
      const profiles = [];
      const solutions = [];
      const typologies = [];
      const steps = [];
      const stops = [];
      tool.tools_countries.forEach( country => {
        countries.push({id: country.id, name: country.name});
        if (this.filters.countries.length === 0 || !this.filters.countries.find(item => item.id === country.id)){
          this.filters.countries.push({id: country.id, name: country.name});
        }
      });
      tool.tools_typologies.forEach( typology => {
        typologies.push({id: typology.id, typology: typology.typology});
        if (this.filters.typologies.length === 0 || !this.filters.typologies.find(item => item.id === typology.id)){
          this.filters.typologies.push({id: typology.id, typology: typology.typology});
        }
      });
      tool.tools_profiles.forEach( profile => {
        profiles.push({id: profile.id, profile: profile.profile});
        if (this.filters.profiles.length === 0 || !this.filters.profiles.find(item => item.id === profile.id)){
          this.filters.profiles.push({id: profile.id, profile: profile.profile});
        }
      });
      tool.tools_stops.forEach( stop => {
        stops.push({id: stop.id, stop: stop.stop});
        if (this.filters.stops.length === 0 || !this.filters.stops.find(item => item.id === stop.id)){
          this.filters.stops.push({id: stop.id, stop: stop.stop});
        }
      });
      tool.tools_steps.forEach( step => {
        steps.push({id: step.id, step: step.step});
        if (this.filters.steps.length === 0 || !this.filters.steps.find(item => item.id === step.id)){
          this.filters.steps.push({id: step.id, step: step.step});
        }
      });
      tool.tools_solutions.forEach( solution => {
        solutions.push({id: solution.id, solution: solution.solution});
        if (this.filters.solutions.length === 0 || !this.filters.solutions.find(item => item.id === solution.id)){
          this.filters.solutions.push({id: solution.id, solution: solution.solution});
        }
      });
      const info = new Tools(
        tool.id,
        tool.name,
        tool.login_access,
        tool.url,
        tool.short_description,
        tool.long_description,
        tool.image,
        tool.wurl,
        countries,
        typologies,
        profiles,
        solutions,
        steps,
        stops
      );
      this.tools.push(info);
    });
  }
  applyFilter(): void {
    let toolsTmp = [];
    this.tools.forEach( tool => {
      toolsTmp.push(tool);
    });
    this.filterApplied.forEach( filter => {
      if (filter === 'countries') {
        const filterByCountry  = [];
        this.tools.forEach( tool => {
          const hasCountry = tool.countries.find( (item, i) => {
            if (item.id === this.countrySelectd){
              return true;
            }
          });
          if( hasCountry ) {
            filterByCountry.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByCountry);
      }
      if (filter === 'profiles') {
        const filterByProfiles  = [];
        this.tools.forEach( tool => {
          const hasProfile = tool.profiles.find( (item, i) => {
            if (item.id === this.profileSelectd){
              return true;
            }
          });
          if( hasProfile ) {
            filterByProfiles.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByProfiles);
      }
      if (filter === 'solutions') {
        const filterBySolutions  = [];
        this.tools.forEach( tool => {
          const hasSolution =  tool.solutions.find( (item, i) => {
            if (item.id === this.solutionSelectd){
              return true;
            }
          });
          if( hasSolution ) {
            filterBySolutions.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterBySolutions);
      }

      if (filter === 'steps') {
        const filterBySteps  = [];
        this.tools.forEach( tool => {
          const hasStep = tool.steps.find( (item, i) => {
            if (item.id === this.stepSelectd){
              return true;
            }
          });
          if( hasStep ) {
            filterBySteps.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterBySteps);
      }
      if (filter === 'stops') {
        const filterByStops  = [];
        this.tools.forEach( tool => {
          const hasStop = tool.stops.find( (item, i) => {
            if (item.id === this.stopSelectd){
              return true;
            }
          });
          if( hasStop ) {
            filterByStops.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByStops);
      }
      if (filter === 'typologies') {
        const filterByTypologies  = [];
        this.tools.forEach( tool => {
          const hasTypo = tool.typologies.find( (item, i) => {
            if (item.id === this.typologySelectd){
              return true;
            }
          });
          if( hasTypo ) {
            filterByTypologies.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByTypologies);
      }
    });
    this.tmpTools = toolsTmp;
  }
  filter(type: string): void {
    if ( ( type === 'countries' && this.countrySelectd !== null ) ||
      ( type === 'profiles' && this.profileSelectd !== null ) ||
      ( type === 'solutions' && this.solutionSelectd !== null ) ||
      ( type === 'steps' && this.stepSelectd !== null ) ||
      ( type === 'stops' && this.stopSelectd !== null ) ||
      ( type === 'typologies' && this.typologySelectd !== null )){

      const indexFilterApplied = this.filterApplied.indexOf(type);
      if (indexFilterApplied < 0) {
        this.filterApplied.push(type);
      }
    } else {
      this.cleanFilter(type);
    }
    this.applyFilter();
  }
  removeElementsFromArray(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }
  cleanFilter(type: string) {
    const indexFilterApplied = this.filterApplied.indexOf(type);
    if (indexFilterApplied > -1) {
      this.filterApplied.splice(indexFilterApplied, 1);
    }
  }
  goToOneClick(building: string): void {
    //this.buildingSelectedEmitter.emit(this.address);
    this.router.navigate(['/oneclick'], {state: {building: this.addressEmitter.emit(building)}});

  }

  goToOneClick2(): void {
    this.router.navigate(['/oneclick'], {state: {country: this.countryOneClick}});
  }

  openModal(tool: ToolsNew) {
    tool.visitCount++;

    this.modalRef = this.modalService.show(ToolsModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { tool}});
    this.modalRef.content.closeBtnName = 'Close';
  }

  goToTools(){
    this.router.navigate(['/', 'tools'])
  }

  goToBestPractices(){
    this.router.navigate(['/', 'bestpractices'])
  }

  onClickSubmit(data:any){
    this.addressEmitter.emit({data});
    localStorage.setItem('address', data.address);
    this.router.navigate(['/oneclick'], { state: data})
    console.log(data)
   }

  saveTool(tool: ToolsNew): void{
    this.userService.addPropertyToolToHistory(tool, sessionStorage.getItem('auth-token')).subscribe( () => {
    this.isFavoriteTool = true;
    });
  }

  /*getCurrentPageObjects(): any[] {
    this.totalPages = Math.ceil(this.tmpNewTools.length / this.objectsPerPage);

    const startIndex = (this.currentPage - 1) * this.objectsPerPage;
    const endIndex = startIndex + this.objectsPerPage;
    return this.tmpNewTools.slice(startIndex, endIndex);
  }*/

  //Version en teoria para los ultimos 6 registros
  getCurrentPageObjects(): any[] {
    const clonedArray = this.tmpNewTools.slice();

    // Ordena el array por uid en orden descendente
    clonedArray.sort((a, b) => b.uid - a.uid);

    this.totalPages = Math.ceil(clonedArray.length / this.objectsPerPage);

    // Obtiene los primeros 6 registros después de la ordenación descendente
    const endIndex = Math.min(6, clonedArray.length);
    const startIndex = Math.max(0, endIndex - 6);

    return clonedArray.slice(startIndex, endIndex);
  }

  getRatingTools(){
    this.toolsService.getToolRateInfo().subscribe((data: any) => {
      this.rateObjects = data;
      console.log(this.rateObjects);
      console.log(this.tmpNewTools)

        for(let i = 0; i < this.tmpNewTools.length; i++){
          let totalRate = 0;
          let count = 0;
          for(let j = 0; j < this.rateObjects.length; j++){
            if (this.rateObjects[j].tool_id == this.tmpNewTools[i].uid) {
              totalRate += this.rateObjects[j].rate;
              count++;
            }
          }
          const averageRate = totalRate / count;
          this.averageRatings.push(averageRate)
          console.log(`Average rating for tool ${this.tmpNewTools[i].uid}: ${averageRate}`);
        }
    });

  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  getPageNumbers(): number[] {
    return Array(this.totalPages).fill(0).map((_, index) => index + 1);
  }

  goToPageMostVisited(page: number): void {
    if (page >= 1 && page <= this.totalPagesMostVisited) {
      this.currentPageMostVisited = page;
    }
  }

  getCurrentPageObjectsMostVisited(): any[] {
    const clonedArray = this.tmpNewTools.slice();

    this.totalPagesMostVisited = Math.ceil(clonedArray.length / this.objectsPerPageMostVisited);

    clonedArray.sort((a, b) => b.visitCount - a.visitCount);

    const startIndex = (this.currentPageMostVisited - 1) * this.objectsPerPageMostVisited;
    const endIndex = startIndex + this.objectsPerPageMostVisited;

    return clonedArray.slice(startIndex, endIndex);
  }

  getPageNumbersMostVisited(): number[] {
    return Array(this.totalPagesMostVisited).fill(0).map((_, index) => index + 1);
  }

  getCurrentPageObjectsPopularEurope(): any[] {
    const clonedArray = this.tmpNewTools.slice();

    this.totalPagesPopularEurope = Math.ceil(clonedArray.length / this.objectsPerPagePopularEurope);

    const startIndex = (this.currentPagePopularEurope - 1) * this.objectsPerPagePopularEurope;
    const endIndex = startIndex + this.objectsPerPagePopularEurope;
    return clonedArray.slice(startIndex, endIndex);
  }

  goToPagePopularEurope(page: number): void {
    if (page >= 1 && page <= this.totalPagesPopularEurope) {
      this.currentPagePopularEurope = page;
    }
  }

  getPageNumbersPopularEurope(): number[] {
    return Array(this.totalPagesPopularEurope).fill(0).map((_, index) => index + 1);
  }

  getCurrentPageObjectsPopularSpain(): any[] {
    this.totalPagesPopularSpain = Math.ceil(this.tmpNewToolsPopularSpain.length / this.objectsPerPagePopularSpain);

    const startIndex = (this.currentPagePopularSpain - 1) * this.objectsPerPagePopularSpain;
    const endIndex = startIndex + this.objectsPerPagePopularSpain;
    return this.tmpNewToolsPopularSpain.slice(startIndex, endIndex);
  }

  goToPagePopularSpain(page: number): void {
    if (page >= 1 && page <= this.totalPagesPopularSpain) {
      this.currentPagePopularSpain = page;
    }
  }

  getPageNumbersPopularSpain(): number[] {
    return Array(this.totalPagesPopularSpain).fill(0).map((_, index) => index + 1);
  }

  goToTheBotton(){
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }
}

<footer>
  <div class="flex-right" style="text-align: right">
    <div>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSdDJ8njLjVTdA4GlZhERAMbsYAz2QFXjSaK-amFrBbizBZr1A/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">
        Leave us your Feedback
      </a>
    </div>
    <div>
      <h5>re-MODULEES Version: 2.0</h5>
    </div>
  </div>
</footer>

export interface ArticleImage {
    id: number,
    name: string,
    article_id: number
}

export class ArticleImage extends Object {
    constructor(
      public id: number,
      public name: string,
      public article_id: number
    ) {
      super();
    }
  }
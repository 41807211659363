import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AdministrationPanelService } from './core/binding-data/administration-panel/administration-panel.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  template: `
        <router-outlet></router-outlet>
      `
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(private router: Router, private administrationPanelService: AdministrationPanelService) {}
  ngOnDestroy(): void {
    this.administrationPanelService.setGuid(null);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}

export interface ToolsDocuments {
    id: number,
    name: string,
    tool_id: number
}

export class ToolsDocuments extends Object {
    constructor(
      public id: number,
      public name: string,
      public tool_id: number
    ) {
      super();
    }
  }
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  arrayNotification: Notification[] = [];

  getArrayNotification: Subscription = null;

  constructor(private administrationPanelService: AdministrationPanelService) {}

  ngOnInit(): void {
    this.administrationPanelService.getArrayNotification$().subscribe((data: Notification[]) => {
      console.log('notification administration panel -> ', data)
      this.arrayNotification = data;
    })
  }

}

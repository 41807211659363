
<div class="modal-header tool-actions row justify-content-end">
  <div class="col-md-2">
    <button type="button" class="btn btn-default row" (click)="saveUser(user.uid)">
      <h5>
        {{"MODAL_MEMBERS.BUTTON_SAVE" | translate}} <i class="fa fa-bookmark"></i>
      </h5>
    </button>
  </div>
  <div class="col-md-2">
    <button type="button" class="btn btn-default row" (click)="bsModalRef.hide()">
      <h5>
        {{closeBtnName}} <i class="fa fa-times" aria-hidden="true"></i>
      </h5>
    </button>
  </div>
</div>
<div class="modal-body content-info">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <img class="logo-tool"  *ngIf="user.user_extension.profileImage == null || user.user_extension.profileImage == undefined || user.user_extension.profileImage == ''" src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" width="150" height="150" alt="Logo tool">
        <img class="logo-tool" *ngIf="user.user_extension.profileImage != null" src="{{imageUrl}}/{{user.user_extension.profileImage.substring(10)}}" width="150" height="150" alt="Logo tool" style="background-color: white;">
      </div>
      <div class="col-md-5">
        <h4>
          {{user.name}}
        </h4>
        <ngb-rating class="estrellas" [max]="5" [readonly]="false"></ngb-rating><br>
        <div>
          {{"MODAL_MEMBERS.CERTIFIED_SOLUTIONS" | translate}}: {{user.certifications.length}}
        </div>

        <div class="website-tool">
          <a class="web-tool" href="https://www.five.es/" target="_blank" *ngIf="user.name === 'IVE'">{{"MODAL_MEMBERS.WEBSITE" | translate}}</a>
          <a class="web-tool" href="" target="_blank" *ngIf="user.name !== 'IVE'">{{"MODAL_MEMBERS.WEBSITE" | translate}}</a>
        </div>
      </div>
    </div>
    <div class="content-description">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">{{"MODAL_MEMBERS.DESCRIPTION_SECTION" | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="my-work-tab" data-toggle="tab" href="#my-work" role="tab" aria-controls="my-work" aria-selected="false">{{"MODAL_MEMBERS.MY_WORK_SECTION" | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="my-network-tab" data-toggle="tab" href="#my-network" role="tab" aria-controls="my-network" aria-selected="false">{{"MODAL_MEMBERS.MY_NETWORK_SECTION" | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="my-expertise-tab" data-toggle="tab" href="#my-expertise" role="tab" aria-controls="my-expertise" aria-selected="false">{{"MODAL_MEMBERS.MY_EXPERTISE_SECTION" | translate}}</a>
        </li>
      </ul>
    </div>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
        <div style="width: 65%; float: left;" *ngIf="user.name !== 'IVE'">
          Lorem ipsum
        </div>
        <div style="width: 65%; float: left; text-align: justify;" *ngIf="user.name === 'IVE'">
          The Valencian Building Institute (IVE) is a public interest foundation, established in 1986, promoted by the Second Vice Presidency and the Department of Housing and Bioclimatic Architecture.
        </div>
        <div class="fondo-gris" style="width: 35%; float: right;">
          <h6 class="title-information">
            {{"MODAL_MEMBERS.GENERAL_INFORMATION_SECTION" | translate}}
          </h6>
          <div class="container">
            <div class="owner-title">
              {{"MODAL_MEMBERS.OWNER" | translate}}
              <div class="owner-tool">
                <img src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" class="rounded-circle" style="width: 50px;" alt="Avatar" />  {{user.name}}
              </div>
            </div>
            <div class="contact">
              {{"MODAL_MEMBERS.CONTACT_MEMBER" | translate}}
              <div class="contact-tool">
                {{user.email}}
              </div>
            </div>
            <div class="website" *ngIf="user.user_extension.phoneNumber != null">
              WhatsApp
              <div>
                {{user.user_extension.phoneNumber}}
              </div>
            </div>
            <div class="website" *ngIf="user.user_extension.dossier != null">
              Dossier
              <div>
                <td>
                  <i class="fa fa-file-pdf-o" style="font-size:45px; color:red"></i>
                  &nbsp;
                  <a href="{{imageUrl}}{{user.user_extension.dossier.slice(9)}}" target="_blank">{{user.user_extension.dossier.slice(55)}}</a>
                </td>
                <!--{{user.user_extension.dossier}}-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="my-work" role="tabpanel" aria-labelledby="my-work-tab">
        <div class="row">
          <div class="col-md-5 card bg-light card-stop" *ngFor="let work of myWorkFilters; let i = index" id="cardFilter{{i}}">
            <div class="card-body">
              <p id="textInfo{{i}}" class="card-text card-text-title">{{work.name}}</p>
            </div>
            <p class="card-text card-text-percent"></p>
            <button type="button" class="btn stretched-link btn-card-filter shadow-none"></button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="my-network" role="tabpanel" aria-labelledby="my-network-tab">
        <div class="row">
          <div class="col-md-5 card bg-light card-stop" *ngFor="let net of myNetworkFilters; let i = index" id="cardFilter{{i}}">
            <div class="card-body">
              <p id="textInfo{{i}}" class="card-text card-text-title">{{net.name}}</p>
            </div>
            <p class="card-text card-text-percent" *ngIf="net.name === 'Owning'">{{countOwning}}</p>
            <p class="card-text card-text-percent" *ngIf="net.name === 'Design'">{{countDesign}}</p>
            <p class="card-text card-text-percent" *ngIf="net.name === 'Financing'">{{countFinancing}}</p>
            <p class="card-text card-text-percent" *ngIf="net.name === 'Public Sector'">{{countPublicSector}}</p>
            <p class="card-text card-text-percent" *ngIf="net.name === 'Constructing'">{{countConstructing}}</p>
            <button type="button" class="btn stretched-link btn-card-filter shadow-none"></button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="my-expertise" role="tabpanel" aria-labelledby="my-expertise-tab">
        <div class="row">
          <div class="col-md-5 card bg-light card-stop" *ngFor="let stop of stopsList; let i = index" id="cardFilter{{i}}">
            <div class="card-body">
              <p id="textInfo{{i}}" class="card-text card-text-title">
                {{stop.stop}}
                <i *ngIf="stop.stop === 'Onboarding'" class="fa fa-handshake-o fa-lg" aria-hidden="true"></i>
                <i *ngIf="stop.stop === 'Evaluation'" class="fa fa-file-text fa-lg" aria-hidden="true"></i>
                <i *ngIf="stop.stop === 'Design and Formalization'" class="fa fa-pencil-square fa-lg" aria-hidden="true"></i>
                <i *ngIf="stop.stop === 'Realization'" class="fa fa-cog fa-lg" aria-hidden="true"></i>
                <i *ngIf="stop.stop === 'Validation'" class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
              </p>
            </div>
            <p class="card-text card-text-percent" *ngIf="stop.stop === 'Onboarding'">{{countOnboarding}}</p>
            <p class="card-text card-text-percent" *ngIf="stop.stop === 'Evaluation'">{{countEvaluation}}</p>
            <p class="card-text card-text-percent" *ngIf="stop.stop === 'Design and Formalization'">{{countDesignFormalization}}</p>
            <p class="card-text card-text-percent" *ngIf="stop.stop === 'Realization'">{{countRealization}}</p>
            <p class="card-text card-text-percent" *ngIf="stop.stop === 'Validation'">{{countValidation}}</p>
            <button type="button" class="btn stretched-link btn-card-filter shadow-none"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button *ngIf="currentUrl == '/administrationPanel'" class="btn btn-danger" (click)="rejectUserToPro()">Reject</button>
  <button *ngIf="currentUrl == '/administrationPanel'" class="btn btn-primary" (click)="validateUserToPro()">Validate</button>
</div>

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, JsonpClientBackend} from '@angular/common/http';
import {Observable} from 'rxjs';
import { GlobalConstants } from '../../shared/GlobalConstants';
import { Article } from 'src/app/shared/models/article';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  private urlArticles = GlobalConstants.backendURL + '/api/articles';
  private urlArticlesImages = GlobalConstants.backendURL + '/api/articles_images';
  private urlArticlesDocuments = GlobalConstants.backendURL + '/api/articles_documents';
  private urlArticleRate = GlobalConstants.backendURL + '/api/article_rate';
  private urlArticlesProposed = GlobalConstants.backendURL + '/api/articles/status/2';
  private urlValidateArticles = GlobalConstants.backendURL + '/api/articles/status/1';

  private urlUpdateProposedArticles = GlobalConstants.backendURL + '/api/articles/proposeArticle/';

  private urlSavedArticles = GlobalConstants.backendURL + '/api/user_article';

  private urlCategoriesArticles = GlobalConstants.backendURL + '/api/category_article';

  private eventSource: EventSource;

  constructor(private http: HttpClient) { }

  getArticles(){
    return this.http.get(`${this.urlArticles}`);
  }

  getCategoriesArticles(){
    return this.http.get(`${this.urlCategoriesArticles}`);
  }

  getArticleById(id: number){
    return this.http.get(this.urlArticles + '/' + id);
  }

  getArticleByUserId(user_id: string){
    return this.http.get(this.urlArticles + '/userId/' + user_id);
  }

  getProposedArticles(){
    return this.http.get(`${this.urlArticlesProposed}`);
  }

  getValidateArticles(){
    return this.http.get(`${this.urlValidateArticles}`);
  }

  getArticlesUpdates(): Observable<any> {
    return new Observable(observer => {
      this.eventSource = new EventSource(`${this.urlArticles}/updates`);

      this.eventSource.addEventListener('message', event => {
        const data = JSON.parse(event.data);
        observer.next(data);
      });

      this.eventSource.onerror = error => {
        observer.error(error);
      };

      return () => {
        this.eventSource.close();
      };
    });
  }

  getArticlesImages(){
    return this.http.get(`${this.urlArticlesImages}`)
  }

  getArticlesDocuments(){
    return this.http.get(`${this.urlArticlesDocuments}`)
  }

  createArticle(name:string, country: number, category: number, date: string, author: string,
    shortDescription: string, longDescription: string, mainImage: any, status: number, guid: string, video: string, link: string
  ){

    let body = {
      article_name: name,
      country_id: country,
      category_id: category,
      publication_date: date,
      author: author,
      short_description: shortDescription,
      long_description: longDescription,
      main_image: mainImage.name,
      articles_status_id: status,
      user_id: guid,
      video: video,
      link: link
    }

    let formData = new FormData();

    formData.append('article', JSON.stringify(body));
    formData.append('image', mainImage);

    console.log(body)

    return this.http.post(`${this.urlArticles}`, formData);
  }

  updateArticle(body: any){
    return this.http.put(`${this.urlArticles}`, body);
  }

  uploadImage(image: any){
    return this.http.post(`${this.urlArticlesImages}`, image);
  }

  updateUploadImage(image: any){
    return this.http.put(`${this.urlArticlesImages}`, image);
  }

  uploadDocuments(file: any) {
    return this.http.post(`${this.urlArticlesDocuments}`, file);
  }

  updateUploadDocuments(file: any) {
    return this.http.put(`${this.urlArticlesDocuments}`, file);
  }

  getToolsImages(){
    return this.http.get(`${this.urlArticlesImages}`);
  }

  getToolsDocuments(){
    return this.http.get(`${this.urlArticlesDocuments}`);
  }

  loadVisitCount(articleId: string): number {
    const storedCount = localStorage.getItem(`visitCount-${articleId}`);
    return storedCount ? parseInt(storedCount, 10) : 0;
  }

  createArticleRate(data): Observable<any>{
    return this.http.post(`${this.urlArticleRate}`, data);
  }

  getArticleRateInfo(){
    return this.http.get(`${this.urlArticleRate}`);
  }

  updateArticlePropose(article: Article){
    return this.http.put(this.urlUpdateProposedArticles, article);
  }

  getCategoryArticle(){
    return this.http.get(GlobalConstants.backendURL + '/api/category_article');
  }

  getArticleStatus(){
    return this.http.get(GlobalConstants.backendURL + '/api/articleStatus');
  }

  deleteArticle(article: Article){
    return this.http.post(this.urlArticles + '/delete', article);
  }

  deleteAdditionalImagesArticle(idArticle: number){
    return this.http.delete(this.urlArticlesImages + '/' + idArticle);
  }

  deleteFilesArticle(idArticle: number){
    return this.http.delete(this.urlArticlesDocuments + '/' + idArticle)
  }

  updateToValidateArticle(id: number){
    return this.http.put(this.urlArticles + '/updateToValidateArticle', {id: id});
  }

  updateToRejectArticle(id: number, adminComment: string){
    return this.http.put(this.urlArticles + '/updateToRejectArticle', {id: id, adminComment: adminComment});
  }

  savedArticles(user_id: any, article_id: any){
    let body = {
      user_id: user_id,
      article_id: article_id,
    }

    return this.http.post(this.urlSavedArticles, body);
  }

  getSavedArticles(idUser: any){
    return this.http.get(this.urlSavedArticles + '/userId/' + idUser);
  }

}

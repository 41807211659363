<div *ngIf="!hasError">
  <div>
      <div *ngIf="ruralBuilding" style="text-align: center;" >
        <h4>Rural property. Please try with another location</h4>
      </div>
    <div *ngIf="building && !ruralBuilding && selectBuilding" class="buildingInfo">
      <!--div [hidden]="!building || ( building && !building.country) || ( building && !building.year)" fxLayoutAlign="end">
        <button [disabled]="!mapControl" class="btn calculateTyp" (click)="showMap();"  routerLink='/home' routerLinkActive='active' >
          <span><i class="fa fa-map-o" aria-hidden="true"></i> Show Map</span>
        </button>
        <a class="btn calculateTyp" role="button"  routerLink='/typology' routerLinkActive='active' (click)="calculateTypology();">
          <span>Calculate energy efficiency</span>
        </a>
      </div-->
      <div class="container">
        <div class="card card-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <div class="location">
                  {{'ONE_CLICK_PAGE.CADASTRE_DATA.LOCATION' | translate}}
                </div>
                <div [hidden]="properties.length < 1">
                  <img class="img-thumbnail" alt="Facade image" [src]='properties[0].image' *ngIf=" properties.length > 0 && properties[0].image" />
                </div>
              </div>
              <div class="col text-ident">
                <h5 [hidden]="!building.region"> {{'ONE_CLICK_PAGE.CADASTRE_DATA.PROVINCE' | translate}}: {{ building.region }} </h5>
                <p *ngIf="building.country !== 'ES'"> <i class="fa fa-map-marker" aria-hidden="true"></i> <strong>&nbsp;{{building.address}}</strong></p>

                <div *ngIf="isCompleteBuilding == true && building.country  == 'ES'">
                  <p> <i class="fa fa-map-marker" aria-hidden="true"></i> <strong>&nbsp;{{addressCompleteBuilding}}</strong></p>
                </div>

                <div *ngIf="isCompleteBuilding == false && building.country  == 'ES'">

                  <div class="input-group mb-3">
                    <select class="custom-select" id="selectAddress" *ngIf="isResidential == true && arrayAddress.length > 0" (change)="selectAddressEventChange($event)">
                      <option *ngFor="let address of arrayAddress; index as i" value="{{arrayRefReference[i]}}">{{address}}</option>
                    </select>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="container cadastreInfo">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.surface">
                <span class="text-bold"><img src="./assets/img/icons/square_foot_black_24dp.svg" alt="Surface" style="width: 16px!important;">&nbsp;{{building.surface}}&nbsp; m<sup>2</sup> </span>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.year">
                <span class="text-bold"><i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{building.year}}</span>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.rc">
                <p> {{'ONE_CLICK_PAGE.CADASTRE_DATA.CADASTRE_REFERENCE' | translate}}:</p>
                <p><strong> {{building.rc}}</strong></p>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.use">
                <p>{{'ONE_CLICK_PAGE.CADASTRE_DATA.PRIMARY_USE' | translate}}:</p>
                <p><strong> {{building.use}}</strong></p>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div *ngIf="calificationEnergyPrimary" [ngSwitch]="calificationEnergyPrimary" class="p-2 flex-fill bd-highlight">

                <div *ngSwitchCase="'A'">
                  <div >
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_ENERGY_PRIMARY' | translate}}:</p>
                    <div id="calificationEnergyPrimaryCEE"><strong class="textCalification colourLetterCertificateA">{{calificationEnergyPrimary}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'B'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_ENERGY_PRIMARY' | translate}}:</p>
                    <div id="calificationEnergyPrimaryCEE"><strong class="textCalification colourLetterCertificateB">{{calificationEnergyPrimary}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'C'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_ENERGY_PRIMARY' | translate}}:</p>
                    <div id="calificationEnergyPrimaryCEE"><strong class="textCalification colourLetterCertificateC">{{calificationEnergyPrimary}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'D'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_ENERGY_PRIMARY' | translate}}:</p>
                    <div id="calificationEnergyPrimaryCEE"><strong class="textCalification colourLetterCertificateD">{{calificationEnergyPrimary}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'E'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_ENERGY_PRIMARY' | translate}}:</p>
                    <div id="calificationEnergyPrimaryCEE"><strong class="textCalification colourLetterCertificateE">{{calificationEnergyPrimary}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'F'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_ENERGY_PRIMARY' | translate}}:</p>
                    <div id="calificationEnergyPrimaryCEE"><strong class="textCalification colourLetterCertificateF">{{calificationEnergyPrimary}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'G'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_ENERGY_PRIMARY' | translate}}:</p>
                    <div id="calificationEnergyPrimaryCEE"><strong class="textCalification colourLetterCertificateG">{{calificationEnergyPrimary}}</strong></div>
                  </div>
                </div>

              </div>

              <div *ngIf="calificationCEE" [ngSwitch]="calificationCEE" class="p-2 flex-fill bd-highlight">

                <div *ngSwitchCase="'A'">
                  <div >
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_CO2_EMISSIONS' | translate}}:</p>
                    <div id="calificationCEE"><strong class="textCalification colourLetterCertificateA">{{calificationCEE}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'B'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_CO2_EMISSIONS' | translate}}:</p>
                    <div id="calificationCEE"><strong class="textCalification colourLetterCertificateB">{{calificationCEE}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'C'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_CO2_EMISSIONS' | translate}}:</p>
                    <div id="calificationCEE"><strong class="textCalification colourLetterCertificateC">{{calificationCEE}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'D'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_CO2_EMISSIONS' | translate}}:</p>
                    <div id="calificationCEE"><strong class="textCalification colourLetterCertificateD">{{calificationCEE}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'E'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_CO2_EMISSIONS' | translate}}:</p>
                    <div id="calificationCEE"><strong class="textCalification colourLetterCertificateE">{{calificationCEE}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'F'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_CO2_EMISSIONS' | translate}}:</p>
                    <div id="calificationCEE"><strong class="textCalification colourLetterCertificateF">{{calificationCEE}}</strong></div>
                  </div>
                </div>

                <div *ngSwitchCase="'G'">
                  <div>
                    <p>{{'BEST_PRACTICES.CEE_INFO.CALIFICATION_CO2_EMISSIONS' | translate}}:</p>
                    <div id="calificationCEE"><strong class="textCalification colourLetterCertificateG">{{calificationCEE}}</strong></div>
                  </div>
                </div>

              </div>

            </div>
            <!--<div class="col-md-6" [hidden]="!selectYear">
              <label for="selectYear">Select year</label>
              <ng-select id="selectYear" [(ngModel)]="selectedYear" [items]="years" (change)="selectYearOption();" [bindValue]="selectedYear"></ng-select>
            </div>-->
          </div>
          <div *ngIf="isUserLogged == true; else notUserLogged">
            <div class="container calculating" *ngIf="isResidential == true">
              <h5 class="title-calc">{{'BEST_PRACTICES.CEE_INFO.TITLE_PARAGRAPH' | translate}}</h5>
              <p class="paragraph-calc">{{'BEST_PRACTICES.CEE_INFO.PARAGRAPH_CALCULATING' | translate}}</p>
            </div>
            <ng-template #notResidential>
              <div class="container calculating">
                <h5 class="title-calc">It is not residential</h5>
                <p class="paragraph-calc">This building is not residential, so, we cannot let you complete the form. Select a residence to complete it.</p>
              </div>
            </ng-template>
            <div class="d-flex flex-row bd-highlight" *ngIf="(this.building.country  === 'ES' && isResidential == true) || this.building.country  !== 'ES'; else notResidential">
              <button class="calculateEnergy btn btn-large" role="button" (click)="goToFormCEE();" [disabled]="!selectBuilding">
                <i class="fa fa-wpforms fa-4x" aria-hidden="true" style="width: 70px; height: 40px;"></i>&nbsp;&nbsp; <span  class="align-middle"><strong> {{'BEST_PRACTICES.CEE_INFO.BUTTON_FORM' | translate}}  &#62;</strong></span>
              </button>
            </div>
          </div>

          <ng-template #notUserLogged>
            <div class="container calculating">
              <h5 class="title-calc">You are not logged</h5>
              <p class="paragraph-calc">If you want to create a form building, you must to log in.</p>
            </div>
          </ng-template>

          </div>
        </div>

      <!--div [hidden]="properties.length < 1" class="seeMore">
        <div  class="cursor-pointer flex justify-center items-center h-16" role="button" data-toggle="collapse" data-target="#propertiesInfo">
          <div class="arrowFromLeft">
            <svg xmlns="http://www.w3.org/2000/svg" width="5.002" height="8.576" viewBox="0 0 5.002 8.576">
              <path d="M1.06,8.428a.5.5,0,0,1-.71,0l-.2-.2A.49.49,0,0,1,0,7.868V.708A.49.49,0,0,1,.15.348l.2-.2a.5.5,0,0,1,.71,0l3.79,3.79a.48.48,0,0,1,0,.7Z"></path>
            </svg><span>See Properties</span>
          </div>
        </div>
      </div-->
      <!--div class="collapse" id="propertiesInfo">
        <div style="text-align: right">
          <a class="btn filterButton" data-bs-toggle="collapse" href="#" data-toggle="collapse" data-target="#filters">
        <span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </span>Filters</a>
        </div>
        <div class="collapse card filtersBuilding" id="filters">
          <div class="card-header"> <h4>Filters </h4> </div>
          <div  fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center" class="card-body">
            <div fxLayout="row" fxLayoutAlign="space-evenly center" >
              <label for="bl">Block</label>
              <input id="bl" type="text" class="form-control"  [(ngModel)]="modelFilters.filtBl" (keyup)="filterBuilding()" >
              <label for="es">Stairs</label>
              <input id ="es" type="text" class="form-control"  [(ngModel)]="modelFilters.filtEs" (keyup)="filterBuilding()" >
            </div>
            <div fxLayout="row" fxLayoutAlign="space-evenly center" >
              <label for="pt">Plant</label>
              <input id ="pt" type="text" class="form-control" [(ngModel)]="modelFilters.filtPt" (keyup)="filterBuilding()" >
              <label for="pu">Door</label>
              <input id ="pu" type="text" class="form-control" [(ngModel)]="modelFilters.filtPu" (keyup)="filterBuilding()" >
            </div>
            <button (click)="clearFilters();">Clear filters</button>
          </div>
        </div>
        <ul class="listBuild">
          <li  *ngFor="let mk of propertiesFilter">
            <h5> {{mk.locInt}} </h5>
            <p style="padding-left: 0">Cadastre reference: {{mk.rc}}</p>
            <div style="text-align: right">
              <button (click)="this.searchFromHistory = false; getDetailFromRC(mk.rc);" > More information > </button>
            </div>
          </li>
        </ul>
      </div-->
  </div>
  </div>
</div>
<ngx-spinner [fullScreen]="false" type="ball-spin-clockwise" [color]="spinnerColor" size="medium">
</ngx-spinner>
<div *ngIf="hasError">
  <div class="container">
    <h6> {{error}}</h6>
  </div>
</div>

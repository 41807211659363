import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private baseUrlLanguage = GlobalConstants.backendURL + '/api/language';
  private baseUrlCountry = GlobalConstants.backendURL + '/api/country';

  constructor(private http: HttpClient) { }

  getLanguages(){
    return this.http.get(this.baseUrlLanguage);
  }

  getCountries(){
    return this.http.get(this.baseUrlCountry);
  }
}

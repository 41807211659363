
<div class="d-flex justify-content-center navButtons">
  <button type="button" class="btn btn-link buttonsAdminTools" (click)="toUserTable()"><div class="h3">User table</div></button>
  <button type="button" class="btn btn-link buttonsAdminTools" (click)="toProUserRequest()"><div class="h3">Pro user request</div></button>
</div>

<div *ngIf="window == 1">
  <form>

    <div class="form-group row">
      <div class="col-2">
        <input type="text" class="form-control" name="searchFilter" [(ngModel)]="valueFilterName" placeholder="Name filter">
      </div>

      <div class="col-2">
        <input type="text" class="form-control" name="emailFilter" [(ngModel)]="valueFilterEmail" placeholder="Email filter">
      </div>

      <div class="col-2">
        <ng-select name="roleFilter" id="roleFilter" [(ngModel)]="valueFilterIdRole" placeholder="Role filter">
          <ng-option *ngFor="let role of arrayRole" [value]="role.id">{{role.name}}</ng-option>
        </ng-select>
      </div>

      <div class="col-2">
        <ng-select name="countryFilter" id="countryFilter" [(ngModel)]="valueFilterCountry" placeholder="Country filter" [disabled]="currentUser.user_roles[0].role_id == 2">
          <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
        </ng-select>
      </div>

      <div class="col-3">
        <label class="word-margin">Status filter: &nbsp;</label>

        <div class="form-check-inline">
          <input class="form-check-input" type="radio" name="flexRadioStatusTrue" id="flexRadioStatusTrue" [value]="true" [(ngModel)]="valueFilterStatus">
          <label class="form-check-label" for="flexRadioStatusTrue">Active</label>
        </div>
        <div class="form-check-inline">
          <input class="form-check-input" type="radio" name="flexRadioStatusFalse" id="flexRadioStatusFalse" [value]="false" [(ngModel)]="valueFilterStatus">
          <label class="form-check-label" for="flexRadioStatusFalse">Unactive</label>
        </div>

      </div>

    </div>



    <div class="form-group row">

      <label id="labelDate">Last login filter: &nbsp;</label>

      <div class="col-5" >
        <!--Datepicker-->
        <div class="dp-hidden position-absolute">
          <div class="input-group">
            <input
              id="datepicker1"
              name="datepicker"
              class="form-control"
              ngbDatepicker
              #datepicker="ngbDatepicker"
              [autoClose]="'outside'"
              [displayMonths]="2"
              [dayTemplate]="t"
              (dateSelect)="onDateSelection($event)"
              outsideDays="hidden"
              [startDate]="fromDate!"
              tabindex="-1"
            />
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="input-group">
          <input
            #dpFromDate
            class="form-control calendar col-3"
            placeholder="First date"
            name="dpFromDate"
            [value]="formatter.format(fromDate)"
            (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            readonly
          />
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
          <div class="col-1"></div>
          <input
            #dpToDate
            class="form-control calendar col-3"
            placeholder="Second date"
            name="dpToDate"
            [value]="formatter.format(toDate)"
            (input)="toDate = validateInput(toDate, dpToDate.value)"
            readonly
          />
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
        </div>
        <!--End Datepicker-->
      </div>
      <div class="col"></div>

      <div class="col-1">
        <button class="btn btn-warning" (click)="applyFilter()">Apply</button>
      </div>
      <div class="col-1">
        <button class="btn btn-danger" (click)="deleteAllFilters()">Delete</button>
      </div>
      <div class="col-1">
        <button class="btn btn-info" (click)="refreshUsers()">Refresh</button>
      </div>
    </div>

    <div class="form-group row">

      <table class="table table-hover">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Country</th>
            <th scope="col">Create Date</th>
            <th scope="col">Last Login Date</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of tmpArrayUser | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i ">
            <td>{{user.name}}</td>
            <td>{{user.email}}</td>
            <td>{{user.user_roles[0].role.name}}</td>
            <td>{{user.country.country_code}}</td>
            <td>{{user.createdAt | date: 'dd/MM/yyyy'}}</td>
            <td>{{user.user_extension.last_login | date: 'dd/MM/yyyy'}}</td>
            <td *ngIf="user.user_extension.active == true; else noActive">Active</td>
            <ng-template #noActive> <td>Unactive</td> </ng-template>
            <td>
              <button class="btn btn-light" (click)="openUpdateModal(content, user.uid)">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </button>
              <button class="btn btn-light" (click)="deleteUser(user.uid)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>

  <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="tmpArrayUser.length"></ngb-pagination>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
      <button type="button" aria-label="Close" (click)="modal.dismiss('Cross click')">&#x2715;</button>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
          <label for="name">Name</label>
          <div class="input-group">
            <input type="text" id="name" name="name" class="form-control" placeholder="Name" [(ngModel)]="valueName" [readOnly]="true"/>
          </div>
          <br>
          <label for="role">Role</label>
          <div class="input-group">
            <select class="custom-select" name="selectRole" id="selectRole" [(ngModel)]="valueRole">
              <option *ngFor="let role of arrayRole" [value]="role.id" selected>{{role.name}}</option>
            </select>
          </div>
          <br>
          <label for="role">Status</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="radioStatus" id="radioTrue" [value]="true" [(ngModel)]="valueActive">
            <label class="form-check-label" for="radioTrue">Active</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="radioStatus" id="radioFalse" [value]="false" [(ngModel)]="valueActive">
            <label class="form-check-label" for="radioFalse">Unactive</label>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div>
  </ng-template>

  <!-- Copy of datepicker-->
  <!--
    <form class="row row-cols-sm-auto">
    <div class="col-12">
      <div class="dp-hidden position-absolute">
        <div class="input-group">
          <input
            name="datepicker"
            class="form-control"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            [displayMonths]="2"
            [dayTemplate]="t"
            (dateSelect)="onDateSelection($event)"
            outsideDays="hidden"
            [startDate]="fromDate!"
            tabindex="-1"
          />
          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
      <div class="input-group">
        <input
          #dpFromDate
          class="form-control calendar col-2"
          placeholder="yyyy-mm-dd"
          name="dpFromDate"
          [value]="formatter.format(fromDate)"
          (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
          readonly
        />
        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
      </div>
    </div>
    <div class="col-12">
      <div class="input-group">
        <input
          #dpToDate
          class="form-control calendar col-2"
          placeholder="yyyy-mm-dd"
          name="dpToDate"
          [value]="formatter.format(toDate)"
          (input)="toDate = validateInput(toDate, dpToDate.value)"
          readonly
        />
        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button"></button>
      </div>
    </div>
  </form>
  -->

</div>

<div *ngIf="window == 2">
  <div class="card card-tool col-md-2 justify-content-center" *ngFor="let user of tmpArrayUserRequest">
    <img *ngIf="user.name !== 'IVE'" src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" height="auto" width="100" class="card-img-top rounded-circle">
    <img *ngIf="user.name === 'IVE'" src="../../../assets/img/icons/logo_IVE-2.svg">
    <div class="card-body">
      <a href="#/administrationPanel" (click)="openModal(user)"><h4>{{user.name}}</h4></a>
      <p>Mauris imperdiet urna et nibh fringilla ornare. Nam non pharetra nulla. Mauris accusan sodales finibus.</p>
    </div>
  </div>
</div>

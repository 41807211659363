import { Injectable } from '@angular/core';
import {GlobalConstants} from '../../shared/GlobalConstants';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToolsNew } from 'src/app/shared/models/toolsnew';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  private baseUrl = GlobalConstants.backendURL + '/api/tools';
  private baseHistoryUrl = GlobalConstants.backendURL + '/api/history/tools';
  private baseProfilesUrl = GlobalConstants.backendURL + '/api/profile';
  private baseConstraintsTypeUrl = GlobalConstants.backendURL + '/api/constraintType';
  private baseConstraintsCategoryUrl = GlobalConstants.backendURL + '/api/constraintCategory';

  private baseUrlStop = GlobalConstants.backendURL + '/api/stop';
  private baseUrlStep = GlobalConstants.backendURL + '/api/step';

  private baseToolStatusUrl = GlobalConstants.backendURL + '/api/toolStatus';

  private baseSolutionsUrl = GlobalConstants.backendURL + '/api/solution';
  private baseTypologiesUrl = GlobalConstants.backendURL + '/api/typology';

  private baseNewToolsUrl = GlobalConstants.backendURL + '/api/tools';
  private urlDocuments = GlobalConstants.backendURL + '/api/tools_documents';
  private urlImage = GlobalConstants.backendURL + '/api/tools_images';

  private urlConstraints = GlobalConstants.backendURL + '/api/constraints_info';
  private urlMoreDescriptions = GlobalConstants.backendURL + '/api/language_descriptions';

  private urlToolRate = GlobalConstants.backendURL + '/api/tool_rate';

  constructor(private http: HttpClient) { }

  getTools() {
    return this.http.get(`${this.baseUrl}`);
  }

  getProfiles() {
    return this.http.get(`${this.baseProfilesUrl}`);
  }

  getContraintsType() {
    return this.http.get(`${this.baseConstraintsTypeUrl}`);
  }

  getContraintsCategory() {
    return this.http.get(`${this.baseConstraintsCategoryUrl}`);
  }

  getSolutions() {
    return this.http.get(`${this.baseSolutionsUrl}`);
  }

  getTypologies() {
    return this.http.get(`${this.baseTypologiesUrl}`);
  }

  getStops() {
    return this.http.get(`${this.baseUrlStop}`);
  }

  getSteps() {
    return this.http.get(`${this.baseUrlStep}`);
  }

  getToolStatus(){
    return this.http.get(this.baseToolStatusUrl);
  }

  getTools2(){
    return this.http.get(`${this.baseUrl}`);
  }

  getUserTools(token: string) {
    return this.http.get(`${this.baseHistoryUrl}`, { headers : { 'x-access-token' : token}});
  }

  getFilters() {
    return this.http.get(`${this.baseUrl}/filters`);
  }

  create(data): Observable<any> {
    return this.http.post(`${this.baseUrl}`, data);
  }

  getNewTools() {
    return this.http.get(`${this.baseNewToolsUrl}`);
  }

  getValidateTools(){
    return this.http.get(`${this.baseNewToolsUrl}` + '/state/1');
  }

  getToolStop(){
    return this.http.get(`${this.baseNewToolsUrl}` + '/state/1/stop');
  }

  getListedTools(
    limit: number,
    offset: number,
    app_name: string,
    stop_id: number,
    step_id: number,
    country_id: number,
    solution_id: number,
    typology_id: number,
    profile_id: number,
    category_id: number,
    class_id: string,
    arrayOtherCountries: number[]
  ){

    let filters = {
      app_name: app_name,
      stop_id: stop_id,
      step_id: step_id,
      country_id: country_id,
      solution_id: solution_id,
      typology_id: typology_id,
      profile_id: profile_id,
      constraint_type_id: category_id,
      constraint_category: class_id,
      otherCountries: arrayOtherCountries
    }

    return this.http.post(`${this.baseNewToolsUrl}` + '/state/1/limit/' + limit + '/offset/' + offset, filters);
  }

  getRecentTools(limit: number, offset: number, country: number){
    return this.http.get(`${this.baseNewToolsUrl}` + '/state/3/limit/' + limit + '/offset/' + offset + '/country/' + country);
  }

  getMostVisited(limit: number, offset: number, country: number){
    return this.http.get(`${this.baseNewToolsUrl}` + '/state/4/limit/' + limit + '/offset/' + offset + '/country/' + country);
  }

  getMostRated(limit: number, offset: number){
    return this.http.get(`${this.baseNewToolsUrl}` + '/state/5/limit/' + limit + '/offset/' + offset);
  }

  calculateRate(rate: number, tool_id: number, user_id: string){
    return this.http.put(`${this.baseNewToolsUrl}` + '/rate', {rate: rate, tool_id: tool_id, user_id: user_id});
  }

  getProposeTools(){
    return this.http.get(`${this.baseNewToolsUrl}` + '/state/2');
  }

  createNewTools(data): Observable<any> {
    return this.http.post(`${this.baseNewToolsUrl}`, data);
  }

  createNewToolPropose(
    app_name: string,
    app_name_origin: string,
    url: string,
    short_description: string,
    long_description: string,
    short_description_origin: string,
    long_description_origin: string,
    country_id: number,
    user_id: string
  ){
    let body = {
      app_name: app_name,
      app_name_origin: app_name_origin,
      url: url,
      short_description: short_description,
      long_description: long_description,
      short_description_origin: short_description_origin,
      long_description_origin: long_description_origin,
      country_id: country_id,
      user_id: user_id
    }

    return this.http.post(this.baseNewToolsUrl + '/propose', body);
  }

  uploadDocuments(arrayDocuments: any) {
    return this.http.post(`${this.urlDocuments}`, arrayDocuments);
  }

  updateUploadDocuments(arrayDocuments: any) {
    return this.http.put(`${this.urlDocuments}`, arrayDocuments);
  }

  uploadImage(image: any){
    return this.http.post(`${this.urlImage}`, image);
  }

  updateUploadImage(image: any){
    return this.http.put(`${this.urlImage}`, image);
  }

  deleteImage(idTool: number){
    return this.http.delete(this.urlImage + '/' + idTool);
  }

  deletePDF(idTool: number){
    return this.http.delete(this.urlDocuments + '/' + idTool);
  }

  uploadConstraints(arrayConstraints: any){
    return this.http.post(`${this.urlConstraints}`, arrayConstraints);
  }

  deleteConstraints(toolId: number){
    return this.http.delete(this.urlConstraints + '/' + toolId);
  }

  uploadMoreDescriptions(arrayMoreDescriptions: any){
    return this.http.post(`${this.urlMoreDescriptions}`, arrayMoreDescriptions);
  }

  deleteMoreDescriptions(toolId: number){
    return this.http.delete(this.urlMoreDescriptions + '/' + toolId);
  }

  getToolsImages(){
    return this.http.get(`${this.urlImage}`);
  }

  getToolsDocuments(){
    return this.http.get(`${this.urlDocuments}`);
  }

  getConstraintsInfo(){
    return this.http.get(`${this.urlConstraints}`);
  }

  createToolRate(data): Observable<any>{
    return this.http.post(`${this.urlToolRate}`, data);
  }

  getToolRateInfo(){
    return this.http.get(`${this.urlToolRate}`);
  }

  deleteTool(tool: any){
    return this.http.post(this.baseNewToolsUrl + '/deleteTool', tool);
  }

  deleteProposeTool(uid: any){

    return this.http.delete(this.baseNewToolsUrl + '/deleteProposeTool/' + uid);
  }

  updateTool(tool: any){
    return this.http.put(this.baseNewToolsUrl, tool);
  }

  updateStatusTool(tool_id: number, status: number, adminComment: string){
    return this.http.put(this.baseNewToolsUrl + '/status', {tool_id: tool_id, status_id: status, adminComment: adminComment});
  }

  updateProposeTool(uid: number, country_origin_id: number, app_name: string, app_name_origin: string, url: string, short_description: string, long_description: string, short_description_origin: string, long_description_origin: string){

    let body = {
      uid: uid,
      country_origin: country_origin_id,
      app_name: app_name,
      app_name_origin:app_name_origin,
      url: url,
      short_description: short_description,
      long_description: long_description,
      short_description_origin: short_description_origin,
      long_description_origin: long_description_origin
    }

    console.log(body)

    return this.http.put(this.baseNewToolsUrl + '/propose', body);
  }

  getToolByUid(uid: number){
    return this.http.get(this.baseUrl + '/' + uid);
  }

  getToolByUserId(userId: string){
    return this.http.get(this.baseUrl + '/userId/' + userId);
  }
}

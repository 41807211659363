import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BestPracticesService } from '../../core/binding-data/best-practices/best-practices.service';
import { AdministrationPanelService } from '../../core/binding-data/administration-panel/administration-panel.service';

@Injectable({
  providedIn: 'root'
})
export class CheckRoleAdminGuard implements CanActivate {

  constructor(private administrationPanelService: AdministrationPanelService, private router: Router){}

  canActivate(): boolean  {

    let boolean = null;

    this.administrationPanelService.getRoleCurrentUser$().subscribe((data) => {
      console.log('role guard -> ', data);
      if(data == null || data != 1 && data != 2){
        this.router.navigate(['/', 'home'])
        boolean = false;
      }else{
        boolean = true;
      }
    }).unsubscribe();

    return boolean;
  }

}

<h1>My tools</h1>

<!--Table-->
<div *ngIf="window == 1" class="form-group row">
  <table class="table table-hover" id="tableTool" name="tableTool">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Stops</th>
        <th scope="col">Steps</th>
        <th scope="col">Short description</th>
        <th scope="col">Status</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tool of arrayTool | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
        <td>{{tool.app_name}}</td>
        <td><p *ngFor="let stop of tool.stops">{{stop.stop}}</p></td>
        <td><p *ngFor="let step of tool.steps">{{step.step}}</p></td>
        <td>{{tool.short_description}}</td>
        <td>{{tool.tool_status.name}}</td>
        <td>
          <button *ngIf="tool.tool_status_id == 6" class="btn btn-light">
            <i class="fa fa-pencil-square-o" aria-hidden="true" (click)="toTinyForm(tool)"></i>
          </button>
          <button *ngIf="tool.tool_status_id == 6" class="btn btn-light">
            <i class="fa fa-trash" aria-hidden="true" (click)="deleteProposeTool(tool.uid)"></i>
          </button>
          <button *ngIf="tool.tool_status_id == 2 || tool.tool_status_id == 5" class="btn btn-light">
            <i class="fa fa-pencil-square-o" aria-hidden="true" (click)="toBigForm(tool.uid)"></i>
          </button>
          <button *ngIf="tool.tool_status_id == 2 || tool.tool_status_id == 5" class="btn btn-light">
            <i class="fa fa-trash" aria-hidden="true" (click)="deleteTool(tool.uid)"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="arrayTool.length"></ngb-pagination>
</div>

<!--Tiny form-->
<form *ngIf="window == 2" id="form{{window}}">
  <button class="btn btn-link buttonsAdminAccount" style="margin-top: 2%; margin-bottom: 2%;" (click)="window = 1">
    <div class="h3">
      <- Table
    </div>
  </button>
  <br>
  <div *ngIf="adminComment != ''" class="form-group">
    <label for="toolAdminComment">Admin comment</label>
    <textarea class="form-control" id="toolAdminComment" name="toolAdminComment" rows="3" placeholder="An admin comment for this tool" value="{{adminComment}}" readonly></textarea>
  </div>

  <div class="form-group">
    <label for="toolCountryorigin">Country of origin of the application <span class="obligatory">*</span></label>
    <ng-select
        [multiple]="false"
        [closeOnSelect]="true"
        [searchable]="true"
        bindLabel="name"
        placeholder="Select country"
        [(ngModel)]="valueCountry"
        id="toollanguageorigin"
        name="toollanguageorigin"
        [required]="true">
        <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
    </ng-select>
  </div>

   <div class="form-group">
     <label for="toolname">Resource name (English name)</label>
     <input type="text" class="form-control" id="toolname" name="toolname" [required]="true" [(ngModel)]="valueName" placeholder="Please write the name in English">
   </div>

   <div class="form-group">
     <label for="toolnameoriginal">Resource name (Application name in language of origin)</label>
     <input type="text" class="form-control" id="toolnameoriginal" name="toolnameoriginal" [(ngModel)]="valueNameOriginal" placeholder="Please write the name in the language of the language of origin">
   </div>

   <div>
     <label>Access via URL. Does the application access via URL?</label>
     <fieldset id="group">
       <div class="form-check">
         <input class="form-check-input" type="radio" name="group" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueIsURL">
         <label class="form-check-label" for="flexRadioMonitoring1">
           Yes
         </label>
       </div>
       <div class="form-check">
         <input class="form-check-input" type="radio" name="group" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueIsURL">
         <label class="form-check-label" for="flexRadioMonitoring2">
           No
         </label>
       </div>
     </fieldset>
   </div>

   <br>

   <div *ngIf="valueIsURL == true">
     <div class="form-group">
       <label for="toolurl">Application URL</label>
       <input type="text" class="form-control" id="toolurl" name="toolurl" [(ngModel)]="valueURL" placeholder="Application URL">
     </div>
   </div>

   <div class="form-group">
     <label for="toolshortdescription">Short description (English) (255 characters)</label>
     <textarea class="form-control" id="toolshortdescription" name="toolshortdescription" [required]="true" maxlength="255" rows="3" placeholder="Short description (English) (255 characters)" [(ngModel)]="valueShortDescription" (ngModelChange)="shortDescriptionChangeEvent()"></textarea>
     <div>{{lengthShortDescription}}/255</div>
   </div>

   <div class="form-group">
     <label for="toollongdescription">Long description (English) (5000 characters)</label>
     <textarea class="form-control" id="toollongdescription" name="toollongdescription" maxlength="5000" rows="3" placeholder="Long description (English) (5000 characters)" [(ngModel)]="valueLongDescription" (ngModelChange)="longDescriptionChangeEvent()"></textarea>
     <div>{{lengthLongDescription}}/5000</div>
   </div>

   <div class="form-group">
     <label for="toolshortdescriptionoriginal">Short description (Application description in language of origin) (255 characters)</label>
     <textarea class="form-control" id="toolshortdescriptionoriginal" name="toolshortdescriptionoriginal" maxlength="255" rows="3" placeholder="Short description (Application description in language of origin) (255 characters)" [(ngModel)]="valueShortDescriptionOriginal" (ngModelChange)="shortDescriptionOriginalChangeEvent()"></textarea>
     <div>{{lengthShortDescriptionOriginal}}/255</div>
   </div>

   <div class="form-group">
     <label for="toollongdescriptionoriginal">Long description (Application description in language of origin) (5000 characters)</label>
     <textarea class="form-control" id="toollongdescriptionoriginal" name="toollongdescriptionoriginal" maxlength="5000" rows="3" placeholder="Long description (Application description in language of origin) (5000 characters)" [(ngModel)]="valueLongDescriptionOriginal" (ngModelChange)="longDescriptionOriginalChangeEvent()"></textarea>
     <div>{{lengthLongDescriptionOriginal}}/5000</div>
   </div>

   <button id="updateButton" name="updateButton" class="btn btn-primary" (click)="updateToPropose()">Update Propose Tool</button>

 </form>

 <!--Big form-->
<div *ngIf="window == 3">
  <button class="btn btn-link buttonsAdminAccount" style="margin-top: 2%; margin-bottom: 2%;" (click)="window = 1">
    <div class="h3">
      <- Table
    </div>
  </button>
  <form #tooldata="ngForm">

    <h2>Form {{switchFormCount}}</h2>

    <div *ngIf="adminComment != ''" class="form-group">
      <label for="toolAdminComment">Admin comment</label>
      <textarea class="form-control" id="toolAdminComment" name="toolAdminComment" rows="3" placeholder="An admin comment for this tool" value="{{adminComment}}" readonly></textarea>
    </div>

    <div [ngSwitch]="switchFormCount">
      <!--First part-->
      <div *ngSwitchCase="1" id="form1">
        <div class="form-group">
          <label for="toolCountryorigin">Country of origin of the application <span class="obligatory">*</span></label>
          <ng-select
              [multiple]="false"
              [closeOnSelect]="true"
              [searchable]="true"
              bindLabel="name"
              placeholder="Select country"
              [(ngModel)]="valueCountry"
              id="toollanguageorigin"
              name="toollanguageorigin"
              [required]="true">
              <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
          </ng-select>
        </div>

        <div class="form-group">
          <label for="toolname">Resource name (English name) <span class="obligatory">*</span></label>
          <input type="text" class="form-control" id="toolname" name="toolname" [required]="true" [(ngModel)]="valueName" placeholder="Please write the name in English">
        </div>

        <div class="form-group">
          <label for="toolnameoriginal">Resource name (Application name in language of origin)</label>
          <input type="text" class="form-control" id="toolnameoriginal" name="toolnameoriginal" [(ngModel)]="valueNameOriginal" placeholder="Please write the name in the language of the country of origin">
        </div>

        <div>
          <label>Access via URL. Does the application access via URL?</label>
          <fieldset id="group">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="group" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueIsURL">
              <label class="form-check-label" for="flexRadioMonitoring1">
                Yes
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="group" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueIsURL">
              <label class="form-check-label" for="flexRadioMonitoring2">
                No
              </label>
            </div>
          </fieldset>
        </div>

        <br>

        <div *ngIf="valueIsURL == true">
          <div class="form-group">
            <label for="toolurl">Application URL <span class="obligatory">*</span></label>
            <input type="text" class="form-control" id="toolurl" name="toolurl" [(ngModel)]="valueURL" placeholder="Application URL">
          </div>
        </div>

        <div class="form-group">
          <label for="toolshortdescription">Short description (English) (255 characters) <span class="obligatory">*</span></label>
          <textarea class="form-control" id="toolshortdescription" name="toolshortdescription" [required]="true" maxlength="255" rows="3" placeholder="Short description (English) (255 characters)" [(ngModel)]="valueShortDescription" (ngModelChange)="shortDescriptionChangeEvent()"></textarea>
          <div>{{lengthShortDescription}}/255</div>
        </div>

        <div class="form-group">
          <label for="toollongdescription">Long description (English) (5000 characters) <span class="obligatory">*</span></label>
          <textarea class="form-control" id="toollongdescription" name="toollongdescription" maxlength="5000" rows="3" placeholder="Long description (English) (5000 characters)" [(ngModel)]="valueLongDescription" (ngModelChange)="longDescriptionChangeEvent()"></textarea>
          <div>{{lengthLongDescription}}/5000</div>
        </div>

        <div class="form-group">
          <label for="toolshortdescriptionoriginal">Short description (Application description in language of origin) (255 characters)</label>
          <textarea class="form-control" id="toolshortdescriptionoriginal" name="toolshortdescriptionoriginal" maxlength="255" rows="3" placeholder="Short description (Application description in language of origin) (255 characters)" [(ngModel)]="valueShortDescriptionOriginal" (ngModelChange)="shortDescriptionOriginalChangeEvent()"></textarea>
          <div>{{lengthShortDescriptionOriginal}}/255</div>
        </div>

        <div class="form-group">
          <label for="toollongdescriptionoriginal">Long description (Application description in language of origin) (5000 characters)</label>
          <textarea class="form-control" id="toollongdescriptionoriginal" name="toollongdescriptionoriginal" maxlength="5000" rows="3" placeholder="Long description (Application description in language of origin) (5000 characters)" [(ngModel)]="valueLongDescriptionOriginal" (ngModelChange)="longDescriptionOriginalChangeEvent()"></textarea>
          <div>{{lengthLongDescriptionOriginal}}/5000</div>
        </div>
        <div>
      </div>
      </div>

      <!--Second part-->
      <div *ngSwitchCase="2" id="form1">

        <label>Multimedia information</label>
        <p *ngIf="toolImage != ''">You have got already a Main image: {{toolImage}}. Do you want to change it?</p>
        <div class="form-group">
          <label for="images" class="form-label">Main Image <span class="obligatory">*</span></label>
          <input class="form-control" type="file" id="images" name="images" (change)="fileImageChangeEvent($event)">
        </div>

        <div>
          <label>Do you have more multimedia resources?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioMonitoring">
            <label class="form-check-label" for="flexRadioMonitoring1">
              Yes
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioMonitoring">
            <label class="form-check-label" for="flexRadioMonitoring2">
              No
            </label>
          </div>
        </div>

        <br>

        <div *ngIf="valueFlexRadioMonitoring == true">
          <label for="images" class="form-label">Multimedia resources</label>

          <div class="form-group">
            <p *ngIf="nameOfImages != ''">You have got already some images: {{nameOfImages}}</p>
            <label for="images" class="form-label">Other images (max 5)</label>
            <input class="form-control" type="file" id="images" name="images" (change)="fileOtherImageChangeEvent($event)" multiple>
          </div>

          <div class="form-group">
            <label for="name">Video</label>
            <input type="text" class="form-control" id="video" name="video" placeholder="Video URL" [(ngModel)]="valueVideoURL">
          </div>

          <div class="form-group">
            <p *ngIf="pdfDocumentsString != ''">You have got already some PDF FILES: {{pdfDocumentsString}}</p>
            <label for="formFile" class="form-label">Documents (.pdf, max 3)</label>
            <input class="form-control" type="file" name="documents" (change)="fileChangeEvent($event)" multiple>
          </div>
        </div>
      </div>

      <!--Third part-->
      <div *ngSwitchCase="3" id="form1">

          <label>More information</label>
          <div>
            <label>Maturity: Is this resource a prototype?</label>
            <fieldset id="group1">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group1" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valuePrototype">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group1" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valuePrototype">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </fieldset>
          </div>

          <div>
            <label>Access. Does the application require authentication?</label>
            <fieldset id="toolAccess">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="toolAccess" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioAccessForm3">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="toolAccess" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioAccessForm3">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </fieldset>
          </div>

          <!--<div *ngIf="valueFlexRadioAccessForm3 == true">-->
          <div class="form-group">
            <label for="name">Cost</label>
            <fieldset id="valueAccess">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioMonitoring1" id="flexRadioMonitoring1" value="free-access" [(ngModel)]="valueAccess">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Free access
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioMonitoring2" id="flexRadioMonitoring2" value="demostration" [(ngModel)]="valueAccess">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  Demostration
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioMonitoring2" id="flexRadioMonitoring2" value="paying" [(ngModel)]="valueAccess">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  Paying
                </label>
              </div>
            </fieldset>
          </div>
            <!--</div>-->

          <div>
            <label>Is the application open source?</label>
            <fieldset id="valueOpenSource">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="valueOpenSource" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueOpenSource">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="valueOpenSource" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueOpenSource">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </fieldset>
          </div>

          <div>
            <label>Is there a repository to download the source code of the application? </label>
            <fieldset id="group4">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group4" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioRepositoryForm3">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group4" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioRepositoryForm3">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </fieldset>
          </div>

          <div *ngIf="valueFlexRadioRepositoryForm3 == true">
            <div class="form-group">
              <label for="valuelUrlRepository">URL Repository <span class="obligatory">*</span></label>
              <input type="text" class="form-control" id="valuelUrlRepository" name="valuelUrlRepository" placeholder="URL Repository" [(ngModel)]="valuelUrlRepository">
            </div>
          </div>

          <div>
            <label>Does the application offer a data access API?</label>
            <fieldset id="group5">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group5" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioAPIForm3">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group5" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioAPIForm3">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </fieldset>
          </div>

          <div *ngIf="valueFlexRadioAPIForm3 == true">
            <div class="form-group">
              <label for="valueApiUrl">API URL information <span class="obligatory">*</span></label>
              <input type="text" class="form-control" id="valueApiUrl" name="valueApiUrl" placeholder="API URL information" [(ngModel)]="valueApiUrl">
            </div>
          </div>

          <div>
            <label>Other countries. Can the application be used in other countries?</label>
            <fieldset id="group6">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group6" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioCountriesForm3">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group6" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioCountriesForm3">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </fieldset>
          </div>

          <div *ngIf="valueFlexRadioCountriesForm3 == true">
            <div class="form-group">
              <label for="valueLanguageWork">{{'TOOLS_PAGE.PROPOSE_TOOL.COUNTRIES_WHERE_TOOL_WORK' | translate}} <span class="obligatory">*</span></label>
              <ng-select
                [multiple]="true"
                [closeOnSelect]="false"
                [searchable]="true"
                bindLabel="name"
                placeholder="Select country"
                [(ngModel)]="valueCountryWork"
                id="valueCountryWork"
                name="valueCountryWork">
                <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
              </ng-select>
            </div>
          </div>

          <div>
            <label>Is the application addressed to energy renovation expert users?</label>
            <fieldset id="valueExpert">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="valueExpert" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueExpert">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="valueExpert" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueExpert">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </fieldset>
          </div>

          <div>
            <label>Constraints. Do you want to add constraints to this tool?</label>
            <fieldset id="group16">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group16" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioMoreConstraints">
                <label class="form-check-label" for="flexRadioMonitoring1">
                  Yes
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="group16" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioMoreConstraints">
                <label class="form-check-label" for="flexRadioMonitoring2">
                  No
                </label>
              </div>
            </fieldset>
          </div>

        <div>
      </div>
      </div>

      <!--Fourth part-->
      <div *ngSwitchCase="4" id="form1">

        <div *ngFor="let current of valueConstraintInfo; index as i">
          <h3>Constraints form {{i + 1}}</h3>
          <button *ngIf="i > 0" class="form-control btn btn-warning" id="deductForm" name="deductForm" (click)="deductForm(4, i)">Deduct the constraint</button>
          <div class="form-group">
            <label for="toolProfiles{{current.idg}}">Profiles</label>
            <ng-select id="filterProfiles{{current.idg}}" name="toolProfiles{{current.idg}}" class="filter" placeholder="Select profile" [(ngModel)]="current.profile_id" (change)="categoryChangeEvent(i)">
              <ng-option *ngFor="let profile of arrayProfile" [value]="profile.id">{{profile.profile}}</ng-option>
            </ng-select>
          </div>

          <div class="form-group">
            <label for="toolConstraintsType{{current.idg}}">Constraint category</label>
            <ng-select id="filterConstraintsType{{current.idg}}" name="toolConstraintsType{{current.idg}}" class="filter" placeholder="Select the constrain category" [(ngModel)]="current.constraint_type_id" (change)="categoryChangeEvent(i)">
              <ng-option *ngFor="let constraint of arrayConstraintType" [value]="constraint.id">{{constraint.constraint_type}}</ng-option>
            </ng-select>
          </div>

          <div class="form-group">
            <label for="toolConstraintsCategory{{current.idg}}">Constraint class</label>
            <ng-select id="filterConstraintsType{{current.idg}}" name="toolConstraintsCategory{{current.idg}}" class="filter" placeholder="Constraints class" [multiple]="true" [(ngModel)]="current.constraint_category" [closeOnSelect]="false" [readonly]="isCategoryReadOnly[i]">
              <ng-option *ngFor="let constraint of tmpConstrainsCategoryList[i]" [value]="constraint.name">{{constraint.constraint_category}}</ng-option>
            </ng-select>
          </div>
        </div>
        <button class="form-control btn btn-primary" id="addForm" name="addForm" (click)="addForm(4)">Add new constraint</button>
      </div>

      <!--Fifth part-->
      <div *ngSwitchCase="5" id="form1">

        <div class="form-group">
          <label for="solutions">Solutions <span class="obligatory">*</span></label>
          <ng-select id="solutions" class="filter" placeholder="Select the solutions provided by with the app" name="solutions" [(ngModel)]="valueSolutions" [multiple]="true" [closeOnSelect]="false">
            <ng-option *ngFor="let solutions of arraySolution" [value]="solutions.id">{{solutions.solution}}</ng-option>
          </ng-select>
        </div>

        <div class="form-group">
          <label for="typologies">Typologies <span class="obligatory">*</span></label>
          <ng-select id="typologies" class="filter" placeholder="Select the typologies provided by with the app" name="typologies" [(ngModel)]="valueTypologies" [multiple]="true" [closeOnSelect]="false">
            <ng-option *ngFor="let typologies of arrayTypology" [value]="typologies.id">{{typologies.typology}}</ng-option>
          </ng-select>
        </div>

        <div class="form-group">
          <label for="stops">Stops <span class="obligatory">*</span></label>
          <ng-select id="stops" class="filter" placeholder="Select the stops provided by with the app" name="stops" [(ngModel)]="valueStops" [multiple]="true" [closeOnSelect]="false" (change)="stopChangeEvent()">
            <ng-option *ngFor="let stop of arrayStop" [value]="stop.id">{{stop.stop}}</ng-option>
          </ng-select>
        </div>

        <div class="form-group">
          <label for="steps">Steps <span class="obligatory">*</span></label>
          <ng-select id="steps" class="filter" placeholder="Select the steps provided by with the app" name="steps" [(ngModel)]="valueSteps" [multiple]="true" [closeOnSelect]="false" [readonly]="isStepReadOnly">
            <ng-option *ngFor="let step of tmpArrayStep" [value]="step.id">{{step.step}}</ng-option>
          </ng-select>
        </div>

        <div>
          <label>Is there a training or certification scheme for expert user of this resource?</label>
          <fieldset id="group8">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="group8" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioTrainingForm6">
              <label class="form-check-label" for="flexRadioMonitoring1">
                Yes
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="group8" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioTrainingForm6">
              <label class="form-check-label" for="flexRadioMonitoring2">
                No
              </label>
            </div>
          </fieldset>
        </div>

        <div *ngIf="valueFlexRadioTrainingForm6 == true">
          <div class="form-group">
            <label for="valueTrainingPage">Training webpage information <span class="obligatory">*</span></label>
            <input type="text" class="form-control" id="valueTrainingPage" name="valueTrainingPage" placeholder="Training webpage information" [(ngModel)]="valueTrainingPage">
          </div>
        </div>

        <br>

        <div>
          <label>Does the application works in other languages?</label>
          <fieldset id="group12">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="group12" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioMoreLanguagesForm6">
              <label class="form-check-label" for="flexRadioMonitoring1">
                Yes
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="group12" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioMoreLanguagesForm6">
              <label class="form-check-label" for="flexRadioMonitoring2">
                No
              </label>
            </div>
          </fieldset>
        </div>

        <br>

        <div *ngIf="valueFlexRadioMoreLanguagesForm6 == true">
          <div class="form-group">
            <label for="toolLanguages">In what other languages does the application work? <span class="obligatory">*</span></label>
            <ng-select
              [multiple]="true"
              [closeOnSelect]="false"
              [searchable]="true"
              bindLabel="name"
              placeholder="Select languages"
              [(ngModel)]="valueLanguagesAdditional"
              id="valueLanguagesAdditional"
              name="valueLanguagesAdditional">
                <ng-option *ngFor="let language of arrayLanguage" [value]="language.language_code">{{language.name}}</ng-option>
            </ng-select>
          </div>
        </div>

        <div *ngIf="valueFlexRadioMoreLanguagesForm6 == true">
          <label>Other languages. Do you want to add descriptions in other languages?</label>
          <fieldset id="group13">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="group13" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="valueFlexRadioMoreDescriptionsForm6">
              <label class="form-check-label" for="flexRadioMonitoring1">
                Yes
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="group13" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="valueFlexRadioMoreDescriptionsForm6">
              <label class="form-check-label" for="flexRadioMonitoring2">
                No
              </label>
            </div>
          </fieldset>
        </div>
      </div>

      <!--Sixth part-->
      <div *ngSwitchCase="6" id="form1">

        <div *ngFor="let current of descriptionLanguage; index as i">
          <h3>Language Description Form {{i + 1}}</h3>

          <label>Descriptions in other languages</label>
          <button *ngIf="i > 0" class="form-control btn btn-warning" id="deductForm" name="deductForm" (click)="deductForm(6, i)">Deduct</button>

          <br>

          <label>Language description information</label>
          <div class="form-group">
            <label for="toollanguageadditional">Language of origin of the application <span class="obligatory">*</span></label>
            <ng-select
              [searchable]="true"
              bindLabel="name"
              placeholder="Select language of origin of the application"
              [(ngModel)]="current.language_id"
              id="toollanguageadditional"
              name="toollanguageadditional{{i}}">
              <ng-option *ngFor="let language of tmpValueLanguagesAdditional" [value]="language.language_code">{{language.name}}</ng-option>
            </ng-select>
          </div>

          <div class="form-group">
            <label for="toolnameadditional">Resources name <span class="obligatory">*</span></label>
            <input type="text" class="form-control" id="toolnameadditional" name="toolnameadditional{{i}}" [(ngModel)]="current.name_other_language" placeholder="Please write the name of the application in the language that you select">
          </div>

          <div class="form-group">
            <label for="toolshortdescriptionadditional">Short description (255 characters) <span class="obligatory">*</span></label>
            <textarea class="form-control" id="toolshortdescriptionadditional" name="toolshortdescriptionadditional{{i}}" maxlength="255" rows="3" placeholder="Short description (English) (255 characters)" [(ngModel)]="current.short_description" (ngModelChange)="shortDescriptionAdditionalChangeEvent(i)"></textarea>
            <div>{{arrayLengthShortDescriptionAdditional[i]}}/255</div>
          </div>

          <div class="form-group">
            <label for="toollongdescriptionadditional">Long description (5000 characters) <span class="obligatory">*</span></label>
            <textarea class="form-control" id="toollongdescriptionadditional" name="toollongdescriptionadditional{{i}}" maxlength="5000" rows="3" placeholder="Long description (English) (5000 characters)" [(ngModel)]="current.long_description" (ngModelChange)="longDescriptionAdditionalChangeEvent(i)"></textarea>
            <div>{{arrayLengthLongDescriptionAdditional[i]}}/5000</div>
          </div>
        </div>
        <button class="form-control btn btn-primary" id="addForm" name="addForm" (click)="addForm(6)">Add</button>
      </div>

      <!--Seventh part-->
      <div *ngSwitchCase="7" id="form1">

        <div class="form-group">
          <label for="valueApplicationOwner">Application owner</label>
          <input type="text" class="form-control" id="valueApplicationOwner" name="valueApplicationOwner" [(ngModel)]="valueApplicationOwner" placeholder="Please write the name of the application owner">
        </div>

        <div class="form-group">
          <label for="valueWebPage">Official Web page</label>
          <input type="text" class="form-control" id="valueWebPage" name="valueWebPage" [(ngModel)]="valueWebPage" placeholder="Please write the name of the official web page">
        </div>

        <div class="form-group">
          <label for="valueProposedBy">Proposed by (email)</label>
          <input type="text" class="form-control" id="valueProposedBy" name="valueProposedBy" [(ngModel)]="valueContactEmailPropose" placeholder="Please write the email">
        </div>

        <div class="form-group">
          <label for="valueOwnerContact">Owner contact (email)</label>
          <input type="text" class="form-control" id="valueOwnerContact" name="valueOwnerContact" [(ngModel)]="valueContactEmailOwner" placeholder="Please write the email of the owner contact">
        </div>

        <div>
        </div>
      </div>
    </div>

    <div [ngSwitch]="switchFormCount">

      <div *ngSwitchCase="1">

        <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
          <h4>
            <span>Next &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </h4>
        </button>

      </div>

      <div *ngSwitchCase="2">

        <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
          <h4>
            <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
          </h4>
        </button>

        <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
          <h4>
            <span>Next &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </h4>
        </button>

      </div>

      <div *ngSwitchCase="3">

        <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
          <h4>
            <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
          </h4>
        </button>

        <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
          <h4>
            <span>Next &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </h4>
        </button>

      </div>

      <div *ngSwitchCase="4">

        <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
          <h4>
            <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
          </h4>
        </button>

        <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
          <h4>
            <span>Next &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </h4>
        </button>

      </div>

      <div *ngSwitchCase="5">

        <div >

          <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
            <h4>
              <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
            </h4>
          </button>

          <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
            <h4>
              <span>Next &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
            </h4>
          </button>
        </div>

      </div>

      <div *ngSwitchCase="6">

        <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
          <h4>
            <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
          </h4>
        </button>

        <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
          <h4>
            <span> Next &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
          </h4>
        </button>

      </div>

      <div *ngSwitchCase="7">

        <button class="btn calculateTyp buttonLeft" (click)="deductSwitchFormCount()">
          <h4>
            <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Back</span>
          </h4>
        </button>

        <button role="button" class="btn calculateTyp" (click)="addSwitchFormCount()">
          <h4>
            <span> Save &nbsp; <i class="fa fa-bookmark" aria-hidden="true"></i></span>
          </h4>
        </button>

      </div>
    </div>
  </form>
</div>

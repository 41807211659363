import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Building } from '../../shared/models/building';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from '../../core/authentication/user.service';
import {Envelope} from '../../shared/models/envelope';
import {System} from '../../shared/models/system';
import {SystemType} from '../../shared/models/systemType';
import {Efficiency} from '../../shared/models/eficiency';
import {Typology} from '../../shared/models/typology';
import {Options} from '@angular-slider/ngx-slider';
import { ceeBuilding } from 'src/app/shared/models/ceeBuilding';
import { CeeService } from '../../core/wms/cee.service';
import { BestPracticesService } from '../../core/binding-data/best-practices/best-practices.service';
import { CeeBuilding } from '../../shared/models/ceeBuilding';
import { async } from '@angular/core/testing';
import { Testimony } from '../../shared/models/testimony';
import { Monitoring } from '../../shared/models/monitoring';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { NodeResponse } from 'src/app/shared/models/node_response';

@Component({
  selector: 'app-user-cee-history',
  templateUrl: './user-cee-history.component.html',
  styleUrls: ['./user-cee-history.component.scss']
})
export class UserCeeHistoryComponent implements OnInit {

  userHistory: any[] = [];
  deletedBuilding: boolean;
  filterCountries: string[];
  filterTypologies: { code: string, name: string}[];
  filterYears: string[];
  tmpUserCEEHistory: CeeBuilding[] = [];

  countryControl: boolean;
  countrySelected: string;

  yearControl: boolean;
  yearSelectedMin: any;
  yearSelectedMax: any;

  typologyControl: boolean;
  typologySelected: string;

  searchText: any;

  filterApplied: string[];

  guid: string = null;
  @Input() history: CeeBuilding[];


  @Input() optionSelected: number;

  @Output() historyUsersEmitter = new EventEmitter<any>();
  @Output() historyEmitter = new EventEmitter<any>();

  @Output() buildingSelectedEmitter = new EventEmitter<any>();

  @Output() buildingToBeUpdatedEmitter = new EventEmitter<any>();

  @Input() role: number = 0;

  userCeeBuildingRelation: any[] = [];

  constructor(
    public afAuth: AngularFireAuth,
    private userService: UserService,
    private ceeService: CeeService,
    private bestPracticesService:BestPracticesService,
    private administrationPanelService: AdministrationPanelService
  ) {}

  ngOnInit(): void {
    this.filterApplied = [];

    this.administrationPanelService.getGuid$().subscribe((data: string) => {
      if(data != null){
        this.guid = data
        this.getUserCeeBuildings();
        this.getRelationUserAndCeeBuilding();

      }else{
        this.guid = null;
      }
    });

    //this.getUserCeeBuildings();
    //this.buildCEEHistory();

  }

  /*getUserCeeBuildings(){
    this.ceeService.getGuidUserCeeBuildings(this.guid).toPromise().then( async (data: any[]) => {
      console.log("las idCeeBuildings del user ->", data );

      this.tmpUserCEEHistory = [];

      if(data.length > 0){
        for(let i = 0; i < data.length; i++){
          this.ceeService.getCeeBuildingId(data[i].cee_building_id).toPromise().then((data: CeeBuilding) => {
            this.tmpUserCEEHistory.push(data);
          })
        }
        this.userHistory = this.tmpUserCEEHistory;
        this.buildFilters();
        this.historyUsersEmitter.emit(this.tmpUserCEEHistory);

        console.log('todos los UserCeeBuilding -> ', this.tmpUserCEEHistory);
      }

    })
  }*/

  getUserCeeBuildings(){

    this.tmpUserCEEHistory = [];

    this.ceeService.getCeeBuildingByUid(this.guid).toPromise().then((data: NodeResponse) => {
      if(data.data.length > 0){
        this.tmpUserCEEHistory = data.data;
        console.log('todos los UserCeeBuilding -> ', this.tmpUserCEEHistory);

        this.userHistory = this.tmpUserCEEHistory;

        this.buildFilters();
        this.historyUsersEmitter.emit(this.tmpUserCEEHistory);

      }
    })
  }

  getRelationUserAndCeeBuilding(){

    this.userCeeBuildingRelation = [];

    this.ceeService.getGuidUserCeeBuildings(this.guid).toPromise().then( (data: any[]) => {
      if(data.length > 0){
        this.userCeeBuildingRelation = data;
        console.log('relation => ', data)
      }

    })
  }

  getIsOwner(idCeeBuilding: number){

    let value = false;

    for(let i = 0; i < this.userCeeBuildingRelation.length; i++){
      if(this.userCeeBuildingRelation[i].cee_building_id = idCeeBuilding){
        value = true;
        break;
      }
    }

    return value;
  }


  consoleJorge(){
    console.log("historias editadas -> ", this.history);
  }


  showBuildingResults(building: any): void {
    this.buildingSelectedEmitter.emit(building);
  }

  updateBuilding(building: any): void {
    this.buildingToBeUpdatedEmitter.emit(building);
  }

  async removeCEEBuildingFromUserHistory(id: number, index: number) {

    if(confirm('do you want to delete this building?')){


        await this.ceeService.deleteRenovationPicturesIdCeeBuilding(id).toPromise().then(async (data: NodeResponse) => {
          console.log('renovation pictures delete -> ', data)
        })

        await this.ceeService.getTestimonyIdCeeBuilding(id).toPromise().then(async (data: Testimony) => {
          if(data != null){
            let testimony = data;

            console.log("testimony para el delete -> ",testimony)

            await this.ceeService.deleteImagesidCeeBuilding(id).toPromise().then(async (data: Testimony)=> {
              console.log(data);

              await this.ceeService.deleteTestimonyIdCeeBuilding(id).toPromise().then((data: any) => {
                console.log(data);
              })
            })
          }
        })

        await this.ceeService.getMonitoringIdCeeBuilding(id).toPromise().then(async (data: Monitoring[]) => {
          if(data != null){
            let monitoring = data;

            console.log("monitoring para el delete -> ", monitoring);

            for(let i = 0; i < monitoring.length; i++){
              await this.ceeService.deleteFileIdMonitoring(monitoring[i].id).toPromise().then(async (data: any) => {
                console.log(data);
              })

              await this.ceeService.deleteMonitoringHealthIdMonitoring(monitoring[i].id).toPromise().then(async (data: any) => {
                console.log(data);
              })

            }

            await this.ceeService.deleteMonitoringIdCeeBuilding(id).toPromise().then((data: any) => {
              console.log(data);
            })

          }
        })

        await this.ceeService.deleteCeeBuilding(id).toPromise().then(async (data: any) => {
          console.log(data);

          this.tmpUserCEEHistory.splice(index, 1);
          this.bestPracticesService.setRefreshUserCeeBuilding();

          for(let i = 0; i < this.history.length; i++){
            if(this.history[i].id == id){
              this.history.splice(i, 1);
              this.historyEmitter.emit(this.history);
              break;
            }
          }
        })
    }
  }

  buildCEEHistory() {
    this.userService.getUserCEEHistory(localStorage.getItem('auth-token')).subscribe( hist => {
      this.userHistory = [];
      for (const histKey in hist) {
        this.userHistory.push(hist[histKey]);
      }
      this.tmpUserCEEHistory = this.userHistory;
      this.buildFilters();
      //this.historyEmitter2.emit(this.userHistory);
    });
  }

  buildFilters() {
    this.filterCountries = [];
    this.filterTypologies = [];
    this.filterYears = [];
    const currentTime = new Date();
    for ( let i = currentTime.getFullYear(); i > 0; i --) {
      this.filterYears.push(i.toString());
    }
    this.tmpUserCEEHistory.forEach( ( hist: any) => {
      if ( this.filterCountries.length === 0 || !this.filterCountries.includes(hist.country)) {
        this.filterCountries.push(hist.country);
      }
      if (this.filterTypologies.length === 0 || !this.filterTypologies.find(item => item.code === hist.tipology_id)) {
        const typo = { code: hist.tipology_id, name: hist.tipology_id};
        this.filterTypologies.push(typo);
      }
      /*if (this.filterYears.length === 0 || !this.filterYears.includes(hist.year)) {
        this.filterYears.push(hist.year);
      }*/
    });
  }

  filter(type: string): void {
    if ( ( type === 'country' && this.countrySelected !== null ) ||
      ( type === 'yearMin' && this.yearSelectedMin !== null ) ||
      ( type === 'yearMax' && this.yearSelectedMax !== null ) ||
      ( type === 'typology' && this.typologySelected !== null )){

      const indexFilterApplied = this.filterApplied.indexOf(type);
      if (indexFilterApplied < 0) {
        this.filterApplied.push(type);
      }
    } else {
      this.cleanFilter(type);
    }
    let arrayFiltered = [];
    this.userHistory.forEach(el => {
      arrayFiltered.push(el);
    });
    this.filterApplied.forEach( filter => {
      if (filter === 'yearMin') {
        const filterByYear  = [];
        this.userHistory.forEach( hist => {
          if ( hist.year >= this.yearSelectedMin) {
            filterByYear.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByYear);
      }
      if (filter === 'yearMax') {
        const filterByYear  = [];
        this.userHistory.forEach( hist => {
          if ( hist.year <= this.yearSelectedMax) {
            filterByYear.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByYear);
      }
      if (filter === 'country') {
        const filterByUse  = [];
        this.userHistory.forEach( hist => {
          if ( hist.country === this.countrySelected) {
            filterByUse.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByUse);
      }

      if (filter === 'typology') {
        const filterByTypology  = [];
        this.userHistory.forEach( hist => {
          if ( hist.typology.categoryCode === this.typologySelected) {
            filterByTypology.push(hist);
          }
        });
        arrayFiltered = this.removeElementsFromArray(arrayFiltered, filterByTypology);
      }
    });
    this.tmpUserCEEHistory = arrayFiltered;
    this.buildFilters();
    this.historyEmitter.emit(this.tmpUserCEEHistory);
  }

  cleanFilter(type: string) {
    const indexFilterApplied = this.filterApplied.indexOf(type);
    if (indexFilterApplied > -1) {
      this.filterApplied.splice(indexFilterApplied, 1);
    }
  }

  removeElementsFromArray(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }

}

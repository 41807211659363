import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { GlobalConstants} from '../../shared/GlobalConstants';
import {Building} from '../../shared/models/building';
import { Tools } from '../../shared/models/tools';
import { User } from 'src/app/shared/models/user';
import { ceeBuilding } from 'src/app/shared/models/ceeBuilding';
import { ToolsNew } from 'src/app/shared/models/toolsnew';
import { ToolsService } from '../tools/tools.service';
import { NodeResponse } from 'src/app/shared/models/node_response';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser: User;

  private baseUrl = GlobalConstants.backendURL + '/api/user';
  private baseUserRoleUrl = GlobalConstants.backendURL + '/api/user_role';
  private baseHistoryUrl = GlobalConstants.backendURL + '/api/history';
  private baseUserExtensionUrl = GlobalConstants.backendURL + '/api/userExtension';
  private baseUserSavedUrl = GlobalConstants.backendURL + '/api/user_saved';
  private baseArticleSaved = GlobalConstants.backendURL + '/api/user_article';

  constructor(private http: HttpClient, private toolService: ToolsService) { }

  getUsers(token: string){
    return this.http.get(`${this.baseUrl}`, { headers : { 'x-access-token' : token}});
  }

  savedUsers(user_id: any, user_saved: any, name: any){
    let body = {
      user_id: user_id,
      user_saved: user_saved,
      name: name
    }

    return this.http.post(this.baseUserSavedUrl, body);
  }

  getSavedUsers(idUser: any){
    return this.http.get(this.baseUserSavedUrl + '/userId/' + idUser);
  }

  getSavedArticles(idUser: any){
    return this.http.get(this.baseArticleSaved + '/userId/' + idUser);
  }

  getAllDataUsers(token: string){
    return this.http.get(this.baseUrl, { headers : { 'x-access-token' : token}});
  }

  getAllDataUsersProfessionals(token: string){
    return this.http.get(this.baseUrl + '/professional', { headers : { 'x-access-token' : token}});
  }

  getByEmail(email: string){
    return this.http.get(this.baseUrl + '/email/' + email);
  }

  getByUid(uid) {
    return this.http.get(`${this.baseUrl}/${uid}`);
  }
  create(data) {
    return this.http.post(this.baseUrl, data);
  }

  register(uid: string, email: string, name: string, country: number) {

    let bodyuser = {
      uid: uid,
      email: email,
      name: name,
      country: country,
      secret: GlobalConstants.secret
    }

    return this.http.post(this.baseUrl + '/register', bodyuser);
  }

  update(uid: string, body: any) {
    return this.http.put(`${this.baseUrl}/${uid}`, body);
  }

  delete(uid) {
    this.toolService.getToolByUserId(uid).toPromise().then(async (dataTools: NodeResponse) => {
      if(dataTools.status == true && dataTools.data.length > 0){
        console.log('This user has got ' + dataTools.data.length + 'tools')

        for(let i = 0; i < dataTools.data.length; i++){
          await this.toolService.deleteTool(dataTools.data[i]).toPromise().then((dataDelete: NodeResponse) => {
            if(dataDelete.status == true) console.log('Tool number ' + (i + 1) + ' deleted');
          });
        }

      }
    })

    return this.http.delete(`${this.baseUrl}/${uid}`);

  }
  addPropertyToHistory(building: Building, token: string) {
    return this.http.post(this.baseHistoryUrl, building, { headers : { 'x-access-token' : token}});
  }
  addPropertyCEEToHistory(building: ceeBuilding, token: string) {
    return this.http.post(`${this.baseHistoryUrl}/cee`, building, { headers : { 'x-access-token' : token}});
  }
  addPropertyToolToHistory(tool: ToolsNew, token: string) {
    return this.http.post(`${this.baseHistoryUrl}/tools`, tool, { headers : { 'x-access-token' : token}});
  }
  getUserRole(uid: string) {
    return this.http.get(this.baseUserRoleUrl + '/' + uid);
  }
  getUserHistory(token: string) {
    return this.http.get(this.baseHistoryUrl + '/buildings',  { headers : { 'x-access-token' : token}});
  }
  getUserCEEHistory(token: string) {
    return this.http.get(this.baseHistoryUrl + '/cee',  { headers : { 'x-access-token' : token}});
  }
  getUserTools(token: string) {
    return this.http.get(this.baseHistoryUrl + '/tools', { headers : { 'x-access-token' : token}});
  }
  isFavorite(token: string, address: string) {
    return this.http.get(this.baseHistoryUrl + '/favorite/address/' + encodeURIComponent(address),
      { headers : { 'x-access-token' : token}});
  }
  isFavorite2(token: string, name: string) {
    return this.http.get(this.baseHistoryUrl + '/favorite/name/' + encodeURIComponent(name),
      { headers : { 'x-access-token' : token}});
  }
  updatePropertyFromHistory(building: Building, token: string) {
    return this.http.put(this.baseHistoryUrl, building, { headers : { 'x-access-token' : token}});
  }
  deletePropertyFromHistory(idBuilding: number, token: string) {
    return this.http.delete(this.baseHistoryUrl + '/building/' + +idBuilding, { headers : { 'x-access-token' : token}});
  }
  deletePropertyCEEFromHistory(idBuilding: number, token: string) {
    return this.http.delete(this.baseHistoryUrl + '/cee/' + +idBuilding, { headers : { 'x-access-token' : token}});
  }
  deletePropertyToolFromHistory(idTool: number, token: string) {
    return this.http.delete(this.baseHistoryUrl + '/tools/' + +idTool, { headers : { 'x-access-token' : token}});
  }
  getYearsRange(country: string) {
    return this.http.get( `${this.baseHistoryUrl}/years/country/${country} `);
  }

  //user_extension

  getAllUserExtension(){
    return this.http.get(this.baseUserExtensionUrl);
  }

  updateStatus(uid: string ,status: boolean){

    let body = {
      uid: uid,
      status: status
    }

    return this.http.put(this.baseUserExtensionUrl + '/updateStatus', body)
  }

  updateLastLogin(uid: string){

    let body = {
      uid: uid
    }

    return this.http.put(this.baseUserExtensionUrl + '/updateLastLogin', body)
  }

  login(uid: string){

    let body = {
      uid: uid,
      secret: GlobalConstants.secret
    }

    return this.http.post(this.baseUrl + '/login', body)
  }

  validateUserToPro(uid: string){
    return this.http.put(this.baseUserExtensionUrl + '/validatePro', {uid});
  }

  rejectUserToPro(uid: string, profileImage: string, dossier: string, arrayCertificationFile: string[]){

    let body = {
      uid: uid,
      profileImage: profileImage,
      dossier: dossier,
      arrayCertificationFile: arrayCertificationFile
    }

    return this.http.put(this.baseUserExtensionUrl + '/rejectPro', body);
  }
}

<div class="score-section" id="contenido">
  <div class="alert alert-success" role="alert" *ngIf="updated">
   {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_UPDATED' | translate}}
  </div>

    <div class=" row" *ngIf="isUserLogged" >
      <div class="col-md-1">
        <button (click)="goBack()" class="p-2 rounded-button" [hidden]="isFavoriteBuilding">
          <span class="button-back"> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUTTON_BACK' | translate}}</span>
        </button>
      </div>
      <div class="col-md-8">

      </div>
      <div class="col-md-3 row" style="text-align: right;">
        <div *ngIf="isFavoriteBuilding && !updateBuilding">
          <button (click)="SavePDF()" class="p-2 rounded-button">
            <i class="fa fa-download" aria-hidden="true"></i> {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUTTON_PRINT' | translate}}
          </button>&nbsp;
        </div>
        <div *ngIf="isFavoriteBuilding && !updateBuilding">
          <button (click)="goBackToupdateBuildingInformation()" class="p-2 rounded-button">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i> {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUTTON_UPDATE' | translate}}
          </button>
        </div>
        <div *ngIf="isFavoriteBuilding && !updateBuilding" class="p-2 container-rounded">
          <i class="fa fa-bookmark" aria-hidden="true"></i> {{'ONE_CLICK_PAGE.SCORE_BUILDING.SAVED' | translate}}
        </div>
        <div *ngIf="updateBuilding">
                  <button (click)="updateBuildingInformation()" class="p-2 rounded-button">
                    <i class="fa fa-bookmark-o" aria-hidden="true"></i> {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUTTON_SAVE' | translate}}
                  </button>
        </div>
        <div *ngIf="!isFavoriteBuilding ">
          <button (click)="saveBuilding()" class="p-2 rounded-button" >
            <i class="fa fa-bookmark-o" aria-hidden="true"></i> {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUTTON_SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>
    <!--div class="d-flex flex-row-reverse" *ngIf="isUserLogged" >
        <div *ngIf="updateBuilding">
          <button (click)="updateBuildingInformation()" class="p-2 rounded-button">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Update
          </button>
        </div>
        <div *ngIf="isFavoriteBuilding" class="p-2 container-rounded">
          <i class="fa fa-bookmark" aria-hidden="true"></i> Saved
        </div>

        <div *ngIf="!isFavoriteBuilding">
          <button (click)="saveBuilding()" class="p-2 rounded-button" >
            <i class="fa fa-bookmark-o" aria-hidden="true"></i> Save
          </button>
        </div>
    </div-->
  <div class="d-flex bd-highlight">
    <div class="p-2 flex-fill bd-highlight" [hidden]="building.properties.length < 1">
      <div [hidden]="building.properties.length < 1">
        <img class="img-thumbnail" alt="Facade image" [src]='building.properties[0].image' *ngIf=" building.properties.length > 0 && building.properties[0].image" width="170" height="170"/>
      </div>
    </div>

    <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'ES'" [hidden]="building.favorite === false">
      <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/ES/{{building.typology.categoryPicCode}}.Gen.ReEx.001.jpg" width="170" height="170">
    </div>

    <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'NL'">
      <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/NL/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
    </div>

    <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'FR'">
      <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/FR/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
    </div>

    <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'GR'">
      <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/GR/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
    </div>

    <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'IT'">
      <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/IT/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
    </div>

    <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'SI'">
      <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/SI/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
    </div>

    <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'BG'">
      <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/BG/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
    </div>

    <div  class="p-2 flex-fill bd-highlight title-information" >
      <h5 [hidden]="!building.region"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.PROVINCE' | translate}}: {{ building.region }} </h5>
      <p> <img src="../../../assets/img/icons/location_icon.svg" alt="Surface" style="width: 16px!important;"> <strong>&nbsp;{{building.address}}</strong>
      </p>
      <div class="d-flex bd-highlight title-information">
        <div class="p-2 flex-fill bd-highlight" *ngIf="building.surface">
          <span><img src="../../../assets/img/icons/square_meters_icon.svg" alt="Surface" style="width: 16px!important;">&nbsp;{{building.surface}}&nbsp; m<sup>2</sup> </span>
        </div>
        <div class="p-2 flex-fill bd-highlight title-information">
          <span><img src="../../../assets/img/icons/year_icon.svg" alt="year" style="width: 16px;">&nbsp;{{building.year}}</span>
        </div>
        </div>
      </div>
    <div  class="p-2 flex-fill bd-highlight title-information" >
      <p *ngIf="building.climateZone == 'ES.ME'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION' | translate}}</strong></p>
      <p *ngIf="building.climateZone == 'FR.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>
      <p *ngIf="building.climateZone == 'SI.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>
      <p *ngIf="building.climateZone == 'BG.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>

      <p *ngIf="building.climateZone == 'GR.ZoneA'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Climatic zone A</strong></p>
      <p *ngIf="building.climateZone == 'GR.ZoneB'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Climatic zone B</strong></p>
      <p *ngIf="building.climateZone == 'GR.ZoneC'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Climatic zone C</strong></p>
      <p *ngIf="building.climateZone == 'GR.ZoneD'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Climatic zone D</strong></p>
      <p *ngIf="building.climateZone == 'GR.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>

      <p *ngIf="building.climateZone == 'IT.MidClim'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Middle Climatic Zone (Zone E)</strong></p>
      <p *ngIf="building.climateZone == 'IT.Alpine'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Alpine Zone (Zone F)</strong></p>
      <p *ngIf="building.climateZone == 'IT.Mediterranean'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Mediterranean Zone</strong></p>

      <p *ngIf="building.country == 'FR'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong>{{building.typology.categoryName}}</strong></p>
      <p *ngIf="building.country == 'GR'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong>{{building.typology.categoryName}}</strong></p>
      <p *ngIf="building.country == 'IT'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong>{{building.typology.categoryName}}</strong></p>
      <p *ngIf="building.country == 'SI'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong>{{building.typology.categoryName}}</strong></p>
      <p *ngIf="building.country == 'BG'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong>{{building.typology.categoryName}}</strong></p>
      <p *ngIf="building.country == 'NL'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong>{{building.typology.categoryName}}</strong></p>

      <p [hidden]="!building.rc">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CADASTRAL_REFERENCE' | translate}}: <strong>{{building.rc}}</strong></p>
      <p [hidden]="!building.use">{{'ONE_CLICK_PAGE.SCORE_BUILDING.PRIMARY_USE' | translate}}: <strong>{{'ONE_CLICK_PAGE.PRIMARY_USE' | translate}}</strong></p>
    </div>

    <div  class="p-2 flex-fill bd-highlight title-information" >
      <div class="row">
        <div class="col-md-7" *ngIf="chartOptionsSummary" style="margin-right: 20px;">
          <highcharts-chart
            [Highcharts]="SummaryChart"
            [options]="chartOptionsSummary" ></highcharts-chart>
        </div>
        <div class="col-md-4">
          <span><img src="../../../assets/img/icons/energy_cost_icon.svg"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.TOTAL_TITLE' | translate}}</span>
          <div style="margin-top: 20px; color: white">
            <div class="rounded-section"  style="background-color: #fd9c6a">
              <h5>{{finalEnergyData.total | number: '1.2-2'}} {{'ONE_CLICK_PAGE.SCORE_BUILDING.TOTAL_TODAY' | translate}}</h5>
            </div>
            <div class="rounded-section" style="background-color: #fac57b">
              <h5>{{finalEnergyDataStd.total | number: '1.2-2'}} % {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalStd' | translate}}</h5>
            </div>
            <div class="rounded-section" style="background-color: #aee279">
              <h5>{{finalEnergyDataAdv.total | number: '1.2-2'}} % {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalAdv' | translate}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Refurbishment section-->
  <div>
    <div class="accordion" id="today">
      <div class="card">
        <div class="card-header header-principal" id="energyEfficiency">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block align-items-center d-flex justify-content-center collapsed button-title" type="button" data-toggle="collapse" data-target="#energySection" aria-expanded="true" aria-controls="energySection">
              <h3>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.TITLE' | translate}}</h3>&nbsp;
            </button>
          </h2>
        </div>

        <div id="energySection" class="collapse show" [ngClass]="" aria-labelledby="energyEfficiency" >
          <nav ngbNav #nav="ngbNav" class="nav-tabs">
          <ng-container ngbNavItem class="me-2">
            <h5>
              <a href="#accountuser" ngbNavLink>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.DEMAND_PER_YEAR' | translate}}</a>
            </h5>
            <ng-template ngbNavContent>
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.TODAY' | translate}}
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.STANDARD' | translate}}</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.ADVANCED' | translate}}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="card-body row">
                      <div class="col-md-4 row" style="border-right: 1px solid #616060;">
                        <div class="col-md-7" *ngIf="chartOptionsDemandActual">
                          <highcharts-chart
                            [Highcharts]="DemandChartActual"
                            [options]="chartOptionsDemandActual" ></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;" >
                            <div class="rounded-section" style="background-color: #f5a9a9">
                              <h5>{{demandData.total | number: '1.2-2'}} {{'ONE_CLICK_PAGE.SCORE_BUILDING.TOTAL_DEMAND' | translate}}</h5>
                            </div>
                            <!--<div class="rounded-section"  style="background-color: #7cb5ec">
                              <h5>{{demandData.other | number: '1.2-2'}}%</h5>
                            </div>-->
                            <div class="rounded-section" style="background-color: #7cb5ec">
                              <h5>{{demandData.heating | number: '1.2-2'}}%</h5>
                            </div>
                            <div class="rounded-section" style="background-color: black">
                              <h5>{{demandData.recovered | number: '1.2-2'}}%</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 row" style="border-right: 1px solid #616060;">
                        <div class="col-md-7"  *ngIf="chartOptionsDemandStd">
                          <highcharts-chart
                            [Highcharts]="DemandChartStd"
                            [options]="chartOptionsDemandStd" ></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;">
                            <div class="rounded-section" style="background-color: #ffbd04">
                              <h5>{{demandDataStd.total | number: '1.2-2'}}% {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalStd' | translate}}</h5>
                            </div>
                            <!--<div class="rounded-section" style="background-color: #7cb5ec">
                              <h5>{{demandDataStd.other | number: '1.2-2'}}% {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalStd' | translate}}</h5>
                            </div>-->
                            <div class="rounded-section" style="background-color: #7cb5ec">
                              <h5>{{demandDataStd.heating | number: '1.2-2'}}% {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalStd' | translate}} </h5>
                            </div>
                            <div class="rounded-section" style="background-color: black">
                              <h5>{{demandDataStd.recovered | number: '1.2-2'}} {{textRecoveredDemandStd}}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 row">
                        <div class="col-md-7"  *ngIf="chartOptionsDemandAdv">
                          <highcharts-chart
                            [Highcharts]="DemandChartAdv"
                            [options]="chartOptionsDemandAdv" ></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;">
                            <div class="rounded-section" style="background-color: #bce39e">
                              <h5>{{demandDataAdv.total | number: '1.2-2'}}% {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalStd' | translate}}</h5>
                            </div>
                            <!--<div class="rounded-section" style="background-color: #7cb5ec">
                              <h5>{{demandDataAdv.other | number: '1.2-2'}}% {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalStd' | translate}}</h5>
                            </div>-->
                            <div class="rounded-section" style="background-color: #7cb5ec">
                              <h5>{{demandDataAdv.heating | number: '1.2-2'}}% {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalStd' | translate}}</h5>
                            </div>
                            <div class="rounded-section" style="background-color: black">
                              <h5>{{demandDataAdv.recovered | number: '1.2-2'}} {{textRecoveredDemandAdv}}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem class="me-2">
            <h5>
              <a href="#my-projects" ngbNavLink>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.FINAL_ENERGY_CONSUMED' | translate}}</a>
            </h5>
            <ng-template ngbNavContent>
                <div id="energyData" class="collapse show" aria-labelledby="energy" >
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.TODAY' | translate}}
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.STANDARD' | translate}}</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.ADVANCED' | translate}}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-4 row" style="border-right: 1px solid #616060;">
                      <div class="col-md-7" *ngIf="chartOptionsFinalEnergyActual">
                        <highcharts-chart
                          [Highcharts]="FinalEnergyChartActual"
                          [options]="chartOptionsFinalEnergyActual" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white">
                          <div class="rounded-section"  style="background-color: #f5a9a9">
                            <h5>{{finalEnergyData.total | number: '1.2-2'}} Total</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7cb5ec">
                            <h5>{{finalEnergyData.fossils | number: '1.2-2'}}%</h5>
                          </div>
                          <div class="rounded-section" style="background-color: black">
                            <h5>{{finalEnergyData.biomass | number: '1.2-2'}}%</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #90ed7d">
                            <h5>{{finalEnergyData.electricity | number: '1.2-2'}}%</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f6a35b">
                            <h5>{{finalEnergyData.district | number: '1.2-2'}}%</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7f85e9">
                            <h5>{{finalEnergyData.other | number: '1.2-2'}}%</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f15c80">
                            <h5>{{finalEnergyData.produced | number: '1.2-2'}}%</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 row" style="border-right: 1px solid #616060;">
                      <div class="col-md-7" *ngIf="chartOptionsFinalEnergyStd">
                        <highcharts-chart
                          [Highcharts]="FinalEnergyChartStd"
                          [options]="chartOptionsFinalEnergyStd" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white;">
                          <div class="rounded-section" style="background-color: #ffbd04">
                            <h5>{{finalEnergyDataStd.total | number: '1.2-2'}} {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalStd' | translate}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7cb5ec">
                            <h5>{{finalEnergyDataStd.fossils | number: '1.2-2'}} {{textFossilStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: black">
                            <h5>{{finalEnergyDataStd.biomass | number: '1.2-2'}} {{textBiomassStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #90ed7d">
                            <h5>{{finalEnergyDataStd.electricity | number: '1.2-2'}} {{textElectricityStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f6a35b">
                            <h5>{{finalEnergyDataStd.district | number: '1.2-2'}} {{textDistrictStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7f85e9">
                            <h5>{{finalEnergyDataStd.other | number: '1.2-2'}} {{textOtherStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f15c80">
                            <h5>{{finalEnergyDataStd.produced | number: '1.2-2'}} {{textProducedStd}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 row">
                      <div class="col-md-7" *ngIf="chartOptionsFinalEnergyAdv">
                        <highcharts-chart
                          [Highcharts]="FinalEnergyChartAdv"
                        [options]="chartOptionsFinalEnergyAdv" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white;">
                          <div class="rounded-section" style="background-color: #bce39e">
                            <h5>{{finalEnergyDataAdv.total | number: '1.2-2'}} {{'ONE_CLICK_PAGE.SCORE_BUILDING.textTotalAdv' | translate}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7cb5ec">
                            <h5>{{finalEnergyDataAdv.fossils | number: '1.2-2'}}{{textFossilAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: black">
                            <h5>{{finalEnergyDataAdv.biomass | number: '1.2-2'}} {{textBiomassAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #90ed7d">
                            <h5>{{finalEnergyDataAdv.electricity | number: '1.2-2'}} {{textElectricityAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f6a35b">
                            <h5>{{finalEnergyDataAdv.district | number: '1.2-2'}} {{textDistrictAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7f85e9">
                            <h5>{{finalEnergyDataAdv.other | number: '1.2-2'}} {{textOtherAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f15c80">
                            <h5>{{finalEnergyDataAdv.produced | number: '1.2-2'}} {{textProducedAdv}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem class="me-2">
            <h5>
              <a href="#saves-properties" ngbNavLink>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.CO2_EMISSIONS' | translate}} <!--CO<sub>2</sub> emissions per year nRE PE--></a>
            </h5>
            <ng-template ngbNavContent>
                <div id="emissionsData" class="collapse show" aria-labelledby="emissions" >
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.TODAY' | translate}}
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.STANDARD' | translate}}</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.ADVANCED' | translate}}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-4 ">
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3" style="background-color: grey;">
                          <h4>
                            {{co2EmissionsActual.co2.value | number: '1.2-2'}}
                          </h4>
                        </div>
                        <div class="col-md-9 arrow-box-today">
                          <h4>
                            kgCO<sub>2</sub>eq/m<sup>2</sup>y
                          </h4>
                        </div>
                      </div>
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: #e7e7e7; color: grey;">
                          <h4>
                            {{co2EmissionsActual.nre.value | number: '1.2-2'}}
                          </h4>
                        </div>
                        <div class="col-md-9 arrow-box-today">
                          <h4>
                            kWh/m<sup>2</sup>y
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: grey;">
                          <h4>
                            {{co2EmissionsStd.co2.value | number: '1.2-2'}}
                          </h4>
                        </div>
                        <div class="col-md-9 arrow-box-standard">
                          <h4>
                            % {{'ONE_CLICK_PAGE.SCORE_BUILDING.REDUCTION' | translate}}
                          </h4>
                        </div>
                      </div>
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: #e7e7e7; color: grey;">
                          <h4>
                            {{co2EmissionsStd.nre.value | number: '1.2-2'}}
                          </h4>
                        </div>
                        <div class="col-md-9 arrow-box-standard">
                          <h4>
                            % {{'ONE_CLICK_PAGE.SCORE_BUILDING.REDUCTION' | translate}}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: grey;">
                          <h4>
                            {{co2EmissionsAdv.co2.value | number: '1.2-2'}}
                          </h4>
                        </div>
                        <div class="col-md-9 arrow-box-advanced">
                          <h4>
                            % {{'ONE_CLICK_PAGE.SCORE_BUILDING.REDUCTION' | translate}}
                          </h4>
                        </div>
                      </div>
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: #e7e7e7; color: grey;">
                          <h4>
                            {{co2EmissionsAdv.nre.value | number: '1.2-2'}}
                          </h4>
                        </div>
                        <div class="col-md-9 arrow-box-advanced">
                          <h4>
                            % {{'ONE_CLICK_PAGE.SCORE_BUILDING.REDUCTION' | translate}}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem class="me-2">
            <h5>
              <a href="#saved-tools" ngbNavLink>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.RES' | translate}}</a>
            </h5>
            <ng-template ngbNavContent>
                  <div id="resData" class="collapse show" aria-labelledby="res" >
                    <div class="row" style="margin: 0;">
                      <div class="col-md-4 ">
                        <div class="titleSectionToday" style="margin-right: 10px;">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.TODAY' | translate}}
                          </h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionStandard" style="margin-right: 10px;">
                          <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.STANDARD' | translate}}</h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionAdvanced">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.ADVANCED' | translate}}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="card-body row">
                      <div class="col-md-4 row" style="border-right: 1px solid #616060;">
                        <div class="col-md-7" *ngIf="chartOptionsResActual">
                          <highcharts-chart
                            [Highcharts]="ResChartActual"
                            [options]="chartOptionsResActual" style="margin-left: -550px;"></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;">
                            <div class="rounded-section" style="background-color: #f5a9a9">
                              <h3>{{textResActual.res}}</h3>
                            </div>
                            <div class="rounded-section">
                              <h3>{{textResActual.other}}</h3>
                            </div>
                          </div>
                        </div>
  
                      </div>
                      <div class="col-md-4 row" style="border-right: 1px solid #616060;">
                        <div class="col-md-7" *ngIf="chartOptionsResStd">
                          <highcharts-chart
                            [Highcharts]="ResChartStd"
                            [options]="chartOptionsResStd"></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;">
                            <div class="rounded-section" style="background-color: #ffbd04">
                              <h3>{{textResStd}}</h3>
                            </div>
                          </div>
                        </div>
  
                      </div>
                      <div class="col-md-4 row">
                        <div class="col-md-7" *ngIf="chartOptionsResAdv">
                          <highcharts-chart
                            [Highcharts]="ResChartAdv"
                            [options]="chartOptionsResAdv" ></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;">
                            <div class="rounded-section" style="background-color: #bce39e">
                              <h3>{{textResAdv}}</h3>
                            </div>
                          </div>
                        </div>
  
                      </div>
                    </div>
                  </div>
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem class="me-2">
            <h5>
              <a href="#reviews" ngbNavLink>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.ENERGY_COST' | translate}}</a>
            </h5>
            <ng-template ngbNavContent>
                  <div id="costData" class="collapse show" aria-labelledby="cost" >
                    <div class="row" style="margin: 0;">
                      <div class="col-md-4 ">
                        <div class="titleSectionToday" style="margin-right: 10px;">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.TODAY' | translate}}
                          </h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionStandard" style="margin-right: 10px;">
                          <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.STANDARD' | translate}}</h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionAdvanced">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.ENERGY_EFFICIENCY.ADVANCED' | translate}}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="card-body row">
                      <div class="col-md-4 ">
                        <div class="rounded-container" style="background-color: #f5a9a9;">
                          <h3>{{energyCost | number: '1.2-2'}}€</h3>
                        </div>
                      </div>
                      <div class="col-md-4 row" style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                        <div class="col-md-6">
                          <div class="rounded-container2" style="background-color: #ffbd04;">
                            <h4>{{energyCostStd.value | number: '1.2-2'}}€</h4>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="rounded-section" style="background-color: #b3b0b0;">
                            <h3>{{energyCostStd.text}}</h3>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 row" style="padding-left: 50px;">
                        <div class="col-md-6">
                          <div class="rounded-container3  col-md-6" style="background-color: #bce39e;">
                            <h5>{{energyCostAdv.value | number: '1.2-2'}}€</h5>
                          </div>
                        </div>
                        <div class="col-md-6" >
                          <div class="rounded-section" style="background-color: #b3b0b0;">
                            <h3>{{energyCostAdv.text}}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>

        <div class="card ">
          <div class="card-header header-principal" id="buildingData">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block collapsed align-items-center d-flex justify-content-center button-title" type="button" data-toggle="collapse" data-target="#components" aria-expanded="false" aria-controls="components">
                <h3>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.TITLE' | translate}}</h3>&nbsp;
              </button>
            </h2>
          </div>
  
          <div id="components" class="collapse" aria-labelledby="buildingData">
            <nav ngbNav #nav2="ngbNav" class="nav-tabs">
              <ng-container ngbNavItem class="me-2">
                      <h5 class="mb-0">
                        <a href="#my-projects" ngbNavLink><img src="../../../assets/img/icons/floor_icon.svg"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.FLOOR' | translate}}</a>
                      </h5>
                      <ng-template ngbNavContent>
                        <div class="row" style="margin: 0;">
                          <div class="col-md-4 ">
                            <div class="titleSectionToday" style="margin-right: 10px;">
                              <h2>
                                {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.TODAY' | translate}}
                              </h2>
                            </div>
                          </div>
                          <div class="col-md-4 ">
                            <div class="titleSectionStandard" style="margin-right: 10px;">
                              <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.STANDARD' | translate}}</h2>
                            </div>
                          </div>
                          <div class="col-md-4 ">
                            <div class="titleSectionAdvanced">
                              <h2>
                                {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.ADVANCED' | translate}}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="card-body row"  style="margin-left: 0;">
                          <div class="col-md-4 ">
                            <div class="row" *ngIf="envelopedToday.floor">
                              <div class="col">
                                <img src="./assets/img/envelope/{{building.country}}/{{envelopedToday.floor.picture}}" width="240" height="170"/>
                              </div>
                              <div class="col">
                                <h6 *ngIf="!currentUser || currentUser.country != building.country">
                                  {{envelopedToday.floor.typeConstruction}}
                                </h6>
                                <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                                  {{envelopedToday.floor.typeConstructionOriginal}}
                                </h6>
                                <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                  {{envelopedToday.floor.description}}
                                </div>
                                <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                  {{envelopedToday.floor.originalDescription}}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                            <div class="row" *ngIf="envelopedStandard.floor">
                              <div class="col">
                                <img src="./assets/img/envelope/{{building.country}}/{{envelopedStandard.floor.picture}}" width="240" height="170"/>
                              </div>
                              <div class="col">
                                <h6 *ngIf="!currentUser || currentUser.country != building.country">
                                  {{envelopedStandard.floor.typeConstruction}}
                                </h6>
                                <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                                  {{envelopedStandard.floor.typeConstructionOriginal}}
                                </h6>
                                <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                  {{envelopedStandard.floor.description}}
                                </div>
                                <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                  {{envelopedStandard.floor.originalDescription}}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 ">
                            <div class="row" *ngIf="envelopedAdvanced.floor">
                              <div class="col">
                                <img src="./assets/img/envelope/{{building.country}}/{{envelopedAdvanced.floor.picture}}" width="240" height="170"/>
                              </div>
                              <div class="col">
                                <h6 *ngIf="!currentUser || currentUser.country != building.country">
                                  {{envelopedAdvanced.floor.typeConstruction}}
                                </h6>
                                <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                                  {{envelopedAdvanced.floor.typeConstructionOriginal}}
                                </h6>
                                <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                  {{envelopedAdvanced.floor.description}}
                                </div>
                                <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                  {{envelopedAdvanced.floor.originalDescription}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                </ng-template>
              </ng-container>
              <ng-container ngbNavItem class="me-2">
                <h5 class="mb-0">
                  <a href="#my-projects" ngbNavLink><img src="../../../assets/img/icons/roof_icon.svg"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.ROOF' | translate}}</a>
                </h5>
                <ng-template ngbNavContent>
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.TODAY' | translate}}
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.STANDARD' | translate}}</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.ADVANCED' | translate}}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row" style="margin-left: 0;">
                    <div class="col-md-4 ">
                      <div class="row" *ngIf="envelopedToday.roof">
                        <div class="col">
                          <img src="./assets/img/envelope/{{building.country}}/{{envelopedToday.roof.picture}}" width="240" height="170"/>
                        </div>
                        <div class="col">
                          <h6 *ngIf="!currentUser || currentUser.country != building.country">
                            {{envelopedToday.roof.typeConstruction}}
                          </h6>
                          <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                            {{envelopedToday.roof.typeConstructionOriginal}}
                          </h6>
                          <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                            {{envelopedToday.roof.description}}
                          </div>
                          <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                            {{envelopedToday.roof.originalDescription}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                      <div class="row" *ngIf="envelopedStandard.roof">
                        <div class="col">
                          <img src="./assets/img/envelope/{{building.country}}/{{envelopedStandard.roof.picture}}" width="240" height="170"/>
                        </div>
                        <div class="col">
                          <h6 *ngIf="!currentUser || currentUser.country != building.country">
                            {{envelopedStandard.roof.typeConstruction}}
                          </h6>
                          <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                            {{envelopedStandard.roof.typeConstructionOriginal}}
                          </h6>
                          <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                            {{envelopedStandard.roof.description}}
                          </div>
                          <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                            {{envelopedStandard.roof.originalDescription}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="row" *ngIf="envelopedAdvanced.roof">
                        <div class="col">
                          <img src="./assets/img/envelope/{{building.country}}/{{envelopedAdvanced.roof.picture}}" width="240" height="170"/>
                        </div>
                        <div class="col">
                          <h6 *ngIf="!currentUser || currentUser.country != building.country">
                            {{envelopedAdvanced.roof.typeConstruction}}
                          </h6>
                          <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                            {{envelopedAdvanced.roof.typeConstructionOriginal}}
                          </h6>
                          <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                            {{envelopedAdvanced.roof.description}}
                          </div>
                          <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                            {{envelopedAdvanced.roof.originalDescription}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container ngbNavItem class="me-2">
                <h5 class="mb-0">
                  <a href="#my-projects" ngbNavLink><img src="../../../assets/img/icons/wall_icon.svg"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.WALL' | translate}}</a>
                </h5>
                <ng-template ngbNavContent>
                    <div class="row" style="margin: 0;">
                      <div class="col-md-4 ">
                        <div class="titleSectionToday" style="margin-right: 10px;">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.TODAY' | translate}}
                          </h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionStandard" style="margin-right: 10px;">
                          <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.STANDARD' | translate}}</h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionAdvanced">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.ADVANCED' | translate}}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="card-body row" style="margin-left: 0;">
                      <div class="col-md-4 ">
                        <div class="row" *ngIf="envelopedToday.wall">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedToday.wall.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <h6 *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedToday.wall.typeConstruction}}
                            </h6>
                            <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedToday.wall.typeConstructionOriginal}}
                            </h6>
                            <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedToday.wall.description}}
                            </div>
                            <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedToday.wall.originalDescription}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                        <div class="row" *ngIf="envelopedStandard.wall">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedStandard.wall.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <h6 *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedStandard.wall.typeConstruction}}
                            </h6>
                            <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedStandard.wall.typeConstructionOriginal}}
                            </h6>
                            <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedStandard.wall.description}}
                            </div>
                            <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedStandard.wall.originalDescription}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="row" *ngIf="envelopedAdvanced.wall">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedAdvanced.wall.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <h6 *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedAdvanced.wall.typeConstruction}}
                            </h6>
                            <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedAdvanced.wall.typeConstructionOriginal}}
                            </h6>
                            <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedAdvanced.wall.description}}
                            </div>
                            <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedAdvanced.wall.originalDescription}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </ng-template>
              </ng-container>
              <ng-container ngbNavItem class="me-2">
                <h5 class="mb-0">
                  <a href="#my-projects" ngbNavLink><img src="../../../assets/img/icons/window_icon.svg"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.WINDOW' | translate}}</a>
                </h5>
                <ng-template ngbNavContent>
                    <div class="row" style="margin: 0;">
                      <div class="col-md-4 ">
                        <div class="titleSectionToday" style="margin-right: 10px;">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.TODAY' | translate}}
                          </h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionStandard" style="margin-right: 10px;">
                          <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.STANDARD' | translate}}</h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionAdvanced">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.ADVANCED' | translate}}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="card-body row" style="margin-left: 0;">
                      <div class="col-md-4 ">
                        <div class="row" *ngIf="envelopedToday.window">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedToday.window.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <h6 *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedToday.window.typeConstruction}}
                            </h6>
                            <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedToday.window.typeConstructionOriginal}}
                            </h6>
                            <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedToday.window.description}}
                            </div>
                            <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedToday.window.originalDescription}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                        <div class="row" *ngIf="envelopedStandard.window">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedStandard.window.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <h6 *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedStandard.window.typeConstruction}}
                            </h6>
                            <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedStandard.window.typeConstructionOriginal}}
                            </h6>
                            <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedStandard.window.description}}
                            </div>
                            <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedStandard.window.originalDescription}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="row" *ngIf="envelopedAdvanced.window">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedAdvanced.window.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <h6 *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedAdvanced.window.typeConstruction}}
                            </h6>
                            <h6 *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedAdvanced.window.typeConstructionOriginal}}
                            </h6>
                            <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                              {{envelopedAdvanced.window.description}}
                            </div>
                            <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                              {{envelopedAdvanced.window.originalDescription}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </ng-template>
              </ng-container>
              <!--<ng-container ngbNavItem class="me-2">
                <h5 class="mb-0">
                  <a href="#my-projects" ngbNavLink>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.DOOR' | translate}}</a>
                </h5>
                <ng-template ngbNavContent>
                    <div class="row" style="margin: 0;">
                      <div class="col-md-4 ">
                        <div class="titleSectionToday" style="margin-right: 10px;">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.TODAY' | translate}}
                          </h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionStandard" style="margin-right: 10px;">
                          <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.STANDARD' | translate}}</h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionAdvanced">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.ADVANCED' | translate}}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="card-body row" style="margin-left: 0;">
                      <div class="col-md-4 ">
                        <div class="row" *ngIf="envelopedToday.door">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedToday.door.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <p>
                              {{envelopedToday.door.typeConstruction}}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                        <div class="row" *ngIf="envelopedStandard.door">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedStandard.door.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <p>
                              {{envelopedStandard.door.typeConstruction}}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="row" *ngIf="envelopedAdvanced.door">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedAdvanced.door.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <p>
                              {{envelopedAdvanced.door.typeConstruction}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                </ng-template>
              </ng-container>
              <ng-container ngbNavItem class="me-2">
                <h5 class="mb-0">
                  <a href="#my-projects" ngbNavLink>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.CELLING' | translate}}</a>
                </h5>
                <ng-template ngbNavContent>
                    <div class="row" style="margin: 0;">
                      <div class="col-md-4 ">
                        <div class="titleSectionToday" style="margin-right: 10px;">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.TODAY' | translate}}
                          </h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionStandard" style="margin-right: 10px;">
                          <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.STANDARD' | translate}}</h2>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="titleSectionAdvanced">
                          <h2>
                            {{'ONE_CLICK_PAGE.SCORE_BUILDING.BUILDING_DATA.ADVANCED' | translate}}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div class="card-body row" style="margin-left: 0;">
                      <div class="col-md-4 " >
                        <div class="row" *ngIf="envelopedToday.celling">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedToday.celling.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <p>
                              {{envelopedToday.celling.typeConstruction}}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                        <div class="row" *ngIf="envelopedStandard.celling">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedStandard.celling.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <p>
                              {{envelopedStandard.celling.typeConstruction}}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 ">
                        <div class="row" *ngIf="envelopedAdvanced.celling">
                          <div class="col">
                            <img src="./assets/img/envelope/{{building.country}}/{{envelopedAdvanced.celling.picture}}" width="240" height="170"/>
                          </div>
                          <div class="col">
                            <p>
                              {{envelopedAdvanced.celling.typeConstruction}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                </ng-template>
              </ng-container>-->
            </nav>
            <div [ngbNavOutlet]="nav2" class="mt-2"></div>
          </div>

          <div class="card">
            <div class="card-header  header-principal" id="systemData">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block align-items-center d-flex justify-content-center collapsed button-title" type="button" data-toggle="collapse" data-target="#systems" aria-expanded="false" aria-controls="systems">
                  <h3>{{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.TITLE' | translate}}</h3>&nbsp;
                </button>
              </h2>
            </div>
    
            <div id="systems" class="collapse" aria-labelledby="systemData">
                      <nav ngbNav #nav3="ngbNav" class="nav-tabs">
                        <ng-container ngbNavItem class="me-2">
                          <h5 class="mb-0">
                            <a href="#my-projects" ngbNavLink><img src="../../../assets/img/icons/heating_icon.svg"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.HEATING' | translate}}</a>
                          </h5>
                          <ng-template ngbNavContent>
                            <div class="row" style="margin: 0;">
                              <div class="col-md-4 ">
                                <div class="titleSectionToday" style="margin-right: 10px;">
                                  <h2>
                                    {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.TODAY' | translate}}
                                  </h2>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="titleSectionStandard" style="margin-right: 10px;">
                                  <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.STANDARD' | translate}}</h2>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="titleSectionAdvanced">
                                  <h2>
                                    {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.ADVANCED' | translate}}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div class="card-body row">
                              <div class="card-body row" style="margin-left: 0;">
                                <div class="col-md-4 ">
                                  <div class="row" *ngIf="systemsToday.heating">
                                    <div class="col">
                                      <img src="./assets/img/system/{{building.country}}/{{systemsToday.heating.picture}}" width="240" height="170"/>
                                    </div>
                                    <div class="col">
                                      <h6>
                                        {{systemsToday.heating.systemName}}
                                      </h6>
                                      <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                        {{systemsToday.heating.description}}
                                      </div>
                                      <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                        {{systemsToday.heating.descriptionOriginal}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                                  <div class="row" *ngIf="systemsStandard.heating">
                                    <div class="col">
                                      <img src="./assets/img/system/{{building.country}}/{{systemsStandard.heating.picture}}" width="240" height="170"/>
                                    </div>
                                    <div class="col">
                                      <h6>
                                        {{systemsStandard.heating.systemName}}
                                      </h6>
                                      <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                        {{systemsStandard.heating.description}}
                                      </div>
                                      <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                        {{systemsStandard.heating.descriptionOriginal}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4 ">
                                  <div class="row" *ngIf="systemsAdvanced.heating">
                                    <div class="col">
                                      <img src="./assets/img/system/{{building.country}}/{{systemsAdvanced.heating.picture}}" width="240" height="170"/>
                                    </div>
                                    <div class="col">
                                      <h6>
                                        {{systemsAdvanced.heating.systemName}}
                                      </h6>
                                      <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                        {{systemsAdvanced.heating.description}}
                                      </div>
                                      <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                        {{systemsAdvanced.heating.descriptionOriginal}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                        <ng-container ngbNavItem class="me-2">
                          <h5 class="mb-0">
                            <a href="#my-projects" ngbNavLink><img src="../../../assets/img/icons/water_icon.svg"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.WATER' | translate}}</a>
                          </h5>
                          <ng-template ngbNavContent>
                            <div class="row" style="margin: 0;">
                              <div class="col-md-4 ">
                                <div class="titleSectionToday" style="margin-right: 10px;">
                                  <h2>
                                    {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.TODAY' | translate}}
                                  </h2>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="titleSectionStandard" style="margin-right: 10px;">
                                  <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.STANDARD' | translate}}</h2>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="titleSectionAdvanced">
                                  <h2>
                                    {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.ADVANCED' | translate}}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div class="card-body row"  style="margin-left: 0;">
                              <div class="col-md-4 ">
                                <div class="row" *ngIf="systemsToday.water">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsToday.water.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      {{systemsToday.water.systemName}}
                                    </h6>
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsToday.water.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsToday.water.descriptionOriginal}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                                <div class="row" *ngIf="systemsStandard.water">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsStandard.water.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      {{systemsStandard.water.systemName}}
                                    </h6>
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsStandard.water.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsStandard.water.descriptionOriginal}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="row" *ngIf="systemsAdvanced.water">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsAdvanced.water.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      {{systemsAdvanced.water.systemName}}
                                    </h6>
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsAdvanced.water.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsAdvanced.water.description}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                        <ng-container ngbNavItem class="me-2">
                          <h5 class="mb-0">
                            <a href="#my-projects" ngbNavLink><img src="../../../assets/img/icons/ventilation_icon.svg"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.VENTILATION' | translate}}</a>
                          </h5>
                          <ng-template ngbNavContent>
                            <div class="row" style="margin: 0;">
                              <div class="col-md-4 ">
                                <div class="titleSectionToday" style="margin-right: 10px;">
                                  <h2>
                                    {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.TODAY' | translate}}
                                  </h2>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="titleSectionStandard" style="margin-right: 10px;">
                                  <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.STANDARD' | translate}}</h2>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="titleSectionAdvanced">
                                  <h2>
                                    {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.ADVANCED' | translate}}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div class="card-body row" style="margin-left: 0;">
                              <div class="col-md-4 ">
                                <div class="row" *ngIf="systemsToday.ventilation">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsToday.ventilation.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      {{systemsToday.ventilation.systemName}}
                                    </h6>
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsToday.ventilation.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsToday.ventilation.descriptionOriginal}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                                <div class="row" *ngIf="systemsStandard.ventilation">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsStandard.ventilation.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      {{systemsStandard.ventilation.systemName}}
                                    </h6>
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsStandard.ventilation.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsStandard.ventilation.descriptionOriginal}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="row" *ngIf="systemsAdvanced.ventilation">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsAdvanced.ventilation.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      {{systemsAdvanced.ventilation.systemName}}
                                    </h6>
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsAdvanced.ventilation.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsAdvanced.ventilation.descriptionOriginal}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                        <ng-container ngbNavItem class="me-2">
                          <h5 class="mb-0">
                            <a href="#my-projects" ngbNavLink><img src="../../../assets/img/icons/solar-panel-icon.png"> {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.PV' | translate}}</a>
                          </h5>
                          <ng-template ngbNavContent>
                            <div class="row" style="margin: 0;">
                              <div class="col-md-4 ">
                                <div class="titleSectionToday" style="margin-right: 10px;">
                                  <h2>
                                    {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.TODAY' | translate}}
                                  </h2>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="titleSectionStandard" style="margin-right: 10px;">
                                  <h2>{{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.STANDARD' | translate}}</h2>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="titleSectionAdvanced">
                                  <h2>
                                    {{'ONE_CLICK_PAGE.SCORE_BUILDING.SYSTEM_DATA.ADVANCED' | translate}}
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div class="card-body row" style="margin-left: 0;">
                              <div class="col-md-4 " >
                                <div class="row" *ngIf="systemsToday.pv">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsToday.pv.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsToday.pv.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsToday.pv.descriptionOriginal}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                                <div class="row" *ngIf="systemsStandard.pv">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsStandard.pv.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsStandard.pv.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsStandard.pv.descriptionOriginal}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 ">
                                <div class="row" *ngIf="systemsAdvanced.pv">
                                  <div class="col">
                                    <img src="./assets/img/system/{{building.country}}/{{systemsAdvanced.pv.picture}}" width="240" height="170"/>
                                  </div>
                                  <div class="col">
                                    <h6>
                                      {{systemsAdvanced.pv.systemName}}
                                    </h6>
                                    <div style="text-align: justify;" *ngIf="!currentUser || currentUser.country != building.country">
                                      {{systemsAdvanced.pv.description}}
                                    </div>
                                    <div style="text-align: justify;" *ngIf="currentUser != null && currentUser.country == building.country">
                                      {{systemsAdvanced.pv.descriptionOriginal}}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                      </nav>
                      <div [ngbNavOutlet]="nav3" class="mt-2"></div>
              </div>
          </div>

        <!--<div id="energySection" class="collapse show" aria-labelledby="energyEfficiency">
          <div class="card-body">
            <div class="accordion" id="charts">
              <div class="card">
                <div class="card-header" id="demand">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#demandData" aria-expanded="false" aria-controls="demandData">
                      Demand per year
                    </button>
                  </h2>
                </div>

                <div id="demandData" class="collapse show" [ngClass]="" aria-labelledby="demand" >
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          Today
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>Standard</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          Advanced
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="card-body row">
                      <div class="col-md-4 row">
                        <div class="col-md-7" *ngIf="chartOptionsDemandActual">
                          <highcharts-chart
                            [Highcharts]="DemandChartActual"
                            [options]="chartOptionsDemandActual" ></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;">
                            <div class="rounded-section" style="background-color: #f5a9a9">
                              <h5>{{demandData.total | number: '1.2-2'}} Total Demand</h5>
                            </div>
                            <div class="rounded-section"  style="background-color: #7cb5ec">
                              <h5>{{demandData.other | number: '1.2-2'}}% Other system demand</h5>
                            </div>
                            <div class="rounded-section" style="background-color: black">
                              <h5>{{demandData.heating | number: '1.2-2'}}% Heating demand</h5>
                            </div>
                            <div class="rounded-section" style="background-color: #90ed7d">
                              <h5>{{demandData.recovered | number: '1.2-2'}}% Recovered Heating</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 row" style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                        <div class="col-md-7"  *ngIf="chartOptionsDemandStd">
                          <highcharts-chart
                            [Highcharts]="DemandChartStd"
                            [options]="chartOptionsDemandStd" ></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;">
                            <div class="rounded-section" style="background-color: #ffbd04">
                              <h5>{{demandDataStd.total | number: '1.2-2'}} {{textTotalDemandStd}}</h5>
                            </div>
                            <div class="rounded-section" style="background-color: #7cb5ec">
                              <h5>{{demandDataStd.other | number: '1.2-2'}} {{textOtherDemandStd}}</h5>
                            </div>
                            <div class="rounded-section" style="background-color: black">
                              <h5>{{demandDataStd.heating | number: '1.2-2'}} {{textHeatingDemandStd}} </h5>
                            </div>
                            <div class="rounded-section" style="background-color: #90ed7d">
                              <h5>{{demandDataStd.recovered | number: '1.2-2'}} {{textRecoveredDemandStd}}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 row">
                        <div class="col-md-7"  *ngIf="chartOptionsDemandAdv">
                          <highcharts-chart
                            [Highcharts]="DemandChartAdv"
                            [options]="chartOptionsDemandAdv" ></highcharts-chart>
                        </div>
                        <div class="col-md-5">
                          <div style="margin-top: 20px; color: white;">
                            <div class="rounded-section" style="background-color: #bce39e">
                              <h5>{{demandDataAdv.total | number: '1.2-2'}} {{textTotalDemandAdv}}</h5>
                            </div>
                            <div class="rounded-section" style="background-color: #7cb5ec">
                              <h5>{{demandDataAdv.other | number: '1.2-2'}} {{textOtherDemandAdv}}</h5>
                            </div>
                            <div class="rounded-section" style="background-color: black">
                              <h5>{{demandDataAdv.heating | number: '1.2-2'}} {{textHeatingDemandAdv}}</h5>
                            </div>
                            <div class="rounded-section" style="background-color: #90ed7d">
                              <h5>{{demandDataAdv.recovered | number: '1.2-2'}}{{textRecoveredDemandAdv}}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="energy">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#energyData" aria-expanded="false" aria-controls="energyData">
                      Final energy consumed
                    </button>
                  </h2>
                </div>

                <div id="energyData" class="collapse show" aria-labelledby="energy" >
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          Today
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>Standard</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          Advanced
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-4 row">
                      <div class="col-md-7" *ngIf="chartOptionsFinalEnergyActual">
                        <highcharts-chart
                          [Highcharts]="FinalEnergyChartActual"
                          [options]="chartOptionsFinalEnergyActual" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white">
                          <div class="rounded-section"  style="background-color: #f5a9a9">
                            <h5>{{finalEnergyData.total | number: '1.2-2'}} Total</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7cb5ec">
                            <h5>{{finalEnergyData.fossils | number: '1.2-2'}}% Fossils</h5>
                          </div>
                          <div class="rounded-section" style="background-color: black">
                            <h5>{{finalEnergyData.biomass | number: '1.2-2'}}% Biomass</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #90ed7d">
                            <h5>{{finalEnergyData.electricity | number: '1.2-2'}}% Electricity</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f6a35b">
                            <h5>{{finalEnergyData.district | number: '1.2-2'}}% District Heating</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7f85e9">
                            <h5>{{finalEnergyData.other | number: '1.2-2'}}% Other</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f15c80">
                            <h5>{{finalEnergyData.produced | number: '1.2-2'}}% Produced electricity</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 row" style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                      <div class="col-md-7" *ngIf="chartOptionsFinalEnergyStd">
                        <highcharts-chart
                          [Highcharts]="FinalEnergyChartStd"
                          [options]="chartOptionsFinalEnergyStd" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white;">
                          <div class="rounded-section" style="background-color: #ffbd04">
                            <h5>{{finalEnergyDataStd.total | number: '1.2-2'}} {{textTotalStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7cb5ec">
                            <h5>{{finalEnergyDataStd.fossils | number: '1.2-2'}} {{textFossilStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: black">
                            <h5>{{finalEnergyDataStd.biomass | number: '1.2-2'}} {{textBiomassStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #90ed7d">
                            <h5>{{finalEnergyDataStd.electricity | number: '1.2-2'}} {{textElectricityStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f6a35b">
                            <h5>{{finalEnergyDataStd.district | number: '1.2-2'}} {{textDistrictStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7f85e9">
                            <h5>{{finalEnergyDataStd.other | number: '1.2-2'}} {{textOtherStd}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f15c80">
                            <h5>{{finalEnergyDataStd.produced | number: '1.2-2'}} {{textProducedStd}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 row">
                      <div class="col-md-7" *ngIf="chartOptionsFinalEnergyAdv">
                        <highcharts-chart
                          [Highcharts]="FinalEnergyChartAdv"
                        [options]="chartOptionsFinalEnergyAdv" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white;">
                          <div class="rounded-section" style="background-color: #bce39e">
                            <h5>{{finalEnergyDataAdv.total | number: '1.2-2'}} {{textTotalAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7cb5ec">
                            <h5>{{finalEnergyDataAdv.fossils | number: '1.2-2'}}{{textFossilAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: black">
                            <h5>{{finalEnergyDataAdv.biomass | number: '1.2-2'}} {{textBiomassAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #90ed7d">
                            <h5>{{finalEnergyDataAdv.electricity | number: '1.2-2'}} {{textElectricityAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f6a35b">
                            <h5>{{finalEnergyDataAdv.district | number: '1.2-2'}} {{textDistrictAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #7f85e9">
                            <h5>{{finalEnergyDataAdv.other | number: '1.2-2'}} {{textOtherAdv}}</h5>
                          </div>
                          <div class="rounded-section" style="background-color: #f15c80">
                            <h5>{{finalEnergyDataAdv.produced | number: '1.2-2'}} {{textProducedAdv}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              <div class="card">
                <div class="card-header" id="emissions">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#emissionsData" aria-expanded="false" aria-controls="emissionsData">
                      CO<sub>2</sub> Emissions per year nRE PE
                    </button>
                  </h2>
                </div>

                <div id="emissionsData" class="collapse show" aria-labelledby="emissions" >
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          Today
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>Standard</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          Advanced
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-4 ">
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3" style="background-color: grey;">
                          <h3>
                            {{co2EmissionsActual.co2.value | number: '1.2-2'}}
                          </h3>
                        </div>
                        <div class="col-md-9"  style="background-color: #f5a9a9;">
                          <h3>
                            {{co2EmissionsActual.co2.text}}
                          </h3>
                        </div>
                      </div>
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: #e7e7e7; color: grey;">
                          <h3>
                            {{co2EmissionsActual.nre.value | number: '1.2-2'}}
                          </h3>
                        </div>
                        <div class="col-md-9"  style="background-color: #f5a9a9;">
                          <h3>
                            {{co2EmissionsActual.nre.text}}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 " style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: grey;">
                          <h3>
                            {{co2EmissionsStd.co2.value | number: '1.2-2'}}
                          </h3>
                        </div>
                        <div class="col-md-9"  style="background-color: #ffbd04;">
                          <h3>
                            % {{co2EmissionsStd.co2.text}}
                          </h3>
                        </div>
                      </div>
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: #e7e7e7; color: grey;">
                          <h3>
                            {{co2EmissionsStd.nre.value | number: '1.2-2'}}
                          </h3>
                        </div>
                        <div class="col-md-9" style="background-color: #ffbd04;">
                          <h3>
                            % {{co2EmissionsStd.nre.text}}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: grey;">
                          <h3>
                            {{co2EmissionsAdv.co2.value | number: '1.2-2'}}
                          </h3>
                        </div>
                        <div class="col-md-9" style="background-color: #bce39e;">
                          <h3>
                            % {{co2EmissionsAdv.co2.text}}
                          </h3>
                        </div>
                      </div>
                      <div class="row rounded-section co2-section">
                        <div class="col-md-3"  style="background-color: #e7e7e7; color: grey;">
                          <h3>
                            {{co2EmissionsAdv.nre.value | number: '1.2-2'}}
                          </h3>
                        </div>
                        <div class="col-md-9" style="background-color: #bce39e;">
                          <h3>
                            % {{co2EmissionsAdv.nre.text}}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="res">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#resData" aria-expanded="false" aria-controls="resData">
                      % RES
                    </button>
                  </h2>
                </div>

                <div id="resData" class="collapse show" aria-labelledby="res" >
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          Today
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>Standard</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          Advanced
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-4 row">
                      <div class="col-md-7" *ngIf="chartOptionsResActual">
                        <highcharts-chart
                          [Highcharts]="ResChartActual"
                          [options]="chartOptionsResActual" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white;">
                          <div class="rounded-section" style="background-color: #f5a9a9">
                            <h3>{{textResActual.res}}</h3>
                          </div>
                          <div class="rounded-section">
                            <h3>{{textResActual.other}}</h3>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-4 row" style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                      <div class="col-md-7" *ngIf="chartOptionsResStd">
                        <highcharts-chart
                          [Highcharts]="ResChartStd"
                          [options]="chartOptionsResStd" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white;">
                          <div class="rounded-section" style="background-color: #ffbd04">
                            <h3>{{textResStd}}</h3>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-4 row">
                      <div class="col-md-7" *ngIf="chartOptionsResAdv">
                        <highcharts-chart
                          [Highcharts]="ResChartAdv"
                          [options]="chartOptionsResAdv" ></highcharts-chart>
                      </div>
                      <div class="col-md-5">
                        <div style="margin-top: 20px; color: white;">
                          <div class="rounded-section" style="background-color: #bce39e">
                            <h3>{{textResAdv}}</h3>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="cost">
                  <h2 class="mb-0">
                    <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#costData" aria-expanded="false" aria-controls="costData">
                      ENERGY COST
                    </button>
                  </h2>
                </div>

                <div id="costData" class="collapse show" aria-labelledby="cost" >
                  <div class="row" style="margin: 0;">
                    <div class="col-md-4 ">
                      <div class="titleSectionToday" style="margin-right: 10px;">
                        <h2>
                          Today
                        </h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionStandard" style="margin-right: 10px;">
                        <h2>Standard</h2>
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="titleSectionAdvanced">
                        <h2>
                          Advanced
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row">
                    <div class="col-md-4 ">
                      <div class="rounded-container" style="background-color: #f5a9a9;">
                        <h2>{{energyCost | number: '1.2-2'}}<i class="fa fa-eur " aria-hidden="true"></i></h2>
                      </div>
                    </div>
                    <div class="col-md-4 row" style="border-left: 1px solid #616060; border-right: 1px solid #616060;">
                      <div class="col-md-6">
                        <div class="rounded-container" style="background-color: #ffbd04;">
                          <h2>{{energyCostStd.value | number: '1.2-2'}}<i class="fa fa-eur " aria-hidden="true"></i></h2>

                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="rounded-section" style="background-color: #b3b0b0;">
                          <h3>{{energyCostStd.text}}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 row">
                      <div class="col-md-6">
                        <div class="rounded-container  col-md-6" style="background-color: #bce39e;">
                          <h2>{{energyCostAdv.value | number: '1.2-2'}}<i class="fa fa-eur " aria-hidden="true"></i></h2>
                        </div>
                      </div>
                      <div class="col-md-6" >
                        <div class="rounded-section" style="background-color: #b3b0b0;">
                          <h3>{{energyCostAdv.text}}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>

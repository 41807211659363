import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { count } from 'console';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { NodeResponse } from 'src/app/shared/models/node_response';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private baseUrl = GlobalConstants.backendURL + '/api/menu';

  constructor(private http: HttpClient) { }

  getAll(){
    return this.http.get(this.baseUrl);
  }

  create(name: string, short_description: string, country_id: number, arrayStopId: number[], arrayToolsId: number[]){

    let body = {
      name: name,
      short_description: short_description,
      country_id: country_id,
      arrayStop: arrayStopId,
      arrayTool: arrayToolsId,
    }

    return this.http.post(this.baseUrl + '/', body);
  }

  update(idMenu: number, name: string, short_description: string, country_id: number, arrayStopId: number[], arrayToolsId: number[], arrayOldStopId: number[]){

    let body = {
      id: idMenu,
      name: name,
      short_description: short_description,
      country_id: country_id,
      arrayStop: arrayStopId,
      arrayTool: arrayToolsId,
      arrayOldStop: arrayOldStopId,
    }

    return this.http.put(this.baseUrl + '/', body);
  }

  delete(idMenu: number, arrayIdMenuStop: number[]){
    return this.http.post(this.baseUrl + '/' + idMenu, arrayIdMenuStop);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Tools} from '../../shared/models/tools';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from '../../core/authentication/user.service';
import { ToolsService } from 'src/app/core/tools/tools.service';
import { Router } from '@angular/router';
import { UserAllData } from 'src/app/shared/models/userAllData';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { ToolsNew } from 'src/app/shared/models/toolsnew';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {

  closeBtnName?: string;
  user?: UserAllData;
  isUserLogged: boolean;
  currentUser: User = new User();
  countryOneClick: string;
  countriesOneClick: [{id, name}];
  comments = "";
  postComments = [];
  replyComments = "";
  postReplyComments = [];

  countOwning: number;
  countDesign: number;
  countFinancing: number;
  countPublicSector: number;
  countConstructing: number;

  countOnboarding: number;
  countEvaluation: number;
  countDesignFormalization: number;
  countRealization: number;
  countValidation: number;

  userToolsNew: ToolsNew[] = [];
  tmpUserToolsNew: ToolsNew[] = [];

  stopsList: any[] = [];

  currentUrl: string = '';

  imageUrl = GlobalConstants.backendURL;

  @Input() currentUserID: string;
  @Output() historyEmitter2 = new EventEmitter<any>();

  constructor(public bsModalRef: BsModalRef, public afAuth: AngularFireAuth, private userService: UserService, private toolsService: ToolsService, private router: Router) {
    this.checkLogin();
   }

  ngOnInit(): void {
    this.getStops();
    this.currentUrl = this.router.url;
    console.log('modal user -> ', this.user);
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);

        console.log(this.currentUser);

        this.userService.getByUid(user.uid).subscribe((userFromDB: any) => {
          console.log(userFromDB);
          if (userFromDB) {
            this.currentUser.name = userFromDB.data.name;
            this.currentUser.role = userFromDB.data.user_roles[0].role.name;
            this.currentUser.country = userFromDB.data.country.country;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
  }

  saveUser(userID: any){
    let userSaved = new FormData();

    let name = 'Owning';

    userSaved.append('user_id', this.currentUser.uid);
    userSaved.append('user_saved', userID);
    userSaved.append('name', 'Owning');

    console.log(userSaved);

    this.userService.savedUsers(this.currentUser.uid, userID, name).subscribe(response => {
      console.log(response);
      alert("User saved successfully");
    });
  }

  getStops(){
    this.toolsService.getStops().subscribe((data:any) => {
      this.stopsList = data.data;
    })
  }

  buildHistoryTools() {
    this.userService.getUserTools(sessionStorage.getItem('auth-token')).subscribe(hist => {
      for (const histKey in hist) {
        this.parseToolHistory(hist[histKey]);
      }

      this.tmpUserToolsNew = this.userToolsNew;
      console.log('Aqui las tools: ', this.tmpUserToolsNew)
      this.historyEmitter2.emit(this.userToolsNew);
      console.log(this.tmpUserToolsNew);

      this.countToolsByStop(this.tmpUserToolsNew);
    });
  }

  parseToolHistory(history: any) {
    const dataTool = history;

    console.log(dataTool.tool);

    this.userToolsNew.push(dataTool.tool.tool);
  }

  // Función para contar las paradas
  countToolsByStop(arrayTools: ToolsNew[]) {
    // Reinicia el conteo de herramientas para cada tipo de parada
    this.countOnboarding = 0;
    this.countEvaluation = 0;
    this.countDesignFormalization = 0;
    this.countRealization = 0;
    this.countValidation = 0;

    // Recorre todas las herramientas y cuenta cuántas veces aparece cada tipo de parada
    for (let i = 0; i < arrayTools.length; i++) {
      for (let j = 0; j < arrayTools[i].stops.length; j++) {
        switch (arrayTools[i].stops[j].stop) {
          case 'Onboarding':
            this.countOnboarding++;
            break;
          case 'Evaluation':
            this.countEvaluation++;
            break;
          case 'Design and Formalization':
            this.countDesignFormalization++;
            break;
          case 'Realization':
            this.countRealization++;
            break;
          case 'Validation':
            this.countValidation++;
            break;
          default:
            break;
        }
      }
    }

    console.log(this.countOnboarding)
    console.log(this.countEvaluation)
    console.log(this.countDesignFormalization)
    console.log(this.countRealization)
    console.log(this.countValidation)
  }

  postComment(): void{
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          if (userFromDB) {
            this.currentUser.name = userFromDB['user'].name;
            this.currentUser.role = userFromDB['role'].name;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
    this.postComments.push(this.comments)
  }

  postReplyComment(): void{
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          if (userFromDB) {
            this.currentUser.name = userFromDB['user'].name;
            this.currentUser.role = userFromDB['role'].name;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
    this.postReplyComments.push(this.replyComments)
  }

  validateUserToPro(){
    this.userService.validateUserToPro(this.user.uid).toPromise().then((data: NodeResponse) => {
      console.log('request validate user -> ', data);
      if(data.status){
        alert(data.message);
        this.bsModalRef.onHidden.emit(data.data)
        this.bsModalRef.hide();
      }else{
        console.log(data.error);
        alert(data.message);
      }
    })
  }

  rejectUserToPro(){

    let files = [];
    this.user.certifications.forEach(cert => files.push(cert.file))

    this.userService.rejectUserToPro(this.user.uid, this.user.user_extension.profileImage, this.user.user_extension.dossier, files).toPromise().then((data: NodeResponse) => {
      console.log('request reject user -> ', data);
      if(data.status){
        alert(data.message);
        this.bsModalRef.onHidden.emit(data.data)
        this.bsModalRef.hide();
      }else{
        console.log(data.error);
        alert(data.message);
      }
    })
  }

  myWorkFilters = [
    { id: 1, name: 'Onboarded' },
    { id: 2, name: 'Informed' },
    { id: 3, name: 'Informed' },
    { id: 4, name: 'Designed' },
    { id: 5, name: 'Contracted' },
    { id: 6, name: 'Validated' }
  ];

  myNetworkFilters = [
    { id: 1, name: 'Owning' },
    { id: 2, name: 'Design' },
    { id: 3, name: 'Financing' },
    { id: 4, name: 'Public Sector' },
    { id: 5, name: 'Constructing' }
  ]
}

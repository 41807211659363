<main>
  <div class="row">
    <div class="col-md-1 options-panel">
      <!-- Login and User section -->
      <!--<div [ngClass]="optionSelected === 1 ? 'buttons-panel active' : 'buttons-panel'"  class="d-flex align-items-center justify-content-center">
        <a *ngIf="!isUserLogged" href="#" style="color: #3c4b64;" (click)=" optionSelected === 1 ? optionSelected = 0 : optionSelected = 1; stepSelected='';">
          <i class="fa fa-user-o fa-2x"></i>
        </a>
        <div *ngIf="isUserLogged" class="row userLogged" >
            <a href="#" style="color: white; text-decoration: none;" (click)=" optionSelected === 5 ? optionSelected = 0 : optionSelected = 5; stepSelected='';" class="square-container">
              <h2 *ngIf="currentUser.name">{{currentUser.name.substring(0, 1)}}</h2>
            </a>
        </div>
      </div>-->
      <div class="title-oneclick">
        <h3>
          {{'ONE_CLICK_PAGE.TITLE_ONE_CLICK' | translate}}
        </h3>
      </div>
      <!-- Home Section -->
      <div [ngClass]="optionSelected === 0 ? 'buttons-panel active' : 'buttons-panel'"  class="d-flex  icon">
        <a href="#/oneclick" class="link-decoration" (click)="optionSelected = 0; stepSelected='';" >
          <!--<i class="fa fa-home fa-2x icon-slot" aria-hidden="true"></i>-->
          <img src="../../../assets/img/buttons/home_botton.png">
          <div class="a-option home-icon">
            {{'ONE_CLICK_PAGE.FIRST_ICON_ONE_CLICK' | translate}}
          </div>
        </a>
      </div>

      <!-- General Dashboard -->
      <div [ngClass]="optionSelected === 3 ? 'buttons-panel active' : 'buttons-panel'" *ngIf="isUserLogged" class="d-flex  icon">
        <a href="#/oneclick" class="link-decoration" (click)="optionSelected === 3 ? optionSelected = 0 : optionSelected = 3; stepSelected='';" >
          <!--<i class="fa fa-sliders fa-2x icon-slot" aria-hidden="true"></i>-->
          <img src="../../../assets/img/buttons/dashboard_botton.png">
          <div  class="a-option dashboard-icon">
            {{'ONE_CLICK_PAGE.SECOND_ICON_ONE_CLICK' | translate}}
          </div>
        </a>
      </div>
      <!-- User history-->
      <div [ngClass]="optionSelected === 4 ? 'buttons-panel active' : 'buttons-panel'" *ngIf="isUserLogged" class="d-flex  icon">
        <a href="#/oneclick" class="link-decoration" (click)="optionSelected === 4 ? optionSelected = 0 : optionSelected = 4; stepSelected='';" >
          <!--<i class="fa fa-bookmark-o fa-2x icon-slot" aria-hidden="true"></i>-->
          <img src="../../../assets/img/buttons/saved_botton.png">
          <div  class="a-option">
            {{'ONE_CLICK_PAGE.THIRD_ICON_ONE_CLICK' | translate}}
          </div>
        </a>
      </div>

      <!-- Upload Dashboard -->
      <!--<div [ngClass]="optionSelected === 8 ? 'buttons-panel active' : 'buttons-panel'" *ngIf="isUserLogged" class="d-flex  icon">
        <a href="#/oneclick" class="link-decoration" (click)="optionSelected === 8 ? optionSelected = 0 : optionSelected = 8; stepSelected='';" >
          <img src="../../../assets/img/buttons/upload_button.png">
          <div  class="a-option dashboard-icon">
            {{'ONE_CLICK_PAGE.FOURTH_ICON_ONE_CLICK' | translate}}
          </div>
        </a>
      </div>-->

      <!-- General Dashboard -->
      <div [ngClass]="optionSelected === 6 ? 'buttons-panel active' : 'buttons-panel'" *ngIf="isUserLogged == false" class="d-flex align-items-center justify-content-center icon">
        <a href="#/oneclick" class="link-decoration" (click)="optionSelected === 6 ? optionSelected = 0 : optionSelected = 6; stepSelected='';" >
          <i class="fa fa-sliders fa-2x icon-slot" aria-hidden="true"></i>
        </a>
      </div>
      <!-- User history-->
      <div [ngClass]="optionSelected === 7 ? 'buttons-panel active' : 'buttons-panel'" *ngIf="isUserLogged == false" class="d-flex align-items-center justify-content-center icon">
        <a href="#/oneclick" class="link-decoration" (click)="optionSelected === 7 ? optionSelected = 0 : optionSelected = 7; stepSelected='';" >
          <i class="fa fa-bookmark-o fa-2x icon-slot" aria-hidden="true"></i>
        </a>
      </div>

    </div>
    <!-- Login Section -->
    <!--div class="col-md-3 information" *ngIf="optionSelected === 1" >
      <img src="./assets/img/logos/Main-logo.png" width="280" height="160">
      <div *ngIf="isUserLogged">
        <app-user-panel  [optionSelected]="optionSelected"></app-user-panel>
      </div>
      <div *ngIf="!isUserLogged">
        <app-login (loginEndedEmitter)="receiveLogin($event)" [optionSelected]="optionSelected"></app-login>
      </div>
    </div-->
    <!-- Building information Section -->
    <div *ngIf="optionSelected === 2" [ngClass]="(stepSelected==='infoBuilding' || stepSelected==='typology')  ? 'col-md-6' : 'col-md-11 energySection'">
      <div  *ngIf="stepSelected==='infoBuilding' || stepSelected==='typology'"  class="information" >
        <div *ngIf="stepSelected==='infoBuilding'">
          <app-cadastre-info [properties]="properties"  [active]="active" [building]="building" (calculateTypologyEmitter)="calculateTypology($event)" (showMapEmitter)="showMapControl($event);" (optionEmitter)="receiveOption($event)" ></app-cadastre-info>
        </div>
        <div *ngIf="stepSelected==='typology'" class="buildInfoSection">
          <app-typology [typologies]="typologies" [subcategoriesTypo]="subcategoriesTypo" [building]="building" (calculateEnergyEmitter)="receiveCalculateEnergy($event)" (errorEmitter)="receiveErrorFromTypology($event)" (optionEmitter)="receiveOption($event)"> </app-typology>
        </div>
      </div>
      <div *ngIf="stepSelected==='result'" class="energySection information2">
        <app-score [building]="building" [updateBuilding]="updateBuilding" (optionEmitter)="receiveOption($event)" ></app-score>
      </div>
    </div>
    <!-- General Dashboard -->
    <div *ngIf="optionSelected===3"  class="col-md-4 information" style="background-color: #EFF2F6;">
      <app-data-map [optionSelected]="optionSelected" (historyEmitter)="receiveHistory($event);" ></app-data-map>
    </div>
    <!-- User history-->
    <div *ngIf="optionSelected===4" class="col-md-4 information" style="background-color: #EFF2F6;">
      <app-user-history [optionSelected]="optionSelected" (historyEmitter)="receiveHistory($event);" (buildingSelectedEmitter)="receiveBuilding($event);" (buildingToBeUpdatedEmitter)="receiveBuildingToUpdate($event);"></app-user-history>
    </div>
    <!-- User information Section -->
    <div *ngIf="optionSelected===5" class="col-md-4 information">
      <app-user-account [currentUser]="currentUser" (logoutEmitter)="receiveLogOut($event);" ></app-user-account>
    </div>

    <!-- User logout -->
    <div *ngIf="optionSelected===6" class="col-md-4 information" style="padding-top: 50px;">
      <div class="text-login">
        <h2>{{"ONE_CLICK_PAGE.NOT_LOGIN.TITLE" | translate}}</h2>
      </div><br>
      <div class="text-login">
        {{"ONE_CLICK_PAGE.NOT_LOGIN.PARAGRAPH_LOGIN" | translate}}
        <button class="col-md-6 login" (click)="openModal() ">
            <h4>
              {{"ONE_CLICK_PAGE.NOT_LOGIN.BUTTON_LOGIN" | translate}}
            </h4>
        </button>
      </div>
    </div>

    <div *ngIf="optionSelected===7" class="col-md-4 information" style="padding-top: 50px;">
      <div class="text-login">
        <h2>{{"ONE_CLICK_PAGE.NOT_LOGIN.TITLE" | translate}}</h2>
      </div><br>
      <div class="text-login">
        {{"ONE_CLICK_PAGE.NOT_LOGIN.PARAGRAPH_LOGIN" | translate}}
        <button class="col-md-6 login" (click)="openModal() ">
          <h4>
            {{"ONE_CLICK_PAGE.NOT_LOGIN.BUTTON_LOGIN" | translate}}
          </h4>
        </button>
      </div>
    </div>

    <!--<div *ngIf="optionSelected===8" class="col-md-4 information" style="padding-top: 50px;">
      <div class="text-login">
        <h2>To update your data, please upload the Excel file to do it.</h2>
      </div><br>
      <div class="text-login">
        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

        <div class="file-upload">

          {{fileName || "No file uploaded yet."}}

            <button mat-mini-fab color="primary" class="upload-btn"
              (click)="fileUpload.click()">
            </button>
        </div>
      </div>
    </div>-->

    <div [ngClass]="stepSelected === 'result' ? '' : ((optionSelected === 2 ) ? 'col-md-5' : ( optionSelected === 1 ? 'col-md-8' : ( (optionSelected >= 3 && optionSelected <= 8) ? 'col-md-7' : 'col-md-11')))">
      <app-map (buildingEmitter)="receiveBuilding($event)" (coordinatesEmitter)="receiveCoordinates($event)" (addressEmitter)="receiveValue($event)" [history]="history"
               [building]="building" [active]="active" [optionSelected]="optionSelected" [countryMap]="countryMap"></app-map>
    </div>
  </div>

</main>


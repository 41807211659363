import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/core/authentication/user.service';
import {User} from '../../shared/models/user';
import { Article } from 'src/app/shared/models/article';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { ArticleImage } from 'src/app/shared/models/article_image';
import { ArticleDocuments } from 'src/app/shared/models/article_documents';
import { ArticlesService } from 'src/app/core/re-hub/articles.service';
import { ZoomedModalComponent } from '../zoomed-modal/zoomed-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as bootstrap from "bootstrap";
import * as $ from 'jquery';
import { NodeResponse } from 'src/app/shared/models/node_response';

@Component({
  selector: 'app-articles-modal',
  templateUrl: './articles-modal.component.html',
  styleUrls: ['./articles-modal.component.scss']
})
export class ArticlesModalComponent implements OnInit {

  @ViewChild('modal') modal: any;

  imageUrl2: any;
  imageUrl = GlobalConstants.backendURL;
  closeBtnName?: string;
  comments = "";
  article?: Article;
  postComments = [];
  replyComments = "";
  postReplyComments = [];
  isUserLogged: boolean;
  currentUser: User = new User();

  imagesTool: ArticleImage[] = [];
  tmpImagesArticles: ArticleImage[] = [];

  newImages: ArticleImage[] = [];

  documentsTool: ArticleDocuments[] = [];
  tmpDocumentsArticles: ArticleDocuments[] = [];

  newDocuments: ArticleDocuments[] = [];

  modalRef: BsModalRef;
  currentUrl: string = '';

  adminComment: string = '';

  constructor(public bsModalRef: BsModalRef, public afAuth: AngularFireAuth, private userService: UserService, private modalService: NgbModal, private articlesService: ArticlesService,  private router: Router) { }

  ngOnInit(): void {
    this.getImages();
    this.getDocuments();
    this.dataValues();
    this.currentUrl = this.router.url;
  }

  postComment(): void{
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          if (userFromDB) {
            this.currentUser.name = userFromDB['user'].name;
            this.currentUser.role = userFromDB['role'].name;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
    this.postComments.push(this.comments)
  }

  postReplyComment(): void{
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          if (userFromDB) {
            this.currentUser.name = userFromDB['user'].name;
            this.currentUser.role = userFromDB['role'].name;
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
    this.postReplyComments.push(this.replyComments)
  }

  shareFacebook() {
    let url = encodeURIComponent(window.location.href);
    console.log(url)
    let shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + url;
    let width = 600;
    let height = 400;
    let left = (window.innerWidth - width) / 2;
    let top = (window.innerHeight - height) / 2;
    let shareWindow = window.open(shareUrl, "Share on Facebook", "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top);

  // Add an event listener for the 'unload' event on the popup window
  shareWindow.addEventListener("unload", function() {
    // Close the popup window when the user navigates away from the page
    shareWindow.close();
  });
  }

  shareTwitter() {
    let url = encodeURIComponent(window.location.href);
    let shareUrl = "https://twitter.com/intent/tweet?url=" + url;
    let width = 600;
    let height = 400;
    let left = (window.innerWidth - width) / 2;
    let top = (window.innerHeight - height) / 2;
    let shareWindow = window.open(shareUrl, "Share on Facebook", "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top);

    // Add an event listener for the 'unload' event on the popup window
    shareWindow.addEventListener("unload", function() {
      // Close the popup window when the user navigates away from the page
      shareWindow.close();
    });
  }

  shareLinkedIn() {
    let url = encodeURIComponent(window.location.href);
    window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + url, "_blank");
  }

  getImages(){
    this.articlesService.getToolsImages().subscribe(data => {
      this.buildArticlesImages(data);
      this.newImages.forEach(images => {
        this.tmpImagesArticles.push(images);
      });

      /*for(let i = 0; i < this.tmpImagesTool.length; i++){
        if(this.tmpImagesTool[i].tool_id == this.tool.uid){
          this.images = true;

        }else{
          this.images = false;

        }
      }

      console.log(this.images)*/
      console.log(this.tmpImagesArticles);
    })
  }

  buildArticlesImages(data) {
    data.forEach(image => {
      const info = new ArticleImage(
        image.id,
        image.name,
        image.article_id,
      );

      this.newImages.push(info);
    });
  }

  getDocuments(){
    this.articlesService.getArticlesDocuments().subscribe(data => {
      this.buildArticlesDocuments(data);
      this.newDocuments.forEach(documents => {
        this.tmpDocumentsArticles.push(documents);
      });
      console.log(this.tmpDocumentsArticles);
    })
  }

  hasPhotos(articleId: string): boolean {
    return this.tmpImagesArticles.some(photo => photo.article_id.toString() === articleId);
  }

  hasDocuments(articleId: string): boolean {
    return this.tmpDocumentsArticles.some(document => document.article_id.toString() === articleId);
  }

  buildArticlesDocuments(data) {
    data.forEach(documents => {
      const info = new ArticleDocuments(
        documents.id,
        documents.name,
        documents.article_id,
      );

      this.newDocuments.push(info);
    });
  }

  openZoomedModal(imageUrl: string, nameImage: string) {
    const modalRef = this.modalService.open(ZoomedModalComponent, {size: 'xl'});
    modalRef.componentInstance.imageUrl = imageUrl+nameImage;
  }

  dataValues(){

    console.log(this.article)

    this.imageUrl2 = this.article.main_image;

    console.log(this.imageUrl2.slice(9));

    /*if(this.tool.name === 'DRIVE-0 autoevaluation'){
      let elem = (document.getElementById("imagen-modal"));
      elem.innerHTML += "<img class='logo-tool' src='./assets/img/tools/cropped-circular.png' width='100' height='90' alt='Logo tool'>";
    }else{
      let elem = (document.getElementById("imagen-modal"));
      elem.innerHTML += "<img class='logo-tool' src='https://www.hommaxsistemas.com:13443/web/image/website/1/favicon?unique=ff3737a' style='background-color: #ACBDD5 !important; border-radius: 50%;' width='150' height='150' alt='Logo tool'>";
    }*/
  }

  updateToValidateArticle(){
    console.log('article seleccionado -> ',  this.article);
    this.articlesService.updateToValidateArticle(this.article.id).toPromise().then((data: NodeResponse) => {
      console.log('data article validate -> ', data);
      if(data.status){

        this.bsModalRef.onHidden.emit(data.data);
        alert(data.message);
        this.bsModalRef.hide();
      }else{
        alert(data.message);
      }
    })
  }

  updateToRejectArticle(content: any){
    console.log('article seleccionado -> ',  this.article);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			async (save) => {
        console.log(this.adminComment);

        this.articlesService.updateToRejectArticle(this.article.id, this.adminComment).toPromise().then((data: NodeResponse) => {
          console.log('data article reject -> ', data);
          if(data.status){
            this.bsModalRef.onHidden.emit(data.data);
            alert(data.message);
            this.bsModalRef.hide();
            this.adminComment = '';
          }else{
            alert(data.message);
          }
        })
      },

        //Si cierrra el MODAL
			(cancel) => {
        this.adminComment = '';
			},
    )
  }

  isURL(): boolean {
    // Expresión regular para verificar si es una URL válida
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    const videoURL = this.article.video;

    return urlPattern.test(videoURL);
  }
}

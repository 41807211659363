<div class="modal-header tool-actions row justify-content-end">
  <div class="col-md-2">
    <button type="button" class="btn btn-default row">
      <h5>
        {{"MODAL_TOOLS.BUTTON_SAVE" | translate}}
        <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
      </h5>

    </button>
  </div>
  <div class="col-md-2">
    <button type="button" class="btn btn-default row" (click)="bsModalRef.hide()">
      <h5>
        {{"MODAL_TOOLS.BUTTON_CLOSE" | translate}} <i class="fa fa-times" aria-hidden="true"></i>
      </h5>
    </button>
  </div>
</div>
<div class="modal-body content-info">
<div class="container">
  <div class="stops-section">
    <div class="stops" *ngFor="let stop of tool.stops">
      {{stop.stop}}
    </div>
    &nbsp;
    <img *ngIf="tool.expert === true" src="../../../assets/img/tools-modal/expert_true.png" alt="Expert" title="It's for expert users">
    <img *ngIf="tool.expert === false" src="../../../assets/img/tools-modal/expert_false.png" alt="Expert" title="It's not for expert users">
  </div>

  <div class="row">
    <div class="col-md-3">
      <img *ngIf="tool.main_image !== null" class="logo-tool" src="{{imageUrl2}}" width="150" height="auto" alt="Logo tool">
      <img *ngIf="tool.main_image === null" src="./assets/img/tools/camera-icon.jpeg" height="200" width="auto" class="card-img-top">
    </div>

    <div class="col-md-5">
      <h4>
        <span *ngIf="!currentUser || currentUser.country != tool.country_origin">
          {{tool.app_name}}
        </span>
        <span *ngIf="currentUser && currentUser.country == tool.country_origin">
          {{tool.app_name_origin}}
        </span>
        &nbsp;
      </h4>
      <ngb-rating class="estrellas" [max]="5" [readonly]="false" [(rate)]="ratingTool"></ngb-rating><br>
      <a class="web-tool" href="#/oneclick" *ngIf="tool.app_name === 'One click'">{{"MODAL_TOOLS.WEBSITE" | translate}}</a>
      <a class="web-tool" href="{{tool.url}}" target="_blank" *ngIf="tool.url != null && tool.url != ''">{{"MODAL_TOOLS.WEBSITE" | translate}}</a>
      <a class="web-tool-2" *ngIf="tool.training_url !== null && tool.training_url !== ''" href="{{tool.training_url}}" target="_blank">{{"MODAL_TOOLS.BUTTON_TRAINING" | translate}}</a>
      <div class="available-country">
        <img *ngIf="tool.country_origin_data.country === 'Austria'" src="../../../assets/img/tools-modal/austria_flag.png" alt="Spain" width="50" class="bandera-spain"><img>
        <img *ngIf="tool.country_origin_data.country === 'Bulgaria'" src="../../../assets/img/flags/bulgaria_flag.svg" alt="Spain" width="50" class="bandera-spain">
        <img *ngIf="tool.country_origin_data.country === 'Spain'" src="../../../assets/img/flags/spain_flag.svg" alt="Spain" width="50" class="bandera-spain">
        <img *ngIf="tool.country_origin_data.country === 'Slovenia'" src="../../../assets/img/flags/eslovenia_flag.svg" alt="Spain" width="50" class="bandera-spain">
        <img *ngIf="tool.country_origin_data.country === 'France'" src="../../../assets/img/flags/francia_flag.svg" alt="Spain" width="50" class="bandera-spain">
        <img *ngIf="tool.country_origin_data.country === 'Greece'" src="../../../assets/img/flags/grecia_flag.svg" alt="Spain" width="50" class="bandera-spain">
        <img *ngIf="tool.country_origin_data.country === 'Netherlands'" src="../../../assets/img/flags/holanda_flag.svg" alt="Spain" width="50" class="bandera-spain">
        <img *ngIf="tool.country_origin_data.country === 'Italy'" src="../../../assets/img/flags/italia_flag.svg" alt="Spain" width="50" class="bandera-spain">
        <img *ngIf="tool.country_origin_data.country === 'Belgium'" src="../../../assets/img/tools-modal/belgium_flag.png" alt="Belgium" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Denmark'" src="../../../assets/img/tools-modal/denmark_flag.png" alt="Denmark" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Estonia'" src="../../../assets/img/tools-modal/estonia_flag.png" alt="Estonia" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Poland'" src="../../../assets/img/tools-modal/poland_flag.png" alt="Poland" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Portugal'" src="../../../assets/img/tools-modal/portugal_flag.png" alt="Portugal" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Romania'" src="../../../assets/img/tools-modal/romania_flag.png" alt="Romania" width="50">
        <img *ngIf="tool.country_origin_data.country === 'United Kingdom'" src="../../../assets/img/tools-modal/united_kingdom_flag.png" alt="United Kingdom" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Croatia'" src="../../../assets/img/tools-modal/croatia_flag.png" alt="Croatia" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Cyprus'" src="../../../assets/img/tools-modal/cyprus_flag.png" alt="Cyprus" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Germany'" src="../../../assets/img/tools-modal/germany_flag.png" alt="Germany" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Czech Republic'" src="../../../assets/img/tools-modal/czech_flag.png" alt="Czech Republic" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Finland'" src="../../../assets/img/tools-modal/finland_flag.png" alt="Finland" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Hungary'" src="../../../assets/img/tools-modal/hungary_flag.png" alt="Hungary" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Ireland, Republic of (EIRE)'" src="../../../assets/img/tools-modal/ireland_flag.png" alt="Ireland" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Latvia'" src="../../../assets/img/tools-modal/latvia_flag.png" alt="Latvia" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Lithuania'" src="../../../assets/img/tools-modal/lithuania_flag.png" alt="Lithuania" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Luxembourg'" src="../../../assets/img/tools-modal/luxembourg_flag.png" alt="Luxembourg" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Malta'" src="../../../assets/img/tools-modal/malta_flag.png" alt="Malta" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Slovakia'" src="../../../assets/img/tools-modal/slovakia_flag.png" alt="Slovakia" width="50">
        <img *ngIf="tool.country_origin_data.country === 'Sweden'" src="../../../assets/img/tools-modal/sweden_flag.png" alt="Sweden" width="50">
        {{tool.country_origin_data.country}}
      </div>
    </div>

    <div class="col-md-3">
      <img class="image-login" *ngIf="tool.login_access === true" src="../../../assets/img/tools-modal/icon_access_true.png" alt="Access" title="Needs login">
      <img class="image-login-2" *ngIf="tool.login_access === false" src="../../../assets/img/tools-modal/icon_access_false.png" alt="Access" title="No needs login">

      &nbsp;

      <img *ngIf="tool.access_characteristics === 'paying'" src="../../../assets/img/tools-modal/cost_paying.png" alt="Cost" title="Paying">
      <img *ngIf="tool.access_characteristics === 'demostration'" src="../../../assets/img/tools-modal/cost_free-access_demostration.png" alt="Cost" title="Demostration">
      <img *ngIf="tool.access_characteristics === 'free-access'" src="../../../assets/img/tools-modal/cost_free-access_demostration.png" alt="Cost" title="Free accees">

      &nbsp;

      <img *ngIf="tool.prototypeValue === true" src="../../../assets/img/tools-modal/prototype_true.png" alt="Protoype" title="It's a prototype">
      <img *ngIf="tool.prototypeValue === false" src="../../../assets/img/tools-modal/prototype_false.png" alt="Protoype" title="It's not a prototype">
    </div>
  </div>
  <div class="content-description">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">{{"MODAL_TOOLS.DESCRIPTION_SECTION" | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="video-tab" data-toggle="tab" href="#video" role="tab" aria-controls="video" aria-selected="false" *ngIf="isURL()">{{"MODAL_TOOLS.VIDEO_SECTION" | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="photo-tab" data-toggle="tab" href="#photos" role="tab" aria-controls="photos" aria-selected="false" *ngIf="hasPhotos(tool.uid.toString())">{{"MODAL_TOOLS.PHOTOS_SECTION" | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="documents-tab" data-toggle="tab" href="#documents" role="tab" aria-controls="documents" aria-selected="false" *ngIf="hasDocuments(tool.uid.toString())">{{"MODAL_TOOLS.DOCUMENTS_SECTION" | translate}}</a>
      </li>
      <!--<li class="nav-item">
        <a class="nav-link" id="review-tab" data-toggle="tab" href="#review" role="tab" aria-controls="review" aria-selected="false">{{"MODAL_TOOLS.REVIEW_SECTION" | translate}}</a>
      </li>-->
    </ul>
  </div>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
      <div style="width: 65%; float: left; text-align: justify;" *ngIf="!currentUser || currentUser.country != tool.country_origin">
        {{tool.long_description}}
      </div>
      <div style="width: 65%; float: left; text-align: justify;" *ngIf="currentUser && currentUser.country == tool.country_origin">
        {{tool.long_description_origin}}
      </div>
      <div class="fondo-gris" style="width: 30%; float: right;">
        <h6 class="title-information">
          {{"MODAL_TOOLS.GENERAL_INFORMATION_SECTION" | translate}}
        </h6>
        <div class="container">
          <div class="owner-title">
            {{"MODAL_TOOLS.OWNER" | translate}}
            <div class="owner-tool">
              <img src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" class="rounded-circle" style="width: 50px;" alt="Avatar" />
              {{tool.app_owner}} &nbsp;
              <a *ngIf="tool.webpage !== null" href="{{tool.webpage}}" target="_blank">
                <img src="../../../assets/img/tools-modal/webpage.png" alt="Webpage" title="Webpage">
              </a>

              <img *ngIf="tool.webpage === null" src="../../../assets/img/tools-modal/webpage_false.png" alt="Webpage" title="There's no webpage">
            </div>
          </div>
          <div class="available" *ngIf="tool.countries != undefined && tool.countries.length > 0 ">
            {{"MODAL_TOOLS.AVAILABLE_TOOL" | translate}}
            <div *ngIf="tool.countries.length < 27">
              <div class="available-text" *ngFor="let country of tool.countries.slice(0, 4); let i = index">
                <div class="available-country">
                  <img *ngIf="country.country === 'Austria'" src="../../../assets/img/tools-modal/austria_flag.png" alt="Spain" width="50" class="bandera-spain"><img>
                  <img *ngIf="country.country === 'Bulgaria'" src="../../../assets/img/flags/bulgaria_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="country.country === 'Spain'" src="../../../assets/img/flags/spain_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="country.country === 'Slovenia'" src="../../../assets/img/flags/eslovenia_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="country.country === 'France'" src="../../../assets/img/flags/francia_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="country.country === 'Greece'" src="../../../assets/img/flags/grecia_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="country.country === 'Netherlands'" src="../../../assets/img/flags/holanda_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="country.country === 'Italy'" src="../../../assets/img/flags/italia_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="country.country === 'Belgium'" src="../../../assets/img/tools-modal/belgium_flag.png" alt="Belgium" width="50">
                  <img *ngIf="country.country === 'Denmark'" src="../../../assets/img/tools-modal/denmark_flag.png" alt="Denmark" width="50">
                  <img *ngIf="country.country === 'Estonia'" src="../../../assets/img/tools-modal/estonia_flag.png" alt="Estonia" width="50">
                  <img *ngIf="country.country === 'Poland'" src="../../../assets/img/tools-modal/poland_flag.png" alt="Poland" width="50">
                  <img *ngIf="country.country === 'Portugal'" src="../../../assets/img/tools-modal/portugal_flag.png" alt="Portugal" width="50">
                  <img *ngIf="country.country === 'Romania'" src="../../../assets/img/tools-modal/romania_flag.png" alt="Romania" width="50">
                  <img *ngIf="country.country === 'United Kingdom'" src="../../../assets/img/tools-modal/united_kingdom_flag.png" alt="United Kingdom" width="50">
                  <img *ngIf="country.country === 'Croatia'" src="../../../assets/img/tools-modal/croatia_flag.png" alt="Croatia" width="50">
                  <img *ngIf="country.country === 'Cyprus'" src="../../../assets/img/tools-modal/cyprus_flag.png" alt="Cyprus" width="50">
                  <img *ngIf="country.country === 'Germany'" src="../../../assets/img/tools-modal/germany_flag.png" alt="Germany" width="50">
                  <img *ngIf="country.country === 'Czech Republic'" src="../../../assets/img/tools-modal/czech_flag.png" alt="Czech Republic" width="50">
                  <img *ngIf="country.country === 'Finland'" src="../../../assets/img/tools-modal/finland_flag.png" alt="Finland" width="50">
                  <img *ngIf="country.country === 'Hungary'" src="../../../assets/img/tools-modal/hungary_flag.png" alt="Hungary" width="50">
                  <img *ngIf="country.country === 'Ireland, Republic of (EIRE)'" src="../../../assets/img/tools-modal/ireland_flag.png" alt="Ireland" width="50">
                  <img *ngIf="country.country === 'Latvia'" src="../../../assets/img/tools-modal/latvia_flag.png" alt="Latvia" width="50">
                  <img *ngIf="country.country === 'Lithuania'" src="../../../assets/img/tools-modal/lithuania_flag.png" alt="Lithuania" width="50">
                  <img *ngIf="country.country === 'Luxembourg'" src="../../../assets/img/tools-modal/luxembourg_flag.png" alt="Luxembourg" width="50">
                  <img *ngIf="country.country === 'Malta'" src="../../../assets/img/tools-modal/malta_flag.png" alt="Malta" width="50">
                  <img *ngIf="country.country === 'Slovakia'" src="../../../assets/img/tools-modal/slovakia_flag.png" alt="Slovakia" width="50">
                  <img *ngIf="country.country === 'Sweden'" src="../../../assets/img/tools-modal/sweden_flag.png" alt="Sweden" width="50">
                  {{country.country}}
                </div>
              </div>
            </div>
            <div *ngIf="tool.countries.length === 27">
              <img src="../../../assets/img/tools-modal/flag_europe.png"> European Union
            </div>

          </div>
          <div class="contact">
            {{"MODAL_TOOLS.CONTACT_TOOL" | translate}}
            <div class="contact-tool">
              <a *ngIf="tool.contact_email_owner !== null" href="mailto:{{tool.contact_email_owner}}">
                <img src="../../../assets/img/tools-modal/contact_true.png" title="Email Contact">
              </a>
              <img *ngIf="tool.contact_email_owner === null" src="../../../assets/img/tools-modal/contact_false.png" title="There's no email contact">
              &nbsp;
              <a *ngIf="tool.open_source === true" href="{{tool.repo_url}}" target="_blank">
                <img src="../../../assets/img/tools-modal/repo_url_true.png" title="Repository URL">
              </a>
              <img *ngIf="tool.open_source === false" src="../../../assets/img/tools-modal/repo_url_false.png" title="There's no repository url">
              &nbsp;
              <a *ngIf="tool.api_url !== null" href="{{tool.api_url}}" target="_blank">
                <img src="../../../assets/img/tools-modal/api_url_true.png" title="Api URL">
              </a>
              <img *ngIf="tool.api_url === null" src="../../../assets/img/tools-modal/api_url_false.png" title="There's no api url">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
      <div *ngIf="tool.video_url === null">
        No videos yet
      </div>

      <div *ngIf="tool.video_url !== null">
        <iframe width="560" height="315" [src]="tool.video_url | safe" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <div class="tab-pane fade" id="photos" role="tabpanel" aria-labelledby="photo-tab">
      <!--<ngb-carousel>
        <ng-template ngbSlide *ngFor="let images of tmpImagesTool">
          <div class="wrapper">
            <div *ngIf="images.tool_id == tool.uid">
              <img src="{{imageUrl}}{{images.name.slice(9)}}" width="700">
            </div>
          </div>
        </ng-template>
      </ngb-carousel>-->

      <div *ngFor="let images of tmpImagesTool" style="display: inline;">
        <div *ngIf="tool.uid == images.tool_id" class="col-md-1" style="display: inline;">
          <!--<a href="{{imageUrl}}{{images.name.slice(9)}}" target="_blank">-->
            <img src="{{imageUrl}}{{images.name.slice(9)}}" width="300" (click)="openZoomedModal(imageUrl, images.name.slice(9))">
          <!--</a>-->
        </div>
      </div>

    </div>
    <div class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">
      <div *ngIf="tmpDocumentsTool.length === null">
        No documents yet
      </div>

      <div *ngIf="tmpDocumentsTool !== null">
        <div *ngFor="let documents of tmpDocumentsTool" style="display: inline;">
          <div *ngIf="tool.uid == documents.tool_id" class="col-md-1" style="display: inline;">
            <td style="text-align: center;">
              <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i>
              <a href="{{imageUrl}}{{documents.name.slice(9)}}" target="_blank">{{documents.name.slice(29)}}</a>
            </td>
          </div>
        </div>
      </div>


    </div>
    <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
      <div *ngFor="let comment of postComments">
        <div class="card">
          <div class="card-body">
              <p>{{comment}}</p>
              <p>Commented by: {{currentUser.name}}</p>
              <button class="like-button">
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>
              </button>&nbsp;
              <button class="dislike-button">
                <i class="fa fa-thumbs-down" aria-hidden="true"></i>
              </button>
              <button class="reply-button" data-toggle="collapse" data-target="#collapseReply" aria-expanded="false" aria-controls="collapseReply">
                Reply to
              </button><br>
              <!--<div *ngFor="let replycomment of postReplyComments">
                <p>{{replycomment}}</p>
                <p>Commented by: {{currentUser.name}}</p>
              </div>-->
              <div class="collapse" id="collapseReply">
                <textarea class="comments-box" cols="95" rows="5" [(ngModel)]="replyComments">

                </textarea>
                <br>
                <button class="btn btn-light me-3 boton">Comment</button>
              </div>
          </div>
        </div>
        <hr class="comment-separator">
      </div>
      <hr>
      <textarea class="comments-box" cols="103" rows="5" [(ngModel)]="comments">

      </textarea>
      <br>
      <button class="btn btn-light me-3 boton" (click)="postComment()">Comment</button>
    </div>
  </div>
</div>
</div>
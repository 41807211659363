<div #myModal [id]="'modal-' + article.id">
  <div class="modal-header articles-actions row justify-content-end">
    <div class="col-md-2">
      <button type="button" class="btn btn-default row">
        <h5>
          {{"MODAL_ARTICLES.BUTTON_SAVE" | translate}}
          <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
        </h5>
      </button>
    </div>

    <div class="col-md-2" ngbDropdown>
        <button class="btn btn-default dropdown-toggle row" type="button" id="dropdownBasic2" ngbDropdownToggle>
            Share
            <span><i class="fa fa-share-alt" aria-hidden="true"></i></span>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
          <a ngbDropdownItem href="#" (click)="shareFacebook()">Facebook</a>
          <a ngbDropdownItem href="#" (click)="shareTwitter()">Twitter</a>
          <a ngbDropdownItem href="#" (click)="shareLinkedIn()">LinkedIn</a>
        </div>
    </div>

    <div class="col-md-2">
      <button type="button" class="btn btn-default row" (click)="bsModalRef.hide()">
        <h5>
          {{closeBtnName}} <i class="fa fa-times" aria-hidden="true"></i>
        </h5>
      </button>
    </div>
</div>
<div class="modal-body content-info">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <!--<img class="logo-tool" *ngIf="user.name !== 'IVE'" src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" width="150" height="150" alt="Logo tool">
        <img class="logo-tool" *ngIf="user.name === 'IVE'" src="../../../assets/img/icons/logo_IVE-2.svg" width="150" height="150" alt="Logo tool" style="background-color: white;">-->
        <img *ngIf="article.main_image !== ''" class="logo-tool" src="{{imageUrl}}/mainImageArticles/{{article.id}}/{{article.main_image}}" width="150" height="auto" alt="Logo tool">
        <img *ngIf="article.main_image === ''" src="./assets/img/tools/camera-icon.jpeg" height="200" width="auto" class="card-img-top">
      </div>
      <div class="col-md-5">
        <h4>
            {{article.article_name}}
          <!--{{user.name}}-->
        </h4>
        <ngb-rating class="estrellas" [max]="5" [readonly]="false"></ngb-rating><br>
        <!--<a class="web-tool" href="https://www.five.es/" target="_blank" *ngIf="user.name === 'IVE'">Visit web site</a>
        <a class="web-tool" href="" target="_blank" *ngIf="user.name !== 'IVE'">Visit web site</a>-->
        <span class="badge" style="background-color: #1C8972; color: white;">{{article.category_article.name}}</span>
      </div>
    </div>
    <br>
    <a *ngIf="article.link != null && article.link != ''" class="web-button" href="{{article.link}}" target="_blank">{{"MODAL_TOOLS.WEBSITE" | translate}}</a>
    <div class="content-description">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">{{"MODAL_ARTICLES.DESCRIPTION_SECTION" | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="video-tab" data-toggle="tab" href="#video" role="tab" aria-controls="video" aria-selected="false" *ngIf="isURL()">{{"MODAL_ARTICLES.VIDEO_SECTION" | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="photo-tab" data-toggle="tab" href="#photos" role="tab" aria-controls="photos" aria-selected="false" *ngIf="hasPhotos(article.id.toString())">{{"MODAL_ARTICLES.PHOTOS_SECTION" | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="documents-tab" data-toggle="tab" href="#documents" role="tab" aria-controls="documents" aria-selected="false" *ngIf="hasDocuments(article.id.toString())">{{"MODAL_ARTICLES.DOCUMENTS_SECTION" | translate}}</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" id="review-tab" data-toggle="tab" href="#review" role="tab" aria-controls="review" aria-selected="false">{{"MODAL_ARTICLES.REVIEW_SECTION" | translate}}</a>
        </li>-->
      </ul>
    </div>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
        <div style="width: 65%; float: left; text-align: justify;">
            {{article.long_description}}
        </div>
        <div class="fondo-gris" style="width: 30%; float: right;">
          <h6 class="title-information">
            {{"MODAL_ARTICLES.GENERAL_INFORMATION_SECTION" | translate}}
          </h6>
          <div class="container">
            <div class="owner-title">
              {{"MODAL_ARTICLES.OWNER" | translate}}
              <div class="owner-tool">
                <img src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" class="rounded-circle" style="width: 50px;" alt="Avatar" />
                {{article.author}} &nbsp;
              </div>
            </div>
              <div class="available-text">
                <div class="available-country">
                  <img *ngIf="article.country.country === 'Austria'" src="../../../assets/img/tools-modal/austria_flag.png" alt="Spain" width="50" class="bandera-spain"><img>
                  <img *ngIf="article.country.country === 'Bulgaria'" src="../../../assets/img/flags/bulgaria_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="article.country.country === 'Spain'" src="../../../assets/img/flags/spain_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="article.country.country === 'Slovenia'" src="../../../assets/img/flags/eslovenia_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="article.country.country === 'France'" src="../../../assets/img/flags/francia_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="article.country.country === 'Greece'" src="../../../assets/img/flags/grecia_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="article.country.country === 'Netherlands'" src="../../../assets/img/flags/holanda_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="article.country.country === 'Italy'" src="../../../assets/img/flags/italia_flag.svg" alt="Spain" width="50" class="bandera-spain">
                  <img *ngIf="article.country.country === 'Belgium'" src="../../../assets/img/tools-modal/belgium_flag.png" alt="Belgium" width="50">
                  <img *ngIf="article.country.country === 'Denmark'" src="../../../assets/img/tools-modal/denmark_flag.png" alt="Denmark" width="50">
                  <img *ngIf="article.country.country === 'Estonia'" src="../../../assets/img/tools-modal/estonia_flag.png" alt="Estonia" width="50">
                  <img *ngIf="article.country.country === 'Poland'" src="../../../assets/img/tools-modal/poland_flag.png" alt="Poland" width="50">
                  <img *ngIf="article.country.country === 'Portugal'" src="../../../assets/img/tools-modal/portugal_flag.png" alt="Portugal" width="50">
                  <img *ngIf="article.country.country === 'Romania'" src="../../../assets/img/tools-modal/romania_flag.png" alt="Romania" width="50">
                  <img *ngIf="article.country.country === 'United Kingdom'" src="../../../assets/img/tools-modal/united_kingdom_flag.png" alt="United Kingdom" width="50">
                  <img *ngIf="article.country.country === 'Croatia'" src="../../../assets/img/tools-modal/croatia_flag.png" alt="Croatia" width="50">
                  <img *ngIf="article.country.country === 'Cyprus'" src="../../../assets/img/tools-modal/cyprus_flag.png" alt="Cyprus" width="50">
                  <img *ngIf="article.country.country === 'Germany'" src="../../../assets/img/tools-modal/germany_flag.png" alt="Germany" width="50">
                  <img *ngIf="article.country.country === 'Czech Republic'" src="../../../assets/img/tools-modal/czech_flag.png" alt="Czech Republic" width="50">
                  <img *ngIf="article.country.country === 'Finland'" src="../../../assets/img/tools-modal/finland_flag.png" alt="Finland" width="50">
                  <img *ngIf="article.country.country === 'Hungary'" src="../../../assets/img/tools-modal/hungary_flag.png" alt="Hungary" width="50">
                  <img *ngIf="article.country.country === 'Ireland, Republic of (EIRE)'" src="../../../assets/img/tools-modal/ireland_flag.png" alt="Ireland" width="50">
                  <img *ngIf="article.country.country === 'Latvia'" src="../../../assets/img/tools-modal/latvia_flag.png" alt="Latvia" width="50">
                  <img *ngIf="article.country.country === 'Lithuania'" src="../../../assets/img/tools-modal/lithuania_flag.png" alt="Lithuania" width="50">
                  <img *ngIf="article.country.country === 'Luxembourg'" src="../../../assets/img/tools-modal/luxembourg_flag.png" alt="Luxembourg" width="50">
                  <img *ngIf="article.country.country === 'Malta'" src="../../../assets/img/tools-modal/malta_flag.png" alt="Malta" width="50">
                  <img *ngIf="article.country.country === 'Slovakia'" src="../../../assets/img/tools-modal/slovakia_flag.png" alt="Slovakia" width="50">
                  <img *ngIf="article.country.country === 'Sweden'" src="../../../assets/img/tools-modal/sweden_flag.png" alt="Sweden" width="50">
                  {{article.country.country}}
                </div>
              </div>

          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
        <div *ngIf="article.video == null || article.video == 'undefined'">
          No videos yet
        </div>

        <div *ngIf="article.video !== 'undefined'">
          <iframe width="560" height="315" [src]="article.video | safe" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="tab-pane fade" id="photos" role="tabpanel" aria-labelledby="photo-tab">
        <div *ngFor="let images of tmpImagesArticles" style="display: inline;">
          <div *ngIf="article.id == images.article_id" class="col-md-1" style="display: inline;">
            <!--<a href="{{imageUrl}}{{images.name.slice(9)}}" target="_blank">-->
              <img src="{{imageUrl}}{{images.name.slice(9)}}" width="300" (click)="openZoomedModal(imageUrl, images.name.slice(9))">
            <!--</a>-->
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">
        <div *ngIf="tmpDocumentsArticles.length == 0 || tmpDocumentsArticles.length == null || tmpDocumentsArticles.length == undefined">
          No documents yet
        </div>

        <div *ngIf="tmpDocumentsArticles !== null">
          <div *ngFor="let documents of tmpDocumentsArticles; index as i" style="display: inline;">
            <div *ngIf="article.id == documents.article_id" class="col-md-1" style="display: inline;">
              <td style="text-align: center;">
                <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i>
                <a href="{{imageUrl}}{{documents.name.slice(9)}}" target="_blank">Document {{i+1}}.pdf<!--{{documents.name.slice(31)}}--></a>
              </td>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
        <div *ngFor="let comment of postComments">
          <div class="card">
            <div class="card-body">
                <p>{{comment}}</p>
                <p>Commented by: {{currentUser.name}}</p>
                <button class="like-button">
                  <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                </button>&nbsp;
                <button class="dislike-button">
                  <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                </button>
                <button class="reply-button" data-toggle="collapse" data-target="#collapseReply" aria-expanded="false" aria-controls="collapseReply">
                  Reply to
                </button><br>
                <!--<div *ngFor="let replycomment of postReplyComments">
                  <p>{{replycomment}}</p>
                  <p>Commented by: {{currentUser.name}}</p>
                </div>-->
                <div class="collapse" id="collapseReply">
                  <textarea class="comments-box" cols="95" rows="5" [(ngModel)]="replyComments">

                  </textarea>
                  <br>
                  <button class="btn btn-light me-3 boton">Comment</button>
                </div>
            </div>
          </div>
          <hr class="comment-separator">
        </div>
        <hr>
        <textarea class="comments-box" cols="103" rows="5" [(ngModel)]="comments">

        </textarea>
        <br>
        <button class="btn btn-light me-3 boton" (click)="postComment()">Comment</button>
      </div>
    </div>
  </div>
  <button *ngIf="currentUrl == '/administrationPanel'" class="btn btn-primary" (click)="updateToValidateArticle()">Validate</button>
  <button *ngIf="currentUrl == '/administrationPanel'" class="btn btn-danger" (click)="updateToRejectArticle(content)">Reject</button>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sugestion</h4>
    <button type="button" aria-label="Close" (click)="modal.dismiss('Cross click')">&#x2715;</button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <label for="comment">Comment</label>
        <div class="input-group">
          <textarea id="comment" name="comment" class="comments-box" cols="103" rows="5" [(ngModel)]="adminComment"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{"MODAL_ARTICLES.BUTTON_SAVE" | translate}}</button>
  </div>
</ng-template>

<div class="map-container">
    <div class="map-frame">
      <div id="map">
        <button id="expandInfo" >
        </button>
      </div>
      <!--div id="logoContainer">
        <img src="./assets/img/logos/Main-logo.png" width="280" height="160">
      </div-->
    </div>
  </div>
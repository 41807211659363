<div *ngIf="window == 1">
    <!--<div class="user-section">
    <div class="container">
      <img alt="Work in progess" src="../../../assets/img/Work-In-Progress.png">
    </div>
  </div>-->
  <div class="row">
    <div class="col-md-1 options-panel">
      <div class="d-flex icon" *ngIf="this.roleUser < 4">
        <a class="link-decoration" role="button" (click)="toUserProfile()">
          <img src="../../../assets/img/icons/icon_user_account.png">
          <div class="a-option tools-icon">
            My account
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toMyArticles()">
          <img src="../../../assets/img/icons/article_admin.png">
          <div class="a-option tools-icon">
            My articles
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toMyTools()">
          <img src="../../../assets/img/icons/tools.png">
          <div class="a-option tools-icon">
            My tools
          </div>
        </a>
      </div>

      <div class="d-flex icon" *ngIf="this.roleUser < 4">
        <a class="link-decoration" role="button" (click)="toSuggestions()">
          <img src="../../../assets/img/icons/free_icon_1.svg" width="30" height="30">
          <div class="a-option tools-icon">
            Suggestions
          </div>
        </a>
      </div>
    </div>

    <div class="col-md-11">
      <div class="user-section">
        <section class="container">
          <div style="width: 50%; float: left;">
            <div class="content-text-country">
              <h1 class="profile-title">
                {{currentUser.name}}
              </h1>
              <p class="parrafo">
                {{userDescription}}
              </p>
            </div>
          </div>
        </section>
        <section class="container">
          <div style="width: 50%; float: right;">
              <div class="card card-user justify-content-center" >
                <div class="card-body">
                  <div class="row">
                    <!--<div class="col-md-3">
                      <img src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" width="100" alt="UserPhoto" class="rounded-circle">
                    </div>-->
                    <div class="col-md-3">
                      <label for="imageInput">
                        <div class="col-md-3" *ngIf="profileImage == null">
                          <img src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" width="100" alt="UserPhoto" class="rounded-circle">
                        </div>
                        <div class="col-md-3" *ngIf="profileImage != null">
                          <img src="{{imageUrl}}/{{profileImage.substring(10)}}" width="100" height="100" alt="UserPhoto" class="rounded-circle">
                        </div>
                      </label>
                      <input type="file" id="imageInput" style="display: none;" (change)="onImageSelected($event)">
                    </div>
                    <div class="col">
                      <h4>{{currentUser.name}}</h4>
                      <!--<p>Joined in 2022</p>-->
                      <br>
                      <!--<div class="row">
                        <div class="col">
                          <button class="btn btn-primary boton-edit" (click)="goToEditAccount(currentUser)">{{"MY_ACCOUNT.BUTTON_EDIT_PROFILE" | translate}}</button>
                        </div>
                      </div>-->
                    </div>
                    <div class="col" *ngIf="roleUser == 4">
                      <button class="btn btn-primary boton-logout" (click)="toFormProClassic()">{{"MY_ACCOUNT.BUTTON_TO_PROFESSIONAL" | translate}}</button>
                    </div>

                    <div class="col" *ngIf="roleUser <= 3">
                      <button class="btn btn-primary boton-logout" (click)="toFormProToUpdate()">Edit profile</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-4">
                      <i class="fa fa-user" aria-hidden="true"></i>&nbsp;{{currentUser.role}} user
                    </div>
                    <div class="col">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;Lives in {{currentUser.country}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-4">
                      <i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;{{currentUser.email}}
                    </div>
                    <div class="col" *ngIf="phoneNumber">
                      <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;{{phoneNumber}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-4" *ngIf="webpage">
                      <i class="fa fa-globe" aria-hidden="true"></i>&nbsp;{{webpage}}
                    </div>
                    <div class="col" *ngIf="dossier">
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;{{dossier.slice(55)}}
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
      </div>

      <div class="container justify-content-center" *ngIf="roleUser == 3">
        <div class="row">
          <div class="col-md-6 text-stars profile-title2">
            <h3>
              You are a <ngb-rating [max]="5" [readonly]="false"></ngb-rating> user
            </h3>
            <div class="profile-title2">
              {{"MY_ACCOUNT.TEXT_USER_STARS" | translate}}
            </div>
          </div>
          <div class="col-md-6">
            <div class="range-section">
              <form #toolsrange = "ngForm">
                <ngx-slider name="slider" [(value)]="value" [options]="options" ngModel></ngx-slider>
              </form>
              <div>
                <label for="customRange1" class="form-label label-info profile-title2">{{"MY_ACCOUNT.TEXT_CERTIFIED_SOLUTIONS" | translate}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem class="me-2">
          <h5>
            <a href="#saves-properties" ngbNavLink>{{"MY_ACCOUNT.MY_WORK_TITLE" | translate}}</a>
          </h5>
          <ng-template ngbNavContent>
            <div class="container">
              <div class="row">
                <div class="col">
                  <h6 class="profile-title2">
                    {{"MY_ACCOUNT.TEXT_MY_WORK" | translate}}
                  </h6>
                </div>
              </div>
              <div class="" *ngIf="filters">
                <div class="row">
                  <div class="col card bg-light card-stop" *ngFor="let work of myWorkFilters; let i = index" id="cardFilter{{work.name}}">
                    <div class="card-body" style="color: white !important; text-align: center;">
                      <p id="textInfo{{i}}" class="card-text card-text-title">{{work.name}}</p>
                    </div>
                    <p class="card-text card-text-percent" *ngIf="work.name === 'Onboarded'" style="color: white !important; text-align: center;">{{countBuildingsCEEOnboarded}}</p>
                    <p class="card-text card-text-percent" *ngIf="work.name === 'Informed'" style="color: white !important; text-align: center;">{{countBuildingsOneClick}}</p>
                    <p class="card-text card-text-percent" *ngIf="work.name === 'Designed'" style="color: white !important; text-align: center;">{{countBuildingsCEEDesigned}}</p>
                    <p class="card-text card-text-percent" *ngIf="work.name === 'Contracted'" style="color: white !important; text-align: center;">{{countBuildingsCEEContracted}}</p>
                    <p class="card-text card-text-percent" *ngIf="work.name === 'Validated'" style="color: white !important; text-align: center;">{{countBuildingsCEEValidated}}</p>
                    <button type="button" class="btn stretched-link btn-card-filter shadow-none" (click)="filterBuildings(work.id)"></button>
                  </div>
                </div>
                <button type="button" class="btn btn-danger" (click)="clearFilterBuildings()">Delete filter</button>
                <br>
                <div *ngIf="selectedFilterBuildings">
                  <p style="font-weight: bold;">You have applied the filter {{ selectedFilterBuildings }}</p>
                </div>
              </div>
              <div class="" *ngIf="filters">
                <div class="row">
                  <div class="col card bg-light card-stop" *ngFor="let filter of filters.stops; let i = index" id="cardFilter{{i}}">
                    <div class="card-header header-image">
                      <i *ngIf="filter.stop === 'Onboarding'" class="fa fa-handshake-o fa-lg" aria-hidden="true"></i>
                      <i *ngIf="filter.stop === 'Evaluation'" class="fa fa-file-text fa-lg" aria-hidden="true"></i>
                      <i *ngIf="filter.stop === 'Elaboration'" class="fa fa-pencil-square fa-lg" aria-hidden="true"></i>
                      <i *ngIf="filter.stop === 'Realization'" class="fa fa-cog fa-lg" aria-hidden="true"></i>
                      <i *ngIf="filter.stop === 'Validation'" class="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                    </div>
                    <div class="card-body">
                      <p id="textInfo{{i}}" class="card-text card-text-title">{{filter.stop}}</p>
                    </div>
                    <p class="card-text card-text-percent"></p>
                    <button type="button" class="btn stretched-link btn-card-filter shadow-none"></button>
                  </div>
                </div>
              </div>

                <div id="properties" class="collapse show" aria-labelledby="propertiesSaved">
                  <div class="card-body">
                    <div class="alert alert-success" role="alert" *ngIf="deletedBuilding">
                      Building deleted
                    </div>
                    <div>
                      <button class="btn btn-primary button-filters" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Filters
                      </button>
                      <div class="collapse" id="collapseExample">
                        <div class="card card-body form filter-section">
                          <div class="row">
                            <div class="col-md-6">
                              <label for="filterYearMin">Min Year: </label>
                              <ng-select id="filterYearMin" class="filter" name="filetYearMin" [(ngModel)]="yearSelectedMin"
                                        (change)="filter('yearMin');">
                                <ng-option *ngFor="let year of filterYears" [value]="year">{{year}}</ng-option>
                              </ng-select>
                            </div>
                            <div class="col-md-6">
                              <label for="filterYearMax">Max Year: </label>
                              <ng-select id="filterYearMax" class="filter" name="filetYearMax"  [(ngModel)]="yearSelectedMax"
                                        (change)="filter('yearMax');">
                                <ng-option *ngFor="let year of filterYears" [value]="year">{{year}}</ng-option>
                              </ng-select>
                            </div>
                          </div>
                          <label for="filterCountry"> Country: </label>
                          <ng-select id="filterCountry" class="filter" name="filetCountry" [(ngModel)]="countrySelected"
                                    (change)="filter('country');">
                            <ng-option *ngFor="let country of filterCountries" [value]="country">{{country}}</ng-option>
                          </ng-select>

                          <label for="buildingType"> Building Type: </label>
                          <ng-select id="buildingType" class="filter" name="filterTypology" [(ngModel)]="typologySelected"
                                    (change)="filter('typology');">
                            <ng-option *ngFor="let typo of filterTypologies" [value]="typo.code">{{typo.name}}</ng-option>
                          </ng-select>

                        </div>
                      </div>
                    </div>

                    <div class="tools row">
                      <div class="card card-tool col-md-2 justify-content-center" *ngFor="let building of tmpUserHistory">
                        <div class="card-body">
                          <div class="row">
                            <div class="col">
                              <div [hidden]="building.properties.length < 1">
                                <img class="img-thumbnail" alt="Facade image" [src]='building.properties[0].image' *ngIf=" building.properties.length > 0 && building.properties[0].image" width="170" height="170"/>
                              </div>
                              <img src="../../../assets/img/typology/ES/{{building.typology.picName}}" *ngIf="building.country === 'ES'" width="150" height="50" class="card-img-top">
                              <img src="../../../assets/img/typology/NL/{{building.typology.picName}}" *ngIf="building.country === 'NL'" width="150" height="50" class="card-img-top">
                              <img src="../../../assets/img/typology/FR/{{building.typology.picName}}" *ngIf="building.country === 'FR'" width="150" height="50" class="card-img-top">
                              <img src="../../../assets/img/typology/BG/{{building.typology.picName}}" *ngIf="building.country === 'BG'" width="150" height="50" class="card-img-top">
                              <img src="../../../assets/img/typology/GR/{{building.typology.picName}}" *ngIf="building.country === 'GR'" width="150" height="50" class="card-img-top">
                              <img src="../../../assets/img/typology/IT/{{building.typology.picName}}" *ngIf="building.country === 'IT'" width="150" height="50" class="card-img-top">
                              <img src="../../../assets/img/typology/SI/{{building.typology.picName}}" *ngIf="building.country === 'SI'" width="150" height="50" class="card-img-top">
                            </div>
                            <div class="col">
                              <button class="btn btn-light" (click)="removeBuildingFromUserHistory(building)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div><br>
                          <div class="row">
                            <div class="col">
                              <div style="width: 75px; color: #004470;">{{building.country}}</div>
                            </div>
                            <div class="col">
                              <div style="width: 75px; color: #004470;">{{building.year}}</div>
                            </div>
                          </div><br>
                          <a href="#/accountuser" id="link-tool-name" (click)="openBuildingModal(building)"><h4>{{building.address}}</h4></a>
                        </div>
                      </div>
                      <div class="card card-tool col-md-2 justify-content-center" *ngFor="let buildingCEE of tmpUserCEEHistory">
                        <div class="card-body">
                          <div class="row">
                            <div class="col">
                              <div [hidden]="buildingCEE.facade_picture == ''">
                                <img class="img-thumbnail" alt="Facade image" [src]='buildingCEE.facade_picture' width="170" height="170"/>
                              </div>
                              <!--<img src="../../../assets/img/typology/ES/{{building.typology.categoryPicCode}}.001.jpg" height="50" width="150" class="card-img-top">-->
                            </div>
                            <div class="col">
                              <button class="btn btn-light">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div><br>
                          <div class="row">
                            <div class="col">
                              <div style="width: 75px; color: #004470;">{{buildingCEE.country}}</div>
                            </div>
                            <div class="col">
                              <div style="width: 75px; color: #004470;">{{buildingCEE.year}}</div>
                            </div>
                          </div><br>
                          <a href="#/accountuser" id="link-tool-name"><h4>{{buildingCEE.map_address}}</h4></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem class="me-2">
          <h5>
            <a href="#saved-tools" ngbNavLink>{{"MY_ACCOUNT.MY_NETWORK_TITLE" | translate}}</a>
          </h5>
          <ng-template ngbNavContent>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <h6 class="profile-title2">
                      {{"MY_ACCOUNT.TEXT_MY_NETWORK" | translate}}
                    </h6>
                  </div>
                  <!--<div class="col-9">
                    <button class="btn btn-danger" (click)="deleteFilter()" id="btnDeleteFilter">Delete filter</button>
                  </div>-->
                </div>
                <div class="" *ngIf="filters">
                  <div class="row">
                    <div class="col card bg-light card-stop" *ngFor="let net of myNetworkFilters; let i = index" id="cardFilterNetwork{{i}}">
                      <div class="card-body" style="color: white !important; text-align: center;">
                        <p id="textInfo{{i}}" class="card-text card-text-title">{{net.name}}</p>
                      </div>
                      <p class="card-text card-text-percent" *ngIf="net.name === 'Owning'" style="color: white !important; text-align: center;">{{countOwning}}</p>
                      <p class="card-text card-text-percent" *ngIf="net.name === 'Design'" style="color: white !important; text-align: center;">{{countDesign}}</p>
                      <p class="card-text card-text-percent" *ngIf="net.name === 'Financing'" style="color: white !important; text-align: center;">{{countFinancing}}</p>
                      <p class="card-text card-text-percent" *ngIf="net.name === 'Public Sector'" style="color: white !important; text-align: center;">{{countPublicSector}}</p>
                      <p class="card-text card-text-percent" *ngIf="net.name === 'Constructing'" style="color: white !important; text-align: center;">{{countConstructing}}</p>
                      <button type="button" class="btn stretched-link btn-card-filter shadow-none" (click)="applyFilterUsers(net.name)"></button>
                    </div>
                  </div>
                  <button type="button" class="btn btn-danger" (click)="clearFilterUsers()">Delete filter</button>
                  <br>
                  <div *ngIf="selectedFilter">
                    <p style="font-weight: bold;" *ngIf="foundMatches">You have applied the filter {{ selectedFilter }}</p>
                  </div>
                </div>

                  <div id="properties" class="collapse show" aria-labelledby="propertiesSaved">
                    <div class="card-body">
                      <div class="alert alert-success" role="alert" *ngIf="deletedTool">
                        Tool deleted
                      </div>
                      <div class="tools row">
                        <div class="card card-tool col-md-2 justify-content-center" *ngFor="let user of savedUsers">
                          <div class="card-body">
                            <div class="row">
                              <div id="climateZone" class="contact-tool">
                              </div>
                              <div class="col">
                                <button class="btn btn-light">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                            <a href="#/accountuser" id="link-tool-name" (click)="openUserModal(user)"><h4>{{user.name}}</h4></a>
                          </div>
                        </div>
                        <div class="alert alert-success" role="alert" *ngIf="deletedTool">
                          Article deleted
                        </div>
                        <div class="card card-tool col-md-2 justify-content-center" *ngFor="let article of savedArticles">
                          <div class="card-body">
                            <div class="row">
                              <div id="climateZone" class="contact-tool">
                              </div>
                              <div class="col">
                                <button class="btn btn-light">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                            <a href="#/accountuser" id="link-tool-name" (click)="openModalArticles(article)"><h4>{{article.article_name}}</h4></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem class="me-2">
          <h5>
            <a href="#saved-tools" ngbNavLink>{{"MY_ACCOUNT.MY_EXPERTISE" | translate}}</a>
          </h5>
          <ng-template ngbNavContent>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <h6 class="profile-title2">
                      {{"MY_ACCOUNT.TEXT_MY_EXPERTISE" | translate}}
                    </h6>
                  </div>
                  <!--<div class="col-9">
                    <button class="btn btn-danger" (click)="deleteFilter()" id="btnDeleteFilter">Delete filter</button>
                  </div>-->
                </div>
                <div class="" *ngIf="filters">
                  <div class="row">
                    <div class="col card bg-light card-stop" *ngFor="let stop of stopsList; let i = index" id="cardFilterStops{{i}}">
                      <div class="card-body" style="color: white !important; text-align: center;">
                        <p id="textInfo{{i}}" class="card-text card-text-title">
                          {{stop.stop}}
                          <!--<i *ngIf="stop.stop === 'Onboarding'" class="fa fa-handshake-o fa-lg" aria-hidden="true" style="color: white !important;"></i>
                          <i *ngIf="stop.stop === 'Evaluation'" class="fa fa-file-text fa-lg" aria-hidden="true" style="color: white !important;"></i>
                          <i *ngIf="stop.stop === 'Design and Formalization'" class="fa fa-pencil-square fa-lg" aria-hidden="true" style="color: white !important;"></i>
                          <i *ngIf="stop.stop === 'Realization'" class="fa fa-cog fa-lg" aria-hidden="true" style="color: white !important;"></i>
                          <i *ngIf="stop.stop === 'Validation'" class="fa fa-check-circle-o fa-lg" aria-hidden="true" style="color: white !important;"></i>-->
                        </p>
                      </div>
                      <p class="card-text card-text-percent" *ngIf="stop.stop === 'Onboarding'" style="color: white !important; text-align: center;">{{ countOnboarding }}</p>
                      <p class="card-text card-text-percent" *ngIf="stop.stop === 'Evaluation'" style="color: white !important; text-align: center;">{{ countEvaluation }}</p>
                      <p class="card-text card-text-percent" *ngIf="stop.stop === 'Design and Formalization'" style="color: white !important; text-align: center;">{{ countDesignFormalization }}</p>
                      <p class="card-text card-text-percent" *ngIf="stop.stop === 'Realization'" style="color: white !important; text-align: center;">{{ countRealization }}</p>
                      <p class="card-text card-text-percent" *ngIf="stop.stop === 'Validation'" style="color: white !important; text-align: center;">{{ countValidation }}</p>
                      <button type="button" class="btn stretched-link btn-card-filter shadow-none" (click)="filterStopTools(stop.id)"></button>
                    </div>
                  </div>
                  <button class="btn btn-danger" (click)="clearFilter()">Delete filter</button>
                  <br>
                  <div *ngIf="selectedFilterTools">
                    <p style="font-weight: bold;">You have applied the filter {{ selectedFilterTools }}</p>
                  </div>
                </div>

                  <div id="properties" class="collapse show" aria-labelledby="propertiesSaved">
                    <div class="card-body">
                      <div class="alert alert-success" role="alert" *ngIf="deletedTool">
                        Tool deleted
                      </div>
                      <div class="tools row">
                        <div class="card card-tool col-md-2 justify-content-center" *ngFor="let tool of (filteredTools ? tmpToolsFiltered : tmpUserToolsNew)">
                          <div class="card-body">
                            <div class="row">
                              <div id="climateZone" class="contact-tool">
                              </div>
                              <div *ngIf="tool.main_image !== ''" class="col" id="data-image">
                                <img src="{{imageUrl}}/mainImage/{{tool.uid}}/{{tool.main_image}}" height="auto" width="auto" class="card-img-top">
                              </div>
                              <div *ngIf="tool.main_image === ''" class="col" id="data-image">
                                <img src="./assets/img/tools/camera-icon.jpeg" height="50" width="150" class="card-img-top">
                              </div>
                              <div class="col">
                                <button class="btn btn-light" (click)="removeToolFromUserHistory(tool)">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                            <a href="#/accountuser" id="link-tool-name" (click)="openModal(tool)"><h4>{{tool.app_name}}</h4></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </ng-template>
        </ng-container>
      </nav>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>

</div>

<div class="container" *ngIf="window == 2">
  <button class="btn btn-link" style="margin-top: 2%; margin-bottom: 2%;" (click)="toUserProfile()"> <- {{"SUCCESS_STORIES_RESULTS.BUTTON_BACK" | translate}}</button>
  <app-user-form-pro [user]="user" [mode]="mode" (window)="changeWindow($event)" (newUser)="getNewUser($event)"></app-user-form-pro>
</div>

<div *ngIf="window == 3">
  <div class="row">
    <div class="col-md-1 options-panel">
      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toUserProfile()">
          <img src="../../../assets/img/icons/icon_user_account.png">
          <div class="a-option tools-icon">
            My account
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toMyArticles()">
          <img src="../../../assets/img/icons/article_admin.png">
          <div class="a-option tools-icon">
            My articles
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toMyTools()">
          <img src="../../../assets/img/icons/tools.png">
          <div class="a-option tools-icon">
            My tools
          </div>
        </a>
      </div>
      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toSuggestions()">
          <img src="../../../assets/img/icons/free_icon_1.svg" width="30" height="30">
          <div class="a-option tools-icon">
            Suggestions
          </div>
        </a>
      </div>
    </div>
    <div class="col-md-11">
      <div class="container">
        <br>
        <app-my-articles [user]="user"></app-my-articles>
      </div>
    </div>
  </div>
</div>

<div *ngIf="window == 4">
  <div class="row">
    <div class="col-md-1 options-panel">
      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toUserProfile()">
          <img src="../../../assets/img/icons/icon_user_account.png">
          <div class="a-option tools-icon">
            My account
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toMyArticles()">
          <img src="../../../assets/img/icons/article_admin.png">
          <div class="a-option tools-icon">
            My articles
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toMyTools()">
          <img src="../../../assets/img/icons/tools.png">
          <div class="a-option tools-icon">
            My tools
          </div>
        </a>
      </div>
      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toSuggestions()">
          <img src="../../../assets/img/icons/free_icon_1.svg" width="30" height="30">
          <div class="a-option tools-icon">
            Suggestions
          </div>
        </a>
      </div>
    </div>
    <div class="col-md-11">
      <div class="container">
        <br>
        <app-my-tools [user]="user"></app-my-tools>
      </div>
    </div>
  </div>
</div>

<div *ngIf="window == 5">
  <div class="row">
    <div class="col-md-1 options-panel">
      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toUserProfile()">
          <img src="../../../assets/img/icons/icon_user_account.png">
          <div class="a-option tools-icon">
            My account
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toMyArticles()">
          <img src="../../../assets/img/icons/article_admin.png">
          <div class="a-option tools-icon">
            My articles
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toMyTools()">
          <img src="../../../assets/img/icons/tools.png">
          <div class="a-option tools-icon">
            My tools
          </div>
        </a>
      </div>
      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="toSuggestions()">
          <img src="../../../assets/img/icons/free_icon_1.svg" width="30" height="30">
          <div class="a-option tools-icon">
            Suggestions
          </div>
        </a>
      </div>
    </div>
    <div class="col-md-11">
      <div class="container">
        <br>
        <app-suggestion-box [user]="user"></app-suggestion-box>
      </div>
    </div>
  </div>
</div>

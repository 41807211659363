import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isNumber, theme } from 'highcharts';
import { Subscription } from 'rxjs';
import { ToolsModalComponent } from 'src/app/components/tools-modal/tools-modal.component';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { CountryService } from 'src/app/core/country/country.service';
import { ToolsService } from 'src/app/core/tools/tools.service';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { ConstraintCategory } from 'src/app/shared/models/constraintCategory';
import { ConstraintType } from 'src/app/shared/models/constraintType';
import { ConstraintsInfo } from 'src/app/shared/models/constraintsInfo';
import { Country } from 'src/app/shared/models/country';
import { DescriptionTools } from 'src/app/shared/models/descriptionTools';
import { Language } from 'src/app/shared/models/language';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Profile } from 'src/app/shared/models/profile';
import { Solution } from 'src/app/shared/models/solution';
import { Step } from 'src/app/shared/models/step';
import { Stop } from 'src/app/shared/models/stop';
import { ToolStatus } from 'src/app/shared/models/toolStatus';
import { ToolsNew } from 'src/app/shared/models/toolsnew';
import { Typology } from 'src/app/shared/models/typology';
import { TypologyTool } from 'src/app/shared/models/typologyTool';
import { User } from 'src/app/shared/models/user';
import { UserService } from 'src/app/core/authentication/user.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-tool-form',
  templateUrl: './tool-form.component.html',
  styleUrls: ['./tool-form.component.scss']
})
export class ToolFormComponent implements OnInit, OnDestroy {

  @Input() arrayCountry: Country[] = [];

  toolSelected: ToolsNew = null;

  window: number = 2;
  isChangeDB: boolean = false;

  guid: string = null;

  arrayTool: ToolsNew[] = [];
  tmpArrayTool: ToolsNew[] = [];

  arrayStatus: ToolStatus[] = [];
  arrayLanguage: Language[] = [];
  arrayStep: Step[] = [];
  arrayStop: Stop[] = [];

  arrayToolPropose: any[] = [];
  tmpArrayToolPropose: any[] = [];

  getGuid: Subscription = null;
  getArrayTool: Subscription = null;
  getArrayLanguage: Subscription = null;
  getArrayToolPropose: Subscription = null;
  getArrayStep: Subscription = null;
  getArrayStop: Subscription = null;
  getArraySolution: Subscription = null;
  getArrayTypology: Subscription = null;
  getArrayProfile: Subscription = null;
  getArrayConstraintType: Subscription = null;
  getArrayConstraintCategory: Subscription = null;
  getArrayToolsSolution: Subscription = null;

  //pagination

  page: number = 1;
  pageSize: number = 10;
  counter: number = 0;

  //pagination form
  switchFormCount: number = 1;
  switchFormProposeCount: number = 1;
  totalFormCount: number = 8;

  //filter

  valueFilterName: string = '';
  valueFilterCountry: number = null;
  valueFilterCountryTool: number = null;
  valueFilterStop: number = null;
  valueFilterStep: number = null;

  valueFilterUsername: string = '';
  valueFilterStatus: number = null;

  //lengths descriptions
  lengthShortDescription: number = 0;
  lengthLongDescription: number = 0;
  lengthShortDescriptionOriginal: number = 0;
  lengthLongDescriptionOriginal: number = 0;

  //first page
  valueUid: number = 0;
  valueCountry: number = null;
  valueName: string = '';
  valueNameOriginal: string = '';
  valueIsURL: boolean = false;
  valueURL: string = '';
  valueShortDescription: string = '';
  valueLongDescription: string = '';
  valueShortDescriptionOriginal: string = '';
  valueLongDescriptionOriginal: string = '';

  //second page
  mainImage: any = null;
  valueFlexRadioMonitoring: boolean = false;
  additionalImages: any[] = [];
  valueVideoURL: string = '';
  isAllDocuments: boolean;
  toolsDocuments: any[] = [];

  isAdditionalImagesChange: boolean = false;
  isPdfFilesChange: boolean = false;

  mainImageName: string = '';
  nameOfAdditionalImages: string = '';
  namePdfDocuments: string = '';

  //third page
  valuePrototype: boolean = false;
  valueFlexRadioAccessForm3: boolean = false;
  valueAccess: string = 'free-access';
  valueOpenSource: boolean = false;
  valueFlexRadioRepositoryForm3: boolean = false;
  valuelUrlRepository: string;
  valueFlexRadioAPIForm3: boolean = false;
  valueApiUrl: string;
  valueFlexRadioCountriesForm3: boolean = false;
  valueCountryWork: string[] = [];
  valueExpert: boolean = false;
  valueFlexRadioMoreConstraints: boolean = false;

  //fourth page
  valueConstraintInfo: any[] = [];
  valueConstraintInfoOld: ConstraintsInfo[] = [];

  valueProfiles: number[] = [];
  valueConstraintType: number[] = [];
  valueConstraintCategory: any[] = [];

  arrayProfile: Profile[] = [];
  arrayConstraintType: ConstraintType[] = [];
  arrayConstraintCategory: ConstraintCategory[] = [];
  tmpConstrainsCategoryList: any[] = [];
  isCategoryReadOnly: boolean[] = [];

  //fifth page
  arraySolution: Solution[] = [];
  arrayTypology: TypologyTool[] = [];
  valueSolutions: number[] = [];
  valueTypologies: number[] = [];
  valueStops: number[] = [];
  tmpArrayStep: Step[] = [];
  valueSteps: number[] = [];
  valueFlexRadioTrainingForm6: boolean = false;
  valueTrainingPage: string = '';
  valueFlexRadioMoreLanguagesForm6: boolean = false;
  valueLanguagesAdditional: string[] = [];
  tmpValueLanguagesAdditional: Language[] = [];

  descriptionLanguage: any[] = [];
  valueFlexRadioMoreDescriptionsForm6: boolean = false;

  isStepReadOnly: boolean = true;

  //sixth page
  descriptionsDB: any[] = [];
  lengthShortDescriptionAdditional: number = 0;
  lengthLongDescriptionAdditional: number = 0

  arrayLengthShortDescriptionAdditional: number[] = [];
  arrayLengthLongDescriptionAdditional: number[] = [];

  //seventh page
  valueApplicationOwner: string = '';
  valueWebPage: string = '';
  valueContactEmailPropose: string = '';
  valueContactEmailOwner: string = '';

  adminComment: string = '';

  idGenerator: number = 0;

  imageUrl = GlobalConstants.backendURL;

  //to visualize on big form information
  countriesName: string = '';
  arrayConstraintsName: string[] = [];
  solutionsName: string = '';
  typologiesName: string = '';
  stopsName: string = '';
  stepsName: string = '';
  otherLanguagesName: string = '';
  isUserLogged: boolean;
  currentUser: User = new User();

  constructor(private toolService: ToolsService, private administrationPanelService: AdministrationPanelService, private countryService: CountryService, private modalService: NgbModal, private userService: UserService, public afAuth: AngularFireAuth) {}

  consoleJorge(){
    console.log(this.descriptionLanguage);
  }

  ngOnInit() {
    this.getGuid = this.administrationPanelService.getGuid$().subscribe((data: string) => {
      this.guid = data;
    })
    this.checkLogin();
    //this.getTools();
    this.getProposeTools();
    this.getLanguage();
    this.getStatus();
    this.getStopStep();
    this.getSolution();
    this.getTypology();
    this.getProfile();
    this.getConstraintType();
    this.getConstraintCategory();
  }

  ngOnDestroy(): void {
    if(this.getArrayTool != null) this.getArrayTool.unsubscribe();
    if(this.getArrayToolPropose != null) this.getArrayToolPropose.unsubscribe();
    if(this.getArrayStep != null) this.getArrayStep.unsubscribe();
    if(this.getArrayStop != null) this.getArrayStop.unsubscribe();
    if(this.getArrayLanguage != null) this.getArrayLanguage.unsubscribe();
    if(this.getArraySolution != null) this.getArraySolution.unsubscribe();
    if(this.getArrayTypology != null) this.getArrayTypology.unsubscribe();
    if(this.getArrayProfile != null) this.getArrayProfile.unsubscribe();
    if(this.getArrayConstraintType != null) this.getArrayConstraintType.unsubscribe();
    if(this.getArrayConstraintCategory != null) this.getArrayConstraintCategory.unsubscribe();
    if(this.getGuid != null) this.getGuid.unsubscribe();
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          console.log(userFromDB['data'].user_roles[0].role_id)
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;
            this.currentUser.user_roles = userFromDB['data'].user_roles;
            if(userFromDB['data'].user_roles[0].role_id == 2){
              //this.applyAutoFilter();
              this.valueFilterCountry = userFromDB['data'].country_id;
              this.getTools();
            }else{
              this.getTools();
            }
          }
          console.log(this.currentUser)
        });
      } else {
        this.isUserLogged = false;
      }
    });
  }

  applyAutoFilter(): void {
    // Supongamos que tienes un mapeo entre los países y los valores del filtro
    const countryFilterMapping = {
      3: 3,
      10: 10,
      12: 12,
      15: 15,
      20: 20,
      25: 25,
      26: 26, // Supongamos que el ID de España es 1
      // Agrega más mapeos según sea necesario
    };

    // Obtener el valor del filtro según el país del usuario
    const autoFilterValue = countryFilterMapping[this.currentUser.country];

    // Aplicar el filtro automáticamente
    if (autoFilterValue) {
      this.valueFilterCountryTool = this.currentUser.country;
      this.arrayTool = this.tmpArrayTool.filter(tool => tool.country_origin == this.valueFilterCountryTool);
      // Aquí puedes llamar a tu lógica de filtrado manual si es necesario
      this.applyFilter('tool');
    }
  }

  /*getTools(){
    this.getArrayTool = this.administrationPanelService.getArrayValidateTool$().subscribe((data: ToolsNew[]) => {

      if(data.length == 0){
        this.toolService.getValidateTools().toPromise().then((dataDb: NodeResponse) => {

          console.log('data tool DB -> ', dataDb)
          this.arrayTool = dataDb.data;
          this.tmpArrayTool = this.arrayTool;
          this.administrationPanelService.setArrayValidateTool(dataDb.data);
        })
      }else{
        console.log('data tool service -> ', data);
        this.arrayTool = data;
        this.tmpArrayTool = this.arrayTool;

      }
      //this.formatTable();

    })
  }*/


  getTools(){
    this.toolService.getListedTools(
      this.pageSize,
      (this.page - 1) * this.pageSize,
      this.valueFilterName.toLocaleLowerCase(),
      this.valueFilterStop,
      this.valueFilterStep,
      this.valueFilterCountryTool,
      null,
      null,
      null,
      null,
      null,
      []
    ).toPromise().then((dataDb: NodeResponse) => {
      console.log('data listed tools -> ', dataDb);

      this.arrayTool = dataDb.data;
      this.tmpArrayTool = this.arrayTool;
      this.counter = dataDb.arrayLengthCounter;
    })
  }

  getProposeTools(){
    this.getArrayToolPropose = this.administrationPanelService.getArrayProposeTool$().subscribe((data: any[]) => {

      if(data.length == 0){
        this.toolService.getProposeTools().toPromise().then((dataDB: NodeResponse) => {

          console.log('data tool PURPOSE DB -> ', dataDB)
          this.arrayToolPropose = dataDB.data;
          this.tmpArrayToolPropose = dataDB.data;
          this.administrationPanelService.setArrayProposeTool(dataDB.data);
        })
      }else{
        console.log('data tool PURPOSE service -> ', data);
          this.arrayToolPropose = data;
          this.tmpArrayToolPropose = data;
      }

    })
  }

  getLanguage(){
    this.administrationPanelService.getArrayLanguage$().subscribe((dataLanguage: Language[]) => {

      if(dataLanguage.length == 0){
        this.countryService.getLanguages().toPromise().then((dataLanguageDB: NodeResponse) => {
          console.log('data language DB ->', this.arrayLanguage);

          this.arrayLanguage = dataLanguageDB.data;
          this.administrationPanelService.setArrayLanguage(this.arrayLanguage);
        })
      }else{
        this.arrayLanguage = dataLanguage;
        console.log('data language service ->', this.arrayLanguage);
      }
    })
  }

  getStatus(){
    this.getArrayToolsSolution = this.administrationPanelService.getArrayToolStatus$().subscribe( (data: ToolStatus[]) => {

      if(data.length == 0){

        this.toolService.getToolStatus().toPromise().then((dataDB: NodeResponse) => {

          console.log('dataDB status tool -> ', dataDB.data);

          if(dataDB.status == true){
            this.arrayStatus = dataDB.data;
            this.administrationPanelService.setArrayToolStatus(this.arrayStatus);
          }
        })
      }else{
        console.log('data status tool -> ', data);
        this.arrayStatus = data;
      }
    })
  }

  getStopStep(){
    this.getArrayStep = this.administrationPanelService.getArrayStep$().subscribe((data: Step[]) => {

      if(data.length == 0){
        this.toolService.getSteps().toPromise().then((dataDb: NodeResponse) => {

          console.log('data step DB -> ', dataDb)
          this.arrayStep = dataDb.data;
          this.administrationPanelService.setArrayStep(dataDb.data);
        })
      }else{
        console.log('data step service -> ', data);
        this.arrayStep = data;
      }
    })

    this.getArrayStop = this.administrationPanelService.getArrayStop$().subscribe((data: Stop[]) => {

      if(data.length == 0){
        this.toolService.getStops().toPromise().then((dataDb: NodeResponse) => {

          console.log('data stop DB -> ', dataDb)
          this.arrayStop = dataDb.data;
          this.administrationPanelService.setArrayStop(dataDb.data);
        })
      }else{
        console.log('data stop service -> ', data);
        this.arrayStop = data;
      }
    })
  }

  getSolution(){
    this.administrationPanelService.getArraySolution$().subscribe((data: Solution[]) => {

      if(data.length == 0){
        this.toolService.getSolutions().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB solution -> ', dataDB)

          if(dataDB.status){
            this.arraySolution = dataDB.data;
            this.administrationPanelService.setArraySolution(this.arraySolution);
          }
        })
      }else{
        console.log('data solution -> ', data)
        this.arraySolution = data;
      }
    })
  }

  getTypology(){
    this.administrationPanelService.getArrayTypology$().subscribe((data: TypologyTool[]) => {
      if(data.length == 0){
        this.toolService.getTypologies().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB typology -> ', dataDB)

          if(dataDB.status){
            this.arrayTypology = dataDB.data;
            this.administrationPanelService.setArrayTypology(this.arrayTypology);
          }
        })
      }else{
        console.log('data typology -> ', data)
        this.arrayTypology = data;
      }
    })
  }

  getProfile(){

    this.administrationPanelService.getArrayProfile$().subscribe((data: Profile[]) => {
      if(data.length == 0){
        this.toolService.getProfiles().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB profile -> ', dataDB)

          if(dataDB.status){
            this.arrayProfile = dataDB.data;
            this.administrationPanelService.setArrayProfile(this.arrayProfile);
          }
        })
      }else{
        console.log('data profile -> ', data)
        this.arrayProfile = data;
      }
    })
  }

  getConstraintType(){
    this.administrationPanelService.getArrayConstraintType$().subscribe((data: ConstraintType[]) => {
      if(data.length == 0){
        this.toolService.getContraintsType().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB constraint type -> ', dataDB)

          if(dataDB.status){
            this.arrayConstraintType = dataDB.data;
            this.administrationPanelService.setArrayConstraintType(this.arrayConstraintType);
          }
        })
      }else{
        console.log('data constraint type -> ', data)
        this.arrayConstraintType = data;
      }
    })
  }

  getConstraintCategory(){
    this.administrationPanelService.getArrayConstraintCategory$().subscribe((data: ConstraintCategory[]) => {
      if(data.length == 0){
        this.toolService.getContraintsCategory().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB constraint category -> ', dataDB)

          if(dataDB.status){
            this.arrayConstraintCategory = dataDB.data;
            this.administrationPanelService.setArrayConstraintCategory(this.arrayConstraintCategory);
          }
        })
      }else{
        console.log('data constraint category -> ', data)
        this.arrayConstraintCategory = data;
      }
    })
  }

  toToolTable(){
    this.deleteFilters('tool');
    this.window = 1;
  }

  toCreateTool(){
    this.initVariablesForm();
    this.window = 2;
  }

  toProposeToolSection(){
    this.deleteFilters('proposeTool');
    this.window = 4;
  }

  toToolProposeTable(){
    this.deleteFilters('toolPropose');
    this.window = 4;
  }

  toUpdateTool(tool_id: number){
    this.initVariablesForm();

    this.toolService.getToolByUid(tool_id).toPromise().then((data: NodeResponse) => {
      if(data.status){
        let tool = data.data;

        console.log(tool)
        this.toolSelected = tool;
        //first page

        this.valueUid = tool.uid;
        this.valueCountry = tool.country_origin_data.id;
        this.valueName = tool.app_name;
        this.valueNameOriginal = tool.app_name_origin;
        this.valueShortDescription = tool.short_description;
        this.valueLongDescription = tool.long_description;
        this.valueShortDescriptionOriginal = tool.short_description_origin;
        this.valueLongDescriptionOriginal = tool.long_description_origin;
        this.valueURL = tool.url;

        if(this.valueURL == null || this.valueURL == ''){
          this.valueIsURL = false;
        }else{
          this.valueIsURL = true;
        }

        this.shortDescriptionChangeEvent();
        this.longDescriptionChangeEvent();
        this.shortDescriptionOriginalChangeEvent();
        this.longDescriptionOriginalChangeEvent();

        //second page

        this.mainImageName = tool.main_image;
        //console.log(toolSelected.tools_images.length)

        if(tool.tools_images.length > 0){
          for(let i = 0; i < tool.tools_images.length; i++){
            let name: string =  tool.tools_images[i].name;
            this.nameOfAdditionalImages += name.split('/')[4];

            if(i != (tool.tools_images.length - 1))  this.nameOfAdditionalImages += ', ';
          }
        }

        this.valueVideoURL = tool.video_url;
        this.additionalImages = tool.tools_images;
        this.isAllDocuments = false;
        this.toolsDocuments = tool.tools_documents;

        if(tool.tools_documents.length > 0){
          for(let i = 0; i < tool.tools_documents.length; i++){
            let name: string =  tool.tools_documents[i].name;
            this.namePdfDocuments += name.split('/')[4];

            if(i < (tool.tools_documents.length - 1  ))  this.namePdfDocuments    += ', ';
          }
        }

        if(this.namePdfDocuments != '' || this.nameOfAdditionalImages != '' || (this.valueVideoURL != null && this.valueVideoURL.length > 0)){
          this.valueFlexRadioMonitoring = true;
        }

        //third page
        this.valuePrototype = tool.prototypeValue;
        this.valueAccess = tool.access_characteristics;
        this.valueFlexRadioAccessForm3 = tool.login_access;
        this.valueOpenSource = tool.open_source;
        this.valuelUrlRepository = tool.repo_url;
        this.valueFlexRadioRepositoryForm3 = this.valuelUrlRepository != null && this.valuelUrlRepository != '' ? true : false;
        this.valueApiUrl = tool.api_url;
        this.valueFlexRadioAPIForm3 = this.valueApiUrl != null && this.valueApiUrl != '' ? true : false;
        tool.countries.forEach(e => this.valueCountryWork.push(e.id));
        this.valueFlexRadioCountriesForm3 = this.valueCountryWork.length > 0 ? true : false;
        this.valueExpert = tool.expert;
        this.valueFlexRadioMoreConstraints = tool.constraints_infos.length > 0 ? true : false;

        //fourth page
        this.tmpConstrainsCategoryList = [];
        for(let i = 0; i < tool.constraints_infos.length; i++){
          this.idGenerator++;
          this.tmpConstrainsCategoryList.push([]);

          this.valueConstraintInfo.push({
            profile_id: tool.constraints_infos[i].profile_id + '',
            constraint_type_id: tool.constraints_infos[i].constraint_type_id + '',
            constraint_category: [],
            tool_id: null,
            idg: this.idGenerator
          });
          console.log('value constraint info -> ', this.valueConstraintInfo);

          this.isCategoryReadOnly.push(true);
          this.categoryChangeEvent(i);

          console.log('lista de constraint de esta iteracion -> ', this.tmpConstrainsCategoryList[i]);
          let arrayCategoryCurrentConstraint = this.tmpConstrainsCategoryList[i];

          for(let j = 0; j < tool.constraints_infos[i].constraint_category.length; j++){
            for(let k = 0; k < arrayCategoryCurrentConstraint.length; k++){
              if(tool.constraints_infos[i].constraint_category[j] == arrayCategoryCurrentConstraint[k].name){
                this.valueConstraintInfo[i].constraint_category.push(arrayCategoryCurrentConstraint[k].name);
              }
              //console.log('constraint value -> ', tool.constraints_infos[i].constraint_category[j], typeof(tool.constraints_infos[i].constraint_category[j]), 'constraint array -> ', this.tmpConstrainsCategoryList[k], typeof(this.tmpConstrainsCategoryList[k].name));
            }
          }
        }


        //fifth sixth page
        tool.solutions.forEach(e => this.valueSolutions.push(e.id));
        tool.typologies.forEach(e => this.valueTypologies.push(e.id));
        tool.stops.forEach(e => this.valueStops.push(e.id));
        this.stopChangeEvent();
        tool.steps.forEach(e => this.valueSteps.push(e.id));

        if(this.valueSteps.length > 0) this.isStepReadOnly == true;

        this.valueTrainingPage = tool.training_url;
        this.valueFlexRadioTrainingForm6 = this.valueTrainingPage == null || this.valueTrainingPage == '' ? false : true;

        if(tool.languages.length > 0) {
          tool.languages.forEach(e => this.valueLanguagesAdditional.push(e.language_code));
          this.valueFlexRadioMoreLanguagesForm6 = true;

          if(tool.tools_descriptions.length > 0) {
            //this.descriptionLanguage.push(tool.tools_descriptions)
            tool.tools_descriptions.forEach(e => {
              this.descriptionLanguage.push({language_id: e.language_id, name_other_language: e.name_other_language, short_description: e.short_description, long_description: e.long_description, tool_id: null});
              this.arrayLengthShortDescriptionAdditional.push(0);
              this.arrayLengthLongDescriptionAdditional.push(0);
            })

            this.valueFlexRadioMoreDescriptionsForm6 = tool.tools_descriptions.length > 0 ? true : false;
          }
        }

        //seventh page
        this.valueApplicationOwner = tool.app_owner;
        this.valueWebPage = tool.webpage;
        this.valueContactEmailPropose = tool.contact_email_proposal;
        this.valueContactEmailOwner = tool.contact_email_owner;

        this.window = 3;
      }
    })


  }

  addSwitchFormCount(){

    if(this.switchFormCount <= this.totalFormCount){
      switch(this.switchFormCount){
        case 1:
          if(this.saveFirstForm()){
            this.switchFormCount++;
          }else{
            alert('You must to type in some inputs');
          }
          //this.switchFormCount++;

          break;

        case 2:
          if(this.saveSecondForm()){
            this.switchFormCount++;
          }else{
            alert('You must to type in some inputs');
          }
          //this.switchFormCount++;

          break;

        case 3:

          if(this.window == 2){
            this.valueConstraintInfo = []
            this.isCategoryReadOnly = [];
          }

          if(this.saveThirdForm()){
            if(this.valueFlexRadioMoreConstraints == true){
              this.idGenerator++;

              if(this.window == 2){
                this.valueConstraintInfo.push({profile_id: null, constraint_type_id: null, constraint_category: null, tool_id: null, idg: this.idGenerator});
                this.isCategoryReadOnly.push(true);
              }else if(this.window == 3){
                if(this.valueConstraintInfo.length == 0){
                  this.valueConstraintInfo.push({profile_id: null, constraint_type_id: null, constraint_category: null, tool_id: null, idg: this.idGenerator});
                  this.isCategoryReadOnly.push(true);
                }
              }

              this.switchFormCount++;
            }else{
              this.switchFormCount = this.switchFormCount + 2;
            }
          }else{
            alert('You must to type in some inputs');
          }

          break;

        case 4:
          let isSaved2 = true;
          console.log(this.isChangeConstraintsInfo())

          for(let i = 0; i < this.valueConstraintInfo.length; i++){
            if(this.saveFourthForm(i) == false){
              alert("You must to complete whole form");
              isSaved2 = false;
              break;
            }
          }

          if(isSaved2 == true){
            this.switchFormCount ++;
          }

          break;

        case 5:
          if(this.window == 2){
            this.descriptionLanguage = [];
            this.arrayLengthShortDescriptionAdditional = [];
            this.arrayLengthLongDescriptionAdditional = [];
          }


          if(this.saveFifthForm() == true){
            if(this.valueFlexRadioMoreDescriptionsForm6 == true){

              if(this.window == 2){
                this.descriptionLanguage.push({language_id: null, name_other_language: null, short_description: null, long_description: null, tool_id: null});
                this.arrayLengthShortDescriptionAdditional.push(0);
                this.arrayLengthLongDescriptionAdditional.push(0);

              }else if(this.window == 3){

                if(this.descriptionLanguage.length == 0){
                  this.descriptionLanguage.push({language_id: null, name_other_language: null, short_description: null, long_description: null, tool_id: null});
                  this.arrayLengthShortDescriptionAdditional.push(0);
                  this.arrayLengthLongDescriptionAdditional.push(0);
                }
              }

              this.switchFormCount++;
            }else{
              this.switchFormCount = this.switchFormCount + 2;
            }
          }else{
            alert("You must to complete whole form");
          }

          break;

        case 6:
          let isSaved = true;

          for(let i = 0; i < this.descriptionLanguage.length; i++){
            if(this.saveSixthForm(i) == false){
              isSaved = false;
              break;
            }
          }

          if(isSaved == true){
            this.switchFormCount++;
          }else{
            alert('You must to complete whole form');
          }

          break;

        case 7:
          if(this.window == 2) this.createTool();
          if(this.window == 3) this.updateTool();

          break;
      }
    }

  }

  deductSwitchFormCount(){

    if(this.switchFormCount == 5 && this.valueFlexRadioMoreConstraints == false){
      this.switchFormCount = this.switchFormCount - 2;
    }else if(this.switchFormCount == 7 && this.valueFlexRadioMoreDescriptionsForm6 == false){
      this.switchFormCount = this.switchFormCount - 2;
    }else{
      this.switchFormCount--;
    }
  }

  addForm(page: number){

    switch(this.switchFormCount){
      case 4:
        this.idGenerator++;
        this.valueConstraintInfo.push({profile_id: null, constraint_type_id: null, constraint_category: null, tool_id: null, idg: this.idGenerator});
        this.isCategoryReadOnly.push(true);
        break;
      case 6:
        this.idGenerator++;
        this.descriptionLanguage.push({language_id: null, name_other_language: null, short_description: null, long_description: null, tool_id: null});
        this.arrayLengthShortDescriptionAdditional.push(0);
        this.arrayLengthLongDescriptionAdditional.push(0);
        break;
    }

  }

  deductForm(page: number, index: number){

    switch(page){
      case 4:
        if(this.valueConstraintInfo.length > 0) {
          this.valueConstraintInfo.splice(index, 1);
          this.isCategoryReadOnly.splice(index, 1);
        }
        break;
      case 6:
        if(this.descriptionLanguage.length > 0){
          this.descriptionLanguage.splice(index, 1);
          this.arrayLengthShortDescriptionAdditional.splice(index, 1);
          this.arrayLengthLongDescriptionAdditional.splice(index, 1);
        }

        break;
    }
  }

  isChangeConstraintsInfo(): boolean{

    if(this.valueConstraintInfo == null && (this.valueConstraintInfoOld != null && this.valueConstraintInfoOld.length == 0)){
      return true;

    }else if(this.valueConstraintInfoOld == null && this.valueConstraintInfo != null){
      return true;

    }else{

      let first = JSON.stringify(this.valueConstraintInfo);
      let second = JSON.stringify(this.valueConstraintInfoOld);

      if(first != second){
        return true;
      }else{
        return false;
      }
    }
  }

  isChangeDescriptionLanguages(): boolean{

    if(this.descriptionLanguage == null && (this.descriptionsDB != null && this.descriptionsDB.length == 0)){
      return true;

    }else if(this.descriptionsDB == null && this.descriptionLanguage != null){
      return true;

    }else{

      let first = JSON.stringify(this.descriptionLanguage);
      let second = JSON.stringify(this.descriptionsDB);

      if(first != second){
        return true;
      }else{
        return false;
      }
    }
  }

  saveFirstForm(): boolean{

    let country = isNumber(this.valueCountry);
    let name = this.valueShortDescription.length > 0 ? true : false;

    let url = false;

    if(this.valueIsURL == false){
      url = true;
    }else if(this.valueIsURL == true && this.valueURL.length > 0){
      url = true;
    }

    let commentShort = this.valueShortDescription.length > 0 ? true : false;
    let commentLong = this.valueLongDescription.length > 0 ? true : false;

    return (url && commentShort && commentLong && country && name);
  }

  saveSecondForm(): boolean{
    let isMainImage = this.mainImage != null || this.mainImageName != '' ? true : false;
    let additional = false;

    if(this.valueFlexRadioMonitoring == false){
      additional = true;
    }else if(this.valueFlexRadioMonitoring == true){
      if((this.additionalImages.length > 0 || this.nameOfAdditionalImages != '') || this.valueVideoURL != ''|| (this.toolsDocuments.length > 0 || this.namePdfDocuments != '')){
        additional = true;
      }
    }

    console.log('second Form -> ', isMainImage && additional)

    return (isMainImage && additional);
  }

  saveThirdForm(): boolean{
    let access = false;
    let repository = false;
    let api = false;
    let countries = false;

    if(this.valueFlexRadioAccessForm3 == false){
      access = true;
    }else if(this.valueFlexRadioAccessForm3 == true && this.valueAccess != null){
      access = true;
    }

    if(this.valueFlexRadioRepositoryForm3 == false){
      repository = true;
    }else if(this.valueFlexRadioRepositoryForm3 == true && this.valuelUrlRepository != ''){
      repository = true;
    }

    if(this.valueFlexRadioAPIForm3 == false){
      api = true;
    }else if(this.valueFlexRadioAPIForm3 == true && this.valueApiUrl != ''){
      api = true;
    }

    if(this.valueFlexRadioCountriesForm3 == false){
      countries = true;
    }else if(this.valueFlexRadioCountriesForm3 == true && this.valueCountryWork.length > 0){
      countries = true;
    }

    console.log('third Form -> ', (access && repository && api && countries))

    return (access && repository && api && countries);
  }

  saveFourthForm(index: number){

    if(
        this.valueConstraintInfo[index].profile_id != null
        && this.valueConstraintInfo[index].constraint_type_id != null
        && this.valueConstraintInfo[index].constraint_category != null && this.valueConstraintInfo[index].constraint_category.length > 0
      ){
        return true;
    }else{
      return false;
    }

    return true;
  }

  saveFifthForm(){

    this.tmpValueLanguagesAdditional = [];

    let training = false;
    let countries = false;

    let solutions = this.valueSolutions.length > 0 ? true : false;
    let typologies = this.valueTypologies.length > 0 ? true : false;
    let stops = this.valueStops.length > 0 ? true : false;
    let steps = this.valueSteps.length > 0 ? true : false;

    if(this.valueFlexRadioTrainingForm6 == true && this.valueTrainingPage.length > 0){
      training = true;
    }else if(this.valueFlexRadioTrainingForm6 == false){
      training = true;
    }

    if(this.valueFlexRadioMoreLanguagesForm6 == false){
      countries = true;
    }else if(this.valueFlexRadioMoreLanguagesForm6 == true){
      if(this.valueLanguagesAdditional.length > 0){
        countries = true;

        for(let i = 0; i < this.valueLanguagesAdditional.length; i++){
          for(let j = 0; j < this.arrayLanguage.length; j++){
            if(this.valueLanguagesAdditional[i] == this.arrayLanguage[j].language_code){
              this.tmpValueLanguagesAdditional.push(this.arrayLanguage[j]);
            }
          }
        }

      }
    }

    console.log('fifth Form -> ', training , solutions , typologies , stops , steps, countries)

    return (training && solutions && typologies && stops && steps && countries)
  }

  saveSixthForm(index: number){

    if(
        this.descriptionLanguage[index].language_id != null
        && (this.descriptionLanguage[index].name_other_language != null && this.descriptionLanguage[index].name_other_language != '')
        && (this.descriptionLanguage[index].short_description != null && this.descriptionLanguage[index].short_description != '')
        && (this.descriptionLanguage[index].long_description != null && this.descriptionLanguage[index].long_description != '')
    ){
      return true;
    }else{
      return false;
    }
  }

  applyFilter(section: string){

    switch(section){
      case 'tool':
        this.getTools();

        break;

      case 'toolPropose':

        this.tmpArrayToolPropose = this.arrayToolPropose;

        if(this.valueFilterName != null && this.valueFilterName != undefined && this.valueFilterName != ''){
          this.tmpArrayToolPropose = this.tmpArrayToolPropose.filter(tool => {
            if(tool.app_name == null){
              return false;
            }else{
              return tool.app_name.toLowerCase().includes(this.valueFilterName.toLowerCase());
            }
          });
        }

        if(this.valueFilterCountry != null && this.valueFilterCountry != undefined){
          this.tmpArrayToolPropose = this.tmpArrayToolPropose.filter(tool => tool.country_origin_data.id == this.valueFilterCountry);
        }

        if(this.valueFilterStatus != null && this.valueFilterStatus != undefined){
          this.tmpArrayToolPropose = this.tmpArrayToolPropose.filter(tool => tool.tool_status.id == this.valueFilterStatus);
        }

        if(this.valueFilterUsername != null && this.valueFilterUsername != undefined && this.valueFilterUsername != ''){
          this.tmpArrayToolPropose = this.tmpArrayToolPropose.filter(tool => {
            if(tool.user.name == null){
              return false;
            }else{
              return tool.user.name.toLowerCase().includes(this.valueFilterUsername.toLowerCase());
            }
          });
        }
        break;
    }
  }

  deleteFilters(section: string){

    switch(section){

      case 'tool':

        this.valueFilterName = '';
        this.valueFilterStop = null;
        this.valueFilterStep = null;
        this.valueFilterCountry = null;

        this.getTools();

        break;

      case 'toolPropose':
        //this.arrayToolPropose = this.tmpArrayToolPropose;

        this.valueFilterName = '';
        this.valueFilterCountry = null;
        this.valueFilterStatus = null;
        this.valueFilterUsername = '';

        this.getProposeTools();
    }
  }

  refreshTable(section: string){

    switch(section){

      case 'tool':
        this.getTools();

        break;

      case 'toolPropose':

        this.toolService.getProposeTools().toPromise().then((dataDB: NodeResponse) => {

          console.log('data tool DB Propose -> ', dataDB);

          if(dataDB.status){
            this.arrayToolPropose = dataDB.data;
            this.tmpArrayToolPropose = dataDB.data;
            this.administrationPanelService.setArrayProposeTool(dataDB.data);
            this.deleteFilters('toolPropose');

          }


        })

        break;
    }
  }

  deleteTool(tool_uid: number){

    if(confirm('Do you want to delete this tool?') == true){

      this.toolService.getToolByUid(tool_uid).toPromise().then((data: NodeResponse) => {
        if(data.status == true){
          let tool: ToolsNew = data.data;

          this.toolService.deleteTool(tool).toPromise().then((dataDelete: NodeResponse) => {
            console.log(dataDelete);

            if(dataDelete.status){
              if(tool.tool_status_id == 3 || tool.tool_status_id == 4 || tool.tool_status_id == 5){

                let index = this.arrayTool.findIndex(e => e.uid == tool.uid);
                this.arrayTool.splice(index, 1);

                this.tmpArrayTool = [];
                this.tmpArrayTool = this.arrayTool;

                this.administrationPanelService.setArrayTool(this.arrayTool);
              }else{

                let index = this.arrayToolPropose.findIndex(e => e.uid == tool.uid);
                this.arrayToolPropose.splice(index, 1);

                this.tmpArrayToolPropose = [];
                this.tmpArrayToolPropose = this.arrayToolPropose;

                this.administrationPanelService.setArrayProposeTool(this.arrayToolPropose);
              }
            }else{
              alert(data.message);
            }
          })
        }
      })
    }
  }

  deleteProposeTool(uid: number){
    if(confirm('Do you want to delete this tool?') == true){

      this.toolService.deleteProposeTool(uid).toPromise().then((data: NodeResponse) => {
        console.log(data);

        if(data.status){
          let index = this.tmpArrayToolPropose.findIndex(e => e.uid == uid);
          this.tmpArrayToolPropose.splice(index, 1);

          let index2 = this.arrayToolPropose.findIndex(e => e.uid == uid);
          this.arrayToolPropose.splice(index2, 1);

          this.administrationPanelService.setArrayProposeTool(this.arrayToolPropose);
        }else{
          alert(data.message);
        }
      })
    }
  }

  createTool(){
    if(confirm('Do you want to create this tool?') == true){

      let nameMainImage = this.mainImage.name;
      let formData = new FormData();

      const body = {
        country_origin: this.valueCountry,
        app_name: this.valueName,
        app_name_origin: this.valueNameOriginal,
        url: this.valueURL,
        short_description: this.valueShortDescription,
        long_description: this.valueLongDescription,
        short_description_origin: this.valueShortDescriptionOriginal,
        long_description_origin: this.valueLongDescriptionOriginal,
        main_image: nameMainImage, //body.main_image,
        video_url: null, //body.video_url,
        prototypeValue: this.valuePrototype,
        login_access: this.valueFlexRadioAccessForm3,
        access_characteristics: this.valueAccess,
        open_source: this.valueOpenSource,
        repo_url: this.valuelUrlRepository,
        api_url: this.valueApiUrl,
        expert: this.valueExpert,
        training_url: this.valueTrainingPage,
        app_owner: this.valueApplicationOwner,
        webpage: this.valueWebPage,
        contact_email_proposal: this.valueContactEmailPropose,
        contact_email_owner: this.valueContactEmailOwner,
        toolStatus: 4,
        userUid: this.guid,
        arrayConstraintsInfo: this.valueConstraintInfo,
        arrayDescription: this.descriptionLanguage,
        arrayLanguage: this.valueLanguagesAdditional,
        arraySolution: this.valueSolutions,
        arrayTypology: this.valueTypologies,
        arrayStop: this.valueStops,
        arrayStep: this.valueSteps,
        arrayCountry: this.valueCountryWork
      }

      console.log('descripciones -> ', this.descriptionLanguage)

      if(this.valueFlexRadioMonitoring == true) body.video_url = this.valueVideoURL;

      formData.append('tool', JSON.stringify(body));
      formData.append('image', this.mainImage);

      this.toolService.create(formData).toPromise().then(async (dataTool: NodeResponse) => {
        console.log('response create tool -> ', dataTool);

        if(this.valueFlexRadioMonitoring == false){
          if(dataTool.status){
            this.arrayTool.push(dataTool.data);
            //this.tmpArrayTool.push(dataTool.data);
            this.administrationPanelService.setArrayValidateTool(this.arrayTool);

            console.log('console arriba')
          }
        }

        if(dataTool.status){

          if(this.valueFlexRadioMonitoring == true){
            try{

              for(let i = 0; i < this.additionalImages.length; i++){
                let imagesAdditional = new FormData();

                imagesAdditional.append('tool_id', dataTool.data.uid);
                imagesAdditional.append('image', this.additionalImages[i], this.additionalImages[i].name);

                await this.toolService.uploadImage(imagesAdditional).toPromise().then((data: NodeResponse) => {
                  console.log("response node imagesAdditional -> ", data);
                })

                if(i == 4) break;
              }


              for(let i = 0; i < this.toolsDocuments.length; i++){
                let bodyFile = new FormData();

                bodyFile.append('file', this.toolsDocuments[i], this.toolsDocuments[i].name);
                bodyFile.append('tool_id', dataTool.data.uid);

                await this.toolService.uploadDocuments(bodyFile).toPromise().then((data) => {
                  console.log("dataTool node file -> ", data);
                })

                if(i == 2) break;
              }

              await this.toolService.getToolByUid(dataTool.data.uid).toPromise().then((data: NodeResponse) => {
                console.log("dataTool node file -> ", data);

                if(data.status){
                  this.arrayTool.push(data.data);
                  //this.tmpArrayTool.push(data.data);
                  this.administrationPanelService.setArrayValidateTool(this.arrayTool);

                  console.log('console abajo')
                }
              })

              alert('Tool created');
              this.toToolTable();

            }catch(error){
              console.error(error)
            }

          }else{
            alert('Tool created');
            this.toToolTable();
          }

        }
      })
    }
  }

  updateTool(){
    if(confirm('Do you want to update this tool?') == true){

      let nameMainImage = this.mainImage == null ? this.mainImageName : this.mainImage.name;
      let formData = new FormData();

      const body = {
        uid: this.valueUid,
        country_origin: this.valueCountry,
        app_name: this.valueName,
        app_name_origin: this.valueNameOriginal,
        url: this.valueURL,
        short_description: this.valueShortDescription,
        long_description: this.valueLongDescription,
        short_description_origin: this.valueShortDescriptionOriginal,
        long_description_origin: this.valueLongDescriptionOriginal,
        main_image: nameMainImage, //body.main_image,
        video_url: null, //body.video_url,
        prototypeValue: this.valuePrototype,
        login_access: this.valueFlexRadioAccessForm3,
        access_characteristics: this.valueAccess,
        open_source: this.valueOpenSource,
        repo_url: this.valuelUrlRepository,
        api_url: this.valueApiUrl,
        expert: this.valueExpert,
        training_url: this.valueTrainingPage,
        app_owner: this.valueApplicationOwner,
        webpage: this.valueWebPage,
        contact_email_proposal: this.valueContactEmailPropose,
        contact_email_owner: this.valueContactEmailOwner,
        toolStatus: 4,
        userUid: this.guid,
        arrayConstraintsInfo: this.valueConstraintInfo,
        arrayDescription: this.descriptionLanguage,
        arrayLanguage: this.valueLanguagesAdditional,
        arraySolution: this.valueSolutions,
        arrayTypology: this.valueTypologies,
        arrayStop: this.valueStops,
        arrayStep: this.valueSteps,
        arrayCountry: this.valueCountryWork
      }

      console.log('descripciones -> ', this.descriptionLanguage)

      if(this.valueFlexRadioMonitoring == true) body.video_url = this.valueVideoURL;

      formData.append('tool', JSON.stringify(body));
      formData.append('oldTool', JSON.stringify(this.toolSelected));
      formData.append('image', this.mainImage);

      this.toolService.updateTool(formData).toPromise().then(async (data: NodeResponse) => {
        console.log('data update tool -> ', data)

        if(data.status){

          if(this.valueFlexRadioMonitoring == true){

            if(this.isAdditionalImagesChange == true){

              for(let i = 0; i < this.additionalImages.length; i++){
                console.log('estoy en el bucle de las imagenes -> ', data.data, this.valueUid);
                let formDataAdditionalImages = new FormData();
                console.log(this.valueUid);

                formDataAdditionalImages.append('image', this.additionalImages[i], this.additionalImages[i].name);
                formDataAdditionalImages.append('toolId', this.valueUid + '');
                let isFirst = i == 0 ? true : false;
                formDataAdditionalImages.append('isFirst', isFirst + '');

                await this.toolService.updateUploadImage(formDataAdditionalImages).toPromise().then((updateImage: NodeResponse) => {
                  console.log(updateImage);
                })
              }

            }

            if(this.isPdfFilesChange == true){

              for(let i = 0; i < this.toolsDocuments.length; i++){
                let bodyPdf = new FormData();

                bodyPdf.append('file', this.toolsDocuments[i], this.toolsDocuments[i].name);
                bodyPdf.append('toolId', this.valueUid + '');
                let isFirst = i == 0 ? true : false;
                bodyPdf.append('isFirst', isFirst + '');

                await this.toolService.updateUploadDocuments(bodyPdf).toPromise().then((data: NodeResponse) => {
                  console.log("dataTool node update pdf -> ", data);
                })

              }
            }

            await this.toolService.getToolByUid(this.valueUid).toPromise().then((data: NodeResponse) => {
              console.log("dataTool node file -> ", data);

              if(data.status){

                let index = this.arrayTool.findIndex(tool => tool.uid == this.valueUid);
                this.arrayTool.splice(index, 1);

                this.arrayTool.push(data.data);
                //this.tmpArrayTool.push(data.data);
                this.administrationPanelService.setArrayValidateTool(this.arrayTool);

                this.toToolTable();
              }
            })
          }else{

            if(this.toolSelected.tools_images.length > 0){
              await this.toolService.deleteImage(this.valueUid).toPromise().then((data: NodeResponse) => {
                console.log("dataImage node delete  -> ", data);
              })
            }

            if(this.toolSelected.tools_documents.length > 0){
              await this.toolService.deletePDF(this.valueUid).toPromise().then((data: NodeResponse) => {
                console.log("dataPDF node delete  -> ", data);
              })
            }

            await this.toolService.getToolByUid(this.valueUid).toPromise().then((data: NodeResponse) => {
              console.log("dataTool node file -> ", data);

              if(data.status){

                let index = this.arrayTool.findIndex(tool => tool.uid == this.valueUid);
                this.arrayTool.splice(index, 1);

                this.arrayTool.push(data.data);
                //this.tmpArrayTool.push(data.data);
                this.administrationPanelService.setArrayValidateTool(this.arrayTool);

                this.toToolTable();
              }
            })
          }
        }


      })
    }
  }

  updateToolPropose(status: number, content: any){

    if(confirm('Do you want to update this tool?') == true){
      console.log('status que le propongo -> ', status, this.toolSelected)

      if(status == 2 || status == 4){
        this.toolService.updateStatusTool(this.toolSelected.uid, status, '').toPromise().then((data: NodeResponse) => {
          console.log(data);

          if(data.status){

            let index = this.tmpArrayToolPropose.findIndex(e => e.uid == this.toolSelected.uid);
            this.tmpArrayToolPropose.splice(index, 1);

            let index2 = this.arrayToolPropose.findIndex(e => e.uid == this.toolSelected.uid);
            this.arrayToolPropose.splice(index2, 1);

            this.administrationPanelService.setArrayProposeTool(this.arrayToolPropose);

            if(status == 2){
              this.toToolProposeTable();
            }else{
              this.toolSelected.tool_status_id = 4;
              this.toolSelected.tool_status = this.arrayStatus.find(i => i.id == status);
              this.arrayTool.push(this.toolSelected);
              this.toToolTable();
            }

          }
        })
      }else if(status == 5 || status == 6){

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
          async (save) => {
            console.log(this.adminComment);

            this.toolService.updateStatusTool(this.toolSelected.uid, status, this.adminComment).toPromise().then((data: NodeResponse) => {
              console.log(data);

              if(data.status){
                let index = this.tmpArrayToolPropose.findIndex(e => e.uid == this.valueUid);
                this.tmpArrayToolPropose.splice(index, 1);

                let index2 = this.arrayToolPropose.findIndex(e => e.uid == this.valueUid);
                this.arrayToolPropose.splice(index2, 1);

                this.toToolProposeTable();

                this.administrationPanelService.setArrayProposeTool(this.arrayToolPropose);
              }
            })
          },

            //Si cierrra el MODAL
          (cancel) => {
            this.adminComment = '';
          },
        );
      }
    }

  }

  shortDescriptionChangeEvent(){
    if(this.valueShortDescription == null || this.valueShortDescription == undefined){
      this.lengthShortDescription = 0;
    }else{
      this.lengthShortDescription = this.valueShortDescription.length;
    }
  }

  longDescriptionChangeEvent(){

    if(this.valueLongDescription == null || this.valueLongDescription == undefined){
      this.lengthLongDescription = 0;
    }else{
      this.lengthLongDescription = this.valueLongDescription.length;
    }
  }

  shortDescriptionOriginalChangeEvent(){

    if(this.valueShortDescriptionOriginal == null || this.valueShortDescriptionOriginal == undefined){
      this.lengthShortDescriptionOriginal = 0;
    }else{
      this.lengthShortDescriptionOriginal = this.valueShortDescriptionOriginal.length;
    }
  }

  longDescriptionOriginalChangeEvent(){

    if(this.valueLongDescriptionOriginal == null || this.valueLongDescriptionOriginal == undefined){
      this.lengthLongDescriptionOriginal = 0;
    }else{
      this.lengthLongDescriptionOriginal = this.valueLongDescriptionOriginal.length;
    }
  }

  shortDescriptionAdditionalChangeEvent(index: number){
    //this.arrayLengthShortDescriptionAdditional[index] = this.descriptionLanguage[index].short_description.length;
  }

  longDescriptionAdditionalChangeEvent(index: number){
    this.arrayLengthLongDescriptionAdditional[index] = this.descriptionLanguage[index].long_description.length;
  }

  fileImageChangeEvent(event: any){

    this.mainImage = null;

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.mainImage = event.target.files[i];
      }else{
        alert('You must to select a IMAGE');
        this.mainImage = null;
      }
    }

  }

  fileOtherImageChangeEvent(event: any){

    let isAllImages = true;
    this.additionalImages = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.additionalImages.push(event.target.files[i]);
        this.isAdditionalImagesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es una imagen');
        isAllImages = false;
        break;
      }
    }

    if(isAllImages == false){
      this.additionalImages = [];
      alert('You must to select IMAGES');
    }
  }

  fileChangeEvent(event: any){

    let isAllDocuments = true;
    this.toolsDocuments = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log( 'Archivo seleccionado n' + i + '-> ', event.target.files[i]);

      if(event.target.files[i].type.substring(5) == 'pdf' || event.target.files[i].type == 'application/pdf'){
        this.toolsDocuments.push(event.target.files[i]);
        this.isPdfFilesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es un archivo PDF -> ');
        isAllDocuments = false;
        break;
      }
    }

    if(isAllDocuments == false){
      this.toolsDocuments = [];
      alert('You must to select PDF FILES');
    }
  }

  getNameCountry(idCountry: number): string{
    return this.arrayCountry.find(country => country.id == idCountry).country;
  }

  getNameStop(toolArrayStop: any[]): string[]{
    let value = [];

    for(let i = 0; i < toolArrayStop.length; i++){
      value.push(this.arrayStop.find(e => e.id == toolArrayStop[i].stop_id).stop);
    }

    return value;
  }

  getNameStep(toolArrayStep: any[]){
    let value = [];

    for(let i = 0; i < toolArrayStep.length; i++){
      value.push(this.arrayStep.find(e => e.id == toolArrayStep[i].step_id).step);
    }

    return value;
  }

  initVariablesForm(){
    this.switchFormCount = 1;

    this.valueUid = 0;
    this.valueCountry = null;
    this.valueName = '';
    this.valueNameOriginal = '';
    this.valueIsURL = false;
    this.valueURL = '';
    this.valueShortDescription = '';
    this.valueLongDescription = '';
    this.valueShortDescriptionOriginal = '';
    this.valueLongDescriptionOriginal = '';

    //second page
    this.mainImageName = "";
    this.mainImage = null;
    this.valueFlexRadioMonitoring = false;
    this.additionalImages = [];
    this.valueVideoURL = '';
    this.toolsDocuments = [];

    this.mainImageName = '';
    this.nameOfAdditionalImages = '';

    this.namePdfDocuments = '';

    this.isAdditionalImagesChange = false;
    this.isPdfFilesChange = false;

    //third page
    this.valuePrototype = false;
    this.valueFlexRadioAccessForm3 = false;
    this.valueAccess = 'free-access';
    this.valueOpenSource = false;
    this.valueFlexRadioRepositoryForm3 = false;
    this.valuelUrlRepository = '';
    this.valueFlexRadioAPIForm3 = false;
    this.valueApiUrl = '';
    this.valueFlexRadioCountriesForm3 = false;
    this.valueCountryWork = [];
    this.valueExpert = false;


    //fourth page
    this.valueFlexRadioMoreConstraints = false;
    this.valueConstraintInfoOld = [];
    this.tmpConstrainsCategoryList = [];
    this.isCategoryReadOnly = [];

    //fifth page
    this.valueConstraintInfo = [];

    //sixth page
    this.valueSolutions = [];
    this.valueTypologies = [];
    this.valueStops = [];
    this.valueSteps = [];
    this.valueFlexRadioTrainingForm6 = false;
    this.valueTrainingPage = '';
    this.valueFlexRadioMoreLanguagesForm6 = false;
    this.valueLanguagesAdditional = [];
    this.tmpValueLanguagesAdditional = [];

    this.descriptionLanguage = [];
    this.valueFlexRadioMoreDescriptionsForm6 = false;


    //seventh page
    this.descriptionsDB = [];
    this.lengthShortDescriptionAdditional = 0;
    this.lengthLongDescriptionAdditional = 0

    this.arrayLengthShortDescriptionAdditional = [];
    this.arrayLengthLongDescriptionAdditional = [];

    //eigth page
    this.valueApplicationOwner = '';
    this.valueWebPage = '';
    this.valueContactEmailPropose = '';
    this.valueContactEmailOwner = '';
  }

  stopChangeEvent(){
    console.log(this.tmpArrayStep, this.valueSteps);

    this.valueSteps = [];
    this.tmpArrayStep = [];
    this.isStepReadOnly = this.valueStops.length == 0 ? true : false;

    this.valueStops.forEach(stop => {
      if(stop == 1) this.tmpArrayStep.push(this.arrayStep[0], this.arrayStep[1]);
      if(stop == 2) this.tmpArrayStep.push(this.arrayStep[2], this.arrayStep[3]);
      if(stop == 3) this.tmpArrayStep.push(this.arrayStep[4], this.arrayStep[5], this.arrayStep[6]);
      if(stop == 4) this.tmpArrayStep.push(this.arrayStep[7], this.arrayStep[8], this.arrayStep[9], this.arrayStep[10]);
      if(stop == 5) this.tmpArrayStep.push(this.arrayStep[11], this.arrayStep[12], this.arrayStep[13], this.arrayStep[14]);
    })

  }

  categoryChangeEvent(index: number){
    this.isCategoryReadOnly[index] = true;
    this.valueConstraintInfo[index].constraint_category = [];
    this.tmpConstrainsCategoryList[index] = [];

    let idProfile = this.valueConstraintInfo[index].profile_id;
    let idType = this.valueConstraintInfo[index].constraint_type_id;

    if(idProfile != null && idType != null){
      this.arrayConstraintCategory.forEach(current => {
        if(current.profile_id == idProfile && current.constraint_type_id == idType) this.tmpConstrainsCategoryList[index].push(current);
      });
      this.isCategoryReadOnly[index] = false;
    }

    console.log(idProfile, idType, this.isCategoryReadOnly, this.tmpConstrainsCategoryList)

  }

  //big form propose

  toTinyForm(tool: ToolsNew){
    console.log(tool);
    this.toolSelected = tool;

    this.window = 5;
  }

  addSwitchFormProposeCount(){

    if(this.switchFormProposeCount <= this.totalFormCount){

      switch(this.switchFormProposeCount){
        case 1:
        case 2:
          this.switchFormProposeCount++;
          break;
        case 3:
          if(this.toolSelected.constraints_infos.length == 0) {
            this.switchFormProposeCount += 2;
          }else{
            this.switchFormProposeCount++;
          }
          break;
        case 4:
          this.switchFormProposeCount++;
          break;
        case 5:
          if(this.toolSelected.tools_descriptions.length == 0){
            this.switchFormProposeCount += 2;
          }else{
            this.switchFormProposeCount++;
          }
          break;
        case 6:
          this.switchFormProposeCount++;
          break;
      }

    }
  }

  deductSwitchFormProposeCount(){
    if(this.switchFormProposeCount > 0){
      this.switchFormProposeCount--;
    }
  }

  toBigForm(tool_id: number){
    this.initBigFormPropose();

    this.toolService.getToolByUid(tool_id).toPromise().then((data: NodeResponse) => {

      if(data.status){

        this.valueUid = data.data.uid;

        this.toolSelected = data.data;
        console.log('console in review -> ', this.toolSelected);

        if(this.toolSelected.tools_images.length > 0){
          for(let i = 0; i < this.toolSelected.tools_images.length; i++){
            let name: string =  this.toolSelected.tools_images[i].name;
            this.nameOfAdditionalImages += name.split('/')[4];

            if(i != (this.toolSelected.tools_images.length - 1))  this.nameOfAdditionalImages += ', ';
          }
        }

        if(this.toolSelected.tools_documents.length > 0){
          for(let i = 0; i < this.toolSelected.tools_documents.length; i++){
            let name: string =  this.toolSelected.tools_documents[i].name;
            this.namePdfDocuments += name.split('/')[4];

            if(i < (this.toolSelected.tools_documents.length - 1  ))  this.namePdfDocuments    += ', ';
          }
        }

        if(this.toolSelected.countries.length > 0){
          for(let i = 0; i < this.toolSelected.countries.length; i++){
            this.countriesName += this.toolSelected.countries[i].country;

            if(i < (this.toolSelected.countries.length - 1  ))  this.countriesName    += ', ';
          }
        }

        if(this.toolSelected.constraints_infos.length > 0){
          for(let i = 0; i < this.toolSelected.constraints_infos.length; i++){
            this.arrayConstraintsName.push('');
            for(let j = 0; j < this.toolSelected.constraints_infos[i].constraint_category.length; j++){
              //this.arrayConstraintsName[i] += this.toolSelected.constraints_infos[i].constraint_category[j];
              let category = this.arrayConstraintCategory.find(e => e.name == this.toolSelected.constraints_infos[i].constraint_category[j]).constraint_category;
              this.arrayConstraintsName[i] += category;

              if(j < (this.toolSelected.constraints_infos[i].constraint_category.length - 1  ))  this.arrayConstraintsName[i]    += ', ';
            }

          }
        }

        for(let i = 0; i < this.toolSelected.solutions.length; i++){
          this.solutionsName += this.toolSelected.solutions[i].solution;

          if(i < (this.toolSelected.solutions.length - 1  ))  this.solutionsName    += ', ';
        }

        for(let i = 0; i < this.toolSelected.typologies.length; i++){
          this.typologiesName += this.toolSelected.typologies[i].typology;

          if(i < (this.toolSelected.typologies.length - 1  ))  this.typologiesName    += ', ';
        }

        for(let i = 0; i < this.toolSelected.stops.length; i++){
          this.stopsName += this.toolSelected.stops[i].stop;

          if(i < (this.toolSelected.stops.length - 1  ))  this.stopsName    += ', ';
        }

        for(let i = 0; i < this.toolSelected.steps.length; i++){
          this.stepsName += this.toolSelected.steps[i].step;

          if(i < (this.toolSelected.steps.length - 1  ))  this.stepsName    += ', ';
        }

        for(let i = 0; i < this.toolSelected.languages.length; i++){
          this.otherLanguagesName += this.toolSelected.languages[i].name;

          if(i < (this.toolSelected.languages.length - 1  ))  this.otherLanguagesName    += ', ';
        }


        this.window = 6;
      }else{
        alert(data.message);
      }


    })


  }

  initBigFormPropose(){
    this.toolSelected = null;

    //second page
    this.mainImageName = '';
    this.nameOfAdditionalImages = '';
    this.namePdfDocuments = '';

    //third page
    this.countriesName = '';
  }

}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Property} from '../../shared/models/property';
import { CadastreESService } from '../../core/cadastre/ES/cadastreES.service';
import {DomSanitizer} from '@angular/platform-browser';
import {UserService} from '../../core/authentication/user.service';
import {User} from '../../shared/models/user';
import {AngularFireAuth} from '@angular/fire/auth';
import {Building} from '../../shared/models/building';
import {Typology} from '../../shared/models/typology';
import {TypologyService} from '../../core/typology/typology.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CadastreNLService} from '../../core/cadastre/NL/cadastre-nl.service';
import { CeeService } from 'src/app/core/wms/cee.service';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { BestPracticesService } from 'src/app/core/binding-data/best-practices/best-practices.service';
import * as L from 'leaflet';
import { Coordinates } from 'src/app/shared/models/coordinates';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';

@Component({
  selector: 'app-cee-cadastre-info',
  templateUrl: './cee-cadastre-info.component.html',
  styleUrls: ['./cee-cadastre-info.component.scss']
})
export class CeeCadastreInfoComponent implements OnInit, OnChanges {
  spinnerColor: string;
  propSelected: Property;
  propIsSelected: boolean;
  facadeImage: any;
  hasError: boolean;
  error: string;
  currentUser: User = new User();
  isUserLogged: boolean = false;
  modelFilters = {filtBl: '', filtEs: '', filtPt: '', filtPu: ''};
  propertiesFilter: Property[];
  mapControl: boolean;
  ruralBuilding: boolean;
  selectBuilding: boolean;
  errorLocation: boolean;
  data: any;
  optionSelected: number;

  isResidential: boolean = false;

  dataNumberCertificate: any;
  dataCalificationCEE: any;
  dataEnergyConsumed: any;
  dataCo2Emissions: any;
  calificationEnergyPrimary: any;
  dataCalificationEnergyPrimary: any;
  dataCadastreReference: any;

  numberCertificate: any;
  calificationCEE: any;
  energyConsumed: any;
  co2Emissions: any;
  cadastreReference: any;
  expDirection: any;
  caseCertificate: any;
  co2EmissionsCertificate2: any;
  energyConsumedCertificate2: any;
  numberCertificate2: any;
  calificationEnergyPrimaryCertificate2: any;
  calificationCO2EmissionsCertificate2: any;

  textSpinner: string;
  // Variables temp for 3 cases
  selectYear = false;
  years: string[];
  selectedYear: string;

  addressCompleteBuilding: string = null;
  arrayRefReferenceCompleteBuilding: string[] = [];
  isCompleteBuilding: boolean = false;
  allDataCompleteBuilding: any = null;

  arrayAddress: string[] = [];
  arrayRefReference: string[] = [];
  arrayData: any = null;
  arrayAllDataSingleHome: any[] = [];

  referenceSelected: any = null;
  addressSelected: any = null;

  arrayDoubleCEE: Map<string, object> = new Map();
  isDoubleCEE: boolean = null;
  doubleCeeCompleteBuilding: any = null;
  isDoubleCEECompleteBuilding: boolean = false;

  @Input() history: Building[];
  @Input() properties: Property[];
  @Input() building: Building;
  @Input() active: number;
  @Output() showMapEmitter = new EventEmitter<boolean>();
  @Output() calculateTypologyEmitter = new EventEmitter<any>();
  @Output() buildingCompleteEmitter = new EventEmitter<any>();
  @Output() optionEmitter = new EventEmitter<any>();

  constructor(private cadastreServiceES: CadastreESService,
    private cadastreNLService: CadastreNLService,
    private sanitizer: DomSanitizer,
    public afAuth: AngularFireAuth,
    private userService: UserService,
    private typologyService: TypologyService,
    private ceeServiceCV: CeeService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private bestPracticesService: BestPracticesService,
    private administrationPanelService: AdministrationPanelService
  ) {
      /*this.afAuth.onAuthStateChanged(user => {
        if (user) {
          this.currentUser = new User(user);
          this.isUserLogged = true;
        }
        else { this.isUserLogged = false; }
      });*/

      this.propertiesFilter = this.properties;
      this.spinnerColor = '#63c5ab';
  }

  ngOnInit(){
    this.years = [];
    for (let i = new Date().getFullYear(); i >= 1000; i--) {
      const year = String( i);
      this.years.push( year);
    }
    this.textSpinner = 'Loading ...';

    this.administrationPanelService.getGuid$().subscribe((guid: string) => {
      if(guid == null){
        this.isUserLogged = false;
      }else{
        this.isUserLogged = true;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {

    this.arrayAddress = [];
    this.calificationEnergyPrimary = null;
    this.calificationCEE = null;

    this.isResidential = false;
    if ( this.active === 1 && changes.building && changes.building.currentValue
      && changes.building.currentValue.country ) {
      if (  changes.building.currentValue.length > 0 && ( changes.building.currentValue[0].error ||
        changes.building.currentValue[0].error_service )) {
        this.hasError = true;
        this.error = changes.building.currentValue.error_service ? changes.building.currentValue.error_service : 'Cadastre Service is not available' ;
      } else {
        this.propertiesFilter = changes.building.currentValue.properties;
        this.properties = changes.building.currentValue.properties;
        this.hasError = false;
        this.error = '';
        if ( !changes.building.firstChange ) {
          this.propSelected = null;
          this.propIsSelected = false;
        }
        this.selectedYear = null;
        this.ruralBuilding = false;
        console.log(this.building)

        const coordinateX = this.building.point.x;
        const coordinateY = this.building.point.y;
        const coordinateLong = this.building.coordinates.lng;
        const coordinateLat = this.building.coordinates.lat;
        const coordinates: Coordinates = {
          coordinateX: coordinateX,
          coordinateY: coordinateY,
          coordinateLong: coordinateLong,
          coordinateLat: coordinateLat
        }
        this.bestPracticesService.setCoordinates(coordinates);
        console.log('coordinates -> ', coordinates);

        if( changes.building.currentValue.year ) {
          this.selectedYear = changes.building.currentValue.year;
          this.building.year = changes.building.currentValue.year;
        }
        this.building.typology = new Typology('', '', '', '', '', '',
            '', null, null, null);
        this.bestPracticesService.setCountry(this.building.country);
        console.log('country -> ', this.building.country);

        if ( this.building.country  === 'ES') {
            this.spinner.show();
            this.textSpinner = 'Waiting for the cadastre service ... ';
            console.log('estoy en españa');
            this.selectBuilding = true;
            this.selectYear = false;
            this.getInfoFromCadastre_ES(true);
            this.getCEE(coordinateX, coordinateY);

        } else if ( this.building.country  === 'NL' ) {
            console.log('estoy en paises bajos cee cadastre info')
            this.spinner.show();
            this.selectBuilding = true;
            this.selectYear = false;
            this.getInfoFromCadastre_NL();
        } else {
            console.log('pais random')
            this.isResidential = true;
            this.bestPracticesService.setAddressMap(this.building.address);
            this.showYearSelection();
        }
      }
      if ( changes.error && changes.error.currentValue) {
        this.error = changes.error.currentValue;
        this.hasError = true;
      }
    }
  }

  showErrorLocation() {
    const buildingTmp  = this.building;
    //this.building = null;
    this.spinner.show();
    this.errorLocation = true;
    this.selectBuilding = false;
    this.hasError = true;
    this.error = 'There is not information in this point, please move inside building ';
    this.building  = new Building(buildingTmp.country, buildingTmp.climateZone, buildingTmp.climateSubZone,
      '', buildingTmp.region, buildingTmp.provinceCode,
      buildingTmp.address, buildingTmp.altitudeCode, buildingTmp.coordinates, buildingTmp.point,
      [], null, '', null, null, false, null, [], 0);
    this.properties = [];
    this.propertiesFilter = [];
    this.spinner.hide();
  }

  showYearSelection() {
    const buildingTmp  = this.building;
    this.building = null;
    this.spinner.show();
    this.selectBuilding = true;
    this.selectYear = true;
    this.building  = new Building(buildingTmp.country, buildingTmp.climateZone, buildingTmp.climateSubZone,
      buildingTmp.year, buildingTmp.region, buildingTmp.provinceCode,
      buildingTmp.address, buildingTmp.altitudeCode, buildingTmp.coordinates, buildingTmp.point,
      [], null, '', null, null, false, null, [], 0);
    this.properties = [];
    this.propertiesFilter = [];
    this.spinner.hide();
  }
  initialData(): void{
    this.propSelected = null;
    this.propIsSelected = true;
  }

  /**
   * Assign to property selected the entire information from Cadastre service
   * @param rc: Property selected from the list
   */
   getDetailFromRC(rc: any): void{
    this.initialData();
    this.cadastreServiceES.getBuildingDetailsByRC(rc).subscribe(( pro) => {
      const parser2 = new DOMParser();
      const dataXML = parser2.parseFromString(pro, 'text/xml');
      const data = dataXML.getElementsByTagName('bico')[0];
      this.propSelected  = this.convertToProperty(data, rc);
    });
  }

  /**
   * Convert from xml format to Property object
   * @param info: xml information
   * @param rc: cadastre reference
   */
  convertToProperty(info: any, rc: string): Property {
    const address = info.getElementsByTagName('ldt')[0].textContent;
    const use = info.getElementsByTagName('luso')[0].textContent;
    const surfaceCons = info.getElementsByTagName('sfc').length > 0 ? info.getElementsByTagName('sfc')[0].textContent : '';
    const year = info.getElementsByTagName('ant').length > 0 ? info.getElementsByTagName('ant')[0].textContent : '';
    const surfaceGraph = info.getElementsByTagName('sfc')[0].textContent;
    const participation = info.getElementsByTagName('cpt').length > 0 ? info.getElementsByTagName('cpt')[0].textContent : '';
    this.cadastreServiceES.getFacadeImage(rc).subscribe( (baseImage: any) => {
      const urlCreator = window.URL;
      this.facadeImage = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(baseImage));
    });
    return new Property(rc, '', '', '', surfaceCons, '', '', '', year, '', address, use,
      surfaceGraph, participation, this.facadeImage, null, '', '', '');
  }


  filterBuilding(): void {
    if ( this.modelFilters.filtBl !== '' ||  this.modelFilters.filtEs !== '' ||
      this.modelFilters.filtPt !== '' ||  this.modelFilters.filtPu !== '' ) {
      let propTempToFilter = this.properties;
      Object.entries(this.modelFilters).forEach( ([key, value]) => {
        if ( key === 'filtBl' && value.length > 0) {
          propTempToFilter = propTempToFilter.filter(
            it => it.block.toLowerCase().includes(value.toLowerCase())
          );
        } else if ( key === 'filtEs' && value.length > 0) {
          propTempToFilter = propTempToFilter.filter(
            it => it.stair.toLowerCase().includes(value.toLowerCase())
          );
        } else if ( key === 'filtPt' && value.length > 0) {
          propTempToFilter = propTempToFilter.filter(
            it => it.floor.toLowerCase().includes(value.toLowerCase())
          );
        } else if ( key === 'filtPu' && value.length > 0) {
          propTempToFilter = propTempToFilter.filter(
            it => it.door.toLowerCase().includes(value.toLowerCase())
          );
        }
      });
      this.propertiesFilter = propTempToFilter;
    } else {
      this.propertiesFilter = this.properties;
    }
  }

  calculateTypology(): void {
    this.mapControl = true;
    this.showMapEmitter.emit(false);
    this.calculateTypologyEmitter.emit({ building: this.building, selected: true});
  }

  clearFilters(): void {
    this.modelFilters = {filtBl: '', filtEs: '', filtPt: '', filtPu: ''};
    this.filterBuilding();
  }

  /**
   * Convert info from xml format to Property object
   * @param prop: property information in xml format
   * @param addressMain
   */
  getInfoPropGeneral(prop: any, addressMain: string) {
    const rc1 = prop.getElementsByTagName('pc1')[0].textContent;
    const rc2 = prop.getElementsByTagName('pc2')[0].textContent;
    const rc3 = prop.getElementsByTagName('car')[0].textContent;
    const rc4 = prop.getElementsByTagName('cc1')[0].textContent;
    const rc5 = prop.getElementsByTagName('cc2')[0].textContent;
    let rc = '';
    rc = rc.concat( rc1, rc2, rc3, rc4, rc5);
    const propType = prop.getElementsByTagName('cn').length > 0 ? prop.getElementsByTagName('cn')[0].textContent : 'urban';

    if ( propType === 'RU') {
      this.ruralBuilding = true;
      return new Property(rc, addressMain, '', '', '', '', '', '', '', 'rural',
        '', '', '', '', '', null, '', '', '');
    } else {
      const tagLocInt = prop.getElementsByTagName('loint')[0];
      const block = tagLocInt.getElementsByTagName('bq').length > 0 ?
        tagLocInt.getElementsByTagName('bq')[0].textContent.split(': ')[0] : '';
      const stair = tagLocInt.getElementsByTagName('es').length > 0 ?
        tagLocInt.getElementsByTagName('es')[0].textContent.split(': ')[0] : '';
      const plant = tagLocInt.getElementsByTagName('pt').length > 0 ?
        tagLocInt.getElementsByTagName('pt')[0].textContent.split(': ')[0] : '';
      const door = tagLocInt.getElementsByTagName('pu').length > 0 ?
        tagLocInt.getElementsByTagName('pu')[0].textContent.split(': ')[0] : '';
      const postalCode = prop.getElementsByTagName('dp') && prop.getElementsByTagName('dp').length > 0 ?
        prop.getElementsByTagName('dp')[0].textContent : '';
      const prov = prop.getElementsByTagName('np') && prop.getElementsByTagName('np').length > 0 ?
        prop.getElementsByTagName('np')[0].textContent : '';
      const town = prop.getElementsByTagName('nm') && prop.getElementsByTagName('nm').length > 0 ?
        prop.getElementsByTagName('nm')[0].textContent : '';
      let logInt = '';
      const textBlock = block !== '' ? 'Bl: ' + block : '';
      const textStair = stair !== '' ? 'Es: ' + stair : '';
      const textPlant = plant !== '' ? 'Pl: ' + plant : '';
      const textDoor = door !== '' ? 'Pt: ' + door : '';
      logInt = logInt.concat(textBlock, ' ' , textStair, ' ' , textPlant , ' ' , textDoor);
      return new Property(rc, addressMain, plant, logInt, '', postalCode, prov, town, '', 'urban',
        '', '', '', '', '', null, block, stair, door);
    }
  }

  showMap(): void{
    this.mapControl = false;
    this.showMapEmitter.emit(true);
  }
  getYearFromCadastre(): void {
    this.cadastreServiceES.getBuildingDetailsByRC(this.properties[0].rc).subscribe((pro) => {
      const parser2 = new DOMParser();
      const dataXML = parser2.parseFromString(pro, 'text/xml');
      const data = dataXML.getElementsByTagName('bico')[0];
      if ( !this.building.year ) {
        this.building.year = this.convertToProperty(data, this.properties[0].rc).yearConstruction;
      }
      this.typologyService.getYearCode( this.building.year ).subscribe(resYear => {
        this.building.typology.yearCode = resYear['year_code'];
        this.buildingCompleteEmitter.emit(this.building);
        this.spinner.hide();
      });
    },
      err => {
        this.showYearSelection();
      });
  }
  getInfoFromParcel(information: string): any{
    const domParser = new DOMParser();
    const dataXML = domParser.parseFromString(information, 'text/xml');
    const use = dataXML.getElementsByTagName('bu-ext2d:currentUse').length > 0 ?
      dataXML.getElementsByTagName('bu-ext2d:currentUse')[0].textContent : '';
    const numberOfUnits = dataXML.getElementsByTagName('bu-ext2d:numberOfBuildingUnits').length > 0 ?
      dataXML.getElementsByTagName('bu-ext2d:numberOfBuildingUnits')[0].textContent : '';
    const numberOfResidentUnits = dataXML.getElementsByTagName('bu-ext2d:numberOfDwellings').length > 0 ?
      dataXML.getElementsByTagName('bu-ext2d:numberOfDwellings')[0].textContent : '';
    const area = dataXML.getElementsByTagName('bu-ext2d:OfficialArea').length > 0 ?
      dataXML.getElementsByTagName('bu-ext2d:OfficialArea')[0].getElementsByTagName('bu-ext2d:value')[0].textContent : '';
    return {
      use,
      numberOfUnits,
      numberOfResidentUnits,
      area
    };
  }

  getInfoFromINSPIRECP(information: string): any{
    const domParser = new DOMParser();
    const dataXML = domParser.parseFromString(information, 'text/xml');
    const buildedSurface = dataXML.getElementsByTagName('cp:areaValue').length > 0 ?
      dataXML.getElementsByTagName('cp:areaValue')[0].textContent : '';

    return buildedSurface;
  }

  getNumberOfFloorFromXML(information: string): number{
    const domParser = new DOMParser();
    const dataXML = domParser.parseFromString(information, 'text/xml');
    const floorsInTotal = dataXML.getElementsByTagName('bu-ext2d:numberOfFloorsAboveGround');
    let floors = 0;
    for ( let i = 0; i < floorsInTotal.length; i++){
      const numberInPart = +floorsInTotal[i].textContent;
      if ( floors < numberInPart){
        floors = +numberInPart;
      }
    }
    return floors;
  }
  getTypologyAutomatic(floors: number, dwellings: number, numberOfUnits: number,  surfaceIn: number, buildedSurface: number  ): void {
    const typology = { code: '', name: ''};
    if ( numberOfUnits === 1 ) {
      const surface = surfaceIn * 0.6;

      if ( buildedSurface < surface ) {
        typology.code = 'SFH'; typology.name = 'Single Family Home';
      }
      else {
        typology.code = 'TH'; typology.name = 'Terraced House';
      }
    }
    else if ( dwellings > 1 ) {
      if ( floors > 1 && floors <= 5 ) { typology.code = 'MFH'; typology.name = 'MultiFamily Home'; }
      else if ( floors > 5 ) { typology.code = 'AB'; typology.name = 'Apartment block'; }
    }
    this.building.typology.categoryCode = typology.code;
    this.building.typology.categoryName = typology.name;
    this.typologyService.getTypologyCode( this.building.year, this.building.country, this.building.climateZone,
      this.building.typology.categoryCode ).subscribe( resTypo => {
        console.log('datos diego ', resTypo);
        console.log(this.building)
        const dataRes = resTypo[0];
        if ( dataRes ) {
          this.building.typology.categoryPicCode = dataRes.category_pic_code;
          this.building.typology.buildingCode = dataRes.category.building_code;
          localStorage.setItem('typology', this.building.typology.categoryName);
          localStorage.setItem('addressBuilding', this.building.address);
          this.bestPracticesService.setAddressMap(this.building.address);
          console.log(this.building.address)
          localStorage.setItem('yearBuilding', this.building.year);
          this.bestPracticesService.setYearConstruction(this.building.year);
          //localStorage.setItem('facadeImage', this.properties[0].image);
          //this.bestPracticesService.setImageFacade(this.properties[0].image);
          this.building.typology.picName = dataRes.name;
          this.typologyService.getYearCode( this.building.year ).subscribe(resYear => {
            this.building.typology.yearCode = resYear['year_code'];
            this.calculateTypologyEmitter.emit({ building: this.building, selected: false});
          });
        } else {
          this.hasError = true;
          this.error = 'We do not have data in this climate zone';
        }
    });
  }
  getDataBuildingFromINSPIRE( parcel, partParcel, cp ): void {
    const infoFromParcel = this.getInfoFromParcel(parcel);
    const infoFromCadastralParcel = this.getInfoFromINSPIRECP(cp);
    const infoFromPartOfParcel = this.getNumberOfFloorFromXML(partParcel);
    const useCut = infoFromParcel.use.split('_');
    const use = useCut[useCut.length - 1];
    if (use === 'residential' ) {
      if ( this.properties.length > 0   && !this.building.year) {
        this.cadastreServiceES.getBuildingDetailsByRC(this.properties[0].rc).subscribe((pro) => {
          const parser2 = new DOMParser();
          const dataXML = parser2.parseFromString(pro, 'text/xml');
          console.log("catasto jorge -> ", dataXML)
          const cadatreReferenceFromCatastro = dataXML.getElementsByTagName('bico')[0].getElementsByTagName('bi')[0].getElementsByTagName('idbi')[0].getElementsByTagName('rc')[0];
          const cadastreTotal = cadatreReferenceFromCatastro.getElementsByTagName('pc1')[0].textContent + cadatreReferenceFromCatastro.getElementsByTagName('pc2')[0].textContent;
          this.bestPracticesService.setCadastreReference(cadastreTotal);
          console.log('referencia catastral desde catastro ->', cadatreReferenceFromCatastro);
          const data = dataXML.getElementsByTagName('bico')[0];
          const infoProp0 = this.convertToProperty(data, this.properties[0].rc);
          const year = infoProp0.yearConstruction;
          if ( infoProp0.surfaceCons !== '0'){
            this.typologyService.getYearCode( year ).subscribe(resYear => {
              this.building.use = use;
              this.building.surface = +infoFromParcel.area;
              this.building.year = year;
              this.building.typology.yearCode = resYear['year_code'];
              this.getTypologyAutomatic(infoFromPartOfParcel, +infoFromParcel.numberOfResidentUnits, +infoFromParcel.numberOfUnits,
                infoFromCadastralParcel, +infoFromParcel.area );
              this.buildingCompleteEmitter.emit(this.building);
              this.spinner.hide();
            });
          } else {
            this.hasError = true;
            this.error = 'This Building is not residential';
            this.spinner.hide();
          }
        });
      } else {
        this.getTypologyAutomatic(infoFromPartOfParcel, +infoFromParcel.numberOfResidentUnits, +infoFromParcel.numberOfUnits,
          infoFromCadastralParcel,
          +infoFromParcel.buildedSurface  );
        this.buildingCompleteEmitter.emit(this.building);
        this.spinner.hide();
      }
    } else {
      if ( infoFromParcel.area === '' && infoFromParcel.numberOfResidentUnits === '' &&
        infoFromParcel.numberOfUnits === ''  && infoFromParcel.use === '' ) {
        this.hasError = true;
        this.error = 'This Building is not residential';
      }
      this.building.typology.categoryName = 'Typology not available';
      this.spinner.hide();
    }
  }
  selectYearOption() {
    this.building.year = String(this.selectedYear);
  }
  getInfoFromCadastre_NL( ) {
    const buildingTmp = this.building;
    this.cadastreNLService.getGeneralInfoBuildingBYCoordinates(this.building.point.x, this.building.point.y).then( (data) => {
      const buildingInfo = JSON.parse(data).features[0].properties;
      buildingTmp.year = buildingInfo.bouwjaar;
      this.bestPracticesService.setYearConstruction(buildingInfo.bouwjaar);
      buildingTmp.use =  buildingInfo.gebruiksdoel;
      buildingTmp.rc = buildingInfo.identificatie;
      this.bestPracticesService.setCadastreReference(buildingInfo.identificatie);
      this.building = buildingTmp;
      this.bestPracticesService.setAddressMap(this.building.address);
      this.buildingCompleteEmitter.emit(this.building);
      this.spinner.hide();
    });
  }
  getInfoFromCadastre_ES(getTypology: boolean) {
    this.errorLocation = false;
    this.selectYear = false;
    const buildingTmp = this.building;
    this.cadastreServiceES.getRCByCoordinates(this.building.point.x, this.building.point.y).then( (data) => {
      this.selectBuilding = false;
      //this.building = null;
      const parser = new DOMParser();
      const dataFile = parser.parseFromString(data, 'text/xml');
      const err = dataFile.getElementsByTagName('err')[0];
      if ( err ){
        const desError = dataFile.getElementsByTagName('des')[0].textContent;
        const codError = dataFile.getElementsByTagName('cod')[0].textContent;
        if ( codError === '16') {
          this.showErrorLocation();
          this.spinner.hide();
        } else {
          this.showYearSelection();
          this.spinner.hide();
        }
        /*this.hasError = true;
        this.error = desError;
        this.spinner.hide();*/
      } else {
        this.selectBuilding = true;
        const rc1 = dataFile.getElementsByTagName('pc1').length > 0 ? dataFile.getElementsByTagName('pc1')[0].textContent : '';
        const rc2 = dataFile.getElementsByTagName('pc2')[0].textContent;
        const rcGeneral = rc1.concat(rc2);
        const addressMain = dataFile.getElementsByTagName('ldt')[0].textContent;
        this.cadastreServiceES.getBuildingDetailsByRC(rcGeneral).subscribe((prop) => {
          const parser2 = new DOMParser();
          const dataXML = parser2.parseFromString(prop, 'text/xml');
          // case: when request is only one property
          const propertyOnly = dataXML.getElementsByTagName('bico')[0];
          this.properties = [];
          if ( propertyOnly !== undefined ){
            const property = this.getInfoPropGeneral(propertyOnly, addressMain);
            this.properties.push(property);
          } else {
            // case: when request are many properties
            const properties = dataXML.getElementsByTagName('rcdnp');
            // tslint:disable-next-line:prefer-for-of
            for ( let i = 0; i < properties.length ; i++){
              const detail = properties[i];
              const property = this.getInfoPropGeneral(detail, addressMain);
              this.properties.push(property);
            }
            this.propertiesFilter = this.properties;
          }
          if ( !this.ruralBuilding ) {
            this.cadastreServiceES.getFacadeImage(this.properties[0].rc).subscribe( (baseImage: any) => {
              const urlCreator = window.URL;
              this.properties[0].image = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(baseImage));
              //localStorage.setItem('ImageFacade', this.properties[0].image);
              //this.bestPracticesService.setImageFacade(this.properties[0].image);
              this.properties[0].latlng = buildingTmp.coordinates;
              buildingTmp.year = this.properties[0].yearConstruction;
              buildingTmp.region = this.properties[0].province;
              buildingTmp.address = this.properties[0].address;
              buildingTmp.rc = rcGeneral;
              buildingTmp.properties = this.properties;
              this.building = buildingTmp;
              if ( getTypology ) {
                let getInfoFromINSPIRE = true;
                // Best case: Request info from Inspire

                const requestINSPIRE = this.cadastreServiceES.getBuildingInfoINSPIREParcel(this.building.rc).toPromise().then( parcel => {
                  if ( parcel && parcel.length > 0 ) {

                    const parser = new DOMParser();
                    const dataXML = parser.parseFromString(parcel, 'text/xml');

                    this.bestPracticesService.setParcel(parcel);
                    getInfoFromINSPIRE = true;

                    let usoParcela = dataXML.getElementsByTagName('gml:featureMember')[0].getElementsByTagName('bu-ext2d:currentUse')[0].textContent;
                    console.log('uso de la parcela', usoParcela);
                    if(usoParcela == '1_residential'){
                      this.isResidential = true;
                    }else{
                      this.isResidential = false;
                    }

                    this.cadastreServiceES.getBuildingInfoINSPIREPartParcel(this.building.rc).toPromise().then( partParcel => {
                      this.bestPracticesService.setPartParcel(partParcel);
                      this.cadastreServiceES.getBuildingInfoINSPIRECadastralParcel(this.building.rc).toPromise().then( cp => {
                        this.bestPracticesService.setCp(cp);
                        this.getDataBuildingFromINSPIRE(parcel, partParcel, cp);
                      },err => {
                        this.showYearSelection();
                      });
                    },err => {
                      this.showYearSelection();
                    });
                  }
                  else  {
                    this.showYearSelection();
                  }
                }, (error) => {
                  this.showYearSelection();
                });

                console.log('request inspire -> ', requestINSPIRE);

                // Case: Select Typology
                setTimeout(() => {
                  //requestINSPIRE.unsubscribe();
                  if ( !getInfoFromINSPIRE && this.properties.length > 0 && !this.building.year) {
                    this.getYearFromCadastre();
                  }
                }, 1500);

              } else {
                this.getYearFromCadastre();
              }
            });
          } else {
            this.showYearSelection();
          }
        });
      }
    }, error => {
      this.spinner.show();
      this.building = buildingTmp;
      if ( !this.errorLocation ) {
        this.selectYear = true;
        this.selectBuilding = true;
      } else {
        this.selectBuilding = false;
      }
      this.properties = [];
      this.propertiesFilter = [];
      this.spinner.hide();
    });
  }

  goBack() {
    this.optionEmitter.emit(1);
  }

  getCEE(coorX: string, coorY: string): any {
    this.ceeServiceCV.getGeneralInfoCEE(coorX, coorY).then(pro => {
      const parser = new DOMParser();
      const dataXML = parser.parseFromString(pro, 'text/xml');

      console.log('certificado energetico', dataXML);

      let calificationCO2EmissionsCEE = null;
      let calificationEnergyPrimaryCEE = null;
      let energyConsumed = null;
      let co2Emissions = null;
      let yearCertificate = null;
      let cadastreReference = null;


      if(dataXML.getElementsByTagName('certificados_layer')[0] != undefined){

        //console.log('image -> ', dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[0].getElementsByTagName('documentli')[0].textContent)
        this.bestPracticesService.setImageFacade(dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[0].getElementsByTagName('documentli')[0].textContent);

        this.arrayData = dataXML;

        this.referenceSelected = null;

        this.addressCompleteBuilding = null;
        this.arrayRefReferenceCompleteBuilding = [];
        this.allDataCompleteBuilding = null;
        this.isCompleteBuilding = false;
        this.doubleCeeCompleteBuilding = null;

        this.arrayAddress = [];
        this.arrayRefReference = [];
        this.arrayAllDataSingleHome = [];

        let indexDoubleCEECompleteBuilding = null;

        let dateOne = null;
        let dateTwo = null;

        try{
          for(let i = 0; i < dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature').length; i++){
            if(dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i].getElementsByTagName('usoedificio')[0].textContent != 'Local Comercial'){
              if(dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i].getElementsByTagName('usoedificio')[0].textContent == 'Edificio de Viviendas (Bloque Completo)'){
                if(this.arrayRefReferenceCompleteBuilding.length > 0){
                  this.arrayRefReferenceCompleteBuilding.push(dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i].getElementsByTagName('ref_referencia')[0].textContent);
                  indexDoubleCEECompleteBuilding = i;
                }
                if(this.isCompleteBuilding == false){
                  this.addressCompleteBuilding = dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i].getElementsByTagName('exp_direccion')[0].textContent;
                  this.allDataCompleteBuilding = dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i];
                  this.arrayRefReferenceCompleteBuilding.push(dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i].getElementsByTagName('ref_referencia')[0].textContent);
                  this.isCompleteBuilding = true;
                  dateOne = dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i].getElementsByTagName('codigo')[0].textContent.substring(1, 5);
                }

              }else{
                this.arrayAddress.push(dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i].getElementsByTagName('exp_direccion')[0].textContent);
                this.arrayRefReference.push(dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i].getElementsByTagName('ref_referencia')[0].textContent);
                this.arrayAllDataSingleHome.push(dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[i]);
              }
            }
          }
        }catch(e){
          console.log("no existe informacion de certificado energetico")
        }

        //DOUBLE CEE

        this.arrayDoubleCEE.clear();
        this.isDoubleCEE = false;

        if(this.arrayRefReference.length > 0){
          for(let i = 0; i < this.arrayRefReference.length; i++){
            let currentRefReference = this.arrayRefReference[i];
            let index = this.arrayRefReference.indexOf(currentRefReference);
            let indexRepeated = [];
            let isIndexed = false;

            while(index != -1){
              indexRepeated.push(index);
              index = this.arrayRefReference.indexOf(currentRefReference, index + 1);
              if(isIndexed == true) {
                this.arrayDoubleCEE.set(this.arrayRefReference[i], this.arrayAllDataSingleHome[i]);
              }
              isIndexed = true;
            }

            console.log('repetidos -> ', indexRepeated);
          }

          console.log("certificados vivienda individual -> ", this.arrayDoubleCEE);

        }

        if(this.arrayDoubleCEE.size > 0) this.isDoubleCEE = true;

        //delete duplicates address

        let duplicateArray = [];

        for(let [key, value] of this.arrayDoubleCEE){
          duplicateArray.push(key);
        }

        if(this.arrayDoubleCEE.size > 0){
          for(let i = 0; i < this.arrayRefReference.length; i++){
            let currentRefReference = this.arrayRefReference[i];
            for(let j = 0; j < duplicateArray.length; j++){
              if(duplicateArray[j] == currentRefReference){
                this.arrayAddress.splice(i, 1);
                this.arrayRefReference.splice(i, 1);
                duplicateArray.splice(j, 1);
                i = 0;
                break;
              }
            }
          }
        }

        //end delete duplicate address

        //DOUBLE CEE complete building

        this.isDoubleCEECompleteBuilding = false;

        if(this.isCompleteBuilding == true && this.arrayRefReferenceCompleteBuilding.length > 1 && this.arrayRefReferenceCompleteBuilding[0] == this.arrayRefReferenceCompleteBuilding[1]){
          dateTwo = dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[indexDoubleCEECompleteBuilding].getElementsByTagName('codigo')[0].textContent.substring(1, 5);
          if(dateOne != dateTwo){
            this.doubleCeeCompleteBuilding = dataXML.getElementsByTagName('certificados_layer')[0].getElementsByTagName('certificados_feature')[indexDoubleCEECompleteBuilding];
            console.log("doble certificado complete building -> ", this.doubleCeeCompleteBuilding);
            this.isDoubleCEECompleteBuilding = true;
          }

        }

        //END DOUBLE CEE


        console.log("console array direcciones -> ", this.arrayAddress)
        console.log("console array referencias -> ", this.arrayRefReference)
        console.log("xml array single home -> ", this.arrayAllDataSingleHome);

        console.log("console direccion complete building -> ", this.addressCompleteBuilding)
        console.log("console refreferencia complete building -> ", this.arrayRefReferenceCompleteBuilding[0])
        console.log("xml complete building -> ", this.allDataCompleteBuilding);

        if(this.isCompleteBuilding == false && this.arrayAllDataSingleHome.length > 0){
          calificationCO2EmissionsCEE = this.arrayAllDataSingleHome[0].getElementsByTagName('cer_emicalificacion')[0].textContent;
          calificationEnergyPrimaryCEE = this.arrayAllDataSingleHome[0].getElementsByTagName('cer_concalificacion')[0].textContent;
          energyConsumed = this.arrayAllDataSingleHome[0].getElementsByTagName('cer_contotal')[0].textContent;
          co2Emissions = this.arrayAllDataSingleHome[0].getElementsByTagName('cer_emitotal')[0].textContent;

          yearCertificate = this.arrayAllDataSingleHome[0].getElementsByTagName('codigo')[0].textContent.substring(1, 5);
          cadastreReference = this.arrayAllDataSingleHome[0].getElementsByTagName('reference')[0].textContent;
        }else{
          if(this.allDataCompleteBuilding != undefined){
            calificationCO2EmissionsCEE = this.allDataCompleteBuilding.getElementsByTagName('cer_emicalificacion')[0].textContent;
            calificationEnergyPrimaryCEE = this.allDataCompleteBuilding.getElementsByTagName('cer_concalificacion')[0].textContent;
            energyConsumed = this.allDataCompleteBuilding.getElementsByTagName('cer_contotal')[0].textContent;
            co2Emissions = this.allDataCompleteBuilding.getElementsByTagName('cer_emitotal')[0].textContent;

            yearCertificate = this.allDataCompleteBuilding.getElementsByTagName('codigo')[0].textContent.substring(1, 5);
            cadastreReference = this.allDataCompleteBuilding.getElementsByTagName('reference')[0].textContent;
          }
        }

        this.calificationCEE = calificationCO2EmissionsCEE;
        this.energyConsumed = energyConsumed;
        this.co2Emissions = co2Emissions;
        this.calificationEnergyPrimary = calificationEnergyPrimaryCEE;

        return { yearCertificate, calificationCO2EmissionsCEE, energyConsumed, co2Emissions, calificationEnergyPrimaryCEE, cadastreReference }

      }else{
        return { yearCertificate, calificationCO2EmissionsCEE, energyConsumed, co2Emissions, calificationEnergyPrimaryCEE, cadastreReference }
      }


    });
  }

  goToFormCEE() {

    if(this.isCompleteBuilding == true){
      this.bestPracticesService.setCompleteBuilding(this.allDataCompleteBuilding);
      this.bestPracticesService.setIsCompleteBuilding(true);
      this.bestPracticesService.setArraySingleHome(this.arrayAllDataSingleHome);


      if(this.isDoubleCEECompleteBuilding == true) this.bestPracticesService.setDoubleCEECompleteBuilding(this.doubleCeeCompleteBuilding);

      if(this.isDoubleCEE == true) this.bestPracticesService.setArrayDoubleCEE(this.arrayDoubleCEE);

    }else {
      if(this.referenceSelected == null){
        this.bestPracticesService.setIsCompleteBuilding(false);
        this.bestPracticesService.setBuilding(this.arrayAllDataSingleHome[0])
      }else{
        this.bestPracticesService.setBuilding(this.addressSelected);
        this.bestPracticesService.setIsCompleteBuilding(false);
      }

      if(this.isDoubleCEE == true) {
        for(let [key, value] of this.arrayDoubleCEE){
          if(key == this.referenceSelected){
            this.bestPracticesService.setDoubleCEE(value);
          }
        }
      }
    }
    this.optionEmitter.emit('formcee');
  }

  selectAddressEventChange(event){

    this.referenceSelected = event.target.value;

    let calificationCO2EmissionsCEE = null;
    let calificationEnergyPrimaryCEE = null;
    let energyConsumed = null;
    let co2Emissions = null;

    if(this.isCompleteBuilding == true){
      calificationCO2EmissionsCEE = this.allDataCompleteBuilding.getElementsByTagName('cer_emicalificacion')[0].textContent;
      calificationEnergyPrimaryCEE = this.allDataCompleteBuilding.getElementsByTagName('cer_concalificacion')[0].textContent;
      energyConsumed = this.allDataCompleteBuilding.getElementsByTagName('cer_contotal')[0].textContent;
      co2Emissions = this.allDataCompleteBuilding.getElementsByTagName('cer_emitotal')[0].textContent;

    }else{
      for(let i = 0; i < this.arrayAllDataSingleHome.length; i++){
        if(this.arrayAllDataSingleHome[i].getElementsByTagName('ref_referencia')[0].textContent == this.referenceSelected){

          calificationCO2EmissionsCEE = this.arrayAllDataSingleHome[i].getElementsByTagName('cer_emicalificacion')[0].textContent;
          calificationEnergyPrimaryCEE = this.arrayAllDataSingleHome[i].getElementsByTagName('cer_concalificacion')[0].textContent;
          energyConsumed = this.arrayAllDataSingleHome[i].getElementsByTagName('cer_contotal')[0].textContent;
          co2Emissions = this.arrayAllDataSingleHome[i].getElementsByTagName('cer_emitotal')[0].textContent;

          this.addressSelected = this.arrayAllDataSingleHome[i];
          break;
        }

      }
    }

    this.calificationCEE = calificationCO2EmissionsCEE;
    this.energyConsumed = energyConsumed;
    this.co2Emissions = co2Emissions;
    this.calificationEnergyPrimary = calificationEnergyPrimaryCEE;

    console.log(this.referenceSelected)
  }
}

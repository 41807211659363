import { Component, Input, OnInit } from '@angular/core';
import { CeeService } from 'src/app/core/wms/cee.service';
import { CeeBuilding } from 'src/app/shared/models/ceeBuilding';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { UserAllData } from 'src/app/shared/models/userAllData';

@Component({
  selector: 'app-my-ceebuildings',
  templateUrl: './my-ceebuildings.component.html',
  styleUrls: ['./my-ceebuildings.component.scss']
})
export class MyCeeBuildingsComponent implements OnInit {

  @Input() user: UserAllData = null;

  window = 1;

  arrayBuilding: CeeBuilding[] = [];

  //pagination
  page: number = 1;
  pageSize: number = 10;

  //pagination form
  switchFormCount: number = 1;
  totalFormCount: number = 8;

  constructor(private ceeBuildingService: CeeService) { }

  ngOnInit(): void {
    this.ceeBuildingService.getCeeBuildingByUid(this.user.uid).toPromise().then((data: NodeResponse) => {
      this.arrayBuilding = data.data;
    })
  }

}

<main style="padding-top: 2rem;">
  <div class="row">
    <div class="col-md-1 options-panel">
      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="switchMenu(0)">
          <img src="../../../assets/img/icons/users.png">
          <div class="a-option user-icon">
            User Table
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="switchMenu(1)">
          <img src="../../../assets/img/icons/menu_tool.png">
          <div class="a-option tools-icon">
            Menus
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="switchMenu(2)">
          <img src="../../../assets/img/icons/tools.png">
          <div class="a-option tools-icon">
            Tools
          </div>
        </a>
      </div>


      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="switchMenu(3)">
          <img src="../../../assets/img/icons/article_admin.png">
          <div class="a-option tools-icon">
            Articles
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="goToBestPractices()">
          <img src="../../../assets/img/icons/icons8-buildings-30.png">
          <div class="a-option tools-icon">
            Success Stories
          </div>
        </a>
      </div>

      <div class="d-flex icon">
        <a class="link-decoration" role="button" (click)="switchMenu(4)">
          <img src="../../../assets/img/icons/free_icon_1.svg" width="30" height="30">
          <div class="a-option tools-icon">
            Suggestions
          </div>
        </a>
      </div>
    </div>

    <div class="container">
      <div class="col-md-11">
        <div [ngSwitch]="menu">
          <div *ngSwitchCase="0"><app-user-table [guid]="guid" [arrayCountry]="arrayCountry"></app-user-table></div>
          <div *ngSwitchCase="1"><app-menu-form [arrayCountry]="arrayCountry"></app-menu-form></div>
          <div *ngSwitchCase="2"><app-tool-form [arrayCountry]="arrayCountry"></app-tool-form></div>
          <div *ngSwitchCase="3"><app-articles-table [arrayCountry]="arrayCountry" [guid]="guid"></app-articles-table></div>
          <div *ngSwitchCase="4"><app-suggestion-table></app-suggestion-table></div>
        </div>
      </div>
    </div>
  </div>
</main>

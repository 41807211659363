import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CeeService } from 'src/app/core/wms/cee.service';
import { Testimony } from 'src/app/shared/models/testimony';
import { CeeBuilding } from '../../shared/models/ceeBuilding';
import { Monitoring } from '../../shared/models/monitoring';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { curveBasis, curveBumpX, curveMonotoneX } from 'd3-shape';
import * as d3 from 'd3';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ceebuilding-data',
  templateUrl: './ceebuilding-data.component.html',
  styleUrls: ['./ceebuilding-data.component.scss']
})
export class CeebuildingDataComponent implements OnInit, AfterViewInit {

  adminComment: string = '';

  switchFormCount: number = 1;
  switchTotalFormCount: number = 6;

  @Output() optionEmitter = new EventEmitter<string>();
  @Input() ceeBuilding: CeeBuilding;

  urlImage: any;

  imageUrl = GlobalConstants.backendURL;

  tipologyCode: string = null;
  caseNme: string = null;

  letterCO2: string = null;
  letterEP: string = null;
  letterCO2_cert2: string = null;
  letterEP_cert2: string = null;

  savingTotal: string = null;

  testimony: Testimony = {
    id: null,
    name: null,
    quote: null,
    role: null,
    video_url: null,
    cee_building_id: null
  };
  monitoring: any[] = [];

  //images: Images[] = [];

  testimonies: any;
  renoPictures: any;
  monitoringBuilding: any;
  monitoringHealthBuilding: any;
  checked: boolean;

  testimonyPictures: any;

  fileMonitoringTemperaturePre: any;
  fileMonitoringTemperaturePreExterior: any;
  fileMonitoringRelativeHumidityPre: any;
  fileMonitoringRelativeHumidityPreExterior: any;
  fileMonitoringElectricityPre: any;
  fileMonitoringGasPre: any;
  fileMonitoringFossilFuelsPre: any;
  fileMonitoringOthersPre: any;

  fileMonitoringTemperaturePostInterior: any;
  fileMonitoringTemperaturePostExterior: any;
  fileMonitoringRelativeHumidityPostInterior: any;
  fileMonitoringRelativeHumidityPostExterior: any;
  fileMonitoringElectricityPost: any;
  fileMonitoringGasPost: any;
  fileMonitoringFossilFuelsPost: any;
  fileMonitoringOthersPost: any;
  fileMonitoringSelfProductionPre: any;
  fileMonitoringSelfProductionPost: any;

  percentSavingEp: any;

  dailyData: any = [];

  multi: any[] = [];
  multiTemperature: any[] = [];
  multiTemperatureExterior: any[] = [];
  multiTemperaturePreExterior: any[] = [];

  multiHumidity: any[] = [];
  multiHumidityPreExterior: any[] = [];
  multiHumidityPost: any[] = [];
  multiHumidityPostExterior: any[] = [];
  multiElectricity: any[] = [];
  multiElectricityPost: any[] = [];
  multiFossilFuels: any[] = [];
  multiOthers: any[] = [];
  multiGas: any[] = [];
  multiSelfProduction: any[] = [];
  multiSelfProductionPost: any[] = [];

  showExterior: boolean = false;
  showExteriorHRPre: boolean = false;
  showExteriorTemperaturePost: boolean = false;
  showExteriorTemperaturePre: boolean = false;

  imagenClass = '';

  emojiMap: { [key: string]: string } = {
    verde: '😀', // Emojis representando caras
    rojo: '😢',
    amarillo: '😕',
    naranja: '😵',
    azul: '🥶',
    transparent: '', // Emojis para otros casos
  };

  role: number = 0;
  guid: string = '';
  isSaved: boolean = null;

  @ViewChild('tuImagen') imagen: ElementRef;

  imagenClassStressors = '';

  @ViewChild('tuImagenStressors') imagenStressors: ElementRef;

  imagenClassThermalComfort = '';

  @ViewChild('tuImagenThermalComfort') imagenThermalComfort: ElementRef;

  constructor(
    private ceeService: CeeService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private administrationService: AdministrationPanelService,
    private modalService: NgbModal) { }

  async ngOnInit() {
    console.log('los datos de home-bp ->', this.ceeBuilding);

    this.administrationService.getRoleCurrentUser$().subscribe(data => {
      console.log('role home bp -> ', data);
      this.role = data;

      this.administrationService.getGuid$().subscribe(idUser => {
        this.guid = idUser;

        this.ceeService.isSavedCeeBuilding(this.ceeBuilding.id, this.guid).toPromise().then((data: NodeResponse) => {
          console.log('is saved -> ', data);
          this.isSaved = data.data;
        });
      });
    })

    await this.getValuesFromServices();
    await this.getTestimonies();
    await this.getRenovationPictures();
    await this.getMonitoringBuilding();
    await this.getTestimonyPictures();

    let image = this.ceeBuilding.facade_picture;

    this.urlImage = this.sanitizer.bypassSecurityTrustUrl(image);
    console.log('console de la imagen -> ',image);

    switch(this.ceeBuilding.tipology_id){
      case 'AB':
        this.tipologyCode = 'Apartment Block';
        break;
      case 'MFH':
        this.tipologyCode = 'Multi Family Home';
        break;
      case 'SFH':
        this.tipologyCode = 'Single Family Home';
        break;
      case 'TH':
        this.tipologyCode = 'Terraced House';
        break;
      default:
        this.tipologyCode = null;
        break;
    }

    switch(this.ceeBuilding.case_id){
      case 1:
        this.caseNme = 'Complete Building';
        break;
      case 2:
        this.caseNme = 'Single Home';
        break;
      default:
        this.caseNme = null;
        break;
    }

    switch(this.ceeBuilding.letter_co2){
      case 1:
        this.letterCO2 = "A";
        break;
      case 2:
        this.letterCO2 = "B";
        break;
      case 3:
        this.letterCO2 = "C";
        break;
      case 4:
        this.letterCO2 = "D";
        break;
      case 5:
        this.letterCO2 = "E";
        break;
      case 6:
        this.letterCO2 = "F";
        break;
      case 7:
        this.letterCO2 = "G";
        break;
      case null:
        this.letterCO2 = null;
        break;
    }

    switch(this.ceeBuilding.letter_ep){
      case 1:
        this.letterEP = "A";
        break;
      case 2:
        this.letterEP = "B";
        break;
      case 3:
        this.letterEP = "C";
        break;
      case 4:
        this.letterEP = "D";
        break;
      case 5:
        this.letterEP = "E";
        break;
      case 6:
        this.letterEP = "F";
        break;
      case 7:
        this.letterEP = "G";
        break;
      case null:
        this.letterEP = null;
        break;
    }

    switch(this.ceeBuilding.letter_co2_cert2){
      case 1:
        this.letterCO2_cert2 = "A";
        break;
      case 2:
        this.letterCO2_cert2 = "B";
        break;
      case 3:
        this.letterCO2_cert2 = "C";
        break;
      case 4:
        this.letterCO2_cert2 = "D";
        break;
      case 5:
        this.letterCO2_cert2 = "E";
        break;
      case 6:
        this.letterCO2_cert2 = "F";
        break;
      case 7:
        this.letterCO2_cert2 = "G";
        break;
      case null:
        this.letterCO2_cert2 = null;
        break;
    }

    switch(this.ceeBuilding.letter_ep_cert2){
      case 1:
        this.letterEP_cert2 = "A";
        break;
      case 2:
        this.letterEP_cert2 = "B";
        break;
      case 3:
        this.letterEP_cert2 = "C";
        break;
      case 4:
        this.letterEP_cert2 = "D";
        break;
      case 5:
        this.letterEP_cert2 = "E";
        break;
      case 6:
        this.letterEP_cert2 = "F";
        break;
      case 7:
        this.letterEP_cert2 = "G";
        break;
      case null:
        this.letterEP_cert2 = null;
        break;
    }

    this.certificateColors();

    const percentageCO2 = this.ceeBuilding.saving_co2_percent / 100;
    console.log(percentageCO2);

    const percentageEpnr = this.ceeBuilding.saving_ep_percent;
    console.log(percentageEpnr);

    const savingPercentage = percentageCO2 * percentageEpnr;

    const savingTotal = savingPercentage * 100;

    const savingDecimals = savingTotal / 100;

    this.savingTotal = savingDecimals.toFixed(0).toString();

    this.percentSavingEp = parseFloat(this.ceeBuilding.saving_ep_percent.toString());

    //this.getLinesReferences();
  }

   // Método para alternar entre la visualización de temperatura exterior e interior
  toggleViewHRPost() {
    this.showExterior = !this.showExterior;
  }

  toggleViewHRPre() {
    this.showExteriorHRPre = !this.showExteriorHRPre;
  }

  toggleViewTemperaturePost() {
    this.showExteriorTemperaturePost = !this.showExteriorTemperaturePost;
  }

  toggleViewTemperaturePre() {
    this.showExteriorTemperaturePre = !this.showExteriorTemperaturePre;
  }

  async getTestimonies(){
    this.ceeService.getTestimonyIdCeeBuilding(this.ceeBuilding.id).subscribe((data) => {
      this.testimonies = data;
      console.log(this.testimonies);
    })
  }

  async getRenovationPictures(){
    this.ceeService.getRenovationPicturesIdCeeBuilding(this.ceeBuilding.id).subscribe((data) => {
      this.renoPictures = data;
      console.log(this.renoPictures);
    })
  }

  async getTestimonyPictures(){
    this.ceeService.getImagesIdCeeBuilding(this.ceeBuilding.id).subscribe((data) => {
      this.testimonyPictures = data;
      console.log(this.testimonyPictures);
    })
  }

  async getMonitoringBuilding(){
    this.ceeService.getMonitoringIdCeeBuilding(this.ceeBuilding.id).subscribe((data) => {
      this.monitoringBuilding = data;
      console.log(this.monitoringBuilding);

      let idMonitoring = 0;
      for(let i = 0; i < this.monitoringBuilding.length; i++){
        idMonitoring = this.monitoringBuilding[i].id;
        console.log(idMonitoring);

        this.ceeService.getFileIdMonitoring(idMonitoring).subscribe((data) => {
          if(this.monitoringBuilding[i].cee_building_id == this.ceeBuilding.id && this.monitoringBuilding[i].variables_id == 3 && this.monitoringBuilding[i].phase_id == 1 && this.monitoringBuilding[i].location == true){
            this.fileMonitoringTemperaturePre = data;
            console.log(this.fileMonitoringTemperaturePre);
            this.processData(this.fileMonitoringTemperaturePre);
          }

          if(this.monitoringBuilding[i].cee_building_id == this.ceeBuilding.id && this.monitoringBuilding[i].variables_id == 3 && this.monitoringBuilding[i].phase_id == 1 && this.monitoringBuilding[i].location == false){
            this.fileMonitoringTemperaturePreExterior = data;
            console.log(this.fileMonitoringTemperaturePreExterior);
            this.processDataTemperaturePreExterior(this.fileMonitoringTemperaturePreExterior);
          }

          if(this.monitoringBuilding[i].cee_building_id == this.ceeBuilding.id && this.monitoringBuilding[i].variables_id == 3 && this.monitoringBuilding[i].phase_id === 2 && this.monitoringBuilding[i].location == true){
            this.fileMonitoringTemperaturePostInterior = data;
            console.log(this.fileMonitoringTemperaturePostInterior);
            this.processDataTemperaturePostInterior(this.fileMonitoringTemperaturePostInterior);
          }

          if(this.monitoringBuilding[i].cee_building_id == this.ceeBuilding.id && this.monitoringBuilding[i].variables_id == 3 && this.monitoringBuilding[i].phase_id === 2 && this.monitoringBuilding[i].location == false){
            this.fileMonitoringTemperaturePostExterior = data;
            console.log(this.fileMonitoringTemperaturePostExterior);
            this.processDataTemperaturePostExterior(this.fileMonitoringTemperaturePostExterior);
          }

          if(this.monitoringBuilding[i].cee_building_id == this.ceeBuilding.id && this.monitoringBuilding[i].variables_id === 4 && this.monitoringBuilding[i].phase_id == 1 && this.monitoringBuilding[i].location == true){
            this.fileMonitoringRelativeHumidityPre = data;
            console.log(this.fileMonitoringRelativeHumidityPre);
            this.processDataHumidity(this.fileMonitoringRelativeHumidityPre);
          }

          if(this.monitoringBuilding[i].cee_building_id == this.ceeBuilding.id && this.monitoringBuilding[i].variables_id === 4 && this.monitoringBuilding[i].phase_id == 1 && this.monitoringBuilding[i].location == false){
            this.fileMonitoringRelativeHumidityPreExterior = data;
            console.log(this.fileMonitoringRelativeHumidityPreExterior);
            this.processDataHumidityPreExterior(this.fileMonitoringRelativeHumidityPreExterior);
          }

          if(this.monitoringBuilding[i].cee_building_id == this.ceeBuilding.id && this.monitoringBuilding[i].variables_id === 4 && this.monitoringBuilding[i].phase_id == 2 && this.monitoringBuilding[i].location == true){
            this.fileMonitoringRelativeHumidityPostInterior = data;
            console.log(this.fileMonitoringRelativeHumidityPostInterior);
            this.processDataHumidityPostInterior(this.fileMonitoringRelativeHumidityPostInterior);
          }

          if(this.monitoringBuilding[i].cee_building_id == this.ceeBuilding.id && this.monitoringBuilding[i].variables_id === 4 && this.monitoringBuilding[i].phase_id == 2 && this.monitoringBuilding[i].location == false){
            this.fileMonitoringRelativeHumidityPostExterior = data;
            console.log(this.fileMonitoringRelativeHumidityPostExterior);
            this.processDataHumidityPostExterior(this.fileMonitoringRelativeHumidityPostExterior);
          }

          /*if(this.monitoringBuilding[i].variables_id == 16 && this.monitoringBuilding[i].phase_id == 1){
            this.fileMonitoringFossilFuelsPre = data;
            console.log(this.fileMonitoringFossilFuelsPre);
            this.processData(this.fileMonitoringFossilFuelsPre);
          }*/

          if(this.monitoringBuilding[i].variables_id == 1 && this.monitoringBuilding[i].phase_id == 1){
            this.fileMonitoringElectricityPre = data;
            this.processDataElectricity(this.fileMonitoringElectricityPre);
            console.log(this.fileMonitoringElectricityPre);
          }

          if(this.monitoringBuilding[i].variables_id == 1 && this.monitoringBuilding[i].phase_id == 2){
            this.fileMonitoringElectricityPost = data;
            console.log(this.fileMonitoringElectricityPost);
            this.processDataElectricityPost(this.fileMonitoringElectricityPost);
          }

          if(this.monitoringBuilding[i].variables_id == 17 && this.monitoringBuilding[i].phase_id == 1){
            this.fileMonitoringSelfProductionPre = data;
            this.processDataElectricitySelfProduction(this.fileMonitoringSelfProductionPre);
            console.log(this.fileMonitoringSelfProductionPre);
          }

          if(this.monitoringBuilding[i].variables_id == 17 && this.monitoringBuilding[i].phase_id == 2){
            this.fileMonitoringSelfProductionPost = data;
            console.log(this.fileMonitoringSelfProductionPost);
            this.processDataElectricitySelfProductionPost(this.fileMonitoringSelfProductionPost);
          }
        });
      }

      this.ceeService.getMonitoringHealthByIdMonitoring(idMonitoring).subscribe((data) => {
        this.monitoringHealthBuilding = data;
        console.log(this.monitoringHealthBuilding.data);


        for(let i = 0; i < this.monitoringHealthBuilding.data.length; i++){
        // We mark the options of the symptoms
        for (let j = 0; j < this.optionsSymptoms.length; j++) {
          const option = this.optionsSymptoms[j];
          option.checked = this.monitoringHealthBuilding.data[i].arraySymptomId.some(item => item.symptom_id === option.id);

          const checkedCount = this.optionsSymptoms.filter(option => option.checked).length;

          if (checkedCount == 0) {
            this.imagenClass = this.emojiMap['verde']; // Si no se marca ninguna opción, se ve en verde
          } else if (checkedCount >= 3) {
            this.imagenClass = this.emojiMap['rojo']; // Si se marcan 3 o más opciones, se ve en rojo
          } else {
            this.imagenClass = this.emojiMap['amarillo']; // En cualquier otro caso, se ve en amarillo
          }
        }

        // We mark the options of the stressors
        for (let k = 0; k < this.optionsStressors.length; k++) {
          const option = this.optionsStressors[k];
          option.checked = this.monitoringHealthBuilding.data[i].arrayStressorId.some(item => item.stressor_id === option.id);

          const checkedCount = this.optionsStressors.filter(option => option.checked).length;

          if (checkedCount == 0) {
            this.imagenClassStressors = this.emojiMap['verde']; // Si no se marca ninguna opción, se ve en verde
          } else if (checkedCount >= 4) {
            this.imagenClassStressors = this.emojiMap['rojo']; // Si se marcan 3 o más opciones, se ve en rojo
          } else {
            this.imagenClassStressors = this.emojiMap['amarillo']; // En cualquier otro caso, se ve en amarillo
          }
        }

        // We mark the options of thermal comfort
        for (let l = 0; l < this.optionsThermalComfort.length; l++) {
          const option = this.optionsThermalComfort[l];
          option.checked = this.monitoringHealthBuilding.data[i].arrayThermalComfortId.some(item => item.thermalComfort_id === option.id);

          const hotChecked = this.optionsThermalComfort.find(option => option.name === 'Hot');
          const coldChecked = this.optionsThermalComfort.find(option => option.name === 'Cold');
          const neutralChecked = this.optionsThermalComfort.find(option => option.name === 'Neutral');

          if(hotChecked.checked == true){
            this.imagenClassThermalComfort = this.emojiMap['naranja'];
          }else{
            this.imagenClassThermalComfort = this.emojiMap['transparent'];
          }

          if(coldChecked.checked == true){
            this.imagenClassThermalComfort = this.emojiMap['azul'];
          }else{
            this.imagenClassThermalComfort = this.emojiMap['transparent'];
          }
        }
      }

      });
    });
  }



  referenceLineTemperature = [];

  barDataElectricity: any[] = [];
  barDataElectricityPost: any[] = [];
  barDataFossilFuels: any[] = [];
  barDataSelfProduction: any[] = [];
  barDataSelfProductionPost: any[] = [];
  barDataOthers: any[] = [];

  async processData(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multi = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];

      /*if(this.monitoringBuilding[i].variables_id == 16){
        this.multiFossilFuels = [{
          name: 'Media',
          series: this.dailyData.map(item => ({
            name: new Date(item.date),
            value: item.average
          }))
        }];
      }

      if(this.monitoringBuilding[i].variables_id == 1){
        this.multiElectricity = [{
          name: 'Media',
          series: this.dailyData.map(item => ({
            name: new Date(item.date),
            value: item.average
          }))
        }];
      }

      if(this.monitoringBuilding[i].variables_id == 18){
        this.multiOthers = [{
          name: 'Media',
          series: this.dailyData.map(item => ({
            name: new Date(item.date),
            value: item.average
          }))
        }];
      }

      if(this.monitoringBuilding[i].variables_id == 17 && this.monitoringBuilding[i].phase_id == 1){
        this.multiSelfProduction = [{
          name: 'Media',
          series: this.dailyData.map(item => ({
            name: new Date(item.date),
            value: item.average
          }))
        }];
      }

      for(let i = 0; i < this.multiElectricity.length; i++){
        for(let j = 0; j < this.multiElectricity[i].series.length; j++){
          this.barDataElectricity.push(this.multiElectricity[i].series[j]);
        }
      }

      for(let i = 0; i < this.multiFossilFuels.length; i++){
        for(let j = 0; j < this.multiFossilFuels[i].series.length; j++){
          this.barDataFossilFuels.push(this.multiFossilFuels[i].series[j]);
        }
      }

      for(let i = 0; i < this.multiSelfProduction.length; i++){
        for(let j = 0; j < this.multiSelfProduction[i].series.length; j++){
          this.barDataSelfProduction.push(this.multiSelfProduction[i].series[j]);
        }
      }

      for(let i = 0; i < this.multiOthers.length; i++){
        for(let j = 0; j < this.multiOthers[i].series.length; j++){
          this.barDataOthers.push(this.multiOthers[i].series[j]);
        }
      }
      console.log(this.barDataElectricity);
      console.log(this.barDataSelfProduction);*/
  }

  async processDataTemperaturePreExterior(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiTemperaturePreExterior = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];
  }

  async processDataTemperaturePostInterior(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiTemperature = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];
  }

  async processDataTemperaturePostExterior(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiTemperatureExterior = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];
  }

  async processDataHumidity(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiHumidity = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];
  }

  async processDataHumidityPreExterior(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiHumidityPreExterior = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];
  }

  async processDataHumidityPostInterior(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiHumidityPost = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];
  }

  async processDataHumidityPostExterior(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiHumidityPostExterior = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];
  }

  async processDataElectricity(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiElectricity = [{
      name: 'Average',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];


    /*this.multiOthers = [{
      name: 'Media',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];*/

    for(let i = 0; i < this.multiElectricity.length; i++){
      for(let j = 0; j < this.multiElectricity[i].series.length; j++){
        this.barDataElectricity.push(this.multiElectricity[i].series[j]);
      }
    }

    /*for(let i = 0; i < this.multiFossilFuels.length; i++){
      for(let j = 0; j < this.multiFossilFuels[i].series.length; j++){
        this.barDataFossilFuels.push(this.multiFossilFuels[i].series[j]);
      }
    }

    for(let i = 0; i < this.multiOthers.length; i++){
      for(let j = 0; j < this.multiOthers[i].series.length; j++){
        this.barDataOthers.push(this.multiOthers[i].series[j]);
      }
    }*/

  }

  async processDataElectricityPost(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiElectricityPost = [{
      name: 'Media',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];

    for(let i = 0; i < this.multiElectricityPost.length; i++){
      for(let j = 0; j < this.multiElectricityPost[i].series.length; j++){
        this.barDataElectricityPost.push(this.multiElectricityPost[i].series[j]);
      }
    }

    /*for(let i = 0; i < this.multiFossilFuels.length; i++){
      for(let j = 0; j < this.multiFossilFuels[i].series.length; j++){
        this.barDataFossilFuels.push(this.multiFossilFuels[i].series[j]);
      }
    }

    for(let i = 0; i < this.multiOthers.length; i++){
      for(let j = 0; j < this.multiOthers[i].series.length; j++){
        this.barDataOthers.push(this.multiOthers[i].series[j]);
      }
    }*/

  }

  async processDataElectricitySelfProduction(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiSelfProduction = [{
      name: 'Media',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];

    for(let i = 0; i < this.multiSelfProduction.length; i++){
      for(let j = 0; j < this.multiSelfProduction[i].series.length; j++){
        this.barDataSelfProduction.push(this.multiSelfProduction[i].series[j]);
      }
    }
    console.log(this.barDataSelfProduction)
  }

  async processDataElectricitySelfProductionPost(data: any[]) {
    // Crear un objeto para almacenar los datos agrupados por día
    const dailyData: { [key: string]: { date: string, sum: number, count: number } } = {};

    // Procesar los datos
    for (const item of data) {
      const value = parseFloat(item.value);
      const timestamp = new Date(item.time_stamp);

      // Obtener la fecha en el formato "2023-02-03"
      const year = timestamp.getFullYear();
      const month = String(timestamp.getDate()).padStart(2, '0'); // Los meses son de 0 a 11
      const day = String(timestamp.getMonth() + 1).padStart(2, '0');
      const dateKey = `${year}-${day}-${month}`;

      //console.log(dateKey);

      if (this.isBetweenOctoberAndMarch(timestamp.getDay())) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23 },
          { name: 'Min', value: 18 },
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25 },
          { name: 'Min', value: 20 },
        ];
      }

      // Sumar el valor al día correspondiente
      if (!dailyData[dateKey]) {
        dailyData[dateKey] = {
          date: dateKey,
          sum: 0,
          count: 0
        };
      }

      dailyData[dateKey].sum += value;
      dailyData[dateKey].count++;
    }

    // Calcular la media para cada día y almacenar los resultados en this.dailyData
    this.dailyData = Object.values(dailyData).map((dailyEntry) => ({
      date: dailyEntry.date,
      sum: dailyEntry.sum,
      average: dailyEntry.sum / dailyEntry.count
    }));

    this.multiSelfProductionPost = [{
      name: 'Media',
      series: this.dailyData.map(item => ({
        name: new Date(item.date),
        value: item.average
      }))
    }];

    for(let i = 0; i < this.multiSelfProductionPost.length; i++){
      for(let j = 0; j < this.multiSelfProductionPost[i].series.length; j++){
        this.barDataSelfProductionPost.push(this.multiSelfProductionPost[i].series[j]);
      }
    }
    console.log(this.barDataSelfProductionPost)
  }

  certificateColors(){
    //Letter CO2
    if(this.letterCO2 == 'A'){
      let myElement = document.getElementById('letterCO2');
      myElement.style.backgroundColor = '#0c964d';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2 == 'B'){
      let myElement = document.getElementById('letterCO2');
      myElement.style.backgroundColor = '#21b14a';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2 == 'C'){
      let myElement = document.getElementById('letterCO2');
      myElement.style.backgroundColor = '#99ca39';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2 == 'D'){
      let myElement = document.getElementById('letterCO2');
      myElement.style.backgroundColor = '#ebe724';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2 == 'E'){
      let myElement = document.getElementById('letterCO2');
      myElement.style.backgroundColor = '#f0b619';
      myElement.style.borderRadius = '15px';
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2 == 'F'){
      let myElement = document.getElementById('letterCO2');
      myElement.style.backgroundColor = '#e17726';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2 == 'G'){
      let myElement = document.getElementById('letterCO2');
      myElement.style.backgroundColor = '#e52d29';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    //Letter Epnr
    if(this.letterEP == 'A'){
      let myElement = document.getElementById('letterEP');
      myElement.style.backgroundColor = '#0c964d';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP == 'B'){
      let myElement = document.getElementById('letterEP');
      myElement.style.backgroundColor = '#21b14a';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP == 'C'){
      let myElement = document.getElementById('letterEP');
      myElement.style.backgroundColor = '#99ca39';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP == 'D'){
      let myElement = document.getElementById('letterEP');
      myElement.style.backgroundColor = '#ebe724';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP == 'E'){
      let myElement = document.getElementById('letterEP');
      myElement.style.backgroundColor = '#f0b619';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP == 'F'){
      let myElement = document.getElementById('letterEP');
      myElement.style.backgroundColor = '#e17726';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP == 'G'){
      let myElement = document.getElementById('letterEP');
      myElement.style.backgroundColor = '#e52d29';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    //Certificate 2
    //Letter CO2
    if(this.letterCO2_cert2 == 'A'){
      let myElement = document.getElementById('letterCO2_cert2');
      myElement.style.backgroundColor = '#0c964d';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2_cert2 == 'B'){
      let myElement = document.getElementById('letterCO2_cert2');
      myElement.style.backgroundColor = '#21b14a';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2_cert2 == 'C'){
      let myElement = document.getElementById('letterCO2_cert2');
      myElement.style.backgroundColor = '#99ca39';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2_cert2 == 'D'){
      let myElement = document.getElementById('letterCO2_cert2');
      myElement.style.backgroundColor = '#ebe724';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2_cert2 == 'E'){
      let myElement = document.getElementById('letterCO2_cert2');
      myElement.style.backgroundColor = '#f0b619';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2_cert2 == 'F'){
      let myElement = document.getElementById('letterCO2_cert2');
      myElement.style.backgroundColor = '#e17726';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterCO2_cert2 == 'G'){
      let myElement = document.getElementById('letterCO2_cert2');
      myElement.style.backgroundColor = '#e52d29';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    //Letter Epnr
    if(this.letterEP_cert2 == 'A'){
      let myElement = document.getElementById('letterEP_cert2');
      myElement.style.backgroundColor = '#0c964d';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP_cert2 == 'B'){
      let myElement = document.getElementById('letterEP_cert2');
      myElement.style.backgroundColor = '#21b14a';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP_cert2 == 'C'){
      let myElement = document.getElementById('letterEP_cert2');
      myElement.style.backgroundColor = '#99ca39';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP_cert2 == 'D'){
      let myElement = document.getElementById('letterEP_cert2');
      myElement.style.backgroundColor = '#ebe724';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP_cert2 == 'E'){
      let myElement = document.getElementById('letterEP_cert2');
      myElement.style.backgroundColor = '#f0b619';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP_cert2 == 'F'){
      let myElement = document.getElementById('letterEP_cert2');
      myElement.style.backgroundColor = '#e17726';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }

    if(this.letterEP_cert2 == 'G'){
      let myElement = document.getElementById('letterEP_cert2');
      myElement.style.backgroundColor = '#e52d29';
      myElement.style.borderRadius = '15px'
      myElement.style.color = 'white';
      myElement.style.padding = '5px';
      myElement.style.fontWeight = 'bold';
    }
  }

  addSwitchFormCount(){
    if(this.switchFormCount <= this.switchTotalFormCount){
      this.switchFormCount++;
    }
  }

  deductSwitchFormCount(){
    if(this.switchFormCount > 0){
      this.switchFormCount--;
    }
  }

  isNanOrNull(variable: any): boolean{
    if(typeof variable == "string"){
      if(variable == null || variable == undefined || variable == ''){
        return false;
      }else{
        return true;
      }
    }else if(typeof variable == "number"){
      if(variable == null || variable == undefined || isNaN(variable)){
        return false;
      }else{
        return true;
      }
    }else if(typeof variable == "boolean"){
      if(variable == null || variable == undefined){
        return false;
      }else{
        return true;
      }
    }
  }

  goBack() {
    this.optionEmitter.emit('map');
  }

  consoleJorge(){
  }

  /*if(data == null){
    this.testimony.cee_building_id = null;
    this.testimony.id = null;
    this.testimony.name = null;
    this.testimony.quote = null;
    this.testimony.role = null;
    this.testimony.video_url = null;
  }else{
    this.testimony = data;
  }*/

  async getValuesFromServices(){

    try{
      await this.ceeService.getTestimonyIdCeeBuilding(this.ceeBuilding.id).toPromise().then((data: Testimony) => {
        if(data != null) this.testimony = data;
      });
    }catch(err){
      console.log("error de testimony -> ", err);
    }

    try{
      await this.ceeService.getMonitoringIdCeeBuilding(this.ceeBuilding.id).toPromise().then((data: Monitoring[]) => {

        if(data != null){
          for(let i = 0; i < data.length; i++){
            this.monitoring.push({season: null, phase: null, family: null, variable: null});

            switch(data[i].season_id){
              case 1:
                this.monitoring[i].season = 'Winter';
                break;
              case 2:
                this.monitoring[i].season = 'Summer';
                break;
            }

            switch(data[i].phase_id){
              case 1:
                this.monitoring[i].phase = 'Pre';
                break;
              case 2:
                this.monitoring[i].phase = 'Post';
                break;
            }

            switch(data[i].family_id){
              case 1:
                this.monitoring[i].family = 'Energy';
                break;
              case 2:
                this.monitoring[i].family = 'Comfort';
                break;
              case 3:
                this.monitoring[i].family = 'Health';
                break;
            }

            switch(data[i].variables_id){
              case 1:
                this.monitoring[i].variable = 'Consume';
                break;
              case 2:
                this.monitoring[i].variable = 'CO2';
                break;
              case 3:
                this.monitoring[i].variable = 'Temperature';
                break;
              case 4:
                this.monitoring[i].variable = 'Humidity';
                break;
              case 5:
                this.monitoring[i].variable = 'Lighting';
                break;
              case 6:
                this.monitoring[i].variable = 'Noise';
                break;
              case 7:
                this.monitoring[i].variable = "VOC's";
                break;
              case 8:
                this.monitoring[i].variable = 'Formaldehyde';
                break;
              case 9:
                this.monitoring[i].variable = 'PM2.5';
                break;
              case 10:
                this.monitoring[i].variable = 'PM10';
                break;
              case 11:
                this.monitoring[i].variable = 'Activity';
                break;
              case 12:
                this.monitoring[i].variable = 'Clothing';
                break;
              case 13:
                this.monitoring[i].variable = 'Stressors';
                break;
              case 14:
                this.monitoring[i].variable = 'Symptoms';
                break;
              case 15:
                this.monitoring[i].variable = 'Thermal comfort';
                break;
            }
          }
        }


      })
    }catch(err){
      console.log("error de monitoring data -> ", err)
    }
  }

  lowerBound = 500;
  upperBound = 1000;

  lineChartData = [
    {
      name: 'Relative Humidity',
      series: [
        { name: '2/3/2023', value: 49.87 },
        { name: '3/3/2023', value: 51.30 },
        { name: '4/3/2023', value: 51.69 },
        { name: '5/3/2023', value: 51.45 },
        { name: '6/3/2023', value: 63.94 },
        { name: '7/3/2023', value: 55.89 },
        { name: '8/3/2023', value: 56.84 },
        { name: '9/3/2023', value: 58.52 },
      ],
    }
  ];

  referenceLineHumidity = [
    {name: 'Max', value: 60},
    {name: 'Min', value: 40}
  ]

  viewTemp: [number, number] = [700, 400];
  showXAxisTemp = true;
  showYAxisTemp = true;
  gradientTemp = false;
  showLegendTemp = false;
  showXAxisLabelTemp = true;
  xAxisLabelTemp = 'Month';
  showYAxisLabelTemp = true;
  yAxisLabelTemp = 'Temperature ºC';

  // Personalización del eje X
  xAxisLabel = 'Month';
  showXAxisLabel = true;
  xAxisLabelFontSize = 16;
  showXAxis = true;
  xAxisTicks = true;
  xAxisTickFormatting = (value: any) => value + 'y';

  // Personalización del eje Y
  yAxisLabel = 'Relative Humidity %';
  showYAxisLabel = true;
  yAxisLabelFontSize = 14;
  showYAxis = true;
  yAxisTicks = true;
  yAxisTickFormatting = (value: any) => '$' + value;

  colorScheme = { domain: ['#AFABD4', '#5AA454'] };
  animations = true;
  showLegend= true;

  rectDimensions: any;

  onChartDimensionsChanged(dimensions: any): void {
    const yScale = dimensions.yScale;
    const yStart = yScale(60);
    const yEnd = yScale(40);

    this.rectDimensions = {
      x: dimensions.xScale.range()[0],
      y: yStart,
      width: dimensions.width,
      height: yEnd - yStart,
    };
  }

  // Lógica para identificar puntos por encima de 1000
  pointsAbove1000 = this.lineChartData[0].series.filter((point) => point.value > 1000).map((point) => point.name);


  lineChartDataTemperature = [
    {
      name: 'Temperature',
      series: [
        { name: '3/2/2023', value: 17.83 },
        { name: '3/3/2023', value: 17.57 },
        { name: '3/4/2023', value: 17.43 },
        { name: '3/5/2023', value: 16.97 },
        { name: '3/6/2023', value: 18.04 },
        { name: '3/7/2023', value: 18.68 },
        { name: '3/8/2023', value: 19.63 },
        { name: '3/9/2023', value: 20.17 },
      ],
    }
  ];

  barChartDataElectricity = [
    {
      "name": "October",
      "value": 95.1
    },
    {
      "name": "November",
      "value": 123.7
    },
    {
      "name": "December",
      "value": 181.5
    }
  ];

  barChartDataGas = [
    {
      "name": "October",
      "value": 8940000
    },
    {
      "name": "November",
      "value": 5000000
    },
    {
      "name": "December",
      "value": 7200000
    }
  ];

  // Obtiene el mes de una fecha en formato MM/DD/AAAA
  getMonthFromDate(dateString: string): number {
    const date = new Date(dateString);
    return date.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por eso sumamos 1.
  }

  // Verifica si el mes está entre octubre (10) y marzo (3)
  isBetweenOctoberAndMarch(month: number): boolean {
    return month >= 10 || month <= 3;
  }

  // Modifica referenceLineTemperature en base al mes de la serie
  getLinesReferences(){
    this.lineChartDataTemperature[0].series.forEach((dataPoint) => {
      const month = this.getMonthFromDate(dataPoint.name);
      if (this.isBetweenOctoberAndMarch(month)) {
        this.referenceLineTemperature = [
          { name: 'Max', value: 23, color: 'blue' }, // Cambiar aquí los valores para los meses entre octubre y marzo.
          { name: 'Min', value: 18, color: 'blue' }, // Cambiar aquí los valores para los meses entre octubre y marzo.
        ];
      } else {
        this.referenceLineTemperature = [
          { name: 'Max', value: 25, color: 'red' }, // Cambiar aquí los valores para los meses entre abril y septiembre.
          { name: 'Min', value: 20, color: 'red' }, // Cambiar aquí los valores para los meses entre abril y septiembre.
        ];
      }
    });

    console.log(this.referenceLineTemperature);
  }

  colorSchemeTemperature = { domain: ['#7BB488', '#E44D25'] };

  colorSchemeElectricity = { domain: ['#D7DF01'] };
  colorSchemeGas = { domain: ['#E44D25'] };
  colorSchemeSelfProduction = { domain: ['#7BB488'] };
  colorSchemeOthers = { domain: ['#5A1981'] };

  xAxisLabelElectricity = 'Month';
  yAxisLabelElectricity = 'Energy KWh';

  xAxisLabelGas = 'Month';
  yAxisLabelGas = 'Gas';

  // Personalización del eje X
  xAxisLabelTemperature = 'Month';
  showXAxisLabelTemperature = true;
  xAxisLabelFontSizeTemperature = 16;
  showXAxisTemperature = true;
  xAxisTicksTemperature = true;
  xAxisTickFormattingTemperature = (value: any) => value + 'y';

  // Personalización del eje Y temperature
  yAxisLabelTemperature = 'Temperature ºC';
  showYAxisLabelTemperature = true;
  yAxisLabelFontSizeTemperature = 14;
  showYAxisTemperature = true;
  yAxisTicksTemperature = true;
  yAxisTickFormattingTemperature = (value: any) => value + 'ºC';

  yAxisTickStepTemperature = 1;

  showLegendTemperature = true;

  curve = curveMonotoneX;

  getScaleY(): any {
    // Función para obtener la escala Y del gráfico
    const yAxisGroup = select(this.elementRef.nativeElement).select('.y.axis');
    const yAxis: any = yAxisGroup.node()?.__chart__;
    if (yAxis) {
      return scaleLinear().domain(yAxis.scale.domain()).range(yAxis.scale.range());
    }
    return null;
  }

  view: any;
  yScaleMin: any;
  yScaleMax: any;

  linePath(series: any): string {
    const xScale = d3.scaleTime()
      .domain(d3.extent(series, (d: any) => new Date(d.name)))
      .range([0, this.view[0]]);

    const yScale = d3.scaleLinear()
      .domain([this.yScaleMin, this.yScaleMax])
      .range([this.view[1], 0]);

    const line = d3.line()
      .x((d: any) => xScale(new Date(d.name)))
      .y((d: any) => yScale(d.value));

    return line(series);
  }

  optionsSymptoms = [
    { id: 1, name: 'Repiratory problems', checked: false },
    { id: 2, name: 'Eye irritation', checked: false },
    { id: 3, name: 'Sneezing or stuffy nose', checked: false },
    { id: 4, name: 'Headache', checked: false },
    { id: 5, name: 'Dry throat', checked: false },
    { id: 6, name: 'Fatigue or tiredness', checked: false }
  ];

  column1Options = this.optionsSymptoms.slice(0, 3);
  column2Options = this.optionsSymptoms.slice(3, 6);

  optionsStressors = [
    { id: 1, name: 'Dry air', checked: false },
    { id: 2, name: 'Moist', checked: false },
    { id: 3, name: 'Noise', checked: false },
    { id: 4, name: 'Stuffy air', checked: false },
    { id: 5, name: 'Drafts', checked: false },
    { id: 6, name: 'Odour', checked: false },
    { id: 7, name: 'Dust', checked: false },
    { id: 8, name: 'Gloom', checked: false },
    { id: 9, name: 'Glare', checked: false }
  ];

  stressors1Options = this.optionsStressors.slice(0, 3);
  stressors2Options = this.optionsStressors.slice(3, 6);
  stressors3Options = this.optionsStressors.slice(6, 9);

  optionsThermalComfort = [
    { id: 1, name: 'Hot', checked: false },
    { id: 2, name: 'Warm', checked: false },
    { id: 3, name: 'Slightly warm', checked: false },
    { id: 4, name: 'Neutral', checked: false },
    { id: 5, name: 'Slightly cool', checked: false },
    { id: 6, name: 'Cool', checked: false },
    { id: 7, name: 'Cold', checked: false },
  ];

  ngAfterViewInit() {
    this.updateImageClass();
  }

  updateImageClass() {
    const checkedCount = this.optionsSymptoms.filter(option => option.checked).length;

    /*if (checkedCount === 0) {
      this.imagenClass = 'verde'; // Si no se marca ninguna opción, se ve en verde
    } else if (checkedCount >= 3) {
      this.imagenClass = 'rojo'; // Si se marcan 3 o más opciones, se ve en rojo
    } else {
      this.imagenClass = 'amarillo'; // En cualquier otro caso, se ve en amarillo
    }*/
  }

  isURL(url: string): boolean {
    // Expresión regular para verificar si es una URL válida
    const urlPattern = /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/;
    return urlPattern.test(url);
  }

  isImage(url: string): boolean {
    // Obten la extensión del archivo
    const extension = url.substr(url.lastIndexOf('.') + 1);
    // Lista de extensiones de imágenes válidas (puedes ajustarla según tus necesidades)
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

    // Comprueba si la extensión está en la lista de extensiones de imágenes
    return imageExtensions.includes(extension.toLowerCase());
  }

  validateBuilding(){
    this.ceeService.changeStatusCeeBuilding(this.ceeBuilding.id, 1, '').toPromise().then((data: NodeResponse) => {
      console.log('response validate -> ', data)
    })
  }

  /*rejectBuild(){
    this.ceeService.changeStatusCeeBuilding(this.ceeBuilding.id, 2, '').toPromise().then((data: NodeResponse) => {
      console.log('response validate -> ', data)
    })
  }*/

  rejectBuild(content: any){

    if(confirm('Do you want to update this tool?') == true){

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
          async (save) => {
            console.log(this.adminComment);

            this.ceeService.changeStatusCeeBuilding(this.ceeBuilding.id, 2, this.adminComment).toPromise().then((data: NodeResponse) => {

              console.log('response reject cee_building -> ', data);

              if(data.status){

                //let index = this.arrayToolPropose.findIndex(e => e.uid == this.valueUid);
                //this.arrayToolPropose.splice(index, 1);

                //this.toToolProposeTable();

                //this.administrationPanelService.setArrayProposeTool(this.arrayToolPropose);
              }
            })
          },

            //Si cierrra el MODAL
          (cancel) => {
            this.adminComment = '';
          },
        );
    }

  }

  saveUserCeeBuilding(){
    this.ceeService.saveUserCeeBuilding(this.ceeBuilding.id, this.guid).toPromise().then((data: NodeResponse) => {
      console.log('usuario salvado -> ', data);

      if(data.status){
        this.isSaved = true;
      }
    })
  }

  deleteUserCeeBuilding(){
    this.ceeService.deleteASavedUserCeeBuilding(this.ceeBuilding.id, this.guid).toPromise().then((data: NodeResponse) => {
      console.log('usuario borrado de save -> ', data)

      if(data.status){
        this.isSaved = false;
      }
    })
  }

}

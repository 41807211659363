<!--<div class="col-1">
  <button class="form-control btn btn-info" id="refreshMenu" name="refreshMenu" (click)="consoleJorge()">Consolame</button>
</div>-->

<div *ngIf="window == 3; else elseBlock">
  <div class="d-flex justify-content-left navButtons">
    <button type="button" class="btn btn-link buttonsAdminTools" (click)="toMenuTable()"><div class="h3"><- {{"SUCCESS_STORIES_RESULTS.BUTTON_BACK" | translate}}</div></button>
  </div>
</div>

<ng-template #elseBlock>
  <div class="d-flex justify-content-center navButtons">
    <button type="button" class="btn btn-link buttonsAdminTools" (click)="toCreateMenu()"><div class="h3">{{"ADMINISTRATION_PANEL.CREATE_MENU" | translate}}</div></button>
    <button type="button" class="btn btn-link buttonsAdminTools" (click)="toMenuTable()"><div class="h3">{{"ADMINISTRATION_PANEL.MENU_TABLE" | translate}}</div></button>
  </div>
</ng-template>

<!--Table-->
<div *ngIf="window == 1">
  <form>

    <div class="form-group row">

      <div class="col-2">
        <input type="text" class="form-control" id="nameFilterMenu" name="nameFilterMenu" [(ngModel)]="valueFilterNameMenu" placeholder="Name filter">
      </div>

      <div class="col-2">
        <ng-select name="valueFilterCountryMenu" id="valueFilterCountryMenu" [(ngModel)]="valueFilterCountryMenu" placeholder="Country filter" [disabled]="currentUser.user_roles[0].role_id == 2">
          <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
        </ng-select>
      </div>

      <div class="col-4">
        <ng-select name="stopFilterMenu" id="stopFilterMenu" [(ngModel)]="valueFilterStopUidMenu" placeholder="Stop filter">
          <ng-option *ngFor="let stop of arrayStop" [value]="stop.id">{{stop.stop}}</ng-option>1
        </ng-select>
      </div>

      <div class="col-1">
        <button class="form-control btn btn-warning" id="applyFilterMenu" name="applyFilterMenu" (click)="applyFilter()">{{"ADMINISTRATION_PANEL.APPLY_BUTTON" | translate}}</button>
      </div>

      <div class="col-1">
        <button class="form-control btn btn-danger" id="deleteFilterMenu" name="deleteFilterMenu" (click)="deleteAllFilters('menu')">{{"ADMINISTRATION_PANEL.DELETE_BUTTON" | translate}}</button>
      </div>

      <div class="col-2">
        <button class="form-control btn btn-info" id="refreshMenu" name="refreshMenu" (click)="refreshTable('menu')">{{"ADMINISTRATION_PANEL.REFRESH_BUTTON" | translate}}</button>
      </div>

    </div>

    <div class="form-group row">
      <table class="table table-hover" id="tableMenu" name="tableMenu">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Country</th>
            <th scope="col">Stops</th>
            <th scope="col">Short description</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let menu of tmpArrayMenu | slice: (pageMenu-1) * pageSizeMenu : (pageMenu-1) * pageSizeMenu + pageSizeMenu; index as i">
            <td>{{menu.name}}</td>
            <td>{{menu.country.country_code}}</td>
            <td><p *ngFor="let stop of menu.menu_stops">{{stop.stop.stop}}</p></td>
            <td>{{menu.short_description}}</td>
            <td>
                <button class="btn btn-light" (click)="initUpdateMenu(menu)">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
                <!--<button class="btn btn-light" (click)="deleteMenu(menu.id)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>-->
                <!--<button class="btn btn-light" (click)="workInProgres()">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>-->
                <button class="btn btn-light" (click)="deleteMenu(menu)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ngb-pagination class="d-flex justify-content-center" [(page)]="pageMenu" [pageSize]="pageSizeMenu" [collectionSize]="tmpArrayMenu.length"></ngb-pagination>

  </form>
</div>

<!--Form-->
<div *ngIf="window == 2 || window == 3">
  <form>
    <div class="form-group">
      <label for="name">{{"ADMINISTRATION_PANEL.NAME_MENU" | translate}} <span class="obligatory">*</span></label>
      <input type="text" class="form-control" id="name" name="name" placeholder="Enter menu name" [(ngModel)]="valueName">
    </div>
    <div class="form-group">
      <label for="comment">{{"ADMINISTRATION_PANEL.SHORT_COMMENT_MENU" | translate}} <span class="obligatory">*</span></label>
      <textarea class="form-control" id="comment" name="comment" rows="3" maxlength="250" placeholder="Comment" [(ngModel)]="valueShortDescription"></textarea>
    </div>

    <div class="form-group row">

      <div class="col-2">
        <label for="countrySelect">{{"ADMINISTRATION_PANEL.SELECT_COUNTRY_MENU" | translate}} <span class="obligatory">*</span></label><br>
        <ng-select name="countrySelect" id="countrySelect" [(ngModel)]="valueCountry" placeholder="Select country">
          <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
        </ng-select>
      </div>

      <div class="col-3">
        <label for="stopSelect">{{"ADMINISTRATION_PANEL.SELECT_STOPS_MENU" | translate}} <span class="obligatory">*</span></label><br>
        <ng-select name="stopSelect" id="stopSelect" [multiple]="true" [closeOnSelect]="false" [(ngModel)]="valueStopId" placeholder="Select stop" (change)="selectStopChangeEvent()">
          <ng-option *ngFor="let stop of arrayStop" [value]="stop.id">{{stop.stop}}</ng-option>
        </ng-select>
      </div>

    </div>

    <div *ngFor="let stop of valueStop; index as i">

      <h1>Form {{stop.stop}}</h1>

      <label for="tool{{stop.id}}">Wich tools do you want in this stop?<span class="obligatory">*</span></label><br>
      <ng-select name="tool{{stop.id}}" id="tool{{stop.id}}" [multiple]="true" [closeOnSelect]="false" [(ngModel)]="valueToolsId[i]" placeholder="Select stop">
        <ng-option *ngFor="let tool of getArrayToolByStop(stop.id)" [value]="tool.uid">{{tool.app_name}}</ng-option>
      </ng-select>
    </div>

    <button *ngIf="window == 2" (click)="createMenu()" class="btn btn-primary">{{"ADMINISTRATION_PANEL.CREATE_MENU" | translate}}</button>
    <button *ngIf="window == 3" (click)="updateMenu()" class="btn btn-primary">{{"FORM_PRO_SECTION.UPDATE_FORM" | translate}}</button>
  </form>
</div>

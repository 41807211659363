import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Coordinates } from 'src/app/shared/models/coordinates';

const initVariable = null;
const initBoolean = false;
const initArray = [];

const initArrayDoubleCEE: Map<string, object> = new Map();
const initCoordinates: Coordinates = {
  coordinateX: null,
  coordinateY: null,
  coordinateLong: null,
  coordinateLat: null
};

@Injectable({
  providedIn: 'root'
})
export class BestPracticesService {

  private guid$ = new BehaviorSubject<string>(initVariable);
  private building$ = new BehaviorSubject<any>(initVariable);
  private imageFacade$ = new BehaviorSubject<any>(initVariable);
  private parcel$ = new BehaviorSubject<any>(initVariable);
  private partParcel$ = new BehaviorSubject<any>(initVariable);
  private cp$ = new BehaviorSubject<any>(initVariable);
  private yearConstruction$ = new BehaviorSubject<any>(initVariable);
  private addressMap$ = new BehaviorSubject<any>(initVariable);
  private isCompleteBuilding$ = new BehaviorSubject<boolean>(initBoolean);
  private completeBuilding$ = new BehaviorSubject<any>(initVariable);
  private arraySingleHome$ = new BehaviorSubject<any[]>(initArray);

  private arrayDoubleCEE$ = new BehaviorSubject<Map<string, object>>(initArrayDoubleCEE);
  private doubleCEE$ = new BehaviorSubject<any>(initVariable);
  private doubleCEECompleteBuilding$ = new BehaviorSubject<any>(initVariable);

  private coordinates$ = new BehaviorSubject<Coordinates>(initCoordinates);
  private cadastreReference$ = new BehaviorSubject<string>(initVariable);
  private country$ = new BehaviorSubject<string>(initVariable);

  private _refreshUserCeeBuilding = new Subject<any>();
  private _refreshCeeBuilding = new Subject<any>();

  //DATABASE

  private arrayFamily$ = new BehaviorSubject<any[]>(initArray);
  private arrayPhase$ = new BehaviorSubject<any[]>(initArray);
  private arraySeason$ = new BehaviorSubject<any[]>(initArray);
  private arrayVariable$ = new BehaviorSubject<any[]>(initArray);
  private arrayTipology$ = new BehaviorSubject<any[]>(initArray);
  private arrayCase$ = new BehaviorSubject<any[]>(initArray);

  constructor(){}

  getBuilding$(): Observable<string>{
    return this.building$.asObservable();
  }

  setBuilding(building: any){
    this.building$ = new BehaviorSubject<any>(initVariable);
    this.building$.next(building);
  }

  getImageFacade$(): Observable<any>{
    return this.imageFacade$.asObservable();
  }

  setImageFacade(image: any){
    this.imageFacade$ = new BehaviorSubject<any>(initVariable);
    this.imageFacade$.next(image);
  }

  getParcel$(): Observable<any>{
    return this.parcel$.asObservable();
  }

  setParcel(parcel: any){
    this.parcel$ = new BehaviorSubject<any>(initVariable);
    this.parcel$.next(parcel);
  }

  getPartParcel$(): Observable<any>{
    return this.partParcel$.asObservable();
  }

  setPartParcel(partParcel: any){
    this.partParcel$ = new BehaviorSubject<any>(initVariable);
    this.partParcel$.next(partParcel);
  }

  getCp$(): Observable<any>{
    return this.cp$.asObservable();
  }

  setCp(cp: any){
    this.cp$ = new BehaviorSubject<any>(initVariable);
    this.cp$.next(cp);
  }

  getYearConstruction$(): Observable<any>{
    return this.yearConstruction$.asObservable();
  }

  setYearConstruction(year: any){
    this.yearConstruction$ = new BehaviorSubject<any>(initVariable);
    this.yearConstruction$.next(year);
  }

  getAddressMap$(): Observable<any>{
    return this.addressMap$.asObservable();
  }

  setAddressMap(addressMap: any){
    this.addressMap$ = new BehaviorSubject<any>(initVariable);
    this.addressMap$.next(addressMap);
  }

  getIsCompleteBuilding$(): Observable<any>{
    return this.isCompleteBuilding$.asObservable();
  }

  setIsCompleteBuilding(boolean: boolean){
    this.isCompleteBuilding$ = new BehaviorSubject<boolean>(initBoolean);
    this.isCompleteBuilding$.next(boolean);
  }

  getCompleteBuilding$(): Observable<any>{
    return this.completeBuilding$.asObservable();
  }

  setCompleteBuilding(completeBuilding: any){
    this.completeBuilding$ = new BehaviorSubject<any>(initVariable);
    this.completeBuilding$.next(completeBuilding);
  }

  getArraySingleHome$(): Observable<any>{
    return this.arraySingleHome$.asObservable();
  }

  setArraySingleHome(arraySingleHome: any[]){
    this.arraySingleHome$ = new BehaviorSubject<any[]>(initArray);
    this.arraySingleHome$.next(arraySingleHome);
  }

  getArrayDoubleCEE$(): Observable<Map<string, object>>{
    return this.arrayDoubleCEE$.asObservable();
  }

  setArrayDoubleCEE(array: Map<string, object>){
    this.arrayDoubleCEE$ = new BehaviorSubject<Map<string, object>>(initArrayDoubleCEE);
    this.arrayDoubleCEE$.next(array);
  }

  getDoubleCEE$(): Observable<any>{
    return this.doubleCEE$.asObservable();
  }

  setDoubleCEE(doubleCEE: any){
    this.doubleCEE$ = new BehaviorSubject<any>(initVariable);
    this.doubleCEE$.next(doubleCEE);
  }

  getDoubleCEECompleteBuilding$(): Observable<any>{
    return this.doubleCEECompleteBuilding$.asObservable();
  }

  setDoubleCEECompleteBuilding(doubleCEECompleteBuilding: any){
    this.doubleCEECompleteBuilding$ = new BehaviorSubject<any>(initVariable);
    this.doubleCEECompleteBuilding$.next(doubleCEECompleteBuilding);
  }

  getcoordinates$(): Observable<Coordinates>{
    return this.coordinates$.asObservable();
  }

  setCoordinates(coordinates: any){
    this.coordinates$ = new BehaviorSubject<Coordinates>(initCoordinates);
    this.coordinates$.next(coordinates);
  }

  getCadastreReference$(): Observable<string>{
    return this.cadastreReference$.asObservable();
  }

  setCadastreReference(cadastreReference: any){
    this.cadastreReference$ = new BehaviorSubject<string>(initVariable);
    this.cadastreReference$.next(cadastreReference);
  }

  getCountry$(): Observable<string>{
    return this.country$.asObservable();
  }

  setCountry(country: string){
    this.country$ = new BehaviorSubject<string>(initVariable);
    this.country$.next(country);
  }

  setRefreshUserCeeBuilding(){
    this._refreshUserCeeBuilding.next();
  }

  getRefreshUserCeeBuilding(){
    return this._refreshUserCeeBuilding.asObservable();
  }

  setRefreshCeeBuilding(){
    this._refreshCeeBuilding.next();
  }

  getRefreshCeeBuilding(){
    return this._refreshCeeBuilding.asObservable();
  }

  //DATABASE

  getFamily$(): Observable<any>{
    return this.arrayFamily$.asObservable();
  }

  setFamily(array: any){
    this.arrayFamily$ = new BehaviorSubject<any>(array);
    this.arrayFamily$.next(array);
  }

  getPhase$(): Observable<any>{
    return this.arrayPhase$.asObservable();
  }

  setPhase(array: any){
    this.arrayPhase$ = new BehaviorSubject<any>(array);
    this.arrayPhase$.next(array);
  }

  getSeason$(): Observable<any>{
    return this.arraySeason$.asObservable();
  }

  setSeason(array: any){
    this.arraySeason$ = new BehaviorSubject<any>(array);
    this.arraySeason$.next(array);
  }

  getVariable$(): Observable<any>{
    return this.arrayVariable$.asObservable();
  }

  setVariable(array: any){
    this.arrayVariable$ = new BehaviorSubject<any>(array);
    this.arrayVariable$.next(array);
  }

  getTipology$(): Observable<any>{
    return this.arrayTipology$.asObservable();
  }

  setTipology(array: any){
    this.arrayTipology$ = new BehaviorSubject<any>(array);
    this.arrayTipology$.next(array);
  }

  getCase$(): Observable<any>{
    return this.arrayCase$.asObservable();
  }

  setCase(array: any){
    this.arrayCase$ = new BehaviorSubject<any>(array);
    this.arrayCase$.next(array);
  }

}

<div style="border-radius: 22px; padding-top: 50px;">
  <div class="card card-saved-properties" style="border-radius: 22px; border: none;">
    <div class="card-header header-principal" id="propertiesSaved">
      <h2 class="mb-0">
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-link btn-block align-items-center d-flex justify-content-center button-saved-properties" type="button" data-toggle="collapse" data-target="#properties" aria-expanded="false" aria-controls="properties">
              <h3>{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.MY_BUILDINGS' | translate}}</h3>
            </button>
          </div>
          <div class="col-md-2">

          </div>
          <div class="col-md-4">
            <img src="../../../assets/img/buttons/saved_botton.png"> &nbsp; <span class="number-saved-properties">{{userHistory.length}}</span>
          </div>
        </div>
      </h2>
    </div>

    <div id="properties" class="collapse show" aria-labelledby="propertiesSaved">
      <div class="card-body">
        <div class="alert alert-success" role="alert" *ngIf="deletedBuilding">
          {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.DELETE_BUILDING' | translate}}
        </div>
        <div style="background-color: #EFF2F6; padding: 5px; border-radius: 10px; margin-bottom: 10px;">
          <div class="row">
            <div class="col-md-6">
              <div class="input-group search-box">
                <input class="form-control search-box-form" type="search" [(ngModel)]="searchText" placeholder="{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.SEARCH_PROPERTY' | translate}}" id="example-search-input">
              </div>
            </div>
            <div class="col-md-6">
              <button class="btn btn-primary filter-button" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.BUTTON' | translate}}
              </button>
            </div>
          </div>
          <div class="collapse " id="collapseExample">
            <div class="card card-body form" style="background-color: #EFF2F6; border: none;">
              <div class="row">
                <div class="col-md-6">
                  <label for="filterCountry"> {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.COUNTRY' | translate}}: </label>
                  <ng-select id="filterCountry" class="filter" [(ngModel)]="countrySelected"
                              (change)="filter('country');">
                    <ng-option *ngFor="let country of filterCountries" [value]="country">{{country}}</ng-option>
                  </ng-select>
                </div>

                <div class="col-md-6">
                  <label for="buildingType"> {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.BUILDING_TYPE' | translate}}: </label>
                  <ng-select id="buildingType" class="filter" [(ngModel)]="typologySelected"
                              (change)="filter('typology');">
                    <ng-option *ngFor="let typo of filterTypologies" [value]="typo.code">{{typo.name}}</ng-option>
                  </ng-select>
                </div>

                <div class="col-md-4">
                  <label for="filterYearMin">{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.MIN_YEAR' | translate}}: </label>
                  <ng-select id="filterYearMin" class="filter" [(ngModel)]="yearSelectedMin"
                             (change)="filter('yearMin');">
                    <ng-option *ngFor="let year of filterYears" [value]="year">{{year}}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md-4">
                  <label for="filterYearMax">{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.MAX_YEAR' | translate}}: </label>
                  <ng-select id="filterYearMax" class="filter" [(ngModel)]="yearSelectedMax"
                             (change)="filter('yearMax');">
                    <ng-option *ngFor="let year of filterYears" [value]="year">{{year}}</ng-option>
                  </ng-select>
                </div>

                <!--<div class="col-md-4" style="text-align: right; padding-top: 30px; padding-left: 30px;">
                  <button class="btn btn-primary clear-button" type="button">
                    Clear
                  </button>
                </div>-->
              </div>
            </div>
          </div>
        </div>
        <!--<table class="table">
          <thead>
            <tr>
              <th scope="col" style="border: none;">{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.ADDRESS' | translate}}</th>
              <th scope="col" style="border: none;">{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.ACTIONS' | translate}}</th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let building of tmpUserHistory | filter: searchText" >
            <th scope="row"><i class="fa fa-bookmark" aria-hidden="true"></i> &nbsp; <button class="button-link" (click)="showBuildingResults(building);" >{{building.address}}</button>
              <div>
                <span><img src="../../../assets/img/icons/year_icon.svg" alt="year" style="width: 16px;">&nbsp;{{building.year}}</span> &nbsp;
                <span><img src="../../../assets/img/icons/square_meters_icon.svg" alt="Surface" style="width: 16px!important;">&nbsp;{{building.surface}}&nbsp; m<sup>2</sup></span>
              </div>
            </th>
            <td>
              <div class="row">
                <button class="btn btn-light" (click)="updateBuilding(building);">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
                <button class="btn btn-light" (click)="removeBuildingFromUserHistory(building)">
                  <i class="fa fa-close" aria-hidden="true"></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>-->
        <div class="card card-building justify-content-center" *ngFor="let building of tmpUserHistory | filter: searchText">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <img class="img-thumbnail" alt="Facade image" [src]='building.properties[0].image' *ngIf=" building.properties.length > 0 && building.properties[0].image" width="170" height="170"/>
                <img src="../../../assets/img/typology/ES/{{building.typology.picName}}" *ngIf="building.country === 'ES'" width="150" height="150">
                <img src="../../../assets/img/typology/NL/{{building.typology.picName}}" *ngIf="building.country === 'NL'" width="150" height="150">
                <img src="../../../assets/img/typology/FR/{{building.typology.picName}}" *ngIf="building.country === 'FR'" width="150" height="150">
                <img src="../../../assets/img/typology/BG/{{building.typology.picName}}" *ngIf="building.country === 'BG'" width="150" height="150">
                <img src="../../../assets/img/typology/GR/{{building.typology.picName}}" *ngIf="building.country === 'GR'" width="150" height="150">
                <img src="../../../assets/img/typology/IT/{{building.typology.picName}}" *ngIf="building.country === 'IT'" width="150" height="150">
                <img src="../../../assets/img/typology/SI/{{building.typology.picName}}" *ngIf="building.country === 'SI'" width="150" height="150">
              </div>
              <div class="col-md-5">
                <div>
                  <button class="button-link" (click)="showBuildingResults(building);" >{{building.address}}</button>
                </div><br>
                <div *ngIf="building.country === 'ES'">
                  {{building.region}} / {{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION' | translate}}
                </div>

                <div *ngIf="building.country === 'NL'">
                  <div>
                    Netherlands / {{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}
                  </div>
                </div>

                <div *ngIf="building.country === 'FR'">
                  <div>
                    France / {{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}
                  </div>
                </div>

                <div *ngIf="building.country === 'BG'">
                  <div>
                    Bulgary / {{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}
                  </div>
                </div>

                <div *ngIf="building.country === 'SI'">
                  <div>
                    Slovenia / {{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}
                  </div>
                </div>

                <div *ngIf="building.country === 'GR'">
                  <div *ngIf="building.climateZone == 'GR.ZoneA'">
                    Greece / Climatic zone A
                  </div>
                  <div *ngIf="building.climateZone == 'GR.ZoneB'">
                    Greece / Climatic zone B
                  </div>
                  <div *ngIf="building.climateZone == 'GR.ZoneC'">
                    Greece / Climatic zone C
                  </div>
                  <div *ngIf="building.climateZone == 'GR.ZoneD'">
                    Greece / Climatic zone D
                  </div>
                  <div *ngIf="building.climateZone == 'GR.N'">
                    Greece / {{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}
                  </div>
                </div>

                <div *ngIf="building.country === 'IT'">
                  <div *ngIf="building.climateZone == 'IT.MidClim'">
                    Italy / Middle Climatic Zone (Zone E)
                  </div>
                  <div *ngIf="building.climateZone == 'IT.Alpine'">
                    Italy / Alpine Zone (Zone F)
                  </div>
                  <div *ngIf="building.climateZone == 'IT.Mediterranean'">
                    Italy / Mediterranean Zone
                  </div>
                </div>
                <br>
                <span class="year-building"><img src="../../../assets/img/icons/year_icon.svg" alt="year" style="width: 16px;">&nbsp;{{building.year}}</span> &nbsp;
                <span [hidden]="!building.surface" class="surface-building"><img src="../../../assets/img/icons/square_meters_icon.svg" alt="Surface" style="width: 16px!important;">&nbsp;{{building.surface}}&nbsp; m<sup>2</sup></span>
                <br><br>
                <span *ngIf="building.country == 'ES'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong class="typology-building">{{building.typology.categoryName}}</strong></span>
                <span *ngIf="building.country == 'FR'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong class="typology-building">{{building.typology.categoryName}}</strong></span>
                <span *ngIf="building.country == 'GR'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong class="typology-building">{{building.typology.categoryName}}</strong></span>
                <span *ngIf="building.country == 'IT'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong class="typology-building">{{building.typology.categoryName}}</strong></span>
                <span *ngIf="building.country == 'SI'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong class="typology-building">{{building.typology.categoryName}}</strong></span>
                <span *ngIf="building.country == 'BG'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong class="typology-building">{{building.typology.categoryName}}</strong></span>
                <span *ngIf="building.country == 'NL'">{{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}: <strong class="typology-building">{{building.typology.categoryName}}</strong></span>
              </div>
              <div class="col-md-3">
                <button class="btn btn-light action-buttons" (click)="updateBuilding(building);">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button class="btn btn-light action-buttons" (click)="removeBuildingFromUserHistory(building)">
                  <i class="fa fa-close" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

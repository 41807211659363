import { Component, Input, OnInit, isDevMode } from '@angular/core';
import {Tools} from '../../shared/models/tools';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToolsService} from '../../core/tools/tools.service';
import {Router} from '@angular/router';
import {ToolsModalComponent} from '../../components/tools-modal/tools-modal.component';
import { Observable } from 'rxjs';
import {User} from '../../shared/models/user';
import {UserService} from '../../core/authentication/user.service';
import { isNumber } from 'highcharts';
import { ToolsNew } from '../../shared/models/toolsnew';
import { AngularFireAuth } from '@angular/fire/auth';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { ConstraintsInfo } from 'src/app/shared/models/constraintsInfo';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { ToolRate } from 'src/app/shared/models/tool_rate';
import { BestPracticesService } from 'src/app/core/binding-data/best-practices/best-practices.service';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Profile } from 'src/app/shared/models/profile';
import { ConstraintType } from 'src/app/shared/models/constraintType';
import { ConstraintCategory } from 'src/app/shared/models/constraintCategory';
import { Solution } from 'src/app/shared/models/solution';
import { TypologyTool } from 'src/app/shared/models/typologyTool';
import { Menu, MenuStops } from 'src/app/shared/models/menu';
import { MenuService } from 'src/app/core/menu/menu.service';
import { CountryService } from 'src/app/core/country/country.service';
import { Country } from 'src/app/shared/models/country';
import { Stop } from 'src/app/shared/models/stop';
import { Step } from 'src/app/shared/models/step';
import { environment } from 'src/environments/environment.prod';
const arrayConstructor = Array;

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {

  visible: boolean = false;

  isDev: boolean = environment.production;

  filters: any = {country: null, typology: null, profile: null, solution: null, step: null, stop: null};
  countrySelected: number = null;
  profileSelected: number = null;
  typologySelected: number = null;
  solutionSelected: number = null;
  otherCountriesSelected: number[] = [];
  stepSelected: number = null;
  stopSelected: number = null;
  constraintClassSelected: number = null;
  constraintCategorySelected: string = null;
  filterApplied: string[];
  countryOneClick: string;
  modalRef: BsModalRef;
  isFavoriteTool: boolean;
  searchText: any;

  valueName: any = '';

  isUserLogged: boolean;
  currentUser: User = new User();

  arrayCountry: Country[] = [];

  valueShortDescription: string = '';
  lengthShortDescription: number = 0;

  valueLongDescription: string = '';
  lengthLongDescription: number = 0

  lengthShortDescriptionOriginal: number = 0;
  valueShortDescriptionOriginal: string = '';

  lengthLongDescriptionOriginal: number = 0
  valueLongDescriptionOriginal: string = '';

  //Information of the form tools
  toolname: string = '';
  toolnameoriginal: string = '';
  toolurl: string = '';
  toolcountry: number = null;
  isValueURL: boolean = false;
  toolimage = "";

  toolsDocuments: any[] = [];
  imagesTestimony: any[] = [];
  mainImages: any[] = [];

  profileList: Profile[] = [];
  constraintsTypeList: ConstraintType[] = [];
  solutionsList: Solution[] = [];
  typologiesList: TypologyTool[] = [];
  constraintsInfo: ConstraintsInfo[] = [];
  stopsList: Stop[] = [];
  stepsList: Step[] = [];
  constraintsCategoryList: ConstraintCategory[] = [];

  isAllDocuments: boolean = true;

  newTools: any[] = [];
  tmpNewTools: any[] = [];
  imageUrl = GlobalConstants.backendURL;

  guid: string = '';

  rateObjects: any[];

  averageRatings: number[] = [];

  arrayMenu: Menu[] = [];
  tmpArrayMenu: Menu[] = [];

  tmpArrayStep: Step[] = [];

  tmpConstrainsCategoryList: any[] = [];

  roleUser: number;

  // Crear un mapa para agrupar las herramientas por stops
  stopToolMap: Map<number, Map<Stop, ToolsNew[]>> = new Map();

  stopToolArray: { stop: Stop, tools: ToolsNew[] }[] = [];

  menu: Menu[];

  //Pagination
  page: number = 1;
  pageSize: number = 5;
  counter: number = null;

  //event filter app_name
  timeout: any = null;

  constructor(private toolsService: ToolsService, private router: Router, private modalService: BsModalService, private userService: UserService, private bestPracticesService: BestPracticesService, public afAuth: AngularFireAuth, private administrationPanel: AdministrationPanelService, private menuService: MenuService, private countryService: CountryService) { }

  ngOnInit() {
    //this.checkLogin();
    this.filterApplied = [];
    this.getCountries();
    this.getProfile();
    this.getConstraintType();
    this.getSolution();
    this.getTypology();
    this.getStops();
    this.getSteps();
    this.getConstraintCategory();
    this.getConstraintsInfo();
    this.getRatingTools();
    this.getMenus();

    this.administrationPanel.getGuid$().subscribe(data => {
      if(data == null){
        this.isUserLogged = false;
        this.getTools(true);

      }else{
        this.guid = data;
        this.isUserLogged = true;

        this.userService.getByUid(this.guid).toPromise().then((data: NodeResponse) => {
          console.log("data del user -> ", data);

          this.countrySelected = data.data.country_id;
          this.getTools(true);

        })
      }
    })

    this.administrationPanel.getRoleCurrentUser$().subscribe(data => {
      console.log('rol del usuario logeado -> ', data)
      this.roleUser = data;
    })


  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          console.log(userFromDB['data'].country_id)
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;
            this.countrySelected = userFromDB['data'].country_id;
            console.log(this.currentUser)
            this.getTools(true);
          }
        });
      } else {
        this.isUserLogged = false;
        this.getTools(true);
      }
    });
  }

  getTools(initPag: boolean){

    if(initPag) this.page = 1;

    this.toolsService.getListedTools(
      this.pageSize,
      (this.page - 1) * this.pageSize,
      this.valueName,
      this.stopSelected,
      this.stepSelected,
      this.countrySelected,
      this.solutionSelected,
      this.typologySelected,
      this.profileSelected,
      this.constraintClassSelected,
      this.constraintCategorySelected,
      this.otherCountriesSelected
    ).toPromise().then((dataDb: NodeResponse) => {
      console.log('data listed tools -> ', dataDb);

      this.newTools = dataDb.data;
      this.tmpNewTools = this.newTools;
      this.counter = dataDb.arrayLengthCounter;
      console.log(this.counter)

      this.applyConstraintClass();
    })

  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        //$this.executeListing(event.target.value);
        $this.getTools(true);
      }
    }, 1000);
  }

  /*private executeListing(value: string) {
    alert(value);
  }*/

  consoleJorge(){
    console.log("isUserLogged", this.isUserLogged);
    console.log("guid", this.guid);
    console.log("current role", this.roleUser);
  }

  applyConstraintClass(){
    this.tmpConstrainsCategoryList = [];

    //Demand - Cultural/Social/Technical/Processual/Financial
    if(this.profileSelected == 1 && this.constraintClassSelected == 1) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[0], this.constraintsCategoryList[1], this.constraintsCategoryList[2], this.constraintsCategoryList[3], this.constraintsCategoryList[4]);
    if(this.profileSelected == 1 && this.constraintClassSelected == 2) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[5], this.constraintsCategoryList[6], this.constraintsCategoryList[7], this.constraintsCategoryList[8]);
    if(this.profileSelected == 1 && this.constraintClassSelected == 3) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[9], this.constraintsCategoryList[10], this.constraintsCategoryList[11], this.constraintsCategoryList[12], this.constraintsCategoryList[13], this.constraintsCategoryList[14], this.constraintsCategoryList[15], this.constraintsCategoryList[16]);
    if(this.profileSelected == 1 && this.constraintClassSelected == 4) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[17], this.constraintsCategoryList[18], this.constraintsCategoryList[19], this.constraintsCategoryList[20], this.constraintsCategoryList[21], this.constraintsCategoryList[22], this.constraintsCategoryList[23], this.constraintsCategoryList[24], this.constraintsCategoryList[25], this.constraintsCategoryList[26], this.constraintsCategoryList[27], this.constraintsCategoryList[28], this.constraintsCategoryList[29]);
    if(this.profileSelected == 1 && this.constraintClassSelected == 5) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[30], this.constraintsCategoryList[31], this.constraintsCategoryList[32], this.constraintsCategoryList[33], this.constraintsCategoryList[34], this.constraintsCategoryList[35], this.constraintsCategoryList[36], this.constraintsCategoryList[37]);

    //Supply - Cultural/Technical/Processual/Financial
    if(this.profileSelected == 2 && this.constraintClassSelected == 1) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[38], this.constraintsCategoryList[39], this.constraintsCategoryList[40], this.constraintsCategoryList[41], this.constraintsCategoryList[42]);
    if(this.profileSelected == 2 && this.constraintClassSelected == 3) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[43], this.constraintsCategoryList[44], this.constraintsCategoryList[45], this.constraintsCategoryList[46], this.constraintsCategoryList[47], this.constraintsCategoryList[48]);
    if(this.profileSelected == 2 && this.constraintClassSelected == 4) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[49], this.constraintsCategoryList[50], this.constraintsCategoryList[51], this.constraintsCategoryList[52], this.constraintsCategoryList[53], this.constraintsCategoryList[54], this.constraintsCategoryList[55], this.constraintsCategoryList[56], this.constraintsCategoryList[57], this.constraintsCategoryList[58]);
    if(this.profileSelected == 2 && this.constraintClassSelected == 5) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[59], this.constraintsCategoryList[60], this.constraintsCategoryList[61]);

    //Financial - Cultural/Processual/Financial
    if(this.profileSelected == 4 && this.constraintClassSelected == 1) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[62], this.constraintsCategoryList[63]);
    if(this.profileSelected == 4 && this.constraintClassSelected == 4) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[64]);
    if(this.profileSelected == 4 && this.constraintClassSelected == 5) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[65], this.constraintsCategoryList[66], this.constraintsCategoryList[67]);

    //Institutional - Cultural/Technical/Processual/Financial
    if(this.profileSelected == 3 && this.constraintClassSelected == 1) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[68]);
    if(this.profileSelected == 3 && this.constraintClassSelected == 3) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[69]);
    if(this.profileSelected == 3 && this.constraintClassSelected == 4) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[70], this.constraintsCategoryList[71], this.constraintsCategoryList[72], this.constraintsCategoryList[73], this.constraintsCategoryList[74], this.constraintsCategoryList[75], this.constraintsCategoryList[76], this.constraintsCategoryList[77]);
    if(this.profileSelected == 3 && this.constraintClassSelected == 5) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[78], this.constraintsCategoryList[79], this.constraintsCategoryList[80], this.constraintsCategoryList[81]);

  }



  getMenus(){
    this.administrationPanel.getArrayMenu$().subscribe((data: Menu[]) => {
      if(data.length == 0){
        this.menuService.getAll().toPromise().then((dataDb: NodeResponse) => {
          console.log(dataDb);
          if(dataDb.status == true){
            this.arrayMenu = dataDb.data;
            this.tmpArrayMenu = this.arrayMenu;
            console.log(this.tmpArrayMenu)

            this.administrationPanel.setArrayMenu(dataDb.data);

            // Iterar sobre los menús y sus herramientas para llenar el mapa
            this.tmpArrayMenu.forEach(menu => {
              const menuId = menu.id;
              const stopToolMapForMenu = new Map<Stop, ToolsNew[]>();

              this.stopToolMap.set(menuId, stopToolMapForMenu);

              this.stopToolArray = Array.from(this.stopToolMap.get(menu.id)).map(([stop, tools]) => ({ stop, tools }));

            });
          }
        })
      }else{
        console.log('data Menu service -> ', data)
        this.arrayMenu = data;
        this.tmpArrayMenu = this.arrayMenu;
      }
    })
  }

  getCountries() {
    this.administrationPanel.getArrayCountry$().subscribe(data => {
      if(data.length == 0){
        this.countryService.getCountries().toPromise().then((dataDB: NodeResponse) => {
          console.log('countries en tool -> ', dataDB);
          this.arrayCountry = dataDB.data;
          this.administrationPanel.setArrayCountry(this.arrayCountry);
        })
      }else{
        console.log('countries en tool -> ', data);
        this.arrayCountry = data;
      }
    })
  }

  getProfile(){

    this.administrationPanel.getArrayProfile$().subscribe((data: Profile[]) => {
      if(data.length == 0){
        this.toolsService.getProfiles().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB profile -> ', dataDB)

          if(dataDB.status){
            this.profileList = dataDB.data;
            this.administrationPanel.setArrayProfile(this.profileList);
          }
        })
      }else{
        console.log('data profile -> ', data)
        this.profileList = data;
      }
    })
  }

  getConstraintType(){
    this.administrationPanel.getArrayConstraintType$().subscribe((data: ConstraintType[]) => {
      if(data.length == 0){
        this.toolsService.getContraintsType().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB constraint type -> ', dataDB)

          if(dataDB.status){
            this.constraintsTypeList = dataDB.data;
            this.administrationPanel.setArrayConstraintType(this.constraintsTypeList);
          }
        })
      }else{
        console.log('data constraint type -> ', data)
        this.constraintsTypeList = data;
      }
    })
  }

  getConstraintCategory(){
    this.administrationPanel.getArrayConstraintCategory$().subscribe((data: ConstraintCategory[]) => {
      if(data.length == 0){
        this.toolsService.getContraintsCategory().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB constraint category -> ', dataDB)

          if(dataDB.status){
            this.constraintsCategoryList = dataDB.data;
            this.constraintsCategoryList.sort((a, b) => a.id - b.id);
            this.administrationPanel.setArrayConstraintCategory(this.constraintsCategoryList);
          }
        })
      }else{
        console.log('data constraint category -> ', data)
        this.constraintsCategoryList = data;
      }
    })
  }

  getConstraintsInfo(){
    this.toolsService.getConstraintsInfo().subscribe((data:any) => {
      this.constraintsInfo = data;
      console.log(this.constraintsInfo)
    })
  }

  getSolution(){
    this.administrationPanel.getArraySolution$().subscribe((data: Solution[]) => {

      if(data.length == 0){
        this.toolsService.getSolutions().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB solution -> ', dataDB)

          if(dataDB.status){
            this.solutionsList = dataDB.data;
            this.administrationPanel.setArraySolution(this.solutionsList);
          }
        })
      }else{
        console.log('data solution -> ', data)
        this.solutionsList = data;
      }
    })
  }

  getTypology(){
    this.administrationPanel.getArrayTypology$().subscribe((data: TypologyTool[]) => {
      if(data.length == 0){
        this.toolsService.getTypologies().toPromise().then((dataDB: NodeResponse) => {
          console.log('dataDB typology -> ', dataDB)

          if(dataDB.status){
            this.typologiesList = dataDB.data;
            this.administrationPanel.setArrayTypology(this.typologiesList);
          }
        })
      }else{
        console.log('data typology -> ', data)
        this.typologiesList = data;
      }
    })
  }

  getStops(){
    this.toolsService.getStops().subscribe((data:any) => {
      this.stopsList = data.data;
      console.log(this.stopsList)
    })
  }

  getSteps(){
    this.toolsService.getSteps().subscribe((data:any) => {
      this.stepsList = data.data;
      console.log(this.stepsList)
    })
  }

  applyFilter(): void {
    let toolsTmp = [];

    let constraintsInfoTmp = [];

    this.newTools.forEach( tool => {
      toolsTmp.push(tool);
    });

    this.constraintsInfo.forEach(constraint => {
      constraintsInfoTmp.push(constraint);
    });

    /*const newArray = toolsTmp.reduce((acc, obj1) => {
      const match = constraintsInfoTmp.find(obj2 => obj2.tool_id === obj1.uid);
      if(match){
        acc.push(obj1)
      }
      console.log(acc)
      return acc;
    });

    console.log(newArray);*/

    /*for(let i = 0; i < toolsTmp.length; i++){
      let matchingObject = constraintsInfoTmp.find(obj => obj.tool_id == toolsTmp[i].uid);
      if(matchingObject){
        toolsTmp[i].profiles = matchingObject.profiles;
          toolsTmp[i].constraintsClass = matchingObject.constraint_type;
          toolsTmp[i].constraintsCategory = matchingObject.constraint_category;
        /*toolsTmp[i].profiles = matchingObject.map(obj => obj.profiles);
        toolsTmp[i].constraintsClass = matchingObject.map(obj => obj.constraint_type);
        toolsTmp[i].constraintsCategory = matchingObject.map(obj => obj.constraint_category);
      }
    }*/

    /*for(let i = 0; i < toolsTmp.length; i++){
      let matchingObject = [];
      for(let j = 0; i < constraintsInfoTmp.length; j++){
        if(toolsTmp[i].uid == constraintsInfoTmp[j].tool_id){
          matchingObject.push(constraintsInfoTmp[j].profiles);
        }
      }
      if(matchingObject.length > 0){
        toolsTmp[i].profiles = matchingObject;
      }
    }*/

    for(let i = 0; i < toolsTmp.length; i++){
      let matchingObject = [];
      for(let j = 0; j < constraintsInfoTmp.length; j++){
        if(toolsTmp[i].uid == constraintsInfoTmp[j].tool_id){
          matchingObject.push(constraintsInfoTmp[j].profiles);
        }
      }
      if(matchingObject.length > 0){
        toolsTmp[i].profiles = matchingObject;
      }
    }

    for(let i = 0; i < toolsTmp.length; i++){
      let matchingObject = [];
      for(let j = 0; j < constraintsInfoTmp.length; j++){
        if(toolsTmp[i].uid == constraintsInfoTmp[j].tool_id){
          matchingObject.push(constraintsInfoTmp[j].constraint_type);
        }
      }
      if(matchingObject.length > 0){
        toolsTmp[i].constraintsClass = matchingObject;
      }
    }

    for(let i = 0; i < toolsTmp.length; i++){
      let matchingObject = [];
      for(let j = 0; j < constraintsInfoTmp.length; j++){
        if(toolsTmp[i].uid == constraintsInfoTmp[j].tool_id){
          matchingObject.push(constraintsInfoTmp[j].constraint_category);
        }
      }
      if(matchingObject.length > 0){
        toolsTmp[i].constraintsCategory = matchingObject;
      }
    }

    console.log(toolsTmp);

    //let arrayConcatenate = [...toolsTmp, ...constraintsInfoTmp];

    this.filterApplied.forEach( filter => {
      if (filter === 'countries') {
        const filterByCountry  = [];
        this.newTools.forEach( tool => {
          const hasCountry = this.countrySelected == tool.country_origin_data.id
          if( hasCountry ) {
            filterByCountry.push(tool);
            console.log(filterByCountry)
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByCountry);
      }

      if (filter === 'profiles') {
        const filterByProfiles  = [];

        this.newTools.forEach( tool => {
          const hasProfile = tool.constraints_infos.find( (item) => {
            if (item.profile_id == this.profileSelected){
              return true;
            }
          });
          if( hasProfile ) {
            filterByProfiles.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByProfiles);
      }

      if (filter === 'constraintclass') {
        const filterByConstraintClass  = [];

        this.tmpConstrainsCategoryList = [];

        //Demand - Cultural/Social/Technical/Processual/Financial
        if(this.profileSelected == 1 && this.constraintClassSelected == 1) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[0], this.constraintsCategoryList[1], this.constraintsCategoryList[2], this.constraintsCategoryList[3], this.constraintsCategoryList[4]);
        if(this.profileSelected == 1 && this.constraintClassSelected == 2) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[5], this.constraintsCategoryList[6], this.constraintsCategoryList[7], this.constraintsCategoryList[8]);
        if(this.profileSelected == 1 && this.constraintClassSelected == 3) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[9], this.constraintsCategoryList[10], this.constraintsCategoryList[11], this.constraintsCategoryList[12], this.constraintsCategoryList[13], this.constraintsCategoryList[14], this.constraintsCategoryList[15], this.constraintsCategoryList[16]);
        if(this.profileSelected == 1 && this.constraintClassSelected == 4) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[17], this.constraintsCategoryList[18], this.constraintsCategoryList[19], this.constraintsCategoryList[20], this.constraintsCategoryList[21], this.constraintsCategoryList[22], this.constraintsCategoryList[23], this.constraintsCategoryList[24], this.constraintsCategoryList[25], this.constraintsCategoryList[26], this.constraintsCategoryList[27], this.constraintsCategoryList[28], this.constraintsCategoryList[29]);
        if(this.profileSelected == 1 && this.constraintClassSelected == 5) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[30], this.constraintsCategoryList[31], this.constraintsCategoryList[32], this.constraintsCategoryList[33], this.constraintsCategoryList[34], this.constraintsCategoryList[35], this.constraintsCategoryList[36], this.constraintsCategoryList[37]);

        //Supply - Cultural/Technical/Processual/Financial
        if(this.profileSelected == 2 && this.constraintClassSelected == 1) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[38], this.constraintsCategoryList[39], this.constraintsCategoryList[40], this.constraintsCategoryList[41], this.constraintsCategoryList[42]);
        if(this.profileSelected == 2 && this.constraintClassSelected == 3) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[43], this.constraintsCategoryList[44], this.constraintsCategoryList[45], this.constraintsCategoryList[46], this.constraintsCategoryList[47], this.constraintsCategoryList[48]);
        if(this.profileSelected == 2 && this.constraintClassSelected == 4) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[49], this.constraintsCategoryList[50], this.constraintsCategoryList[51], this.constraintsCategoryList[52], this.constraintsCategoryList[53], this.constraintsCategoryList[54], this.constraintsCategoryList[55], this.constraintsCategoryList[56], this.constraintsCategoryList[57], this.constraintsCategoryList[58]);
        if(this.profileSelected == 2 && this.constraintClassSelected == 5) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[59], this.constraintsCategoryList[60], this.constraintsCategoryList[61]);

        //Financial - Cultural/Processual/Financial
        if(this.profileSelected == 4 && this.constraintClassSelected == 1) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[62], this.constraintsCategoryList[63]);
        if(this.profileSelected == 4 && this.constraintClassSelected == 4) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[64]);
        if(this.profileSelected == 4 && this.constraintClassSelected == 5) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[65], this.constraintsCategoryList[66], this.constraintsCategoryList[67]);

        //Institutional - Cultural/Technical/Processual/Financial
        if(this.profileSelected == 3 && this.constraintClassSelected == 1) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[68]);
        if(this.profileSelected == 3 && this.constraintClassSelected == 3) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[69]);
        if(this.profileSelected == 3 && this.constraintClassSelected == 4) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[70], this.constraintsCategoryList[71], this.constraintsCategoryList[72], this.constraintsCategoryList[73], this.constraintsCategoryList[74], this.constraintsCategoryList[75], this.constraintsCategoryList[76], this.constraintsCategoryList[77]);
        if(this.profileSelected == 3 && this.constraintClassSelected == 5) this.tmpConstrainsCategoryList.push(this.constraintsCategoryList[78], this.constraintsCategoryList[79], this.constraintsCategoryList[80], this.constraintsCategoryList[81]);



        this.newTools.forEach( tool => {
          const hasProfile = tool.constraints_infos.find( (item, i) => {
            if (item.constraint_type_id == this.constraintClassSelected){
              return true;
            }
          });
          if( hasProfile ) {
            filterByConstraintClass.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByConstraintClass);
      }

      if (filter === 'constraintcategory') {
        const filterByConstraintCategory  = [];

        this.newTools.forEach( tool => {
          const hasProfile = tool.constraints_infos.find( (item, i) => {
            /*if (item.constraint_category[i] == this.constraintCategorySelected){
              return true;
            }*/
            for(let j = 0; j < item.constraint_category.length; j++){
              if(item.constraint_category[j] == this.constraintCategorySelected){
                return true;
              }
            }
          });
          if( hasProfile ) {
            filterByConstraintCategory.push(tool);
          }
        });

        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByConstraintCategory);
      }

      if (filter === 'solutions') {
        const filterBySolutions  = [];
        this.newTools.forEach( tool => {
          const hasSolution =  tool.solutions.find( (item, i) => {
            if (item.id === this.solutionSelected){
              return true;
            }
          });
          if( hasSolution ) {
            filterBySolutions.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterBySolutions);
      }

      if (filter === 'stops') {
        const filterByStops  = [];
        this.tmpArrayStep = [];

        if(this.stopSelected == 1) this.tmpArrayStep.push(this.stepsList[0], this.stepsList[1]);
        if(this.stopSelected == 2) this.tmpArrayStep.push(this.stepsList[2], this.stepsList[3]);
        if(this.stopSelected == 3) this.tmpArrayStep.push(this.stepsList[4], this.stepsList[5], this.stepsList[6]);
        if(this.stopSelected == 4) this.tmpArrayStep.push(this.stepsList[7], this.stepsList[8], this.stepsList[9], this.stepsList[10]);
        if(this.stopSelected == 5) this.tmpArrayStep.push(this.stepsList[11], this.stepsList[12], this.stepsList[13], this.stepsList[14]);

        this.newTools.forEach( tool => {
          const hasStop = tool.stops.find( (item) => {
            if (item.id == this.stopSelected){
              return true;
            }
          });
          if( hasStop ) {
            filterByStops.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByStops);
      }

      if (filter === 'steps') {
        const filterBySteps  = [];
        this.newTools.forEach( tool => {
          const hasStep = tool.steps.find( (item, i) => {
            if (item.id == this.stepSelected){
              return true;
            }
          });
          if( hasStep ) {
            filterBySteps.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterBySteps);
      }

      if (filter === 'typologies') {
        const filterByTypologies  = [];
        this.newTools.forEach( tool => {
          const hasTypo = tool.typologies.find( (item) => {
            if (item.id === this.typologySelected){
              return true;
            }
          });
          if( hasTypo ) {
            filterByTypologies.push(tool);
          }
        });
        toolsTmp = this.removeElementsFromArray(toolsTmp, filterByTypologies);
      }
    });
    this.tmpNewTools = toolsTmp;
  }

  /*filter(type: string): void {
    if ((type === 'countries' && this.countrySelected !== null) ||
        (type === 'profiles' && this.profileSelected !== null) ||
        (type === 'solutions' && this.solutionSelected !== null) ||
        (type === 'stops' && this.stopSelected !== null) ||
        (type === 'steps' && this.stepSelected !== null) ||
        (type === 'typologies' && this.typologySelected !== null) ||
        (type === 'constraintclass' && this.constraintClassSelected !== null) ||
        (type === 'constraintcategory' && this.constraintCategorySelected !== null)) {

        const indexFilterApplied = this.filterApplied.indexOf(type);
        if (indexFilterApplied < 0) {
            this.filterApplied.push(type);
        }
    } else {
        this.cleanFilter(type);
    }

    // Ajusta la lógica para aplicar filtros
    this.applyFilter();
  }*/

  removeElementsFromArray(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }

  cleanFilter(type: string): void {
    const indexFilterApplied = this.filterApplied.indexOf(type);
    if (indexFilterApplied > -1) {
        this.filterApplied.splice(indexFilterApplied, 1);
    }
  }


  goToOneClick(): void {
    this.router.navigate(['/oneclick'], {state: {country: this.countryOneClick}});
  }

  openModal(tool: ToolsNew) {
    tool.visitCount++;
    this.modalRef = this.modalService.show(ToolsModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { tool}});
    this.modalRef.content.closeBtnName = 'Close';
  }

  saveTool(tool: ToolsNew): void{
    console.log(this.currentUser.uid);
    this.userService.addPropertyToolToHistory(tool, sessionStorage.getItem('auth-token')).subscribe( () => {
      this.isFavoriteTool = true;
      alert("Tool saved successfully");
    });
    console.log(tool);
  }

  createToolPropose(){

    if(this.isFormFilled()){
      if(confirm('Do you want to propose this tool?') == true){
        this.toolsService.createNewToolPropose(
          this.toolname,
          this.toolnameoriginal,
          this.toolurl,
          this.valueShortDescription,
          this.valueLongDescription,
          this.valueShortDescriptionOriginal,
          this.valueLongDescriptionOriginal,
          this.toolcountry,
          this.guid
        ).toPromise().then((data: NodeResponse) => {
          console.log('response tool propose ->' , data);
          if(data.status){
            this.initVariablesProposeForm();
            alert(data.message);
          }else{
            alert(data.message);
          }
        })
      }
    }else{
      alert('The form is not filled')
    }


  }

  initVariablesProposeForm(){
    this.toolcountry = null;
    this.toolname = '';
    this.toolnameoriginal = '';
    this.isValueURL = false;
    this.toolurl = '';
    this.valueShortDescription = '';
    this.valueLongDescription = '';
    this.valueShortDescriptionOriginal = '';
    this.valueLongDescriptionOriginal = '';
  }

  isFormFilled(): boolean{

    let value1 = false;
    let value2 = false;

    if(
      this.toolcountry != null &&
      this.toolname != '' &&
      this.toolnameoriginal != '' &&
      this.valueShortDescription != '' &&
      this.valueLongDescription != '' &&
      this.valueShortDescriptionOriginal != '' &&
      this.valueLongDescriptionOriginal != ''
    ){
      value1 = true;
    }

    if(this.isValueURL == true){
      if(this.toolurl != ''){
        value2 = true;
      }
    }else if(this.isValueURL == false){
      value2 = true;
    }

    console.log(value1, value2, value1 && value2)

    return (value1 && value2);
  }

  booleanURLChangeEvent(){
    this.toolurl = '';
  }

  rateTool(rating: number, tool: ToolsNew){
    if(this.isUserLogged == true){
      let bodyData = new FormData();

      const rate = rating;
      const tool_id = tool.uid;

      const rateTool = new ToolRate(rate, tool_id, this.guid);

      this.toolsService.createToolRate(rateTool).subscribe(response => {
        console.log(response);
      }, error => {
        console.log(error);
      })

      console.log("The puntuation for this tool " + tool.app_name + " is: " + rating);
    }else{
      alert("You need to login in the app to rate the tools.");
    }

  }

  getRatingTools(){
    this.toolsService.getToolRateInfo().subscribe((data: any) => {
      this.rateObjects = data;
      console.log(this.rateObjects);
      console.log(this.tmpNewTools)

        for(let i = 0; i < this.tmpNewTools.length; i++){
          let totalRate = 0;
          let count = 0;
          for(let j = 0; j < this.rateObjects.length; j++){
            if (this.rateObjects[j].tool_id == this.tmpNewTools[i].uid) {
              totalRate += this.rateObjects[j].rate;
              count++;
            }
          }
          const averageRate = totalRate / count;
          this.averageRatings.push(averageRate)
          console.log(`Average rating for tool ${this.tmpNewTools[i].uid}: ${averageRate}`);
        }
    });

  }

  fileImageChangeEvent(event: any){

    //this.isAllImages = true;

    //console.log(event.target.files[0].type.substring(0,5));

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.toolimage = event.target.files[i];
        this.mainImages.push(event.target.files[i]);
      }else{
        //console.log('no es una imagen ', this.isAllImages);
        //this.isAllImages = false;
      }
    }

  }

  fileOtherImageChangeEvent(event: any){

    //this.isAllImages = true;

    //console.log(event.target.files[0].type.substring(0,5));

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.imagesTestimony.push(event.target.files[i]);
      }else{
        //console.log('no es una imagen ', this.isAllImages);
        //this.isAllImages = false;
      }

      if(i == 4) break;
    }
  }

  fileChangeEvent(event: any){

    //let file = event.target.files[0];
    this.isAllDocuments = true;
    this.toolsDocuments = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log( 'Archivo seleccionado n' + i + '-> ', event.target.files[i]);

      if(event.target.files[i].type.substring(5) == 'pdf' || event.target.files[i].type == 'application/pdf'){
        //this.pdfFiles.splice(i, 1, file);
        this.toolsDocuments.push(event.target.files[i]);
        //return true;
      }else{
        //this.pdfFiles.splice(i, 1, null);
        console.log('esto no es un archivo PDF');
        this.isAllDocuments = false;
      }

    }
  }

  sortTools(){
    this.tmpNewTools.sort((a, b) => a.app_name.localeCompare(b.app_name));
  }

  sortToolsZA(){
    this.tmpNewTools.sort((a, b) => b.app_name.localeCompare(a.app_name));
  }

  getMissingStops(menu: Menu): string[] {
    // Lista de todos los stops posibles
    const allStops = ['Onboarding', 'Evaluation', 'Design and Formalization', 'Realization', 'Validation'];

    // Obtén los nombres de los stops existentes en el menú actual
    const existingStops = new Set(menu.menu_stops.map((menuStop: MenuStops) => menuStop.stop.stop));

    // Filtra los stops que faltan y evita duplicados
    const missingStops = allStops.filter(stop => !existingStops.has(stop));

    return missingStops.map(stop => stop.replace(/\s+/g, '-'));
  }

  createEmptyColumns(count: number): any[] {
    return arrayConstructor(count).fill(null);
  }

}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Article } from 'src/app/shared/models/article';
import { ArticlesService } from 'src/app/core/re-hub/articles.service';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Country } from 'src/app/shared/models/country';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { Subscription } from 'rxjs';
import { CategoryArticle } from 'src/app/shared/models/categoryArticle';
import { ArticleStatus } from 'src/app/shared/models/articleStatus';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ArticlesModalComponent } from 'src/app/components/articles-modal/articles-modal.component';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { User } from 'src/app/shared/models/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'src/app/core/authentication/user.service';

@Component({
  selector: 'app-articles-table',
  templateUrl: './articles-table.component.html',
  styleUrls: ['./articles-table.component.scss']
})
export class ArticlesTableComponent implements OnInit, OnDestroy {

  @Input() arrayCountry: Country[] = [];
  @Input() guid: string = '';

  window: number = 1;

  arrayArticle: Article[] = [];
  tmpArrayArticle: Article[] = [];
  arrayCategoryArticle: CategoryArticle[] = [];
  arrayArticleStatus: ArticleStatus[] = [];

  arrayProposeArticle: Article[] = [];
  tmpArrayProposeArticle: Article[] = [];

  getArrayArticle: Subscription = null;
  getArrayProposeArticle: Subscription = null;
  getArrayCategoryArticle: Subscription = null;
  getArrayArticleStatus: Subscription = null;

  //filter
  valueFilterName: string = '';
  valueFilterCountry: number = null;
  valueFilterCountryArticle: number = null;
  valueFilterCategoryArticle: number = null;
  valueFilterUsername: string = '';
  valueFilterStatus: number = null;

  //variables form
  adminComment: string = '';
  valueName: string = '';
  valueCountry: number = null;
  valueCategory: number = null;
  valueDate: string = '';
  valueAuthor: string = '';
  valueShortDescription: string = '';
  valueLongDescription: string = '';
  valueMainImage: any = null;
  valueOtherImages: any[] = [];
  valueDocuments: any[] = [];
  valueVideoURL: string = '';
  valueLink: string = '';

  isMoreMultimedia: boolean = false;

  isMainImageChange: boolean = false;
  isAdditionalImagesChange: boolean = false;
  isPdfFilesChange: boolean = false;

  nameMainImage: string = '';
  nameAdditionalImages: string = '';
  nameAdditionalDocuments: string = '';

  articleSelected: Article = null;

//-------------------------------------
  articlecountry: number;
  articlecategory: number;
  //isMoreMultimedia: boolean = false;
  //valueVideoURL: string;

  articleName: string;
  articleAuthor: string;
  articleDate: Date;
  articleImage: string;
  mainImages: any[] = [];
  otherImagesArticle: any[] = [];

  isAllDocuments: boolean;
  articlesDocuments: any[] = [];

  lengthShortDescription: number = 0;
  lengthLongDescription: number = 0
  //valueShortDescription: string = null;
  //valueLongDescription: string = null;

  articles: any[] = [];
  tmpArticles: any[] = [];

  countrySelectd: any;
  categorySelectd: any;
  searchTextArticles: any;

  filterApplied: string[];
  filterAppliedProposed: string[];

  page: number = 1;
  pageSize: number = 10;

  page2: number = 1;
  pageSize2: number = 10;

  categories: any[] = [];

  modalRef: BsModalRef;
  imageUrl: string = GlobalConstants.backendURL;
  isUserLogged: boolean;
  currentUser: User = new User();

  constructor(private articleService: ArticlesService, private administrationPanelService: AdministrationPanelService, private modalService: BsModalService, private userService: UserService, public afAuth: AngularFireAuth) {}

  ngOnInit(): void {
    this.checkLogin();
    this.getValidateArticles();
    this.getCategories();
    this.getArticleStatus();
    this.getProposedArticles();
  }

  ngOnDestroy(): void {
    if(this.getArrayArticle != null) this.getArrayArticle.unsubscribe();
    if(this.getArrayProposeArticle != null) this.getArrayProposeArticle.unsubscribe();
    if(this.getArrayCategoryArticle != null) this.getArrayCategoryArticle.unsubscribe();
    if(this.getArrayArticleStatus != null) this.getArrayArticleStatus.unsubscribe();
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          console.log(userFromDB['data'].country_id)
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;
            this.currentUser.user_roles = userFromDB['data'].user_roles;
            console.log(this.currentUser)
            if(userFromDB['data'].user_roles[0].role_id == 2){
              this.applyAutoFilter();
            }
          }
        });
      } else {
        this.isUserLogged = false;
      }
    });
  }

  /*checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          console.log(userFromDB['data'].user_roles[0].role_id)
          console.log(userFromDB['data'].country_id);
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;
            this.currentUser.user_roles = userFromDB['data'].user_roles;
            if(userFromDB['data'].user_roles[0].role_id == 2){
              this.applyAutoFilter();
            }
          }
          console.log(this.currentUser)
        });
      } else {
        this.isUserLogged = false;
      }
    });
  }*/

  applyAutoFilter() {
    // Supongamos que tienes un mapeo entre los países y los valores del filtro
    const countryFilterMapping = {
      3: 3,
      10: 10,
      12: 12,
      15: 15,
      20: 20,
      25: 25,
      26: 26, // Supongamos que el ID de España es 1
      // Agrega más mapeos según sea necesario
    };
  
    // Obtener el valor del filtro según el país del usuario
    const autoFilterValue = countryFilterMapping[this.currentUser.country];
  
    // Aplicar el filtro automáticamente
    if (autoFilterValue) {
      this.valueFilterCountryArticle = autoFilterValue;
      
      this.arrayArticle = this.tmpArrayArticle.filter(article => article.country_id == this.valueFilterCountryArticle);
      // Aquí puedes llamar a tu lógica de filtrado manual si es necesario
      this.applyFilter('article');
    }
  }

  getValidateArticles(){
    this.getArrayArticle = this.administrationPanelService.getArrayValidateArticle$().subscribe((data: Article[]) => {
      if(data.length == 0){
        this.articleService.getValidateArticles().toPromise().then((dataDB: NodeResponse) => {
          console.log('article data DB -> ', dataDB);
          console.log(this.currentUser.country)
          if(dataDB.status == true){
            this.administrationPanelService.setArrayValidateArticle(dataDB.data);

            this.arrayArticle = dataDB.data;
            this.tmpArrayArticle = dataDB.data;
          }
        })
      }else{
        console.log('article data -> ', data);

        this.arrayArticle = data;
        this.tmpArrayArticle = data;
      }
    })
  }

  getCategories(){
    this.getArrayCategoryArticle = this.administrationPanelService.getArrayCategoryArticle$().subscribe((data: CategoryArticle[]) => {
      if(data.length == 0){
        this.articleService.getCategoryArticle().toPromise().then((dataDB: NodeResponse) => {
          console.log('category article data DB -> ', dataDB);
          if(dataDB.status == true){
            this.administrationPanelService.setArrayCategoryArticle(dataDB.data);
            this.arrayCategoryArticle = dataDB.data;
          }
        })
      }else{
        console.log('category article data -> ', data);

        this.arrayCategoryArticle = data;
      }
    })
  }

  getArticleStatus(){
    this.getArrayArticleStatus = this.administrationPanelService.getArrayArticleStatus$().subscribe((data: ArticleStatus[]) => {
      if(data.length == 0){
        this.articleService.getArticleStatus().toPromise().then((dataDB: NodeResponse) => {
          console.log('Articles Status data DB -> ', dataDB);
          if(dataDB.status == true){
            this.administrationPanelService.setArrayArticleStatus(dataDB.data);
            this.arrayArticleStatus   = dataDB.data;
          }
        })
      }else{
        console.log('Articles Status data -> ', data);

        this.arrayArticleStatus = data;
      }
    })
  }

  getProposedArticles(){
    this.getArrayProposeArticle = this.administrationPanelService.getArrayProposedArticle$().subscribe((data: Article[]) => {
      if(data.length == 0){
        this.articleService.getProposedArticles().toPromise().then((dataDB: NodeResponse) => {
          console.log('article proposed data DB -> ', dataDB);
          if(dataDB.status == true){
            this.administrationPanelService.setArrayProposedArticle(dataDB.data);

            this.arrayProposeArticle = dataDB.data;
            this.tmpArrayProposeArticle = dataDB.data;
          }
        })
      }else{
        console.log('article data -> ', data);

        this.arrayProposeArticle = data;
        this.tmpArrayProposeArticle = data;
      }
    })
  }

  applyFilter(section: string){

    switch(section){
      case 'article':

        this.tmpArrayArticle = this.arrayArticle;

        console.log(this.tmpArrayArticle);

        if(this.valueFilterName != null && this.valueFilterName != undefined && this.valueFilterName != ''){
          this.tmpArrayArticle = this.tmpArrayArticle.filter(article => {
            if(article.article_name == null){
              return false;
            }else{
              return article.article_name.toLowerCase().includes(this.valueFilterName.toLowerCase());
            }
          });
        }
       
        if(this.valueFilterCountryArticle != null && this.valueFilterCountryArticle != undefined){
          this.tmpArrayArticle = this.tmpArrayArticle.filter(article => article.country_id == this.valueFilterCountryArticle);
          console.log(this.currentUser.country)
          console.log(this.tmpArrayArticle);
        }

        if(this.valueFilterCategoryArticle != null && this.valueFilterCategoryArticle != undefined){
          this.tmpArrayArticle = this.tmpArrayArticle.filter(article => article.category_id == this.valueFilterCategoryArticle);
        }
      break;

      case 'proposeArticle':

        this.tmpArrayProposeArticle = this.arrayProposeArticle;

        if(this.valueFilterName != null && this.valueFilterName != undefined && this.valueFilterName != ''){
          this.tmpArrayProposeArticle = this.tmpArrayProposeArticle.filter(article => {
            if(article.article_name == null){
              return false;
            }else{
              return article.article_name.toLowerCase().includes(this.valueFilterName.toLowerCase());
            }
          });
        }

        if(this.valueFilterUsername != null && this.valueFilterUsername != undefined && this.valueFilterUsername != ''){
          this.tmpArrayProposeArticle = this.tmpArrayProposeArticle.filter(article => {
            if(article.user.name == null){
              return false;
            }else{
              return article.user.name.toLowerCase().includes(this.valueFilterUsername.toLowerCase());
            }
          });
        }

        if(this.valueFilterCountry != null && this.valueFilterCountry != undefined){
          this.tmpArrayProposeArticle = this.tmpArrayProposeArticle.filter(article => article.country_id == this.valueFilterCountry);
        }

        if(this.valueFilterStatus != null && this.valueFilterStatus != undefined){
          this.tmpArrayProposeArticle = this.tmpArrayProposeArticle.filter(article => article.articles_status_id == this.valueFilterStatus);
        }

      break;
    }
  }

  deleteFilters(section: string){

    switch(section){
      case 'article':

        this.tmpArrayArticle = this.arrayArticle;

        this.valueFilterName = '';
        this.valueFilterCountry = null;
        this.valueFilterCategoryArticle = null;

        break;

      case 'proposeArticle':

        this.tmpArrayProposeArticle = [];
        this.tmpArrayProposeArticle = this.arrayProposeArticle;

        this.valueFilterName = '';
        this.valueFilterUsername = '';
        this.valueFilterCountry = null;
        this.valueFilterStatus = null;

        break;
    }
  }

  refreshTable(section: string){

    switch(section){
      case 'article':
        this.articleService.getValidateArticles().toPromise().then((dataDB: NodeResponse) => {
          console.log('data article DB -> ', dataDB)

          if(dataDB.status){
            this.arrayArticle = dataDB.data;
            this.tmpArrayArticle = dataDB.data;
            this.administrationPanelService.setArrayValidateArticle(dataDB.data);

            this.deleteFilters('article');
          }
        })

        break;

      case 'proposeArticle':
          this.articleService.getProposedArticles().toPromise().then((dataDB: NodeResponse) => {
            console.log('data article DB -> ', dataDB)

            if(dataDB.status){
              this.arrayProposeArticle = dataDB.data;
              this.tmpArrayProposeArticle = dataDB.data;
              this.administrationPanelService.setArrayProposeTool(dataDB.data);

              this.deleteFilters('proposeArticle');
            }
          })

          break;
    }
  }

  toArticleTable(){
    this.deleteFilters('article')

    this.window = 2;
  }

  toProposeArticleTable(){
    this.deleteFilters('proposeArticle')

    this.window = 4;
  }

  toCreateArticle(){
    this.initVariablesForm();

    this.window = 1;
  }

  toUpdateArticle(article: Article){
    this.initVariablesForm();

    console.log(article)

    this.articleSelected = article;

    this.adminComment = article.adminComment;
    this.valueLink = article.link;
    this.valueName = article.article_name;
    this.valueCountry = article.country_id;
    this.valueCategory = article.category_id;
    this.valueDate = article.publication_date.split('T')[0];
    this.valueAuthor = article.author;
    this.valueShortDescription = article.short_description;
    this.valueLongDescription = article.long_description;

    this.nameMainImage = article.main_image;

    if(article.articles_images.length > 0 && article.articles_documents.length > 0 && article.video.length > 0){

      for(let i = 0; i < article.articles_images.length; i++){
        console.log('estoy dentro del bucle de images additional')
        let name: string =  article.articles_images[i].name;
        this.nameAdditionalImages += name.split('/')[4];

        if(i < article.articles_images.length)  this.nameAdditionalImages += ', '
      }

      for(let i = 0; i < article.articles_documents.length; i++){
        console.log('estoy dentro del bucle de documents')
        let name: string =  article.articles_documents[i].name;
        this.nameAdditionalDocuments += name.split('/')[4];

        if(i < article.articles_documents.length)  this.nameAdditionalDocuments += ', '
      }

      this.valueVideoURL = article.video;
      this.isMoreMultimedia = true;

    }

    this.window = 3;
  }

  toProposeArticle(){
    this.deleteFilters('article')

    this.window = 4;
  }

  fileImageChangeEvent(event: any){

    this.valueMainImage = null;

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.valueMainImage = event.target.files[i];
        this.isMainImageChange = true;
      }else{
        alert('You must to select a IMAGE');
        this.valueMainImage = null;
      }
    }

  }

  fileOtherImageChangeEvent(event: any){

    let isAllImages = true;
    this.valueOtherImages = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.valueOtherImages.push(event.target.files[i]);
        this.isAdditionalImagesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es una imagen');
        isAllImages = false;
        break;
      }
    }

    if(isAllImages == false){
      this.valueOtherImages = [];
      alert('You must to select IMAGES');
    }
  }

  fileChangeEvent(event: any){

    let isAllDocuments = true;
    this.valueDocuments = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log( 'Archivo seleccionado n' + i + '-> ', event.target.files[i]);

      if(event.target.files[i].type.substring(5) == 'pdf' || event.target.files[i].type == 'application/pdf'){
        this.valueDocuments.push(event.target.files[i]);
        this.isPdfFilesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es un archivo PDF -> ');
        isAllDocuments = false;
        break;
      }
    }

    if(isAllDocuments == false){
      this.valueDocuments = [];
      alert('You must to select PDF FILES');
    }
  }

  shortDescriptionChangeEvent(){
    this.lengthShortDescription = this.valueShortDescription.length;
  }

  longDescriptionChangeEvent(){
    this.lengthLongDescription = this.valueLongDescription.length;
  }

  createArticle(){

    if(this.isFormFilled()){
      if(confirm('Do you want to create this article?') == true){
        let video = this.isMoreMultimedia == true ? this.valueVideoURL : '';

        this.articleService.createArticle(
          this.valueName, this.valueCountry, this.valueCategory, this.valueDate, this.valueAuthor, this.valueShortDescription,
          this.valueLongDescription, this.valueMainImage, 1, this.guid, video, this.valueLink
        ).toPromise().then(async (dataArticle: NodeResponse) => {
          console.log('createa article resonse -> ', dataArticle);

          if(dataArticle.status == true){

            if(this.isMoreMultimedia == false){
              this.arrayArticle.push(dataArticle.data);
              this.administrationPanelService.setArrayValidateArticle(this.arrayArticle);
              this.toArticleTable();

            }else{
              try{

                for(let i = 0; i < this.valueOtherImages.length; i++){
                  let imagesAdditional = new FormData();

                  imagesAdditional.append('article_id', dataArticle.data.id);
                  imagesAdditional.append('image', this.valueOtherImages[i], this.valueOtherImages[i].name);

                  await this.articleService.uploadImage(imagesAdditional).toPromise().then((dataImage: NodeResponse) => {
                    console.log("response node imagesAdditional -> ", dataImage);
                  })

                  if(i == 4) break;
                }

                for(let i = 0; i < this.valueDocuments.length; i++){
                  let bodyFile = new FormData();

                  bodyFile.append('file', this.valueDocuments[i], this.valueDocuments[i].name);
                  bodyFile.append('article_id', dataArticle.data.id);

                  await this.articleService.uploadDocuments(bodyFile).toPromise().then((dataPDF) => {
                    console.log("dataTool node file -> ", dataPDF);
                  })

                  if(i == 2) break;
                }

                await this.articleService.getArticleById(dataArticle.data.id).toPromise().then((data: NodeResponse) => {
                  console.log("dataTool DB -> ", data);

                  if(data.status == true){
                    console.log('console abajo -> ', data.data)
                    this.arrayArticle.push(data.data);
                    //this.tmpArrayArticle.push(data.data);
                    this.administrationPanelService.setArrayValidateArticle(this.arrayArticle);

                    this.toArticleTable();
                  }
                })


              }catch(err){
                console.error(err)
              }
            }

          }
        })

      }
    }else{
      alert('You need to fill out whole form')
    }

  }

  deleteArticle(article: Article){
    if(confirm('Do you want to delete this tool?') == true){
      this.articleService.deleteArticle(article).toPromise().then((data: NodeResponse) => {
        console.log('article delete responde -> ', data)

        let index = this.arrayArticle.findIndex(e => e.id == article.id);
        this.arrayArticle.splice(index, 1);
        this.deleteFilters('articles');
        this.administrationPanelService.setArrayValidateArticle(this.arrayArticle);
        this.tmpArrayArticle = [];
        this.tmpArrayArticle = this.arrayArticle;
      })
    }
  }

  updateArticle(){
    if(this.isFormFilled()){
      if(confirm('Do you want to update this article?') == true){

        let nameMainImage = this.valueMainImage == null ? this.nameMainImage : this.valueMainImage.name;

        let body = {
          article_name: this.valueName,
          country_id: this.valueCountry,
          category_id: this.valueCategory,
          publication_date: this.valueDate,
          author: this.valueAuthor,
          short_description: this.valueShortDescription,
          long_description: this.valueLongDescription,
          main_image: nameMainImage,
          articles_status_id: this.articleSelected.articles_status_id,
          user_id: this.guid,
          video: '',
          link: this.valueLink
        }

        if(this.isMoreMultimedia == true) body.video = this.valueVideoURL;

        let formData = new FormData();

        formData.append('article', JSON.stringify(body));
        formData.append('oldArticle', JSON.stringify(this.articleSelected));
        formData.append('image', this.valueMainImage);

        this.articleService.updateArticle(formData).toPromise().then(async (data: NodeResponse) => {
          console.log('data update tool -> ', data)

          if(data.status == true){

            if(this.isMoreMultimedia == true){

              if(this.isAdditionalImagesChange == true){
                for(let i = 0; i < this.valueOtherImages.length; i++){

                  let formDataAdditionalImages = new FormData();

                  formDataAdditionalImages.append('image', this.valueOtherImages[i], this.valueOtherImages[i].name);
                  formDataAdditionalImages.append('articleId', this.articleSelected.id + '');
                  let isFirst = i == 0 ? true : false;
                  formDataAdditionalImages.append('isFirst', isFirst + '');

                  await this.articleService.updateUploadImage(formDataAdditionalImages).toPromise().then((updateImage: NodeResponse) => {
                    console.log("dataArticle node update image -> ", updateImage);

                  })

                }
              }

              if(this.isPdfFilesChange == true){
                for(let i = 0; i < this.valueDocuments.length; i++){
                  let bodyPdf = new FormData();

                  bodyPdf.append('file', this.valueDocuments[i], this.valueDocuments[i].name);
                  bodyPdf.append('articleId', this.articleSelected.id + '');
                  let isFirst = i == 0 ? true : false;
                  bodyPdf.append('isFirst', isFirst + '');

                  await this.articleService.updateUploadDocuments(bodyPdf).toPromise().then((data: NodeResponse) => {
                    console.log("dataArticle node update pdf -> ", data);
                  })

                }
              }

              await this.articleService.getArticleById(this.articleSelected.id).toPromise().then((newData: NodeResponse) => {
                console.log("dataTool DB -> ", newData);

                if(data.status == true){

                  if(this.articleSelected.articles_status_id == 1){
                    //console.log('console abajo -> ', newData.data)

                    let index = this.arrayArticle.findIndex(article => article.id == this.articleSelected.id);
                    this.arrayArticle.splice(index, 1);

                    this.arrayArticle.push(newData.data);
                    //this.tmpArrayArticle.push(data.data);
                    this.administrationPanelService.setArrayValidateArticle(this.arrayArticle);

                    this.toArticleTable();
                  }else{

                    //console.log('console abajo -> ', newData.data)

                    let index = this.arrayProposeArticle.findIndex(article => article.id == this.articleSelected.id);
                    this.arrayProposeArticle.splice(index, 1);

                    this.arrayProposeArticle.push(newData.data);
                    //this.tmpArrayArticle.push(data.data);
                    this.administrationPanelService.setArrayProposedArticle(this.arrayProposeArticle);

                    this.toProposeArticleTable()

                  }

                }
              })


            }else{

              if(this.articleSelected.articles_images.length > 0){
                this.articleService.deleteAdditionalImagesArticle(this.articleSelected.id).toPromise().then(async (dataImage: NodeResponse) => {
                  console.log("dataImage node delete  -> ", data);
                })

              }

              if(this.articleSelected.articles_documents.length > 0){
                this.articleService.deleteFilesArticle(this.articleSelected.id).toPromise().then(async (dataFile: NodeResponse) => {
                  console.log("dataFile node delete  -> ", data);
                })
              }

              await this.articleService.getArticleById(this.articleSelected.id).toPromise().then((newData: NodeResponse) => {
                console.log("dataTool DB -> ", newData);

                if(data.status == true){

                  if(this.articleSelected.articles_status_id == 1){
                    //console.log('console abajo -> ', newData.data)

                    let index = this.arrayArticle.findIndex(article => article.id == this.articleSelected.id);
                    this.arrayArticle.splice(index, 1);

                    this.arrayArticle.push(newData.data);
                    //this.tmpArrayArticle.push(data.data);
                    this.administrationPanelService.setArrayValidateArticle(this.arrayArticle);

                    this.toArticleTable();
                  }else{

                    //console.log('console abajo -> ', newData.data)

                    let index = this.arrayProposeArticle.findIndex(article => article.id == this.articleSelected.id);
                    this.arrayProposeArticle.splice(index, 1);

                    this.arrayProposeArticle.push(newData.data);
                    //this.tmpArrayArticle.push(data.data);
                    this.administrationPanelService.setArrayProposedArticle(this.arrayProposeArticle);

                    this.toProposeArticleTable()

                  }

                }
              })

            }

          }
        })
      }
    }else{
      alert('You need to fill out whole form')
    }

  }

  initVariablesForm(){

    this.valueName = '';
    this.valueCountry = null;
    this.valueCategory = null;
    this.valueDate = null;
    this.valueAuthor = '';
    this.valueShortDescription = '';
    this.valueLongDescription = '';
    this.valueMainImage = null;
    this.valueOtherImages = [];
    this.valueDocuments = [];
    this.valueVideoURL = '';

    this.isMoreMultimedia = false;

    this.isAdditionalImagesChange = false;
    this.isPdfFilesChange = false;
    this.isMainImageChange = false;

    this.nameMainImage = '';
    this.nameAdditionalDocuments = '';
    this.nameAdditionalImages = '';
  }

  isFormFilled(): boolean{

    let value = false;
    let value2 = false;

    if(
      this.valueName != '' &&
      this.valueCountry != null &&
      this.valueCategory != null &&
      this.valueDate != null &&
      this.valueAuthor != '' &&
      this.valueShortDescription != '' &&
      this.valueLongDescription != '' &&
      (this.valueMainImage != null || this.nameMainImage != '')

    ){
      value = true;
    }

    if(this.isMoreMultimedia == true){
      if((this.valueOtherImages.length > 0 || this.nameAdditionalImages != '') || (this.valueDocuments.length > 0 || this.nameAdditionalDocuments != '') || this.valueVideoURL != ''){
        value2 = true
      }
    }else{
      value2 = true
    }

    return (value && value2);
  }

  openModalArticles(article: Article) {
    //this.incrementVisitCount(article.id);
    this.modalRef = this.modalService.show(ArticlesModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { article}});
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.onHidden.asObservable().subscribe((article: Article) => {
      console.log('article en el componente -> ', article);
      if(article.articles_status_id == 1){
        let index = this.arrayProposeArticle.findIndex(a => a.id == article.id);

        this.arrayProposeArticle.splice(index, 1);
        this.tmpArrayProposeArticle = this.arrayProposeArticle;

        this.arrayArticle.push(article);

      }else if(article.articles_status_id == 2){
        let index = this.arrayProposeArticle.findIndex(a => a.id == article.id);

        this.arrayProposeArticle.splice(index, 1);
        this.tmpArrayProposeArticle = this.arrayProposeArticle;
      }
    })
  }

  consoleJorge(){
    console.log(this.valueName,
      this.valueCountry,
      this.valueCategory,
      this.valueDate,
      this.valueAuthor,
      this.valueShortDescription,
      this.valueLongDescription,
      this.valueMainImage,
      this.valueOtherImages,
this.valueDocuments,
this.valueVideoURL)
  }
}
<div style="padding-top: 2rem;">

  <div *ngIf="window == 1" class="form-group row">
    <div class="col-1">
      Filters:
    </div>

    <div class="col-2">
      <input type="text" class="form-control" id="nameFilter" name="nameFilter" [(ngModel)]="valueFilterName" placeholder="Name filter">
    </div>

    <div class="col-2">
      <input type="text" class="form-control" id="valueFilterSubject" name="valueFilterSubject" [(ngModel)]="valueFilterSubject" placeholder="Subject filter">
    </div>

    <div class="col-2">
      <ng-select name="valueFilterStatus" id="valueFilterStatus" [(ngModel)]="valueFilterStatus" placeholder="Status filter">
        <ng-option *ngFor="let status of arrayStatus" [value]="status.id">{{status.name}}</ng-option>
      </ng-select>
    </div>

    <div class="col-1">
      <button class="form-control btn btn-warning" id="applyFilter" name="applyFilter" (click)="getAllSuggestion()">Apply</button>
    </div>

    <div class="col-1">
      <button class="form-control btn btn-danger" id="initTable" name="initTable" (click)="initTable()">Delete</button>
    </div>


  </div>

  <div *ngIf="window == 1" class="form-group row">
    <table class="table table-hover" id="table" name="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Subject</th>
          <th scope="col">Username</th>
          <th scope="col">Status</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let suggestion of arraySuggestion; index as i">
          <td>{{suggestion.subject}}</td>
          <td>{{suggestion.user.name}}</td>
          <td>{{suggestion.suggestion_status.name}}</td>
          <td>
            <button class="btn btn-light" (click)="showSuggestion(suggestion.id)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button class="btn btn-light" (click)="deleteSuggestion(suggestion.id)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="counter" (pageChange)="getAllSuggestion()"></ngb-pagination>

  </div>

  <div *ngIf="window == 2">
    <div class="d-flex justify-content-left navButtons">
      <button type="button" class="btn btn-link buttonsAdminTools" (click)="initTable()"><div class="h3"><- Back</div></button>
    </div>

    <div class="form-group">
      <label for="name">Subject</label>
      <input type="text" id="subject" class="form-control" placeholder="Subject of the propose" name="subject" [(ngModel)]="suggestionSelected.subject" readonly><br>
    </div>

    <div class="form-group">
      <label for="suggestion">Suggestion (5000)</label><br>
      <textarea id="suggestion" name="suggestion" class="form-control" placeholder="Write the suggestion" rows="4" cols="50" maxlength="5000" [(ngModel)]="suggestionSelected.suggestion" readonly></textarea>
      <div>{{suggestionSelected.suggestion.length}}/5000</div><br>
    </div>

    <div class="form-group">
      <label for="images" class="form-label">Images</label><br>
      <div *ngFor="let image of suggestionSelected.suggestion_images">
        <img src="{{imageUrl}}/{{image.name.substring(9)}}" height="350" width="500" class="card-img-top">
      </div>
    </div>

    <div class="form-group">
      <label for="video_url">Video</label>
      <input type="text" id="video_url" class="form-control" placeholder="Link to a video" name="video_url" [(ngModel)]="suggestionSelected.video_url" readonly><br>
    </div>

    <div class="form-group">
      <label for="video_url">Select status to change it</label>
      <ng-select name="valueSelectStatus" id="valueSelectStatus"[(ngModel)]="valueStatusSelected" placeholder="Select status">
        <ng-option *ngFor="let status of secondArrayStatus" [value]="status.id">{{status.name}}</ng-option>
      </ng-select>
    </div>

    <div class="form-group">
      <label for="video_url">Select status to change it</label>
      <textarea id="comentary" name="comentary" class="form-control" placeholder="Write the comentary" rows="4" cols="50" maxlength="5000" [(ngModel)]="valueCommentary"></textarea>
      <div>{{valueCommentary.length}}/5000</div><br>
    </div>

    <div class="form-group">
      <button *ngIf="(valueStatusSelected != null && valueStatusSelected != suggestionSelected.status_id) || this.valueCommentary != this.suggestionSelected.commentary" class="btn btn-primary" (click)="changeStatus()">Submit</button>
    </div>

  </div>
</div>

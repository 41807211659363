
<div class="container" style="overflow: hidden;">

  <div>
    <div class="row">
      <div class="col-md-5">
        {{'SUCCESS_STORIES_RESULTS.PROVINCE' | translate}}: <br>

        <div *ngIf="isNanOrNull(ceeBuilding.address)">
          <h4 class="map_address-building" name="map_address">{{ceeBuilding.address}}</h4>
        </div>
      </div>
      <div class="col-md-3">

      <div *ngIf="(role == 1 || role == 2) && (ceeBuilding.status_id == 3 || ceeBuilding.status_id == 2)">
        <label for="ceeBuildingAdminComment">Admin comment</label>
        <textarea class="form-control" id="ceeBuildingAdminComment" name="ceeBuildingAdminComment" rows="3" placeholder="An admin comment for this ceeBuilding" [(ngModel)]="ceeBuilding.adminComment" readonly></textarea>
      </div>

      </div>
      <div class="col-md-4">
        <button (click)="goBack()" class="p-2 rounded-button">
          <span class="button-back"> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; {{'SUCCESS_STORIES_RESULTS.BUTTON_BACK' | translate}}</span>
        </button>
        <div *ngIf="(role == 1 || role == 2) && ceeBuilding.status_id == 3">
          <button class="btn btn-primary" (click)="validateBuilding()">Validate</button>
          <button class="btn btn-danger" (click)="rejectBuild(content)">Reject</button>
        </div>

        <div *ngIf="role != null && role <= 4 && ceeBuilding.status_id == 1 && isSaved != null">
          <button class="btn btn-primary" *ngIf="!isSaved" (click)="saveUserCeeBuilding()">Save</button>
          <button class="btn btn-danger" *ngIf="isSaved" (click)="deleteUserCeeBuilding()">Unsave</button>
        </div>
      </div>

    </div>
  </div>

  <br>

  <div class="row">
    <div class="col-md-4">
      <ng-container *ngIf="ceeBuilding.facade_picture">
        <img *ngIf="isURL(ceeBuilding.facade_picture)" src="{{ceeBuilding.facade_picture}}" width="270" height="370">
        <ng-container *ngIf="!isURL(ceeBuilding.facade_picture)">
          <!-- Aquí puedes poner una imagen de reemplazo en lugar de mostrar la URL no válida -->
          <img src="{{imageUrl}}/imageFacade/{{ceeBuilding.id}}/{{ceeBuilding.facade_picture}}" width="270" height="370">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!ceeBuilding.facade_picture">

        <!--Typology AB-->
        <img *ngIf="ceeBuilding.country === 'Spain' && ceeBuilding.tipology_id === 'AB'" src="../../../assets/img/typology/ES/ES.ME.AB.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'France' && ceeBuilding.tipology_id === 'AB'" src="../../../assets/img/typology/FR/FR.N.AB.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Italy' && ceeBuilding.tipology_id === 'AB'" src="../../../assets/img/typology/IT/IT.MidClim.AB.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Slovenia' && ceeBuilding.tipology_id === 'AB'" src="../../../assets/img/typology/SI/SI.N.AB.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Bulgary' && ceeBuilding.tipology_id === 'AB'" src="../../../assets/img/typology/BG/BG.N.AB.03.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Greece' && ceeBuilding.tipology_id === 'AB'" src="../../../assets/img/typology/GR/GR.ZoneA.SFH.02.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Netherlands' && ceeBuilding.tipology_id === 'AB'" src="../../../assets/img/typology/NL/NL.N.AB.01.gal.001.jpg" width="270" height="370">

        <!--Typology MFH-->
        <img *ngIf="ceeBuilding.country === 'Spain' && ceeBuilding.tipology_id === 'MFH'" src="../../../assets/img/typology/ES/ES.ME.MFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'France' && ceeBuilding.tipology_id === 'MFH'" src="../../../assets/img/typology/FR/FR.N.MFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Italy' && ceeBuilding.tipology_id === 'MFH'" src="../../../assets/img/typology/IT/IT.MidClim.MFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Slovenia' && ceeBuilding.tipology_id === 'MFH'" src="../../../assets/img/typology/SI/SI.N.MFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Bulgary' && ceeBuilding.tipology_id === 'MFH'" src="../../../assets/img/typology/BG/BG.N.MFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Greece' && ceeBuilding.tipology_id === 'MFH'" src="../../../assets/img/typology/GR/GR.ZoneA.MFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Netherlands' && ceeBuilding.tipology_id === 'MFH'" src="../../../assets/img/typology/NL/NL.N.MFH.01.Gen.ReEx.001.jpg" width="270" height="370">

        <!--Typology SFH-->
        <img *ngIf="ceeBuilding.country === 'Spain' && ceeBuilding.tipology_id === 'SFH'" src="../../../assets/img/typology/ES/ES.ME.SFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'France' && ceeBuilding.tipology_id === 'SFH'" src="../../../assets/img/typology/FR/FR.N.SFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Italy' && ceeBuilding.tipology_id === 'SFH'" src="../../../assets/img/typology/IT/IT.MidClim.SFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Slovenia' && ceeBuilding.tipology_id === 'SFH'" src="../../../assets/img/typology/SI/SI.N.SFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Bulgary' && ceeBuilding.tipology_id === 'SFH'" src="../../../assets/img/typology/BG/BG.N.SFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Greece' && ceeBuilding.tipology_id === 'SFH'" src="../../../assets/img/typology/GR/GR.ZoneA.SFH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Netherlands' && ceeBuilding.tipology_id === 'SFH'" src="../../../assets/img/typology/NL/NL.N.SFH.01.Gen.ReEx.001.jpg" width="270" height="370">

        <!--Typology TH-->
        <img *ngIf="ceeBuilding.country === 'Spain' && ceeBuilding.tipology_id === 'TH'" src="../../../assets/img/typology/ES/ES.ME.TH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'France' && ceeBuilding.tipology_id === 'TH'" src="../../../assets/img/typology/FR/FR.N.TH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Italy' && ceeBuilding.tipology_id === 'TH'" src="../../../assets/img/typology/IT/IT.MidClim.TH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Slovenia' && ceeBuilding.tipology_id === 'TH'" src="../../../assets/img/typology/SI/SI.N.TH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Bulgary' && ceeBuilding.tipology_id === 'TH'" src="../../../assets/img/typology/BG/BG.N.TH.01.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Greece' && ceeBuilding.tipology_id === 'TH'" src="../../../assets/img/typology/GR/GR.ZoneD.SFH.03.Gen.ReEx.001.jpg" width="270" height="370">
        <img *ngIf="ceeBuilding.country === 'Netherlands' && ceeBuilding.tipology_id === 'TH'" src="../../../assets/img/typology/NL/NL.N.TH.01.Gen.ReEx.001.jpg" width="270" height="370">
      </ng-container>

      <!--<img *ngIf="isURL(ceeBuilding.facade_picture)" src="{{ceeBuilding.facade_picture}}" width="270" height="370">

      <img *ngIf="!isURL(ceeBuilding.facade_picture)" src="{{imageUrl}}/imageFacade/{{ceeBuilding.id}}/{{ceeBuilding.facade_picture}}" width="270" height="370">-->



    </div>
    <div class="col-md-4">
      <div class="row">
        <div class="d-flex bd-highlight title-information">
          <div class="p-2 flex-fill bd-highlight" *ngIf="ceeBuilding.land_surface">
            <span><img src="../../../assets/img/icons/square_meters_icon.svg" alt="Surface" style="width: 16px!important;">&nbsp;{{ceeBuilding.land_surface}}&nbsp; m<sup>2</sup> </span>
          </div>
          <div class="p-2 flex-fill bd-highlight title-information">
            <span><img src="../../../assets/img/icons/year_icon.svg" alt="year" style="width: 16px;">&nbsp;{{ceeBuilding.year}}</span>
          </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="d-flex bd-highlight title-information">
            <div class="p-2 flex-fill bd-highlight" *ngIf="ceeBuilding.tipology_id">
              <!--<img class="card-img-top typologyIcon p-2 flex-fill bd-highlight" alt="Building image"  src="./assets/img/typology/{{building.country}}/{{building.typology.picName}}" >-->
              <!--For the cadastre in Spain-->
              <img *ngIf="tipologyCode === 'Apartment Block'" src="../../../assets/img/icons/apartment_block_icon.svg">
              <img *ngIf="tipologyCode === 'Multi Family Home'" src="../../../assets/img/icons/multy_family_icon.svg">
              <img *ngIf="tipologyCode === 'Single Family Home'" src="../../../assets/img/icons/single_family_icon.svg">
              <img *ngIf="tipologyCode === 'Terraced House'" src="../../../assets/img/icons/terraced_house_icon.svg">
              {{tipologyCode}}
            </div>
            <div class="p-2 flex-fill bd-highlight title-information" *ngIf="ceeBuilding.case_id">
              <img *ngIf="caseNme === 'Complete Building'" src="../../../assets/img/cee-building-data/icon.PNG">
              <img *ngIf="caseNme === 'Single Home'" src="../../../assets/img/cee-building-data/icon.PNG">
              {{caseNme}}
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="d-flex bd-highlight title-information">
            <div class="p-2 flex-fill bd-highlight" style="color: #004470;" *ngIf="ceeBuilding.rc">
              <div>{{'SUCCESS_STORIES_RESULTS.CADASTRE_REFERENCE' | translate}}:</div>
              <span style="font-weight: bold;">{{ceeBuilding.rc}}</span>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="d-flex bd-highlight title-information">
            <div class="p-2 flex-fill bd-highlight" style="color: #004470;">
              <div>{{'SUCCESS_STORIES_RESULTS.PRIMARY_USE' | translate}}:</div>
              <span style="font-weight: bold;">Residential</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="title-energy-refurbishment">
          <span class="title-energy">{{'SUCCESS_STORIES_RESULTS.ENERGY_REFURBISHMENT' | translate}}</span>
          <img class="image-check" src="../../../assets/img/cee-building-data/checkmark-24.png">
          <span class="saving" *ngIf="ceeBuilding.year_certificate !== null">Saving {{savingTotal}}%</span>
          <span class="saving" *ngIf="ceeBuilding.year_certificate === null">No information</span>
        </div>
        <br>
        <div class="row">
          <div class="col" style="text-align: justify;" *ngIf="ceeBuilding.year_certificate === null">
            There is not information of the first certificate.
          </div>
          <div class="col" *ngIf="ceeBuilding.year_certificate !== null">
            <span class="year-certificate">
              <b>{{ceeBuilding.year_certificate}}</b>
            </span><br><br>
            <label>{{'SUCCESS_STORIES_RESULTS.EMISSIONS' | translate}}</label><br>
            <div id="letterCO2">
              <span style="padding-left: 10px;">
                {{letterCO2}}
              </span>
              <span style="padding-left: 70px;">
                {{ceeBuilding.value_co2}} CO<sub>2</sub>
              </span>
            </div>
            <br>
            <label>{{'SUCCESS_STORIES_RESULTS.ENERGY_CONSUMPTION' | translate}}</label><br>
            <div id="letterEP">
              <span style="padding-left: 10px;">
                {{letterEP}}
              </span>
              <span style="padding-left: 70px;">
                {{ceeBuilding.value_ep}} Epnr
              </span>
            </div>
          </div>
          <div class="col" style="text-align: justify;" *ngIf="ceeBuilding.year_certificate2 === null">
            {{'SUCCESS_STORIES_RESULTS.TEXT_NOT_CERTIFICATION' | translate}}
          </div>
          <div class="col" *ngIf="ceeBuilding.year_certificate2 !== null">
            <span class="year-certificate">
              <b>{{ceeBuilding.year_certificate2}}</b>
            </span><br><br>
            <label><b>{{'SUCCESS_STORIES_RESULTS.EMISSIONS' | translate}}</b></label><br>
            <div id="letterCO2_cert2">
              <span style="padding-left: 10px;">
                {{letterCO2_cert2}}
              </span>
              <span style="padding-left: 70px;">
                {{ceeBuilding.value_co2_cert2}} CO<sub>2</sub>
              </span>
            </div>
            <br>
            <label><b>{{'SUCCESS_STORIES_RESULTS.ENERGY_CONSUMPTION' | translate}}</b></label><br>
            <div id="letterEP_cert2">
              <span style="padding-left: 10px;">
                {{letterEP_cert2}}
              </span>
              <span style="padding-left: 70px;">
                {{ceeBuilding.value_ep_cert2}} Epnr
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>

    <div>
      <div class="accordion" id="today">


      <div class="card">
        <div class="card-header header-principal" id="energyEfficiency">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block align-items-center d-flex justify-content-center collapsed button-title" type="button" data-toggle="collapse" data-target="#energySection" aria-expanded="true" aria-controls="energySection">
              <h3>{{'SUCCESS_STORIES_RESULTS.RENOVATION_SECTION' | translate}}</h3>&nbsp;
            </button>
          </h2>
        </div>

        <div id="energySection" class="collapse show" [ngClass]="" aria-labelledby="energyEfficiency" >
          <nav ngbNav #nav="ngbNav" class="nav-tabs">
            <ng-container ngbNavItem class="me-2">
              <h6>
                <a class="title-nav" href="#building-information" ngbNavLink>{{'SUCCESS_STORIES_RESULTS.BUILDING_DWELLING_INFORMATION' | translate}}</a>
              </h6>
              <ng-template ngbNavContent>
                <div class="container background-building">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="rounded-information">
                        <h5>
                          {{caseNme}}
                        </h5>
                      </div>
                      <div class="text-information">
                        {{'SUCCESS_STORIES_RESULTS.SCOPE_BUILDING' | translate}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <div class="rounded-information">
                        <h5>
                          {{tipologyCode}}
                        </h5>
                      </div>
                      <div class="text-information">
                        {{'SUCCESS_STORIES_RESULTS.BUILDING_TYPOLOGY' | translate}}
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="rounded-information">
                        <h5>
                          {{ceeBuilding.year}}
                        </h5>
                      </div>
                      <div class="text-information">
                        {{'SUCCESS_STORIES_RESULTS.CONSTRUCTION_YEAR' | translate}}
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="rounded-information">
                        <h5>
                          {{ceeBuilding.reform_year}}
                        </h5>
                      </div>
                      <div class="text-information">
                        {{'SUCCESS_STORIES_RESULTS.RENOVATION_YEAR' | translate}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <div class="rounded-information">
                        <h5>
                          {{ceeBuilding.number_floors}}
                        </h5>
                      </div>
                      <div class="text-information">
                        {{'SUCCESS_STORIES_RESULTS.NUMBER_FLOORS' | translate}}
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="rounded-information">
                        <h5>
                          {{ceeBuilding.number_dwellings}}
                        </h5>
                      </div>
                      <div class="text-information">
                        {{'SUCCESS_STORIES_RESULTS.NUMBER_DWELLINGS' | translate}}
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="rounded-information">
                        <h5>
                          {{ceeBuilding.building_surface}}
                        </h5>
                      </div>
                      <div class="text-information">
                        {{'SUCCESS_STORIES_RESULTS.BUILDING_SURFACE' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem class="me-2">
              <h6>
                <a class="title-nav" href="#renovation-impact" ngbNavLink>{{'SUCCESS_STORIES_RESULTS.MEASURES_IMPLEMENTED' | translate}}</a>
              </h6>
              <ng-template ngbNavContent>
                <div class="container">
                  <div class="row">
                    <div class="col-md-2" style="text-align: center;">
                      <img [src]="ceeBuilding.windows === true ? '../../../assets/img/icons/window_icon.svg' : '../../../assets/img/icons/window_not_reformed.png'">
                      <div [ngClass]="ceeBuilding.windows === true ? 'windows-true' : 'windows-false'">
                        {{'SUCCESS_STORIES_RESULTS.WINDOWS_REPLACEMENT' | translate}}
                      </div>
                    </div>
                    <div class="col-md-2" style="text-align: center;">
                      <img [src]="ceeBuilding.wall === true ? '../../../assets/img/icons/wall_icon.svg' : '../../../assets/img/icons/walls-not_reformed.png'">
                      <div [ngClass]="ceeBuilding.wall === true ? 'wall-true' : 'wall-false'">
                        {{'SUCCESS_STORIES_RESULTS.INSULTATION_REPLACEMENT' | translate}}
                      </div>
                    </div>
                    <div class="col-md-2" style="text-align: center;">
                      <img [src]="ceeBuilding.photovoltaic === true ? '../../../assets/img/icons/solar-panel-icon.png' : '../../../assets/img/icons/pv_not_reformed.png'">
                      <div [ngClass]="ceeBuilding.photovoltaic === true ? 'pv-true' : 'pv-false'">
                        {{'SUCCESS_STORIES_RESULTS.PHOTOVOLTAIC_PANELS' | translate}}
                      </div>
                    </div>
                    <div class="col-md-2" style="text-align: center;">
                      <img [src]="ceeBuilding.aerothermal === true ? '../../../assets/img/icons/aerothermal-reformed.png' : '../../../assets/img/icons/aerothermal-not_reformed.png'">
                      <div [ngClass]="ceeBuilding.aerothermal === true ? 'aerothermal-true' : 'aerothermal-false'">
                        {{'SUCCESS_STORIES_RESULTS.AEROTHERMAL' | translate}}
                      </div>
                    </div>
                    <div class="col-md-2" style="text-align: center;">
                      <img [src]="ceeBuilding.shw === true ? '../../../assets/img/icons/water_icon.svg' : '../../../assets/img/icons/water-not_reformed.png'">
                      <div [ngClass]="ceeBuilding.shw === true ? 'shw-true' : 'shw-false'">
                        {{'SUCCESS_STORIES_RESULTS.SHW_REPLACEMENT' | translate}}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="container-description" *ngIf="ceeBuilding.intervention_description !== null">
                        <label class="label-description">
                          {{'SUCCESS_STORIES_RESULTS.DESCRIPTION_INTERVENTION' | translate}}
                        </label>
                        <div class="description">
                          {{ceeBuilding.intervention_description}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 style="font-weight: bold;">
                    {{'SUCCESS_STORIES_RESULTS.PHOTOGRAPHS_BUILDING' | translate}}
                  </h5>
                  <br>
                  <div class="container">
                    <div class="text-without-testimony" *ngIf="renoPictures.data.length === 0">
                      There are no renovation pictures for this building.
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <ngb-carousel [interval]="10000">
                        <ng-template ngbSlide *ngFor="let images of renoPictures.data">
                          <img
                            [src]="imageUrl + images.name.slice(9)"
                            alt="Imagen de Reno"
                            width="500"
                          />
                        </ng-template>
                      </ngb-carousel>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
            <ng-container ngbNavItem class="me-2">
              <h6>
                <a class="title-nav" href="#cost-financing" ngbNavLink>{{'SUCCESS_STORIES_RESULTS.COST_FINANCING_BUILDING' | translate}}</a>
              </h6>
              <ng-template ngbNavContent>
                <div class="container background-building">
                  <h5 style="font-weight: bold; text-align: center;">
                    {{'SUCCESS_STORIES_RESULTS.WHOLE_BUILDING' | translate}}
                  </h5>
                  <div class="row">
                    <div class="col-md-5 investment-building mx-auto">
                      <div class="row">
                        <div class="col-md-6">
                          <span class="title-cost">{{'SUCCESS_STORIES_RESULTS.TOTAL_COST' | translate}}</span>
                        </div>
                        <div class="col-md-3">
                          <span class="investment-building2">{{ceeBuilding.investment}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.investment_square_meter}} €/m<sup>2</sup></span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span>{{'SUCCESS_STORIES_RESULTS.RECEIVED_GRANT' | translate}}</span>
                        </div>
                        <div class="col-md-3">
                          <span class="investment-building2">{{ceeBuilding.renovation_subsidies}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.subsidies_percent}} %</span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span>{{'SUCCESS_STORIES_RESULTS.FINAL_COST_WITH_GRANT' | translate}}</span>
                        </div>
                        <div class="col-md-3">
                          <span class="investment-building2">{{ceeBuilding.final_investment}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.final_investment_sqmeters}} €/m<sup>2</sup></span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span style="font-weight: bold;">{{'SUCCESS_STORIES_RESULTS.MONTHLY_FINAL_COST' | translate}}</span>
                        </div>
                        <div class="col-md-6">
                          <span style="font-weight: bold;">{{ceeBuilding.investment_permonth}} €</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5 subsidies-building mx-auto">
                      <label class="title-subsidies-building">{{'SUCCESS_STORIES_RESULTS.NEXT_GENERATION' | translate}}</label>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span>Grant estimation</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_subsidies}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_subsidies_percent}}%</span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span>{{'SUCCESS_STORIES_RESULTS.FINAL_COST_WITH_GRANT' | translate}}</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_final_investment}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_final_investment_m2}} €/m<sup>2</sup></span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span style="font-weight: bold;">{{'SUCCESS_STORIES_RESULTS.MONTHLY_FINAL_COST' | translate}}</span>
                        </div>
                        <div class="col-md-6">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_final_inv_month}} €</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 style="font-weight: bold; text-align: center;">
                    {{'SUCCESS_STORIES_RESULTS.PER_DWELLING' | translate}}
                  </h5>

                  <div class="row">
                    <div class="col-md-5 investment-per-dwelling mx-auto">
                      <div class="row">
                        <div class="col-md-6">
                          <span class="title-cost">{{'SUCCESS_STORIES_RESULTS.TOTAL_COST' | translate}}</span>
                        </div>
                        <div class="col-md-3">
                          <span class="investment-building2">{{ceeBuilding.investment_dwelling}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.investment_square_meter}} €/m<sup>2</sup></span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span>{{'SUCCESS_STORIES_RESULTS.RECEIVED_GRANT' | translate}}</span>
                        </div>
                        <div class="col-md-3">
                          <span class="investment-building2">{{ceeBuilding.subsidies_dwelling}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.subsidies_percent_dwelling}} %</span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span>{{'SUCCESS_STORIES_RESULTS.FINAL_COST_WITH_GRANT' | translate}}</span>
                        </div>
                        <div class="col-md-3">
                          <span class="investment-building2">{{ceeBuilding.final_investment_dwelling}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.final_inv_sqmeters_dwellings}} €/m<sup>2</sup></span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span style="font-weight: bold;">{{'SUCCESS_STORIES_RESULTS.MONTHLY_FINAL_COST' | translate}}</span>
                        </div>
                        <div class="col-md-6">
                          <span style="font-weight: bold;">{{ceeBuilding.inv_permonth_dwellings}} €</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5 subsidies-per-dwelling mx-auto">
                      <label class="title-subsidies-building">{{'SUCCESS_STORIES_RESULTS.NEXT_GENERATION' | translate}}</label>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span>Grant estimation</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_subsidies_dw}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_subsidies_percent_dw}}%</span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span>{{'SUCCESS_STORIES_RESULTS.FINAL_COST_WITH_GRANT' | translate}}</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_final_investment_dw}} €</span>
                        </div>
                        <div class="col-md-3">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_final_inv_m2_dw}} €/m<sup>2</sup></span>
                        </div>
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-md-6">
                          <span style="font-weight: bold;">{{'SUCCESS_STORIES_RESULTS.MONTHLY_FINAL_COST' | translate}}</span>
                        </div>
                        <div class="col-md-6">
                          <span style="font-weight: bold;">{{ceeBuilding.potential_final_inv_month_dw}} €</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>

        <div class="card ">
          <div class="card-header header-principal" id="buildingData">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block collapsed align-items-center d-flex justify-content-center button-title" type="button" data-toggle="collapse" data-target="#components" aria-expanded="false" aria-controls="components">
                <h3>{{'SUCCESS_STORIES_RESULTS.EXPERIENCE_SECTION' | translate}}</h3>&nbsp;
              </button>
            </h2>
          </div>

          <div id="components" class="collapse" aria-labelledby="buildingData">
            <nav ngbNav #nav2="ngbNav" class="nav-tabs">
              <ng-container ngbNavItem class="me-2">
                <h6>
                  <a class="title-nav" href="#experience" ngbNavLink>{{'SUCCESS_STORIES_RESULTS.VIDEO_EXPERIENCE' | translate}}</a>
                </h6>
                <ng-template ngbNavContent>
                  <div class="container">
                    <div class="text-without-testimony" *ngIf="ceeBuilding.video_testimony === null || ceeBuilding.video_testimony === ''">
                      {{'SUCCESS_STORIES_RESULTS.TEXT_NOT_VIDEO' | translate}}
                    </div>
                    <iframe width="1260" height="595" *ngIf="ceeBuilding.video_testimony !== null" [src]="ceeBuilding.video_testimony | safe" frameborder="0" allowfullscreen></iframe>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container ngbNavItem class="me-2">
                <h6>
                  <a class="title-nav" href="#testimonies" ngbNavLink>{{'SUCCESS_STORIES_RESULTS.TESTIMONIES_EXPERIENCE' | translate}}</a>
                </h6>
                <ng-template ngbNavContent>
                  <div class="text-without-testimony" *ngIf="testimonies.length === 0">
                    {{'SUCCESS_STORIES_RESULTS.TEXT_NOT_TESTIMONIES' | translate}}
                  </div>
                  <div class="text-center" *ngIf="testimonies.length === 1">
                    <div *ngFor="let testimony of testimonies">
                      <div *ngIf="testimony.name !== null">
                        <div class="row">
                          <div class="col">
                            <label class="author-testimony">
                              <b>{{testimony.name}}</b>
                            </label>
                            <footer class="footer-testimony">
                              {{testimony.role}}
                            </footer>
                            <br>
                            <div class="quote-testimony">
                              "{{testimony.quote}}"
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="testimonies.length !== 1">
                    <div class="row">
                      <div class="col-md-4" *ngFor="let testimony of testimonies">
                        <label class="author-testimony">
                          <b>{{testimony.name}}</b>
                        </label>
                        <footer class="footer-testimony">
                          {{testimony.role}}
                        </footer>
                        <br>
                        <div class="quote-testimony">
                          "{{testimony.quote}}"
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container ngbNavItem class="me-2">
                <h6>
                  <a class="title-nav" href="#photographs" ngbNavLink>{{'SUCCESS_STORIES_RESULTS.PHOTOGRAPH_EXPERIENCE' | translate}}</a>
                </h6>
                <ng-template ngbNavContent>
                  <div class="container">
                    <div class="text-without-testimony" *ngIf="testimonyPictures.length === 0">
                      {{'SUCCESS_STORIES_RESULTS.TEXT_NOT_PHOTOGRAPHS' | translate}}
                    </div>
                      <!--<div *ngFor="let testimonyPic of testimonyPictures">
                        <img src="{{imageUrl}}{{testimonyPic.name.slice(9)}}" width="300">&nbsp;&nbsp;
                      </div>-->
                      <div class="d-flex justify-content-center align-items-center">
                        <ngb-carousel [interval]="10000">
                          <ng-template ngbSlide *ngFor="let testimonyPic of testimonyPictures">
                            <img
                              [src]="imageUrl + testimonyPic.name.slice(9)"
                              alt="Imagen de testimonio"
                              width="500"
                            />
                          </ng-template>
                        </ngb-carousel>
                      </div>
                  </div>
                </ng-template>
              </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav2" class="mt-2"></div>
          </div>

          <div class="card">
            <div class="card-header  header-principal" id="systemData">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block align-items-center d-flex justify-content-center collapsed button-title" type="button" data-toggle="collapse" data-target="#systems" aria-expanded="false" aria-controls="systems">
                  <h3>{{'SUCCESS_STORIES_RESULTS.DATA_SECTION' | translate}}</h3>&nbsp;
                </button>
              </h2>
            </div>

            <div id="systems" class="collapse" aria-labelledby="systemData">
                      <nav ngbNav #nav3="ngbNav" class="nav-tabs">
                        <ng-container ngbNavItem class="me-2">
                          <h6 class="mb-0">
                            <a href="#epc" ngbNavLink>{{'SUCCESS_STORIES_RESULTS.EPC_DATA' | translate}}</a>
                          </h6>
                          <ng-template ngbNavContent>
                            <div class="container epc-information">
                              <div class="row justify-content-center" style="margin: 0;">
                                <div class="col-md-5 epc-before">
                                  <h5>
                                    {{'SUCCESS_STORIES_RESULTS.INITIAL_ENERGY_PERFORMANCE' | translate}} - {{ceeBuilding.year_certificate}}
                                  </h5>
                                  <div class="row justify-content-center">
                                    <div class="col-md-4">
                                      {{'SUCCESS_STORIES_RESULTS.RATING_SCALE' | translate}}
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      {{'SUCCESS_STORIES_RESULTS.ENERGY_CONSUMPTION_SCALE' | translate}}
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      {{'SUCCESS_STORIES_RESULTS.EMISSIONS_SCALE' | translate}}
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #0c964d; border-radius: 15px; color: white; width: 35%;">
                                        <span style="margin-left: 10px;">A <!--more efficient--></span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP === 'A'">{{ceeBuilding.value_ep}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2 === 'A'">{{ceeBuilding.value_co2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #21b14a; border-radius: 15px; color: white; width: 45%;">
                                        <span style="margin-left: 10px;">B</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP === 'B'">{{ceeBuilding.value_ep}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2 === 'B'">{{ceeBuilding.value_co2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #99ca39; border-radius: 15px; color: white; width: 55%;">
                                        <span style="margin-left: 10px;">C</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP === 'C'">{{ceeBuilding.value_ep}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2 === 'C'">{{ceeBuilding.value_co2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #ebe724; border-radius: 15px; color: white; width: 65%;">
                                        <span style="margin-left: 10px;">D</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP === 'D'">{{ceeBuilding.value_ep}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2 === 'D'">{{ceeBuilding.value_co2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #f0b619; border-radius: 15px; color: white; width: 75%;">
                                        <span style="margin-left: 10px;">E</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP === 'E'">{{ceeBuilding.value_ep}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2 === 'E'">{{ceeBuilding.value_co2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #e17726; border-radius: 15px; color: white; width: 85%;">
                                        <span style="margin-left: 10px;">F</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP === 'F'">{{ceeBuilding.value_ep}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2 === 'F'">{{ceeBuilding.value_co2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #e52d29; border-radius: 15px; color: white;">
                                        <span style="margin-left: 10px;">G {{'SUCCESS_STORIES_RESULTS.LESS_EFFICIENT' | translate}}</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP === 'G'">{{ceeBuilding.value_ep}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2 === 'G'">{{ceeBuilding.value_co2}}</span>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-5 epc-after">
                                  <h5>
                                    {{'SUCCESS_STORIES_RESULTS.FINAL_ENERGY_PERFORMACE' | translate}} - {{ceeBuilding.year_certificate2}}
                                  </h5>
                                  <div class="row justify-content-center">
                                    <div class="col-md-4">
                                      {{'SUCCESS_STORIES_RESULTS.RATING_SCALE' | translate}}
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      {{'SUCCESS_STORIES_RESULTS.ENERGY_CONSUMPTION_SCALE' | translate}}
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      {{'SUCCESS_STORIES_RESULTS.EMISSIONS_SCALE' | translate}}
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #0c964d; border-radius: 15px; color: white; width: 35%;">
                                        <span style="margin-left: 10px;">A <!--more efficient--></span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP_cert2 === 'A'">{{ceeBuilding.value_ep_cert2}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2_cert2 === 'A'">{{ceeBuilding.value_co2_cert2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #21b14a; border-radius: 15px; color: white; width: 45%;">
                                        <span style="margin-left: 10px;">B</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP_cert2 === 'B'">{{ceeBuilding.value_ep_cert2}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2_cert2 === 'B'">{{ceeBuilding.value_co2_cert2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #99ca39; border-radius: 15px; color: white; width: 55%;">
                                        <span style="margin-left: 10px;">C</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP_cert2 === 'C'">{{ceeBuilding.value_ep_cert2}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2_cert2 === 'C'">{{ceeBuilding.value_co2_cert2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #ebe724; border-radius: 15px; color: white; width: 65%;">
                                        <span style="margin-left: 10px;">D</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP_cert2 === 'D'">{{ceeBuilding.value_ep_cert2}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2_cert2 === 'D'">{{ceeBuilding.value_co2_cert2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #f0b619; border-radius: 15px; color: white; width: 75%;">
                                        <span style="margin-left: 10px;">E</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP_cert2 === 'E'">{{ceeBuilding.value_ep_cert2}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2_cert2 === 'E'">{{ceeBuilding.value_co2_cert2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #e17726; border-radius: 15px; color: white; width: 85%;">
                                        <span style="margin-left: 10px;">F</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP_cert2 === 'F'">{{ceeBuilding.value_ep_cert2}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2_cert2 === 'F'">{{ceeBuilding.value_co2_cert2}}</span>
                                    </div>
                                  </div>
                                  <br>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div style="background-color: #e52d29; border-radius: 15px; color: white;">
                                        <span style="margin-left: 10px;">G {{'SUCCESS_STORIES_RESULTS.LESS_EFFICIENT' | translate}}</span>
                                      </div>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterEP_cert2 === 'G'">{{ceeBuilding.value_ep_cert2}}</span>
                                    </div>
                                    <div class="col-md-4" style="text-align: center;">
                                      <span *ngIf="letterCO2_cert2 === 'G'">{{ceeBuilding.value_co2_cert2}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row justify-content-center">
                                <div class="col-md-11 savings-epc-co2">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <span style="font-weight: bold;">{{'SUCCESS_STORIES_RESULTS.SAVING_NON_RENEWABLE_EP' | translate}}</span>
                                      <br>
                                      <div class="row justify-content-center">
                                        <div class="col-md-4 rounded-information" style="margin-right: 10px; font-weight: bold;">
                                          {{ceeBuilding.saving_ep_abs}} kWh/m<sup>2</sup> year
                                        </div>
                                        <div class="col-md-4 rounded-information" style="font-weight: bold;">
                                          {{percentSavingEp.toFixed(2)}}%
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <span style="font-weight: bold;">{{'SUCCESS_STORIES_RESULTS.SAVING_EMISSIONS' | translate}}</span>
                                      <br>
                                      <div class="row justify-content-center">
                                        <div class="col-md-4 rounded-information" style="margin-right: 10px; font-weight: bold;">
                                          {{ceeBuilding.saving_co2_abs}} kg CO<sub>2</sub>/m<sup>2</sup> year
                                        </div>
                                        <div class="col-md-4 rounded-information" style="font-weight: bold;">
                                          {{ceeBuilding.saving_co2_percent}}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                          <ng-container ngbNavItem class="me-2">
                            <h6 class="mb-0">
                              <a href="#ieq" ngbNavLink *ngIf="multi.length !== 0 || multiTemperaturePreExterior.length != 0 || multiTemperature.length != 0 || multiTemperatureExterior.length != 0 || multiHumidity.length !== 0 || multiHumidityPreExterior.length != 0 || multiHumidityPost.length != 0 || multiHumidityPostExterior.length != 0">{{'SUCCESS_STORIES_RESULTS.IEQ_DATA' | translate}}</a>
                            </h6>
                            <ng-template ngbNavContent>
                              <div class="container ieq-information">
                                <div class="row justify-content-center" style="margin: 0;">
                                  <div class="col-md-6" *ngIf="multiHumidity.length != 0 || multiHumidityPreExterior.length != 0">
                                    <h5 style="text-align: center;">
                                      {{ showExteriorHRPre ? ('SUCCESS_STORIES_RESULTS.RELATIVE_HUMIDITY_PRE_EXTERIOR' | translate) : ('SUCCESS_STORIES_RESULTS.RELATIVE_HUMIDITY_PRE_INTERIOR' | translate)}}
                                    </h5>
                                    <br>
                                    <div *ngIf="showExteriorHRPre ? multiHumidityPreExterior.length == 0 : multiHumidity.length == 0">
                                      There is no data for relative humidity pre renovation.
                                    </div>
                                    <div class="row justify-content-center" *ngIf="showExteriorHRPre ? multiHumidityPreExterior.length != 0 : multiHumidity.length != 0">
                                      <div class="col">
                                        <ngx-charts-line-chart
                                          [view]="[500, 350]"
                                          [scheme]="colorScheme"
                                          [results]="showExteriorHRPre ? multiHumidityPreExterior : multiHumidity"
                                          [xAxis]="true"
                                          [yAxis]="true"
                                          [yScaleMin]="0"
                                          [yScaleMax]="100"
                                          [curve]="curve"
                                          [gradient]="false"
                                          [legend]="false"
                                          [showXAxisLabel]="true"
                                          [xAxisLabel]="xAxisLabel"
                                          [showYAxisLabel]="true"
                                          [yAxisLabel]="yAxisLabel"
                                          [referenceLines]="referenceLineHumidity"
                                          [showRefLabels]="true"
                                          [showRefLines]="true"
                                        >
                                        </ngx-charts-line-chart>
                                      </div>
                                    </div>
                                    <div class="row justify-content-center mt-3">
                                      <button class="btn btn-primary button-change" (click)="toggleViewHRPre()">
                                        {{ showExteriorHRPre ? 'Show Interior' : 'Show Exterior' }}
                                      </button>
                                    </div>
                                  </div>

                                  <div class="col-md-6" *ngIf="multiHumidityPost.length != 0 || multiHumidityPostExterior.length != 0">
                                    <h5 style="text-align: center;">
                                      {{ showExterior ? ('SUCCESS_STORIES_RESULTS.RELATIVE_HUMIDITY_POST_EXTERIOR' | translate) : ('SUCCESS_STORIES_RESULTS.RELATIVE_HUMIDITY_POST_INTERIOR' | translate )}}
                                    </h5>
                                    <br>
                                    <div *ngIf="showExterior ? multiHumidityPostExterior.length == 0 : multiHumidityPost.length == 0">
                                      There is no data for relative humidity post renovation.
                                    </div>
                                    <div class="row justify-content-center" *ngIf="showExterior ? multiHumidityPostExterior.length != 0 : multiHumidityPost.length != 0">
                                      <div class="col">
                                        <ngx-charts-line-chart
                                          [view]="[500, 350]"
                                          [scheme]="colorScheme"
                                          [results]="showExterior ? multiHumidityPostExterior : multiHumidityPost"
                                          [xAxis]="true"
                                          [yAxis]="true"
                                          [yScaleMin]="0"
                                          [yScaleMax]="100"
                                          [curve]="curve"
                                          [gradient]="false"
                                          [legend]="false"
                                          [showXAxisLabel]="true"
                                          [xAxisLabel]="xAxisLabel"
                                          [showYAxisLabel]="true"
                                          [yAxisLabel]="yAxisLabel"
                                          [referenceLines]="referenceLineHumidity"
                                          [showRefLabels]="true"
                                          [showRefLines]="true"
                                        >
                                        </ngx-charts-line-chart>
                                      </div>
                                    </div>
                                    <div class="row justify-content-center mt-3">
                                      <button class="btn btn-primary button-change" (click)="toggleViewHRPost()">
                                        {{ showExterior ? 'Show Interior' : 'Show Exterior' }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <br>
                                <div class="row justify-content-center" style="margin: 0;">
                                  <div class="col-md-6" *ngIf="multi.length != 0 || multiTemperaturePreExterior.length != 0">
                                    <h5 style="text-align: center;">
                                      {{ showExteriorTemperaturePre ? ('SUCCESS_STORIES_RESULTS.TEMPERATURE_PRE_EXTERIOR' | translate) : ('SUCCESS_STORIES_RESULTS.TEMPERATURE_PRE_INTERIOR' | translate) }}
                                    </h5>
                                    <br>
                                    <div *ngIf="showExteriorTemperaturePre ? multiTemperaturePreExterior.length == 0 : multi.length == 0">
                                      There is no data for temperature pre renovation.
                                    </div>
                                    <div class="row justify-content-center" *ngIf="showExteriorTemperaturePre ? multiTemperaturePreExterior.length != 0 : multi.length != 0">
                                      <div class="col">
                                        <ngx-charts-line-chart
                                          class="custom-line-chart"
                                          [view]="[500, 350]"
                                          [scheme]="colorSchemeTemperature"
                                          [results]="showExteriorTemperaturePre ? multiTemperaturePreExterior : multi"
                                          [xAxis]="showXAxisTemp"
                                          [yAxis]="showYAxisTemp"
                                          [legend]="showLegendTemp"
                                          [showXAxisLabel]="showXAxisLabelTemp"
                                          [showYAxisLabel]="showYAxisLabelTemp"
                                          [xAxisLabel]="xAxisLabelTemp"
                                          [yAxisLabel]="yAxisLabelTemp"
                                          [yScaleMin]="1"
                                          [yScaleMax]="25"
                                          [curve]="curve"
                                          [referenceLines]="referenceLineTemperature"
                                          [showRefLabels]="true"
                                          [showRefLines]="true"
                                        >
                                        </ngx-charts-line-chart>
                                      </div>
                                    </div>
                                    <div class="row justify-content-center mt-3">
                                      <button class="btn btn-primary button-change" (click)="toggleViewTemperaturePre()">
                                        {{ showExteriorTemperaturePre ? 'Show Interior' : 'Show Exterior' }}
                                      </button>
                                    </div>
                                  </div>

                                  <div class="col-md-6" *ngIf="multiTemperature.length != 0 || multiTemperatureExterior.length != 0">
                                    <h5 style="text-align: center;">
                                      {{ showExteriorTemperaturePost ? ('SUCCESS_STORIES_RESULTS.TEMPERATURE_POST_EXTERIOR' | translate) : ('SUCCESS_STORIES_RESULTS.TEMPERATURE_POST_INTERIOR' | translate) }}
                                    </h5>
                                    <br>
                                    <div *ngIf="showExteriorTemperaturePost ? multiTemperatureExterior.length == 0 : multiTemperature.length == 0">
                                      There is no data for temperature post renovation.
                                    </div>
                                    <div class="row justify-content-center" *ngIf="showExteriorTemperaturePost ? multiTemperatureExterior.length != 0 : multiTemperature.length != 0">
                                      <div class="col">
                                        <ngx-charts-line-chart
                                          class="custom-line-chart"
                                          [view]="[500, 350]"
                                          [scheme]="colorSchemeTemperature"
                                          [results]="showExteriorTemperaturePost ? multiTemperatureExterior : multiTemperature"
                                          [xAxis]="showXAxisTemp"
                                          [yAxis]="showYAxisTemp"
                                          [legend]="showLegendTemp"
                                          [showXAxisLabel]="showXAxisLabelTemp"
                                          [showYAxisLabel]="showYAxisLabelTemp"
                                          [xAxisLabel]="xAxisLabelTemp"
                                          [yAxisLabel]="yAxisLabelTemp"
                                          [yScaleMin]="1"
                                          [yScaleMax]="25"
                                          [curve]="curve"
                                          [referenceLines]="referenceLineTemperature"
                                          [showRefLabels]="true"
                                          [showRefLines]="true"
                                        >
                                        </ngx-charts-line-chart>
                                      </div>
                                    </div>
                                    <div class="row justify-content-center mt-3">
                                      <button class="btn btn-primary button-change" (click)="toggleViewTemperaturePost()">
                                        {{ showExteriorTemperaturePost ? 'Show Interior' : 'Show Exterior' }}
                                      </button>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </ng-template>
                          </ng-container>
                        <ng-container ngbNavItem class="me-2">
                          <h6 class="mb-0">
                            <a href="#energy" ngbNavLink *ngIf="multiElectricity.length !== 0 || multiSelfProduction.length !== 0 || multiElectricityPost.length !== 0">{{'SUCCESS_STORIES_RESULTS.ENERGY_DATA' | translate}}</a>
                          </h6>
                          <ng-template ngbNavContent>
                            <div class="container ieq-information">
                              <div class="row justify-content-center" style="margin: 0;">
                                <div class="col-md-6" *ngIf="multiElectricity.length != 0">
                                  <h5 style="text-align: center;">
                                    {{'SUCCESS_STORIES_RESULTS.ELECTRICITY_PRE' | translate}}
                                  </h5>
                                  <br>
                                  <div *ngIf="multiElectricity.length == 0">
                                    There is no data for electricity pre renovation.
                                  </div>
                                  <div class="row justify-content-center" *ngIf="multiElectricity.length != 0">
                                    <div class="col">
                                      <ngx-charts-bar-vertical
                                        [view]="[500, 350]"
                                        [scheme]="colorSchemeElectricity"
                                        [results]="barDataElectricity"
                                        [xAxis]="showXAxisTemperature"
                                        [yAxis]="showYAxisTemperature"
                                        [legend]="false"
                                        [showXAxisLabel]="showXAxisLabelTemperature"
                                        [showYAxisLabel]="showYAxisLabelTemperature"
                                        [xAxisLabel]="xAxisLabelElectricity"
                                        [yAxisLabel]="yAxisLabelElectricity"
                                      ></ngx-charts-bar-vertical>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-5" *ngIf="multiElectricityPost.length != 0">
                                  <h5 style="text-align: center;">
                                    {{'SUCCESS_STORIES_RESULTS.ELECTRICITY_POST' | translate}}
                                  </h5>
                                  <br>
                                  <div *ngIf="multiElectricityPost.length == 0">
                                    There is no data for electricity post renovation.
                                  </div>
                                  <div class="row justify-content-center" *ngIf="multiElectricityPost.length != 0">
                                    <div class="col">
                                      <ngx-charts-bar-vertical
                                        [view]="[500, 350]"
                                        [scheme]="colorSchemeElectricity"
                                        [results]="barDataElectricityPost"
                                        [xAxis]="showXAxisTemperature"
                                        [yAxis]="showYAxisTemperature"
                                        [legend]="false"
                                        [showXAxisLabel]="showXAxisLabelTemperature"
                                        [showYAxisLabel]="showYAxisLabelTemperature"
                                        [xAxisLabel]="xAxisLabelElectricity"
                                        [yAxisLabel]="yAxisLabelElectricity"
                                      ></ngx-charts-bar-vertical>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row justify-content-center" style="margin: 0;">
                                <div class="col-md-6" *ngIf="multiSelfProduction.length != 0">
                                  <h5 style="text-align: center;">
                                    {{'SUCCESS_STORIES_RESULTS.PHOTOVOLTAIC_PANELS_ENERGY_PRE' | translate}}
                                  </h5>
                                  <br>
                                  <div *ngIf="multiSelfProduction.length == 0">
                                    There is no data for pv production.
                                  </div>
                                  <div class="row justify-content-center" *ngIf="multiSelfProduction.length != 0">
                                    <div class="col">
                                      <ngx-charts-bar-vertical
                                        [view]="[500, 350]"
                                        [scheme]="colorSchemeSelfProduction"
                                        [results]="barDataSelfProduction"
                                        [xAxis]="showXAxisTemperature"
                                        [yAxis]="showYAxisTemperature"
                                        [legend]="false"
                                        [showXAxisLabel]="showXAxisLabelTemperature"
                                        [showYAxisLabel]="showYAxisLabelTemperature"
                                        [xAxisLabel]="xAxisLabelElectricity"
                                        [yAxisLabel]="yAxisLabelElectricity"
                                      ></ngx-charts-bar-vertical>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-5" *ngIf="multiSelfProductionPost.length != 0">
                                  <h5 style="text-align: center;">
                                    {{'SUCCESS_STORIES_RESULTS.PHOTOVOLTAIC_PANELS_ENERGY_POST' | translate}}
                                  </h5>
                                  <br>
                                  <div *ngIf="multiSelfProduction.length == 0">
                                    There is no data for pv production.
                                  </div>
                                  <div class="row justify-content-center" *ngIf="multiSelfProductionPost.length != 0">
                                    <div class="col">
                                      <ngx-charts-bar-vertical
                                        [view]="[500, 350]"
                                        [scheme]="colorSchemeSelfProduction"
                                        [results]="barDataSelfProductionPost"
                                        [xAxis]="showXAxisTemperature"
                                        [yAxis]="showYAxisTemperature"
                                        [legend]="false"
                                        [showXAxisLabel]="showXAxisLabelTemperature"
                                        [showYAxisLabel]="showYAxisLabelTemperature"
                                        [xAxisLabel]="xAxisLabelElectricity"
                                        [yAxisLabel]="yAxisLabelElectricity"
                                      ></ngx-charts-bar-vertical>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!--<div class="row justify-content-center" style="margin: 0;">
                                <div class="col-md-6" *ngIf="multiFossilFuels.length != 0">
                                  <h5 style="text-align: center;">
                                    FOSSIL FUELS
                                  </h5>
                                  <br>
                                  <div *ngIf="multiFossilFuels.length == 0">
                                    There is no data for fossil fuels.
                                  </div>
                                  <div class="row justify-content-center" *ngIf="multiFossilFuels.length != 0">
                                    <div class="col">
                                      <ngx-charts-bar-vertical
                                        [view]="[500, 350]"
                                        [scheme]="colorSchemeGas"
                                        [results]="barDataFossilFuels"
                                        [xAxis]="showXAxisTemperature"
                                        [yAxis]="showYAxisTemperature"
                                        [legend]="false"
                                        [showXAxisLabel]="showXAxisLabelTemperature"
                                        [showYAxisLabel]="showYAxisLabelTemperature"
                                        [xAxisLabel]="xAxisLabelGas"
                                        [yAxisLabel]="yAxisLabelGas"
                                      ></ngx-charts-bar-vertical>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-5" *ngIf="multiOthers.length != 0">
                                  <h5 style="text-align: center;">
                                    OTHERS
                                  </h5>
                                  <br>
                                  <div *ngIf="multiOthers.length == 0">
                                    There is no data for others consumption.
                                  </div>
                                  <div class="row justify-content-center" *ngIf="multiOthers.length != 0">
                                    <div class="col">
                                      <ngx-charts-bar-vertical
                                        [view]="[500, 350]"
                                        [scheme]="colorSchemeOthers"
                                        [results]="barDataOthers"
                                        [xAxis]="showXAxisTemperature"
                                        [yAxis]="showYAxisTemperature"
                                        [legend]="false"
                                        [showXAxisLabel]="showXAxisLabelTemperature"
                                        [showYAxisLabel]="showYAxisLabelTemperature"
                                        [xAxisLabel]="xAxisLabelGas"
                                        [yAxisLabel]="yAxisLabelGas"
                                      ></ngx-charts-bar-vertical>
                                    </div>
                                  </div>
                                </div>
                              </div>-->
                            </div>
                          </ng-template>
                        </ng-container>
                        <ng-container ngbNavItem class="me-2">
                          <h6 class="mb-0">
                            <a href="#comfort" ngbNavLink>{{'SUCCESS_STORIES_RESULTS.COMFORT_DATA' | translate}}</a>
                          </h6>
                          <ng-template ngbNavContent>
                            <div class="container ieq-information">
                              <div class="row justify-content-center" style="margin: 0;">
                                <div class="col-md-4">
                                  <h5 style="text-align: center; font-weight: bold; color: black;">
                                    {{'SUCCESS_STORIES_RESULTS.SYMPTOMS_COMFORT' | translate}}
                                    <!--<span>
                                      <svg width="100" height="100">
                                        <circle cx="50" cy="50" r="20" fill="transparent" [ngClass]="imagenClass"/>
                                      </svg>
                                    </span>-->
                                    <span [ngClass]="imagenClass" style="font-size: 3em;">{{ imagenClass }}</span>
                                  </h5>
                                  <br>
                                  <br>
                                  <div class="justify-content-center">
                                    <div class="columnSymptoms">
                                      <div *ngFor="let option of optionsSymptoms.slice(0, 3)">
                                        <label>
                                          <i [ngClass]="option.checked ? 'fa fa-heart' : 'fa fa-heartbeat'" [ngStyle]="{'color': option.checked ? 'red' : 'green'}"></i>
                                          <input type="checkbox" [(ngModel)]="option.checked" onclick="return false;" (change)="updateImageClass()" style="visibility: hidden;">
                                          {{ option.name }}
                                        </label>
                                      </div>
                                    </div>

                                    <div class="columnSymptoms">
                                      <div *ngFor="let option of optionsSymptoms.slice(3, 6)">
                                        <label>
                                          <i [ngClass]="option.checked ? 'fa fa-heart' : 'fa fa-heartbeat'" [ngStyle]="{'color': option.checked ? 'red' : 'green'}"></i>
                                          <input type="checkbox" [(ngModel)]="option.checked" onclick="return false;" (change)="updateImageClass()" style="visibility: hidden;">
                                          {{ option.name }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div class="col-md-4">
                                  <h5 style="text-align: center; font-weight: bold; color: black;">
                                    {{'SUCCESS_STORIES_RESULTS.STRESSORS_COMFORT' | translate}}
                                    <!--<span>
                                      <svg width="100" height="100">
                                        <circle cx="50" cy="50" r="20" fill="transparent" [ngClass]="imagenClassStressors"/>
                                      </svg>
                                    </span>-->
                                    <span [ngClass]="imagenClassStressors" style="font-size: 3em;">{{ imagenClassStressors }}</span>
                                  </h5>
                                  <br>
                                  <br>
                                  <div class="justify-content-center">
                                    <div class="column">
                                      <div *ngFor="let option of optionsStressors.slice(0, 3)">
                                        <label>
                                          <i [ngClass]="option.checked ? 'fa fa-thumbs-down' : 'fa fa-thumbs-up'" [ngStyle]="{'color': option.checked ? 'red' : 'green'}"></i>
                                          <input type="checkbox" [(ngModel)]="option.checked" onclick="return false;" style="visibility: hidden;">
                                          {{ option.name }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="column">
                                      <div *ngFor="let option of optionsStressors.slice(3, 6)">
                                        <label>
                                          <i [ngClass]="option.checked ? 'fa fa-thumbs-down' : 'fa fa-thumbs-up'" [ngStyle]="{'color': option.checked ? 'red' : 'green'}"></i>
                                          <input type="checkbox" [(ngModel)]="option.checked" onclick="return false;" style="visibility: hidden;">
                                          {{ option.name }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="column">
                                      <div *ngFor="let option of optionsStressors.slice(6, 9)">
                                        <label>
                                          <i [ngClass]="option.checked ? 'fa fa-thumbs-down' : 'fa fa-thumbs-up'" [ngStyle]="{'color': option.checked ? 'red' : 'green'}"></i>
                                          <input type="checkbox" [(ngModel)]="option.checked" onclick="return false;" style="visibility: hidden;">
                                          {{ option.name }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <h5 style="text-align: center; font-weight: bold; color: black;">
                                    {{'SUCCESS_STORIES_RESULTS.THERMAL_COMFORT' | translate}}
                                    <!--<span>
                                      <svg width="100" height="100">
                                        <circle cx="50" cy="50" r="20" fill="transparent" [ngClass]="imagenClassThermalComfort" />
                                      </svg>
                                    </span>-->
                                    <span [ngClass]="imagenClassThermalComfort" style="font-size: 3em;">{{ imagenClassThermalComfort }}</span>
                                  </h5>
                                  <br>
                                  <br>
                                  <div class="justify-content-center">
                                    <div class="column">
                                      <div *ngFor="let option of optionsThermalComfort.slice(0, 3)" class="justify-content-center">
                                        <label>
                                          <i [ngClass]="option.checked ? 'fa fa-check-circle-o' : 'fa fa-times-circle-o'" [ngStyle]="{'color': option.checked ? 'green' : 'grey'}"></i>
                                          <input type="checkbox" [(ngModel)]="option.checked" onclick="return false;" style="visibility: hidden;">
                                          {{ option.name }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="column">
                                      <div *ngFor="let option of optionsThermalComfort.slice(3, 4)" class="justify-content-center">
                                        <label style="padding-top: 30%;">
                                          <i [ngClass]="option.checked ? 'fa fa-check-circle-o' : 'fa fa-times-circle-o'" [ngStyle]="{'color': option.checked ? 'green' : 'grey'}"></i>
                                          <input type="checkbox" [(ngModel)]="option.checked" onclick="return false;" style="visibility: hidden;">
                                          {{ option.name }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="column">
                                      <div *ngFor="let option of optionsThermalComfort.slice(4, 7)" class="justify-content-center">
                                        <label>
                                          <i [ngClass]="option.checked ? 'fa fa-check-circle-o' : 'fa fa-times-circle-o'" [ngStyle]="{'color': option.checked ? 'green' : 'grey'}"></i>
                                          <input type="checkbox" [(ngModel)]="option.checked" onclick="return false;" style="visibility: hidden;">
                                          {{ option.name }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                      </nav>
                      <div [ngbNavOutlet]="nav3" class="mt-2"></div>
              </div>
          </div>
      </div>
    </div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sugestion</h4>
    <button type="button" aria-label="Close" (click)="modal.dismiss('Cross click')">&#x2715;</button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <label for="comment">Comment</label>
        <div class="input-group">
          <textarea id="comment" name="comment" class="comments-box" cols="103" rows="5" [(ngModel)]="adminComment"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

export const environment = {
  production: true,
  firebase : {
    apiKey: 'AIzaSyBigxaCwSMHXeYFCDUlNERoxuku05GRRps',
    authDomain: 'remodulees-eb272.firebaseapp.com',
    projectId: 'remodulees-eb272',
    storageBucket: 'remodulees-eb272.appspot.com',
    messagingSenderId: '389138375181',
    appId: '1:389138375181:web:dc62b876c7db36a68c2b44',
    measurementId: 'G-V9EY5Y4EJH'
  }
};

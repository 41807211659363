import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {User} from '../../shared/models/user';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from '../../core/authentication/user.service';
import { UserModalComponent } from 'src/app/components/user-modal/user-modal.component';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { ArticlesModalComponent } from 'src/app/components/articles-modal/articles-modal.component';
import { Article } from 'src/app/shared/models/article';
import { ArticlesService } from 'src/app/core/re-hub/articles.service';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ArticleRate } from 'src/app/shared/models/article_rate';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { UserAllData } from 'src/app/shared/models/userAllData';
import { FormProService } from 'src/app/core/formPro/form-pro.service';

@Component({
  selector: 'app-relab',
  templateUrl: './relab.component.html',
  styleUrls: ['./relab.component.scss']
})
export class RelabComponent implements OnInit {

  @ViewChild('myModal') myModal: ElementRef;

  imageUrl = GlobalConstants.backendURL;
  users: User[] = [];
  allUsers: User[] = [];
  tmpUser: User[] = [];
  isUserLogged: boolean = false;
  currentUser: User = new User();
  modalRef: BsModalRef;
  showLogin: boolean;
  searchTextSupplier: any;
  searchTextArticles: any;

  countrySelectd: any;
  categorySelectd: any;

  countryUserSelectd: any;
  profileSelectd: any;
  profile2Selectd: any;
  activitySelectd: any;

  articlecountry: number = null;
  articlecategory: number = null;
  moreMultimedia: boolean = false;
  valueVideoURL: string = '';

  articleName: string = '';
  articleAuthor: string = '';
  articleDate: Date = null;
  articleImage: any = null;
  mainImages: any[] = [];
  link: string = '';
  otherImagesArticle: any[] = [];

  isAllDocuments: boolean;
  articlesDocuments: any[] = [];

  lengthShortDescription: number = 0;
  lengthLongDescription: number = 0
  valueShortDescription: string = '';
  valueLongDescription: string = '';

  articles: Article[] = [];
  tmpArticles: Article[] = []

  newArticles: Article[] = [];

  filterApplied: string[];

  activityFormProFilter = [];
  activityFormProFilterAutomatically = [];

  filterAppliedUsers: string[];

  guid: string;
  roleUser: number = null;

  isRate: boolean;
  rateObjects: any[];
  ratingArticle: any;
  noMatch: boolean;

  articleRating: number;

  userLogged: any;

  //URLs to share the articles
  facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=";
  twitterUrl = "https://twitter.com/intent/tweet?url=";

  constructor(private afAuth: AngularFireAuth, private userService: UserService, private modalService: BsModalService, private administrationPanel: AdministrationPanelService, private articleService: ArticlesService, private route: ActivatedRoute, private modalService2: NgbModal, private formProService: FormProService) {
  }

  ngOnInit(): void {
    //this.checkLogin();
    this.filterApplied = [];
    this.filterAppliedUsers = [];
    this.getUsers();
    this.getArticlesJorgeModel();
    this.getRatingArticles();
    this.getCategoriesArticles();
    this.getActivityFormPro();

    this.route.queryParams.subscribe(params => {
      const modalId = params['id'];
      if (modalId) {
        /*const modal = document.getElementById('modal-' + modalId);
        console.log(modal);
        this.modalService2.open(modal);*/
      }
    });

    this.administrationPanel.getGuid$().subscribe(data => {
      console.log('id del usuario logeado -> ', data)
      if(data == null){
        this.isUserLogged = false;
      }else{
        this.isUserLogged = true;
        this.guid = data;
      }
      console.log('usuario logeado -> ', this.isUserLogged)
    })
    
    this.userService.getByUid(this.guid).subscribe(userFromDB => {
      this.currentUser = new User(userFromDB);
      this.userLogged = userFromDB;
      if(userFromDB) {
        this.currentUser.country = userFromDB['data'].country_id;

        // Aplicar filtro automático según el país
        this.applyAutoFilter();
      }
      console.log(this.currentUser);
    })

    this.administrationPanel.getRoleCurrentUser$().subscribe(data => {
      console.log('rol del usuario logeado -> ', data)
      this.roleUser = data;
    })
  }

  private openModalViaUrl = false;

  setOpenModalViaUrl(value: boolean) {
    this.openModalViaUrl = value;
  }

  getOpenModalViaUrl(): boolean {
    return this.openModalViaUrl;
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        //this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          this.userLogged = userFromDB;
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;

            // Aplicar filtro automático según el país
            this.applyAutoFilter();
          }
          console.log(this.currentUser)
        });
      } else {
        //this.isUserLogged = false;
      }
    });
  }

  getActivityFormPro(){
    this.formProService.getActivityFormPro().toPromise().then((dataDb: NodeResponse) => {
      this.activityFormProFilter = dataDb.data;
    })
  }

  applyAutoFilter(): void {
    // Supongamos que tienes un mapeo entre los países y los valores del filtro
    const countryFilterMapping = {
      3: 'Bulgary',
      10: 'France',
      12: 'Greece',
      15: 'Italy',
      20: 'Netherlands',
      25: 'Slovenia',
      26: 'Spain', // Supongamos que el ID de España es 1
      // Agrega más mapeos según sea necesario
    };

    // Obtener el valor del filtro según el país del usuario
    const autoFilterValue = countryFilterMapping[this.currentUser.country];

    // Aplicar el filtro automáticamente
    if (autoFilterValue) {
      this.countrySelectd = autoFilterValue;

      // Aquí puedes llamar a tu lógica de filtrado manual si es necesario
      this.filter('countries');
    }

    // Aplicar el filtro automáticamente a countryUserSelectd
    const autoFilterUserValue = this.currentUser.country; // Puedes ajustar según tus necesidades
    if (autoFilterUserValue) {
      this.countryUserSelectd = autoFilterUserValue;
      this.filterUsers('countriesUsers');
    }
  }

  getArticlesJorgeModel(){
    this.articleService.getValidateArticles().toPromise().then((dataDb: NodeResponse) => {

      console.log('data tool DB -> ', dataDb)
      this.articles = dataDb.data;
      this.tmpArticles = this.articles;
      console.log(this.tmpArticles)
    })
  }

  getCategoriesArticles(){
    this.articleService.getCategoryArticle().toPromise().then((dataDb: NodeResponse) => {
      this.categories = dataDb.data;
    })
  }

  getUsers(){
    this.userService.getAllDataUsersProfessionals(sessionStorage.getItem('auth-token')).subscribe( (user: NodeResponse) => {
      this.users = user.data
      this.allUsers = this.users;
      console.log("Usuarios todos -> ", user.data)
      //This line is for filter the users in order to get the professional users
      //this.tmpUser = this.users.filter(user => user.user_roles.some(role => role.role.id === 3));
      this.tmpUser = this.users;
      console.log("Usuarios solo profesionales -> ", this.tmpUser)
    });
  }

  buildUsersInformation(data) {
    data.forEach( user => {
      const info = new User(user);
      this.users.push(info);
    });
  }

  sortUsers(){
    this.tmpUser.sort((a, b) => b.user.user.name.localeCompare(a.user.user.name));
  }



  openModal(user: UserAllData) {
    this.modalRef = this.modalService.show(UserModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { user}});
    this.modalRef.content.closeBtnName = 'Close';
  }

  openModalArticles(article: Article) {
    //this.incrementVisitCount(article.id);
    this.modalRef = this.modalService.show(ArticlesModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: { article}});
    this.modalRef.content.closeBtnName = 'Close';
  }

  /*incrementVisitCount(articleId: number) {
    const article = this.articles.find(a => a.id === articleId);
    if (article) {
      article.visitCount++;
      localStorage.setItem(`visitCount-${articleId}`, article.visitCount.toString());
    }
  }*/

  generateArticleUrl(articleId: string, articleName: string): string {
    //http://localhost:4200/#/relab?id=30&modal=First%20article

    const url = encodeURIComponent(window.location.origin + '/#/relab?id=' + articleId + '&modal=myModal');
    console.log(url)
    return url;
  }

  shareArticles() {
    let url = encodeURIComponent(window.location.href);
    window.open(this.facebookUrl + url, "_blank");
    window.open(this.twitterUrl + url, "_blank");
  }

  shareFacebook(articleId: string, articleName: string) {
    let url = this.generateArticleUrl(articleId, articleName);
    console.log(url)
    let shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + url;
    let width = 600;
    let height = 400;
    let left = (window.innerWidth - width) / 2;
    let top = (window.innerHeight - height) / 2;
    window.open(shareUrl, "Share on Facebook", "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top);

  // Add an event listener for the 'unload' event on the popup window
  //shareWindow.addEventListener("unload", function() {
    // Close the popup window when the user navigates away from the page
    //shareWindow.close();
  //});
  }

  shareTwitter(articleId: string, articleName: string) {
    let url = this.generateArticleUrl(articleId, articleName);
    let shareUrl = "https://twitter.com/intent/tweet?url=" + url;
    let width = 600;
    let height = 400;
    let left = (window.innerWidth - width) / 2;
    let top = (window.innerHeight - height) / 2;
    window.open(shareUrl, "Share on Facebook", "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top);

    // Add an event listener for the 'unload' event on the popup window
   // shareWindow.addEventListener("unload", function() {
      // Close the popup window when the user navigates away from the page
      //shareWindow.close();
    //});
  }

  shareLinkedIn(articleId: string, articleName: string) {
    let url = this.generateArticleUrl(articleId, articleName);
    //let url = encodeURIComponent(window.location.href);
    let shareUrl = "https://www.linkedin.com/sharing/share-offsite/?url=" + url;
    let width = 600;
    let height = 400;
    let left = (window.innerWidth - width) / 2;
    let top = (window.innerHeight - height) / 2;
    window.open(shareUrl, "Share on Linkedin", "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top)
  }

  fileImageChangeEvent(event: any){

    this.articleImage = null;

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.articleImage = event.target.files[i];
      }else{
        alert('You must to select a IMAGE');
        this.articleImage = null;
      }
    }

  }

  fileOtherImageChangeEvent(event: any){

    let isAllImages = true;
    this.otherImagesArticle = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.otherImagesArticle.push(event.target.files[i]);
        //this.isAdditionalImagesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es una imagen');
        isAllImages = false;
        break;
      }
    }

    if(isAllImages == false){
      this.otherImagesArticle = [];
      alert('You must to select IMAGES');
    }
  }

  fileChangeEvent(event: any){

    let isAllDocuments = true;
    this.articlesDocuments = [];

    for(let i = 0; i < event.target.files.length; i++){
      console.log( 'Archivo seleccionado n' + i + '-> ', event.target.files[i]);

      if(event.target.files[i].type.substring(5) == 'pdf' || event.target.files[i].type == 'application/pdf'){
        this.articlesDocuments.push(event.target.files[i]);
        //this.isPdfFilesChange = true;
        if(i == 4) break;
      }else{
        console.log('esto no es un archivo PDF -> ');
        isAllDocuments = false;
        break;
      }
    }

    if(isAllDocuments == false){
      this.articlesDocuments = [];
      alert('You must to select PDF FILES');
    }
  }

  createArticle(){

    //console.log(this.guid);
    if(this.isFormFilled()){
      this.articleService.createArticle(
        this.articleName, this.articlecountry, this.articlecategory, this.articleDate + '', this.articleAuthor, this.valueShortDescription,
        this.valueLongDescription, this.articleImage, 3, this.guid, this.valueVideoURL, this.link
      ).toPromise().then(async (dataArticle: NodeResponse) => {
        console.log('response data base article -> ', dataArticle);

        if(dataArticle.status == true){

          if(this.moreMultimedia == true){

            for(let i = 0; i < this.otherImagesArticle.length; i++){
              let imagesAdditional = new FormData();

              imagesAdditional.append('article_id', dataArticle.data.id);
              imagesAdditional.append('image', this.otherImagesArticle[i], this.otherImagesArticle[i].name);

              await this.articleService.uploadImage(imagesAdditional).toPromise().then((dataImage: NodeResponse) => {
                console.log("response node imagesAdditional -> ", dataImage);
              })

              if(i == 4) break;
            }

            for(let i = 0; i < this.articlesDocuments.length; i++){
              let bodyFile = new FormData();

              bodyFile.append('file', this.articlesDocuments[i], this.articlesDocuments[i].name);
              bodyFile.append('article_id', dataArticle.data.id);

              await this.articleService.uploadDocuments(bodyFile).toPromise().then((dataPDF) => {
                console.log("dataTool node file -> ", dataPDF);
              })

              if(i == 2) break;
            }
            alert(dataArticle.message)
            this.clearForm();
          }else{
            alert(dataArticle.message);
            this.clearForm();
          }
        }else{
          alert(dataArticle.error)
        }
      });
    }else{
      alert('You need to fill whole form')
    }

  }

  filter(type: string): void {
    if ( ( type === 'countries' && this.countrySelectd !== null ) ||
      ( type === 'category' && this.categorySelectd !== null )){
      console.log(this.filterApplied)
      const indexFilterApplied = this.filterApplied.indexOf(type);
      console.log(indexFilterApplied)
      if (indexFilterApplied < 0) {
        this.filterApplied.push(type);
      }
    } else {
      this.cleanFilter(type);
    }
    this.applyFilter();
  }

  applyFilterActivityForm() {
    this.activityFormProFilterAutomatically = [];

    if(this.profile2Selectd == 1) this.activityFormProFilterAutomatically.push(this.activityFormProFilter[0], this.activityFormProFilter[1])
    if(this.profile2Selectd == 2) this.activityFormProFilterAutomatically.push(this.activityFormProFilter[2], this.activityFormProFilter[3], this.activityFormProFilter[4], this.activityFormProFilter[5], this.activityFormProFilter[6])
    if(this.profile2Selectd == 3) this.activityFormProFilterAutomatically.push(this.activityFormProFilter[8], this.activityFormProFilter[9], this.activityFormProFilter[10])
    if(this.profile2Selectd == 4) this.activityFormProFilterAutomatically.push(this.activityFormProFilter[7])

    console.log(this.activityFormProFilterAutomatically)
  }

  filterUsers(type: string): void {
    if ( (type === 'countriesUsers' && this.countryUserSelectd !== null) ||
      (type === 'profileUsers' && this.profile2Selectd !== null) ||
      (type === 'activityUsers' && this.activitySelectd !== null)){
      console.log(this.filterAppliedUsers)
      const indexFilterApplied = this.filterAppliedUsers.indexOf(type);
      console.log(indexFilterApplied)
      if (indexFilterApplied < 0) {
        this.filterAppliedUsers.push(type);
      }
    } else {
      this.cleanFilterUsers(type);
    }
    this.applyFilterUsers();
    this.applyFilterActivityForm();
  }

  cleanFilter(type: string) {
    const indexFilterApplied = this.filterApplied.indexOf(type);
    if (indexFilterApplied > -1) {
      this.filterApplied.splice(indexFilterApplied, 1);
    }
  }

  cleanFilterUsers(type: string) {
    const indexFilterApplied = this.filterAppliedUsers.indexOf(type);
    if (indexFilterApplied > -1) {
      this.filterAppliedUsers.splice(indexFilterApplied, 1);
    }
  }

  applyFilterUsers(): void {
    let usersTmp = [];

    this.users.forEach(user => {
      usersTmp.push(user);
    })
    this.filterAppliedUsers.forEach( filter => {
      if(filter === 'countriesUsers'){
        const filterByCountryUsers  = [];
        this.users.forEach(user => {
          const hasCountryUser = this.countryUserSelectd === user.country.id;
          const hasRole = user.user_roles.some(role => role.role.id === 3); // Aquí cambia el 3 por el ID del rol deseado
          if( hasCountryUser && hasRole ){
            filterByCountryUsers.push(user);
            console.log(filterByCountryUsers);
          }
        });
        usersTmp = this.removeElementsFromArrayUsers(usersTmp, filterByCountryUsers);
        console.log("Paises -> ", usersTmp)
      }

      if (filter === 'profileUsers') {
          const filterByProfileUsers = [];
          console.log("Selected Profile:", this.profile2Selectd);
      
          this.users.forEach(user => {
              console.log("Checking user:", user);
              // Verificación segura usando el operador de encadenamiento opcional
              const activityFormPro = user.user_extension?.activityFormPro;
              let profileId;
              const hasRole = user.user_roles.some(role => role.role.id === 3); // Aquí cambia el 3 por el ID del rol deseado

              if (activityFormPro && activityFormPro.profile && hasRole) {
                  profileId = activityFormPro.profile.id;
              } else {
                  profileId = undefined;
              }

              // Verificar si profileId es igual a profile2Selectd
              if (profileId == this.profile2Selectd && hasRole) {
                  filterByProfileUsers.push(user);
                  console.log("User matches profile2Selectd:", user);
              } else {
                  console.log("User does not match profile2Selectd:", user);
              }
      
              // Añadir un log adicional para verificar los usuarios filtrados
              console.log("Filtered Users so far:", filterByProfileUsers);
          });
      
          usersTmp = this.removeElementsFromArrayUsers(usersTmp, filterByProfileUsers);
          console.log("Final filtered users:", usersTmp);
      }

      if(filter === 'activityUsers'){
        const filterByActivityUsers = [];
        console.log(this.activitySelectd)
        this.users.forEach(user => {
          console.log("Checking user:", user);

          const activityFormPro = user.user_extension?.activityFormPro;
          let activityID;
          const hasRole = user.user_roles.some(role => role.role.id === 3); // Aquí cambia el 3 por el ID del rol deseado

          if (activityFormPro && activityFormPro.id && hasRole) {
            activityID = activityFormPro.id;
          } else {
            activityID = undefined;
          }
          
          // Verificar si profileId es igual a profile2Selectd
          if (activityID == this.activitySelectd && hasRole) {
            filterByActivityUsers.push(user);
            console.log("User matches activitySelectd:", user);
          } else {
            console.log("User does not match activitySelectd:", user);
          }
        });
        usersTmp = this.removeElementsFromArrayUsers(usersTmp, filterByActivityUsers);
        console.log("Activity -> ", usersTmp)
      }

    });

    this.tmpUser = usersTmp;
  }

  applyFilter(): void {
    let articlesTmp = [];

    this.articles.forEach( article => {
      articlesTmp.push(article);
    });

    this.filterApplied.forEach( filter => {
      if (filter === 'countries') {
        const filterByCountry  = [];
        this.articles.forEach( article => {
          const hasCountry = article.country.country.includes(this.countrySelectd);
          if( hasCountry ) {
            filterByCountry.push(article);
            console.log(filterByCountry)
          }
        });
        articlesTmp = this.removeElementsFromArray(articlesTmp, filterByCountry);
      }

      if (filter === 'category') {
        const filterByCategory  = [];
        this.articles.forEach( article => {
          const hasCategory = article.category_article.name.includes(this.categorySelectd);
          if( hasCategory ) {
            filterByCategory.push(article);
            console.log(filterByCategory);
          }
        });
        articlesTmp = this.removeElementsFromArray(articlesTmp, filterByCategory);
      }

    });

    this.tmpArticles = articlesTmp;
  }

  removeElementsFromArray(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }

  removeElementsFromArrayUsers(arrayInit, element) {
    const indexToRemove = [];
    arrayInit.forEach( filtered => {
      const index = element.indexOf(filtered, 0);
      if (index < 0 ) {
        indexToRemove.push(arrayInit.indexOf(filtered, 0));
      }
    });
    for (let i = indexToRemove.length - 1 ; i >= 0; i--){
      arrayInit.splice(indexToRemove[i], 1);
    }
    return arrayInit;
  }

  rateArticle(rating: number, article: Article){
    if(this.isUserLogged == true){
      let bodyData = new FormData();

      const rate = rating;
      const article_id = article.id;
      this.administrationPanel.getGuid$().subscribe((data: string) => this.guid = data);

      this.articleRating = rating;

      //const rateArticle = new ArticleRate(rate, article_id, this.guid);

      /*this.articleService.createArticleRate(rateArticle).subscribe(response => {
        console.log(response);
      }, error => {
        console.log(error);
      })*/

      console.log("The puntuation for this tool " + article.article_name + " is: " + rating);
    }else{
      alert("You need to login in the app to rate the articles.");
    }

  }

  getRatingArticles(){
    //this.administrationPanel.getGuid$().subscribe((data: string) => this.guid = data);
    this.articleService.getArticleRateInfo().subscribe((data: any) => {
      this.rateObjects = data;
      console.log(this.rateObjects);
      console.log(this.tmpArticles)

        for(let i = 0; i < this.tmpArticles.length; i++){
          for(let j = 0; j < this.rateObjects.length; j++){
            if(this.rateObjects[j].tool_id != this.tmpArticles[i].id){
              //console.log(this.rateObjects[i].rate)
              console.log("dentro")
              this.isRate = false;
            }else{
              console.log("fuera")
              this.isRate = true
            }
          }
        }
    });

  }

  isFormFilled(): boolean{

    let value = false;
    let value2 = false;

    if(
      this.articleName != '' &&
      this.articlecountry != null &&
      this.articlecategory != null &&
      this.articleDate != null &&
      this.articleAuthor != '' &&
      this.valueShortDescription != '' &&
      this.valueLongDescription != '' &&
      this.articleImage != null

    ){
      value = true;
    }

    if(this.moreMultimedia == true){
      if(this.otherImagesArticle.length > 0 || this.articlesDocuments.length > 0 || this.valueVideoURL != ''){
        value2 = true
      }
    }else{
      value2 = true
    }

    return (value && value2);
  }

  clearForm(){
    this.articleName = '';
    this.articlecountry = null;
    this.articlecategory = null;
    this.articleDate = null;
    this.articleAuthor = '';
    this.valueShortDescription = '';
    this.valueLongDescription = '';
    this.articleImage = null;

    this.moreMultimedia = false;

    this.valueVideoURL = '';
    this.otherImagesArticle = [];
    this.articlesDocuments = [];
  }

  saveArticles(articleID: any){
    console.log(this.currentUser.uid)
    this.articleService.savedArticles(this.currentUser.user.data.uid, articleID).subscribe(response => {
      console.log(response);
      alert("Article saved successfully");
    })
  }

  countries = [
    { id: 1, name: 'Austria', country_code: 'AU' },
    { id: 2, name: 'Belgium', country_code: 'BE' },
    { id: 3, name: 'Bulgaria', country_code: 'BG'  },
    { id: 4, name: 'Croatia', country_code: 'HR'  },
    { id: 5, name: 'Cyprus', country_code: 'CY'  },
    { id: 6, name: 'Czech Republic', country_code: 'CZ'  },
    { id: 7, name: 'Denmark', country_code: 'DK'  },
    { id: 8, name: 'Estonia', country_code: 'EE'  },
    { id: 9, name: 'Finland', country_code: 'FI'  },
    { id: 10, name: 'France', country_code: 'FR'  },
    { id: 11, name: 'Germany', country_code: 'DE'  },
    { id: 12, name: 'Greece', country_code: 'GR'  },
    { id: 13, name: 'Hungary', country_code: 'HU'  },
    { id: 14, name: 'Ireland, Republic of (EIRE)', country_code: 'IE'  },
    { id: 15, name: 'Italy', country_code: 'IT'  },
    { id: 16, name: 'Latvia', country_code: 'LV'  },
    { id: 17, name: 'Lithuania', country_code: 'LT'  },
    { id: 18, name: 'Luxembourg', country_code: 'LU'  },
    { id: 19, name: 'Malta', country_code: 'MT'  },
    { id: 20, name: 'Netherlands', country_code: 'NL'  },
    { id: 21, name: 'Poland', country_code: 'PL'  },
    { id: 22, name: 'Portugal', country_code: 'PT'  },
    { id: 23, name: 'Romania', country_code: 'RO'  },
    { id: 24, name: 'Slovakia', country_code: 'SK'  },
    { id: 25, name: 'Slovenia', country_code: 'SI'  },
    { id: 26, name: 'Spain', country_code: 'ES'  },
    { id: 27, name: 'Sweden', country_code: 'SE'  },
    { id: 28, name: 'United Kingdom', country_code: 'UK'  }
  ];

  categories = [];

  profiles = [
    { id: 1, name: 'Super admin' },
    { id: 2, name: 'Regional admin' },
    { id: 3, name: 'Professional' },
    { id: 4, name: 'Citizen' }
  ]

  profiles2 = [
    { id: 1, name: 'Demand' },
    { id: 2, name: 'Supply' },
    { id: 3, name: 'Public' },
    { id: 4, name: 'Finantial' }
  ]
}

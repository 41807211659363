<div class="container">
  <!--<button (click)="consoleJorge()">Consolame</button>-->
  <form>
    <h2>User form pro</h2>
    <!--<button class="btn btn-primary" (click)="consoleJorge()">Consolame</button>-->
    <div class="form-group">
      <label for="valueUsername">{{"FORM_PRO_SECTION.USERNAME_FORM_PRO" | translate}} <span class="obligatory">*</span></label>
      <input type="text" class="form-control" id="valueUsername" name="valueUsername" [(ngModel)]="valueUsername" placeholder="Please write your username">
    </div>

    <div class="form-group">
      <label for="valueEmail">{{"FORM_PRO_SECTION.EMAIL_FORM_PRO" | translate}}</label>
      <input type="text" class="form-control" id="valueEmail" name="valueEmail" [(ngModel)]="valueEmail" placeholder="Please write your username" readonly>
    </div>

    <div class="form-group">
      <label for="valueRole">{{"FORM_PRO_SECTION.ROLE_FORM_PRO" | translate}}</label>
      <!--<ng-select
        [multiple]="false"
        [closeOnSelect]="true"
        [searchable]="false"
        bindLabel="name"
        placeholder="Select role"
        [(ngModel)]="valueIdRole"
        id="valueIdRole"
        name="valueIdRole">
        <ng-option *ngFor="let role of arrayRole" [value]="role.id">{{role.name}}</ng-option>
      </ng-select>-->
      <input type="text" class="form-control" id="valueRole" name="valueRole" [(ngModel)]="valueRole.name" readonly>
    </div>

    <div>
      <label>{{"FORM_PRO_SECTION.UPGRADE_ROLE_PRO" | translate}}</label>
      <fieldset id="valueIsUpgadreRole">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="valueIsUpgadreRole" id="valueIsUpgadreRole1" (change)="isUpdateChangeEvent()" [value]="true" [(ngModel)]="valueIsUpgadreRole">
          <label class="form-check-label" for="valueIsUpgadreRole1">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="valueIsUpgadreRole" id="valueIsUpgadreRole2" (change)="isUpdateChangeEvent()" [value]="false" [(ngModel)]="valueIsUpgadreRole">
          <label class="form-check-label" for="valueIsUpgadreRole2">
            No
          </label>
        </div>
      </fieldset>
    </div>

    <div *ngIf="valueIsUpgadreRole">
      <div class="form-group">
        <label for="valueName">{{"FORM_PRO_SECTION.NAME_FORM_PRO" | translate}} <span class="obligatory">*</span></label>
        <input type="text" class="form-control" id="valueName" name="valueName" [(ngModel)]="valueName" placeholder="Please write your name">
      </div>

      <div class="form-group">
        <label for="valueLastName">{{"FORM_PRO_SECTION.LAST_NAME_FORM_PRO" | translate}} <span class="obligatory">*</span></label>
        <input type="text" class="form-control" id="valueLastName" name="valueLastName" [(ngModel)]="valueLastName" placeholder="Please write your lastname">
      </div>

      <div class="form-group">
        <label for="valueProfileImage">{{"FORM_PRO_SECTION.PROFILE_IMAGE_FORM_PRO" | translate}}<span class="obligatory">*</span></label>
        <div class="nameImages">{{valueNameProfileImage}}</div>
        <input class="form-control" type="file" name="valueProfileImage" id="valueProfileImage" (change)="fileProfileImageChangeEvent($event)">
      </div>

      <div class="form-group">
        <label for="valueNumberPhone">{{"FORM_PRO_SECTION.WHATSAPP_NUMBER_FORM_PRO" | translate}}</label>
        <div class="row">
          <ng-select
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="false"
          placeholder="+ ..."
          [(ngModel)]="valuePrefixNumber"
          id="valuePrefixNumber"
          name="valuePrefixNumber"
          class="col-3">
            <ng-option *ngFor="let country of arrayCountry" [value]="country.prefix">{{country.prefix + ' ' + country.country}}</ng-option>
          </ng-select>
          <input type="text" class="form-control col-9" id="valueNumberPhone" name="valueNumberPhone" [(ngModel)]="valueNumberPhone" placeholder="Number phone with whatsapp to contact you">
        </div>
      </div>

      <div class="form-group">
        <label for="valueWebpage">{{"FORM_PRO_SECTION.WEBPAGE_FORM_PRO" | translate}}</label>
        <input type="text" class="form-control" id="valueWebpage" name="valueWebpage" [(ngModel)]="valueWebpage" placeholder="Number phone with whatsapp to contact you">
      </div>

      <div class="form-group">
        <label for="valueIdActivity">{{"FORM_PRO_SECTION.ACTIVITY_FORM_PRO" | translate}} <span class="obligatory">*</span></label>
        <ng-select
          [multiple]="false"
          [closeOnSelect]="true"
          [searchable]="false"
          bindLabel="name"
          placeholder="Select activity"
          [(ngModel)]="valueIdActivity"
          id="valueIdActivity"
          name="valueIdActivity">
          <ng-option *ngFor="let activity of arrayActivityFormPro" [value]="activity.id">{{activity.name}}</ng-option>
        </ng-select>
      </div>

      <div class="form-group">
        <label for="valueIdSpecialization">{{"FORM_PRO_SECTION.SPECIALIZATION_FORM_PRO" | translate}} <span class="obligatory">*</span></label>
        <ng-select
          [multiple]="true"
          [closeOnSelect]="false"
          [searchable]="true"
          bindLabel="name"
          placeholder="Select specialization"
          [(ngModel)]="valueIdSpecialization"
          id="valueIdSpecialization"
          name="valueIdSpecialization">
          <ng-option *ngFor="let specialization of arraySpecialization" [value]="specialization.id">{{specialization.name}}</ng-option>
        </ng-select>
      </div>

      <div class="form-group">
        <label for="valueDossier">{{"FORM_PRO_SECTION.DOSSIER_FORM_PRO" | translate}} (Only one PDF)</label>
        <div class="nameImages">{{valueNameDossier}}</div>
        <input class="form-control" type="file" name="valueDossier" id="valueDossier" (change)="fileDossierChangeEvent($event)">
      </div>

      <div class="form-group">
        <label for="valuePersonalDescription">{{"FORM_PRO_SECTION.SHORT_DESCRIPTION_FORM_PRO" | translate}}</label>
        <textarea class="form-control" id="valuePersonalDescription" name="valuePersonalDescription" cols="103" rows="5" [(ngModel)]="valueDescription"></textarea>
      </div>

      <div *ngFor="let exp of valueExperience; index as i">

        <h3>{{"FORM_PRO_SECTION.EXPERIENCE_FORM_PRO" | translate}} {{i + 1}}</h3>

        <button class="form-control btn btn-warning btnDeductExperience" *ngIf="valueExperience.length > 1" (click)="deductForm(1, i)">{{"FORM_PRO_SECTION.DEDUCT_EXPERIENCE_FORM_PRO" | translate}}</button>

        <div class="form-group">
          <label for="valueCadastreReference{{exp.idg}}">{{"FORM_PRO_SECTION.CADASTRAL_REFERENCE_ADDRESS_FORM_PRO" | translate}}<span class="obligatory">*</span></label>
          <input type="text" class="form-control" id="valueCadastreReference{{exp.idg}}" name="valueCadastreReference{{exp.idg}}" [(ngModel)]="exp.cadastreReference" placeholder="Select the cadastre reference of the building">
        </div>

        <div class="form-group">
          <label for="valueIdTypology{{exp.idg}}">{{"FORM_PRO_SECTION.TYPOLOGY_FORM_PRO" | translate}} <span class="obligatory">*</span></label>
          <ng-select
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="false"
            bindLabel="name"
            placeholder="Select typology"
            [(ngModel)]="exp.typology_id"
            id="valueIdTypology{{exp.idg}}"
            name="valueIdTypology{{exp.idg}}">
            <ng-option *ngFor="let typology of arrayTypology" [value]="typology.id">{{typology.typology}}</ng-option>
          </ng-select>
        </div>

        <div class="form-group">
          <label for="valueYear{{exp.idg}}">Interventation year<span class="obligatory">*</span></label>
          <input type="number" max="4" class="form-control" id="valueYear{{exp.idg}}" name="valueYear{{exp.idg}}" [(ngModel)]="exp.interventionYear" placeholder="Interventation year of the building">
        </div>

        <div class="form-group">
          <label for="valueDescription{{exp.idg}}">{{"FORM_PRO_SECTION.SHORT_DESCRIPTION_FORM_PRO" | translate}}<span class="obligatory">*</span></label>
          <textarea class="form-control" id="valueDescription{{exp.idg}}" name="valueDescription{{exp.idg}}" cols="103" rows="5" [(ngModel)]="exp.description"></textarea>
        </div>

        <button class="form-control btn btn-primary btnAddExperience" (click)="addForm(1, i)">{{"FORM_PRO_SECTION.ADD_EXPERIENCE_FORM_PRO" | translate}}</button>

      </div>

      <div *ngFor="let cer of valueCertificationInfo; index as i">

        <h3>{{"FORM_PRO_SECTION.CERTIFICATION_TITLE_FORM_PRO" | translate}} {{i + 1}}</h3>

        <button class="form-control btn btn-warning btnDeductCertification" *ngIf="valueCertificationInfo.length > 1" (click)="deductForm(2, i)">{{"FORM_PRO_SECTION.DEDUCT_CERTIFICATION_FORM_PRO" | translate}}</button>

        <div class="form-group">
          <label for="valueCertification{{cer.idg}}">{{"FORM_PRO_SECTION.CERTIFICATION_TITLE_FORM_PRO" | translate}} <span class="obligatory">*</span></label>
          <ng-select
            [multiple]="false"
            [closeOnSelect]="true"
            [searchable]="true"
            bindLabel="name"
            placeholder="Select tool"
            [(ngModel)]="cer.tool_id"
            id="valueCertification{{cer.idg}}"
            name="valueCertification{{cer.idg}}">
            <ng-option [value]="0">Other</ng-option>
            <ng-option *ngFor="let tool of arrayTool" [value]="tool.uid">{{tool.app_name}}</ng-option>
          </ng-select>
        </div>

        <div *ngIf="cer.tool_id == 0" class="form-group">
          <label for="valueOtherName{{cer.idg}}">Other name<span class="obligatory">*</span></label>
          <input type="text" class="form-control" id="valueOtherName{{cer.idg}}" name="valueOtherName{{cer.idg}}" [(ngModel)]="cer.name" placeholder="Chosse a name for the certification">
        </div>

        <div class="form-group">
          <label for="valueCertificationSupport">Certification Support <span class="obligatory">*</span></label>
          <div class="nameImages">{{cer.file}}</div>
          <input class="form-control" type="file" name="valueCertificationSupport" id="valueCertificationSupport" (change)="fileCertificationSupportChangeEvent($event, i)">
        </div>

        <button class="form-control btn btn-primary btnAddCertification" (click)="addForm(2, i)">{{"FORM_PRO_SECTION.ADD_CERTIFICATION_FORM_PRO" | translate}}</button>

      </div>

    </div>

    <br><br>

    <button class="form-control btn btn-primary btnUpdatePro" (click)="submitForm()">{{"FORM_PRO_SECTION.UPDATE_FORM" | translate}}</button>

  </form>
</div>

<div style="padding-top: 2rem;">

    <div *ngIf="window == 3">
      <div class="d-flex justify-content-left navButtons">
        <button *ngIf="articleSelected.articles_status_id == 1" type="button" class="btn btn-link buttonsAdminTools" (click)="toArticleTable()"><div class="h3"><- Articles table</div></button>
        <button *ngIf="articleSelected.articles_status_id != 1" type="button" class="btn btn-link buttonsAdminTools" (click)="toProposeArticleTable()"><div class="h3"><- Propose articles table</div></button>
      </div>
    </div>
<!--
    <div *ngIf="window == 5">
      <div class="d-flex justify-content-left navButtons">
        <button type="button" class="btn btn-link buttonsAdminTools"><div class="h3" (click)="toProposeArticle()"><- Propose article table</div></button>
      </div>
    </div>
-->
    <div *ngIf="window == 1 || window == 2 || window == 4">
      <div class="d-flex justify-content-center navButtons">
        <button type="button" class="btn btn-link buttonsAdminTools" (click)="toCreateArticle()"><div class="h3">Create article</div></button>
        <button type="button" class="btn btn-link buttonsAdminTools" (click)="toArticleTable()"><div class="h3">Article table</div></button>
        <button type="button" class="btn btn-link buttonsAdminTools" (click)="toProposeArticle()"><div class="h3">Propose article</div></button>
      </div>
    </div>
</div>

<div *ngIf="window == 1 || window == 3">
    <form>

      <div *ngIf="adminComment != null && adminComment != ''">
        <label for="adminComment">Admin comment</label>
        <p>{{adminComment}}</p>
      </div>

      <label for="name">Article name <span class="obligatory">*</span></label>
      <input type="text" id="name" class="form-control" placeholder="Write a name for the article" [(ngModel)]="valueName" name="name" required><br>

      <label for="country">Country of origin of the article: <span class="obligatory">*</span></label>
      <ng-select
        [multiple]="false"
        [closeOnSelect]="true"
        [searchable]="true"
        bindLabel="name"
        placeholder="Select country"
        [(ngModel)]="valueCountry"
        id="articlecountry"
        name="articlecountry"
        [required]="true">
        <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
      </ng-select><br>

      <label for="category">Article category: <span class="obligatory">*</span></label>
      <ng-select
        [multiple]="false"
        [closeOnSelect]="true"
        [searchable]="true"
        bindLabel="name"
        placeholder="Select category"
        [(ngModel)]="valueCategory"
        id="articlecategory"
        name="articlecategory"
        [required]="true">
        <ng-option *ngFor="let category of arrayCategoryArticle" [value]="category.id">{{category.name}}</ng-option>
      </ng-select><br>

      <label for="date">Publication date <span class="obligatory">*</span></label>
      <input type="date" id="date" class="form-control" [(ngModel)]="valueDate" name="date"><br>

      <label for="author">Author <span class="obligatory">*</span></label>
      <input type="text" id="author" class="form-control" placeholder="Name of the author" [(ngModel)]="valueAuthor" name="author"><br>

      <label for="short_desc">Article summary (255) <span class="obligatory">*</span></label><br>
      <textarea id="short_desc" name="short_desc" class="form-control" placeholder="Write the sort description" rows="4" cols="50" [(ngModel)]="valueShortDescription" (ngModelChange)="shortDescriptionChangeEvent()" maxlength="255"></textarea>
      <div>{{lengthShortDescription}}/255</div><br>

      <label for="long_desc">Long Description (5000) <span class="obligatory">*</span></label><br>
      <textarea id="long_desc" name="long_desc" class="form-control" placeholder="Write the long description" rows="4" cols="50" [(ngModel)]="valueLongDescription" (ngModelChange)="longDescriptionChangeEvent()"maxlength="5000"></textarea>
      <div>{{lengthLongDescription}}/5000</div><br>

      <label for="link">Link:</label>
      <input type="text" id="link" class="form-control" placeholder="web url" [(ngModel)]="valueLink" name="link"><br>

      <label>Multimedia information</label>
      <div class="form-group">
        <p *ngIf="window == 3">You have got already a Main image: {{nameMainImage}}. Do you want to change it?</p>
        <label for="images" class="form-label">Main Image <span class="obligatory">*</span></label>
        <input class="form-control" type="file" id="images" name="images" (change)="fileImageChangeEvent($event)">
      </div>

      <div>
        <label>Do you have more multimedia resources? <span class="obligatory">*</span></label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="isMoreMultimedia">
          <label class="form-check-label" for="flexRadioMonitoring1">
            Yes
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="isMoreMultimedia">
          <label class="form-check-label" for="flexRadioMonitoring2">
            No
          </label>
        </div>
      </div>

      <br>

      <div *ngIf="isMoreMultimedia == true">
        <label for="images" class="form-label">Multimedia resources</label>

        <div class="form-group">
          <p *ngIf="window == 3">You have got already some images: {{nameAdditionalImages}}</p>
          <label for="images" class="form-label">Other images (max 5) <span class="obligatory">*</span></label>
          <input class="form-control" type="file" id="images" name="images" (change)="fileOtherImageChangeEvent($event)" multiple>
        </div>

        <div class="form-group">
          <label for="name">Video <span class="obligatory">*</span></label>
          <input type="text" class="form-control" id="video" name="video" placeholder="Video URL" [(ngModel)]="valueVideoURL">
        </div>

        <div class="form-group">
          <p *ngIf="window == 3">You have got already some PDF FILES: {{nameAdditionalDocuments}}</p>
          <label for="formFile" class="form-label">Documents (.pdf, max 3) <span class="obligatory">*</span></label>
          <input class="form-control" type="file" name="documents" (change)="fileChangeEvent($event)" multiple>
        </div>
      </div>

      <button *ngIf="window == 1" (click)="createArticle()" class="btn btn-primary">Create article</button>
      <button *ngIf="window == 3" (click)="updateArticle()" class="btn btn-primary">Update article</button>

    </form>
</div>

<div *ngIf="window == 2 || window == 4">
  <div class="form-group row">
    <div *ngIf="window == 2" class="col-1">
      Filters:
    </div>

    <div *ngIf="window == 2" class="col-2">
      <input type="text" class="form-control" id="nameFilter" name="nameFilter" [(ngModel)]="valueFilterName" placeholder="Name filter">
    </div>

    <!--<div *ngIf="window == 4" class="col-2">
      <input type="text" class="form-control" id="usernameFilter" name="usernameFilter" [(ngModel)]="valueFilterUsername" placeholder="Username filter">
    </div>-->

    <div *ngIf="window == 2" class="col-2">
      <ng-select name="valueFilterCountryArticle" id="valueFilterCountryArticle" [(ngModel)]="valueFilterCountryArticle" placeholder="Country filter" [disabled]="currentUser.user_roles[0].role_id == 2">
        <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
      </ng-select>
    </div>

    <div *ngIf="window == 2" class="col-2">
      <ng-select name="categoryFilter" id="categoryFilter" [(ngModel)]="valueFilterCategoryArticle" placeholder="Category filter">
        <ng-option *ngFor="let category of arrayCategoryArticle" [value]="category.id">{{category.name}}</ng-option>
      </ng-select>
    </div>

    <!--<div *ngIf="window == 4" class="col-2">
      <ng-select name="statusFilter" id="statusFilter" [(ngModel)]="valueFilterStatus" placeholder="Status filter">
        <ng-option *ngFor="let status of arrayArticleStatus" [value]="status.id">{{status.name}}</ng-option>
      </ng-select>
    </div>-->

    <div class="col-1">
      <button *ngIf="window == 2" class="form-control btn btn-warning" id="applyFilter" name="applyFilter" (click)="applyFilter('article')">Apply</button>
      <!--<button *ngIf="window == 4" class="form-control btn btn-warning" id="applyFilterPropose" name="applyFilterPropose" (click)="applyFilter('proposeArticle')">Apply</button>-->
    </div>

    <div class="col-1">
      <button *ngIf="window == 2" class="form-control btn btn-danger" id="deleteFilter" name="deleteFilter" (click)="deleteFilters('article')">Delete</button>
      <!--<button *ngIf="window == 4" class="form-control btn btn-danger" id="deleteFilterPropose" name="deleteFilterPropose" (click)="deleteFilters('proposeArticle')">Delete</button>-->
    </div>

    <div class="col-1">
      <button *ngIf="window == 2" class="form-control btn btn-info" id="refreshTools" name="refreshTools" (click)="refreshTable('article')">Refresh</button>
      <!--<button *ngIf="window == 4" class="form-control btn btn-info" id="refreshToolsPropose" name="refreshToolsPropose" (click)="refreshTable('proposeArticle')">Refresh</button>-->
    </div>

  </div>

  <div *ngIf="window == 2">

    <div class="form-group row">
      <table class="table table-hover" id="tableToolUpdate" name="tableTool">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Author</th>
            <th scope="col">Country</th>
            <th scope="col">Category</th>
            <th scope="col">Short description</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let article of tmpArrayArticle | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
            <td>{{article.article_name}}</td>
            <td>{{article.author}}</td>
            <td>{{article.country.country_code}}</td>
            <td>{{article.category_article.name}}</td>
            <td>{{article.short_description}}</td>
            <td>
              <button class="btn btn-light" (click)="toUpdateArticle(article)">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </button>
              <button class="btn btn-light" (click)="deleteArticle(article)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="tmpArrayArticle.length"></ngb-pagination>

  </div>

  <div *ngIf="window == 4">
    <div class="col-md-2 card-article justify-content-center" *ngFor="let article of tmpArrayProposeArticle">
      <div class="header">
        <button style="margin-left: 0%;" type="button" class="btn btn-default row">
          {{'TOOLS_PAGE.TOOLS.BUTTON_SAVE' | translate}}
          <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
        </button>&nbsp; &nbsp; &nbsp; &nbsp;
      </div>
      <a href="#/administrationPanel" (click)="openModalArticles(article)" style="text-decoration: none; cursor: pointer;">
        <h3 class="report-title">{{article.article_name}}</h3>
      </a>
      <span class="badge" style="background-color: #1C8972; color: white;">{{article.category_article.name}}</span>
      &nbsp;
      <span class="badge" style="background-color: #5A1981; color: white;">{{article.country.country}}</span>
      <br>
      <img src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8=" class="rounded-circle" style="width: 50px;" alt="Avatar" />
      <footer class="author-article">{{article.author}}</footer>
      <footer>{{article.publication_date | date: 'dd/MM/yyyy'}}</footer>
      <p class="report-parrafo" style="text-align: justify;">
        {{article.short_description}}
      </p>
      <div class="image-container">
        <img src="{{imageUrl}}/mainImageArticles/{{article.id}}/{{article.main_image}}" width="300" height="auto">
      </div>
      <br>
    </div>
  </div>

</div>

<!--
    <div class="form-group row">
      <table class="table table-hover" id="tableToolUpdate" name="tableTool">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Username</th>
            <th scope="col">Country</th>
            <th scope="col">Short description</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let article of tmpArrayProposeArticle | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; index as i">
            <td>{{article.article_name}}</td>
            <td>{{article.user.name}}</td>
            <td>{{article.country.country_code}}</td>
            <td>{{article.short_description}}</td>
            <td>{{article.articles_status.name}}</td>
            <td>
              <button class="btn btn-light" (click)="toUpdateArticle(article)">
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </button>
              <button class="btn btn-light" (click)="deleteArticle(article)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ngb-pagination class="d-flex justify-content-center" [(page)]="page2" [pageSize]="pageSize2" [collectionSize]="tmpArrayProposeArticle.length"></ngb-pagination>
  -->
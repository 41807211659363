<div class="typologySection">
  <div class="d-flex flex-row bd-highlight">
    <div class="p-2 bd-highlight">
      <img src="../../../assets/img/icons/energy_cost_icon.svg">
    </div>
    <div class="p-2 bd-highlight">
      <h5><span  class="align-middle"><strong> {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TITLE_CALCULATING' | translate}}</strong></span></h5>
    </div>
  </div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col">
        <div [hidden]="building.properties.length < 1">
          <img class="img-thumbnail" alt="Facade image" [src]='building.properties[0].image' *ngIf=" building.properties.length > 0 && building.properties[0].image" width="170" height="170"/>
        </div>
        <div [hidden]="building.typology.categoryCode === ''" *ngIf="building.country === 'FR'">
          <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/FR/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
        </div>
        <div [hidden]="building.typology.categoryCode === ''" *ngIf="building.country === 'IT'">
          <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/IT/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
        </div>
        <div [hidden]="building.typology.categoryCode === ''" *ngIf="building.country === 'NL'">
          <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/NL/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
        </div>
        <div [hidden]="building.typology.categoryCode === ''" *ngIf="building.country === 'BG'">
          <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/BG/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
        </div>
        <div [hidden]="building.typology.categoryCode === ''" *ngIf="building.country === 'SI'">
          <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/SI/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
        </div>
        <div [hidden]="building.typology.categoryCode === ''" *ngIf="building.country === 'GR'">
          <img class="img-thumbnail" alt="Facade image" src="../../../assets/img/typology/GR/{{building.typology.categoryPicCode}}.001.jpg" width="170" height="170">
        </div>
      </div>
      <div class="col">
        <h5 [hidden]="!building.region"> {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.PROVINCE' | translate}}: {{ building.region }} </h5>
        <p> <img src="../../../assets/img/icons/location_icon.svg" alt="location" style="width: 16px"> <strong>&nbsp;{{building.address}}</strong>
        </p>
        <div class="d-flex bd-highlight">
          <div class="p-2 flex-fill bd-highlight" *ngIf="building.surface">
            <span><img src="../../../assets/img/icons/square_meters_icon.svg" alt="Surface" style="width: 16px!important;">&nbsp;{{building.surface}}&nbsp; m<sup>2</sup> </span>
          </div>
          <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'ES'">
            <p *ngIf="building.climateZone == 'ES.ME'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION' | translate}}</strong></p>
          </div>

          <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'FR'">
            <p *ngIf="building.climateZone == 'FR.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>
          </div>

          <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'IT'">
            <p *ngIf="building.climateZone == 'IT.MidClim'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Middle Climatic Zone (Zone E)</strong></p>
            <p *ngIf="building.climateZone == 'IT.Alpine'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Alpine Zone (Zone F)</strong></p>
            <p *ngIf="building.climateZone == 'IT.Mediterranean'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Mediterranean Zone</strong></p>
          </div>

          <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'BG'">
            <p *ngIf="building.climateZone == 'BG.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>
          </div>

          <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'GR'">
            <p *ngIf="building.climateZone == 'GR.ZoneA'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Climatic zone A</strong></p>
            <p *ngIf="building.climateZone == 'GR.ZoneB'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Climatic zone B</strong></p>
            <p *ngIf="building.climateZone == 'GR.ZoneC'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Climatic zone C</strong></p>
            <p *ngIf="building.climateZone == 'GR.ZoneD'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>Climatic zone D</strong></p>
            <p *ngIf="building.climateZone == 'GR.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>
          </div>

          <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'NL'">
            <p *ngIf="building.climateZone == 'NL.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>
          </div>

          <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'SI'">
            <p *ngIf="building.climateZone == 'SI.N'">{{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <strong>{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</strong></p>
          </div>
        </div>
        <div class="d-flex bd-highlight">
          <div class="p-2 flex-fill bd-highlight">
            <span><img src="../../../assets/img/icons/year_icon.svg" alt="year" style="width: 16px;">&nbsp;{{building.year}}</span>
          </div>
          <div class="p-2 flex-fill bd-highlight">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="accordion customData" id="accordionExample">
    <div class="card">
      <div class="card-header color-card-header" id="typologySelectSection">
        <div class="d-flex bd-highlight">
          <div class="col-md-6">
            <h4>
              1. {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.TYPOLOGY' | translate}}
            </h4>
          </div>
          <div class="col-md-4" *ngIf="building.typology" [hidden]="!building.typology.categoryName">
            <!--<img class="card-img-top typologyIcon p-2 flex-fill bd-highlight" alt="Building image"  src="./assets/img/typology/{{building.country}}/{{building.typology.picName}}" >-->
            <!--For the cadastre in Spain-->
            <img *ngIf="building.typology.categoryName === 'Apartment block'" src="../../../assets/img/icons/apartment_block_icon.svg">
            <img *ngIf="building.typology.categoryName === 'MultiFamily Home'" src="../../../assets/img/icons/multy_family_icon.svg">
            <img *ngIf="building.typology.categoryName === 'SingleFamily Home'" src="../../../assets/img/icons/single_family_icon.svg">

            <img *ngIf="building.typology.categoryName === 'Apartment Block'" src="../../../assets/img/icons/apartment_block_icon.svg">
            <img *ngIf="building.typology.categoryName === 'Multi Family Home'" src="../../../assets/img/icons/multy_family_icon.svg">
            <img *ngIf="building.typology.categoryName === 'Single Family Home'" src="../../../assets/img/icons/single_family_icon.svg">
            <img *ngIf="building.typology.categoryName === 'Terraced House'" src="../../../assets/img/icons/terraced_house_icon.svg">
            {{building.typology.categoryName}}
          </div>
          <div class="col-md-2">
            <button class="btn btn-link button-arrow" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <div id="collapseOne" class="collapse show " aria-labelledby="typologySelectSection" data-parent="#accordionExample">
        <div class="d-flex bd-highlight" *ngIf="!hasSubCategories">
          <div *ngFor="let typ of typologies" class="d-flex bd-highlight col">
            <a (click)="calculateEnvelopedSystems(typ);" class="row typologyIcon2">
              <img class="card-img-top typologyIcon p-2 flex-fill bd-highlight" alt="Building image"  src="./assets/img/typology/{{building.country}}/{{typ.picName}}" >
              <!--<img *ngIf="typ.categoryName === 'Apartment Block'" src="../../../assets/img/icons/apartment_block_icon.svg">
              <img *ngIf="typ.categoryName === 'Multi Family Home'" src="../../../assets/img/icons/multy_family_icon.svg">
              <img *ngIf="typ.categoryName === 'Single Family Home'" src="../../../assets/img/icons/single_family_icon.svg">
              <img *ngIf="typ.categoryName === 'Terraced House'" src="../../../assets/img/icons/terraced_house_icon.svg">-->
            <h6 class="p-2 flex-fill bd-highlight">{{typ.categoryName}}</h6>
          </a>
          </div>
        </div>
        <div class="d-flex bd-highlight" *ngIf="hasSubCategories">
          <div *ngFor="let typ of typologies" class="d-flex bd-highlight">
            <a (click)="selectTypo(typ);" >
              <img class="card-img-top typologyIcon p-2 flex-fill bd-highlight" alt="Building image"  src="./assets/img/typology/{{building.country}}/{{typ.picName}}" >
            </a>
            <h6 class="p-2 flex-fill bd-highlight typology-color">{{typ.categoryName}}</h6>
          </div>
        </div>
        <div class="typoSelected d-flex bd-highlight" *ngIf="hasSubCategories && typologySelected">
          <div class=" p-2 flex-fill bd-highlight typologyImageCont">
            <img class="card-img-top typologyImage" alt="Building image"  src="./assets/img/typology/{{building.country}}/{{typologySelected.picName}}" >
          </div>
          <div class=" p-2 flex-fill bd-highlight" *ngIf="subcategoriesTypo && subcategoriesTypo[typologySelected.categoryCode]">
            <div class="form-check" *ngFor="let sub of subcategoriesTypo[typologySelected.categoryCode].subcats">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="{{sub.category_pic_code}}" value="{{typologySelected}}" (click)="calculateEnvelopedSystems(typologySelected = sub.info)">
              <label class="form-check-label" for="{{sub.category_pic_code}}">
                {{sub.description}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header color-card-header" id="buildingDataSection">
        <div class="d-flex bd-highlight">
          <div class="col-md-6">
           <h4>2. {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.BUILDING_DATA' | translate}} </h4>
          </div>
          <div class="col-md-4">

          </div>
          <div class="col-md-2">
            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <div id="collapseTwo" class="collapse" aria-labelledby="buildingDataSection" data-parent="#accordionExample" [ngClass]="building.typology && building.typology.categoryCode ? 'show' : ''">
        <div class="d-flex bd-highlight" >
          <div *ngFor="let env of buildingSelection | keyvalue" class="p-2 flex-fill bd-highlight" [hidden]="!showTypoSelect" >
              <a class="componentEnv" [ngClass]="componentSelected.component === env.key ? 'env-selected' : ''" (click)="selectComponent(env.key, env.value);" *ngIf="env.value > 0" id="{{env.key}}">
                <!--<i class="fa fa-home fa-2x col-md-6" aria-hidden="true" ></i>-->
                <span *ngIf="env.key === 'Door'"> {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.DOOR' | translate}}</span>
                <span *ngIf="env.key === 'Floor'"><img src="../../../assets/img/icons/floor_icon.svg"> {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.FLOOR' | translate}}</span>
                <span *ngIf="env.key === 'Roof'"><img src="../../../assets/img/icons/roof_icon.svg"> {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.ROOF' | translate}}</span>
                <span *ngIf="env.key === 'Wall'"><img src="../../../assets/img/icons/wall_icon.svg"> {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.WALL' | translate}}</span>
                <span *ngIf="env.key === 'Window'"><img src="../../../assets/img/icons/window_icon.svg"> {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.WINDOW' | translate}}</span>
                <!--<span> {{env.key}}</span>-->
              </a>
          </div>
        </div>
        <div class="envelopeSelected  d-flex justify-content-center" *ngIf="componentSelected && componentSelected.value === 1">
          <div class="row p-2 flex-fill bd-highlight" *ngIf="envelopedSelected && envelopedSelected.length > 0">
            <img class="typologyImage2" alt="Building image"  src="./assets/img/envelope/{{building.country}}/{{envelopedSelected[0].picture}}" >
          </div>
          <div class="col p-2 flex-fill bd-highlight">
            <h5><p>{{envelopedSelected[0].typeConstruction}}</p></h5>
            <p>{{envelopedSelected[0].description}}</p>
          </div>
        </div>

        <div class="envelopeSelected d-flex justify-content-center row" *ngIf="componentSelected && componentSelected.value > 1">
          <div class="row col-md col-sm " *ngFor="let env of envelopedSelected;">
            <img class="card-img-top typologyImage col-md col-sm col-lg" alt="Building image"  src="./assets/img/envelope/{{building.country}}/{{env.picture}}" alt="{{env.componentType}}">

            <div class=" col-md col-sm col-lg"> 
              <label *ngIf="!currentUser || currentUser.country != building.country"><h5>{{env.typeConstruction}}</h5></label>
              <label *ngIf="currentUser != null && currentUser.country == building.country"><h5>{{env.typeConstructionOriginal}}</h5></label>
              <p *ngIf="!currentUser || currentUser.country != building.country">
                {{env.description}}
              </p>
              <p *ngIf="currentUser != null && currentUser.country == building.country">
                {{env.originalDescription}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header color-card-header" id="systemTypeSection">
        <div class="d-flex bd-highlight">
          <div class="col-md-6">
            <h4>
              3. {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.SYSTEM_DATA.TITLE' | translate}}
            </h4>
          </div>
          <div class="col-md-4">
            <ng-select [(ngModel)]="codeSystemMeasureSelected" appendTo="body" placeholder="Select system..." (change)="selectSystem(1); enableCalculate=true;" [searchable]="true" [clearable]="true">
              <ng-option *ngFor="let sys of systems" [value]="sys.codeSystemMeasure">{{sys.description}}</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2">
            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <div id="collapseThree" class="collapse show" aria-labelledby="systemTypeSection" data-parent="#accordionExample">
        <div class="d-flex bd-highlight">
        </div>
        <div class="d-flex bd-highlight" [hidden]="!categorySystem">
          <div class="p-2 flex-fill bd-highlight">
            <a  class="componentSys" (click)="selectSystem(1);"  id="heating"><img src="../../../assets/img/icons/heating_icon.svg">
              {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.SYSTEM_DATA.HEATING' | translate}}</a>
          </div>
          <div class="p-2 flex-fill bd-highlight">
            <a class="componentSys" (click)="selectSystem(2);"  id="water"><img src="../../../assets/img/icons/water_icon.svg">
              {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.SYSTEM_DATA.DOMESTIC_HOT_WATER' | translate}}
            </a>
          </div>
          <div class="p-2 flex-fill bd-highlight">
            <a class="componentSys" (click)="selectSystem(3);" id="ventilation"><img src="../../../assets/img/icons/ventilation_icon.svg">
              {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.SYSTEM_DATA.VENTILATION' | translate}}
            </a>
          </div>
        </div>
        <div class="envelopeSelected d-flex bd-highlight" *ngIf="categorySystem && categorySystem.name">
          <div class="row p-2 flex-fill bd-highlight typologyImageCont">
            <img class="card-img-top typologyImage" alt="Building image"  src="./assets/img/system/{{building.country}}/{{categorySystem.picture}}" >
          </div>
          <div class="col p-2 flex-fill bd-highlight" style="padding-left: 2rem !important;">
            <h5><p>{{categorySystem.systemName}}</p></h5>
            <p *ngIf="!currentUser || currentUser.country != building.country">
              {{categorySystem.description}}
            </p>
            <p *ngIf="currentUser != null && currentUser.country == building.country">
              {{categorySystem.descriptionOriginal}}
            </p>
          </div>
        </div>

        <!--
          <div class="row">
            <div class="col ">
              <div class="card">
                <div class="card-header">
                  <a  class="componentSys" (click)="selectSystem(1);"  id="heating"><i class="fa fa-home fa-2x col-md-6" aria-hidden="true" ></i>
                    {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.SYSTEM_DATA.HEATING' | translate}}</a>
                </div>
                <div class="card-body">
                  <div class="envelopeSelected d-flex bd-highlight" *ngIf="categorySystem && categorySystem.name">
                    <div class=" p-2 flex-fill bd-highlight typologyImageCont">
                      <img class="card-img-top typologyImage" alt="Building image"  src="./assets/img/system/{{building.country}}/{{categorySystem.picture}}" >
                    </div>
                    <div class=" p-2 flex-fill bd-highlight">
                      <h6>{{categorySystem.description}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col ">
              <div class="card">
                <div class="card-header">
                  <a class="componentSys" (click)="selectSystem(2);"  id="water"><i class="fa fa-home fa-2x col-md-6" aria-hidden="true" ></i>
                    {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.SYSTEM_DATA.DOMESTIC_HOT_WATER' | translate}}
                  </a>
                </div>
                <div class="card-body">
                  <div class="envelopeSelected d-flex bd-highlight" *ngIf="categorySystem && categorySystem.name">
                    <div class=" p-2 flex-fill bd-highlight typologyImageCont">
                      <img class="card-img-top typologyImage" alt="Building image"  src="./assets/img/system/{{building.country}}/{{categorySystem.picture}}" >
                    </div>
                    <div class=" p-2 flex-fill bd-highlight">
                      <h6>{{categorySystem.description}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col ">
              <div class="card">
                <div class="card-header">
                  <a class="componentSys" (click)="selectSystem(3);" id="ventilation"><i class="fa fa-home fa-2x col-md-6" aria-hidden="true" ></i>
                    {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.SYSTEM_DATA.VENTILATION' | translate}}
                  </a>
                </div>
                <div class="card-body">
                  <div class="envelopeSelected d-flex bd-highlight" *ngIf="categorySystem && categorySystem.name">
                    <div class=" p-2 flex-fill bd-highlight typologyImageCont">
                      <img class="card-img-top typologyImage" alt="Building image"  src="./assets/img/system/{{building.country}}/{{categorySystem.picture}}" >
                    </div>
                    <div class=" p-2 flex-fill bd-highlight">
                      <h6>{{categorySystem.description}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        -->
      </div>
    </div>
  </div>

  <div >
    <div class="d-flex justify-content-around typologyInfo">
      <button  class="btn calculateTyp" (click)="goBack();">
        <h4>
          <span> <i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.BUTTON_BACK' | translate}}</span>
        </h4>

      </button>
      <button role="button" (click)="calculateEfficiency();" class="btn calculateTyp" [disabled]="!enableCalculate">
        <h4>
          <span> {{'ONE_CLICK_PAGE.CALCULATING_ENERGY.BUTTON_NEXT' | translate}} &nbsp; <i class="fa fa-arrow-right" aria-hidden="true"></i></span>
        </h4>
      </button>
    </div>
  </div>
  <!--div class="container" [ngbNavOutlet]="nav"
       fxLayout="column"
       fxLayoutGap="30px" >
  </div-->
</div>


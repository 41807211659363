import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/authentication/user.service';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';
import { CountryService } from 'src/app/core/country/country.service';
import { Language } from 'src/app/shared/models/language';
import { NodeResponse } from 'src/app/shared/models/node_response';

@Component({
  selector: 'app-country-page',
  templateUrl: './country-page.component.html',
  styleUrls: ['./country-page.component.scss']
})
export class CountryPageComponent implements OnInit {

  uid: string = '';

  arrayLanguage: Language[] = [];
  valueSelectLanguage: Language = null;

  constructor(
    private router: Router,
    private userService: UserService,
    private countryService: CountryService,
    private administrationPanelService: AdministrationPanelService
  ) { }

  ngOnInit(): void {

    this.uid = sessionStorage.getItem('uid');
    console.log(this.uid)

    this.userService.getByUid(this.uid).subscribe((resUser: NodeResponse) => {
      if(resUser.status == true && resUser.data.country == null){
        this.countryService.getLanguages().toPromise().then((resLanguage: NodeResponse) => {
          console.log(resLanguage)
          resLanguage.data.forEach(language => this.arrayLanguage.push(language));
        })
      }else{
        console.log('tienes ya un pais')
        this.router.navigate(['/', 'home'])
      }
    }, (error: any) => {
      if(error.status == 500){
        console.log('pa tu casa')
        this.router.navigate(['/', 'home'])
      }
    })

  }

  updateLanguage(){

    let bodyUpdate = {
      language: this.valueSelectLanguage
    }

    this.userService.update(this.uid, bodyUpdate).toPromise().then((res: any) => {
      console.log('res update de language -> ', res)
      alert('Your user has a language now. Just login again on the home page.');
      this.router.navigate(['/', 'home']);
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private urlRole = GlobalConstants.backendURL + '/api/role';
  private urluserRole = GlobalConstants.backendURL + '/api/user_role';

  constructor(private http: HttpClient) { }

  getAllRole(){
    return this.http.get(this.urlRole);
  }

  updateRoleUser(body){
    return this.http.put(this.urluserRole, body);
  }
}

<div style="border-radius: 22px; padding-top: 50px;">
  <div class="card card-saved-properties" style="border-radius: 22px; border: none;">

    <div class="card-header header-principal" id="propertiesSaved">
      <h2 class="mb-0">
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-link btn-block align-items-center d-flex justify-content-center button-saved-properties" type="button" data-toggle="collapse" data-target="#properties" aria-expanded="false" aria-controls="properties">
              <h3>All properties</h3>
            </button>
          </div>
          <div class="col-md-2">

          </div>
          <div class="col-md-4">
            <!--<img src="../../../assets/img/buttons/saved_botton.png"> &nbsp; <span class="number-saved-properties">{{userHistory.length}}</span>-->
          </div>
        </div>
      </h2>
    </div>

    <div id="properties" class="collapse show" aria-labelledby="propertiesSaved">
      <div class="card-body">
        <div style="background-color: #EFF2F6; padding: 5px; border-radius: 10px; margin-bottom: 10px;">
          <div class="row">
            <div class="col-md-6">
              <div class="input-group search-box">
                <input class="form-control search-box-form" type="search" [(ngModel)]="searchText" placeholder="{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.SEARCH_PROPERTY' | translate}}" id="example-search-input">
              </div>
            </div>
            <div class="col-md-6">
              <button class="btn btn-primary filter-button" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.BUTTON' | translate}}
              </button>
            </div>
          </div>
          <div class="collapse " id="collapseExample">
            <div class="card card-body form">
              <div class="row">
                <div class="col-md-6">
                  <label for="filterCountry"> {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.COUNTRY' | translate}}: </label>
                  <ng-select id="filterCountry" class="filter" [(ngModel)]="countrySelected"
                              (change)="filter('country');">
                    <ng-option *ngFor="let country of filterCountries" [value]="country">{{country}}</ng-option>
                  </ng-select>
                </div>

                <div class="col-md-6">
                  <label for="buildingType"> {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.BUILDING_TYPE' | translate}}: </label>
                  <ng-select id="buildingType" class="filter" [(ngModel)]="typologySelected"
                              (change)="filter('typology');">
                    <ng-option *ngFor="let typo of filterTypologies" [value]="typo.code">{{typo.name}}</ng-option>
                  </ng-select>
                </div>

                <div class="col-md-6">
                  <label for="filterYearMin">{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.MIN_YEAR' | translate}}: </label>
                  <ng-select id="filterYearMin" class="filter" [(ngModel)]="yearSelectedMin"
                            (change)="filter('yearMin');">
                    <ng-option *ngFor="let year of filterYears" [value]="year">{{year}}</ng-option>
                  </ng-select>
                </div>

                <div class="col-md-6">
                  <label for="filterYearMax">{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.MAX_YEAR' | translate}}: </label>
                  <ng-select id="filterYearMax" class="filter" [(ngModel)]="yearSelectedMax"
                            (change)="filter('yearMax');">
                    <ng-option *ngFor="let year of filterYears" [value]="year">{{year}}</ng-option>
                  </ng-select>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="tmpCeeBuilding.length"></ngb-pagination>

    <div class="card card-building justify-content-center" *ngFor="let building of tmpCeeBuilding | filter: searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <img *ngIf="building.facade_picture !== null" src="{{building.facade_picture}}" width="150" height="150">

            <!--Typology AB-->
            <img *ngIf="building.facade_picture === null && building.country === 'ES' && building.tipology_id === 'AB'" src="../../../assets/img/typology/ES/ES.ME.AB.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'FR' && building.tipology_id === 'AB'" src="../../../assets/img/typology/FR/FR.N.AB.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'IT' && building.tipology_id === 'AB'" src="../../../assets/img/typology/IT/IT.MidClim.AB.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'SI' && building.tipology_id === 'AB'" src="../../../assets/img/typology/SI/SI.N.AB.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'BG' && building.tipology_id === 'AB'" src="../../../assets/img/typology/BG/BG.N.AB.03.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'GR' && building.tipology_id === 'AB'" src="../../../assets/img/typology/GR/GR.ZoneA.SFH.02.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'NL' && building.tipology_id === 'AB'" src="../../../assets/img/typology/NL/NL.N.AB.01.gal.001.jpg" width="150" height="150">

            <!--Typology MFH-->
            <img *ngIf="building.facade_picture === null && building.country === 'ES' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/ES/ES.ME.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'FR' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/FR/FR.N.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'IT' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/IT/IT.MidClim.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'SI' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/SI/SI.N.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'BG' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/BG/BG.N.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'GR' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/GR/GR.ZoneA.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'NL' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/NL/NL.N.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">

            <!--Typology SFH-->
            <img *ngIf="building.facade_picture === null && building.country === 'ES' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/ES/ES.ME.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'FR' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/FR/FR.N.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'IT' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/IT/IT.MidClim.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'SI' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/SI/SI.N.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'BG' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/BG/BG.N.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'GR' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/GR/GR.ZoneA.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'NL' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/NL/NL.N.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">

            <!--Typology TH-->
            <img *ngIf="building.facade_picture === null && building.country === 'ES' && building.tipology_id === 'TH'" src="../../../assets/img/typology/ES/ES.ME.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'FR' && building.tipology_id === 'TH'" src="../../../assets/img/typology/FR/FR.N.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'IT' && building.tipology_id === 'TH'" src="../../../assets/img/typology/IT/IT.MidClim.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'SI' && building.tipology_id === 'TH'" src="../../../assets/img/typology/SI/SI.N.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'BG' && building.tipology_id === 'TH'" src="../../../assets/img/typology/BG/BG.N.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'GR' && building.tipology_id === 'TH'" src="../../../assets/img/typology/GR/GR.ZoneD.SFH.03.Gen.ReEx.001.jpg" width="150" height="150">
            <img *ngIf="building.facade_picture === null && building.country === 'NL' && building.tipology_id === 'TH'" src="../../../assets/img/typology/NL/NL.N.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
          </div>
          <div class="col-md-5">
            <div>
              <button class="button-link" (click)="showBuildingResults(building);" >{{building.map_address}}</button>
            </div><br>
            <div *ngIf="building.tipology_id === 'AB'">
              <img src="../../../assets/img/icons/apartment_block_icon.svg"> Apartment Block
            </div>
            <div *ngIf="building.tipology_id === 'MFH'">
              <img src="../../../assets/img/icons/multy_family_icon.svg"> Multi Family Home
            </div>
            <div *ngIf="building.tipology_id === 'SFH'">
              <img src="../../../assets/img/icons/single_family_icon.svg"> Single Family Home
            </div>
            <div *ngIf="building.tipology_id === 'TH'">
              <img src="../../../assets/img/icons/terraced_house_icon.svg"> Terraced House
            </div>

            <br>

            <div *ngIf="building.case_id === 1">
              <img src="../../../assets/img/cee-building-data/icon.PNG">
              Complete Building
            </div>

            <div *ngIf="building.case_id === 2">
              <img src="../../../assets/img/cee-building-data/icon.PNG">
              Single Home
            </div>

            <br>

            <div *ngIf="building.year !== null">
              <img src="../../../assets/img/icons/year_icon.svg">
              {{building.year}}
            </div>
          </div>
          <div class="col-md-3">
            <button *ngIf="role == 1 || role == 2" class="btn btn-light action-buttons" (click)="updateBuilding(building);">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button *ngIf="role == 1 || role == 2" class="btn btn-light action-buttons" (click)="removeCEEBuildingFromUserHistory(building.id, i)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="tmpCeeBuilding.length"></ngb-pagination>

  </div>
</div>

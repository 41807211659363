<div *ngIf="!hasError">
  <div>
      <div *ngIf="ruralBuilding" style="text-align: center;" >
        <h4>Rural property. Please try with another location</h4>
      </div>
      <div *ngIf="noData" class="message-other-location">
        <h4>No data for this location. Please try with another location</h4>
      </div>

      <div *ngIf="building.country === 'PT' || building.country === 'GB' || building.country === 'AF' || building.country === 'AL' || building.country === 'DZ'
      || building.country === 'AS' || building.country === 'AD' || building.country === 'BE' || building.country === 'GI' || building.country === 'IS'
      || building.country === 'PL' || building.country === 'TR' || building.country === 'UA' || building.country === 'US' || building.country === 'DE'
      || building.country === 'CZ' || building.country === 'RU' || building.country === 'MD' || building.country === 'DK' || building.country === 'FI'
      || building.country === 'HU' || building.country === 'SE' || building.country === 'CH' || building.country === 'AT' || building.country === 'BA'
      || building.country === 'ME' || building.country === 'SK' || building.country === 'AO' || building.country === 'UG' || building.country === 'AR'
      || building.country === 'CN' || building.country === 'IN' || building.country === 'IE' || building.country === 'JP' || building.country === 'BR'
      || building.country === 'AU' || building.country === 'CA' || building.country === 'CF' || building.country === 'CO' || building.country === 'LV'
      || building.country === 'EE' || building.country === 'FI' || building.country === 'RO'" class="message-other-location">
        <h4>No data for this location. Please try with another location</h4>
      </div>
    <div *ngIf="building && !ruralBuilding && selectBuilding && building.country === 'ES' || building.country === 'FR' || building.country === 'GR' || building.country === 'IT' || building.country === 'SI' || building.country === 'BG' || building.country === 'NL'" class="buildingInfo">
      <!--div [hidden]="!building || ( building && !building.country) || ( building && !building.year)" fxLayoutAlign="end">
        <button [disabled]="!mapControl" class="btn calculateTyp" (click)="showMap();"  routerLink='/home' routerLinkActive='active' >
          <span><i class="fa fa-map-o" aria-hidden="true"></i> Show Map</span>
        </button>
        <a class="btn calculateTyp" role="button"  routerLink='/typology' routerLinkActive='active' (click)="calculateTypology();">
          <span>Calculate energy efficiency</span>
        </a>
      </div-->
      <div class="container">
        <div class="card card-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <div class="location">
                  {{'ONE_CLICK_PAGE.CADASTRE_DATA.LOCATION' | translate}}
                </div>
                <div [hidden]="properties.length < 1">
                  <img class="img-thumbnail" alt="Facade image" [src]='properties[0].image' *ngIf=" properties.length > 0 && properties[0].image" />
                </div>
              </div>
              <div class="col text-ident">
                <h5 [hidden]="!building.region"> {{'ONE_CLICK_PAGE.CADASTRE_DATA.PROVINCE' | translate}}: {{ building.region }} </h5>
                <p> <img src="../../../assets/img/icons/location_icon.svg" alt="location" style="width: 20px"> <strong>&nbsp;{{building.address}}</strong>
                </p>
              </div>
            </div>
          </div>
          <div class="container cadastreInfo">
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.surface">
                <span class="text-bold"><img src="../../../assets/img/icons/square_meters_icon.svg" alt="Surface" style="width: 25px!important;">&nbsp;{{building.surface}}&nbsp; m<sup>2</sup> </span>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.year" style="margin-left: 25px;">
                <span class="text-bold"><img src="../../../assets/img/icons/year_icon.svg" alt="year" style="width: 25px;">&nbsp;{{building.year}}</span>
              </div>

              <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'FR'" style="margin-left: 25px;">
                Country: <span class="text-bold">France</span>
              </div>

              <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'IT'" style="margin-left: 25px;">
                Country: <span class="text-bold">Italy</span>
              </div>

              <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'SI'" style="margin-left: 25px;">
                Country: <span class="text-bold">Slovenia</span>
              </div>

              <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'BG'" style="margin-left: 25px;">
                Country: <span class="text-bold">Bulgary</span>
              </div>

              <div class="p-2 flex-fill bd-highlight" *ngIf="building.country === 'GR'" style="margin-left: 25px;">
                Country: <span class="text-bold">Greece</span>
              </div>

              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'FR.N'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</span>
              </div>

              <!--Italy-->
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'IT.MidClim'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">Middle Climatic Zone (Zone E)</span>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'IT.Alpine'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">Alpine Zone (Zone F)</span>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'IT.Mediterranean'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">Mediterranean Zone</span>
              </div>

              <!--Bulgary-->
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'BG.N'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</span>
              </div>

              <!--Slovenia-->
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'SI.N'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</span>
              </div>

              <!--Greece-->
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'GR.ZoneA'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">Climatic zone A</span>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'GR.ZoneB'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">Climatic zone B</span>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'GR.ZoneC'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">Climatic zone C</span>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'GR.ZoneD'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">Climatic zone D</span>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.climateZone === 'GR.N'" style="margin-left: 25px;">
                {{'ONE_CLICK_PAGE.SCORE_BUILDING.CLIMATE_REGION' | translate}}: <span class="text-bold">{{'ONE_CLICK_PAGE.SCORE_BUILDING.TYPE_CLIMATE_REGION_FRANCE' | translate}}</span>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.rc">
                <p> {{'ONE_CLICK_PAGE.CADASTRE_DATA.CADASTRE_REFERENCE' | translate}}:</p>
                <p><strong> {{building.rc}}</strong></p>
              </div>
              <div class="p-2 flex-fill bd-highlight" *ngIf="building.use">
                <p>{{'ONE_CLICK_PAGE.CADASTRE_DATA.PRIMARY_USE' | translate}}:</p>
                <p><strong> {{'ONE_CLICK_PAGE.PRIMARY_USE' | translate}}</strong></p>
              </div>
            </div>
            <div class="col-md-6" [hidden]="!selectYear">
              <label for="selectYear">{{'ONE_CLICK_PAGE.SELECT_YEAR' | translate}}</label>
              <ng-select id="selectYear" [(ngModel)]="selectedYear" [items]="years" (change)="selectYearOption();" [bindValue]="selectedYear"></ng-select>
            </div>
          </div>
          <div class="container calculating">
              <h5 class="title-calc">{{'ONE_CLICK_PAGE.CADASTRE_DATA.TITLE_PARAGRAPH' | translate}}</h5>
              <p class="paragraph-calc">{{'ONE_CLICK_PAGE.CADASTRE_DATA.PARAGRAPH_CALCULATING' | translate}}</p>
          </div>
          <div class="d-flex flex-row bd-highlight">
            <button class="calculateEnergy btn btn-large"  role="button" (click)="calculateTypology();" [disabled]="!selectBuilding">
              <!--<i class="fa fa-battery-full fa-4x" aria-hidden="true" style="width: 70px; height: 40px;"></i>-->
              <img src="../../../assets/img/icons/energy_cost_button.png" style="width: 45px; height: 29px;">&nbsp;&nbsp; <span class="align-middle"><strong> {{'ONE_CLICK_PAGE.CADASTRE_DATA.BUTTON_CALCULATING' | translate}}  &#62;</strong></span>
            </button>
          </div>
          </div>
        </div>

      <!--div [hidden]="properties.length < 1" class="seeMore">
        <div  class="cursor-pointer flex justify-center items-center h-16" role="button" data-toggle="collapse" data-target="#propertiesInfo">
          <div class="arrowFromLeft">
            <svg xmlns="http://www.w3.org/2000/svg" width="5.002" height="8.576" viewBox="0 0 5.002 8.576">
              <path d="M1.06,8.428a.5.5,0,0,1-.71,0l-.2-.2A.49.49,0,0,1,0,7.868V.708A.49.49,0,0,1,.15.348l.2-.2a.5.5,0,0,1,.71,0l3.79,3.79a.48.48,0,0,1,0,.7Z"></path>
            </svg><span>See Properties</span>
          </div>
        </div>
      </div-->
      <!--div class="collapse" id="propertiesInfo">
        <div style="text-align: right">
          <a class="btn filterButton" data-bs-toggle="collapse" href="#" data-toggle="collapse" data-target="#filters">
        <span>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </span>Filters</a>
        </div>

        <div class="collapse card filtersBuilding" id="filters">
          <div class="card-header"> <h4>Filters </h4> </div>
          <div  fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between center" class="card-body">
            <div fxLayout="row" fxLayoutAlign="space-evenly center" >
              <label for="bl">Block</label>
              <input id="bl" type="text" class="form-control"  [(ngModel)]="modelFilters.filtBl" (keyup)="filterBuilding()" >

              <label for="es">Stairs</label>
              <input id ="es" type="text" class="form-control"  [(ngModel)]="modelFilters.filtEs" (keyup)="filterBuilding()" >
            </div>

            <div fxLayout="row" fxLayoutAlign="space-evenly center" >
              <label for="pt">Plant</label>
              <input id ="pt" type="text" class="form-control" [(ngModel)]="modelFilters.filtPt" (keyup)="filterBuilding()" >

              <label for="pu">Door</label>
              <input id ="pu" type="text" class="form-control" [(ngModel)]="modelFilters.filtPu" (keyup)="filterBuilding()" >
            </div>
            <button (click)="clearFilters();">Clear filters</button>
          </div>
        </div>
        <ul class="listBuild">
          <li  *ngFor="let mk of propertiesFilter">
            <h5> {{mk.locInt}} </h5>
            <p style="padding-left: 0">Cadastre reference: {{mk.rc}}</p>
            <div style="text-align: right">
              <button (click)="this.searchFromHistory = false; getDetailFromRC(mk.rc);" > More information > </button>
            </div>
          </li>
        </ul>
      </div-->
  </div>
  </div>
</div>
<ngx-spinner [fullScreen]="false" type="ball-spin-clockwise" [color]="spinnerColor" size="medium">
</ngx-spinner>
<div *ngIf="hasError">
  <div class="container">
    <h6> {{error}}</h6>
  </div>
</div>


<h1>My articles</h1>
<div *ngIf="window == 1" class="form-group row">
  <table class="table table-hover" id="tableToolUpdate" name="tableTool">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Title</th>
        <th scope="col">Country</th>
        <th scope="col">Category</th>
        <th scope="col">Short description</th>
        <th scope="col">Status</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let article of tmpArrayArticle | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; index as i">
        <td>{{article.article_name}}</td>
        <td>{{article.country.country_code}}</td>
        <td>{{article.category_article.name}}</td>
        <td>{{article.short_description}}</td>
        <td>{{article.articles_status.name}}</td>
        <td>
          <button class="btn btn-light" (click)="openModalArticles(article)">
            <i class="fa fa-solid fa-eye"></i>
          </button>
          <button *ngIf="article.articles_status_id == 2" class="btn btn-light" (click)="toUpdateArticle(article)">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          </button>
          <button *ngIf="article.articles_status_id == 2" class="btn btn-light" (click)="deleteArticle(article)">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="tmpArrayArticle.length"></ngb-pagination>
</div>


<div *ngIf="window == 2">
  <br>
  <button class="btn btn-primary" (click)="toTable()"> <- Articles table</button>
  <br>
  <form>

    <div *ngIf="adminComment != null && adminComment != ''">
      <label for="adminComment">Admin comment</label>
      <p>{{adminComment}}</p>
    </div>

    <label for="name">Article name <span class="obligatory">*</span></label>
    <input type="text" id="name" class="form-control" placeholder="Write a name for the article" [(ngModel)]="valueName" name="name" required><br>

    <label for="country">Country of origin of the article: <span class="obligatory">*</span></label>
    <ng-select
      [multiple]="false"
      [closeOnSelect]="true"
      [searchable]="true"
      bindLabel="name"
      placeholder="Select country"
      [(ngModel)]="valueCountry"
      id="articlecountry"
      name="articlecountry"
      [required]="true">
      <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
    </ng-select><br>

    <label for="category">Article category: <span class="obligatory">*</span></label>
    <ng-select
      [multiple]="false"
      [closeOnSelect]="true"
      [searchable]="true"
      bindLabel="name"
      placeholder="Select category"
      [(ngModel)]="valueCategory"
      id="articlecategory"
      name="articlecategory"
      [required]="true">
      <ng-option *ngFor="let category of arrayCategoryArticle" [value]="category.id">{{category.name}}</ng-option>
    </ng-select><br>

    <label for="date">Publication date: <span class="obligatory">*</span></label>
    <input type="date" id="date" class="form-control" [(ngModel)]="valueDate" name="date"><br>

    <label for="author">Author: <span class="obligatory">*</span></label>
    <input type="text" id="author" class="form-control" placeholder="Name of the author" [(ngModel)]="valueAuthor" name="author"><br>

    <label for="short_desc">Article summary (255): <span class="obligatory">*</span></label><br>
    <textarea id="short_desc" name="short_desc" class="form-control" placeholder="Write the sort description" rows="4" cols="50" [(ngModel)]="valueShortDescription" (ngModelChange)="shortDescriptionChangeEvent()"></textarea>
    <div>{{lengthShortDescription}}/255</div><br>

    <label for="long_desc">Long Description (5000): <span class="obligatory">*</span></label><br>
    <textarea id="long_desc" name="long_desc" class="form-control" placeholder="Write the long description" rows="4" cols="50" [(ngModel)]="valueLongDescription" (ngModelChange)="longDescriptionChangeEvent()"></textarea>
    <div>{{lengthLongDescription}}/5000</div><br>

    <label for="link">Link:</label>
    <input type="text" id="link" class="form-control" placeholder="web url" [(ngModel)]="valueLink" name="link"><br>

    <label>Multimedia information</label>
    <div class="form-group">
      <p>You have got already a Main image: {{nameMainImage}}. Do you want to change it?</p>
      <label for="images" class="form-label">Main Image <span class="obligatory">*</span></label>
      <input class="form-control" type="file" id="images" name="images" (change)="fileImageChangeEvent($event)">
    </div>

    <div>
      <label>Do you have more multimedia resources? <span class="obligatory">*</span></label>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="isMoreMultimedia">
        <label class="form-check-label" for="flexRadioMonitoring1">
          Yes
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="flexRadioMonitoring" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="isMoreMultimedia">
        <label class="form-check-label" for="flexRadioMonitoring2">
          No
        </label>
      </div>
    </div>

    <br>

    <div *ngIf="isMoreMultimedia == true">
      <label for="images" class="form-label">Multimedia resources</label>

      <div class="form-group">
        <p>You have got already some images: {{nameAdditionalImages}}</p>
        <label for="images" class="form-label">Other images (max 5)</label>
        <input class="form-control" type="file" id="images" name="images" (change)="fileOtherImageChangeEvent($event)" multiple>
      </div>

      <div class="form-group">
        <label for="name">Video</label>
        <input type="text" class="form-control" id="video" name="video" placeholder="Video URL" [(ngModel)]="valueVideoURL">
      </div>

      <div class="form-group">
        <p>You have got already some PDF FILES: {{nameAdditionalDocuments}}</p>
        <label for="formFile" class="form-label">Documents (.pdf, max 3)</label>
        <input class="form-control" type="file" name="documents" (change)="fileChangeEvent($event)" multiple>
      </div>
    </div>

    <button (click)="updateArticle()" class="btn btn-primary">Update article</button>

  </form>
</div>



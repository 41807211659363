import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdministrationPanelService } from 'src/app/core/binding-data/administration-panel/administration-panel.service';

@Injectable({
  providedIn: 'root'
})
export class CheckLoginGuard implements CanActivate {

  constructor(private administrationPanelService: AdministrationPanelService, private router: Router){}

  canActivate(): boolean {

    let boolean = null;

    this.administrationPanelService.getRoleCurrentUser$().subscribe((data) => {
      console.log('login guard -> ', data);
      if(data == null){
        this.router.navigate(['/', 'home'])
        boolean = false;
      }else{
        boolean = true;
      }
    }).unsubscribe();

    return boolean;
  }

}

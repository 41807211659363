<div style="border-radius: 22px; padding-top: 50px;">
  <div class="card card-saved-properties" style="border-radius: 22px; border: none;">
    <div class="card-header header-principal" id="propertiesSaved">
      <h2 class="mb-0">
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-link btn-block align-items-center d-flex justify-content-center button-saved-properties" type="button" data-toggle="collapse" data-target="#properties" aria-expanded="false" aria-controls="properties">
              <h3>{{"BEST_PRACTICES.SAVED_PROPERTIES.TITLE_PROPOSED_STORIES" | translate}}</h3>
            </button>
          </div>
          <div class="col-md-2">

          </div>
          <div class="col-md-4">
            <img src="../../../assets/img/buttons/saved_botton.png"> &nbsp; <span class="number-saved-properties">{{ceeBuilding.length}}</span>
          </div>
        </div>
      </h2>
    </div>

    <div id="properties" class="collapse show" aria-labelledby="propertiesSaved">
      <div class="card-body">
        <div class="card card-building justify-content-center" *ngFor="let building of tmpCeeBuilding | filter: searchText; index as i">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <img *ngIf="building.facade_picture !== null" src="{{building.facade_picture}}" width="150" height="150">

                <!--Typology AB-->
                <img *ngIf="building.facade_picture === null && building.country === 'ES' && building.tipology_id === 'AB'" src="../../../assets/img/typology/ES/ES.ME.AB.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'FR' && building.tipology_id === 'AB'" src="../../../assets/img/typology/FR/FR.N.AB.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'IT' && building.tipology_id === 'AB'" src="../../../assets/img/typology/IT/IT.MidClim.AB.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'SI' && building.tipology_id === 'AB'" src="../../../assets/img/typology/SI/SI.N.AB.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'BG' && building.tipology_id === 'AB'" src="../../../assets/img/typology/BG/BG.N.AB.03.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'GR' && building.tipology_id === 'AB'" src="../../../assets/img/typology/GR/GR.ZoneA.SFH.02.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'NL' && building.tipology_id === 'AB'" src="../../../assets/img/typology/NL/NL.N.AB.01.gal.001.jpg" width="150" height="150">

                <!--Typology MFH-->
                <img *ngIf="building.facade_picture === null && building.country === 'ES' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/ES/ES.ME.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'FR' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/FR/FR.N.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'IT' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/IT/IT.MidClim.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'SI' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/SI/SI.N.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'BG' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/BG/BG.N.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'GR' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/GR/GR.ZoneA.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'NL' && building.tipology_id === 'MFH'" src="../../../assets/img/typology/NL/NL.N.MFH.01.Gen.ReEx.001.jpg" width="150" height="150">

                <!--Typology SFH-->
                <img *ngIf="building.facade_picture === null && building.country === 'ES' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/ES/ES.ME.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'FR' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/FR/FR.N.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'IT' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/IT/IT.MidClim.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'SI' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/SI/SI.N.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'BG' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/BG/BG.N.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'GR' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/GR/GR.ZoneA.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'NL' && building.tipology_id === 'SFH'" src="../../../assets/img/typology/NL/NL.N.SFH.01.Gen.ReEx.001.jpg" width="150" height="150">

                <!--Typology TH-->
                <img *ngIf="building.facade_picture === null && building.country === 'ES' && building.tipology_id === 'TH'" src="../../../assets/img/typology/ES/ES.ME.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'FR' && building.tipology_id === 'TH'" src="../../../assets/img/typology/FR/FR.N.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'IT' && building.tipology_id === 'TH'" src="../../../assets/img/typology/IT/IT.MidClim.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'SI' && building.tipology_id === 'TH'" src="../../../assets/img/typology/SI/SI.N.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'BG' && building.tipology_id === 'TH'" src="../../../assets/img/typology/BG/BG.N.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'GR' && building.tipology_id === 'TH'" src="../../../assets/img/typology/GR/GR.ZoneD.SFH.03.Gen.ReEx.001.jpg" width="150" height="150">
                <img *ngIf="building.facade_picture === null && building.country === 'NL' && building.tipology_id === 'TH'" src="../../../assets/img/typology/NL/NL.N.TH.01.Gen.ReEx.001.jpg" width="150" height="150">
              </div>
              <div class="col-md-5">
                <div>
                  <button class="button-link" (click)="showBuildingResults(building);" >{{building.map_address}}</button>
                </div><br>
                <div *ngIf="building.tipology_id === 'AB'">
                  <img src="../../../assets/img/icons/apartment_block_icon.svg"> Apartment Block
                </div>
                <div *ngIf="building.tipology_id === 'MFH'">
                  <img src="../../../assets/img/icons/multy_family_icon.svg"> Multi Family Home
                </div>
                <div *ngIf="building.tipology_id === 'SFH'">
                  <img src="../../../assets/img/icons/single_family_icon.svg"> Single Family Home
                </div>
                <div *ngIf="building.tipology_id === 'TH'">
                  <img src="../../../assets/img/icons/terraced_house_icon.svg"> Terraced House
                </div>

                <br>

                <div *ngIf="building.case_id === 1">
                  <img src="../../../assets/img/cee-building-data/icon.PNG">
                  Complete Building
                </div>

                <div *ngIf="building.case_id === 2">
                  <img src="../../../assets/img/cee-building-data/icon.PNG">
                  Single Home
                </div>

                <br>

                <div *ngIf="building.year !== null">
                  <img src="../../../assets/img/icons/year_icon.svg">
                  {{building.year}}
                </div>

                <div *ngIf="building.status_id == 3" style="color: blue;">
                  Proposed building
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {GlobalConstants} from '../../shared/GlobalConstants';
import {HttpClient} from '@angular/common/http';
import { Experience } from 'src/app/shared/models/experience';
import { id } from '@swimlane/ngx-charts';

@Injectable({
  providedIn: 'root'
})
export class FormProService {

  private baseUrl = GlobalConstants.backendURL + '/api/userExtension';
  private baseUrlActivityFormPro = GlobalConstants.backendURL + '/api/activityFormPro';
  private baseUrlSpecialization = GlobalConstants.backendURL + '/api/specialization';
  private baseUrlCertification = GlobalConstants.backendURL + '/api/certification';

  private baseUrlSuggestion = GlobalConstants.backendURL + '/api/suggestion';
  private baseUrlSuggestionImages = GlobalConstants.backendURL + '/api/suggestionImages';

  constructor(private http: HttpClient) { }

  updateToPro(
    uid: string,
    user_extension_id: number,
    role_id: number,
    officialName: string,
    lastName: string,
    phoneNumber: string,
    webPage: string,
    activityFormPro_id: number,
    fileProfileImage: any,
    fileDossier: any,
    specialization_id: number[],
    arrayExperience: Experience[],
    prefix: string,
    description: string
  ){
    let body = {
      uid: uid,
      user_extension_id: user_extension_id,
      role_id: role_id,
      officialName: officialName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      webPage: webPage,
      activityFormPro_id: activityFormPro_id,
      specialization_id: specialization_id,
      arrayExperience: arrayExperience,
      prefix: prefix,
      description: description
    }

   console.log(body)

    let formData = new FormData();

    formData.append('data', JSON.stringify(body));
    formData.append('profileImage', fileProfileImage, fileProfileImage.name);
    if(fileDossier != null) formData.append('dossier', fileDossier, fileDossier.name);

    return this.http.post(this.baseUrl + '/updateToPro', formData);
  }

  updateFormProBasicData(
    user_extension_id: number,
    officialName: string,
    lastName: string,
    phoneNumber: string,
    webPage: string,
    activityFormPro_id: number,
    prefix: string,
    description: string
  ){

    let body = {
      user_extension_id: user_extension_id,
      officialName: officialName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      webPage: webPage,
      activityFormPro_id: activityFormPro_id,
      prefix: prefix,
      description: description
    }

    return this.http.put(this.baseUrl + '/update/form', body);
  }

  updateProfileImage(file: any, uid: string, idUserExtension: number, oldProfileImage: string){

    let formData = new FormData();

    formData.append('profileImage', file, file.name);
    formData.append('idUser', uid);
    formData.append('idUserExtension', idUserExtension + '');
    formData.append('oldProfileImage', oldProfileImage);

    return this.http.put(this.baseUrl + '/update/profileImage', formData);
  }

  updateDossier(file: any, uid: string, idUserExtension: number, oldDossier: string){

    let formData = new FormData();

    formData.append('dossier', file, file.name);
    formData.append('idUser', uid);
    formData.append('idUserExtension', idUserExtension + '');
    formData.append('oldDossier', oldDossier);

    return this.http.put(this.baseUrl + '/update/dossier', formData);
  }

  updateExperience(arrayExperience: Experience[], user_id: string){

    let body = {
      arrayExperience: arrayExperience,
      user_id: user_id
    }

    return this.http.put(this.baseUrl + '/update/experience', body);
  }

  createCertification(user_id: string, tool_id: number, otherName: string, fileCertification: any){
    let body = {
      user_id: user_id,
      tool_id: tool_id,
      otherName: otherName
    }

    let formData = new FormData();

    formData.append('data', JSON.stringify(body));
    formData.append('certification', fileCertification, fileCertification.name);

    return this.http.post(this.baseUrlCertification, formData);
  }

  deleteCertification(user_id: string){
    return this.http.delete(this.baseUrlCertification + '/' + user_id);
  }

  deleteCertificationById(user_id: string, idCertification: number){
    return this.http.delete(this.baseUrlCertification + '/' + user_id + '/' + idCertification);
  }

  updateWithFile(id: number, user_id: string, tool_id: number, otherName: string, fileCertification: any, pathOldCerficate: string){

    let body = {
      id: id,
      user_id: user_id,
      tool_id: tool_id,
      otherName: otherName,
      pathOldCerficate: pathOldCerficate
    }

    let formData = new FormData();

    formData.append('data', JSON.stringify(body));
    formData.append('certification', fileCertification, fileCertification.name);

    return this.http.put(this.baseUrlCertification, formData);

  }

  updateWithoutFile(id: number, user_id: string, tool_id: number, name: string){

    let body = {
      id: id,
      user_id: user_id,
      tool_id: tool_id,
      name: name,
    }

    return this.http.put(this.baseUrlCertification + '/withoutFile', body);

  }

  getActivityFormPro() {
    return this.http.get(this.baseUrlActivityFormPro);
  }

  getSpecialization() {
    return this.http.get(this.baseUrlSpecialization);
  }

  //suggestion functions

  getAllSuggestion(username: string, subject: string, status: number, limit: number, offset: number){
    return this.http.post(this.baseUrlSuggestion, {username: username, subject: subject, status_id: status, limit: limit, offset: offset});
  }

  getSuggestionById(id: number){
    return this.http.get(this.baseUrlSuggestion + '/id/' + id);
  }

  getSuggestionByUserId(id: string){
    return this.http.get(this.baseUrlSuggestion + '/user/' + id);
  }

  getAllSuggestionStatus(){
    return this.http.get(this.baseUrlSuggestion + '/status');
  }

  createSuggestion(subject: string, suggestion: string, video_url: string, user_id: string){
    return this.http.post(this.baseUrlSuggestion + '/create', {subject: subject, suggestion: suggestion, video_url: video_url, user_id: user_id});
  }

  createImageSuggestion(suggestion_id: string, image: any){

    let imagesAdditional = new FormData();

    imagesAdditional.append('suggestion_id', suggestion_id);
    imagesAdditional.append('image', image, image.name);

    return this.http.post(this.baseUrlSuggestionImages, imagesAdditional);
  }

  updateStatus(idSuggestion: number, status_id: number, commentary?: string){
    console.log(idSuggestion, status_id, commentary);

    return this.http.put(this.baseUrlSuggestion, {id: idSuggestion, status_id: status_id, commentary: commentary});
  }

  deleteSuggestion(idSuggestion: number){
    return this.http.delete(this.baseUrlSuggestion + '/id/' + idSuggestion);
  }
}
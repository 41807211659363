<div class="landing">
    <div class="country-section">
      <section class="">
          <div style="width: 40%; float: left; padding-left: 5%;">
            <div class="content-text-country">
              <h1 class="landing-title">
                {{'HOME_PAGE.TITLE' | translate}}
              </h1>
              <p class="description-title" [innerHTML]="'HOME_PAGE.DESCRIPTION' | translate">
              </p>
            </div>
            <div>
              <button type="button" class="btn btn-primary me-3 button-one-click" (click)="goToOneClick2()">
                {{'HOME_PAGE.BUTTON_FORM_IMPROVE_GO_TO_ONE_CLICK' | translate}}
              </button>
              <button type="button" class="btn btn-primary me-3 button-success-stories" style="margin-left: 10px;" (click)="goToTheBotton()">
                {{'HOME_PAGE.BUTTON_BEST_PRACTICES2' | translate}}
              </button>
            </div>
          </div>
      </section>
        <section>
          <div style="width: 50%; float: right; background-color: #DADEE2 !important;">
            <img *ngIf="currentUser && currentUser.country != 26 && currentUser.country != 25" src="../../../assets/img/logos/reModulees.PNG" class="image-responsive" height="400" width="auto">
            <img *ngIf="currentUser && currentUser.country == 25" src="../../../assets/img/personalization/SI/EnSvet.png" class="image-responsive" height="400" width="900">
            <img *ngIf="currentUser && currentUser.country == 26" src="../../../assets/img/personalization/ES/reM_OneClick.png" class="image-responsive" height="400" width="auto">
            <img *ngIf="!currentUser" src="../../../assets/img/logos/reModulees.PNG" class="image-responsive" height="400" width="auto">
          </div>
        </section>
    </div>
    <div class="tools-section">
      <div class="">
        <div>
          <h2 class="tools-title">
            <strong>{{"TOOLS_HOME_PAGE.TITLE" | translate}}</strong>
          </h2>
          {{"TOOLS_HOME_PAGE.PARAGRAPH" | translate}}

          <button type="button" class="btn btn-primary me-3 boton" (click)="goToTools()">
            {{"TOOLS_HOME_PAGE.BUTTON_TOOLS" | translate}}
          </button>
        </div>

        <div class="tools-title-recently-added">
          <h2>
            <strong>{{"TOOLS_HOME_PAGE.RECENTLY_ADDED" | translate}}</strong>
          </h2>
        </div>

        <div class="separador">
          <div class="row">
            <div class="col-md-2" [@fadeInOut] *ngFor="let tool of arrayToolMostRecent | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index" [ngClass]="{'active': i == 0 }">
              <div class="card card-tool justify-content-center">
                <div class="card-header"><ngb-rating class="estrellas" [max]="5" [readonly]="false"></ngb-rating>
                  <div class="cosa">
                    <button type="button" class="btn btn-default row boton-guardar" (click)="saveTool(tool)">
                      {{"TOOLS_HOME_PAGE.BUTTON_SAVE" | translate}}
                        <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
                    </button>
                  </div>
                </div>
                <div *ngIf="tool.main_image != null" class="image-container">
                  <img src="{{imageUrl}}/mainImage/{{tool.uid}}/{{tool.main_image}}" height="auto" width="auto" class="card-img-top">
                </div>
                <div *ngIf="tool.main_image == null" class="image-container">
                  <img src="./assets/img/tools/camera-icon.jpeg" height="auto" width="auto" class="card-img-top">
                </div>
                <div class="card-body">
                  <!--<p style="width: 75px; color: #004470;">{{tool.stops[0]}}</p>-->
                  <a href="#/home" (click)="openModal(tool);" *ngIf="!currentUser || currentUser.country != tool.country_origin || !tool.app_name_origin"><h5>{{tool.app_name}}</h5></a>
                  <a href="#/home" (click)="openModal(tool);" *ngIf="currentUser != null && currentUser.country == tool.country_origin"><h5>{{tool.app_name_origin}}</h5></a>
                  <p class="short_description_tools" *ngIf="!currentUser || currentUser.country != tool.country_origin || !tool.short_description_origin">{{tool.short_description}}</p>
                  <p class="short_description_tools" *ngIf="currentUser != null && currentUser.country == tool.country_origin">{{tool.short_description_origin}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex justify-content-center">
              <!--First-->
              <button class="button-first" (click)="pagination('recent', 'first')" [disabled]="pageMostRecent == 1">First</button>
              <!--Previous-->
              <button class="button-previous" (click)="pagination('recent', 'previous')" [disabled]="pageMostRecent == 1">Previous</button>
              <!--Next-->
              <button class="button-next" (click)="pagination('recent', 'next')" [disabled]="pageMostRecent === getLastNumberPageMostRecent()">Next</button>
              <!--Last-->
              <button class="button-last" (click)="pagination('recent', 'last')" [disabled]="pageMostRecent === getLastNumberPageMostRecent()">Last</button>
            </div>
          </div>
        </div>

        <div class="tools-title-most-visited">
          <h2>
            <strong>{{"TOOLS_HOME_PAGE.MOST_VISITED" | translate}}</strong>
          </h2>
        </div>

        <div class="separador">
          <div class="row">
            <div class="col-md-2" [@fadeInOut] *ngFor="let tool of arrayToolMostVisited; let i = index" [ngClass]="{'active': i == 0 }">
              <div class="card card-tool justify-content-center" >
                <div class="card-header"><ngb-rating class="estrellas" [max]="5" [readonly]="false"></ngb-rating>
                <div class="cosa">
                  <button type="button" class="btn btn-default row boton-guardar" (click)="saveTool(tool)">
                    {{"TOOLS_HOME_PAGE.BUTTON_SAVE" | translate}}
                      <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>
              <div *ngIf="tool.main_image != null" class="image-container">
                <img src="{{imageUrl}}/mainImage/{{tool.uid}}/{{tool.main_image}}" height="auto" width="auto" class="card-img-top">
              </div>
              <div *ngIf="tool.main_image == null" class="image-container">
                <img src="./assets/img/tools/camera-icon.jpeg" height="auto" width="auto" class="card-img-top">
              </div>
                <div class="card-body">
                  <!--<p style="width: 75px; color: #004470;">{{tool.stops[0]}}</p>-->
                  <a href="#/home" (click)="openModal(tool);" *ngIf="!currentUser || currentUser.country != tool.country_origin || !tool.app_name_origin"><h5>{{tool.app_name}}</h5></a>
                  <a href="#/home" (click)="openModal(tool);" *ngIf="currentUser != null && currentUser.country == tool.country_origin"><h5>{{tool.app_name_origin}}</h5></a>
                  <p class="short_description_tools" *ngIf="!currentUser || currentUser.country != tool.country_origin || !tool.short_description_origin">{{tool.short_description}}</p>
                  <p class="short_description_tools" *ngIf="currentUser != null && currentUser.country == tool.country_origin">{{tool.short_description_origin}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex justify-content-center">
              <!--First-->
              <button class="button-first" (click)="pagination('visited', 'first')" [disabled]="pageMostVisited == 1">First</button>
              <!--Previous-->
              <button class="button-previous" (click)="pagination('visited', 'previous')" [disabled]="pageMostVisited == 1">Previous</button>
              <!--Next-->
              <button class="button-next" (click)="pagination('visited', 'next')" [disabled]="pageMostVisited === getLastNumberPageMostVisited()">Next</button>
              <!--Last-->
              <button class="button-last" (click)="pagination('visited', 'last')" [disabled]="pageMostVisited === getLastNumberPageMostVisited()">Last</button>
            </div>
          </div>
        </div>

        <!--<div class="tools-title-most-visited">
          <h2>
            <strong>{{"TOOLS_HOME_PAGE.POPULAR_IN_SPAIN" | translate}}</strong>
          </h2>
        </div>

        <div class="separador">
          <div class="row">
            <div class="col-md-2" [@fadeInOut] *ngFor="let tool of getCurrentPageObjectsPopularSpain() | filter: 'Spain' | slice: (pagePopularSpain-1) * pageSizePopularSpain : (pagePopularSpain-1) * pageSizePopularSpain + pageSizePopularSpain; let i = index" [ngClass]="{'active': i == 0}">
              <div class="card card-tool justify-content-center" >
                <div class="card-header"><ngb-rating class="estrellas" [max]="5" [readonly]="false"></ngb-rating>
                <div class="cosa">
                  <button type="button" class="btn btn-default row boton-guardar" (click)="saveTool(tool)">
                    {{"TOOLS_HOME_PAGE.BUTTON_SAVE" | translate}}
                      <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>
              <div *ngIf="tool.main_image != null" class="image-container">
                <img src="{{imageUrl}}/mainImage/{{tool.uid}}/{{tool.main_image}}" height="auto" width="auto" class="card-img-top">
              </div>
              <div *ngIf="tool.main_image == null" class="image-container">
                <img src="./assets/img/tools/camera-icon.jpeg" height="auto" width="auto" class="card-img-top">
              </div>
                <div class="card-body">
                  <a href="#/home" (click)="openModal(tool);"><h5>{{tool.app_name}}</h5></a>
                  <p class="short_description_tools">{{tool.short_description}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex justify-content-center">
              <button class="button-first" (click)="goToPagePopularSpain(1)" [disabled]="currentPagePopularSpain === 1">First</button>

              <button class="button-previous" (click)="goToPagePopularSpain(currentPagePopularSpain - 1)" [disabled]="currentPagePopularSpain === 1">Previous</button>

              <button *ngFor="let page of getPageNumbersPopularSpain()" (click)="goToPagePopularSpain(page)" [disabled]="currentPagePopularSpain === page" style="display: none;">{{ page }}</button>

              <button class="button-next" (click)="goToPagePopularSpain(currentPagePopularSpain + 1)" [disabled]="currentPagePopularSpain === totalPagesPopularSpain">Next</button>

              <button class="button-last" (click)="goToPagePopularSpain(totalPagesPopularSpain)" [disabled]="currentPagePopularSpain === totalPagesPopularSpain">Last</button>
            </div>
          </div>
        </div>-->

        <div class="tools-title-most-visited">
          <h2>
            <strong>{{"TOOLS_HOME_PAGE.POPULAR_IN_EUROPE" | translate}}</strong>
          </h2>
        </div>

        <div class="separador">
          <div class="row">
            <div class="col-md-2" [@fadeInOut] *ngFor="let tool of arrayToolMostRated; let i = index" [ngClass]="{'active': i == 0}">
              <div class="card card-tool justify-content-center" >
                <div class="card-header"><ngb-rating class="estrellas" [max]="5" [readonly]="false"></ngb-rating>
                <div class="cosa">
                  <button type="button" class="btn btn-default row boton-guardar" (click)="saveTool(tool)">
                    {{"TOOLS_HOME_PAGE.BUTTON_SAVE" | translate}}
                      <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>
              <div *ngIf="tool.main_image != null" class="image-container">
                <img src="{{imageUrl}}/mainImage/{{tool.uid}}/{{tool.main_image}}" height="auto" width="auto" class="card-img-top">
              </div>
              <div *ngIf="tool.main_image == null" class="image-container">
                <img src="./assets/img/tools/camera-icon.jpeg" height="auto" width="auto" class="card-img-top">
              </div>
                <div class="card-body">
                  <!--<p style="width: 75px; color: #004470;">{{tool.stops[0]}}</p>-->
                  <a href="#/home" (click)="openModal(tool);" *ngIf="!currentUser || currentUser.country != tool.country_origin || !tool.app_name_origin"><h5>{{tool.app_name}}</h5></a>
                  <a href="#/home" (click)="openModal(tool);" *ngIf="currentUser != null && currentUser.country == tool.country_origin"><h5>{{tool.app_name_origin}}</h5></a>
                  <p class="short_description_tools" *ngIf="!currentUser || currentUser.country != tool.country_origin || !tool.short_description_origin">{{tool.short_description}}</p>
                  <p class="short_description_tools" *ngIf="currentUser != null && currentUser.country == tool.country_origin">{{tool.short_description_origin}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex justify-content-center">
              <button class="button-first" (click)="pagination('rated', 'first')" [disabled]="pageMostRated == 1">First</button>
              <button class="button-previous" (click)="pagination('rated', 'previous')" [disabled]="pageMostRated == 1">Previous</button>
              <button class="button-next" (click)="pagination('rated', 'next')" [disabled]="pageMostRated === getLastNumberPageMostRated()">Next</button>
              <button class="button-last" (click)="pagination('rated', 'last')" [disabled]="pageMostRated === getLastNumberPageMostRated()">Last</button>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="section-best-practices">
    <section class="">
        <div style="width: 40%; float: left; padding-left: 5%;">
          <h2 class="title-best-practices">
            <strong>{{'HOME_PAGE.BEST_PRACTICES' | translate}}</strong>
          </h2>
          <p class="description-title-bp">
            {{'HOME_PAGE.DESCRIPTION_SUCCESS_STORIES' | translate}}
          </p>
          <div class="button-section-bp">
            <button type="button" class="btn btn-primary me-3 boton-bp" (click)="goToBestPractices()">
              {{"HOME_PAGE.BUTTON_BEST_PRACTICES" | translate}}
            </button>
          </div>
        </div>
    </section>
    <section>
      <div style="width: 50%; float: right; background-color: #DADEE2 !important;">
        <img *ngIf="currentUser && currentUser.country != 26" src="../../../assets/img/logos/SuccessStoriesImage.JPG" class="image-responsive" height="400" width="auto">
        <img *ngIf="currentUser && currentUser.country == 26" src="../../../assets/img/personalization/ES/reM_Stories.png" class="image-responsive" height="400" width="auto">
        <img *ngIf="!currentUser" src="../../../assets/img/logos/SuccessStoriesImage.JPG" class="image-best-practices" height="450" width="auto">
      </div>
    </section>
  </div>
  </div>

import { Component, OnInit } from '@angular/core';
import { CeeService } from 'src/app/core/wms/cee.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  csvFiles: any = null;
  guid: string = 'VDZ7HabBCbgPEwIhC459Aa1OM782';
  idMonitoring: number = 103;

  constructor(private ceeService: CeeService,) { }

  ngOnInit(): void {
  }

  uploadFile(){

    let bodyFile = new FormData();

    bodyFile.append('file', this.csvFiles, this.csvFiles.name);
    bodyFile.append('guid', this.guid);
    bodyFile.append('idMonitoring', this.idMonitoring + '');

    console.log(this.csvFiles)

    this.ceeService.uploadFile2(bodyFile).toPromise().then((data: any) => {
      console.log("response node file -> ", data);
    })
  }

  fileChangeEvent(event: any): boolean{

    let file = event.target.files[0];

    console.log( 'Archivo seleccionado -> ', event.target.files[0]);

    if(file.type.substring(5) == 'csv' || file.type == 'application/vnd.ms-excel'){
      this.csvFiles = file;
      return true;
    }else{
      this.csvFiles = null;
      console.log('esto no es un archivo CSV');
      return false;
    }
  }

  updateFile(){
    let bodyFile = new FormData();

    bodyFile.append('file', this.csvFiles, this.csvFiles.name);
    bodyFile.append('guid', this.guid);
    bodyFile.append('idMonitoring', this.idMonitoring + '');

    console.log(this.csvFiles)

    this.ceeService.updateFile2(bodyFile).toPromise().then((data: any) => {
      console.log("response node file -> ", data);
    })
  }

}

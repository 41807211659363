<div class="container">
  <div class="card card-data-map" style="margin-top: 50px;">
    <div class="card-header header-principal" id="propertiesSaved">
      <h2 class="mb-0">
        <div class="row">
          <div class="col-md-6">
            <button class="btn btn-link btn-block align-items-center d-flex justify-content-center button-data-map" type="button" data-toggle="collapse" data-target="#datamap" aria-expanded="false" aria-controls="datamap">
              <h3>{{'ONE_CLICK_PAGE.DASHBOARD.TITLE' | translate}}</h3>
            </button>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4"></div>
        </div>
      </h2>
    </div>
    <div class="row">
      <div class="col-md-6" [hidden]="true">
        <label for="filterCountry"> {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.COUNTRY' | translate}}: </label>
        <ng-select id="filterCountry" class="filter" [(ngModel)]="countrySelected"
                    (change)="filter('country');">
          <ng-option *ngFor="let country of filterCountries" [value]="country">{{country}}</ng-option>
        </ng-select>
      </div>

      <div class="col-md-6" [hidden]="true">
        <label for="buildingType"> {{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.BUILDING_TYPE' | translate}}: </label>
        <ng-select id="buildingType" class="filter" [(ngModel)]="typologySelected"
                    (change)="filter('typology');">
          <ng-option *ngFor="let typo of filterTypologies" [value]="typo.code">{{typo.name}}</ng-option>
        </ng-select>
      </div>

      <div class="col-md-4" [hidden]="true">
        <label for="filterYearMin">{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.MIN_YEAR' | translate}}: </label>
        <ng-select id="filterYearMin" class="filter" [(ngModel)]="yearSelectedMin"
                   (change)="filter('yearMin');">
          <ng-option *ngFor="let year of filterYears" [value]="year">{{year}}</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4" [hidden]="true">
        <label for="filterYearMax">{{'ONE_CLICK_PAGE.SAVED_PROPERTIES.FILTERS.MAX_YEAR' | translate}}: </label>
        <ng-select id="filterYearMax" class="filter" [(ngModel)]="yearSelectedMax"
                   (change)="filter('yearMax');">
          <ng-option *ngFor="let year of filterYears" [value]="year">{{year}}</ng-option>
        </ng-select>
      </div>

      <!--<div class="col-md-4" style="text-align: right; padding-top: 30px; padding-left: 30px;">
        <button class="btn btn-primary clear-button" type="button">
          Clear
        </button>
      </div>-->
    </div>
    <div id="datamap" class="accordion collapse show">
      <div class="card text-dark bg-light mb-3">
        <div class="card-header">
          <div class="btn-text-left">
            <button class="btn btn-link btn-block d-flex collapsed button-title" type="button" data-toggle="collapse" data-target="#buildingsSaved" aria-expanded="true" aria-controls="buildingsSaved">
              {{'ONE_CLICK_PAGE.DASHBOARD.BUILDINGS_SAVED' | translate}}
            </button>
          </div>
        </div>
        <div id="buildingsSaved" class="collapse show" [ngClass]="" aria-labelledby="buildingsSaved">
          <div class="card-body">
            <div class="row" *ngIf="userHistory">
              <div class="col-md-6">
                <img src="../../../assets/img/buttons/home_botton.png"> {{'ONE_CLICK_PAGE.DASHBOARD.NUMBER_BUILDINGS_SAVED' | translate}}:
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <h4><img src="../../../assets/img/buttons/saved_botton.png"> &nbsp; {{userHistory.length}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="datamap" class="accordion collapse show">
      <div class="card text-dark bg-light mb-3">
        <div class="card-header">
          <button class="btn btn-link btn-block d-flex collapsed  button-title" type="button" data-toggle="collapse" data-target="#typology" aria-expanded="true" aria-controls="typology">
            {{'ONE_CLICK_PAGE.DASHBOARD.TYPOLOGY' | translate}}
          </button>
        </div>
        <div id="typology" class="collapse show" [ngClass]="" aria-labelledby="typology">
          <div class="card-body" *ngIf="chartOptionsTypology">
            <highcharts-chart
              [Highcharts]="typologyChart"
              [options]="chartOptionsTypology" ></highcharts-chart>
          </div>
        </div>
        <!--<div *ngFor="let category of categories">
          <div>
            <i class="fa fa-home fa-lg" aria-hidden="true"></i> {{category}}
          </div>
        </div>-->
      </div>
    </div>

    <div id="datamap" class="accordion collapse show">
      <div class="card text-dark bg-light mb-3">
        <div class="card-header">
          <button class="btn btn-link btn-block d-flex collapsed button-title2" type="button" data-toggle="collapse" data-target="#energyCost" aria-expanded="false" aria-controls="energyCost">
            {{'ONE_CLICK_PAGE.DASHBOARD.ENERGY_COST' | translate}}
          </button>
        </div>
        <div id="energyCost" class="collapse" [ngClass]="" aria-labelledby="energyCost">
          <div class="card-body" *ngIf="chartOptionsEnergy">
            <highcharts-chart
              [Highcharts]="energyChart"
              [options]="chartOptionsEnergy" ></highcharts-chart>
          </div>
        </div>
      </div>
    </div>

    <div id="datamap" class="accordion collapse show">
      <div class="card text-dark bg-light mb-3">
        <div class="card-header">
          <button class="btn btn-link btn-block d-flex collapsed button-title2" type="button" data-toggle="collapse" data-target="#energyRenewable" aria-expanded="false" aria-controls="energyRenewable">
            {{'ONE_CLICK_PAGE.DASHBOARD.RENEWABLE_ENERGY' | translate}}
          </button>
        </div>
        <div id="energyRenewable" class="collapse" [ngClass]="" aria-labelledby="energyRenewable">
          <div class="card-body" *ngIf="chartOptionsPrimaryEnergy">
            <highcharts-chart
              [Highcharts]="primaryEnergyChart"
              [options]="chartOptionsPrimaryEnergy" ></highcharts-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tools-main">
  <!--<div class="container">
    <img alt="Work in progess" src="../../../assets/img/Work-In-Progress.png">
  </div>-->
  <div class="info-section">
    <section class="">
      <div style="width: 40%; float: left; padding-left: 5%;" class="col-md-6 content-text-generator text-justify">
        <h1 class="tools-title">
          {{"TOOLS_PAGE.TITLE_TOOLS_PAGE" | translate}}
        </h1>
        <p style="color: #265F85;">
          {{"TOOLS_PAGE.DESCRIPTION_TOOLS_PAGE" | translate}}
        </p>
      </div>
      </section>
      <section>
      <div style="width: 50%; float: right; background-color: #DADEE2 !important; overflow: auto;" class="col-md-6">
        <img *ngIf="currentUser && currentUser.country != 26" src="./assets/img/tools/SolutionsStoreImage.JPG" class="image-camera-responsive" height="485" width="auto">
        <img *ngIf="currentUser && currentUser.country == 26" src="../../../assets/img/personalization/ES/reM_Solutions.png" class="image-responsive" height="485" width="850">
        <img *ngIf="!currentUser" src="./assets/img/tools/SolutionsStoreImage.JPG" class="image-camera-responsive" height="485" width="auto">
      </div>
    </section>
  </div>

  <div>
    <hr>
  </div>

  <nav ngbNav #nav="ngbNav" class="nav-tabs">
    <ng-container ngbNavItem class="me-2">
      <h5>
        <a href="#tools" ngbNavLink>{{'TOOLS_PAGE.TOOLS.TITLE' | translate}}</a>
      </h5>
      <ng-template ngbNavContent>
        <div class="tools-section">
          <div class="container button-filter-tools">
            <div class="input-group col-md-4 search-box">
              <button class="btn btn-primary boton-filtros boton-filtros-constraints" style="margin-right: 700px; margin-left: -850px;" type="button" data-toggle="collapse" data-target="#collapseConstraints" aria-expanded="false" aria-controls="collapseExample">
                <img src="../../../assets/img/icons/scout.png">&nbsp;{{'TOOLS_PAGE.TOOLS.BUTTON_CONSTRAINTS' | translate}}
              </button>
              <button class="btn btn-primary boton-filtros" type="button" style="margin-right: 5px;" (click)="sortTools()">
                <i class="fa fa-sort" aria-hidden="true"></i>&nbsp;A - Z
              </button>
              <button class="btn btn-primary boton-filtros" type="button" (click)="sortToolsZA()">
                <i class="fa fa-sort" aria-hidden="true"></i>&nbsp;Z - A
              </button>
              &nbsp; &nbsp;
              <input class="form-control" type="text" [(ngModel)]="valueName" (keyup)="onKeySearch($event)" placeholder="{{'TOOLS_PAGE.TOOLS.PLACEHOLDER_SEARCH_TOOL' | translate}}" id="example-search-input">
              &nbsp; &nbsp;
              <button class="btn btn-primary boton-filtros" style="margin-right: -20px;" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                <i class="fa fa-filter" aria-hidden="true"></i>&nbsp;{{'TOOLS_PAGE.TOOLS.BUTTON_FILTERS' | translate}}
              </button>
            </div>
          </div>
          <div class="collapse container" id="collapseConstraints">
            <div class="row justify-content-end2 filters-block" *ngIf="filters">
              <div class="col-1 filters">
                <ng-select id="filterProfiles" class="filter" [(ngModel)]="profileSelected"
                          (change)="getTools(true);" placeholder="{{'FILTERS.PROFILE' | translate}}">
                  <ng-option *ngFor="let profile of profileList" [value]="profile.id">{{profile.profile}}</ng-option>
                </ng-select>
              </div>
              <div class="col-2 filters">
                <ng-select id="filterConstraintsType" class="filter" placeholder="{{'FILTERS.CONSTRAINT_CATEGORY' | translate}}" (change)="getTools(true)" [(ngModel)]="constraintClassSelected">
                  <ng-option *ngFor="let constraint of constraintsTypeList" [value]="constraint.id">{{constraint.constraint_type}}</ng-option>
                </ng-select>
              </div>
              <div class="col-2 filters">
                <ng-select id="filterConstraintsType" class="filter" placeholder="{{'FILTERS.CONSTRAINT_CLASS' | translate}}" (change)="getTools(true)" [(ngModel)]="constraintCategorySelected">
                  <ng-option *ngFor="let constraint of tmpConstrainsCategoryList" [value]="constraint.name">{{constraint.constraint_category}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="collapse container" id="collapseExample">
            <div class="row justify-content-end filters-block" *ngIf="filters">
              <div class="col-1 filters" >
                <ng-select id="filterCountry" class="filter" [(ngModel)]="countrySelected"
                          (change)="getTools(true);" placeholder="{{'FILTERS.COUNTRY' | translate}}">
                          <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
                </ng-select>
              </div>
              <div class="col-2 filters">
                <ng-select id="filterOtherCountries" class="filter" [(ngModel)]="otherCountriesSelected" [closeOnSelect]="false"
                          (change)="getTools(true);" placeholder="Other countries" [multiple]="true">
                  <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
                </ng-select>
              </div>
              <div class="col-2 filters">
                <ng-select id="filterSolutions" class="filter" [(ngModel)]="solutionSelected"
                          (change)="getTools(true);" placeholder="{{'FILTERS.SOLUTION' | translate}}">
                  <ng-option *ngFor="let solution of solutionsList" [value]="solution.id">{{solution.solution}}</ng-option>
                </ng-select>
              </div>
              <div class="col-2 filters">
                <ng-select id="filterStops" class="filter" (change)="getTools(true);" [(ngModel)]="stopSelected" placeholder="{{'FILTERS.STOP' | translate}}">
                  <ng-option *ngFor="let stop of stopsList" [value]="stop.id">{{stop.stop}}</ng-option>
                </ng-select>
              </div>
              <div class="col-2 filters">
                <ng-select id="filterSteps" class="filter" (change)="getTools(true);" [(ngModel)]="stepSelected" placeholder="{{'FILTERS.STEP' | translate}}">
                  <ng-option *ngFor="let step of stepsList" [value]="step.id">{{step.step}}</ng-option>
                </ng-select>
              </div>
              <div class="col-2 filters">
                <ng-select id="filterTipologies" class="filter" [(ngModel)]="typologySelected"
                          (change)="getTools(true);" placeholder="{{'FILTERS.TYPOLOGY' | translate}}" >
                  <ng-option *ngFor="let typology of typologiesList" [value]="typology.id">{{typology.typology}}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>

          <div class="tools row">

            <ng-container *ngFor="let tool of tmpNewTools">
              <div class="card card-tool col-md-2 justify-content-center" *ngIf="tool.toolStatus !== 2">
                <div class="card-header">
                  <div *ngFor="let rate of rateObjects; index as i">
                    <!--<span *ngIf="tool.uid == rate.tool_id">Average rating: {{rate.rate}}</span>-->
                    <span  *ngIf="averageRatings[i] && tool.uid == rate.tool_id">Average rating: {{ averageRatings[i] }}</span>
                  </div>

                  <ngb-rating class="estrellas" (rateChange)="rateTool($event, tool)" [max]="5" [readonly]="false"></ngb-rating>

                  <div class="cosa">
                    <button type="button"  class="btn btn-default row boton-guardar" (click)="saveTool(tool)">
                        {{'TOOLS_PAGE.TOOLS.BUTTON_SAVE' | translate}}
                        <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
                    </button>
                  </div>
                </div>
                <div *ngIf="tool.main_image !== null" id="herramienta">
                  <img src="{{imageUrl}}/mainImage/{{tool.uid}}/{{tool.main_image}}" height="auto" width="auto" class="card-img-top">
                </div>
                <div *ngIf="tool.main_image === null" id="herramienta">
                  <img src="./assets/img/tools/camera-icon.jpeg" height="200" width="auto" class="card-img-top">
                </div>
                <!--<div *ngIf="tool.main_image.includes('null')" id="herramienta">
                  <img src="./assets/img/tools/camera-icon.jpeg" height="200" width="auto" class="card-img-top">
                </div>-->
                <div class="card-body">
                  <div style="display: inline;" *ngFor="let stop of tool.stops">
                      {{stop.stop}}
                  </div>
                  &nbsp;
                  <img *ngIf="tool.expert === true" src="../../../assets/img/tools-modal/expert_true.png" alt="Expert" title="It's for expert users">

                  <a href="#/tools" (click)="openModal(tool);">
                    <h4>
                      <span *ngIf="!isUserLogged || (isUserLogged && countrySelected != tool.country_origin)">
                        {{tool.app_name}}
                      </span>
                      <span *ngIf="isUserLogged && countrySelected == tool.country_origin">
                        {{tool.app_name_origin}}
                      </span>
                      <img *ngIf="tool.prototypeValue === true" src="../../../assets/img/tools-modal/prototype_true.png" alt="Protoype" title="It's a prototype">
                    </h4>
                  </a>

                  <p style="text-align: justify;" *ngIf="!isUserLogged || (isUserLogged && countrySelected != tool.country_origin)">
                    {{tool.short_description}}
                  </p>
                  <p style="text-align: justify;" *ngIf="isUserLogged && countrySelected == tool.country_origin">
                    {{tool.short_description_origin}}
                  </p>
                </div>
              </div>
            </ng-container>

          </div>
          <ngb-pagination class="d-flex justify-content-center" [(page)]="page" [pageSize]="pageSize" [collectionSize]="counter" [maxSize]="5" (pageChange)="getTools(false)"></ngb-pagination>

        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem class="me-2">
      <h5>
        <a href="#combine" ngbNavLink>{{'TOOLS_PAGE.BEST_KITS' | translate}}</a>
      </h5>
      <ng-template ngbNavContent>
        <!--<h2 style="padding-left: 50%;">Work in progress</h2>-->
        <div class="kits-section2" *ngFor="let menu of tmpArrayMenu; index as i">
            <div class="">
              <div class="title-best-kits col-md-12">
                <strong class="title-first-part">Follow this step by step guide for</strong>
                <div class="row kits-row" style="margin-left: -40px;">
                  <div class="tools-title-best-kits">
                    <span style="color: #ac1e12; padding-left: 40px; padding-right: 10px;"><i class="fa fa-caret-right" aria-hidden="true"></i></span>
                    <strong class="menu-name">{{menu.name}}</strong>
                    <div>
                      <span style="padding-left: 40px; text-align: justify;" class="menu_description">{{menu.short_description}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div *ngIf="tmpArrayMenu && tmpArrayMenu.length > 0">
                  <div class="row">
                    <!-- Mostrar stops existentes -->
                    <div *ngFor="let menuStop of menu.menu_stops; index as i" class="col stops" id="ejemplo{{menuStop.stop_id}}" style="font-weight: bold;">
                      <span style="width: 75%; float: left;">
                        {{i+1}}.{{menuStop.stop.stop}}
                      </span>
                      <span style="width: 25%; float: left;">
                        <!-- Condiciones para los iconos correspondientes a los stops existentes -->
                        <i *ngIf="menuStop.stop.stop == 'Onboarding'" class="fa fa-handshake-o fa-lg" id="shake-hands-icon2" aria-hidden="true"></i>
                        <i *ngIf="menuStop.stop.stop == 'Evaluation'" class="fa fa-file-text fa-lg" id="evaluation-icon" aria-hidden="true"></i>
                        <i *ngIf="menuStop.stop.stop == 'Design and Formalization'" class="fa fa-pencil-square fa-lg" id="pencil-design-icon" aria-hidden="true"></i>
                        <i *ngIf="menuStop.stop.stop == 'Realization'" class="fa fa-cog fa-lg" id="pencil-design-icon" aria-hidden="true"></i>
                        <i *ngIf="menuStop.stop.stop == 'Validation'" class="fa fa-check-circle-o fa-lg" id="check-validation-icon" aria-hidden="true"></i>
                      </span>
                    </div>

                    <!-- Agregar stops faltantes de forma dinámica -->
                    <ng-container *ngIf="getMissingStops(menu).length > 0">
                      <div *ngFor="let missingStop of getMissingStops(menu); let j = index" class="col stops" id="ejemplo{{missingStop}}" style="font-weight: bold;">
                        <span style="width: 75%; float: left;">
                          {{menu.menu_stops.length + j + 1}}.{{missingStop}}
                        </span>
                        <span style="width: 25%; float: left;">
                          <!-- Condiciones para los iconos correspondientes a los stops faltantes -->
                          <i *ngIf="missingStop == 'Onboarding'" class="fa fa-handshake-o fa-lg" id="shake-hands-icon2" aria-hidden="true"></i>
                          <i *ngIf="missingStop == 'Evaluation'" class="fa fa-file-text fa-lg" id="evaluation-icon" aria-hidden="true"></i>
                          <i *ngIf="missingStop == 'Design-and-Formalization'" class="fa fa-pencil-square fa-lg" id="pencil-design-icon" aria-hidden="true"></i>
                          <i *ngIf="missingStop == 'Realization'" class="fa fa-cog fa-lg" id="pencil-design-icon" aria-hidden="true"></i>
                          <i *ngIf="missingStop == 'Validation'" class="fa fa-check-circle-o fa-lg" id="check-validation-icon" aria-hidden="true"></i>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="row" style="padding-top: 2%;">
                  <div *ngFor="let tools of menu.menu_stops; index as i" class="col">
                    <div *ngFor="let toolsMenus of tools.menuStop_tools">
                      <div class="card card-tool-best-kits justify-content-center">
                        <div class="card-header"><ngb-rating class="estrellas" [max]="5" [readonly]="false"></ngb-rating>
                          <div class="cosa">
                            <button type="button" class="btn btn-default row boton-guardar" (click)="saveTool(toolsMenus.tool)">
                              {{"TOOLS_HOME_PAGE.BUTTON_SAVE" | translate}}
                                <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
                            </button>
                          </div>
                        </div>
                        <div *ngIf="toolsMenus.tool.main_image != null" class="image-container">
                          <img src="{{imageUrl}}/mainImage/{{toolsMenus.tool.uid}}/{{toolsMenus.tool.main_image}}" height="auto" width="auto" class="card-img-top">
                        </div>
                        <div *ngIf="toolsMenus.tool.main_image == null" class="image-container">
                          <img src="./assets/img/tools/camera-icon.jpeg" height="auto" width="auto" class="card-img-top">
                        </div>
                        <div class="card-body">
                          <!--<p style="width: 75px; color: #004470;">{{tool.stops[0]}}</p>-->
                          <a href="{{toolsMenus.tool.url}}" target="_blank"><h4 class="title-app-name" style="height: auto; width: auto;">{{toolsMenus.tool.app_name}}</h4></a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Crear columnas vacías dinámicamente para stops faltantes -->
                  <ng-container *ngIf="menu.menu_stops.length < 5">
                    <div *ngFor="let _ of createEmptyColumns(5 - menu.menu_stops.length)" class="col" style="font-weight: bold;"></div>
                  </ng-container>

                </div>
                <!--<div class="row" style="padding-top: 2%;">
                  <div *ngFor="let stop of menu.stops" class="col">
                    <div *ngFor="let tool of menu.tools">
                      <div *ngFor="let toolStop of tool.stops">
                        <div *ngIf="stop.stop === toolStop.stop" style="margin-left: 5%;">
                          <div class="card card-tool-best-kits justify-content-center col">
                            <div class="card-header"><ngb-rating class="estrellas" [max]="5" (rateChange)="rateTool($event, tool)" [readonly]="false"></ngb-rating>
                              <div class="cosa">
                                <button type="button" class="btn btn-default row boton-guardar" (click)="saveTool(tool)">
                                  <div>
                                    Save
                                    <span><i class="fa fa-bookmark" aria-hidden="true"></i></span>
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div *ngIf="tool.main_image !== null" id="herramienta">
                                  <img src="{{imageUrl}}/mainImage/{{tool.uid}}/{{tool.main_image}}" height="auto" width="auto" class="card-img-top">
                                </div>
                                <div *ngIf="tool.main_image === null" id="herramienta">
                                  <img src="./assets/img/tools/camera-icon.jpeg" height="auto" width="auto" class="card-img-top">
                                </div>
                                <div class="col tool_name">
                                  <a href="{{tool.url}}" target="_blank"><h4 class="title-app-name" style="height: auto; width: auto;">{{tool.app_name}}</h4></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->
              </div>
            </div>
            <div class="container">
            </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem class="me-2">
      <h5>
        <a href="#propose-tool" ngbNavLink>{{'TOOLS_PAGE.PROPOSE_TOOL.TITLE_SECTION' | translate}}</a>
      </h5>
      <ng-template ngbNavContent>
        <div *ngIf="isUserLogged == false || roleUser == 4">
          <div class="text-login" style="text-align: center;">
            <h2>{{"ONE_CLICK_PAGE.NOT_LOGIN.TITLE" | translate}}</h2>
          </div><br>
          <div class="text-login" style="text-align: center;">
            You don't have permission to do this, if you want to propose a tool, please request an upgrade of your user role. Only the professional users can propose a tool.
          </div>
        </div>

          <div class="form-section" *ngIf="isUserLogged == true && roleUser < 4">
            <div>
              <div class="bloque-blanco-rounded">
                <div class="form-izquierda col-md-6">
                  <h2>{{'TOOLS_PAGE.PROPOSE_TOOL.TITLE_FORM' | translate}}</h2><br>
                  <p>
                    {{'TOOLS_PAGE.PROPOSE_TOOL.DESCRIPTION_TOOL_PROPOSE' | translate}}
                  </p>
                </div>
                <div class="form-derecha col-md-6">
                  <form #tooldata="ngForm">
                    <!--{{tooldata.value | json}}-->
                    <div>
                      <!--<button *ngIf="isDev" class="btn btn-primary" (click)="consoleJorge()">Test</button>-->
                      <!--First part-->
                      <div id="form1">
                          <div class="form-group">
                            <label for="toolcountryorigin">{{'TOOLS_PAGE.PROPOSE_TOOL.TOOL_COUNTRY_ORIGIN' | translate}} <span class="obligatory">*</span></label>
                            <ng-select
                                [multiple]="false"
                                [closeOnSelect]="true"
                                [searchable]="true"
                                bindLabel="name"
                                placeholder="Select country"
                                [(ngModel)]="toolcountry"
                                id="toolcountryorigin"
                                name="toolcountryorigin"
                                [required]="true">
                                <ng-option *ngFor="let country of arrayCountry" [value]="country.id">{{country.country}}</ng-option>
                            </ng-select>
                          </div>

                          <div class="form-group">
                            <label for="toolname">{{'TOOLS_PAGE.PROPOSE_TOOL.TOOL_NAME' | translate}} <span class="obligatory">*</span></label>
                            <input type="text" class="form-control" id="toolname" name="toolname" [required]="true" [(ngModel)]="toolname" placeholder="Please write the name in English">
                          </div>

                          <div class="form-group">
                            <label for="toolnameoriginal">{{'TOOLS_PAGE.PROPOSE_TOOL.TOOL_NAME_ORIGIN' | translate}} <span class="obligatory">*</span></label>
                            <input type="text" class="form-control" id="toolnameoriginal" name="toolnameoriginal" [(ngModel)]="toolnameoriginal" placeholder="Please write the name in the language of the country of origin">
                          </div>

                          <div>
                            <label>{{'TOOLS_PAGE.PROPOSE_TOOL.TOOL_URL' | translate}} <span class="obligatory">*</span></label>
                            <fieldset id="group">
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="group" id="flexRadioMonitoring1" [value]="true" [(ngModel)]="isValueURL" (change)="booleanURLChangeEvent()">
                                <label class="form-check-label" for="flexRadioMonitoring1">
                                  Yes
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="group" id="flexRadioMonitoring2" [value]="false" [(ngModel)]="isValueURL" (change)="booleanURLChangeEvent()">
                                <label class="form-check-label" for="flexRadioMonitoring2">
                                  No
                                </label>
                              </div>
                            </fieldset>
                          </div>

                          <br>

                          <div *ngIf="isValueURL == true">
                            <div class="form-group">
                              <label for="toolurl">Application URL <span class="obligatory">*</span></label>
                              <input type="text" class="form-control" id="toolurl" name="toolurl" [(ngModel)]="toolurl" placeholder="Application URL">
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="toolshortdescription">{{'TOOLS_PAGE.PROPOSE_TOOL.TOOL_SHORT_DESCRIPTION' | translate}} <span class="obligatory">*</span></label>
                            <textarea class="form-control" id="toolshortdescription" name="toolshortdescription" [required]="true" maxlength="255" rows="3" placeholder="Short description (English) (255 characters)" [(ngModel)]="valueShortDescription"></textarea>
                            <div>{{lengthShortDescription}}/255</div>
                          </div>

                          <div class="form-group">
                            <label for="toollongdescription">{{'TOOLS_PAGE.PROPOSE_TOOL.TOOL_LONG_DESCRIPTION' | translate}} <span class="obligatory">*</span></label>
                            <textarea class="form-control" id="toollongdescription" name="toollongdescription" maxlength="5000" rows="3" placeholder="Long description (English) (5000 characters)" [(ngModel)]="valueLongDescription"></textarea>
                            <div>{{lengthLongDescription}}/5000</div>
                          </div>

                          <div class="form-group">
                            <label for="toolshortdescriptionoriginal">{{'TOOLS_PAGE.PROPOSE_TOOL.TOOL_SHORT_DESCRIPTION_ORIGIN' | translate}} <span class="obligatory">*</span></label>
                            <textarea class="form-control" id="toolshortdescriptionoriginal" name="toolshortdescriptionoriginal" maxlength="255" rows="3" placeholder="Short description (Application description in country of origin) (255 characters)" [(ngModel)]="valueShortDescriptionOriginal" ></textarea>
                            <div>{{lengthShortDescriptionOriginal}}/255</div>
                          </div>

                          <div class="form-group">
                            <label for="toollongdescriptionoriginal">{{'TOOLS_PAGE.PROPOSE_TOOL.TOOL_LONG_DESCRIPTION_ORIGIN' | translate}} <span class="obligatory">*</span></label>
                            <textarea class="form-control" id="toollongdescriptionoriginal" name="toollongdescriptionoriginal" maxlength="5000" rows="3" placeholder="Long description (Application description in country of origin) (5000 characters)" [(ngModel)]="valueLongDescriptionOriginal"></textarea>
                            <div>{{lengthLongDescriptionOriginal}}/5000</div>
                          </div>
                          <div>
                        </div>
                      </div>
                </div>

                    <div>
                      <div>
                        <button role="button" class="btn calculateTyp" (click)="createToolPropose()">
                          <h4>
                            <span> {{'TOOLS_PAGE.PROPOSE_TOOL.BUTTON_APPLY' | translate}} &nbsp; <i class="fa fa-bookmark" aria-hidden="true"></i></span>
                          </h4>
                        </button>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

import { Component, OnInit, OnDestroy, ViewChild, Output, Input } from '@angular/core';
import { UserService } from 'src/app/core/authentication/user.service';
import { AdministrationPanelService } from '../../core/binding-data/administration-panel/administration-panel.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/models/user';
import { ModalDismissReasons, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/shared/models/role';
import { RoleService } from 'src/app/core/role/role.service';
import { threadId } from 'worker_threads';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { UserExtension } from 'src/app/shared/models/user_extension';
import { AngularFireAuth } from '@angular/fire/auth';
import { Country } from 'src/app/shared/models/country';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { UserAllData } from 'src/app/shared/models/userAllData';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { UserModalComponent } from 'src/app/components/user-modal/user-modal.component';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit, OnDestroy {

  @Input() arrayCountry: Country[] = [];
  @Input() guid: string = '';

  token: string = '';
  window: number = 1;

  arrayUser: UserAllData[] = [];
  arrayRole: Role[] = [];

  tmpArrayUser: UserAllData[] = [];

  getArrayUser: Subscription = null;
  getArrayRole: Subscription = null;

  valueName: string = '';
  valueRole: number = 0;
  valueActive: boolean = false;

  //filters
  valueFilterIdRole: number = null;
  valueFilterCountry: number = null;
  valueFilterStatus: boolean = null;
  valueFilterEmail: string = '';
  valueFilterName: string = '';

  //Pagination
  page: number = 1;
  pageSize: number = 5;

  //DatePicker
  hoveredDate: NgbDate | null = null;

	fromDate: NgbDate | null;
	toDate: NgbDate | null;

  valueSecondDate: any = null;

  //ProUser
  modalRef: BsModalRef;

  tmpArrayUserRequest: UserAllData[] = [];
  isUserLogged: boolean;
  currentUser: User = null;

  constructor(
    private userService: UserService,
    private administrationPanelService: AdministrationPanelService,
    private modalService: NgbModal,
    private roleService: RoleService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public afAuth: AngularFireAuth,
    private modalServiceCard: BsModalService
  ) {}

  consoleJorge(){
    console.log(this.valueSecondDate)
  }

  ngOnInit() {
    this.checkLogin();
    this.token = sessionStorage.getItem('auth-token');
    this.getAllDataUsers();
    this.getRoles();
  }

  ngOnDestroy(): void {
    if(this.getArrayUser != null) this.getArrayUser.unsubscribe();
    if(this.getArrayRole != null) this.getArrayRole.unsubscribe();
  }

  checkLogin(): void {
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.isUserLogged = true;
        this.currentUser = new User(user);
        console.log('user 2!! ',  new User(user));
        this.userService.getByUid(user.uid).subscribe(userFromDB => {
          console.log(userFromDB['data'].user_roles[0].role_id)
          if (userFromDB) {
            this.currentUser.country = userFromDB['data'].country_id;
            this.currentUser.user_roles = userFromDB['data'].user_roles;
            if(userFromDB['data'].user_roles[0].role_id == 2){
              this.applyAutoFilter();
            }
          }
          console.log(this.currentUser)
        });
      } else {
        this.isUserLogged = false;
      }
    });
  }

  applyAutoFilter(): void {
    // Supongamos que tienes un mapeo entre los países y los valores del filtro
    const countryFilterMapping = {
      3: 3,
      10: 10,
      12: 12,
      15: 15,
      20: 20,
      25: 25,
      26: 26, // Supongamos que el ID de España es 1
      // Agrega más mapeos según sea necesario
    };

    // Obtener el valor del filtro según el país del usuario
    const autoFilterValue = countryFilterMapping[this.currentUser.country];

    // Aplicar el filtro automáticamente
    if (autoFilterValue) {
      this.valueFilterCountry = autoFilterValue;

      // Aquí puedes llamar a tu lógica de filtrado manual si es necesario
      this.applyFilter();
    }
  }

  getAllDataUsers(){
    this.getArrayUser = this.administrationPanelService.getUser$().subscribe((dataUser: UserAllData[]) => {
      if(dataUser.length == 0){
        this.userService.getAllDataUsers(this.token).toPromise().then((dataUserDB: NodeResponse) => {
          console.log('All data User DB', dataUserDB);
          if(dataUserDB.status == true) {
            this.arrayUser = dataUserDB.data;
            this.tmpArrayUser = dataUserDB.data;
            this.administrationPanelService.setUser(this.arrayUser);
          }
        });
      }else{
        console.log('All data User', dataUser);
        this.arrayUser = dataUser;
        this.tmpArrayUser = dataUser;
      }
    })
  }

  getRoles(){
    this.getArrayRole = this.administrationPanelService.getRole$().subscribe((dataRole: Role[]) => {
      if(dataRole.length == 0){
        this.roleService.getAllRole().toPromise().then((dataRoleDB: NodeResponse) => {
          console.log('All data Role DB', dataRoleDB)
          if(dataRoleDB.status == true){
            this.arrayRole = dataRoleDB.data;
            this.administrationPanelService.setRole(this.arrayRole);
          }
        })
      }else{
        this.arrayRole = dataRole;
      }
    })
  }

  toUserTable(){
    this.deleteAllFilters();
    this.window = 1;
  }

  toProUserRequest(){
    this.window = 2;
    this.usersWithProRequest()
  }

  refreshUsers(){

    this.userService.getAllDataUsers(this.token).toPromise().then((dataUserDB: NodeResponse) => {
      console.log('All data User DB', dataUserDB);
      if(dataUserDB.status == true) {
        this.deleteAllFilters();

        this.arrayUser = dataUserDB.data;
        this.tmpArrayUser = dataUserDB.data;
        this.administrationPanelService.setUser(this.arrayUser);
      }
    });
  }

  openUpdateModal(content, uidSelected: string) {

    let currentUser = this.arrayUser.find(user => user.uid == uidSelected);
    let currentUserIndex = this.arrayUser.findIndex(user => user.uid == uidSelected);

    this.valueName = currentUser.name;
    this.valueRole = currentUser.user_roles[0].role_id;
    this.valueActive = currentUser.user_extension.active;

		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(

      //si es usuario salva el formulario
			async (save) => {

        let bodyRole = {
          user_id: currentUser.uid,
          role_id: this.valueRole
        }

        await this.roleService.updateRoleUser(bodyRole).toPromise().then(async (dataRole: any) => {
          console.log('response update role -> ', dataRole);

          if(dataRole.status == true){

            await this.userService.updateStatus(currentUser.uid, this.valueActive).toPromise().then((dataUserExtension: NodeResponse) => {
              console.log('response update status -> ', dataUserExtension);

                if(dataUserExtension.status == true){

                  let newNameRole = this.arrayRole.find(role => role.id == this.valueRole).name;

                  this.arrayUser[currentUserIndex].user_roles[0].role_id = this.valueRole;
                  this.arrayUser[currentUserIndex].user_roles[0].role.name = newNameRole;
                  this.arrayUser[currentUserIndex].user_extension.active = this.valueActive;

                  let index2 = this.tmpArrayUser.findIndex(user => user.uid == uidSelected);
                  this.tmpArrayUser[index2].user_roles[0].role_id = this.valueRole;
                  this.tmpArrayUser[index2].user_roles[0].role.name = newNameRole;
                  this.tmpArrayUser[index2].user_extension.active = this.valueActive;


                  this.administrationPanelService.setUser(this.arrayUser);

                  this.valueName = '';
                  this.valueRole = 0;
                  this.valueActive = false;

                }
            })
          }


        })
			},
        //Si cierrra el MODAL
			(cancel) => {
				//this.closeResult = `Dismissed ${this.getDismissReason(cancel)}`;
        this.valueName = '';
        this.valueRole = 0;
        this.valueActive = false;
			},
		);
	}

  deleteUser(uidSelected: string){
    if(confirm('Do you want to delete this user?') == true){

      this.userService.delete(uidSelected).toPromise().then((data) => {
        console.log("user deleted", data);

        let index = this.arrayUser.findIndex(user => user.uid == uidSelected);
        this.arrayUser.splice(index, 1);

        let index2 = this.tmpArrayUser.findIndex(user => user.uid == uidSelected);
        this.tmpArrayUser.splice(index2, 1);

        this.administrationPanelService.setUser(this.arrayUser);

      })
    }

  }

  applyFilter(){

    this.tmpArrayUser = [];
    this.tmpArrayUser = this.arrayUser;

    console.log(this.tmpArrayUser);

    if(this.valueFilterName != null && this.valueFilterName != undefined && this.valueFilterName != ''){
      this.tmpArrayUser = this.tmpArrayUser.filter(user => user.name.toLowerCase().includes(this.valueFilterName.toLowerCase()));
    }

    if(this.valueFilterEmail != null && this.valueFilterEmail != undefined && this.valueFilterEmail != ''){
      this.tmpArrayUser = this.tmpArrayUser.filter(user => user.email.toLowerCase().includes(this.valueFilterEmail.toLowerCase()));
    }

    if(this.valueFilterIdRole != null){
      this.tmpArrayUser = this.tmpArrayUser.filter(user => user.user_roles[0].role_id == this.valueFilterIdRole);
    }

    if(this.fromDate != null && this.fromDate != undefined){
      this.tmpArrayUser = this.tmpArrayUser.filter(user => this.compareDates(user))
    }

    if(this.valueFilterCountry != null){
      this.tmpArrayUser = this.tmpArrayUser.filter(user => user.country_id == this.valueFilterCountry);
    }

    if(this.valueFilterStatus != null){
      this.tmpArrayUser = this.tmpArrayUser.filter(user => user.user_extension.active == this.valueFilterStatus);
    }
  }

  //new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)

  deleteAllFilters(){
    this.tmpArrayUser = [];
    this.tmpArrayUser = this.arrayUser;

    this.valueFilterIdRole = null;
    this.fromDate = null;
    this.toDate = null;

    // Evita borrar el filtro de país si el rol del usuario es 2
    if (this.currentUser.user_roles[0].role_id !== 2) {
      this.valueFilterCountry = null;
    }

    this.valueFilterStatus = null;
    this.valueFilterName = '';
    this.valueFilterEmail = '';

    this.applyFilter();
  }

  //DatePicker

  onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}

    console.log(this.fromDate, this.toDate)
  }

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

  compareDates(user: any){
    let last_login: string = user.user_extension.last_login;
    let last_login_formated = last_login.split('T')[0].split('-');
    let last_login_date = new Date(parseInt(last_login_formated[0]), (parseInt(last_login_formated[1]) - 1), parseInt(last_login_formated[2]))

    let firstDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);


    if(this.toDate != null && this.toDate != undefined){

      let seconDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);

      if(last_login_date.getTime() >= firstDate.getTime() && last_login_date.getTime() <= seconDate.getTime()){
        return user;
      }

    }else if(last_login_date.getTime() >= firstDate.getTime()){
      return user;
    }
  }

  //end datepicker

  openModal(user: UserAllData) {
    this.modalRef = this.modalServiceCard.show(UserModalComponent,
      { class: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        initialState: {user},

      });
      this.modalRef.content.closeBtnName = 'Close';
      this.modalRef.onHidden.asObservable().subscribe((user) => {
        if(user != 'browser-back-navigation-clicked'){
          console.log('la data del hide -> ', user);

          let index = this.arrayUser.findIndex(e => e.uid == user.uid);

          this.arrayUser.splice(index, 1, user);
          this.administrationPanelService.setUser(this.arrayUser);

          this.tmpArrayUser = [];
          this.tmpArrayUser = this.arrayUser;

          this.usersWithProRequest();
        }
      });

  }

  usersWithProRequest(){
    this.tmpArrayUserRequest = [];

    for(let i = 0; i < this.arrayUser.length; i++){
      let user = this.arrayUser[i];

      if(user.userToProRequests.length > 0){
        for(let j = 0; j < user.userToProRequests.length; j++){
          if(user.userToProRequests[j].userToProRequest_status_id == 1){
            this.tmpArrayUserRequest.push(user);
          }
        }
      }
    }
  }
}

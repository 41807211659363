export interface ToolRate  {
    rate: number;
    tool_id: number;
    user_id: string;
}

export class ToolRate extends Object {
    constructor(
      public rate: number,
      public tool_id: number,
      public user_id: string
    ) {
      super();
    }
  }
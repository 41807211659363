import { Component, Input, OnInit } from '@angular/core';
import { FormProService } from 'src/app/core/formPro/form-pro.service';
import { GlobalConstants } from 'src/app/shared/GlobalConstants';
import { NodeResponse } from 'src/app/shared/models/node_response';
import { Suggestion } from 'src/app/shared/models/suggestion';
import { UserAllData } from 'src/app/shared/models/userAllData';

@Component({
  selector: 'app-suggestion-box',
  templateUrl: './suggestion-box.component.html',
  styleUrls: ['./suggestion-box.component.scss']
})
export class SuggestionBoxComponent implements OnInit {

  @Input() user: UserAllData = null;

  window = 1;

  valueSubject: string = '';
  valueSugestion: string = '';
  valueVideo: string = '';
  valueImages: any[] = [];

  nameImages: string = "No images selected";

  //table
  arraySuggestion: any[] = [];

  //pagination
  page: number = 1;
  pageSize: number = 10;

  //end table

  //form
  suggestionSelected: any = null;
  imageUrl: string = GlobalConstants.backendURL;

  constructor(private suggestionService: FormProService) { }

  ngOnInit(): void {
    this.getSuggestionsByUserId(this.user.uid);
  }

  getSuggestionsByUserId(userId: string){
    this.suggestionService.getSuggestionByUserId(this.user.uid).toPromise().then((data: NodeResponse) => {
      console.log(data);

      if(data.status == true){
        this.arraySuggestion = data.data;
      }else{
        console.log(data.message);
      }
    })
  }

  showSuggestion(id: number){
    this.suggestionService.getSuggestionById(id).toPromise().then((data: NodeResponse) => {
      console.log(data);

      if(data.status == true){
        this.window = 3;

        this.suggestionSelected = data.data;

      }else{
        console.log(data.message);
      }
    })
  }

  initTable(){
    if(this.window != 2){
      this.getSuggestionsByUserId(this.user.uid);
    }
    this.window = 2;
  }

  initForm(){
    this.window = 1;

    this.valueSubject = '';
    this.valueSugestion = '';
    this.valueVideo = '';
    this.nameImages = "No images selected";
    this.valueImages = [];
  }

  createSuggestion(){
    if(this.valueSubject.length > 0 && this.valueSugestion.length > 0 && this.valueImages.length > 0){
      if(confirm('Do you want to submit your suggestion?')){

        this.suggestionService.createSuggestion(this.valueSubject, this.valueSugestion, this.valueVideo, this.user.uid).toPromise().then(async (data: NodeResponse) => {
          console.log('suggestion response -> ', data);

          alert(data.message);

          if(data.status == true){

            for(let i = 0; i < this.valueImages.length; i++){

              await this.suggestionService.createImageSuggestion(data.data.id, this.valueImages[i]).toPromise().then((dataImage: NodeResponse) => {
                console.log("response node imagesAdditional -> ", dataImage);
              })

            }

            this.initForm();

          }
        })

      }
    }else{
      alert('You must to fill all obligatory fields')
    }

  }

  fileImageChangeEvent(event: any){

    this.valueImages = [];
    this.nameImages = 'Selected images: ';

    for(let i = 0; i < event.target.files.length; i++){
      console.log('Imagen n' + i + ' -> ', event.target.files[i]);

      if(event.target.files[i].type.substring(0,5) == 'image'){
        this.valueImages.push(event.target.files[i]);
        this.nameImages += event.target.files[i].name;

        if(i != (event.target.files.length - 1)){
          this.nameImages += ', ';
        }else{
          this.nameImages += '.';
        }
      }else{
        alert('You must to select IMAGES');
        this.valueImages = [];
        this.nameImages = "No images selected";

      }

      if(i == 4) break;
    }

  }
}
